import styled from '@emotion/styled';

import { BUSINESS_MODAL_BREAKPOINT } from '../BusinessModal.styled';

export const BusinessModalContentContainer = styled.div`
    display: flex;
    height: 100%;
`;

export const LeftPart = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 240px;
    border-right: solid 1px rgba(20, 37, 66, 0.12);
    flex-shrink: 0;
`;

export const RightPart = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 400px;

    // Tablet support
    @media (${BUSINESS_MODAL_BREAKPOINT}) {
        width: 100%;
    }
`;
