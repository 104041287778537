import { MouseEvent } from 'react';

import DateFormat from 'admin/content/logs/common/components/DateFormat/DateFormat';
import Status from 'admin/content/logs/common/components/Status/Status';

import { DiffusionCell } from './RowTop.styled';
import Business from '../Business/Business';
import BusinessDetails from '../BusinessDetails';
import { DiffusionTableRowContainer } from '../DiffusionRow/DiffusionRow.styled';

type Props = {
    partnerName: string;
    businessName: string;
    businessAddress: string;
    businessCity: string;
    businessZipCode: string;
    businessId: string;
    pushType: string;
    creationDate: number;
    updateDate: number;
    state: string;
    closed: boolean;
    toggleClosed: () => void;
};

const Row = ({
    partnerName,
    businessName,
    businessAddress,
    businessCity,
    businessZipCode,
    businessId,
    pushType,
    creationDate,
    updateDate,
    state,
    toggleClosed,
    closed,
}: Props) => {
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if ((event.target as HTMLElement).getAttribute('data-track') !== 'partner_logo') {
            toggleClosed();
        }
    };
    return (
        <DiffusionTableRowContainer appearance="content" onClick={handleClick}>
            <DiffusionCell width="small">
                <Business logo={partnerName} businessId={businessId} />
            </DiffusionCell>
            <DiffusionCell width="large">
                {' '}
                <BusinessDetails
                    name={businessName}
                    address={businessAddress}
                    zipcode={businessZipCode}
                    city={businessCity}
                />
            </DiffusionCell>
            <DiffusionCell width="medium" type="category">
                {' '}
                {pushType}
            </DiffusionCell>
            <DiffusionCell width="regular">
                {' '}
                <DateFormat date={creationDate} />{' '}
            </DiffusionCell>
            <DiffusionCell width="regular">
                {' '}
                <DateFormat date={updateDate} />{' '}
            </DiffusionCell>
            <DiffusionCell width="regular">
                {' '}
                <Status status={state} />{' '}
            </DiffusionCell>
            <DiffusionCell width="small">
                {state !== 'PENDING' && <i className={`fa fa-angle-${closed ? 'down' : 'up'}`} />}
            </DiffusionCell>
        </DiffusionTableRowContainer>
    );
};

export default Row;
