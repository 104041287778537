import {
    MessagingSettingsStats,
    PaginatedBmSettings,
} from 'app/api/types/business_message_settings';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import formatObjectKeysToSnakeCase from 'app/businessEditV2/oldFromEditV1/services/formatObjectKeysToSnakeCase';
import { MessagingSettingsForm } from 'app/reviewManagement/messaging/types';

class MessagingSettings extends ApiResourceBaseClass {
    static bmSettings(
        pageParam: number,
        query: string,
        business_id?: string,
    ): Promise<PaginatedBmSettings> {
        return MessagingSettings.get('messaging/settings/search', {
            page: pageParam,
            query: query,
            business__in: business_id,
            partoo_ui: true,
        }).then(({ data }) => data);
    }

    static update(id: number, payload: MessagingSettingsForm): Promise<any> {
        const postPayload = formatObjectKeysToSnakeCase(payload);
        if ('uses_business_hours' in postPayload)
            postPayload.uses_business_hours = !payload.usesBusinessHours;
        return MessagingSettings.post(`messaging/settings/${id}?partoo_ui=true`, postPayload).then(
            ({ data }) => data,
        );
    }

    static messagingSettingsStats(): Promise<MessagingSettingsStats> {
        return MessagingSettings.get('messaging/settings/stats').then(({ data }) => data);
    }
}

export default MessagingSettings;
