import styled from '@emotion/styled';

import { Menu } from 'app/common/designSystem/components/molecules/AsyncSubMenuMultipleSelect';

export const SectionContainer = styled.div`
    width: 352px;
`;

export const MenuItem = styled(Menu.Item)`
    padding: 6px 24px 6px 24px;
    height: inherit;
`;
