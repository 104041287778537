import { SuccessResponse } from '@partoohub/api-client';

import AdminApiResourceBaseClass from 'admin/common/api/calls/baseAdminApiCalls';

const RESOURCE_URI = 'diffusion_cleaner';

export enum Publisher {
    GOOGLE_MY_BUSINESS = 'google_my_business',
    FACEBOOK = 'facebook',
    APPLE_BUSINESS_CONNECT = 'apple_business_connect',
    LE118000 = 'le118000',
    HOODSPOT = 'hoodspot',
    JUSTACOTE = 'justacote',
    BING_GLOBAL = 'bing_global',
}

interface DiffusionCleanerCountFilter {
    provider?: string;
    orgId?: string;
    group__in?: string[];
    groups?: string[];
    publishers?: string[];
    errorKeys?: string[];
    orderBy?: string;
}

export interface DiffusionCleanerCountData {
    error_key: string;
    business_count: number;
    publisher: Publisher;
    description: string;
    wiki_link: string;
    bulk_buttons: string[];
    business_buttons: string[];
    error_name: string;
    business_edit_section: string;
}
interface DiffusionCleanerStatusFilter {
    provider?: string;
    publisher?: string;
    orgId?: string;
    group__in?: string[];
    groups?: string[];
    errorKey: string;
    page?: number;
}

interface DiffusionCleanerMissingCategoriesParam {
    business_id: string;
    lang: string;
    publisher: Publisher;
}

interface DiffusionCleanerReadableErrorParam {
    call_log_id: number;
}

interface DiffusionCleanerUnknownErrorParam {
    call_log_id: number;
}

export interface DiffusionCleanerStatusData {
    id: string;
    name: string;
    address_full: string;
    zipcode: string;
    city: string;
    country: string;
    diffuser_name: string;
    call_log_ids: number[];
}

interface DiffusionCleanerMissingCategoriesData {
    id: string;
    label: string;
}

interface DiffusionCleanerReadableErrorData {
    human_error_message: string;
}

interface DiffusionCleanerUnknownErrorData {
    response_content: string;
}

export interface DiffusionCleanerBusinessPushFilter {
    businessId: string;
    diffuser: string;
}

export interface DiffusionCleanerExport {
    provider?: string;
    orgId?: string;
    group__in?: string[];
    groups?: string[];
    lang: string;
    publishers?: string[];
    errorKeys?: string[];
}
export class DiffusionCleanerAdminApiCall extends AdminApiResourceBaseClass {
    static async diffusionCleanerCount(
        filter?: DiffusionCleanerCountFilter,
    ): Promise<DiffusionCleanerCountData[]> {
        const { data } = await DiffusionCleanerAdminApiCall.get(`${RESOURCE_URI}/count`, {
            org_id: filter?.orgId,
            provider: filter?.provider,
            group__in: filter?.group__in?.length ? filter.group__in.join(',') : null,
            groups: filter?.groups?.length ? filter.groups.join(',') : null,
            publisher__in: filter?.publishers?.length ? filter.publishers.join(',') : null,
            error_key__in: filter?.errorKeys?.length ? filter.errorKeys.join(',') : null,
            ordering: filter?.orderBy || '-count',
        });
        return data;
    }

    static async diffusionCleanerStatus(
        filter?: DiffusionCleanerStatusFilter,
    ): Promise<DiffusionCleanerStatusData[]> {
        const { data } = await DiffusionCleanerAdminApiCall.get(`${RESOURCE_URI}/status`, {
            org_id: filter?.orgId,
            provider: filter?.provider,
            publisher: filter?.publisher,
            group__in: filter?.group__in?.length ? filter.group__in.join(',') : null,
            groups: filter?.groups?.length ? filter.groups.join(',') : null,
            error_key: filter?.errorKey,
            page: filter?.page,
        });
        return data;
    }

    static async businessPush(filter: DiffusionCleanerBusinessPushFilter): Promise<void> {
        await DiffusionCleanerAdminApiCall.post(`${RESOURCE_URI}/business_push`, {
            business_id: filter.businessId,
            diffuser: filter.diffuser,
        });
    }

    static async bulkPush(filter: DiffusionCleanerStatusFilter): Promise<void> {
        await DiffusionCleanerAdminApiCall.post(`${RESOURCE_URI}/bulk_push`, {
            org_id: filter?.orgId,
            provider: filter?.provider,
            publisher: filter?.publisher,
            group__in: filter?.groups?.length ? filter.groups.join(',') : null,
            error_key: filter?.errorKey,
        });
    }

    static async getMissingCategories(
        params: DiffusionCleanerMissingCategoriesParam,
    ): Promise<DiffusionCleanerMissingCategoriesData[]> {
        const { data } = await DiffusionCleanerAdminApiCall.get(
            `${RESOURCE_URI}/missing_categories`,
            params,
        );
        return data;
    }

    static async getCallLog(
        params: DiffusionCleanerReadableErrorParam,
    ): Promise<DiffusionCleanerReadableErrorData> {
        const { data } = await DiffusionCleanerAdminApiCall.get(
            `${RESOURCE_URI}/readable_error`,
            params,
        );
        return data;
    }

    static async getResponseContent(
        params: DiffusionCleanerUnknownErrorParam,
    ): Promise<DiffusionCleanerUnknownErrorData> {
        const { data } = await DiffusionCleanerAdminApiCall.get(
            `${RESOURCE_URI}/unknown_error`,
            params,
        );
        return data;
    }

    static async diffusionCleanerExport(params: DiffusionCleanerExport): Promise<SuccessResponse> {
        const { data } = await DiffusionCleanerAdminApiCall.get(`${RESOURCE_URI}/exporter`, {
            org_id: params?.orgId,
            provider: params?.provider,
            group__in: params?.group__in?.length ? params.group__in : null,
            groups: params?.groups?.length ? params.groups : null,
            publisher__in: params?.publishers?.length ? params.publishers.join(',') : null,
            error_key__in: params?.errorKeys?.length ? params.errorKeys.join(',') : null,
            lang: params?.lang,
        });
        return data;
    }
}
