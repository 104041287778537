import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

export const NoMergedAccountPlaceholderContainer = styled.div`
    margin-top: 64px;
`;

export const StyledButton = styled(Button)`
    margin-top: 16px;
`;
