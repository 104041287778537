import React from 'react';

import { Button, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { diffusionCleanerApiClient } from 'admin/api/apiResources';

interface PushButtonProps {
    businessId: string;
    diffuser: string;
}

export const PushButton: React.FC<PushButtonProps> = ({ businessId, diffuser }) => {
    const { t } = useTranslation();

    const { mutate, isLoading } = useMutation(
        ({ businessId, diffuser }: PushButtonProps) =>
            diffusionCleanerApiClient.businessPush({ businessId, diffuser }),
        {
            onSuccess: () => {
                toast.success('', t('admin:page_diffusion_cleaner__business_push_button__success'));
            },
            onError: (error: any) => {
                toast.error(
                    error.message,
                    t('admin:page_diffusion_cleaner__business_push_button__error'),
                );
            },
        },
    );

    const onPush = () => {
        mutate({ businessId, diffuser });
    };

    return (
        <Button
            appearance="outlined"
            shape="rounded"
            size="medium"
            variant="secondary"
            dataTrackId="admin_diffusion_cleaner__push_business_button"
            onClick={onPush}
            disabled={isLoading}
        >
            <Text variant="bodyMBold">
                {t('admin:page_diffusion_cleaner__business_button__push')}
            </Text>
        </Button>
    );
};
