import { FC } from 'react';

import {
    V2FormattedDiffusionErrors,
    V2FormattedDiffusionPostCheckErrors,
} from 'app/api/types/diffusion';

import { DetailsContainer } from './Details.styled';
import DiffusionDetails from '../DiffusionDetails/DiffusionDetails';

type Props = {
    diffusionError: Array<V2FormattedDiffusionErrors>;
    diffusionState: string;
    callLog: string;
    logs_human_error_message: Array<string>;
    postCheckErrors: Array<V2FormattedDiffusionPostCheckErrors>;
};

const Details: FC<Props> = ({
    diffusionError,
    diffusionState,
    callLog,
    logs_human_error_message,
    postCheckErrors,
}) => {
    const hasDiffusionErrors =
        (diffusionError && diffusionError.length > 0) ||
        postCheckErrors?.filter(
            postCheck =>
                ![
                    'postcheck_partoo_error',
                    'postcheck_all_server_error',
                ].includes(postCheck.key),
        );
    function renderMultipleDiffusions() {
        return (
            <DiffusionDetails
                // @ts-ignore
                state={diffusionState}
                diffusionErrors={diffusionError}
                callLog={callLog}
                logs_human_error_message={logs_human_error_message}
                postCheckErrors={postCheckErrors}
            />
        );
    }

    function renderEmptyDiffusion() {
        return (
            <DiffusionDetails
                key="psl-0"
                state=""
                diffusionErrors={diffusionError}
                callLog={callLog}
                logs_human_error_message={logs_human_error_message}
                postCheckErrors={postCheckErrors}
            />
        );
    }

    return (
        <DetailsContainer>
            {hasDiffusionErrors
                ? renderMultipleDiffusions()
                : renderEmptyDiffusion()}
        </DetailsContainer>
    );
};

export default Details;
