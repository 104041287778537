import { useEffect, useMemo, useState } from 'react';

import {
    OrgCategoryResponse,
    OrgCountryResponse,
    ServiceSuggestionType,
} from '@partoohub/api-client';
import {
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import {
    Button,
    Checkbox,
    Dialog,
    Modal,
    Option,
    Stack,
    Text,
    TextInput,
    toast,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { servicesApiClient } from 'app/api/v2/api_calls/apiResources';
import BusinessModalButton from 'app/common/components/businessModal';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import useSelectedBusinessesTitle from 'app/common/components/businessModal/hooks/useSelectedBusinessesTitle';
import useTotalBusinessesCount from 'app/common/components/businessModal/hooks/useTotalBusinessesCount';
import { resetBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers';

import {
    langApiClient,
    organizationApiClient,
    servicesAdminApiClient,
} from 'admin/api/apiResources';
import { adminBulkCreateServiceQueryParams } from 'admin/api/types';
import { CategorySelect } from 'admin/common/components/inputs/CategorySelect';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { SingleSelect } from 'admin/common/components/inputs/SingleSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import {
    AVAILABLE_LANGUAGES,
    ORG_CATEGORIES,
    ORG_COUNTRIES,
    SERVICES_SUGGESTIONS,
} from 'admin/common/data/queryKeysConstants';
import { useMe } from 'admin/common/hooks/queries/useMe';

import {
    OverrideHelperContainer,
    OverrideHelperLabel,
    OverrideHelperText,
    ServiceHelperContainer,
    ServiceHelperLabel,
    ServiceHelperText,
} from './AdminServiceHelper.styled';
import { ServiceModalFormType } from './ServicesSection/modals/utils';
import { ServicesList } from './ServicesSection/ServicesList';

const langCodeToId = (code: string) => code.toUpperCase().replace('-', '_');

const BulkServicesCreateForm = () => {
    const { t } = useTranslation();

    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [category, setCategory] = useState<Option | undefined>();
    const [services, setServices] = useState<Array<ServiceModalFormType>>([]);
    const [isOverrideChecked, setIsOverrideChecked] = useState<boolean>(false);

    const [openedWarningModal, setOpenedWarningModal] = useState<boolean>(false);

    const { data: me } = useMe();
    const [language, setLanguage] = useState<string | null>(me?.lang ?? null);
    const [country, setCountry] = useState<string | null>(null);

    const businessModalFilters = useBusinessModalFilters();

    useEffect(() => {
        if (language === null && me && me.lang !== null) {
            setLanguage(langCodeToId(me.lang));
        }
    }, [me]);

    const dispatch = useDispatch();

    useEffect(() => {
        setCountry(null);
        setCategory(undefined);
        setServices([]);
        dispatch(resetBusinessFilter());
    }, [org]);

    useEffect(() => {
        setServices([]);
        dispatch(resetBusinessFilter());
    }, [category]);

    const orgId =
        org?.value && typeof parseInt(org.value, 10) === 'number' ? parseInt(org?.value, 10) : 0;

    const languagesQuery = useQuery(
        [AVAILABLE_LANGUAGES],
        () => langApiClient.getAvailableLanguages(),
        {
            refetchInterval: false,
        },
    );

    const categoriesQuery = useQuery(
        [ORG_CATEGORIES, { org, language }],
        () => organizationApiClient.getBusinessesCategory(orgId, language || undefined),
        {
            enabled: !!orgId && !!language,
        },
    );

    const countriesQuery = useQuery(
        [ORG_COUNTRIES, { orgId }],
        () => organizationApiClient.getBusinessesCountries(orgId),
        {
            enabled: !!orgId,
        },
    );

    const suggestionsQuery = useQuery(
        [SERVICES_SUGGESTIONS, { category, country }],
        () =>
            servicesApiClient.getServicesSuggestionsCategory(
                category ? category.value : '',
                country || '',
                language || '',
            ),
        { enabled: !!category && !!country },
    );

    const languages = languagesQuery.data || [];
    const categories = categoriesQuery.data?.categories || [];
    const countries = countriesQuery.data?.countries || [];
    const servicesSuggestions: Array<ServiceSuggestionType> =
        suggestionsQuery.data?.services_suggestions || [];

    const selectedBusinessesCount = useSelectedBusinessesCount();
    const totalBusinessesCount = useTotalBusinessesCount();

    const { title } = useSelectedBusinessesTitle();

    const areAllBusinessesSelected =
        !selectedBusinessesCount || selectedBusinessesCount === totalBusinessesCount;

    const categoryOptions = categories.map((element: OrgCategoryResponse) => ({
        label: element.name,
        name: element.gmb_id,
        value: element.gmb_id,
    }));

    const languagesOptions = languages.map(({ value }) => {
        const langId = langCodeToId(value);
        return {
            value: langId,
            name: langId,
            label: t(`language_${langId}`),
        };
    });

    const countriesOptions = countries.map((element: OrgCountryResponse) => ({
        value: element.code,
        name: element.code,
        label: element.name,
    }));

    const bulkCreateServicesMutation = useMutation(
        () => {
            const queryParams = adminBulkCreateServiceQueryParams(
                category?.value || '',
                services,
                isOverrideChecked,
            );

            return servicesAdminApiClient.adminBulkCreateService({
                ...queryParams,
                ...businessModalFilters,
            });
        },
        {
            onSuccess: () => {
                setOrg(undefined);
                setCategory(undefined);
                setServices([]);
                setIsOverrideChecked(false);
                dispatch(resetBusinessFilter());
                toast.success(
                    t('bulk_service_create_success_toast_description'),
                    t('bulk_service_create_success_toast_title'),
                );
            },
            onError: () => {
                toast.error(
                    t('error_adding_service_toast_description'),
                    t('error_adding_service_toast_title'),
                );
            },
        },
    );

    const onClickOpenWarningModal = () => {
        setOpenedWarningModal(true);
    };

    const onClickSaveServices = () => {
        bulkCreateServicesMutation.mutate();
        setOpenedWarningModal(false);
    };

    const serviceSearchParams = useMemo(
        () => ({
            can_modify_service_list: true,
            connected_to_gmb_location: true,
            org_id: orgId,
            country,
        }),
        [orgId, country],
    );

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Warning,
    };

    return (
        <AdminPage
            dataTrackId="page_bulk_services"
            title={t('admin:page_bulk_services__title')}
            description={t('admin:page_bulk_services__description')}
            infoLink={'https://partoo.elium.com/tile/view/812/'}
        >
            <BorderedContainer>
                <PageFormRow>
                    <NewOrganizationSelect
                        dataTrackId="page_bulk_services__select_organization"
                        orgs={org}
                        setOrgs={setOrg}
                        required
                    />
                </PageFormRow>
                <PageFormRow>
                    <SingleSelect
                        dataTrackId="page_bulk_services__select_country"
                        disabled={!orgId}
                        onChange={setCountry}
                        sections={[{ options: countriesOptions }]}
                        selectedValue={country}
                        placeholder={'Country'}
                        disableReset
                        required
                    />
                    <SingleSelect
                        dataTrackId="page_bulk_services__select_language"
                        disabled={languagesQuery.isLoading}
                        onChange={setLanguage}
                        sections={[{ options: languagesOptions }]}
                        placeholder="Category search language"
                        selectedValue={language}
                        required
                    />
                </PageFormRow>
                <PageFormRow>
                    <CategorySelect
                        dataTrackId="page_bulk_services__select_category"
                        disabled={!orgId}
                        categoriesOptions={categoryOptions}
                        category={category}
                        setCategory={setCategory}
                        required
                    />
                </PageFormRow>
                {category && country && (
                    <>
                        <PageFormRow>
                            <div>
                                <ServiceHelperContainer>
                                    <ServiceHelperText>
                                        {/* @ts-ignore */}
                                        <i className="fa-solid fa-info-circle" />
                                        <ServiceHelperLabel>
                                            {t('admin_bulk_services_business_helper')}
                                        </ServiceHelperLabel>
                                    </ServiceHelperText>
                                </ServiceHelperContainer>
                                <BusinessModalButton
                                    withUrlSync={false}
                                    noBusinessLimit
                                    searchFilters={serviceSearchParams}
                                    button={
                                        // the business modal is not opened without this div
                                        <div>
                                            <TextInput
                                                dataTrackId="bulk_service_create__open_business_selection"
                                                label={t('admin:select_businesses')}
                                                value={title}
                                            />
                                        </div>
                                    }
                                />
                            </div>
                        </PageFormRow>
                        <PageFormRow>
                            <ServicesList
                                country={country}
                                category={category}
                                services={services}
                                setServices={setServices}
                                suggestions={servicesSuggestions}
                            />
                        </PageFormRow>
                        <PageFormRow>
                            <Stack>
                                <OverrideHelperContainer>
                                    <OverrideHelperText>
                                        {/* @ts-ignore */}
                                        <i className="fa-solid fa-info-circle" />
                                        <OverrideHelperLabel>
                                            {t('admin_bulk_services_override_helper')}
                                        </OverrideHelperLabel>
                                    </OverrideHelperText>
                                </OverrideHelperContainer>

                                <Checkbox
                                    dataTrackId="bulk_service_create__checkbox"
                                    checked={isOverrideChecked}
                                    onChange={() => setIsOverrideChecked(!isOverrideChecked)}
                                >
                                    <Text variant="bodyMRegular" as="span">
                                        {t('admin_gmb_services_override')}
                                    </Text>
                                </Checkbox>
                            </Stack>
                        </PageFormRow>
                        <PageFormRow>
                            <SubmitButton
                                dataTrackId="admin_bulk_service_creation__add_services"
                                text={t('create_service_in_bulk')}
                                onClick={onClickOpenWarningModal}
                                disabled={services.length === 0}
                            />
                        </PageFormRow>
                    </>
                )}
            </BorderedContainer>
            <Modal isOpen={openedWarningModal} closeModal={() => setOpenedWarningModal(false)}>
                <Dialog
                    dataTrackId=""
                    headerContent={illustrationElement}
                    title={t('add_service_modal_title')}
                    subTitle={t(
                        areAllBusinessesSelected
                            ? 'add_bulk_services_all_businesses'
                            : 'add_bulk_services_business',
                        {
                            count: selectedBusinessesCount,
                            countNumber: selectedBusinessesCount,
                        },
                    )}
                    actions={[
                        <Button
                            dataTrackId="bulk_service_create_form__cancel"
                            variant="secondary"
                            size="large"
                            onClick={() => setOpenedWarningModal(false)}
                            appearance="outlined"
                            full
                        >
                            {t('cancel')}
                        </Button>,
                        <Button
                            dataTrackId="bulk_service_create_form__save"
                            variant="primary"
                            size="large"
                            onClick={onClickSaveServices}
                            full
                        >
                            {t('save')}
                        </Button>,
                    ]}
                />
            </Modal>
        </AdminPage>
    );
};

export default BulkServicesCreateForm;
