import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';
import { Stack, Text } from '@partoohub/ui';

export const StyledContainer = styled(Stack)`
    flex: 1;
    background-color: ${HEX_COLORS.white};
    padding: 24px;
    border-radius: 16px;
    border-width: 1px;
    margin: 16px 0 16px 40px;
    justify-content: space-between;
`;

export const StyledFooter = styled.div`
    border-top: 1px solid ${greyColorObject.initial};
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 24px;
`;

export const StyledTextWrapper = styled.div`
    margin-bottom: 32px;
`;

export const StyledTitle = styled(Text)`
    margin-bottom: 32px;
`;
