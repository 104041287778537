import { FC, useState } from 'react';

import { Filters, Option, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { DemoCreatorAdminApiCall } from 'app/api/v2/api_calls/demoCreatorApiCalls';

import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { languagesData } from 'admin/common/data/languages';

import { OrganizationSelect } from './OrganizationSelect';

const DemoCreator: FC = () => {
    const { t } = useTranslation();

    const langOptions: Option[] = languagesData
        .filter(({ supportedForDemo }) => supportedForDemo)
        .map(({ code }) => ({
            label: t(`original-${code}`),
            name: code,
            value: code,
        }));
    const section = [{ options: langOptions }];

    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [langs, setLangs] = useState<Record<string, boolean>>({});
    const lang = Object.entries(langs)
        .filter(([_, selected]) => !!selected)
        .map(([value, _]) => value)[0];

    const { mutateAsync, isLoading } = useMutation(DemoCreatorAdminApiCall.createDemo, {
        onSuccess: (data: any) => {
            if (data.status === 'success' || data.status === 'pending') {
                toast.success(
                    t('admin:page_demo_creator__success_toast__content'),
                    t('admin:page_demo_creator__success_toast__title'),
                );
            } else toast.error(data.error, t('admin:toast_error__title'));
        },
        onError: (error: any) => {
            toast.error(error.message, t('admin:toast_error__title'));
        },
        onSettled: () => resetForm(),
    });

    const resetForm = () => {
        setOrg(undefined);
        setLangs({});
    };

    const submitForm = async () => {
        await mutateAsync({
            org: org?.value || '',
            lang: lang || '',
        });
    };

    const isFormValid = () => !!org && !!lang;
    return (
        <AdminPage
            dataTrackId="demo_creator__page"
            title={t('admin:page_demo_creator__title')}
            description={t('admin:page_demo_creator__description')}
            infoLink="https://partoo.elium.com/tile/view/1549"
        >
            <BorderedContainer>
                <PageFormRow>
                    <OrganizationSelect
                        dataTrackId="demo-creator-organization"
                        org={org}
                        setOrg={setOrg}
                        placeholder={t('admin:organization')}
                    />
                    <Filters
                        dataTrackId="admin_demo_creator__select_language"
                        menuListArray={section}
                        selectedItems={langs}
                        onChange={setLangs}
                        placeholder={t('admin:page_demo_creator__select_language')}
                        position="bottom"
                        maxHeight={300}
                        required
                    />
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin_demo_creator__create"
                disabled={!isFormValid() || isLoading}
                text={t('admin:page_demo_creator__button_create')}
                onClick={submitForm}
            />
        </AdminPage>
    );
};

export default DemoCreator;
