import React from 'react';

const DesktopIcon = React.forwardRef<SVGSVGElement>((_, ref) => (
    <svg
        ref={ref}
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M19.5 4H4.5C3.65625 4 3 4.6875 3 5.5V15.5C3 16.3438 3.65625 17 4.5 17H10.5L10 18.5H7.75C7.3125 18.5 7 18.8438 7 19.25C7 19.6875 7.3125 20 7.75 20H16.25C16.6562 20 17 19.6875 17 19.25C17 18.8438 16.6562 18.5 16.25 18.5H14L13.5 17H19.5C20.3125 17 21 16.3438 21 15.5V5.5C21 4.6875 20.3125 4 19.5 4ZM19 13H5V6H19V13Z" />
    </svg>
));

export default DesktopIcon;
