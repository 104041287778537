import { FilteredBusinessData } from 'app/api/types/business';
import { BusinessSearchParams } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { parseSearchParams } from 'app/common/components/businessModalDeprecated/parseSearchParams';

export const SELECT_MODE = 'select';
export type FilterMode = 'select' | 'unselect';

// Const
export const APPLY_FILTER_BY_BUSINESS_FILTERS = 'APPLY_FILTER_BY_BUSINESS_FILTERS';
const RESET_BUSINESS_FILTER = 'RESET_BUSINESS_FILTER';

const SET_BUSINESS_FILTERS = 'SET_BUSINESS_FILTERS';
const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS';

// Type
type ResetBusinessFilterAction = {
    type: typeof RESET_BUSINESS_FILTER;
};
type SetBusinessFiltersAction = {
    type: typeof SET_BUSINESS_FILTERS;
    payload: Partial<FilteredBusinessData>;
};
type SetSearchFiltersAction = {
    type: typeof SET_SEARCH_FILTERS;
    searchFilters: BusinessSearchParams;
};
type ApplyFilterByBusinessFiltersAction = {
    type: typeof APPLY_FILTER_BY_BUSINESS_FILTERS;
};

type FilterByBusinessAction =
    | ApplyFilterByBusinessFiltersAction
    | ResetBusinessFilterAction
    | SetBusinessFiltersAction
    | SetSearchFiltersAction;

// Actions
export const setBusinessFilters = (
    payload: Partial<FilteredBusinessData>,
): SetBusinessFiltersAction => ({
    type: SET_BUSINESS_FILTERS,
    payload,
});
export const setSearchFilters = (searchFilters: BusinessSearchParams): SetSearchFiltersAction => ({
    type: SET_SEARCH_FILTERS,
    searchFilters,
});
export const applyFilterByBusinessFilters = (): ApplyFilterByBusinessFiltersAction => ({
    type: APPLY_FILTER_BY_BUSINESS_FILTERS,
});
export const resetBusinessFilter = (): ResetBusinessFilterAction => ({
    type: RESET_BUSINESS_FILTER,
});

// STATE FLOW TYPE
export type FilterByBusinessState = {
    filteredBusinesses: FilteredBusinessData & {
        pending: FilteredBusinessData;
    };
    searchFilters: BusinessSearchParams;
};

const generateInitialState = (): FilterByBusinessState => {
    // the url search params here define the initial state,
    // if a search param is not present, it is parsed as its
    // corresponding empty value (for example [] for arrays)
    const { mode, includedIds, excludedIds, queries, cities, groupIdIn, orgs } = parseSearchParams(
        window.location,
    );

    return {
        filteredBusinesses: {
            mode,
            includedIds,
            excludedIds,
            queries,
            cities,
            orgs,
            groupIdIn,
            groupIsNull: true,
            searchCount: 0,
            pending: {
                mode,
                includedIds,
                excludedIds,
                queries,
                cities,
                orgs,
                groupIdIn,
                groupIsNull: true,
                searchCount: 0,
            },
        },
        searchFilters: {},
    };
};

// INITIAL STATE
export const initialState: FilterByBusinessState = generateInitialState();

const resetState: FilterByBusinessState = {
    ...initialState,
    filteredBusinesses: {
        ...initialState.filteredBusinesses,
        mode: 'select',
        includedIds: [],
        excludedIds: [],
        queries: [],
        cities: [],
        orgs: [],
        groupIdIn: [],
        pending: {
            ...initialState.filteredBusinesses.pending,
            mode: 'select',
            includedIds: [],
            excludedIds: [],
            queries: [],
            cities: [],
            orgs: [],
            groupIdIn: [],
        },
    },
};

// REDUCER
const filterByBusinessReducer = (
    state: FilterByBusinessState = initialState,
    action: FilterByBusinessAction,
): FilterByBusinessState => {
    switch (action.type) {
        // (new) business modal Redux backport
        // this puts the local state from the modal into redux state (including pending)
        case SET_BUSINESS_FILTERS:
            return {
                ...state,
                filteredBusinesses: {
                    ...state.filteredBusinesses,
                    ...action.payload,
                    pending: {
                        ...state.filteredBusinesses.pending,
                        ...action.payload,
                    },
                },
            };
        // Specific to the business modal
        // Set the page related business search filters
        case SET_SEARCH_FILTERS:
            return {
                ...state,
                searchFilters: action.searchFilters,
            };

        case APPLY_FILTER_BY_BUSINESS_FILTERS:
            return {
                ...state,
                filteredBusinesses: {
                    ...state.filteredBusinesses,
                    ...state.filteredBusinesses.pending,
                },
            };

        case RESET_BUSINESS_FILTER:
            return {
                ...state,
                filteredBusinesses: resetState.filteredBusinesses,
            };

        default:
            return state;
    }
};

// SELECTORS
export const groupIdInSelector = ({ filteredBusinesses: { groupIdIn } }: FilterByBusinessState) =>
    groupIdIn;

export const includedIdsSelector = ({
    filteredBusinesses: { includedIds },
}: FilterByBusinessState): string | null =>
    includedIds.length ? includedIds.join(',') || null : null;

export const excludedIdsSelector = ({
    filteredBusinesses: { excludedIds },
}: FilterByBusinessState): string | null =>
    excludedIds.length ? excludedIds.join(',') || null : null;

export const querySelector = ({
    filteredBusinesses: { queries },
}: FilterByBusinessState): string | null => queries.join(',') || null;

export const citySelector = ({
    filteredBusinesses: { cities },
}: FilterByBusinessState): string | null => cities.join(',') || null;

export const modeSelector = ({ filteredBusinesses: { mode } }: FilterByBusinessState): string =>
    mode;

export default filterByBusinessReducer;
