import { AuthToken, Business, Organization, Provider, Review, User } from '@partoohub/api-client';

import {
    AdministrativeRegions,
    BulkExportAdminApiCall,
    BulkExportV1ApiCall,
    BulkFoodMenuApiCall,
    BulkImportAdminApiCall,
    BulkImportV1,
    CompetitiveBenchmark,
    DiffusionApiCall,
    IntegrationsApiCall,
    ReviewAdminApiCall,
} from './resources';

// CLIENT API ENDPOINTS
const BASE_URL = `https://${window.location.host}/api/v2`;

export const authTokenApiClient = new AuthToken(BASE_URL);
export const businessApiClient = new Business(BASE_URL);
export const organizationApiClient = new Organization(BASE_URL);
export const providerApiClient = new Provider(BASE_URL);
export const reviewApiClient = new Review(BASE_URL);
export const userApiClient = new User(BASE_URL);

// ADMIN API ENDPOINTS
const ADMIN_BASE_URL = `https://${window.location.host}/admin`;

export const administrativeRegionsApiClient = new AdministrativeRegions(ADMIN_BASE_URL);
export const bulkExportV1ApiClient = new BulkExportV1ApiCall(ADMIN_BASE_URL);
export const bulkExportApiClient = new BulkExportAdminApiCall(ADMIN_BASE_URL);
export const bulkFoodMenuApiClient = new BulkFoodMenuApiCall(ADMIN_BASE_URL);
export const bulkImportApiClient = new BulkImportAdminApiCall(ADMIN_BASE_URL);
export const bulkImportV1ApiClient = new BulkImportV1(ADMIN_BASE_URL);
export const competitiveBenchmarkApiClient = new CompetitiveBenchmark(ADMIN_BASE_URL);
export const diffusionApiClient = new DiffusionApiCall(ADMIN_BASE_URL);
export const reviewAdminApiClient = new ReviewAdminApiCall(ADMIN_BASE_URL);
export const integrationsApiClient = new IntegrationsApiCall(ADMIN_BASE_URL);
