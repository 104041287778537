import { useTranslation } from 'react-i18next';

import { DesktopIcon } from 'admin/common/components/icons/DesktopIcon';
import { MobileIcon } from 'admin/common/components/icons/MobileIcon';

import { IconWrapper, OptionWrapper, SwitchInput } from './PlatformTargetSwitch.styled';

type Props = {
    value: string;
    setValue: (value: string) => void;
};

export const PlatformTargetSwitch = ({ value, setValue }: Props) => {
    const { t } = useTranslation();

    return (
        <SwitchInput>
            <OptionWrapper selected={value === 'desktop'} onClick={() => setValue('desktop')}>
                <IconWrapper>
                    <DesktopIcon />
                </IconWrapper>
                {t('admin:page_connect_as__desktop_link')}
            </OptionWrapper>
            <OptionWrapper selected={value === 'mobile'} onClick={() => setValue('mobile')}>
                <IconWrapper>
                    <MobileIcon />
                </IconWrapper>
                {t('admin:page_connect_as__mobile_link')}
            </OptionWrapper>
        </SwitchInput>
    );
};
