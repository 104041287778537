import { useEffect, useState } from 'react';

import {
    DatePickerType,
    Option,
    OverrideDatePicker,
    Radio,
    RangeDate,
    Text,
    toast,
} from '@partoohub/ui';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ReviewTreatmentAdminApiCall } from 'app/api/v2/api_calls/reviewTreatmentApiCalls';

import BusinessSelect from 'admin/common/components/inputs/BusinessSelect';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import {
    PageFormRow,
    PageFormSubtitle,
} from 'admin/common/components/templates/AdminPage/AdminPage.styled';

import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

import { DEFAULT_LOCALE_DATE_PICKER } from 'admin/common/data/locale';
import { DATE_FORMAT } from 'admin/common/utils/dates';

import { FilterContainer } from './BulkReviewTreatment.styled';

type ReviewType = 'all' | 'only_with_comment' | 'only_without_comment';

export const BulkReviewTreatment: React.FC = () => {
    const { t } = useTranslation();
    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [selectedBusinesses, setSelectedBusinesses] = useState<Array<Option>>([]);
    const [selectedDates, setSelectedDates] = useState<RangeDate>([null, null]);
    const [selectedReviewType, setSelectedReviewType] = useState<ReviewType>('all');

    const selectedOrgId =
        org?.value && typeof parseInt(org?.value, 10) === 'number' ? parseInt(org.value, 10) : 0;
    useEffect(() => {
        setSelectedBusinesses([]);
    }, [selectedOrgId]);

    const { mutateAsync, isLoading } = useMutation(
        ReviewTreatmentAdminApiCall.submitBulkReviewTreatmentCall,
        {
            onSuccess: () => {
                toast.success(
                    t('admin:page_tools_reviews_treatment__success_toast__content'),
                    t('admin:page_tools_reviews_treatment__success_toast__title'),
                );
            },
            onError: (error: any) => {
                toast.error(error.message, t('admin:toast_error__title'));
            },
            onSettled: () => {
                setOrg(undefined);
                setSelectedBusinesses([]);
                setSelectedDates([null, null]);
                setSelectedReviewType('all');
            },
        },
    );

    const canSubmit = !!selectedOrgId && !!selectedDates[0] && !isLoading;
    const onSubmit = async () => {
        await mutateAsync({
            org_id: selectedOrgId,
            ...(selectedBusinesses.length
                ? {
                      business_ids: selectedBusinesses.map(element => element.value),
                  }
                : {}),
            date: selectedDates[0] ? `${format(selectedDates[0], DATE_FORMAT)}T12:00:00` : '',
            reviewType: selectedReviewType,
        });
    };

    return (
        <AdminPage
            dataTrackId="page_tools_reviews"
            title={t('admin:page_tools_reviews_treatment__title')}
            description={t('admin:page_tools_reviews_treatment__description')}
            infoLink={
                'https://partoo.elium.com/tile/view/279/#06fd2392-6101-42da-97af-169412b99583'
            }
        >
            <BorderedContainer>
                <PageFormRow>
                    <FilterContainer>
                        <NewOrganizationSelect
                            dataTrackId="page_tools_reviews__filter_organization"
                            orgs={org}
                            setOrgs={setOrg}
                            placeholder={t('admin:organization')}
                        />
                    </FilterContainer>
                </PageFormRow>
                <PageFormRow>
                    <FilterContainer>
                        <BusinessSelect
                            dataTrackId="page_tools_reviews__filter_business"
                            businesses={selectedBusinesses}
                            placeholder={t('businesses')}
                            setBusinesses={setSelectedBusinesses}
                            searchBusinessParams={selectedOrgId ? { org_id: selectedOrgId } : {}}
                            isMulti
                        />
                    </FilterContainer>
                </PageFormRow>
                <PageFormRow>
                    <OverrideDatePicker
                        dataTrackId="page_tools_reviews__select_date"
                        type={DatePickerType.DateSelector}
                        labels={{ startDate: t('date') }}
                        placeholders={{ startDate: t('date_placeholder') }}
                        dates={selectedDates}
                        onChange={setSelectedDates}
                        localeDatePicker={DEFAULT_LOCALE_DATE_PICKER}
                    />
                </PageFormRow>
                <PageFormRow>
                    <div>
                        <PageFormSubtitle>
                            {t('admin:page_tools_reviews_treatment__subtitle_mark_treated')}
                        </PageFormSubtitle>
                        <div>
                            {['all', 'only_with_comment', 'only_without_comment'].map(label => (
                                <Radio
                                    dataTrackId={`bulk_review_treatment__${label}__radio`}
                                    key={label}
                                    name={label}
                                    value={label}
                                    checked={selectedReviewType === label}
                                    onChange={() => setSelectedReviewType(label as ReviewType)}
                                >
                                    <Text variant="bodyMBold" as="span" color="secondary">
                                        {t(label)}
                                    </Text>
                                </Radio>
                            ))}
                        </div>
                    </div>
                </PageFormRow>
            </BorderedContainer>

            <SubmitButton
                dataTrackId="admin_reviews_treatment__submit"
                disabled={!canSubmit}
                text={t('admin:page_tools_reviews_treatment__button_submit')}
                onClick={onSubmit}
            />
        </AdminPage>
    );
};
