import { Chip, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

interface CopyChipProps {
    audioFileUrl: string;
}

export const CopyChip = ({ audioFileUrl }: CopyChipProps) => {
    const { t } = useTranslation();

    const handleChipClick = () => {
        if (audioFileUrl) {
            navigator.clipboard.writeText(audioFileUrl);
            toast.success(
                t('admin:page__call_deflection_audio_upload__copy__success_toast__content'),
                t('admin:page__call_deflection_audio_upload__copy__success_toast__title'),
            );
        }
    };

    return (
        <div
            data-track={'admin__call_deflection_audio_upload'}
            data-intercom-targert={'admin__call_deflection_audio_upload'}
        >
            <Chip
                dataTrackId="admin__call_deflection_audio_upload__chip"
                disabled={!audioFileUrl}
                iconAction={<i className="fas fa-copy" />}
                onClick={handleChipClick}
            >
                <Stack gap="4px" direction="row" alignItems="center">
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCopy, IconPrefix.SOLID]}
                        iconSize="12px"
                        color="primary"
                    />
                    {t('admin:copy')}
                </Stack>
            </Chip>
        </div>
    );
};
