import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

type SideMenuWrapperProps = {
    open: boolean;
};

export const SideMenuWrapper = styled.div<SideMenuWrapperProps>`
    padding: 32px 0px 32px 16px;

    width: 0px;
    min-width: 0px;
    overflow-x: hidden;
    background-color: ${greyColorObject.light};

    transition:
        width 0.3s,
        min-width 0.3s,
        padding 0.3s;

    ${props =>
        props.open &&
        css`
            padding: 32px 32px 32px 16px;

            width: 246px;
            min-width: 246px;
        `}
`;

export const SideMenuTitle = styled.div`
    display: flex;
    gap: 8px;

    margin-bottom: 32px;

    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    white-space: nowrap;
`;

export const SideMenuContent = styled.div``;
