import { useQueries } from 'react-query';

import { userInfosApiClient } from 'admin/api/apiResources';
import { MULTI_ORGANIZATIONS } from 'admin/common/data/queryKeysConstants';

export const useUsersByUserInfoIds = (userInfoIds: Array<number>) => {
    return useQueries(
        userInfoIds.map((userInfoId: number) => ({
            queryKey: [MULTI_ORGANIZATIONS, userInfoId],
            queryFn: () => userInfosApiClient.getUsersByUserInfoId(userInfoId),
            staleTime: Infinity,
        })),
    );
};
