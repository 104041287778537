import { CategoriesData, CategoriesFilter, SuccessResponse } from '@partoohub/api-client';
import { ApiResourceBaseClass } from '@partoohub/api-client';
import { getQueryStringFromObject } from '@partoohub/utils';

import { generateQueryParamsString } from 'app/api/v2/api_calls/utils';

import {
    CategoriesOnPublishers,
    CategoriesStats,
    CategoryDetailData,
    CategoryDetailFilter,
    CategoryOnPublisherFilter,
    EditCategoryPayload,
    RemapCategoryPayload,
} from 'admin/api/types/categories';

const CATEGORIES_RESOURCE_URI = 'categories';

export class CategoriesApiCall extends ApiResourceBaseClass {
    async getCategoriesDetails(
        queryFilter: CategoriesFilter = {},
        page = 1,
    ): Promise<CategoriesData> {
        const queryParams = getQueryStringFromObject({ ...queryFilter, page });
        const { data } = await this.get<CategoriesData>(
            `${CATEGORIES_RESOURCE_URI}/details?${queryParams}`,
        );
        return data;
    }

    async getCategoryDetails(
        categoryId: number,
        queryFilter: CategoryDetailFilter = {},
    ): Promise<CategoryDetailData> {
        const queryParams = getQueryStringFromObject({ ...queryFilter });
        const { data } = await this.get<CategoryDetailData>(
            `${CATEGORIES_RESOURCE_URI}/${categoryId}/details?${queryParams}`,
        );
        return data;
    }

    async editCategory(
        categoryId: number,
        payload: EditCategoryPayload,
    ): Promise<CategoryDetailData> {
        const { data } = await this.put<CategoryDetailData>(
            `${CATEGORIES_RESOURCE_URI}/${categoryId}/details`,
            payload,
        );
        return data;
    }

    async getCategoriesOnPublishers(
        queryFilter: CategoryOnPublisherFilter = {},
        page = 1,
    ): Promise<CategoriesOnPublishers> {
        const queryParams = getQueryStringFromObject({ ...queryFilter, page });
        const { data } = await this.get<CategoriesOnPublishers>(
            `publishers_categories?${queryParams}`,
        );
        return data;
    }

    async postCategoriesRemap(categoriesToRemap: RemapCategoryPayload): Promise<SuccessResponse> {
        const { data } = await this.post<SuccessResponse>(
            `${CATEGORIES_RESOURCE_URI}/remap`,
            categoriesToRemap,
        );
        return data;
    }

    async exportCategories(queryParams: CategoriesFilter): Promise<any> {
        const { data } = await this.get<any>(
            `${CATEGORIES_RESOURCE_URI}/export?${generateQueryParamsString(queryParams)}`,
        );
        return data;
    }

    async getCategoriesStats(): Promise<CategoriesStats> {
        const { data } = await this.get<CategoriesStats>(`${CATEGORIES_RESOURCE_URI}/stats`);
        return data;
    }
}
