export const PARTOOROBOT_SIDEBAR_LOGO = 'sidebar_logo';
export const PARTOOROBOT_SIDEBAR_BUSINESSES = 'sidebar_businesses';
export const PARTOOROBOT_SIDEBAR_REVIEWS = 'sidebar_reviews';
export const PARTOOROBOT_SIDEBAR_HOMEPAGE = 'sidebar_homepage';
export const PARTOOROBOT_SIDEBAR_POSTS = 'sidebar_posts';
export const PARTOOROBOT_SIDEBAR_MESSAGES = 'sidebar_messages';
export const PARTOOROBOT_SIDEBAR_ANALYTICS = 'sidebar_analytics';
export const PARTOOROBOT_SIDEBAR_SETTINGS = 'sidebar_settings';
export const PARTOOROBOT_SIDEBAR_QUICK_SEARCH = 'sidebar_quick_search';
export const PARTOOROBOT_SIDEBAR_PARTNER_LINK = 'sidebar_partner_link';

export const PARTOOROBOT_TAB_ANALYTYCS_VISIBILITY = 'tab_analytics_visibility';
export const PARTOOROBOT_TAB_ANALYTYCS_REVIEWS = 'tab_analytics_reviews';
export const PARTOOROBOT_TAB_COMPETITIVE_BENCHMARK = 'tab_competitive_benchmark';
export const PARTOOROBOT_TAB_CE_REVIEWS = 'tab_ce_reviews';
export const PARTOOROBOT_TAB_CE_REVIEW_BOOSTER = 'tab_ce_review_booster';

export const PARTOOROBOT_SETTINGS_MAIN_CARD = 'settings_main_card';
export const PARTOOROBOT_SETTINGS_TEAM_CARD = 'settings_team_card';
export const PARTOOROBOT_SETTINGS_REVIEWS_CARD = 'settings_reviews_card';
export const PARTOOROBOT_SETTINGS_MESSAGES_CARD = 'settings_messages_card';
export const PARTOOROBOT_SETTINGS_PLATFORMS_CARD = 'settings_platforms_card';
export const PARTOOROBOT_SETTINGS_INTEGRATIONS_CARD = 'settings_integrations_card';

export const PARTOOROBOT_404_NOT_FOUND = '404_not_found';
