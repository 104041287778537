import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { EASINGS } from 'admin/common/data/constants/easings';

type DropdownProps = {
    open: boolean;
};

export const CollapsibleTitle = styled.div`
    font-weight: 700;
    width: fit-content;
    color: ${({ theme }) => theme.colors.secondary.initial};
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
`;

export const DropdownIcon = styled.span<DropdownProps>`
    margin-left: 4px;
    padding: 0px 4px;

    font-size: 12px;

    i {
        transition: transform 0.2s ${EASINGS.easeOutAndBack};
    }

    ${props =>
        !props.open ||
        css`
            i {
                transform: rotate(90deg);
            }
        `}
`;

export const CollapsibleContentContainer = styled.div<DropdownProps>`
    ${props =>
        props.open ||
        css`
            display: none;
        `}
`;

export const CollapsibleContent = styled.div`
    padding-top: 26px;
`;

export const GearContainer = styled.div`
    padding-right: 6px;
`;

export const TitleText = styled.p`
    margin-bottom: 0;
`;
