import { SuccessResponse } from 'app/api/types';
import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';

const RESOURCE_URI = 'diffusions/overriding_state';

type OverridingStateParameters = {
    publishers: Array<string>;
    state?: string;
    org_id?: string;
    provider?: string;
};
class OverridingStateApiCall extends AdminApiResourceBaseClass {
    static set(
        parameters: OverridingStateParameters,
    ): Promise<SuccessResponse> {
        return OverridingStateApiCall.post(`${RESOURCE_URI}`, {
            ...parameters,
        }).then(({ data }) => data);
    }
}

export default OverridingStateApiCall;
