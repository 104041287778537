import useReduxFilteredBusinesses from 'app/common/components/businessModal/hooks/useReduxFilteredBusinesses';

/**
 * Returns the total count of businesses in the Business Modal
 * Returns undefined if the query is not ready
 */
export default function useTotalBusinessesCount(): number | undefined {
    const { query } = useReduxFilteredBusinesses();
    return query?.data?.pages[0]?.count;
}
