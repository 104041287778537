import { useEffect } from 'react';

/**
 * Hook to hide intercom when the drawer is open
 */
export const useHideIntercom = (hideIntercom: boolean) => {
    useEffect(() => {
        // 2 possible intercoms depending on if already loaded or not
        const intercomLightweight: HTMLElement | null = document.querySelector(
            '[class=intercom-lightweight-app]',
        );
        const intercomFull: HTMLElement | null = document.querySelector(
            '[class=intercom-container]',
        );
        const intercom: HTMLElement | null = intercomFull || intercomLightweight;

        if (intercom) {
            intercom.style.transition = 'opacity ease 0.3s';

            if (hideIntercom) {
                intercom.style.opacity = '0';
                intercom.style.pointerEvents = 'none';
            } else {
                intercom.style.opacity = '1';
                intercom.style.pointerEvents = 'auto';
            }

            // Cleanup
            return () => {
                intercom.style.opacity = '1';
            };
        }
    }, [hideIntercom]);
};
