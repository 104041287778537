import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

export const OnPublishersFormContainer = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    margin-top: 32px;
`;

export const StyledSubtitle = styled(Text)`
    padding: 26px 24px;
    border-bottom: solid 1px ${greyColorObject.initial};
`;

export const OnPublisherFormContent = styled.div`
    padding: 8px 24px 0 24px;
`;
export const OnPublisherFormSelectBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
`;
export const OnPublisherFormIconNameContainer = styled.div`
    display: flex;
    height: 90px;
    padding: 17px 0;
    align-items: center;
    justify-content: center;
`;
export const OnPublisherFormSelect = styled.div`
    flex-grow: 2;
    margin-left: auto;
`;

export const OnPublisherFormIcon = styled.div`
    height: 24px;
    width: 24px;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

export const OnPublisherFormName = styled.div`
    width: 150px;
    margin: 0 32px 0 16px;
    font-weight: bold;
    text-transform: capitalize;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
`;
