import { SuccessResponse } from '@partoohub/api-client';

import {
    PaginatedMetricsResponse,
    ReviewAnalyticsDimension,
    ReviewAnalyticsFiltersType,
    ReviewAnalyticsMetric,
} from 'app/api/types/review_analytics';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'review_analytics';

export class ReviewAnalytics extends ApiResourceBaseClass {
    static async getMetrics<
        T extends ReviewAnalyticsDimension,
        U extends readonly ReviewAnalyticsMetric[],
    >(
        dimensions: T,
        metrics: U,
        filters: ReviewAnalyticsFiltersType,
    ): Promise<PaginatedMetricsResponse<T, U>> {
        const queryParams = {
            dimensions,
            metrics: metrics.join(','),
            ...filters,
        };
        const { data } = await ReviewAnalytics.get(`${RESOURCE_URI}/metrics`, queryParams);
        return data;
    }

    static async downloadMetrics<
        T extends ReviewAnalyticsDimension,
        U extends readonly ReviewAnalyticsMetric[],
    >(
        metrics: U,
        email: string,
        filters: ReviewAnalyticsFiltersType,
        dimensions?: T,
    ): Promise<SuccessResponse> {
        const queryParams = {
            dimensions,
            metrics: metrics.join(','),
            email,
            ...filters,
        };

        const { data } = await ReviewAnalytics.get(`${RESOURCE_URI}/metrics/download`, queryParams);
        return data;
    }
}
