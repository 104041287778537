import { useEffect, useState } from 'react';

import { Option } from '@partoohub/ui';
import { useQuery } from 'react-query';

import { useSearchParams } from 'react-router-dom';

import Organization from 'app/api/v2/api_calls/orgApiCalls';

import { ORGANIZATIONS } from 'admin/common/data/queryKeysConstants';

// this hook lets you add a state variable that contains an org value
// the value is automatically reflected in the URL like ?org_id=1234
// when a user directly goes to a URL with ?org_id=1234, the hook makes an API call to get the other org data like the name of the org
export const useOrg: () => [Option | undefined, (selectedOrg?: Option) => void] = () => {
    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [params, setParams] = useSearchParams();
    const orgId = params.get('org_id');

    const { data } = useQuery(
        [ORGANIZATIONS, orgId],
        () => {
            const orgIdInt = parseInt(orgId!);
            return Organization.getOrg(orgIdInt);
        },
        { enabled: orgId !== null },
    );
    const orgLabel = data?.name;

    useEffect(() => {
        // If we have an orgId but not a label in state, set it from the fetched data
        if (orgId && orgLabel) {
            setOrg({ value: orgId, label: `${orgLabel} (${orgId})`, name: '' });
        }
    }, [orgId, orgLabel]);

    const setOrgAndUpdateURL = (selectedOrg: Option | undefined) => {
        setOrg(selectedOrg);
        const newParams = new URLSearchParams(params);
        if (selectedOrg === undefined) {
            newParams.delete('org_id');
        } else {
            newParams.set('org_id', selectedOrg.value);
        }
        setParams(newParams);
    };

    return [org, setOrgAndUpdateURL];
};

export function useOrganization(orgId: string | undefined) {
    return useQuery([ORGANIZATIONS, orgId], () => Organization.getOrg(parseInt(orgId!)), {
        enabled: orgId !== undefined,
    });
}
