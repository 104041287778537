import {
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    LeftElementType,
    Option,
    SingleSelect,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import { searchDuplicatesApiClient } from 'admin/api/apiResources';
import { DUPLICATE_BUSINESS_SEARCH_PLACE_TYPES } from 'admin/common/data/queryKeysConstants';

type Props = {
    selectedValue?: Option;
    onChange: (v?: Option) => void;
};
export const PlaceTypeSelect = ({ selectedValue, onChange }: Props) => {
    const { t } = useTranslation();

    const { isSuccess, data } = useQuery(DUPLICATE_BUSINESS_SEARCH_PLACE_TYPES, () =>
        searchDuplicatesApiClient.getPlaceTypes(),
    );

    const options =
        data?.map(placeType => ({
            label: placeType,
            name: placeType,
            value: placeType,
        })) || [];
    return (
        <SingleSelect
            dataTrackId="admin_duplicate_businesses__select_place_type"
            sections={[{ options }]}
            selectedValue={selectedValue}
            onChange={onChange}
            label={t('admin:page_tools_clients_duplicates__place_type_input')}
            leftElementType={LeftElementType.Icon}
            leftElement={[FontAwesomeIconsPartooUsed.faStore, IconPrefix.REGULAR]}
            disabled={!isSuccess}
            maxHeight={300}
        />
    );
};
