import SDKBridge, { PARTOO_APP_EVENT_IDS } from './Bridge';
import withHideableComponent from './hideableComponent';
import {
    componentDisplaySelector,
    hideableComponentsNames,
    setHideableComponentDisplay,
} from './reducers';

const sdkBridge = new SDKBridge();

export {
    withHideableComponent,
    hideableComponentsNames,
    setHideableComponentDisplay,
    sdkBridge,
    componentDisplaySelector,
    PARTOO_APP_EVENT_IDS,
};
