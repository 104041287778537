import { ApiResourceBaseClass } from '@partoohub/api-client';

import { BulkReviewTreatmentFilter, BulkReviewTreatmentResponse } from 'admin/api/types';

const RESOURCE_URI = 'bulk_review_treated';

export class ReviewTreatmentAdminApiCall extends ApiResourceBaseClass {
    async submitBulkReviewTreatmentCall(
        filter: BulkReviewTreatmentFilter,
    ): Promise<BulkReviewTreatmentResponse> {
        const { data } = await this.post<BulkReviewTreatmentResponse>(RESOURCE_URI, filter);
        return data;
    }
}
