import { css } from '@emotion/react';

import { MAX_TABLET_WIDTH } from 'app/hooks';

export const BUSINESS_MODAL_BREAKPOINT = `max-width: ${MAX_TABLET_WIDTH}px`;

export const BUSINESS_MODAL_DRAWER_CSS = css`
    padding: 0;
    width: initial;

    @media (${BUSINESS_MODAL_BREAKPOINT}) {
        width: 100%;
    }
`;
