import { useEffect, useState } from 'react';

import { greyColorObject } from '@partoohub/branding';

import { FeedbackFormData } from 'app/api/types/feedbackForm';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import BorderedContainer from 'admin/common/components/layout/BorderedContainer';

import { Divider } from './SurveySection.styled';
import { CreateOrUpdateSurveyFullModal } from '../CreateOrUpdateSurveyFullModal';
import { SurveySectionContentList } from './SurveySectionContentList/SurveySectionContentList';
import { SurveySectionNoContent } from './SurveySectionNoContent/SurveySectionNoContent';
import { SurveySectionTitle } from './SurveySectionTitle/SurveySectionTitle';

interface Props {
    formList: FeedbackFormData[];
    orgId: number;
}

export const SurveySection = ({ formList, orgId }: Props) => {
    const [openSurveyFullModal, setOpenSurveyFullModal] = useStateQueryParams(
        'openingSurvey',
        false,
    );
    const isOpen = openSurveyFullModal === 'true';
    const [selectedSurvey, setSelectedSurvey] = useState<FeedbackFormData | undefined>(undefined);

    useEffect(() => {
        setOpenSurveyFullModal('false');
    }, []);
    const handleOpenSurveyFullModal = (survey?: FeedbackFormData) => {
        if (survey) setSelectedSurvey(survey);
        setOpenSurveyFullModal('true');
    };
    const handleCloseSurveyFullModal = () => setOpenSurveyFullModal('false');
    const hadForm = formList.length > 0;

    return (
        <BorderedContainer>
            <SurveySectionTitle {...(hadForm && { action: handleOpenSurveyFullModal })} />
            <Divider color={greyColorObject.initial} />
            {hadForm ? (
                <SurveySectionContentList formList={formList} onEdit={handleOpenSurveyFullModal} />
            ) : (
                <SurveySectionNoContent addSurvey={handleOpenSurveyFullModal} />
            )}
            <CreateOrUpdateSurveyFullModal
                orgId={orgId}
                show={isOpen}
                onHideClick={handleCloseSurveyFullModal}
                {...(selectedSurvey && { survey: selectedSurvey })}
            />
        </BorderedContainer>
    );
};
