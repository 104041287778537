import styled from '@emotion/styled';

export const Chip = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 500px;
    padding: 2.5px 5px;

    color: #ffffff;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
`;

export const BetaChipStyled = styled(Chip)`
    background: linear-gradient(
        270deg,
        #c182ff -30.25%,
        #8a5df2 51.3%,
        #533891 134.57%
    );
`;

export const NewChipStyled = styled(Chip)`
    background: linear-gradient(
        270deg,
        #00baff -30.25%,
        #0085f2 51.3%,
        #005091 134.57%
    );
`;

export const LogoChipStyled = styled(Chip)`
    background: #142542;
    justify-content: center;
    transform: translateY(-35%);
    font-size: 12px;
    line-height: 14px;
`;
