import { V2FormattedDiffusionReceivedData } from 'app/api/types/diffusion';
import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

const RESOURCE_URI = 'diffusions';

export type DiffusionFilter = BusinessModalFiltersType & {
    publisher__in: string | null;
    diffuser__in: string | null;
    state__in: string | null;
};

class Diffusion extends AdminApiResourceBaseClass {
    static getDiffusion(
        filter: DiffusionFilter,
    ): Promise<V2FormattedDiffusionReceivedData> {
        return Diffusion.get(`${RESOURCE_URI}/list`, {
            ...filter,
        }).then(({ data }) => data);
    }
}

export default Diffusion;
