import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import BetaChip from 'admin/common/components/chips/BetaChip';
import NewChip from 'admin/common/components/chips/NewChip';
import { useIsSideMenuOpen } from 'admin/common/contexts/isSideMenuOpenContext';
import {
    CALL_DEFLECTION_PATH,
    GOOGLE_ACCOUNT_REFRESH_PATH,
    GOOGLE_ADMIN_MANAGEMENT_PATH,
    REVIEW_CLEANER_PATH,
    USER_ACTIONS_PATH,
} from 'admin/common/data/routeIds';

import { SideMenuContent, SideMenuTitle, SideMenuWrapper } from './Menus.styled';
import MenuLink from '../MenuLink';

const PlaygroundMenu: FC = () => {
    const { t } = useTranslation();
    const { isSideMenuOpen } = useIsSideMenuOpen();

    return (
        <>
            <SideMenuWrapper open={isSideMenuOpen}>
                <SideMenuTitle>
                    {t('admin:side_menu_playground__title')} <BetaChip />
                </SideMenuTitle>

                <SideMenuContent>
                    <MenuLink
                        to={USER_ACTIONS_PATH}
                        name={
                            <>
                                {t('admin:side_menu_playground__section_user_actions')}
                                <NewChip />
                            </>
                        }
                    />
                    <MenuLink
                        to={GOOGLE_ACCOUNT_REFRESH_PATH}
                        name={t('admin:side_menu_playground__section_google_account_refresh')}
                    />
                    <MenuLink
                        to={REVIEW_CLEANER_PATH}
                        name={t('admin:side_menu_playground__section_review_cleaner')}
                    />
                    <MenuLink
                        to={CALL_DEFLECTION_PATH}
                        name={t('admin:side_menu_playground__section_call_deflection')}
                    />
                    <MenuLink
                        to={GOOGLE_ADMIN_MANAGEMENT_PATH}
                        name={
                            <>
                                {t('admin:side_menu_playground__section_google_administration')}
                                <NewChip />
                            </>
                        }
                    />
                </SideMenuContent>
            </SideMenuWrapper>
            <Outlet />
        </>
    );
};

export default PlaygroundMenu;
