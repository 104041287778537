import React, { useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Button, Modal, NumberInput, Stack, Text, TextArea, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { servicesTypeEnum } from 'app/api/types/services';

import {
    ButtonsWrapper,
    ModalSubtitle,
    ModalTitle,
    ModalWrapper,
    StructuredServiceName,
} from './modal.styled';
import { ServiceModalFormType } from './utils';

export type AddServiceModalDataType = {
    serviceType: string;
    serviceName: string;
    serviceId?: number;
    serviceDescription?: string;
    servicePrice?: string;
};

type Props = {
    show: boolean;
    setServices: React.Dispatch<React.SetStateAction<Array<ServiceModalFormType>>>;
    services: Array<ServiceModalFormType>;
    handleClose: () => void;
    data: AddServiceModalDataType;
    currency: string;
};

export const AddServiceModal = ({
    show,
    handleClose,
    setServices,
    services,
    data,
    currency,
}: Props) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<ServiceModalFormType>({
        name: '',
        description: '',
        price: '',
        type: '',
        serviceId: undefined,
    });
    const [serviceWithSameNameAlreadyExist, setServiceWithSameNameAlreadyExist] =
        useState<boolean>(false);

    const addService = () => {
        const nameAlreadyExists = services.some(service => service.name === formData.name);
        if (nameAlreadyExists) {
            setServiceWithSameNameAlreadyExist(true);
            return;
        }
        const queryParams = {
            name:
                data.serviceType === servicesTypeEnum.STRUCTURED_SERVICE_ITEM
                    ? data.serviceName
                    : formData.name!,
            description: formData.description,
            price: formData.price.replace(',', '.'),
            type: data.serviceType,
            serviceId:
                data.serviceType === servicesTypeEnum.STRUCTURED_SERVICE_ITEM
                    ? data.serviceId
                    : undefined,
        };
        setServices([...services, queryParams]);
        close();
    };

    const close = () => {
        resetForm();
        handleClose();
    };

    function removeDuplicateCharacters(string: string) {
        return string
            .split('')
            .filter((item, pos, self) => self.indexOf(item) === pos || item !== ',')
            .join('');
    }

    const setOnChange = (value?: string) => {
        setFormData({
            ...formData,
            price: value ? removeDuplicateCharacters(value.replace(/[^\d,]/g, '')) : '',
        });
    };

    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            price: '',
            type: '',
            serviceId: undefined,
        });
        setServiceWithSameNameAlreadyExist(false);
    };

    const isFormInvalid = (): boolean =>
        (data.serviceType === servicesTypeEnum.FREE_FORM_SERVICE_ITEM && !formData.name) ||
        formData.description.length > 300 ||
        (Number.isNaN(parseFloat(formData.price)) && formData.price !== '') ||
        (data.serviceType === servicesTypeEnum.FREE_FORM_SERVICE_ITEM &&
            formData.name?.replace(/\s/g, '').length === 0);

    return (
        <Modal isOpen={show} closeModal={close}>
            <ModalWrapper>
                <ModalTitle>{t('add_service_modal_title')}</ModalTitle>
                <ModalSubtitle>{t('add_service_modal_subtitle')}</ModalSubtitle>
                <div>
                    {data.serviceType === servicesTypeEnum.STRUCTURED_SERVICE_ITEM && (
                        <StructuredServiceName>
                            <Text variant="bodySBold" color={HEX_COLORS.secondary}>
                                {t('service_name')}
                            </Text>
                            <Text variant="heading6">{data.serviceName}</Text>
                        </StructuredServiceName>
                    )}
                    <Stack>
                        {data.serviceType === servicesTypeEnum.FREE_FORM_SERVICE_ITEM && (
                            <TextInput
                                dataTrackId="bulk_service_create__edit_modal__service_name"
                                label={t('service_name')}
                                required
                                value={formData.name}
                                onChange={value => {
                                    setFormData({
                                        ...formData,
                                        name: value || '',
                                    });
                                }}
                                error={serviceWithSameNameAlreadyExist}
                                notice={
                                    serviceWithSameNameAlreadyExist
                                        ? t('error_adding_service_already_exists_toast_description')
                                        : undefined
                                }
                            />
                        )}
                        <NumberInput
                            dataTrackId="bulk_service_create__edit_modal__service_price"
                            unit={`${currency}`}
                            label={`${t('service_price')} (${currency})`}
                            min={0}
                            value={formData.price.replace('.', ',')}
                            onChange={value => setOnChange(value)}
                        />
                        <TextArea
                            dataTrackId="bulk_service_create__edit_modal__service_description"
                            label={t('service_description')}
                            placeholder={t('service_description')}
                            rows={5}
                            maxRows={10}
                            characterCount={300}
                            value={formData.description}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    description: value,
                                });
                            }}
                        />
                    </Stack>
                </div>

                <ButtonsWrapper direction="row" gap="0.5rem">
                    <Button
                        dataTrackId="bulk_service_create__edit_modal__cancel"
                        appearance="outlined"
                        variant="secondary"
                        onClick={close}
                        size="large"
                        full
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        dataTrackId="bulk_service_create__edit_modal__validate"
                        variant="primary"
                        shape="cube"
                        size="large"
                        disabled={isFormInvalid()}
                        onClick={addService}
                        full
                    >
                        {t('validate')}
                    </Button>
                </ButtonsWrapper>
            </ModalWrapper>
        </Modal>
    );
};
