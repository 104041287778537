import { Button, FontAwesomeIconsPartooUsed, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type Props = {
    action?: () => void;
};
export const SurveySectionTitle = ({ action }: Props) => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <div>
                <Text variant="heading6">{t('admin:feedback_management__surveys')}</Text>
                <Text variant="bodyMRegular" color="secondary" as="span">
                    {t('admin:feedback_management__add_configure_surveys')}
                </Text>
            </div>
            {action && (
                <Button
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.REGULAR]}
                    onClick={action}
                    dataTrackId="feedback_management__add_survey_button"
                >
                    {t('admin:feedback_management__add_survey_button')}
                </Button>
            )}
        </Stack>
    );
};
