import { GetScopesParams, GetScopesResponse } from 'app/api/types/scopes';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'scopes';

class Scopes extends ApiResourceBaseClass {
    static async getScopes(
        queryParams: GetScopesParams,
    ): Promise<GetScopesResponse> {
        const { data } = await Scopes.get(`${RESOURCE_URI}`, queryParams);
        return data;
    }
}

export default Scopes;
