import { CustomFile, Dropzone, DropzoneTypeEnum } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type DropZoneProps = {
    handleDropSuccess: (files: CustomFile[]) => void;
    deletePhoto: () => void;
    files: CustomFile[];
};

export const FoodMenuDropzone = ({ handleDropSuccess, deletePhoto, files }: DropZoneProps) => {
    const { t } = useTranslation();

    return (
        <Dropzone
            title=""
            files={files}
            onDropSuccess={handleDropSuccess}
            onDelete={deletePhoto}
            accept={{
                ['image/png']: ['.png'],
                ['image/jpeg']: ['.jpg', '.jpeg'],
            }}
            acceptedDescription="PNG, JPEG"
            type={DropzoneTypeEnum.Photo}
            multiple={false}
            maxSize={1_000_000_000} // 1Go
            full
        >
            {t('menu_photo_dropzone_label')}
        </Dropzone>
    );
};
