import styled from '@emotion/styled';

export const FloatingFooter = styled.div`
    flex-shrink: 0;
    position: sticky;
    height: 76px;
    display: flex;

    padding: 8px;
    border-top: 1px solid rgba(20, 37, 66, 0.12);
    gap: 8px;
`;
