import { Checkbox, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { CategoryDetailData } from 'app/api/types/categories';

import {
    CategorySettingsLegacyBox,
    CheckboxContainer,
    EditCategorySettingsContainer,
    StyledSubtitle,
    StyledTitle,
} from './EditCategorySettings.styled';

type Props = {
    category: CategoryDetailData;
    isChecked: boolean;
    onClickCheckBox: (value: boolean) => void;
};

export const EditCategorySettings = ({ isChecked, category, onClickCheckBox }: Props) => {
    const { t } = useTranslation();
    return (
        <EditCategorySettingsContainer>
            <StyledTitle variant="heading6">{t('admin_categories_edit_parameters')}</StyledTitle>
            <CheckboxContainer>
                <Checkbox
                    dataTrackId=""
                    checked={isChecked}
                    onChange={() => onClickCheckBox(!isChecked)}
                >
                    <Text variant="bodyMBold" as="span" color="secondary">
                        Block Push Long Description On GMB
                    </Text>
                </Checkbox>
            </CheckboxContainer>
            <StyledSubtitle>
                <span>{t('admin_categories_edit_remaped_ids')}</span>
            </StyledSubtitle>
            <CategorySettingsLegacyBox>
                {category.deprecated_categories && category.deprecated_categories.length ? (
                    category.deprecated_categories.map(deprecated => (
                        <Text variant="bodyMBold" as="span" key={deprecated.gmb_id}>
                            {deprecated.gmb_id}
                        </Text>
                    ))
                ) : (
                    <Text variant="bodyMBold" as="span" color="secondary">
                        {t('admin_categories_no_source_cat_mapped')}
                    </Text>
                )}
            </CategorySettingsLegacyBox>
        </EditCategorySettingsContainer>
    );
};
