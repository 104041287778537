import { useState } from 'react';

import {
    Chip,
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    List,
    Text,
    toast,
} from '@partoohub/ui';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import { GoogleAdminListingData } from 'app/api/types/googleAdminManagement';
import api from 'app/api/v2/api_calls';

const getStatusVariant = (status: string) => {
    switch (status) {
        case 'accepted':
            return 'success';
        case 'PENDING':
            return 'primary';
        case 'created':
        case 'creation_failed':
        case 'acceptation_failed':
            return 'warning';
        default:
            return 'secondary';
    }
};

type GoogleAdminListingRowProps = {
    googleAdminListing: GoogleAdminListingData;
};

const GoogleAdminListingRow = ({ googleAdminListing }: GoogleAdminListingRowProps) => {
    const { t } = useTranslation();
    const [isAcceptClicked, setIsAcceptClicked] = useState<boolean>(false);
    const acceptInvitation = useMutation(
        () =>
            api.googleAdminManagement.acceptInvitation(
                googleAdminListing.google_location_id,
                googleAdminListing.invitation_name,
            ),
        {
            onSuccess: () => {
                toast.loading(
                    t('admin:slap__toast_start_accept_description'),
                    t('admin:slap__toast_start_accept_title'),
                );
            },
            onError: () => {
                toast.error(t('admin:slap__toast_error_description'), t('error'));
            },
        },
    );

    return (
        <List.Row dataTrackId="slap_administration_row" id={googleAdminListing.id}>
            <List.Cell>
                <Text variant="bodyMRegular">
                    {format(googleAdminListing.created_at, 'yyyy-MM-dd HH:mm:ss')}
                </Text>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{googleAdminListing.google_location_name}</Text>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{googleAdminListing.google_location_id}</Text>
            </List.Cell>
            <List.Cell>
                <Chip
                    dataTrackId="slap_status"
                    chipShape="cube"
                    variant={getStatusVariant(googleAdminListing.status)}
                >
                    {googleAdminListing.status}
                </Chip>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{googleAdminListing.origin.toUpperCase()}</Text>
            </List.Cell>
            {googleAdminListing.status === 'pending' && (
                <List.Cell>
                    <IconButton
                        dataTrackId="slap_accept_invitation"
                        appearance="contained"
                        tooltip={t('admin:slap__accept_invitation')}
                        aria-label={t('admin:slap__accept_invitation')}
                        icon={[FontAwesomeIconsPartooUsed.faCheck, IconPrefix.SOLID]}
                        onClick={() => {
                            acceptInvitation.mutate();
                            setIsAcceptClicked(true);
                        }}
                        disabled={isAcceptClicked}
                    />
                </List.Cell>
            )}
        </List.Row>
    );
};

export default GoogleAdminListingRow;
