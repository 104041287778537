import { Option } from '@partoohub/ui';

import { V2UserData } from 'app/api/types/user';

import { UserStatus } from 'admin/common/data/enums';

export const convertUserToOption = (user: V2UserData) => {
    const isDisabled = user.status === UserStatus.DISABLED;

    return {
        label: `${user.first_name} ${user.last_name} - ${user.email} - ORG (${user.org_id}) - ${user.role}${isDisabled ? ` (${user.status})` : ''}`,
        value: user.id,
        name: `${user.first_name} ${user.last_name}`,
        disabled: isDisabled,
    } as Option;
};
