import styled from '@emotion/styled';

export const SublinkStyled = styled.div`
    margin-top: 16px;

    height: 19px;

    .link {
        display: flex;
        justify-content: space-between;

        color: ${({ theme }) => theme.colors.secondary.initial};
        font-weight: 400;

        transition: color 0.2s;

        &.active,
        &:hover {
            color: ${({ theme }) => theme.colors.primary.initial};

            .link_chip {
                background-color: rgba(0, 133, 242, 0.12);
            }
        }

        &.active {
            font-weight: 700;
        }
    }
`;

export const SublinkText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SublinkChip = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 4px;
    padding: 2.5px 7px;
    border-radius: 50px;

    min-width: 22px;

    background: rgba(127, 142, 164, 0.12);
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
`;
