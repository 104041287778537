import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router';

import BetaChip from 'admin/common/components/chips/BetaChip';
import { PartooIcon } from 'admin/common/components/icons/PartooIcon';
import {
    CategoriesIcon,
    ConnectAsIcon,
    DataIcon,
    GoToAppIcon,
    GoToServiceDeskIcon,
    LogsIcon,
    PlaygroundIcon,
    ToolsIcon,
} from 'admin/common/components/icons/sidebar';
import {
    CATEGORIES_PATH,
    CONNECT_AS,
    CONNECT_AS_PATH,
    DATA_PATH,
    DEMO_CREATOR_PATH,
    DIFFUSION_CLEANER_PATH,
    DIFFUSION_LOGS_PATH,
    EXPORTER_PATH,
    LOGS_PATH,
    PLAYGROUND_PATH,
    TOOLS_PATH,
    USER_ACTIONS_PATH,
} from 'admin/common/data/routeIds';
import env from 'admin/common/utils/getEnvironment';

import {
    FooterNav,
    MainNav,
    SidebarContent,
    SidebarHeader,
    SidebarHeaderLogo,
    SidebarHeaderTitle,
    SidebarWrapper,
} from './Sidebar.styled';
import { ExternalSidebarLink, SidebarLink } from './SidebarLink';

const appUrl =
    env.getEnv() === 'prod' ? 'https://app.partoo.co/' : `https://app.${env.getEnv()}.partoo.co/`;

const serviceDeskUrl = 'https://partoo.atlassian.net/servicedesk/customer/portals';

export const Sidebar: FC = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const isActive = (path: string): boolean => pathname.startsWith(path);

    return (
        <SidebarWrapper>
            <Link to="/">
                <SidebarHeader>
                    <SidebarHeaderLogo>
                        <PartooIcon />
                    </SidebarHeaderLogo>
                    <SidebarHeaderTitle>{env.getEnv()}</SidebarHeaderTitle>
                </SidebarHeader>
            </Link>

            <SidebarContent>
                <MainNav>
                    <SidebarLink
                        to={CONNECT_AS}
                        tooltip={t('admin:side_menu_connect_as__title')}
                        active={isActive(CONNECT_AS_PATH)}
                        icon={<ConnectAsIcon active={isActive(CONNECT_AS_PATH)} />}
                    />
                    <SidebarLink
                        to={EXPORTER_PATH}
                        tooltip={t('admin:side_menu_data__title')}
                        active={isActive(DATA_PATH)}
                        icon={<DataIcon active={isActive(DATA_PATH)} />}
                    />
                    <SidebarLink
                        to={CATEGORIES_PATH}
                        tooltip={t('admin:side_menu_categories__title')}
                        active={isActive(CATEGORIES_PATH)}
                        icon={<CategoriesIcon active={isActive(CATEGORIES_PATH)} />}
                    />
                    <SidebarLink
                        to={DIFFUSION_LOGS_PATH}
                        tooltip={t('admin:side_menu_logs__title')}
                        active={isActive(LOGS_PATH)}
                        icon={<LogsIcon active={isActive(LOGS_PATH)} />}
                    />
                    <SidebarLink
                        to={
                            env.getEnv() === 'prod' || env.getEnv() === 'preprod'
                                ? DIFFUSION_CLEANER_PATH
                                : DEMO_CREATOR_PATH
                        }
                        tooltip={t('admin:side_menu_tools__title')}
                        active={isActive(TOOLS_PATH)}
                        icon={<ToolsIcon active={isActive(TOOLS_PATH)} />}
                    />
                    <SidebarLink
                        to={USER_ACTIONS_PATH}
                        tooltip={t('admin:side_menu_playground__title')}
                        active={isActive(PLAYGROUND_PATH)}
                        icon={<PlaygroundIcon active={isActive(PLAYGROUND_PATH)} />}
                        chip={<BetaChip />}
                    />
                </MainNav>
                <FooterNav>
                    <ExternalSidebarLink
                        dataTrack="admin_sidebar__click__go_to_service_desk"
                        to={serviceDeskUrl}
                        tooltip={t('admin:side_menu_gotoservicedesk__title')}
                        icon={<GoToServiceDeskIcon />}
                    />
                    <ExternalSidebarLink
                        dataTrack="admin_sidebar__click__go_to_app"
                        to={appUrl}
                        tooltip={t('admin:side_menu_gotoapp__title')}
                        icon={<GoToAppIcon />}
                    />
                </FooterNav>
            </SidebarContent>
        </SidebarWrapper>
    );
};
