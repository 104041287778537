import { STATIC_BASE_URL } from 'admin/common/data/config';

import { BusinessPartnerLogo } from './Business.styled';
import { PartnerLogo } from '../PartnerLogo/PartnerLogo';

type Props = {
    logo: string;
    businessId: string;
};

const Business = ({ logo, businessId }: Props) => {
    return (
        <BusinessPartnerLogo>
            <PartnerLogo
                sourceImg={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/${logo}.svg`}
                businessId={businessId}
            />
        </BusinessPartnerLogo>
    );
};

export default Business;
