import styled from '@emotion/styled';

export const LoadingViewContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    text-align: center;

    button {
        width: 100%;
        justify-content: center;
        margin: 16px auto 0px auto;
        max-width: 330px;
    }
`;
