import { FC, useEffect, useMemo, useState } from 'react';

import { Option, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { bulkExportV1ApiClient } from 'admin/api/apiResources';
import { BusinessStatusSelect } from 'admin/common/components/inputs/BusinessStatusSelect';
import EmailInput from 'admin/common/components/inputs/EmailInput';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { NewProviderSelect } from 'admin/common/components/inputs/ProviderSelect/NewProviderSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { useMe } from 'admin/common/hooks/queries/useMe';

import { ExportFormatInput } from './components/ExportFormatInput';
import { OrganisationTypeInput } from './components/OrganisationTypeInput';
import SubscribedInput from './components/SubscribedInput';

const ExporterV1: FC = () => {
    const { t } = useTranslation();

    const [exportFormat, setExportFormat] = useState<string | null>('partoo');
    const [orgs, setOrgs] = useState<Option[]>([]);
    const [provider, setProvider] = useState<Option | undefined>(undefined);
    const [isEnterprise, setIsEnterprise] = useState<string | null>('both');
    const [hasActivePM, setHasActivePM] = useState<string | null>('both');
    const [hasActiveRM, setHasActiveRM] = useState<string | null>('both');
    const [hasActiveRB, setHasActiveRB] = useState<string | null>('both');
    const [status, setStatus] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');

    const { data: me } = useMe();

    useEffect(() => {
        setEmail(me?.email ?? '');
    }, [me]);

    const isFormValid = useMemo(
        () => !!email && !!exportFormat && (!!orgs?.length || !!provider),
        [email, exportFormat, orgs, provider],
    );

    const { mutateAsync, isLoading } = useMutation(
        () =>
            bulkExportV1ApiClient.bulkExportV1({
                email,
                partner: exportFormat || '',
                statuses: !status || status === 'both' ? [] : [status],
                org_ids: orgs.map(o => o.value),
                providers: provider?.value ? [provider.value] : [],
                is_enterprise: isEnterprise || '',
                has_active_presence_management: hasActivePM || '',
                has_active_review_management: hasActiveRM || '',
                has_active_review_booster: hasActiveRB || '',
            }),
        {
            onSuccess: (data: any) => {
                if (data.status === 'success') {
                    toast.success(
                        t('admin:page_exporter__success_toast__content'),
                        t('admin:page_exporter__success_toast__title'),
                    );
                } else toast.error(data.error, t('admin:toast_error__title'));
            },
            onError: (error: any) => {
                toast.error(error.message, t('admin:toast_error__title'));
            },
            onSettled: () => resetForm(),
        },
    );

    const submitForm = async () => {
        if (!isFormValid || isLoading) return;
        await mutateAsync();
    };

    const resetForm = () => {
        setExportFormat('partoo');
        setOrgs([]);
        setProvider(undefined);
        setIsEnterprise('both');
        setHasActivePM('both');
        setHasActiveRM('both');
        setHasActiveRB('both');
        setStatus('both');
    };

    return (
        <AdminPage
            dataTrackId="page_old_exporter"
            title={t('admin:page_old_exporter__title')}
            description={t('admin:page_old_exporter__description')}
            infoLink={'https://partoo.elium.com/tile/view/271'}
        >
            <BorderedContainer>
                <PageFormRow>
                    <ExportFormatInput
                        selectedValue={exportFormat}
                        onChange={setExportFormat}
                        placeholder={t('admin:name_field_export_format')}
                    />
                </PageFormRow>
                <PageFormRow>
                    <NewOrganizationSelect
                        dataTrackId="admin_old_exporter__filter_organization"
                        orgs={orgs}
                        setOrgs={setOrgs}
                        placeholder={t('admin:organization')}
                        isMulti
                    />
                </PageFormRow>
                <PageFormRow>
                    <OrganisationTypeInput
                        selectedValue={isEnterprise}
                        onChange={setIsEnterprise}
                        placeholder={t('admin:filter_select_business_type')}
                    />
                    <SubscribedInput
                        dataTrackId="page_old_exporter__subscribed_input__pm"
                        selectedValue={hasActivePM}
                        onChange={setHasActivePM}
                        placeholder={`${t('admin:sort_by')} Presence Management`}
                    />
                    <SubscribedInput
                        dataTrackId="page_old_exporter__subscribed_input__rm"
                        selectedValue={hasActiveRM}
                        onChange={setHasActiveRM}
                        placeholder={`${t('admin:sort_by')} Review Management`}
                    />
                    <SubscribedInput
                        dataTrackId="page_old_exporter__subscribed_input__rb"
                        selectedValue={hasActiveRB}
                        onChange={setHasActiveRB}
                        placeholder={`${t('admin:sort_by')} Review Booster`}
                    />
                </PageFormRow>
                <PageFormRow>
                    <NewProviderSelect
                        dataTrackId="admin_old_exporter__filter_provider"
                        provider={provider}
                        setProvider={setProvider}
                    />
                    <BusinessStatusSelect status={status} setStatus={setStatus} />
                </PageFormRow>
                <PageFormRow>
                    <EmailInput
                        placeholder={t('admin:receiving_email')}
                        email={email}
                        onChange={setEmail}
                        isMandatory
                    />
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId={`admin_old_exporter__export_${exportFormat}_data`}
                disabled={!isFormValid || isLoading}
                text={t('admin:page_exporter__button_export_data')}
                onClick={submitForm}
            />
        </AdminPage>
    );
};

export default ExporterV1;
