import { Image } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';

type Props = {
    publisherName: string;
    disabled?: boolean;
};

const PublisherImg = ({ publisherName, disabled = false }: Props) => {
    const imgSrc = `${STATIC_BASE_URL}/images/common/partners_sprite/circle/${publisherName}.svg`;
    return (
        <Image
            src={imgSrc}
            alt={publisherName}
            height={16}
            width={16}
            className={`noselect ${disabled ? 'img__disabled' : ''}`}
        />
    );
};

export default PublisherImg;
