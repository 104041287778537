import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleSelect } from 'admin/common/components/inputs/SingleSelect';

type Props = {
    placeholder: string;
    selectedValue: string | null;
    onChange: (value: string | null) => void;
};

export const ExportFormatInput: FC<Props> = ({ placeholder, selectedValue, onChange }) => {
    const { t } = useTranslation();
    const translatedExportFormatOptions = useMemo(() => {
        const result = [
            { value: 'partoo', name: 'partoo', label: 'Partoo' },
            {
                value: 'new_state_extractor',
                name: 'new_state_extractor',
                label: `Partoo - ${t('admin:status')} V2`,
            },
            {
                value: 'client_status_extractor',
                name: 'client_status_extractor',
                label: `Partoo - ${t('admin:client_status')}`,
            },
            {
                value: 'partoo_attributes',
                name: 'partoo_attributes',
                label: `Partoo - ${t('admin:attributes')}`,
            },
            {
                value: 'partoo_payment_type',
                name: 'partoo_payment_type',
                label: `Partoo - ${t('admin:payment_types')}`,
            },
            {
                value: 'partoo_google_posts',
                name: 'partoo_google_posts',
                label: `Partoo - ${t('admin:google_posts_authorizations')}`,
            },
            { value: 'google_my_business', name: 'google_my_business', label: t('GMB') },
            { value: 'facebook', name: 'facebook', label: t('Facebook') },
            { value: 'factual', name: 'factual', label: t('Factual') },
            { value: 'bonial', name: 'bonial', label: t('Bonial') },
            { value: 'pages_jaunes', name: 'pages_jaunes', label: t('Pages Jaunes') },
            {
                value: 'partoo_place_action_links',
                name: 'partoo_place_action_links',
                label: `Partoo - ${t('admin:place_action_links')}`,
            },
        ];

        return result;
    }, []);

    return (
        <SingleSelect
            dataTrackId="page_old_exporter__select_export_format"
            sections={[{ options: translatedExportFormatOptions }]}
            selectedValue={selectedValue}
            onChange={onChange}
            placeholder={placeholder}
            disableReset
        />
    );
};
