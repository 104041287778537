import { useMutation, useQueryClient } from 'react-query';

import {
    CreateFeedbackLookerStudioPayload,
    UpdateFeedbackLookerStudioPayload,
} from 'app/api/types/feedbackLookerStudio';
import api from 'app/api/v2/api_calls';

import { FEEDBACK_LOOKER_STUDIO } from 'admin/common/data/queryKeysConstants';

type SuccessResponse = {
    org_id: number;
};

export const useCreateOrUpdateFeedbackLookerStudio = () => {
    const queryClient = useQueryClient();

    const onSuccess = ({ org_id }: SuccessResponse) => {
        queryClient.invalidateQueries([FEEDBACK_LOOKER_STUDIO, org_id]);
    };

    const { mutate: createFeedbackLookerStudio, isLoading: createIsLoading } = useMutation(
        (payload: CreateFeedbackLookerStudioPayload) =>
            api.feedbackLookerStudio.createFeedbackLookerStudio(payload),
        { onSuccess },
    );

    const { mutate: updateFeedbackLookerStudio, isLoading: updateIsLoading } = useMutation(
        (payload: { orgId: number; data: UpdateFeedbackLookerStudioPayload }) =>
            api.feedbackLookerStudio.updateFeedbackLookerStudio(payload.orgId, payload.data),
        { onSuccess },
    );

    const createOrUpdateFeedbackLookerStudio = (
        orgId: number | undefined,
        payload: CreateFeedbackLookerStudioPayload | UpdateFeedbackLookerStudioPayload,
    ) => {
        if (orgId) {
            updateFeedbackLookerStudio({
                orgId,
                data: payload as UpdateFeedbackLookerStudioPayload,
            });
        } else {
            createFeedbackLookerStudio(payload as CreateFeedbackLookerStudioPayload);
        }
    };

    return {
        createOrUpdateFeedbackLookerStudio,
        isCreatingOrUpdating: createIsLoading || updateIsLoading,
    };
};
