import { setDefaultOptions } from 'date-fns';
import { enGB, es, fr, it, pt } from 'date-fns/locale';

import { UserData } from '../api/types/user';

import { i18n } from '../translations/i18n';

export const setLocale = (locale: string) => {
    switch (locale) {
        case 'es':
            setDefaultOptions({ locale: es });
            break;
        case 'fr':
            setDefaultOptions({ locale: fr });
            break;
        case 'it':
            setDefaultOptions({ locale: it });
            break;
        case 'pt':
        case 'pt-BR':
            setDefaultOptions({ locale: pt });
            break;
        default:
            setDefaultOptions({ locale: enGB });
            break;
    }
};

export const setUser = (userData: UserData) => {
    if (userData.lang !== i18n.language) i18n.changeLanguage(userData.lang);

    const html = document.getElementsByTagName('html');
    if (html && html[0]) {
        html[0].setAttribute('lang', userData.lang);
        setLocale(userData.lang);
    }

    // Provide user info to Google Tag Manager by global dataLayer object.
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        userId: userData.id,
        app_type: 'admin',
        language: userData.lang,
    });
};
