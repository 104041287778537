import { FC, PropsWithChildren, createContext, useCallback, useMemo, useRef } from 'react';

interface HideIntercomContextValue {
    showIntercom: (id: string) => void;
    hideIntercom: (id: string) => void;
}

export const HideIntercomContext = createContext<HideIntercomContextValue | undefined>(undefined);

export const HideIntercomProvider: FC<PropsWithChildren> = ({ children }) => {
    // internal record of useHideIntercom values
    const recordRef = useRef<Record<string, boolean>>({});

    const updateIntercomVisibility = useCallback(() => {
        // 2 possible intercoms depending on if already loaded or not
        const intercomLightweight: HTMLElement | null = document.querySelector(
            '[class=intercom-lightweight-app]',
        );
        const intercomFull: HTMLElement | null = document.querySelector(
            '[class=intercom-container]',
        );
        const intercom: HTMLElement | null = intercomFull || intercomLightweight;
        if (!intercom) {
            return;
        }

        const shouldHideIntercom = Object.values(recordRef.current).some(Boolean);

        intercom.style.transition = 'opacity ease 0.3s';
        if (shouldHideIntercom) {
            intercom.style.opacity = '0';
            intercom.style.pointerEvents = 'none';
        } else {
            intercom.style.opacity = '1';
            intercom.style.pointerEvents = 'auto';
        }
    }, []);

    const showIntercom = useCallback((id: string) => {
        recordRef.current[id] = false;
        updateIntercomVisibility();
    }, []);
    const hideIntercom = useCallback((id: string) => {
        recordRef.current[id] = true;
        updateIntercomVisibility();
    }, []);
    const contextData = useMemo<HideIntercomContextValue>(
        () => ({ showIntercom, hideIntercom }),
        [showIntercom, hideIntercom],
    );

    return (
        <HideIntercomContext.Provider value={contextData}>{children}</HideIntercomContext.Provider>
    );
};
