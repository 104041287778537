import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';

const RESOURCE_URI = 'logs';

type DailyReportLogsAdminFilter = {
    categories?: string;
    created_at?: string;
    origin?: 'user' | 'system';
    user_id?: string;
    page?: number;
};

type ImportLogsAdminFilter = {
    categories?: string;
    created_at?: string;
    origin?: 'user' | 'system';
    user_id?: string;
    search_query?: string;
    statuses?: string;
    bulk_types_formats?: string;
    page?: number;
};

export interface DailyReportLog {
    id: string;
    category:
        | 'apple_report'
        | 'unique_puller_report'
        | 'stale_data_report'
        | 'expert_feed_report'
        | 'gc_feed_report'
        | 'smb_feed_report'
        | 'smb_partner_co_status_issue_report'
        | 'gc_partner_co_status_issue_report'
        | 'channel_and_expert_partner_co_status_issue_report';
    created_at: string;
    link: string;
}

export interface DailyReportData {
    name: string;
    count: number;
}

export interface BulkImportLog {
    id: string;
    category: 'import_admin' | 'import_app';
    type: string;
    status: string;
    created_at: string;
    originator: string;
    originator_first_name: string;
    originator_last_name: string;
    originator_email: string;
    bulk_type: string;
    bulk_format: string;
    import_filename: string;
    report_filename: string;
}

export type SearchLogsReceivedData<T> = {
    logs: Array<T>;
    count: number;
    max_page: number;
    page: number;
};

export class LogsAdminApiCall extends AdminApiResourceBaseClass {
    static async dailyReports(
        filter?: DailyReportLogsAdminFilter,
    ): Promise<SearchLogsReceivedData<DailyReportLog>> {
        const { data } = await LogsAdminApiCall.get(`${RESOURCE_URI}`, {
            type: 'daily_report',
            ...filter,
        });
        return data;
    }

    static async dailyReportData(id: string): Promise<DailyReportData[]> {
        const { data } = await LogsAdminApiCall.get(`daily_report_data/${id}`);
        return data;
    }

    static async dailyReportLink(id: string) {
        const { data } = await LogsAdminApiCall.get(`daily_reports/${id}`);
        return data.url;
    }

    static async bulkImportReportLink(id: string) {
        const { data } = await LogsAdminApiCall.get(`import_log/${id}/report`);
        return data.url;
    }

    static async bulkImportInputLink(id: string) {
        const { data } = await LogsAdminApiCall.get(`import_log/${id}/file`);
        return data.url;
    }

    static async getBulkImportLog(
        filter?: ImportLogsAdminFilter,
    ): Promise<SearchLogsReceivedData<BulkImportLog>> {
        const { data } = await LogsAdminApiCall.get(`${RESOURCE_URI}`, {
            type: 'bulk_import',
            ...filter,
        });
        return data;
    }
}
