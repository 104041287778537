import { AutoTagSettingsData, AutoTagSettingsPayload } from 'app/api/types/reviewAutoTagSettings';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'review_auto_tag_settings';

export class ReviewAutoTagSettings extends ApiResourceBaseClass {
    static async getAutoTagSettings(): Promise<AutoTagSettingsData> {
        const { data } = await ReviewAutoTagSettings.get(RESOURCE_URI);
        return data;
    }

    static async updateAutoTagSettings(
        settingId: number,
        payload: AutoTagSettingsPayload,
    ): Promise<AutoTagSettingsData> {
        const { data } = await ReviewAutoTagSettings.put(`${RESOURCE_URI}/${settingId}`, payload);
        return data;
    }

    static async postAutoTagSettings(
        payload: AutoTagSettingsPayload,
    ): Promise<AutoTagSettingsData> {
        const { data } = await ReviewAutoTagSettings.post(RESOURCE_URI, payload);
        return data;
    }
}
