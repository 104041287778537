import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { FullScreenModal } from 'admin/common/components/modals/FullScreenModal/FullScreenModal';
import { UserInfosQueryKeys } from 'admin/content/tools/pages/MultiOrganizations/enums';

import { MergedAccountsEditForm } from './MergedAccountsEditForm';

export const MergedAccountsEditModal = () => {
    const { t } = useTranslation();
    const [userInfoId, setUserInfoId] = useStateQueryParams(UserInfosQueryKeys.USER_INFO_ID);

    const childrenRight = (
        <EditFormDescription
            title={t('admin:org_switcher_merge_page_advice_title')}
            description={t('admin:org_switcher_merge_page_advice_description')}
            advice={t('admin:org_switcher_merge_page_advice_description_2')}
        />
    );

    return (
        <FullScreenModal
            childrenLeft={<MergedAccountsEditForm />}
            childrenRight={childrenRight}
            show={!!userInfoId}
            onHideClick={() => setUserInfoId('')}
            forceDisplayRightPart // ????
        />
    );
};
