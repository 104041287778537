import { JSX, cloneElement, useState } from 'react';

import { BusinessModalProps } from 'app/common/components/businessModal/components/BusinessModal/BusinessModal';

import BusinessModal from './components/BusinessModal';
import DisplayedButton from './components/DisplayedButton';

type Props = BusinessModalProps & {
    button?: JSX.Element;
    dataTrack?: string;
    disabled?: boolean;
};

const BusinessModalButton = ({
    button,
    dataTrack,
    disabled = false,
    ...businessModalProps
}: Props) => {
    const [opened, setOpened] = useState<boolean>(false);

    return (
        <div data-track={dataTrack} data-intercom-target={dataTrack}>
            {!disabled && (
                <BusinessModal
                    opened={opened}
                    closeModal={() => setOpened(false)}
                    {...businessModalProps}
                />
            )}

            {button ? (
                cloneElement(button, {
                    onClick: () => (disabled ? setOpened(false) : setOpened(true)),
                })
            ) : (
                <DisplayedButton
                    handleClick={() => (disabled ? setOpened(false) : setOpened(true))}
                    isSelectionMode={!!businessModalProps.isSelectionMode}
                />
            )}
        </div>
    );
};

export default BusinessModalButton;
