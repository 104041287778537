import { useState } from 'react';

import { ConfirmModalProps } from './ConfirmModal';

type UseConfirmModalProps = Omit<ConfirmModalProps, 'show' | 'onConfirm' | 'onHide'>;
export type OpenConfirmModalProps = Partial<UseConfirmModalProps>;

/**
 *  Hook to allow imperative use of the ConfirmModal component
 *  @example
 *  const { confirm, modalProps } = useConfirmModal();
 *  return (
 *      <>
 *          <button
 *              onClick={() => {
 *                  const confirmed = await confirm({ content: 'Are you sure?' });
 *                  if (confirmed) {
 *                      save();
 *                  }
 *              }
 *          >
 *              Save
 *          </button>
 *          <ConfirmModal {...modalProps} />
 *      </>
 *  );
 */

export const useConfirmModal = () => {
    const [openProps, setOpenProps] = useState<OpenConfirmModalProps>({});
    const [confirmPromise, setConfirmPromise] = useState<{
        resolve: (confirmed: boolean) => void;
    } | null>(null);

    const openModal = async (openProps: OpenConfirmModalProps = {}) => {
        try {
            setOpenProps(openProps);
            return await new Promise<boolean>(resolve => {
                setConfirmPromise({ resolve });
            });
        } finally {
            setConfirmPromise(null);
            setOpenProps({});
        }
    };

    return {
        confirm: openModal,
        modalProps: {
            show: !!confirmPromise,
            onConfirm: () => confirmPromise?.resolve(true),
            onHide: () => confirmPromise?.resolve(false),
            ...openProps,
        },
    };
};
