import { Illustration, IllustrationElement } from '@partoohub/iconography';
import { Button, Dialog, Modal, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

interface ConfirmModalWithTextProps {
    title: string;
    subTitle: string;
    opened: boolean;
    onConfirm: () => void;
    onClose: () => void;
    asset?: IllustrationElement;
    cancelText?: string;
    confirmText?: string;
}

export const ConfirmWarningModal = ({
    opened,
    title,
    subTitle,
    onConfirm,
    onClose,
    asset,
    cancelText,
    confirmText,
}: ConfirmModalWithTextProps) => {
    const { t } = useTranslation();

    const confirmAndCloseModal = () => {
        onConfirm();
        onClose();
    };

    return (
        <Modal isOpen={opened} closeModal={onClose}>
            <Dialog
                dataTrackId=""
                subTitle={subTitle}
                title={title}
                header={
                    asset && (
                        <div style={{ paddingTop: '24px' }}>
                            <Illustration
                                dataTrackId=""
                                height="140"
                                illustration={asset}
                                width="320"
                            />
                        </div>
                    )
                }
            >
                <Stack direction="row">
                    <Button
                        dataTrackId=""
                        variant="secondary"
                        shape="cube"
                        size="large"
                        appearance="outlined"
                        full
                        onClick={onClose}
                    >
                        {cancelText || t('cancel')}
                    </Button>
                    <Button
                        dataTrackId=""
                        variant="danger"
                        shape="cube"
                        size="large"
                        full
                        onClick={confirmAndCloseModal}
                    >
                        {confirmText || t('confirm')}
                    </Button>
                </Stack>
            </Dialog>
        </Modal>
    );
};
