import React, { useState } from 'react';

import { BulkImportLog } from 'admin/api/types';
import { dateToUnix } from 'admin/common/utils/dates';
import { bgColor } from 'admin/content/logs/common/utils';
import {
    ImportLogRowColor,
    ImportLogRowColumns,
    ImportLogRowWrapper,
} from 'admin/content/logs/pages/ImportLog/components/ImportLog.styled';
import ImportLogRowDetails from 'admin/content/logs/pages/ImportLog/components/ImportLogRowDetails';
import { ImportLogRowTop } from 'admin/content/logs/pages/ImportLog/components/ImportLogRowTop';

export type Props = {
    log: BulkImportLog;
};

const ImportLogRow: React.FC<Props> = ({ log }) => {
    const [closed, setClosed] = useState(true);
    const creatorName = log.originator_first_name
        ? `${log.originator_first_name} ${log.originator_last_name.substring(0, 1)}.`
        : '';

    return (
        <ImportLogRowWrapper>
            <ImportLogRowColor className={`${bgColor[log.status.toUpperCase()]}`} />
            <ImportLogRowColumns>
                <ImportLogRowTop
                    creatorName={creatorName}
                    creatorEmail={log.originator_email}
                    bulkType={log.bulk_type}
                    bulkFormat={log.bulk_format}
                    creationDate={dateToUnix(log.created_at)}
                    status={log.status}
                    toggleClosed={() => setClosed(!closed)}
                    closed={closed}
                />
                {!closed && (
                    <ImportLogRowDetails importLogId={log.id} hasReport={!!log.report_filename} />
                )}
            </ImportLogRowColumns>
        </ImportLogRowWrapper>
    );
};

export default ImportLogRow;
