import styled from '@emotion/styled';
import { List } from '@partoohub/ui';

export const StyledIconListCell = styled(List.Cell)`
    position: absolute;
    display: flex;
    align-items: center;
    right: 16px;
`;

export const MergedAccountRowWrapper = styled.div`
    cursor: pointer;
`;
