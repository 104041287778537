import { isNull, omitBy } from 'lodash-es';
import queryString from 'query-string';

import { InvoicesData } from 'app/api/types/invoice';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'invoices';

class Invoice extends ApiResourceBaseClass {
    static getInvoices(page = 1, queryFilter: Record<string, any> = {}): Promise<InvoicesData> {
        const queryParams = queryString.stringify(omitBy({ ...queryFilter, page }, isNull));
        return Invoice.get(`${RESOURCE_URI}?${queryParams}`).then(({ data }) => data);
    }

    static async downloadInvoice(invoiceId: string): Promise<string> {
        const { data } = await Invoice.get(`${RESOURCE_URI}/${invoiceId}/download`);
        return data.url;
    }
}

export default Invoice;
