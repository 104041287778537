import { ReactNode } from 'react';

import { Text } from '@partoohub/ui';

import {
    ServiceCell,
    ServiceCellContent,
    ServiceCellLeft,
    ServiceCellRight,
} from './ServiceItem.styled';

export type ServiceItemProps = {
    mainLabel: string;
    disabled?: boolean;
    description?: string;
    optionalLabel?: ReactNode;
    menuElem?: ReactNode;
};

export const ServiceItem = ({
    mainLabel,
    disabled,
    description,
    optionalLabel,
    menuElem,
}: ServiceItemProps) => (
    <ServiceCell>
        <ServiceCellContent
            disabled={disabled}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <ServiceCellLeft>
                <Text disabled={disabled} variant="bodyMBold" oneLine noTooltip>
                    {mainLabel}
                </Text>
                {description && (
                    <Text variant="bodyMRegular" color="secondary" oneLine noTooltip>
                        {description}
                    </Text>
                )}
            </ServiceCellLeft>
            <ServiceCellRight direction="row" alignItems="baseline" gap="24px">
                {optionalLabel && (
                    <Text variant="bodyMBold" color="secondary">
                        {optionalLabel}
                    </Text>
                )}
                {menuElem}
            </ServiceCellRight>
        </ServiceCellContent>
    </ServiceCell>
);
