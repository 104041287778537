import { FC } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SingleSelect } from './SingleSelect';

export const bulkTypeEnum = {
    LOCATION: 'location',
    USER: 'user',
};

type Props = {
    placeholder: string;
    selectedValue: string | null;
    onChange: (value: string | null) => void;
};

export const BulkTypeSelect: FC<Props> = ({ placeholder, selectedValue, onChange }) => {
    const { t } = useTranslation();
    return (
        <SingleSelect
            dataTrackId="bulk_type_select"
            sections={[
                {
                    options: [
                        {
                            value: bulkTypeEnum.LOCATION,
                            name: bulkTypeEnum.LOCATION,
                            label: t('location'),
                            icon: [FontAwesomeIconsPartooUsed.faStore, IconPrefix.REGULAR],
                        },
                        {
                            value: bulkTypeEnum.USER,
                            name: bulkTypeEnum.USER,
                            label: t('user'),
                            icon: [FontAwesomeIconsPartooUsed.faUsers, IconPrefix.REGULAR],
                        },
                    ],
                },
            ]}
            selectedValue={selectedValue}
            onChange={onChange}
            placeholder={placeholder}
            disableReset
            required
        />
    );
};
