import {
    BUSINESSES_CITIES,
    BUSINESSES_GROUPS,
    BUSINESSES_ORGS,
    BUSINESSES_QUERIES,
    BUSINESSES_SELECT_ALL,
    EXCLUDED_BUSINESSES,
    INCLUDED_BUSINESSES,
} from 'app/common/data/routeIds';
import { useGetParamReduxSync } from 'app/common/hooks/useGetParamReduxSync';
import { AppState } from 'app/states/reducers';

const BusinessModalGetParamsReduxSync = () => {
    useGetParamReduxSync<string>(
        BUSINESSES_SELECT_ALL,
        [
            (state: AppState) => state.filterByBusiness.filteredBusinesses,
            'mode',
        ],
        [
            (state: AppState) =>
                state.filterByBusiness.filteredBusinesses.pending,
            'mode',
        ],
    );

    useGetParamReduxSync(
        INCLUDED_BUSINESSES,
        [
            (state: AppState) => state.filterByBusiness.filteredBusinesses,
            'includedIds',
        ],
        [
            (state: AppState) =>
                state.filterByBusiness.filteredBusinesses.pending,
            'includedIds',
        ],
    );

    useGetParamReduxSync(
        EXCLUDED_BUSINESSES,
        [
            (state: AppState) => state.filterByBusiness.filteredBusinesses,
            'excludedIds',
        ],
        [
            (state: AppState) =>
                state.filterByBusiness.filteredBusinesses.pending,
            'excludedIds',
        ],
    );

    useGetParamReduxSync(
        BUSINESSES_QUERIES,
        [
            (state: AppState) => state.filterByBusiness.filteredBusinesses,
            'queries',
        ],
        [
            (state: AppState) =>
                state.filterByBusiness.filteredBusinesses.pending,
            'queries',
        ],
    );

    useGetParamReduxSync(
        BUSINESSES_CITIES,
        [
            (state: AppState) => state.filterByBusiness.filteredBusinesses,
            'cities',
        ],
        [
            (state: AppState) =>
                state.filterByBusiness.filteredBusinesses.pending,
            'cities',
        ],
    );

    useGetParamReduxSync(
        BUSINESSES_GROUPS,
        [
            (state: AppState) => state.filterByBusiness.filteredBusinesses,
            'groupIdIn',
        ],
        [
            (state: AppState) =>
                state.filterByBusiness.filteredBusinesses.pending,
            'groupIdIn',
        ],
    );

    useGetParamReduxSync(
        BUSINESSES_ORGS,
        [
            (state: AppState) => state.filterByBusiness.filteredBusinesses,
            'orgs',
        ],
        [
            (state: AppState) =>
                state.filterByBusiness.filteredBusinesses.pending,
            'orgs',
        ],
    );

    return null;
};

export default BusinessModalGetParamsReduxSync;
