import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useIsSideMenuOpen } from 'admin/common/contexts/isSideMenuOpenContext';
import {
    DAILY_REPORTS_PATH,
    DIFFUSION_LOGS_PATH,
    IMPORT_LOGS_PATH,
} from 'admin/common/data/routeIds';

import {
    SideMenuContent,
    SideMenuTitle,
    SideMenuWrapper,
} from './Menus.styled';
import MenuLink from '../MenuLink';

const LogsMenu: FC = () => {
    const { t } = useTranslation();
    const { isSideMenuOpen } = useIsSideMenuOpen();

    return (
        <>
            <SideMenuWrapper open={isSideMenuOpen}>
                <SideMenuTitle>
                    {t('admin:side_menu_logs__title')}
                </SideMenuTitle>

                <SideMenuContent>
                    <MenuLink
                        to={DIFFUSION_LOGS_PATH}
                        name={t(
                            'admin:side_menu_logs__section_logs__diffusion',
                        )}
                    />
                    <MenuLink
                        to={IMPORT_LOGS_PATH}
                        name={
                            <>
                                {t(
                                    'admin:side_menu_logs__section_logs__import_logs',
                                )}
                            </>
                        }
                    />
                    <MenuLink
                        to={DAILY_REPORTS_PATH}
                        name={t(
                            'admin:side_menu_logs__section_logs__daily_report',
                        )}
                    />
                </SideMenuContent>
            </SideMenuWrapper>
            <Outlet />
        </>
    );
};

export default LogsMenu;
