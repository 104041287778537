import {
    AuthToken,
    Business,
    Cities,
    Group,
    Lang,
    MessagingPrivacyPolicy,
    Organization,
    ProductUpsale,
    Provider,
    SsoOidc,
    SsoSaml,
    User,
} from '@partoohub/api-client';

const BASE_URL = `https://${window.location.host}/api/v2`;

export const authTokenApiClient = new AuthToken(BASE_URL);
export const businessApiClient = new Business(BASE_URL);
export const citiesApiClient = new Cities(BASE_URL);
export const groupApiClient = new Group(BASE_URL);
export const messagingPrivacyPolicy = new MessagingPrivacyPolicy(BASE_URL);
export const productUpsaleApiClient = new ProductUpsale(BASE_URL);
export const providerApiClient = new Provider(BASE_URL);
export const langApiClient = new Lang(BASE_URL);
export const organizationApiClient = new Organization(BASE_URL);
export const userApiClient = new User(BASE_URL);
export const ssoOidcApiClient = new SsoOidc(BASE_URL);
export const ssoSamlApiClient = new SsoSaml(BASE_URL);
