import {
    MoreHoursGetDataType,
    MoreHoursGetParamsType,
} from 'app/api/types/more_hours';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'more_hours';

class MoreHours extends ApiResourceBaseClass {
    static getMoreHours(
        params: MoreHoursGetParamsType,
    ): Promise<MoreHoursGetDataType> {
        return MoreHours.get(`${RESOURCE_URI}/list`, params).then(
            ({ data }) => data,
        );
    }
}

export default MoreHours;
