import styled from '@emotion/styled';

import { Stack } from '@partoohub/ui';

export const ReviewCleanerContent = styled(Stack)`
    div {
        color: ${({ theme }) => theme.colors.secondary.initial};
        font-weight: ${({ theme }) => theme.typography.bodyMSemibold.weight};
    }
`;
