import React from 'react';

import { useTranslation } from 'react-i18next';

import {
    Actions,
    ChevronWrapper,
    Count,
    ErrorInfo,
    Icon,
    IconWrapper,
} from 'admin/content/tools/pages/DiffusionCleaner/components/common/DiffusionCleanerCommon.styled';

import { ColumnHeading } from './ColumnHeading';
import { ColumnHeadingsContainer } from './ColumnHeadingsContainer.styled';

export const DiffusionCleanerColumnHeadings = () => {
    const { t } = useTranslation();
    return (
        <ColumnHeadingsContainer>
            <IconWrapper>
                <Icon />
            </IconWrapper>
            <ErrorInfo>
                <ColumnHeading
                    title={t('admin:page_diffusion_cleaner__column__error_type')}
                ></ColumnHeading>
            </ErrorInfo>
            <Count variant="bodyMBold">
                <ColumnHeading
                    title={t('admin:page_diffusion_cleaner__column__count')}
                ></ColumnHeading>
            </Count>
            <Actions />
            <ChevronWrapper />
        </ColumnHeadingsContainer>
    );
};
