import styled from '@emotion/styled';
import { Button, Stack } from '@partoohub/ui';

export const NotFoundTemplateContainer = styled(Stack)`
    margin: auto;
    height: 100%;
`;

export const StyledButton = styled(Button)`
    margin-top: 16px;
`;
