import businessPublisherErrorsFormatter from 'app/api/formatters/response/businessPublisherErrorsFormatter';
import { nestedCamelCasify } from 'app/common/services/CamelCasify';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'publisher_errors';

class PublisherErrors extends ApiResourceBaseClass {
    static getPublisherErrors(
        businessId: string,
        content: Record<string, any>,
    ): Promise<PublisherErrors> {
        return PublisherErrors.post(
            `${RESOURCE_URI}/${businessId}`,
            content,
        ).then(({ data }) =>
            businessPublisherErrorsFormatter(nestedCamelCasify(data)),
        );
    }

    // content is camelcasified in new api
    static getPublisherErrorsV2(
        businessId: string,
        content: Record<string, any>,
    ): Promise<Record<string, any>> {
        return PublisherErrors.post(
            `${RESOURCE_URI}/${businessId}`,
            content,
        ).then(({ data }) => data);
    }
}

export default PublisherErrors;
