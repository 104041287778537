import React, { useEffect, useState } from 'react';

import { Drawer } from '@partoohub/ui';

import { BUSINESS_MODAL_DRAWER_CSS } from 'app/common/components/businessModal/components/BusinessModal/BusinessModal.styled';
import { useHideIntercom } from 'app/common/hooks/useHideIntercom';

import AccessGroupContent from './AccessGroupContent';

type Props = {
    opened: boolean;
    closeModal: () => void;
    value: Array<number>;
    onChange: (value: Array<number>) => void;
    orgId?: number;
};

const AccessGroupModal = ({ opened, closeModal, value, onChange, orgId }: Props) => {
    const [savedValue, setSavedValue] = useState<Array<number>>(value);

    const cancelModal = () => {
        setSavedValue(value);
        closeModal();
    };

    const saveModal = () => {
        onChange(savedValue);
        closeModal();
    };

    useEffect(() => {
        setSavedValue(value);
    }, [value]);

    // Hide intercom when drawer is open
    useHideIntercom(opened);

    return (
        <Drawer
            dataTrackId="access-group-modal"
            isOpen={opened}
            onHide={closeModal}
            cssContainer={BUSINESS_MODAL_DRAWER_CSS}
        >
            <AccessGroupContent
                closeModal={cancelModal}
                value={savedValue}
                onChange={setSavedValue}
                saveModal={saveModal}
                orgId={orgId}
            />
        </Drawer>
    );
};

export default AccessGroupModal;
