import { useEffect, useState } from 'react';

import { Filters, Section } from '@partoohub/ui';

import { getSingleFilterSelectedOption } from 'admin/common/utils/selectOptions';

type Props = {
    dataTrackId: string;
    placeholder: string;
    sections: Section[];
    selectedValue: string | null;
    onChange: (selectedOptionValue: string | null) => void;
    disabled?: boolean;
    required?: boolean;
    disableReset?: boolean;
};

export const SingleSelect = ({
    dataTrackId,
    placeholder,
    sections,
    selectedValue,
    onChange,
    disabled,
    required,
    disableReset,
}: Props) => {
    const [selectedOption, setSelectedOption] = useState<Record<string, boolean>>(
        selectedValue ? { [selectedValue]: true } : {},
    );

    const onOptionSelected = (option: Record<string, boolean>) => {
        const optionValue = getSingleFilterSelectedOption(option);
        onChange(optionValue || null);
    };

    useEffect(() => {
        setSelectedOption(selectedValue ? { [selectedValue]: true } : {});
    }, [selectedValue]);

    return (
        <Filters
            dataTrackId={dataTrackId}
            placeholder={placeholder}
            menuListArray={sections}
            selectedItems={selectedOption}
            onChange={onOptionSelected}
            disabled={disabled}
            position="bottom"
            maxHeight={300}
            required={required}
            disableReset={disableReset}
        />
    );
};
