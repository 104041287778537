import { FC } from 'react';

import { Stack, Switch, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { IncognitoToggleWrapper, StyledText } from './IncognitoToggle.styled';

type Props = {
    checked: boolean;
    disabled?: boolean;
    onClick: () => void;
};

export const IncognitoToggle: FC<Props> = ({ checked, disabled, onClick }: Props) => {
    const { t } = useTranslation();
    return (
        <Stack gap="8px">
            <IncognitoToggleWrapper onClick={() => undefined}>
                <Switch
                    dataTrackId="incognito__switch"
                    name="incognito__switch"
                    checked={checked}
                    disabled={disabled}
                    onChange={onClick}
                />
                <StyledText variant="bodyMBold" as="span" color="secondary">
                    {t('admin:page_connect_as__incognito_placeholder')}
                    {disabled && <span>*</span>}
                </StyledText>
            </IncognitoToggleWrapper>
            {disabled && (
                <Text variant="bodyMRegular" as="span" color="secondary">
                    *{t('admin:page_connect_as__incognito_description')}
                    <a
                        href="https://chrome.google.com/webstore/detail/partoo-extension/mbpokkkmolldhgnmdojcggoanoajldhb"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Text as="span" variant="bodyMSemibold" color="#0085F2">
                            {t('admin:page_connect_as__incognito_link')}
                        </Text>
                    </a>
                </Text>
            )}
        </Stack>
    );
};
