import styled from '@emotion/styled';

import { HEX_COLORS, greyColorObject } from '@partoohub/branding';
import { Tooltip } from '@partoohub/ui';

import { FooterNav } from '../Sidebar.styled';

export const LinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .link {
        position: relative;

        padding: 8px;

        height: 64px;
        width: 64px;

        fill: ${HEX_COLORS.blackberry};

        transition:
            background-color 0.2s,
            padding 0.05s;

        &.active {
            padding: 0px;

            background-color: ${greyColorObject.light};

            .icon_wrapper {
                padding: 12px;
            }
        }

        &:hover .icon_wrapper {
            background-color: ${greyColorObject.light};
        }

        &.active,
        &:hover .icon_wrapper {
            fill: ${({ theme }) => theme.colors.primary.initial};
        }

        &:before,
        &:after {
            position: absolute;
            right: 0px;

            content: '';
            width: 30px;
            height: 8px;

            background-color: transparent;
            box-shadow: transparent 0px 0px 0px 0px;

            pointer-events: none;
            transition: box-shadow 0.1s;
        }

        &:before {
            top: 0px;

            border-radius: 0px 0px 4px;

            transform: translateY(-100%);
        }

        &:after {
            bottom: 0px;

            border-radius: 0px 4px 0px 0px;

            transform: translateY(100%);
        }

        &.active:before {
            box-shadow: rgb(245 247 251) 0px 4px 0px 0px;
        }

        &.active:after {
            box-shadow: rgb(245 247 251) 0px -4px 0px 0px;
        }

        svg {
            transition: fill 0.2s;
        }
    }

    ${FooterNav} & .link:after {
        display: none;
    }
`;

export const IconWrapper = styled.div`
    border-radius: 8px;
    padding: 4px;

    background-color: transparent;

    transition:
        background-color 0.2s,
        padding 0.05s;
`;

export const ChipWrapper = styled.div`
    position: absolute;
    top: 4px;
    right: 4px;
`;

export const StyledTooltip = styled(Tooltip)`
    left: -16px;
    padding: 8px 16px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    &::after {
        top: 50%;
    }
`;
