import { MouseEvent } from 'react';

import { FuzzySearchOrgsReceivedData, V2OrgData } from '@partoohub/api-client';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UseInfiniteQueryResult } from 'react-query/types/react/types';

import { Menu } from 'app/common/designSystem/components/molecules/AsyncSubMenuMultipleSelect';

import { MenuItem, SectionContainer } from './Section.styled';

interface GroupsSectionProps {
    query: UseInfiniteQueryResult<FuzzySearchOrgsReceivedData>;
    items: Array<V2OrgData> | undefined;
    onClickItem: (event: MouseEvent<HTMLDivElement>, org: V2OrgData) => void;
}

const OrgsSection = ({ query, items, onClickItem }: GroupsSectionProps) => {
    const { t } = useTranslation();

    return (
        <SectionContainer>
            <Menu.Section title={t('org')}>
                <InfiniteScroll
                    next={() => query.fetchNextPage()}
                    hasMore={!!query.hasNextPage}
                    loader={null}
                    dataLength={items?.length ?? 0}
                    scrollableTarget="scrollable-business-menu"
                >
                    {items?.map(org => (
                        <MenuItem key={org.org_id} onClick={event => onClickItem(event, org)}>
                            {org.name} ({org.org_id})
                        </MenuItem>
                    ))}
                </InfiniteScroll>
            </Menu.Section>
        </SectionContainer>
    );
};

export default OrgsSection;
