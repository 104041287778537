import { format, isValid, parseISO } from 'date-fns';
import qs from 'query-string';

import {
    BUSINESSES_CITIES,
    BUSINESSES_GROUPS,
    BUSINESSES_ORGS,
    BUSINESSES_QUERIES,
    BUSINESSES_SELECT_ALL,
    EXCLUDED_BUSINESSES,
    INCLUDED_BUSINESSES,
} from 'app/common/data/routeIds';

/** Variant of qs.stringify with nice looking defaults for arrays
 *  in parameters object.
 *  Also skips empty string to allow get parameter deletion using :
 *  navigate({search:'a='}, {replace:true}); // this will delete get param `a`
 *  // https://www.npmjs.com/package/query-string#api
 */
export const stringify = (parameters: Record<string, any>, options?: Record<string, any>): string =>
    qs.stringify(parameters, {
        arrayFormat: 'separator',
        arrayFormatSeparator: ',',
        skipEmptyString: true,
        ...options,
    });

/** Variant of qs.parse with defaults for parsing strings
 *  computed by the `stringify` function above.
 *  // https://www.npmjs.com/package/query-string#api
 */
export const parse = (parameters: string, options?: Record<string, any>): Record<string, any> =>
    qs.parse(parameters, {
        arrayFormat: 'separator',
        arrayFormatSeparator: ',',
        ...options,
    });

export const parseNumberArray = (value: string) =>
    parseStringArray(value)
        .map(s => parseInt(s, 10))
        .filter(n => !Number.isNaN(n));

export const stringifyNumberArray = (value: Array<number>) => value.join(',');

export const parseStringArray = (value: string) => value.split(',').filter(Boolean);

export const stringifyStringArray = (value: Array<string>) => value.join(',');

export const identity = <T>(_: T) => _;

export const parseDateFns = (value: string) => {
    const parsedDate = parseISO(value);
    return isValid(parsedDate) ? parsedDate : null;
};

export const stringifyDateFns = (value: Date | null) => {
    return value !== null ? format(value, 'yyyy-MM-dd') : '';
};

export const businessParams = (): string => {
    /* Return date and business related params as string */
    const reservedParams: string[] = [
        BUSINESSES_SELECT_ALL,
        INCLUDED_BUSINESSES,
        EXCLUDED_BUSINESSES,
        BUSINESSES_QUERIES,
        BUSINESSES_CITIES,
        BUSINESSES_GROUPS,
        BUSINESSES_ORGS,
    ];
    const queryParamsObj = qs.parse(location.search);
    const filteredQueryParamsObj = Object.entries(queryParamsObj).reduce((acc, cur) => {
        if (reservedParams.indexOf(cur[0]) > -1) {
            acc[cur[0]] = cur[1];
        }
        return acc;
    }, {});
    return qs.stringify(filteredQueryParamsObj);
};
