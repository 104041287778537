import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

export type MessagingStatsQueryParams = {
    conversations_created_before: string;
    created_after?: string;
};

export type MessagingStatsData = {
    unansweredConversations: number;
    unansweredLateConversations: number;

    // Data between "created_after" and now
    actualAverageResponseTime: number | null;
    // Data between "created_after" and "conversations_created_before"
    previousAverageResponseTime: number | null;
};

/**
 * Only the hidden messaging/stats endpoint for mobile and web homepages
 */
class MessagingStats extends ApiResourceBaseClass {
    static getMessagingStats(queryParams: MessagingStatsQueryParams): Promise<MessagingStatsData> {
        return MessagingStats.get('messaging/stats', queryParams).then(({ data }) => {
            return data;
        });
    }
}

export default MessagingStats;
