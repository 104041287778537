import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

type MenuPhotoDeleteClickableProps = {
    photoUrl: string;
};

export const ModalWrapper = styled.div`
    padding: 40px 8px 8px;
    width: 500px;
`;

export const ModalContent = styled.div`
    padding: 0px 16px;
`;

export const ModalTitle = styled.div`
    margin-bottom: 8px;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
`;

export const ModalSubtitle = styled.div`
    margin-bottom: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
`;

export const CancelButton = styled.div`
    margin-bottom: 16px;
    text-align: center;
`;

export const AddItemName = styled.div`
    margin-right: 8px;
    flex: 2;
`;

export const AddItemPrice = styled.div`
    width: 160px;
    min-width: 160px;
`;

export const AddItemDescription = styled.div`
    margin-right: 8px;
    flex: 2;
`;

export const AddItemDropzone = styled.div`
    height: 160px;
    width: 160px;
    min-width: 160px;
    min-height: 160px;
    max-height: 160px;
`;

export const AddItemInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AddItemInputLineWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ItemPhotoWrapper = styled.div`
    max-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ItemPhoto = styled.img`
    height: 160px;
    width: 160px;
    min-width: 160px;
    max-height: 160px;
    border: 1px solid #dfe3ec;
    border-radius: 4px;
`;

export const MenuPhotosDeleteClickable = styled.div`
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    height: 160px;
    width: 160px;
    min-width: 160px;
    max-height: 160px;
    border: 1px solid #dfe3ec;
    background-image: ${({ photoUrl }: MenuPhotoDeleteClickableProps) => `url(${photoUrl})`};

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: black;
        opacity: 0.1;
        transition: all ease-in-out 0.3s;
        z-index: 0;
    }

    &:hover::before {
        opacity: 0.7;
    }
`;

export const MenuPhotosDelete = styled.div`
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 1em;
    width: 100%;
    height: 206px;

    &:hover {
        color: darkgray;
        opacity: 1;
        transition: all ease-in-out 0.3s;
    }
`;

export const MenuPhotoDeleteIconButton = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: ${HEX_COLORS.white};
    color: ${({ theme }) => theme.colors.danger.initial};
    cursor: pointer;
    border-radius: 100px 100px 100px 100px;
`;
