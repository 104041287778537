import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Modal, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DeleteModalWrapper, DialogHeader } from './modal.styled';
import { ServiceModalFormType } from './utils';

type Props = {
    show: boolean;
    serviceToDelete?: ServiceModalFormType;
    handleClose: () => void;
    setServices: React.Dispatch<React.SetStateAction<Array<ServiceModalFormType>>>;
};
export const DeleteServiceModal = ({ show, serviceToDelete, handleClose, setServices }: Props) => {
    const { t } = useTranslation();

    const deleteService = () => {
        setServices(elem => elem.filter(obj => obj.name !== serviceToDelete?.name));
        handleClose();
    };

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Delete,
    };

    return (
        <Modal isOpen={show} closeModal={() => handleClose()}>
            <DeleteModalWrapper>
                <Dialog
                    dataTrackId=""
                    header={
                        <DialogHeader>
                            <Illustration
                                dataTrackId="delete_service_modal__illustration"
                                illustration={illustrationElement}
                                width="320"
                                height="140"
                            />
                        </DialogHeader>
                    }
                    title={t('delete_service_modal_title')}
                    subTitle={t('delete_service_modal_content', {
                        serviceName: serviceToDelete?.name,
                    })}
                >
                    <Stack direction="row">
                        <Button
                            dataTrackId="cancel_delete_service"
                            variant="secondary"
                            size="large"
                            onClick={() => handleClose()}
                            appearance="outlined"
                            full
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            dataTrackId="delete_delete_service"
                            variant="danger"
                            size="large"
                            onClick={deleteService}
                            full
                        >
                            {t('delete')}
                        </Button>
                    </Stack>
                </Dialog>
            </DeleteModalWrapper>
        </Modal>
    );
};
