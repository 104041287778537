import styled from '@emotion/styled';

export const SearchFiltersContainer = styled.div`
    margin-bottom: 24px;
    padding-inline: 24px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 16px;
`;

export const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    padding: 0 12px;

    min-height: 46px;
    margin-top: 19px;

    background-color: ${({ theme }) => theme.colors.danger.alpha};
    color: ${({ theme }) => theme.colors.danger.initial};
    border-radius: 4px;

    > i {
        margin-right: 12px;
    }
`;
