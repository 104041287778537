import { useEffect, useMemo } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    LeftElementType,
    TextInput,
    TextInputProps,
} from '@partoohub/ui';
import { validate as isValidEmail } from 'email-validator';
import { Control, useController, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { FeedbackFormData } from 'app/api/types/feedbackForm';

import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { FEEDBACK_MANAGEMENT_BASE_URL } from 'admin/common/data/config';
import { stringifyDate } from 'admin/common/utils/dates';

import { StyledContainer, StyledFooter, StyledTitle } from './CreateOrUpdateSurveyContent.styled';
import { useCreateOrUpdateFeedbackForm } from '../../hooks/useCreateOrUpdateFeedbackForm';

type Props = {
    orgId: number;
    onHide: () => void;
    survey?: FeedbackFormData;
};

const DEFAULT_FORM_DATA = {
    email_reply_source: '',
    email_reply_subject: '',
    external_id: '',
    expiration_interval_seconds: 1209600,
    display_name: '',
};

export const CreateOrUpdateSurveyContent = ({ orgId, survey, onHide }: Props) => {
    const { t } = useTranslation();
    const {
        getValues,
        register,
        control,
        reset,
        setError,
        watch,
        formState: { isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: DEFAULT_FORM_DATA,
    });

    useEffect(() => {
        if (survey) {
            reset({
                ...DEFAULT_FORM_DATA,
                ...survey,
                email_reply_source: survey?.email_reply_source ?? '',
                email_reply_subject: survey?.email_reply_subject ?? '',
                external_id: survey?.external_id ?? '',
            });
        }
    }, [survey, reset]);

    const watchDisplayName = watch('display_name');

    const required = t('field_required');

    register('display_name', { required });
    register('email_reply_source', {
        validate: {
            email: v => isValidEmail(v) || t('email_invalid'),
        },
    });
    register('email_reply_subject');
    register('external_id');

    const { createOrUpdateFeedbackForm, isCreatingOrUpdating, isSuccess } =
        useCreateOrUpdateFeedbackForm(setError);
    const disableSaveButton = isCreatingOrUpdating || !watchDisplayName;

    useEffect(() => {
        if (isSuccess && isValid) onHide();
    }, [isSuccess, isValid]);

    const handleSaveForm = () => {
        createOrUpdateFeedbackForm(survey?.id, {
            org_id: orgId,
            ...getValues(),
        });
    };
    return (
        <StyledContainer>
            <div>
                <StyledTitle variant="heading2">
                    {t('admin:feedback_management__surveys')}
                </StyledTitle>
                <PageFormRow>
                    <ControlledTextInput
                        dataTrackId="feedback_management__survey_name"
                        label={t('admin:feedback_management__survey_name')}
                        name="display_name"
                        control={control}
                        assistiveMessages={t('admin:feedback_management__survey_name_notice')}
                        required
                    />
                </PageFormRow>
                <PageFormRow>
                    <ControlledTextInput
                        dataTrackId="feedback_management__email"
                        label={t('admin:feedback_management__email')}
                        leftElementType={LeftElementType.Icon}
                        leftElement={[FontAwesomeIconsPartooUsed.faEnvelope, IconPrefix.REGULAR]}
                        name="email_reply_source"
                        assistiveMessages={t('admin:feedback_management__email_notice')}
                        control={control}
                    />
                    <ControlledTextInput
                        dataTrackId="feedback_management__email_subject"
                        label={t('admin:feedback_management__email_subject')}
                        name="email_reply_subject"
                        control={control}
                    />
                </PageFormRow>
                <PageFormRow>
                    <ControlledTextInput
                        dataTrackId="feedback_management__survey_id"
                        label={t('admin:feedback_management__survey_id')}
                        name="external_id"
                        control={control}
                    />
                </PageFormRow>
                {survey?.id && (
                    <PageFormRow>
                        <TextInput
                            label={t('admin:feedback_management__survey_link')}
                            value={`${FEEDBACK_MANAGEMENT_BASE_URL}/${survey.id}?email=email&store_code=store_code&firstname=firstname&lastname=lastname&date=${stringifyDate(new Date())}`}
                            type="text"
                            copy={true}
                            dataTrackId={'feedback_management__survey_link'}
                            readOnly
                            copiedText={t('admin:feedback_management__survey_link_copied')}
                        />
                    </PageFormRow>
                )}
            </div>
            <StyledFooter>
                <SubmitButton
                    dataTrackId="feedback_management__submit"
                    disabled={disableSaveButton}
                    text={t('save')}
                    onClick={handleSaveForm}
                    icon={[FontAwesomeIconsPartooUsed.faSave, IconPrefix.REGULAR]}
                />
            </StyledFooter>
        </StyledContainer>
    );
};

type ControlledTextInputProps = TextInputProps<any> & {
    control: Control<any>;
    name: string;
    assistiveMessages?: string;
};

const ControlledTextInput = ({
    control,
    name,
    assistiveMessages,
    ...props
}: ControlledTextInputProps) => {
    const {
        field,
        formState: { errors },
    } = useController({ control, name, defaultValue: '' });

    const errorMessage = errors[name]?.message;
    const errorText = typeof errorMessage === 'string' ? errorMessage : undefined;

    const Notice = useMemo(() => {
        return <div>{errorText || assistiveMessages}</div>;
    }, [errorText, assistiveMessages]);

    return <TextInput {...props} {...field} error={!!errorText} notice={Notice} />;
};
