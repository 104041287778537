import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { UploadAudioResponse } from 'app/api/v2/api_calls/messagingCallDeflectionApiCalls';

export const useUploadAudio = (
    onSuccess: (response: UploadAudioResponse) => void = () => undefined,
    onError: (error: Error) => void = () => undefined,
) => {
    return useMutation((payload: File) => api.MessagingCallDeflection.uploadAudio(payload), {
        onSuccess,
        onError,
    });
};
