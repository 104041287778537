import styled from '@emotion/styled';

export const OptionIcons = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 8px;
`;

export const AddMenuSection = styled.div`
    margin-bottom: 24px;
`;

export const BusinessSelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
