import { useState } from 'react';

import { Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import BusinessSelect from 'admin/common/components/inputs/BusinessSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import ReviewCleanerConfirmModal from 'admin/content/playground/pages/ReviewManagement/modale/ReviewCleanerConfirmModal';

export default function () {
    const { t } = useTranslation();
    const [businesses, setBusinesses] = useState<Option | undefined>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <AdminPage
            dataTrackId="page_review_cleaner"
            title={t('admin:page_review_cleaner__title')}
            description={t('admin:page_review_cleaner__description')}
        >
            <ReviewCleanerConfirmModal
                show={!!showModal}
                businessId={businesses?.value ?? ''}
                title={businesses?.label as string}
                hide={() => setShowModal(false)}
            />
            <BorderedContainer>
                <PageFormRow>
                    <BusinessSelect
                        dataTrackId="admin_obsolete_reviews_cleaning__select_business"
                        setBusinesses={setBusinesses}
                        businesses={businesses}
                        placeholder={t('admin:filter_select_business')}
                    />
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin_obsolete_reviews_cleaning__check_reviews"
                onClick={() => setShowModal(true)}
                text={t('admin:page_review_cleaner__submit_button')}
                disabled={!businesses}
            />
        </AdminPage>
    );
}
