import styled from '@emotion/styled';

export const ChoiceText = styled.div`
    display: block;
    flex-direction: column;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const SuggestedOptionItem = styled.button`
    background-color: ${({ theme }) => theme.colors.primary.alpha};
    border-radius: 12px;
    border: none;
    color: ${({ theme }) => theme.colors.primary.initial};
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-right: 4px;
    margin-bottom: 8px;
    justify-content: space-between;
    height: 24px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
`;

export const SuggestedOptionsHeader = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 6px 0 12px 4px;
`;

export const SuggestedOptionsItems = styled.div`
    display: flex;
    flex-wrap: wrap;

    padding-bottom: 8px;
    border-bottom: solid 1px #f6f8fc;
    margin-bottom: 14px;
`;

export const SuggestedOptionItemIcon = styled.div`
    margin-left: 8px;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    min-width: 17px;
`;
