import React from 'react';

export const RightToBracketIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            strokeWidth="0.1"
            d="M20 8V16C20 17.6875 18.6562 19 17 19H14.75C14.3125 19 14 18.6875 14 18.25C14 17.8438 14.3125 17.5 14.75 17.5H17C17.8125 17.5 18.5 16.8438 18.5 16V8C18.5 7.1875 17.8125 6.5 17 6.5H14.75C14.3125 6.5 14 6.1875 14 5.75C14 5.34375 14.3125 5 14.75 5H17C18.6562 5 20 6.34375 20 8ZM15.4688 12.5625L10.7188 16.75C10.5312 16.9375 10.2812 17 10.0312 17C9.875 17 9.71875 17 9.59375 16.9375C9.21875 16.75 9 16.4062 9 16V14.25H5.25C4.53125 14.25 4 13.7188 4 13V11C4 10.3125 4.53125 9.75 5.25 9.75H9V8.03125C9 7.625 9.21875 7.28125 9.59375 7.09375C9.96875 6.9375 10.4062 7 10.7188 7.28125L15.4688 11.4375C15.6562 11.5938 15.75 11.8125 15.75 12C15.75 12.2188 15.6562 12.4375 15.4688 12.5625ZM13.8438 12L10.5 9.0625V11.25H5.5V12.75H10.5V14.9688L13.8438 12Z"
        />
    </svg>
);

export const DatabaseIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            strokeWidth="0.1"
            d="M12 20C8.125 20 5 18.9062 5 17.5V6.5C5 5.125 8.125 4 12 4C15.8438 4 19 5.125 19 6.5V17.5C19 18.9062 15.8438 20 12 20ZM17.5 6.71875L17.4688 6.6875C17.3125 6.5625 16.9688 6.34375 16.4375 6.15625C15.375 5.78125 13.7812 5.5 12 5.5C10.1875 5.5 8.59375 5.78125 7.53125 6.15625C7 6.34375 6.65625 6.5625 6.5 6.6875V6.71875V9.46875C6.90625 9.6875 7.46875 9.90625 8.1875 10.0938C9.25 10.3438 10.5625 10.5 12 10.5C13.4062 10.5 14.7188 10.3438 15.7812 10.0938C16.5 9.90625 17.0625 9.6875 17.5 9.46875V6.71875ZM17.5 11.125C17.0625 11.2812 16.625 11.4375 16.125 11.5625C14.9375 11.8438 13.5 12 12 12C10.4688 12 9.03125 11.8438 7.84375 11.5625C7.34375 11.4375 6.90625 11.2812 6.5 11.125V13.4688C6.90625 13.6875 7.46875 13.9062 8.1875 14.0938C9.25 14.3438 10.5625 14.5 12 14.5C13.4062 14.5 14.7188 14.3438 15.7812 14.0938C16.5 13.9062 17.0625 13.6875 17.5 13.4688V11.125ZM6.5 17.3438C6.65625 17.4688 7 17.6875 7.53125 17.875C8.59375 18.25 10.1875 18.5 11.9688 18.5C13.7812 18.5 15.375 18.25 16.4375 17.875C16.9688 17.6875 17.3125 17.4688 17.4688 17.3438L17.5 17.3125V15.125C17.0625 15.2812 16.625 15.4375 16.125 15.5625C14.9375 15.8438 13.5 16 11.9688 16C10.4688 16 9.03125 15.8438 7.84375 15.5625C7.34375 15.4375 6.90625 15.2812 6.46875 15.125V17.3125L6.5 17.3438ZM17.5625 17.25L17.5312 17.2812C17.5625 17.25 17.5625 17.25 17.5625 17.25ZM6.4375 17.2812C6.4375 17.25 6.40625 17.25 6.40625 17.25L6.4375 17.2812ZM6.4375 6.75C6.40625 6.78125 6.40625 6.78125 6.40625 6.78125L6.4375 6.75ZM17.5625 6.78125C17.5625 6.78125 17.5625 6.78125 17.5312 6.75L17.5625 6.78125Z"
        />
    </svg>
);

export const AppleIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            strokeWidth="1.5"
            d="M11.44 5C11.44 5 11.4915 5 11.5172 5C12.4637 5 13.4939 4.52656 14.1378 3.79644C14.801 3.0435 15.1036 2.13655 14.9684 1.30946C14.9362 1.12123 14.7495 0.984332 14.5306 1.00144C13.5969 1.05848 12.541 1.56044 11.8971 2.25634C11.2017 3.00928 10.8798 3.92193 11.0408 4.70339C11.073 4.87451 11.2404 4.9943 11.4335 5H11.44Z"
        />
        <path
            strokeWidth="1.5"
            d="M20.954 17.0757C20.8964 16.9404 20.7883 16.8407 20.6515 16.7837C18.4759 15.972 17.7915 13.6364 18.411 11.9417C18.7136 11.1015 19.3187 10.4179 20.1472 9.96217C20.2841 9.89097 20.3777 9.75567 20.4137 9.60614C20.4498 9.45661 20.4065 9.29996 20.3057 9.17891C19.2035 7.81887 17.633 7 16.1058 7C15.0396 7 14.312 7.27058 13.678 7.51268C13.2098 7.6907 12.7991 7.84023 12.3381 7.84023C11.8266 7.84023 11.3511 7.67646 10.8108 7.4842C10.1769 7.25634 9.4565 7.00712 8.556 7.00712C6.81985 7.00712 5.06208 8.04673 3.96708 9.71295C3.56365 10.3324 3.27549 11.1015 3.12421 11.9915C2.68477 14.5336 3.42678 17.5599 5.1053 20.0877C6.05623 21.5189 7.25929 22.9858 8.93781 23H8.97383C9.67262 23 10.1409 22.7935 10.6019 22.5941C11.0846 22.3805 11.5384 22.1811 12.3597 22.174H12.3885C13.1954 22.174 13.6348 22.3734 14.1031 22.587C14.5497 22.7864 15.0108 23 15.7095 23H15.7384C17.3809 22.9858 18.6344 21.4477 19.6357 19.9381C20.2553 19.0053 20.4858 18.5283 20.954 17.4958C21.0117 17.3676 21.0189 17.2109 20.954 17.0828V17.0757Z"
        />
    </svg>
);

export const ShopIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2 9.8125C2 9.625 2.03125 9.4375 2.125 9.25L4.5625 4.8125C4.8125 4.3125 5.3125 4 5.875 4H18.0938C18.6562 4 19.1562 4.3125 19.4062 4.8125L21.8438 9.25C21.9375 9.4375 22 9.625 22 9.8125C22 10.4688 21.4375 11 20.7812 11H20V19.25C20 19.6875 19.6562 20 19.25 20C18.8125 20 18.5 19.6875 18.5 19.25V11H14V18.75C14 19.4688 13.4375 20 12.75 20H5.21875C4.53125 20 3.96875 19.4688 3.96875 18.75V11H3.1875C2.53125 11 2 10.4688 2 9.8125ZM5.46875 11V14H12.5V11H5.46875ZM5.875 5.5L3.6875 9.5H20.2812L18.0938 5.5H5.875ZM12.5 18.5V15.5H5.46875V18.5H12.5Z"
            strokeWidth="0.1"
        />
    </svg>
);

export const BuildingIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.75 7.25C8.75 7 8.96875 6.75 9.25 6.75H10.75C11 6.75 11.25 7 11.25 7.25V8.75C11.25 9.03125 11 9.25 10.75 9.25H9.25C8.96875 9.25 8.75 9.03125 8.75 8.75V7.25ZM14.75 6.75C15 6.75 15.25 7 15.25 7.25V8.75C15.25 9.03125 15 9.25 14.75 9.25H13.25C12.9688 9.25 12.75 9.03125 12.75 8.75V7.25C12.75 7 12.9688 6.75 13.25 6.75H14.75ZM8.75 11.25C8.75 11 8.96875 10.75 9.25 10.75H10.75C11 10.75 11.25 11 11.25 11.25V12.75C11.25 13.0312 11 13.25 10.75 13.25H9.25C8.96875 13.25 8.75 13.0312 8.75 12.75V11.25ZM14.75 10.75C15 10.75 15.25 11 15.25 11.25V12.75C15.25 13.0312 15 13.25 14.75 13.25H13.25C12.9688 13.25 12.75 13.0312 12.75 12.75V11.25C12.75 11 12.9688 10.75 13.25 10.75H14.75ZM6 6C6 4.90625 6.875 4 8 4H16C17.0938 4 18 4.90625 18 6V18C18 19.125 17.0938 20 16 20H8C6.875 20 6 19.125 6 18V6ZM7.5 6V18C7.5 18.2812 7.71875 18.5 8 18.5H10.5V16.5C10.5 15.6875 11.1562 15 12 15C12.8125 15 13.5 15.6875 13.5 16.5V18.5H16C16.25 18.5 16.5 18.2812 16.5 18V6C16.5 5.75 16.25 5.5 16 5.5H8C7.71875 5.5 7.5 5.75 7.5 6Z"
            strokeWidth="0.1"
        />
    </svg>
);

export const BuildingsIcon = (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18 5.5H13C12.7188 5.5 12.5 5.75 12.5 6V18C12.5 18.2812 12.7188 18.5 13 18.5H18C18.25 18.5 18.5 18.2812 18.5 18V6C18.5 5.75 18.25 5.5 18 5.5ZM13 4H18C19.0938 4 20 4.90625 20 6V18C20 19.125 19.0938 20 18 20H13C11.875 20 11 19.125 11 18V6C11 4.90625 11.875 4 13 4ZM10 8V9.5H6.25C5.8125 9.5 5.5 9.84375 5.5 10.25V17.75C5.5 18.1875 5.8125 18.5 6.25 18.5H10.0312C10.125 19.0938 10.375 19.5938 10.75 20H6.25C5 20 4 19 4 17.75V10.25C4 9.03125 5 8 6.25 8H10ZM7.5 14H8.5C8.75 14 9 14.25 9 14.5V15.5C9 15.7812 8.75 16 8.5 16H7.5C7.21875 16 7 15.7812 7 15.5V14.5C7 14.25 7.21875 14 7.5 14ZM14.5 14.5V13.5C14.5 13.25 14.7188 13 15 13H16C16.25 13 16.5 13.25 16.5 13.5V14.5C16.5 14.7812 16.25 15 16 15H15C14.7188 15 14.5 14.7812 14.5 14.5ZM7.5 11H8.5C8.75 11 9 11.25 9 11.5V12.5C9 12.7812 8.75 13 8.5 13H7.5C7.21875 13 7 12.7812 7 12.5V11.5C7 11.25 7.21875 11 7.5 11ZM14.5 7.5C14.5 7.25 14.7188 7 15 7H16C16.25 7 16.5 7.25 16.5 7.5V8.5C16.5 8.78125 16.25 9 16 9H15C14.7188 9 14.5 8.78125 14.5 8.5V7.5ZM15 12C14.7188 12 14.5 11.7812 14.5 11.5V10.5C14.5 10.25 14.7188 10 15 10H16C16.25 10 16.5 10.25 16.5 10.5V11.5C16.5 11.7812 16.25 12 16 12H15Z"
            strokeWidth="0.1"
        />
    </svg>
);
