import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { feedbackReplySuggestionApiClient } from 'admin/api/apiResources';
import { FEEDBACK_REPLY_SUGGESTION_PROMPT } from 'admin/common/data/queryKeysConstants';

export const useDeleteFeedbackReplySuggestionPrompt = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { mutate } = useMutation(
        ({ orgId }: { orgId: number }) => feedbackReplySuggestionApiClient.deletePrompt(orgId),
        {
            //To update the view after deleting
            onMutate: async ({ orgId }) => {
                await queryClient.cancelQueries([FEEDBACK_REPLY_SUGGESTION_PROMPT, orgId]);
                const previousPrompt = queryClient.getQueryData([
                    FEEDBACK_REPLY_SUGGESTION_PROMPT,
                    orgId,
                ]);
                queryClient.setQueryData([FEEDBACK_REPLY_SUGGESTION_PROMPT, orgId], null);
                return { previousPrompt };
            },

            onSuccess: (_, { orgId }) => {
                queryClient.invalidateQueries([FEEDBACK_REPLY_SUGGESTION_PROMPT, orgId]);
                toast.success(
                    t('admin:feedback_management__delete_prompt_success_toast_content'),
                    t('admin:feedback_management__update_prompt_success_toast_title'),
                );
            },
            onError: (_, { orgId }, context) => {
                queryClient.setQueryData(
                    [FEEDBACK_REPLY_SUGGESTION_PROMPT, orgId],
                    context?.previousPrompt,
                );
                toast.error(
                    t('admin:feedback_management__delete_prompt_error_toast_content'),
                    t('admin:feedback_management__update_prompt_error_toast_title'),
                );
            },
        },
    );

    return mutate;
};
