import React, { MouseEvent } from 'react';

import { useTranslation } from 'react-i18next';

import { Menu } from 'app/common/designSystem/components/molecules/AsyncSubMenuMultipleSelect';

type Props = {
    onClickItem: (event: MouseEvent<HTMLDivElement>, city: string) => void;
    items?: Array<string>;
};

const CitiesSection = ({ items, onClickItem }: Props) => {
    const { t } = useTranslation();

    return (
        <Menu.Section title={t('business_drawer_cities')}>
            {items?.map(city => (
                <Menu.Item
                    key={city}
                    onClick={event => onClickItem(event, city)}
                >
                    {city}
                </Menu.Item>
            ))}
        </Menu.Section>
    );
};

export default CitiesSection;
