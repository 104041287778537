import { Checkbox } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { GroupSectionContainer, NoGroupSectionHeader, SectionName } from '../GroupSection.styled';

type Props = {
    onClick: (id: string, label: string, checked: boolean) => void;
    selectedGroups: string[];
};

export const NO_GROUP_ID = '-1';
const NO_GROUP_LABEL = 'No Group'; // "Internal" use only, not to be displayed

export const NoGroupSection = ({ onClick, selectedGroups }: Props) => {
    const { t } = useTranslation();

    return (
        <GroupSectionContainer>
            <NoGroupSectionHeader>
                <SectionName disabled={false}>{t('other_group_name')}</SectionName>
                <Checkbox
                    dataTrackId="group_section__checkbox"
                    aria-label=""
                    onChange={() =>
                        onClick(NO_GROUP_ID, NO_GROUP_LABEL, selectedGroups.includes(NO_GROUP_ID))
                    }
                    checked={selectedGroups.includes(NO_GROUP_ID)}
                    disabled={false}
                />
            </NoGroupSectionHeader>
        </GroupSectionContainer>
    );
};
