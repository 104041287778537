import React, {
    ComponentPropsWithRef,
    ComponentType,
    ElementType,
    FC,
    ReactElement,
    Ref,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Placement } from '@popperjs/core';
import useResizeObserver from '@react-hook/resize-observer';
import ReactDOM from 'react-dom';
import mergeRefs from 'react-merge-refs';
import { usePopper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

import { BlackTooltip } from './TooltipWrapper.styled';

type TooltipPortalProps = {
    text: string;
    className?: string;
    position?: Placement;
    referenceElement?: HTMLElement | null;
    tooltip?: ComponentType<ComponentPropsWithRef<ElementType>>;
    xOffset?: number;
    yOffset?: number;
};

const TooltipPortal = React.forwardRef<HTMLElement, TooltipPortalProps>(
    (
        {
            text,
            className,
            referenceElement,
            tooltip,
            position = 'bottom',
            xOffset,
            yOffset,
        },
        ref,
    ) => {
        const [popperElement, setPopperElement] = useState<HTMLElement | null>(
            null,
        );

        // https://popper.js.org/docs/v2/modifiers/offset/
        const offsetModifier = {
            name: 'offset',
            options: { offset: [xOffset || 0, yOffset || 0] },
        };

        const modifiers = [
            (xOffset !== undefined || yOffset !== undefined) && offsetModifier,
        ].filter(Boolean) as Array<typeof offsetModifier>;

        const { styles, attributes, update } = usePopper(
            referenceElement,
            popperElement,
            {
                placement: position,
                modifiers,
            },
        );

        // watch target component resize and update tooltip to recalculate its position
        // (mostly useful for TextArea component)
        // https://popper.js.org/react-popper/v2/hook/#update-forceupdate-and-state
        useResizeObserver(referenceElement || null, () => {
            update?.();
        });

        const TooltipElement = tooltip || BlackTooltip;

        return ReactDOM.createPortal(
            <TooltipElement
                ref={mergeRefs([setPopperElement, ref])}
                style={styles.popper}
                className={className}
                {...attributes.popper}
            >
                {text}
            </TooltipElement>,
            document.body,
        );
    },
);

type RenderPropArgs = {
    innerRef: Ref<HTMLElement>;
};

type Props = {
    onHide?: () => void;
    children: ReactElement | ((args: RenderPropArgs) => ReactElement);
} & Omit<TooltipPortalProps, 'referenceElement'>;

/**
 * A component that can wrap any component to show a tooltip on hover.
 * Usage:
 * ```
 * <TooltipWrapper text='the tooltip text'>
 *     <TargetElement/>
 * </TooltipWrapper>
 * ```
 * /!\ TargetElement must be able to provide a ref !
 */
const TooltipWrapper: FC<Props> = React.forwardRef<HTMLElement, Props>(
    (props, ref) => {
        const { onHide, text, children } = props;

        const tooltipRef = useRef<HTMLElement>(null);

        // basic react-popper usage
        // https://popper.js.org/react-popper/v2/
        const [referenceElement, setReferenceElement] =
            useState<HTMLElement | null>(null);

        // TODO: use a useHover custom hook
        const [hover, setHover] = useState<boolean>(false);

        const handleMouseEnter = () => {
            setHover(true);
        };

        const handleMouseLeave = () => {
            setHover(false);
        };

        useEffect(() => {
            referenceElement?.addEventListener('mouseenter', handleMouseEnter);
            referenceElement?.addEventListener('mouseleave', handleMouseLeave);
            return () => {
                referenceElement?.removeEventListener(
                    'mousenter',
                    handleMouseEnter,
                );
                referenceElement?.removeEventListener(
                    'mouseleave',
                    handleMouseLeave,
                );
            };
        }, [referenceElement]);

        let child: ReactElement | null = null;
        if (typeof children === 'function') {
            child = children({
                innerRef: setReferenceElement,
            });
        } else if (typeof children === 'object') {
            // ReactElement
            child = React.cloneElement(children, {
                ref: mergeRefs([setReferenceElement, ref]),
            });
        }

        const visible = !!text && hover;

        return (
            <>
                {child}
                <CSSTransition
                    nodeRef={tooltipRef}
                    in={visible}
                    classNames="fade"
                    timeout={100}
                    unmountOnExit
                    onExited={onHide}
                >
                    <TooltipPortal
                        ref={tooltipRef}
                        referenceElement={referenceElement}
                        {...props}
                    />
                </CSSTransition>
            </>
        );
    },
);

export default TooltipWrapper;
