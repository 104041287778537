import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const ModalWrapper = styled.div`
    padding: 24px;
    width: 500px;
`;

export const ButtonsWrapper = styled(Stack)`
    margin-top: 24px;
`;

export const DeleteModalWrapper = styled.div`
    width: 500px;
`;

export const ModalTitle = styled.div`
    margin-bottom: 8px;

    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
`;

export const EditModalTitle = styled(ModalTitle)`
    margin-bottom: 32px;
`;

export const ModalSubtitle = styled.div`
    margin-bottom: 24px;

    text-align: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
`;

export const DialogHeader = styled.div`
    padding-top: 24px;
`;

export const StructuredServiceName = styled.div`
    margin-bottom: 32px;
`;
