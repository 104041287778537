import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { CustomConfirmModalWithText } from './CustomConfirmModalWithText';
import { useCreateNewKeyword } from '../../hooks/useCreateNewKeyword';
import { useGetKeywordsFromOrg } from '../../hooks/useGetKeywordsFromOrg';
import { useOrgIdParsed } from '../../hooks/useOrgIdParsed';

type Props = {
    onClose: () => void;
};

export const ModalCreateKeyword = ({ onClose }: Props) => {
    const { t } = useTranslation();
    const [validationStyle, setValidationStyle] = useState<'disable' | 'error'>('disable');
    const { mutate } = useCreateNewKeyword();
    const orgId = useOrgIdParsed();
    const { keywords } = useGetKeywordsFromOrg(orgId);

    const createKeyword = (keyword: string) => {
        const inList = keywords.map(k => k.keyword.toLowerCase()).includes(keyword.toLowerCase());
        const lengthBetweenBoundaries = keyword.length >= 2 && keyword.length < 128;

        if ([Boolean(keyword), !inList, lengthBetweenBoundaries].every(condition => condition)) {
            mutate({ newKeyword: keyword, orgId });
            onClose();
        } else {
            setValidationStyle('error');
        }
    };

    return (
        <CustomConfirmModalWithText
            validationStyle={validationStyle}
            inputTextType="text"
            opened
            onClose={onClose}
            onConfirm={createKeyword}
            subTitle={t('admin:competitive_benchmark__modal_create_keyword_subtitle')}
            title={t('admin:competitive_benchmark__modal_create_keyword_title')}
            errorNotice={t('admin:competitive_benchmark__modal_create_keyword_error_notice')}
            inputTextLabel={t('keyword')}
            resetNotice={() => setValidationStyle('disable')}
        />
    );
};
