import { Button, Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useIsMatchingAccesses from 'app/common/components/AccessGroupModal/hooks/useIsMatchingAccesses';
import { FloatingFooter } from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/Footer/Footer.styled';
import useGroupsV2 from 'app/common/hooks/queries/useGroupsV2';

type Props = {
    value: Array<number>;
    onChange: (value: Array<number>) => void;
    saveModal: () => void;
    orgId?: number;
};

const AccessGroupsFooter = ({ value, onChange, saveModal, orgId }: Props) => {
    const { t } = useTranslation();

    const { sections } = useGroupsV2({ org_id: orgId });

    const isMatchingAccesses = useIsMatchingAccesses(value, sections);

    const validate = () => {
        if (isMatchingAccesses) {
            saveModal();
        }
    };

    const commonButtonProps = {
        shape: 'cube',
        size: 'large',
        full: true,
    } as const;

    return (
        <FloatingFooter>
            <Button
                {...commonButtonProps}
                appearance="outlined"
                onClick={() => onChange([])}
                dataTrackId={'business_modal_accesss__erase_button'}
                variant="secondary"
            >
                {t('business_drawer_erase')}
            </Button>

            <Tooltip
                placement="bottom"
                text={!isMatchingAccesses ? t('groups_must_match_accesses') : ''}
            >
                <Button
                    {...commonButtonProps}
                    appearance="contained"
                    onClick={validate}
                    disabled={!isMatchingAccesses}
                    variant="primary"
                    dataTrackId="business_modal_accesss__confirm_button"
                >
                    {t('business_drawer_confirm')}
                </Button>
            </Tooltip>
        </FloatingFooter>
    );
};

export default AccessGroupsFooter;
