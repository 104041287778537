import {
    ExpeditorNameResponse,
    InvitationDetailResponse,
    ReviewUrlResponse,
    SendInvitationData,
    SendInvitationResponse,
    TemplateResponse,
    UpdateExpeditorData,
    UpdateTemplateResponse,
} from 'app/api/types/review_booster';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'review_booster';

class ReviewBooster extends ApiResourceBaseClass {
    static sendInvitation(invitationData: SendInvitationData): Promise<SendInvitationResponse> {
        return ReviewBooster.post(`${RESOURCE_URI}/send_invitation`, invitationData).then(
            ({ data }) => data,
        );
    }

    static async getExpeditorName(businessId: string): Promise<ExpeditorNameResponse> {
        const { data } = await ReviewBooster.get(`${RESOURCE_URI}/expeditor`, {
            business_id: businessId,
        });

        return data;
    }

    static async getReviewUrl(businessId: string, minifyUrl = false): Promise<ReviewUrlResponse> {
        try {
            const { data } = await ReviewBooster.get(
                `${RESOURCE_URI}/get_review_url/${businessId}?minify_url=${minifyUrl}`,
            );

            return data;
        } catch (_) {
            return { review_url: '' };
        }
    }

    static updateExpeditorName(
        updateExpeditorData: UpdateExpeditorData,
    ): Promise<ExpeditorNameResponse> {
        return ReviewBooster.post(`${RESOURCE_URI}/expeditor`, updateExpeditorData).then(
            ({ data }) => data,
        );
    }

    static getTemplate(templateId?: number): Promise<TemplateResponse> {
        return ReviewBooster.get(
            `${RESOURCE_URI}/template`,
            templateId ? { template_id: templateId } : undefined,
        ).then(({ data }) => data);
    }

    static updateTemplate(template: string): Promise<UpdateTemplateResponse> {
        return ReviewBooster.put(`${RESOURCE_URI}/template`, {
            template,
        }).then(({ data }) => data);
    }

    static checkDuplicatedReviewInvitation(
        phone: string,
        businessId: string,
        after: string,
    ): Promise<InvitationDetailResponse> {
        return ReviewBooster.get(`${RESOURCE_URI}/search-invitation`, {
            target_phone: phone,
            business_id: businessId,
            after,
        }).then(({ data }) => data);
    }
}

export default ReviewBooster;
