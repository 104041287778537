import { useState } from 'react';

import { CategoryData } from '@partoohub/api-client';
import { Option, SingleSelect, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from 'react-query';

import { categoriesApiClient } from 'admin/api/apiResources';
import { CategoryDetailData } from 'admin/api/types/categories';

import { STATIC_BASE_URL } from 'admin/common/data/config';

import { CATEGORIES } from 'admin/common/data/queryKeysConstants';

import {
    RemapInformationContainer,
    RemapInformationDetails,
    RemapInformationIcon,
    RemapInformationSection,
} from './RemapInformation.styled';

type Props = {
    category: CategoryDetailData | null;
    changeCategoryValue: (selectedOption: CategoryData | null) => void;
    selectedCategory: CategoryData | null;
};

export const RemapInformation = ({ category, changeCategoryValue, selectedCategory }: Props) => {
    const { t } = useTranslation();
    const [userSearch, setUserSearch] = useState<string>('');

    const params = {
        state: 'OK',
        search_query: userSearch,
        order_by: 'gmb_id',
        live__isnull: true,
        with_names: true,
    };

    const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
        [CATEGORIES, { params }],
        ({ pageParam = 1 }) => categoriesApiClient.getCategoriesDetails(params, pageParam),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            keepPreviousData: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    const categoryToOption = (category: CategoryData): Option => {
        return {
            label: `${category.name || t('admin_categories_missinglabel')} (${category.gmb_id})`,
            value: `${category.id}`,
            name: `${category.id}`,
        };
    };
    const categories = data?.pages?.map(({ categories }) => categories)?.flat();

    const categoriesOptions = categories?.map(category => categoryToOption(category)) || [];

    const onChange = (selectedOption?: Option) => {
        if (!selectedOption?.value) {
            changeCategoryValue(null);
            return;
        }
        const selectedCategoryDetails = categories?.find(
            category => category.id.toString() === selectedOption.value,
        );
        changeCategoryValue(selectedCategoryDetails || null);
    };

    const selectedValue: Option | undefined =
        selectedCategory &&
        selectedCategory.names &&
        selectedCategory.gmb_id &&
        selectedCategory.id !== category?.id
            ? categoryToOption(selectedCategory)
            : undefined;

    return (
        <RemapInformationContainer>
            <RemapInformationSection>
                <RemapInformationIcon>
                    <img
                        src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/google_my_business.svg`}
                        alt=""
                    />
                </RemapInformationIcon>
                {category && (
                    <RemapInformationDetails>
                        <Text variant="heading6">{t('admin_categories_obsolete_GMB')}</Text>
                        {category && category.gmb_id && (
                            <Text variant="bodyMRegular" color="secondary">
                                {`${
                                    category.name || t('admin_categories_missinglabel')
                                } (${category.gmb_id})`}
                            </Text>
                        )}
                        <Text variant="bodySRegular" color="danger">
                            {t('admin_categories_obsolete_warning').replace(
                                '[xx]',
                                category.impacted_businesses.toString(),
                            )}
                        </Text>
                    </RemapInformationDetails>
                )}
            </RemapInformationSection>

            <RemapInformationSection>
                <SingleSelect
                    dataTrackId="page_remap_category__select_category"
                    placeholder={t('admin_categories_obsolete_dropdown_placeholder')}
                    label={t('admin_categories_obsolete_dropdown_placeholder')}
                    sections={[{ options: categoriesOptions }]}
                    onChange={onChange}
                    onSearch={setUserSearch}
                    selectedValue={selectedValue}
                    maxHeight={300}
                    loadMore={fetchNextPage}
                    hasMore={hasNextPage}
                />
            </RemapInformationSection>
        </RemapInformationContainer>
    );
};
