import React, { ComponentProps, MouseEvent, ReactNode } from 'react';

import { HEX_COLORS } from '@partoohub/branding';

import {
    AnyIcon,
    AnyInput,
    AnyPlaceholder,
    Border,
    Container,
    PillBody,
    PillBodyText,
    PillCross,
} from './SearchBox.styled';

interface Props {
    children: ReactNode;
    focused: boolean;
}

const SearchBox = React.forwardRef<HTMLDivElement, Props & ComponentProps<'div'>>(
    ({ children, focused, ...props }, ref) => (
        // Put the border on a separate div, so that when increasing its
        // thickness, elements in position absolute inside children don't
        // move around. The container is in position relative and serves
        // as stable reference.
        <Container ref={ref} {...props}>
            <Border focused={focused}>{children}</Border>
        </Container>
    ),
);

interface InputProps {
    cursor: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps & ComponentProps<'input'>>(
    ({ type = 'text', value, cursor = 'default', ...props }, ref) => (
        <AnyInput type={type} value={value} ref={ref} cursor={cursor} {...props} />
    ),
);

interface IconProps {
    className?: string;
}

const Icon = ({ className = 'fa-solid fa-caret-down' }: IconProps) => (
    <AnyIcon className={className} />
);

interface PillProps {
    children: ReactNode;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    onClickCross?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Pill = ({ children, onClick, onClickCross }: PillProps) => {
    const handleClick = event => {
        onClick?.(event);
    };

    const handleClickCross = event => {
        event.stopPropagation();
        onClickCross?.(event);
    };

    return (
        <PillBody onClick={handleClick}>
            <PillBodyText>{children}</PillBodyText>
            <PillCross className="fa-solid fa-circle-xmark" onClick={handleClickCross} />
        </PillBody>
    );
};

interface PlaceholderProps {
    aside: boolean;
    children: ReactNode;
    focused: boolean;
}

const Placeholder = ({ aside, focused, children }: PlaceholderProps) => (
    <AnyPlaceholder color={focused ? HEX_COLORS.primary : HEX_COLORS.secondary} aside={aside}>
        {children}
    </AnyPlaceholder>
);

export default Object.assign(SearchBox, {
    Input,
    Icon,
    Pill,
    Placeholder,
});
