import React, { FC, useState } from 'react';

import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    Modal,
    Stack,
    TextInput,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FoodMenuSectionType } from 'admin/common/api/calls/bulkFoodMenuApiCalls';

import { MenuItems } from './MenuItems';

import { ModalContent, ModalSubtitle, ModalTitle, ModalWrapper } from './modal.styled';

interface MenuSectionsProps {
    value: Array<FoodMenuSectionType>;
    onChange: React.Dispatch<React.SetStateAction<Array<FoodMenuSectionType>>>;
    disabled: boolean;
}

export const MenuSections: FC<MenuSectionsProps> = ({ value, onChange, disabled }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState<boolean>(false);
    const [name, setName] = useState<string>('');

    const openModal = () => {
        setShow(true);
    };

    const closeModal = () => {
        setName('');
        setShow(false);
    };

    const isFormInvalid = () =>
        name.length === 0 || name.length > 140 || name?.replace(/\s/g, '').length === 0;

    const onApply = () => {
        const newSection = {
            name,
            items: [],
            order: value.length + 1,
        };
        onChange([...value, newSection]);
        setShow(false);
        setName('');
    };

    return (
        <div>
            <Modal isOpen={show} closeModal={closeModal}>
                <ModalWrapper>
                    <ModalContent>
                        <ModalTitle>{t('add_food_menu_section_name_modal_title')}</ModalTitle>
                        <ModalSubtitle>
                            {t('add_food_menu_section_name_modal_subtitle')}
                        </ModalSubtitle>
                        <div>
                            <TextInput
                                label={t('food_menu_section_name')}
                                required
                                value={name}
                                onChange={value => setName(value || '')}
                                characterCount={140}
                                dataTrackId="menu_enter_section_name"
                            />
                        </div>
                    </ModalContent>

                    <Stack>
                        <Button
                            dataTrackId="menu_cancel_form__button"
                            full
                            size="large"
                            variant="secondary"
                            appearance="outlined"
                            shape="cube"
                            onClick={closeModal}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            dataTrackId="menu_validate_form__button"
                            full
                            size="large"
                            variant="primary"
                            shape="cube"
                            disabled={isFormInvalid()}
                            onClick={onApply}
                        >
                            {t('validate')}
                        </Button>
                    </Stack>
                </ModalWrapper>
            </Modal>
            {value.map((section, index) => (
                <MenuItems
                    key={`${section.name}_${section.order}`}
                    sections={value}
                    section={section}
                    setSections={onChange}
                    disabled={disabled}
                    first={index === 0}
                    last={index === value.length - 1}
                />
            ))}
            {!disabled && (
                <Button
                    dataTrackId="create_menu__button"
                    onClick={openModal}
                    disabled={disabled}
                    shape="cube"
                    appearance="outlined"
                    variant="primary"
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                >
                    {value.length > 0
                        ? t('menu_add_section_button_text')
                        : t('menu_create_button_text')}
                </Button>
            )}
        </div>
    );
};
