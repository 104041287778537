import { GetPartnerLinksReceivedData } from 'app/api/types/partner_links';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'partner_links';

class PartnerLink extends ApiResourceBaseClass {
    static getPartnerLinks(): Promise<GetPartnerLinksReceivedData> {
        return PartnerLink.get(RESOURCE_URI).then(({ data }) => data);
    }
}

export default PartnerLink;
