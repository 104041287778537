import { GetPlaceActionLinksReceivedData } from 'app/api/types/placeActionLinks';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'place_action_links';

class PlaceActionLinks extends ApiResourceBaseClass {
    static getPlaceActionLinks(): Promise<GetPlaceActionLinksReceivedData> {
        return PlaceActionLinks.get(RESOURCE_URI).then(({ data }) => data);
    }
}

export default PlaceActionLinks;
