import styled from '@emotion/styled';
import { Icon } from '@partoohub/ui';

export const Row = styled.li`
    display: flex;
    align-items: center;
    height: 80px;
    padding: 20px;
`;
export const HeaderRow = styled.li`
    display: flex;
    align-items: center;
    height: 32px;
    background: #f6f8fc;
    border-radius: 4px;
    padding: 10px 20px;
    color: #7f8ea4;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
`;

export const IconContainer = styled.div`
    height: 40px;
    padding: 8px;
    display: inline-block;
    background: #f6f8fc;
    border-radius: 50%;
    margin-right: 16px;
    stroke: black;
`;

export const FontAwesomeIconContainer = styled(IconContainer)`
    display: inline-flex;
    width: 40px;
`;

export const FontAwesomeIconContent = styled(Icon)`
    margin: auto;
`;

export const EmptyPlaceholder = styled.div`
    flex-grow: 1;
`;
