import React, { Component, ForwardedRef, ReactElement } from 'react';

import mergeRefs from 'react-merge-refs';

import { withForwardedRef } from 'app/common/HOC/withForwardRef';

interface Props {
    children: ReactElement;
    duration: number;
    upperRef?: ForwardedRef<HTMLElement | null>;
}

interface State {
    ref: HTMLElement | null;
}

/**
 * Animates the height of an element using the FLIP technique.
 */
class FlipSizeAnimated extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            ref: null,
        };

        this.setRef = this.setRef.bind(this);
    }

    static defaultProps = {
        duration: 1000,
    };

    setRef(r: HTMLElement) {
        if (r !== null && r !== this.state.ref) {
            this.setState({
                ref: r,
            });
        }
    }

    /*
     * Grab the bounding client rect of of the child before the DOM is committed
     */
    getSnapshotBeforeUpdate(): any {
        if (this.state.ref !== null) {
            return this.state.ref.getBoundingClientRect();
        }
    }

    /*
     * Grab the bounding client rect of of the child after the DOM is committed.
     * Then compute the delta between before commit and after commit client rects,
     * and generate the animation keyframes.
     */
    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: DOMRect,
    ) {
        if (snapshot === undefined) {
            return;
        }

        if (this.state.ref === null) {
            return;
        }

        const first = snapshot;
        const last = this.state.ref.getBoundingClientRect();

        const delta = last.height - first.height;
        if (delta === 0) {
            return; // optimization
        }

        if ('animate' in this.state.ref) {
            this.state.ref.animate(
                [
                    {
                        height: `${first.height}px`,
                    },
                    {
                        height: `${last.height}px`,
                    },
                ],
                {
                    duration: this.props.duration,
                    easing: 'linear',
                },
            );
        }
    }

    render() {
        // preserve the ref on children while we add another one when cloning children
        // https://github.com/facebook/react/issues/8873#issuecomment-275423780:w
        const { ref: userRef } = this.props.children as ReactElement & {
            ref: React.RefCallback<HTMLElement>;
        };

        return React.cloneElement(this.props.children, {
            ref: mergeRefs([userRef, this.setRef, this.props.upperRef || null]),
        });
    }
}

export default withForwardedRef<HTMLElement | null, Props>(FlipSizeAnimated);
