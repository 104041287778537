import { FC, useMemo, useState } from 'react';

import { ServiceSuggestionType } from '@partoohub/api-client';
import { Option, Text } from '@partoohub/ui';
import { getAllInfoByISO } from 'iso-country-currency';

import { AddServiceModal, AddServiceModalDataType } from './modals/AddServiceModal';
import { DeleteServiceModal } from './modals/DeleteServiceModal';
import { EditServiceModal, EditServiceModalDataType } from './modals/EditServiceModal';
import { ServiceModalFormType } from './modals/utils';
import { SectionBody } from './SectionBody';
import { SectionFooter } from './SectionFooter';
import { MenuButton } from './ServiceActions/MenuButton';
import { ServiceItemProps } from './ServiceItem';
import { CategoryCell, SectionGlobal } from './ServicesList.styled';

type Props = {
    category: Option;
    services: Array<ServiceModalFormType>;
    setServices: React.Dispatch<React.SetStateAction<Array<ServiceModalFormType>>>;
    suggestions: Array<ServiceSuggestionType>;
    country?: string;
};

export const ServicesList: FC<Props> = ({
    category,
    services,
    setServices,
    suggestions,
    country,
}: Props) => {
    const [openedModal, setOpenedModal] = useState<string>('');
    const [modalData, setModalData] = useState<AddServiceModalDataType | EditServiceModalDataType>({
        serviceType: '',
        serviceName: '',
        serviceId: undefined,
        serviceDescription: undefined,
        servicePrice: undefined,
    });
    const [serviceToDelete, setServiceToDelete] = useState<ServiceModalFormType>();

    const { currency } = getAllInfoByISO(country ?? 'FR');

    const onMenuClick = (service: ServiceModalFormType, value: string) => {
        if (value === 'delete') openDeleteServiceModal(service);
        if (value === 'edit') openEditServiceModal(service);
    };

    const openDeleteServiceModal = (service: ServiceModalFormType) => {
        setServiceToDelete(service);
        setOpenedModal('delete_service');
    };

    const openAddServiceModal = (type: string, name: string, serviceId?: number) => {
        setModalData({
            serviceName: name,
            serviceType: type,
            serviceId,
        });
        setOpenedModal('add_service');
    };

    const openEditServiceModal = (service: ServiceModalFormType) => {
        setModalData({
            serviceName: service.name,
            serviceType: service.type,
            serviceId: service.serviceId,
            servicePrice: service.price,
            serviceDescription: service.description,
        });
        setOpenedModal('edit_service');
    };

    const servicesList: ServiceItemProps[] = useMemo(() => {
        return services.map(service => ({
            mainLabel: service.name,
            description: service.description,
            optionalLabel:
                service.price && `${service.price.toString().replace('.', ',')} ${currency}`,
            menuElem: (
                <MenuButton
                    key={`service_menu_${service.name}_for_${category.value}`}
                    onMenuClick={(value: string) => {
                        onMenuClick(service, value);
                    }}
                />
            ),
        }));
    }, [services]);

    const suggestionsToDisplay: ServiceSuggestionType[] = useMemo(() => {
        return (suggestions || []).filter(
            suggestion => !services.some(service => suggestion.service_id === service.serviceId),
        );
    }, [suggestions, services]);

    return (
        <>
            <SectionGlobal key={`category_${category.value}`}>
                <CategoryCell>
                    <Text variant="heading6">{category.label?.toString() ?? ''}</Text>
                </CategoryCell>

                <SectionBody servicesList={servicesList} />

                <SectionFooter
                    suggestionsToDisplay={suggestionsToDisplay}
                    openAddServiceModal={openAddServiceModal}
                    category={category}
                />
            </SectionGlobal>

            <DeleteServiceModal
                show={openedModal === 'delete_service'}
                handleClose={() => setOpenedModal('')}
                setServices={setServices}
                serviceToDelete={serviceToDelete}
            />

            <AddServiceModal
                show={openedModal === 'add_service'}
                handleClose={() => setOpenedModal('')}
                setServices={setServices}
                data={modalData as AddServiceModalDataType}
                currency={currency}
                services={services}
            />

            <EditServiceModal
                show={openedModal === 'edit_service'}
                handleClose={() => setOpenedModal('')}
                setServices={setServices}
                data={modalData as EditServiceModalDataType}
                currency={currency}
                services={services}
            />
        </>
    );
};
