import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';

import { OverridingStateParameters } from 'admin/api/types';

const RESOURCE_URI = 'diffusions/overriding_state';

export class OverridingStateParametersApiCall extends ApiResourceBaseClass {
    async set(parameters: OverridingStateParameters): Promise<SuccessResponse> {
        const { data } = await this.post<SuccessResponse>(`${RESOURCE_URI}`, {
            ...parameters,
        });

        return data;
    }
}
