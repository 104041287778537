import { FilterMap, FilterName, PartialFilters } from '@partoohub/modular-components';

export const concatTrueValues = (obj: PartialFilters, key: FilterName): Record<string, boolean> => {
    if (!(key in obj && obj[key] instanceof Map)) {
        return {};
    }
    const trueEntries = [...obj[key].entries()].filter(([, v]) => v === true);
    const valueDict: Record<string, boolean> = {};
    for (const [key, value] of trueEntries) {
        valueDict[key] = value;
    }
    return valueDict;
};

export const createFilterMap = (values?: Record<string, boolean>): FilterMap => {
    if (!values) {
        return new Map();
    }
    const map = new Map(Object.entries(values));
    return map;
};

export const removeEmptyValues = (obj: any) => {
    return Object.fromEntries(
        Object.entries(obj).filter(
            ([_, v]) =>
                v !== null &&
                v !== undefined &&
                v !== '' &&
                !(Array.isArray(v) && v.length === 0) &&
                !(typeof v === 'object' && !Array.isArray(v) && Object.keys(v).length === 0),
        ),
    );
};
