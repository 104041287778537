import { useState } from 'react';

import { TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import BusinessModalButton from 'app/common/components/businessModal';

import useSelectedBusinessesTitle from 'app/common/components/businessModal/hooks/useSelectedBusinessesTitle';
import { resetBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers';

import { FoodMenuSectionType } from 'admin/common/api/calls/bulkFoodMenuApiCalls';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import {
    PageContent,
    PageDescription,
    PageFormRow,
} from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import {
    ActionSelect,
    AddDetailsAction,
    AddPhotoAction,
    BulkMenuActionEnum,
    DeleteAction,
    UpdateFoodMenuModal,
} from 'admin/content/data/pages/FoodMenusCreation/components';

const FOOD_MENUS_CREATION_SEARCH_PARAMS = {
    can_have_food_menus: true,
    connected_to_gmb_location: true,
};

const FoodMenusCreation = () => {
    const { t } = useTranslation();
    const [bulkMenuAction, setBulkMenuAction] = useState<string | null>(null);
    const [openedSaveModal, setOpenedSaveModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [photosMenu, setPhotosMenu] = useState<Array<string>>([]);
    const [sections, setSections] = useState<Array<FoodMenuSectionType>>([]);
    const dispatch = useDispatch();

    const buttonInfoByAction: {
        [action: string]: {
            translationKey: string;
            active: boolean;
        };
    } = {
        [BulkMenuActionEnum.PHOTO]: {
            translationKey: 'admin:bulk_menu__add_photo',
            active: photosMenu?.length > 0,
        },
        [BulkMenuActionEnum.DETAILS]: {
            translationKey: 'admin:bulk_menu__add_details',
            active: sections?.length > 0,
        },
        [BulkMenuActionEnum.BOTH]: {
            translationKey: 'admin:bulk_menu__add_both',
            active: photosMenu?.length > 0 || sections?.length > 0,
        },
    };

    const resetForm = () => {
        setBulkMenuAction(null);
        setPhotosMenu([]);
        setSections([]);
        dispatch(resetBusinessFilter());
    };

    const { title } = useSelectedBusinessesTitle();

    return (
        <AdminPage
            dataTrackId="page_bulk_food_menus"
            title={t('admin:bulk_food_menus__page_title')}
            description={t('admin:bulk_food_menus__page_description')}
            infoLink={'https://partoo.elium.com/tile/view/281'}
        >
            <BorderedContainer>
                <PageFormRow>
                    <PageContent>
                        <PageDescription>
                            {t('admin:bulk_food_menus__business_select_description')}
                        </PageDescription>
                        <BusinessModalButton
                            withUrlSync={false}
                            noBusinessLimit
                            searchFilters={FOOD_MENUS_CREATION_SEARCH_PARAMS}
                            button={
                                // the business modal is not opened without this div
                                <div>
                                    <TextInput
                                        dataTrackId="bulk_food_menus__open_business_selection"
                                        label={t('admin:select_businesses')}
                                        value={title}
                                    />
                                </div>
                            }
                        />
                    </PageContent>
                </PageFormRow>
                <PageFormRow>
                    <PageContent>
                        <PageDescription>
                            {t('admin:bulk_food_menus__action_description')}
                        </PageDescription>
                        <ActionSelect
                            selectedAction={bulkMenuAction}
                            onChange={setBulkMenuAction}
                        />
                    </PageContent>
                </PageFormRow>
                {bulkMenuAction && (
                    <PageFormRow>
                        {(bulkMenuAction === BulkMenuActionEnum.DETAILS ||
                            bulkMenuAction === BulkMenuActionEnum.BOTH) && (
                            <AddDetailsAction setSections={setSections} sections={sections} />
                        )}
                        {(bulkMenuAction === BulkMenuActionEnum.PHOTO ||
                            bulkMenuAction === BulkMenuActionEnum.BOTH) && (
                            <AddPhotoAction
                                photosMenu={photosMenu}
                                setPhotosMenu={setPhotosMenu}
                                photosPerRow={bulkMenuAction === BulkMenuActionEnum.PHOTO ? 4 : 2}
                            />
                        )}
                    </PageFormRow>
                )}
            </BorderedContainer>
            <PageFormRow>
                {!!bulkMenuAction && bulkMenuAction !== BulkMenuActionEnum.DELETE && (
                    <SubmitButton
                        dataTrackId={`admin_bulk_menu_creation__add_${bulkMenuAction}`}
                        disabled={!buttonInfoByAction[bulkMenuAction]?.active || loading}
                        loading={loading}
                        text={t(buttonInfoByAction[bulkMenuAction]?.translationKey)}
                        onClick={() => setOpenedSaveModal(true)}
                    />
                )}
            </PageFormRow>

            {bulkMenuAction && bulkMenuAction === BulkMenuActionEnum.DELETE && (
                <DeleteAction onSuccess={resetForm} />
            )}
            <UpdateFoodMenuModal
                bulkMenuAction={bulkMenuAction}
                sections={sections}
                onSuccess={resetForm}
                photosMenu={photosMenu}
                openedSaveModal={openedSaveModal}
                setOpenedSaveModal={setOpenedSaveModal}
                setLoading={setLoading}
            />
        </AdminPage>
    );
};

export default FoodMenusCreation;
