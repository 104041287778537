import { useState } from 'react';

import {
    BusinessCount,
    BusinessModal,
    BusinessModalButton,
    BusinessModalButtonVariant,
    BusinessModalFilters,
    BusinessModalVariant,
    FilterVisibility,
    ResetModeEnum,
} from '@partoohub/modular-components';
import { Stack } from '@partoohub/ui';

import { BASE_URL } from 'admin/api/apiResources';
import { useGetTranslations } from 'admin/common/components/businessModal/hooks/useGetTranslations';
import { queryClient } from 'admin/main';

import { initialCountValue } from './constants';
import { useBusinessModalButtonText } from './hooks/useBusinessModalButtonText';

interface Props {
    variant: BusinessModalVariant;
    disabled?: boolean;
    label?: string;
    buttonVariant?: BusinessModalButtonVariant;
    filters?: Partial<BusinessModalFilters>;
    setFilters?: (filters: Partial<BusinessModalFilters>) => void;
    onCountChange?: (count: BusinessCount) => void;
    filterVisibility?: FilterVisibility;
    resetMode?: ResetModeEnum;
}

const EmptyFunction = () => {
    // Empty function
};

const defaultFilterVisibility: FilterVisibility = {
    searchBusiness: true,
    searchCity: true,
    searchOrg: true,
};

export const BusinessModalComponent = ({
    variant = 'all',
    disabled = false,
    label,
    buttonVariant = 'page',
    filters,
    setFilters = EmptyFunction,
    onCountChange,
    filterVisibility = defaultFilterVisibility,
    resetMode = ResetModeEnum.Lifecycle,
}: Props) => {
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState<BusinessCount>(initialCountValue);
    const translations = useGetTranslations();
    const buttonText = useBusinessModalButtonText(count, buttonVariant);

    const handleOpen = () => setOpen(true);

    const handleSetCount = (newCount: BusinessCount) => {
        setCount(newCount);
        if (onCountChange) {
            onCountChange(newCount);
        }
    };

    return (
        <Stack direction="column" style={{ width: '100%' }}>
            <BusinessModalButton
                dataTrackId="business_modal__button"
                label={label}
                disabled={disabled}
                buttonText={buttonText}
                onClick={handleOpen}
                variant={buttonVariant}
            />
            <BusinessModal
                apiBaseUrl={BASE_URL}
                client={queryClient}
                filters={filters}
                setFilters={setFilters}
                count={count}
                setCount={handleSetCount}
                translations={translations}
                open={open}
                setOpen={setOpen}
                variant={variant}
                filterVisibility={filterVisibility}
                resetMode={resetMode}
            />
        </Stack>
    );
};
