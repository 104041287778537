import { useTranslation } from 'react-i18next';

import { ConfirmModalWithText } from 'admin/common/components/modals/ConfirmModalWithText';

interface Props {
    opened: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

const PASSWORD = 'merci rick';

export const ConfirmEditGenericPromptModal = ({ opened, onConfirm, onClose }: Props) => {
    const { t } = useTranslation();

    return (
        <ConfirmModalWithText
            validationStyle="error"
            inputTextType="password"
            opened={opened}
            onClose={onClose}
            onConfirm={onConfirm}
            subTitle={t('admin:feedback_management__confirm_update_generic_prompt_modal_subtitle')}
            title={t('admin:feedback_management__confirm_update_generic_prompt_modal_title')}
            errorNotice={t(
                'admin:feedback_management__confirm_update_generic_prompt_wrong_password',
            )}
            inputTextLabel={t('password')}
            confirmText={PASSWORD}
        />
    );
};
