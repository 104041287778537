import { useMemo } from 'react';

import styled from '@emotion/styled';

import { List } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2UserData } from 'app/api/types/user';

import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

const FlexDiv = styled.div`
    flex: 1;
`;

type SelectBarProps = {
    users: Array<V2UserData>;
    selectedAction: string;
    submitForm: (selectedItems: string) => void;
    enableButton: boolean;
};

export function SelectBar({ users, selectedAction, submitForm, enableButton }: SelectBarProps) {
    const { selectedRowIds, unselectAll } = List.useListContext();
    const selectedItems = useMemo(
        () => users.filter(item => selectedRowIds[item.id]),
        [users, 'id', selectedRowIds],
    );
    const { t } = useTranslation();
    let text = t('admin:user_actions__button_choose_action_and_user');
    if (selectedItems.length) {
        if (selectedAction) {
            text = t(`admin:user_actions__button_${selectedAction}`, {
                count: selectedItems.length,
            });
        } else {
            text = t('admin:user_actions__button_choose_action', {
                count: selectedItems.length,
            });
        }
    } else if (selectedAction) {
        text = t('admin:user_actions__button_choose_user');
    }

    return (
        <FlexDiv>
            <SubmitButton
                dataTrackId={selectedAction ? `admin_user_actions__submit_${selectedAction}` : ''}
                onClick={() => {
                    submitForm(selectedItems.map(user => user.id).toString());
                    unselectAll();
                }}
                text={text}
                disabled={!enableButton || selectedItems.length === 0}
            />
        </FlexDiv>
    );
}
