import { SuccessResponse } from '@partoohub/api-client';

import {
    CustomFieldsChangeOrderPayload,
    CustomFieldsCreationData,
    CustomFieldsCreationPayload,
    CustomFieldsDeletePayload,
    CustomFieldsEditPayload,
} from 'app/api/types/business';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'custom_fields';

class CustomFields extends ApiResourceBaseClass {
    static createCustomFields(
        payload: CustomFieldsCreationPayload,
    ): Promise<CustomFieldsCreationData> {
        return CustomFields.post(`${RESOURCE_URI}`, payload).then(({ data }) => data);
    }

    static editCustomFields(
        customFieldsId: number,
        payload: CustomFieldsEditPayload,
    ): Promise<SuccessResponse> {
        return CustomFields.put(`${RESOURCE_URI}/${customFieldsId}`, payload).then(
            ({ data }) => data,
        );
    }

    static deleteCustomFields(
        customFieldsId: number,
        payload: CustomFieldsDeletePayload,
    ): Promise<SuccessResponse> {
        return CustomFields.delete(`${RESOURCE_URI}/${customFieldsId}`, {
            params: payload,
        }).then(({ data }) => data);
    }

    static changeOrderCustomFields(
        payload: CustomFieldsChangeOrderPayload,
    ): Promise<SuccessResponse> {
        return CustomFields.post(`${RESOURCE_URI}/order`, payload).then(({ data }) => data);
    }
}

export default CustomFields;
