import { Option, Section, SingleSelect as SingleSelectDS } from '@partoohub/ui';

type Props = {
    dataTrackId: string;
    placeholder: string;
    sections: Section[];
    selectedValue: string | null;
    onChange: (selectedOptionValue: string | null) => void;
    disabled?: boolean;
    required?: boolean;
    disableReset?: boolean;
};

export const SingleSelect = ({
    dataTrackId,
    placeholder,
    sections,
    selectedValue,
    onChange,
    disabled,
    required,
    disableReset,
}: Props) => {
    const flattenOptions = sections.flatMap(section => section.options);
    const selectedOption = flattenOptions.find(option => option.value === selectedValue);

    const onOptionSelected = (option?: Option) => {
        onChange(option?.value || null);
    };

    return (
        <SingleSelectDS
            dataTrackId={dataTrackId}
            label={placeholder}
            sections={sections}
            selectedValue={selectedOption}
            onChange={onOptionSelected}
            disabled={disabled}
            position="bottom"
            maxHeight={300}
            required={required}
            disableReset={disableReset}
        />
    );
};
