import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { MenuList } from '@partoohub/ui';

export const Container = styled.div<{ cssContainer?: SerializedStyles }>`
    display: flex;
    gap: 4px;
    position: relative;
    cursor: pointer;
    align-items: center;
    ${({ cssContainer }) => cssContainer};
`;

export const OverrideMenuList = styled(MenuList)<{ css?: SerializedStyles }>`
    ${({ css }) => css};
`;
