import { FC, useState } from 'react';

import {
    Chip,
    CustomFile,
    DropzoneTypeEnum,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    Image,
    Stack,
    Text,
    toast,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import usePhotosUpload from 'app/businessEditV2/hooks/usePhotosUpload';

import { resizeImage } from 'app/utils/resizeImage';

import { EllipsisLink } from 'admin/common/components/buttons/EllipsisLink';
import { DropZoneDescription } from 'admin/common/components/inputs/DropZone/DropZoneDescription';
import { DropZoneWithoutUpload } from 'admin/common/components/inputs/DropZone/DropzoneWithoutUpload';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

import { CopyUrlRow } from './ImageUploader.styled';

const MAX_SIZE = 5_000_000;
const ACCEPTED_FILES = {
    ['image/png']: ['.png'],
    ['image/jpeg']: ['.jpg', '.jpeg'],
};

const ImageUploader: FC = () => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<CustomFile[]>([]);
    const [uploadedFileUrls, setUploadedFileUrls] = useState<string[]>([]);
    const isFormValid = files?.length;

    const uploadPhotos = usePhotosUpload(
        data => {
            if (data?.result?.uploads) {
                setUploadedFileUrls(data.result.uploads);
            }
            resetForm();
        },
        e => {
            toast.error(e?.message, t('admin:toast_error__title'));
            resetForm();
        },
    );

    const submitForm = () => {
        if (!isFormValid) return;
        uploadPhotos.mutate(files);
        toast.loading(
            t('admin:page_image_uploader__success_toast__content'),
            t('admin:page_image_uploader__success_toast__title'),
        );
    };

    const resetForm = () => {
        setFiles([]);
    };

    const onChipClick = (url: string) => {
        navigator.clipboard.writeText(url);
        toast.success(
            t('admin:page_image_uploader__success_copy_url_toast__content'),
            t('admin:page_image_uploader__success_copy_url_toast__title'),
        );
    };

    const copyChip = (url: string) => (
        <div
            data-track={'admin_image_uploader__copy_link'}
            data-intercom-targert={'admin_image_uploader__copy_link'}
        >
            <Chip
                dataTrackId={'admin_image_uploader__copy_link__chip'}
                disabled={false}
                onClick={() => onChipClick(url)}
            >
                <Stack gap="4px" direction="row" alignItems="center">
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCopy, IconPrefix.SOLID]}
                        iconSize="12px"
                        color="primary"
                    />
                    {t('admin:copy')}
                </Stack>
            </Chip>
        </div>
    );

    return (
        <AdminPage
            dataTrackId="admin__image_uploader"
            title={t('admin:page_image_uploader__title')}
            description={t('admin:page_image_uploader__description')}
        >
            <BorderedContainer>
                <PageFormRow marginBottom={24}>
                    <DropZoneWithoutUpload
                        title={t('drop_zone_label')}
                        acceptedDescription={
                            <DropZoneDescription
                                maxSize={MAX_SIZE}
                                acceptedExtensions={ACCEPTED_FILES}
                            />
                        }
                        type={DropzoneTypeEnum.Document}
                        files={files}
                        setFiles={setFiles}
                        multiple={true}
                        maxSize={MAX_SIZE}
                        full
                        accept={ACCEPTED_FILES}
                    />
                </PageFormRow>
                {!!uploadedFileUrls.length && (
                    <PageFormRow>
                        <Text variant="heading5" as="p">
                            {t('admin:page_image_uploader__uploaded_images')}
                        </Text>
                    </PageFormRow>
                )}
                {uploadedFileUrls.map(url => (
                    <CopyUrlRow key={url}>
                        <Image
                            src={resizeImage(url, 200)}
                            alt={t('admin:uploaded_image')}
                            height={64}
                            style={{ border: '2px solid black' }}
                        ></Image>
                        <EllipsisLink value={url} icon={copyChip(url)} />
                    </CopyUrlRow>
                ))}
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin__image_uploader__submit"
                text={t('submit_label')}
                onClick={submitForm}
                disabled={!isFormValid}
                loading={uploadPhotos.isLoading}
            />
        </AdminPage>
    );
};

export default ImageUploader;
