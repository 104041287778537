import 'regenerator-runtime/runtime';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';

import reducers from 'admin/common/reducers';

export const history = createBrowserHistory();

const configureStore = () => {
    const middlewares = [createRouterMiddleware(history)];
    let composeEnhancer = compose;

    if (process.env.NODE_ENV !== 'production') {
        composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            : compose; // eslint-enable
    }

    return createStore(reducers(), composeEnhancer(applyMiddleware(...middlewares)));
};

export default configureStore;
