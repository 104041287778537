import React from 'react';

type Props = {
    active: boolean;
};

const BulkIcon = React.forwardRef<SVGSVGElement, Props>(({ active }, ref) => (
    <svg
        ref={ref}
        width="100%"
        height="100%"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
    >
        {active && (
            <g clipPath="url(#clip0_3792_68550)">
                <path
                    d="M19.9999 22.9327C18.8661 22.9327 17.7327 22.6555 16.6991 22.1012L9.2464 18.1036C8.16569 17.524 7.49664 16.403 7.50015 15.1783C7.50371 13.9535 8.17927 12.8365 9.26331 12.2631L16.7348 8.31109C18.7793 7.22968 21.2205 7.22968 23.265 8.31109L30.7365 12.2631C31.8206 12.8365 32.4961 13.9535 32.4997 15.1783C32.5032 16.403 31.8341 17.524 30.7535 18.1036L23.3007 22.1012C22.2674 22.6555 21.1335 22.9326 19.9999 22.9327ZM23.3007 30.9389L31.9848 26.2811C32.4601 26.0262 32.6387 25.4342 32.3838 24.9589C32.1289 24.4836 31.5369 24.305 31.0616 24.5599L22.3775 29.2178C20.8888 30.0163 19.1112 30.0163 17.6186 29.2157L8.93454 24.6067C8.45803 24.3539 7.86694 24.5352 7.61417 25.0115C7.36131 25.4879 7.54253 26.079 8.01894 26.3319L16.6992 30.9389C17.7327 31.4932 18.8662 31.7704 20 31.7704C21.1335 31.7704 22.2674 31.4932 23.3007 30.9389ZM23.3007 26.5445L31.9848 21.8866C32.4601 21.6317 32.6387 21.0397 32.3838 20.5645C32.1289 20.0892 31.5369 19.9106 31.0616 20.1655L22.3775 24.8233C20.8888 25.6219 19.1112 25.6219 17.6186 24.8213L8.93454 20.2123C8.45803 19.9595 7.86694 20.1407 7.61417 20.6171C7.36131 21.0935 7.54253 21.6846 8.01894 21.9375L16.6992 26.5444C17.7327 27.0988 18.8662 27.3759 20 27.3759C21.1335 27.3759 22.2674 27.0988 23.3007 26.5445Z"
                    fill="#0085F2"
                />
            </g>
        )}
        {!active && (
            <g clipPath="url(#clip0_3792_68550)">
                <path d="M19.9999 22.9327C18.8661 22.9327 17.7327 22.6555 16.6991 22.1012L9.2464 18.1036C8.16569 17.524 7.49664 16.403 7.50015 15.1783C7.50371 13.9535 8.17927 12.8365 9.26331 12.2631L16.7348 8.31109C18.7793 7.22968 21.2205 7.22968 23.265 8.31109L30.7365 12.2631C31.8206 12.8365 32.4961 13.9535 32.4997 15.1783C32.5032 16.403 31.8341 17.524 30.7535 18.1036L23.3007 22.1012C22.2674 22.6555 21.1335 22.9326 19.9999 22.9327ZM19.9999 9.45333C19.1921 9.45333 18.3844 9.64808 17.648 10.0376L10.1766 13.9896C9.7252 14.2284 9.45478 14.6749 9.45333 15.184C9.45188 15.693 9.71963 16.1411 10.1697 16.3825L17.6224 20.3801C19.1113 21.1786 20.8888 21.1786 22.3776 20.3801L29.8304 16.3825C30.2805 16.1411 30.5482 15.6931 30.5468 15.184C30.5453 14.6749 30.2749 14.2284 29.8235 13.9896L22.3518 10.0376C21.6155 9.64812 20.8077 9.45333 19.9999 9.45333V9.45333ZM17.1914 9.17433H17.1919H17.1914ZM23.3007 30.9389L31.9848 26.2811C32.4601 26.0262 32.6387 25.4342 32.3838 24.9589C32.1289 24.4836 31.5369 24.305 31.0616 24.5599L22.3775 29.2178C20.8888 30.0163 19.1112 30.0163 17.6186 29.2157L8.93454 24.6067C8.45803 24.3539 7.86694 24.5352 7.61417 25.0115C7.36131 25.4879 7.54253 26.079 8.01894 26.3319L16.6992 30.9389C17.7327 31.4932 18.8662 31.7704 20 31.7704C21.1335 31.7704 22.2674 31.4932 23.3007 30.9389V30.9389ZM23.3007 26.5445L31.9848 21.8866C32.4601 21.6317 32.6387 21.0397 32.3838 20.5645C32.1289 20.0892 31.5369 19.9106 31.0616 20.1655L22.3775 24.8233C20.8888 25.6219 19.1112 25.6219 17.6186 24.8213L8.93454 20.2123C8.45803 19.9595 7.86694 20.1407 7.61417 20.6171C7.36131 21.0935 7.54253 21.6846 8.01894 21.9375L16.6992 26.5444C17.7327 27.0988 18.8662 27.3759 20 27.3759C21.1335 27.3759 22.2674 27.0988 23.3007 26.5445V26.5445Z" />
            </g>
        )}

        <defs>
            <clipPath id="clip0_3792_68550">
                <rect width="25" height="25" transform="translate(7.5 7.5)" />
            </clipPath>
        </defs>
    </svg>
));

export default BulkIcon;
