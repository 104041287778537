import React, { ReactNode } from 'react';

import { ExplanationWithIconsContainer } from './ExplanationWithIcons.styled';

type Props = {
    explanation?: ReactNode | string;
    content: Array<ReactNode>;
};

const ExplanationWithIcons = ({ explanation, content }: Props) => (
    <ExplanationWithIconsContainer>
        {explanation}
        {content.map((icon, i) => (
            <div key={i}>{icon}</div>
        ))}
    </ExplanationWithIconsContainer>
);

export default ExplanationWithIcons;
