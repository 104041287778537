import { Option, SingleSelect } from '@partoohub/ui';

type Props = {
    category?: Option;
    setCategory: (selectedItem?: Option) => void;
    categoriesOptions: Array<Option>;
    disabled?: boolean;
    required?: boolean;
    dataTrackId: string;
};

export const CategorySelect = ({
    category,
    setCategory,
    categoriesOptions,
    disabled = false,
    required = false,
    dataTrackId,
}: Props) => {
    return (
        <SingleSelect
            dataTrackId={dataTrackId}
            sections={[{ options: categoriesOptions }]}
            selectedValue={category}
            onChange={setCategory}
            label={'Category'}
            placeholder=""
            maxHeight={300}
            disabled={disabled}
            required={required}
        />
    );
};
