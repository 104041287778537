import React, { useEffect, useState } from 'react';

import { Banner, Button, Text, TextInput, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useMutation, useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';

import { LocationCardStyled } from 'app/businessConnection/components/modals/ConnectionModal/ConnectionModal.styled';

import { BUSINESS_LOCATIONS } from 'admin/common/data/queryKeysConstants';

import { Container } from 'admin/content/playground/pages/GoogleAdminManagement/RequestAdminContent/RequestAdminContent.styled';

const RequestAdminContent = () => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    const [isConfirmClicked, setIsConfirmClicked] = useState<boolean>(false);

    const isValidSearch = searchValue?.length === 24;

    const { data: location, isLoading } = useQuery(
        [BUSINESS_LOCATIONS, searchValue],
        () => api.account.getLocationDetail(searchValue ?? '', 'GOOGLE_MY_BUSINESS'),
        {
            enabled: !!searchValue && isValidSearch,
            retry: 0,
        },
    );

    useEffect(() => {
        setIsConfirmClicked(false);
    }, [searchValue]);

    const requestAdmin = useMutation(
        () => api.googleAdminManagement.requestAdmin(searchValue || ''),
        {
            onSuccess: () => {
                toast.loading(
                    t('admin:slap__toast_request_admin_description'),
                    t('admin:slap__toast_request_admin_title'),
                );
            },
            onError: () => {
                toast.error(t('admin:slap__toast_error_description'), t('error'));
            },
        },
    );

    return (
        <Container>
            <Text variant="heading6">{t('admin:slap__request_admin_title')}</Text>

            <TextInput
                dataTrackId=""
                value={searchValue}
                onChange={setSearchValue}
                placeholder={t('admin:slap__search_field_placeholder')}
                type="text"
                notice={t('admin:slap__search_field_google_location_description')}
            />

            {isLoading && searchValue && (
                <Text variant="bodyMSemibold">
                    {isValidSearch
                        ? t('admin:slap__search_in_progress')
                        : t('admin:slap__not_valid_google_location')}
                </Text>
            )}

            {!location && !isLoading && searchValue && (
                <Text variant="bodyMSemibold">{t('admin:slap__not_found_google_location')}</Text>
            )}

            {location && <LocationCardStyled partnerName="google" location={location} />}

            <Banner variant="warning" withCloseIcon={false} dataTrackId={'slap_drawer_warning'}>
                {t('admin:slap__request_admin_warning_description')}
            </Banner>

            <Button
                onClick={() => {
                    if (isValidSearch) {
                        requestAdmin.mutate();
                        setIsConfirmClicked(true);
                    }
                }}
                dataTrackId="slap_request_administration"
                disabled={!location || isLoading || !isValidSearch || isConfirmClicked}
            >
                {t('confirm')}
            </Button>
        </Container>
    );
};

export default RequestAdminContent;
