import { FC } from 'react';

const Loader: FC = () => (
    <div className="loader">
        <div className="loader__content">
            <div className="loader__text">Loading...</div>
        </div>
    </div>
);

export default Loader;
