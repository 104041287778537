import { FuzzySearchOrgsReceivedData, SearchOrgParams, V2OrgData } from '@partoohub/api-client';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';

import { UseInfiniteQueryResult } from 'react-query/types/react/types';

import { Choice } from 'app/api/types/user';
import { organizationApiClient } from 'app/api/v2/api_calls/apiResources';
import { ORGANIZATIONS } from 'app/common/data/queryKeysConstants';

export const useOrgs = (
    filters: SearchOrgParams = {},
    options: UseInfiniteQueryOptions<FuzzySearchOrgsReceivedData> = {},
): [UseInfiniteQueryResult<FuzzySearchOrgsReceivedData>, Array<V2OrgData> | undefined] => {
    const query = useInfiniteQuery(
        [ORGANIZATIONS, { filters }],
        ({ pageParam = 1 }) =>
            organizationApiClient.searchOrgs({
                ...filters,
                page: pageParam,
            }),
        {
            getNextPageParam: prevPage =>
                prevPage.page < prevPage.max_page ? prevPage.page + 1 : undefined,
            ...options,
        },
    );

    const orgs = query.data?.pages.map(({ orgs }) => orgs)?.flat();

    // TODO: return object instead of array
    return [query, orgs];
};

export const formatOrgToChoice = (org: V2OrgData) =>
    ({
        label: `${org.name} (${org.org_id})`,
        value: org.org_id.toString(),
    }) as Choice;
