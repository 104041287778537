import { ReactNode, SyntheticEvent, useState } from 'react';

import { Button, Expandable, IconButton, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    StyledBorderedContainer,
    StyledCheckedIcon,
    StyledEditor,
    StyledUnCheckedIcon,
} from './ExpandableEditor.styled';
import { CustomReplySuggestionPromptChip } from '../ReplySuggestionPromptEditor/CustomReplySuggestionPromptChip';

interface Props {
    title: string;
    value: string;
    isValid: boolean;
    onSave: () => void;
    onReset: () => void;
    disabled: boolean;
    children: ReactNode;
    onDelete?: () => void;
    isCustom?: boolean;
    fromReplySuggestion?: boolean;
}

export const ExpandableEditor = ({
    title,
    value,
    isValid,
    isCustom,
    onSave,
    onReset,
    onDelete,
    disabled,
    fromReplySuggestion,
    children,
}: Props) => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);

    const handleReset = (e: SyntheticEvent) => {
        e.stopPropagation();
        setOpened(false);
        onReset();
    };

    const handleSave = (e: SyntheticEvent) => {
        e.stopPropagation();
        onSave();
    };

    const iconChecked = (
        <StyledCheckedIcon icon={['fa-check', IconPrefix.REGULAR]} color="success" />
    );
    const iconUnchecked = (
        <StyledUnCheckedIcon icon={['fa-check', IconPrefix.REGULAR]} color="#1425421F" />
    );

    return (
        <StyledBorderedContainer opened={opened}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => setOpened(prev => !prev)}
            >
                <Stack direction="row" alignItems="center">
                    {value && isValid ? iconChecked : iconUnchecked}
                    <Text variant="bodyLSemibold">{title}</Text>
                    {fromReplySuggestion && (
                        <CustomReplySuggestionPromptChip isCustomPrompt={isCustom} />
                    )}
                </Stack>
                <IconButton
                    dataTrackId={`${title}_open_icon`}
                    appearance="outlined"
                    icon={[opened ? 'fa-chevron-down' : 'fa-chevron-right', IconPrefix.REGULAR]}
                    color="secondary"
                />
            </Stack>
            <Expandable opened={opened}>
                <StyledEditor>
                    {children}
                    <Stack direction="row" justifyContent="space-between">
                        <div>
                            {isCustom && (
                                <Button
                                    dataTrackId={`${title}_edit_delete`}
                                    size="large"
                                    variant="danger"
                                    shape="cube"
                                    onClick={onDelete}
                                >
                                    {t('delete')}
                                </Button>
                            )}
                        </div>
                        <Stack direction="row">
                            <Button
                                dataTrackId={`${title}_edit_cancel`}
                                size="large"
                                variant="secondary"
                                shape="cube"
                                appearance="outlined"
                                onClick={handleReset}
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                dataTrackId={`${title}_edit_save`}
                                size="large"
                                variant="primary"
                                shape="cube"
                                disabled={disabled}
                                onClick={handleSave}
                            >
                                {t('save')}
                            </Button>
                        </Stack>
                    </Stack>
                </StyledEditor>
            </Expandable>
        </StyledBorderedContainer>
    );
};
