import { useCallback } from 'react';

import { useBlocker } from 'app/hooks/useBlocker';

/**
 * Prompts the user with an Alert before they leave the current screen.
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 *
 * @param  message
 * @param  when
 */
export function usePrompt(message, when = true) {
    const blocker = useCallback(
        tx => {
            if (window.confirm(message)) tx.retry();
        },
        [message],
    );

    useBlocker(blocker, when);
}
