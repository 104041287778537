import { useEffect, useMemo } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Modal, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { reviewAdminApiClient } from 'admin/api/apiResources';

import { ReviewCleanerContent } from './ReviewCleanerConfirmModal.styled';

type Props = {
    show?: boolean;
    businessId: string;
    title: string;
    hide: () => void;
};

const REVIEW_CLEANER_QUERY_KEY = 'REVIEW_CLEANER';

export default function ({ businessId, title, hide, show }: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { data, isLoading } = useQuery(
        [REVIEW_CLEANER_QUERY_KEY, businessId],
        () => {
            return reviewAdminApiClient.getWrongGmbLocationCount(businessId);
        },
        {
            staleTime: 60 * 10 * 1000, // 10 minutes
            enabled: show && !!businessId,
            retry: false,
        },
    );

    useEffect(() => {
        return () => {
            queryClient.invalidateQueries(REVIEW_CLEANER_QUERY_KEY);
        };
    }, []);

    const incorrectReviewsCount = data ? data.count : 0;

    const handleConfirm = () => {
        if (incorrectReviewsCount > 0) {
            reviewAdminApiClient
                .cleanGmbReviews(businessId)
                .then(response => {
                    toast.success(
                        t('admin:page_review_cleaner__success_toast_description', {
                            count: response.deleted,
                        }),
                        t('success'),
                    );
                })
                .catch(() => {
                    toast.error('', t('admin:toast_error__title'));
                })
                .finally(() => {
                    hide();

                    queryClient.invalidateQueries({
                        queryKey: [REVIEW_CLEANER_QUERY_KEY, businessId],
                        refetchActive: false,
                    });
                });

            return;
        }

        hide();
    };

    const content = useMemo(() => {
        if (isLoading) {
            return <div>{t('loading')}</div>;
        }

        if (incorrectReviewsCount == 0) {
            return <div>{t('admin:page_review_cleaner__reviews_ok')}</div>;
        }

        return (
            <>
                <div>
                    {t('admin:page_review_cleaner__reviews_nok_count', {
                        count: incorrectReviewsCount,
                    })}
                </div>
                <div>{t('admin:page_review_cleaner__reviews_clean_confirm')}</div>
            </>
        );
    }, [isLoading, incorrectReviewsCount]);

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name:
            incorrectReviewsCount === 0 ? IllustrationOldEnum.Success : IllustrationOldEnum.Delete,
    };

    return (
        <Modal isOpen={show} closeModal={hide}>
            <Dialog
                dataTrackId=""
                headerContent={illustrationElement}
                title={title}
                actions={[
                    <Button
                        dataTrackId="review_cleaner__cancel"
                        variant="secondary"
                        size="large"
                        onClick={hide}
                        appearance="outlined"
                        full
                    >
                        {t('cancel')}
                    </Button>,
                    <Button
                        dataTrackId="review_cleaner__save"
                        variant={incorrectReviewsCount === 0 ? 'success' : 'danger'}
                        size="large"
                        onClick={handleConfirm}
                        full
                        disabled={isLoading}
                    >
                        {incorrectReviewsCount === 0 ? t('confirm') : t('delete')}
                    </Button>,
                ]}
            >
                <ReviewCleanerContent justifyContent="center" alignItems="center">
                    {content}
                </ReviewCleanerContent>
            </Dialog>
        </Modal>
    );
}
