import { ApiResourceBaseClass } from '@partoohub/api-client';

import { DiffusionFilter, V2FormattedDiffusionReceivedData } from 'admin/api/types/diffusion';

const RESOURCE_URI = 'diffusions';

export class DiffusionApiCall extends ApiResourceBaseClass {
    async getDiffusion(filter: DiffusionFilter): Promise<V2FormattedDiffusionReceivedData> {
        const { data } = await this.get<V2FormattedDiffusionReceivedData>(`${RESOURCE_URI}/list`, {
            ...filter,
        });

        return data;
    }
}
