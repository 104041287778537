import {
    IllustrationCategoryEnum,
    IllustrationNetworkElement,
    IllustrationNetworkEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';

import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';

import { PageWrapper } from './NotFoundPage.styled';

const NotFoundPage = () => {
    const { t } = useTranslation();

    const illustrationElement: IllustrationNetworkElement = {
        name: IllustrationNetworkEnum.NotFound,
        type: IllustrationCategoryEnum.Network,
    };

    return (
        <PageWrapper>
            <NotFoundTemplate
                show
                title={t('page_not_found_title')}
                subtitle={t('page_not_found_description')}
                imgSrc={illustrationElement}
                withButton={false}
            />
        </PageWrapper>
    );
};

export default NotFoundPage;
