import { FC, useEffect, useRef, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { BusinessModalFilters } from '@partoohub/modular-components';
import { ButtonsGroup } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { resetBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers';

import { diffusionApiClient } from 'admin/api/apiResources';
import {
    V2FormattedDiffusion,
    V2FormattedDiffusionErrors,
    V2FormattedDiffusionPostCheckErrors,
    V2FormattedDiffusionReceivedData,
} from 'admin/api/types/diffusion';
import { BusinessModalComponent } from 'admin/common/components/businessModal/BusinessModal';
import AdminPage from 'admin/common/components/templates/AdminPage';
import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';

import { useWebsocket } from 'admin/common/contexts/websocketContext';
import { DIRECT_PUBLISHERS } from 'admin/common/data/constants/publisher';
import { DIFFUSION_UPDATED, NEW_DIFFUSION } from 'admin/common/data/websocket';
import { camelCasify } from 'admin/common/utils/CamelCasify';
import { removeEmptyValues } from 'admin/common/utils/filter';
import { getSelectedOptions } from 'admin/common/utils/selectOptions';
import { EmptyPlaceholder } from 'admin/content/logs/common/components/List.styled';

import { DIFFUSERS_BY_PUBLISHER } from './diffusers';

import { DiffusionLogsFiltersWrapper, DiffusionLogsSmallFilter } from './DiffusionLogs.styled';
import { DiffusionLogsPublisherSelect } from './DiffusionLogsDiffuserSelect';
import { DiffusionLogsStateSelect } from './DiffusionLogsStateSelect';

import NotificationButton from './NotificationButton';
import DiffusionRow from './tableComponents/contents/DiffusionRow/DiffusionRow';
import LoadingDetails from './tableComponents/contents/LoadingDetails/LoadingDetails';
import DiffusionTableHeader from './tableComponents/header/DiffusionTableHeader';

export interface DiffusionData {
    updateDate: number;
    creationDate: number;
    errors: Array<V2FormattedDiffusionErrors>;
    logs: string;
    publisher: string;
    businessId: string;
    businessOrgId: number;
    businessName: string;
    businessAddress: string;
    businessZipcode: string;
    businessCity: string;
    partnerName: string;
    state: string;
    diffuser: string;
    logsHumanErrorMessage: Array<string>;
    postCheckErrors: Array<V2FormattedDiffusionPostCheckErrors>;
}

const formatDiffusionApiStateToInternalState = (
    diffusion: Array<V2FormattedDiffusion>,
): Array<DiffusionData> => diffusion.map(d => camelCasify(d) as DiffusionData);

const Diffusion: FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const websocket = useWebsocket();
    const [diffusions, setDiffusions] = useState<Array<DiffusionData>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState<BusinessModalFilters>({});
    const [publisher, setPublisher] = useState<Array<string>>([]);
    const [diffusers, setDiffusers] = useState<Record<string, boolean>>({});
    const [states, setStates] = useState<Record<string, boolean>>({});
    const [websocketDiffusion, setWebsocketDiffusion] = useState<Array<DiffusionData>>([]);
    const diffusionsRef = useRef(diffusions);
    const filtersWithoutEmptyValues = removeEmptyValues(filters);
    // A ref is required inside the websocket.onmessage to have actual data
    const currentFiltersRef = useRef<any>({
        searchParams: {},
        publisher,
        diffusers,
        states,
    });
    const requestVersionRef = useRef<number>(0);
    const selectedStates = getSelectedOptions(states);
    const selectedDiffusers = getSelectedOptions(diffusers);
    const queryParams = {
        ...filtersWithoutEmptyValues,
        publisher__in: publisher === undefined ? null : publisher[0],
        diffuser__in: selectedDiffusers.length ? selectedDiffusers.join(',') : null,
        state__in: selectedStates.length ? selectedStates.join(',') : null,
    };

    const isDiffusionEqual = (obj1: any, obj2: any) =>
        obj1.diffuser === obj2.diffuser && obj1.businessId === obj2.businessId;

    const containsObject = (obj: any, list: any) => {
        let i;
        for (i = 0; i < list.length; i += 1) {
            if (isDiffusionEqual(list[i], obj)) {
                return true;
            }
        }
        return false;
    };

    const updateWebsocketDiffusionsReceived = (obj: any, list: any) => {
        let i;
        for (i = 0; i < list.length; i += 1) {
            if (isDiffusionEqual(list[i], obj)) {
                const updatedList = list;
                updatedList[i] = {
                    ...list[i],
                    state: obj.state,
                    creationDate: obj.creationDate,
                    updateDate: obj.updateDate,
                    logs: obj.logs,
                };
                updatedList.sort((a: any, b: any) => a.updateDate - b.updateDate);
                return [...updatedList];
            }
        }
        return [obj, ...list];
    };

    const updateDiffusions = (obj: any, list: any) => {
        let i;
        for (i = 0; i < list.length; i += 1) {
            if (isDiffusionEqual(list[i], obj)) {
                const updatedList = list;
                updatedList[i] = {
                    ...list[i],
                    state: obj.state,
                    creationDate: obj.creationDate,
                    updateDate: obj.updateDate,
                    logs: obj.logs,
                };
                return [...updatedList];
            }
        }
        return [...list];
    };

    const matchCurrentFilter = (diffusion: DiffusionData) => {
        const currentBusinessIncludedIds = currentFiltersRef.current?.business__in ?? [];
        const currentBusinessExcludedIds = currentFiltersRef.current?.business__notin ?? [];
        const currentOrgId = currentFiltersRef.current?.business_org_id ?? null;
        const currentPublishers = currentFiltersRef.current.publishers;
        const currentDiffusers = currentFiltersRef.current.diffusers;

        if (
            currentBusinessIncludedIds.length &&
            !currentBusinessIncludedIds.includes(diffusion.businessId)
        )
            return false;
        if (
            currentBusinessExcludedIds.length &&
            currentBusinessExcludedIds.includes(diffusion.businessId)
        )
            return false;
        if (currentOrgId && currentOrgId !== diffusion.businessOrgId) return false;
        if (currentPublishers.length && !currentPublishers.includes(diffusion.publisher))
            return false;
        if (currentDiffusers.length && !currentDiffusers.includes(diffusion.diffuser)) return false;
        if (currentFiltersRef.current.states.length === 0) return true;
        return currentFiltersRef.current.states.includes(diffusion.state);
    };

    const hasFilters = [
        queryParams?.business__in,
        queryParams?.business_query,
        queryParams?.business_org_id,
        queryParams?.diffuser__in,
        queryParams?.publisher__in,
        queryParams?.state__in,
    ].some(Boolean);

    const websocketMessages = (event: any) => {
        const data = JSON.parse(event.data);
        const formattedDiffusions = formatDiffusionApiStateToInternalState([data.data]);
        const filteredDiffusion = formattedDiffusions.filter(matchCurrentFilter);

        if (filteredDiffusion.length === 0) return;

        filteredDiffusion.forEach(diffusion => {
            if (data.type === DIFFUSION_UPDATED) {
                if (containsObject(diffusion, diffusionsRef.current)) {
                    setDiffusions(state => updateDiffusions(diffusion, state));
                } else {
                    setWebsocketDiffusion(state =>
                        updateWebsocketDiffusionsReceived(diffusion, state),
                    );
                }
            }
            if (data.type === NEW_DIFFUSION) {
                setWebsocketDiffusion(state => updateWebsocketDiffusionsReceived(diffusion, state));
            }
        });
    };

    const pullDiffusions = () => {
        requestVersionRef.current += 1;
        const version = requestVersionRef.current;

        setIsLoading(true);
        setDiffusions([]);
        setWebsocketDiffusion([]);
        diffusionApiClient
            .getDiffusion(queryParams)
            .then((data: V2FormattedDiffusionReceivedData) => {
                if (version < requestVersionRef.current) return;

                const { diffusions: diffusion } = data;
                setDiffusions(formatDiffusionApiStateToInternalState(diffusion));
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        websocket?.then(ws => {
            ws.addEventListener('open', () => undefined);

            ws.addEventListener('message', e => {
                websocketMessages(e);
            });

            return () => ws.close();
        });
    }, []);

    useEffect(() => {
        diffusionsRef.current = diffusions;
    }, [diffusions]);

    useEffect(() => {
        currentFiltersRef.current = {
            searchParams: filtersWithoutEmptyValues,
            publisher,
            diffusers,
            states,
        };
        pullDiffusions();
    }, [
        queryParams.business__in,
        queryParams.business__notin,
        queryParams.business_org_id,
        publisher,
        diffusers,
        states,
    ]);

    const addNewDiffusions = () => {
        setDiffusions([...websocketDiffusion, ...diffusions]);
        setWebsocketDiffusion([]);
    };

    const resetFilters = () => {
        setStates({});
        setDiffusers({});
        dispatch(resetBusinessFilter());
    };

    const [selected, setSelected] = useState('all');

    const selectPublisher = (publisher: string) => {
        setDiffusers({});
        setPublisher(publisher !== 'all' ? [publisher] : []);
        setSelected(publisher);
    };

    const publishersTabs = ['all', ...DIRECT_PUBLISHERS];

    const illustrationElement: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.Happy,
        type: IllustrationCategoryEnum.Laptop,
    };

    return (
        <AdminPage
            dataTrackId="page_logs_diffusion"
            title={t('admin:page_logs_diffusion__title')}
            infoLink={'https://partoo.elium.com/tile/view/277/'}
            buttons={
                <BusinessModalComponent
                    variant="businessOnly"
                    buttonVariant="page"
                    filters={filters}
                    setFilters={setFilters}
                />
            }
        >
            <ButtonsGroup selected={selected} onChange={selectPublisher}>
                {publishersTabs.map(tab => (
                    <ButtonsGroup.ButtonGroup key={`tabs-${tab}`} value={tab}>
                        {t(tab)}
                    </ButtonsGroup.ButtonGroup>
                ))}
            </ButtonsGroup>
            <DiffusionLogsFiltersWrapper>
                <DiffusionLogsSmallFilter>
                    <DiffusionLogsStateSelect
                        states={states}
                        setStates={setStates}
                        disabled={false}
                    />
                </DiffusionLogsSmallFilter>
                <DiffusionLogsSmallFilter>
                    {!!publisher.length && publisher[0] in DIFFUSERS_BY_PUBLISHER && (
                        <DiffusionLogsPublisherSelect
                            diffusers={diffusers}
                            setDiffusers={setDiffusers}
                            disabled={false}
                            diffuserOptions={DIFFUSERS_BY_PUBLISHER[publisher]}
                        />
                    )}
                </DiffusionLogsSmallFilter>
            </DiffusionLogsFiltersWrapper>
            {!diffusions.length && !isLoading ? (
                <EmptyPlaceholder>
                    <NotFoundTemplate
                        show
                        title={t('no-result-found')}
                        subtitle={hasFilters ? t('page_logs__empty_placeholder__subtitle') : ''}
                        imgSrc={illustrationElement}
                        buttonText={t('page_logs__empty_placeholder__button')}
                        handleClick={resetFilters}
                        withButton={hasFilters}
                    />
                </EmptyPlaceholder>
            ) : (
                <DiffusionTableHeader />
            )}
            <NotificationButton count={websocketDiffusion.length} onClick={addNewDiffusions} />
            {isLoading && [...Array(10)].map((_value, index) => <LoadingDetails key={index} />)}
            {diffusions.map((value, index) => (
                <DiffusionRow key={index} log={value} />
            ))}
        </AdminPage>
    );
};

export default Diffusion;
