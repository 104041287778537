import { FontAwesomeIconsPartooUsed, IconPrefix, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'admin/common/data/config';

import { FullScreen, LeftPart, RightPart, StyledIconButton } from './FullScreenModal.styled';

type Props = {
    childrenLeft: JSX.Element;
    childrenRight?: JSX.Element;
    show: boolean;
    onHideClick: () => void;
    forceDisplayExitButton?: boolean;
    forceDisplayRightPart?: boolean;
    hideCloseButton?: boolean;
};
export const FullScreenModal = ({
    show,
    onHideClick,
    childrenLeft,
    childrenRight,
    forceDisplayExitButton = false,
    forceDisplayRightPart = false,
    hideCloseButton = false,
}: Props) => {
    const { t } = useTranslation();
    const displayRightPart = childrenRight && forceDisplayRightPart;
    const closeButton = (
        <StyledIconButton
            aria-label={t('admin:close')}
            appearance="outlined"
            onClick={onHideClick}
            icon={[FontAwesomeIconsPartooUsed.faXMarkLarge, IconPrefix.SOLID]}
            dataTrackId="close_fullscreen_modal_button"
        />
    );
    return (
        <Modal isOpen={show} closeModal={onHideClick} placement="center" animation="fadeIn">
            <FullScreen>
                <LeftPart>{childrenLeft}</LeftPart>
                {displayRightPart && (
                    <RightPart
                        backgroundUrl={`${STATIC_BASE_URL}/images/app/funnel/background.png`}
                    >
                        {childrenRight}
                    </RightPart>
                )}
                {!hideCloseButton && closeButton}
                {!childrenRight && forceDisplayExitButton && closeButton}
            </FullScreen>
        </Modal>
    );
};
