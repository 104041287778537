import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';

const RESOURCE_URI = 'bulk_review_treated';

type BulkReviewTreatmentFilter = {
    org_id: number;
    business_ids?: Array<string>;
    date: string;
    reviewType: 'all' | 'only_with_comment' | 'only_without_comment';
};

type BulkReviewTreatmentResponse = {
    status: string;
};

export class ReviewTreatmentAdminApiCall extends AdminApiResourceBaseClass {
    static async submitBulkReviewTreatmentCall(
        filter: BulkReviewTreatmentFilter,
    ): Promise<BulkReviewTreatmentResponse> {
        const { data } = await ReviewTreatmentAdminApiCall.post(
            RESOURCE_URI,
            filter,
        );
        return data;
    }
}
