import React from 'react';

import { Button, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useMe } from 'admin/common/hooks/queries/useMe';

export const ReinstatementFormButton: React.FC = () => {
    const { t } = useTranslation();
    const { data: user } = useMe();

    const reinstatementFormUrl = `https://support.google.com/business/troubleshooter/2690129?hl=${
        user?.lang || 'fr'
    }#ts=9408879%2C2690101%2C2858538%2C2690136%2C2690105%2C2690141%2C2691868`;

    return (
        <Button
            appearance="outlined"
            shape="rounded"
            size="medium"
            variant="secondary"
            dataTrackId="admin_diffusion_cleaner__reinstatement_form_business_button"
            onClick={() => window.open(reinstatementFormUrl, '_blank', 'noreferrer')}
        >
            <Text variant="bodyMBold">
                {t('admin:page_diffusion_cleaner__business_button__reinstatement_form')}
            </Text>
        </Button>
    );
};
