import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, CustomFile, Modal, TextArea, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import {
    FoodMenuItemFormType,
    FoodMenuItemType,
} from 'admin/common/api/calls/bulkFoodMenuApiCalls';

import {
    AddItemDescription,
    AddItemInputLineWrapper,
    AddItemInputWrapper,
    AddItemName,
    AddItemPrice,
    CancelButton,
    ModalContent,
    ModalSubtitle,
    ModalTitle,
    ModalWrapper,
} from 'admin/content/data/pages/FoodMenusCreation/components/MenuSections/modal.styled';
import { usePhotosUpload } from 'admin/content/data/pages/FoodMenusCreation/hooks/usePhotosUpload';

import { FoodMenuDropzone } from './FoodMenuDropzone';

type Props = {
    show: boolean;
    handleClose: () => void;
    handleConfirm: (item: FoodMenuItemType) => void;
    initialData: FoodMenuItemType | null;
    currency: string;
    type?: string;
};

const defaultFormData = {
    name: '',
    description: '',
    price: '',
    media: undefined,
    order: 0,
};

function errorMaxCharacters(value: string, maxLen?: number | null) {
    return maxLen ? !!(value && value.length > maxLen) : false;
}

const itemToFormData = (item: FoodMenuItemType): FoodMenuItemFormType => ({
    ...defaultFormData,
    ...item,
    ...{ price: item.price ? item.price.toString().replace('.', ',') : '' },
});

const priceInputToFloat = (priceInput: string) => parseFloat(priceInput.replace(',', '.'));

const removeDuplicateCharacters = (str: string): string =>
    str
        .split('')
        .filter((item, pos, self) => self.indexOf(item) === pos || item !== ',')
        .join('');

export const AddItemModal = ({
    currency,
    handleClose,
    handleConfirm,
    initialData,
    show,
    type,
}: Props) => {
    const [formData, setFormData] = useState<FoodMenuItemFormType>({
        ...defaultFormData,
    });
    const { t } = useTranslation();

    const [files, setFiles] = useState<CustomFile[]>([]);

    useEffect(() => {
        if (show) {
            setFormData(initialData ? itemToFormData({ ...initialData }) : { ...defaultFormData });
        }
    }, [show]);

    const onConfirm = useCallback(() => {
        const itemPrice = priceInputToFloat(formData.price);
        const item = {
            ...formData,
            name: formData.name,
            price: !Number.isNaN(itemPrice) ? itemPrice : undefined,
        };
        handleConfirm(item);
        setFormData({
            name: '',
            description: '',
            price: '',
            media: undefined,
            order: 0,
        });
        handleClose();
    }, [formData, handleConfirm]);

    const setOnChange = (value: string) => {
        setFormData({
            ...formData,
            price: value ? removeDuplicateCharacters(value.replace(/[^\d,]/g, '')) : '',
        });
    };

    const { mutate: uploadPhotos, isLoading } = usePhotosUpload(data => {
        setFormData({
            ...formData,
            media: {
                source_url: data.result.uploads[0],
                public_url: undefined,
            },
        });
    });

    const handleDropSuccess = (files: CustomFile[]) => {
        setFiles(files);
        uploadPhotos(files);
    };

    const deletePhoto = () => {
        setFiles([]);
        setFormData({
            ...formData,
            media: undefined,
        });
    };

    const isFormValid: boolean = useMemo<boolean>(
        () =>
            !!(
                formData.name &&
                formData.name.replace(/\s/g, '').length > 0 &&
                formData.name.length <= 140 &&
                formData.description?.length <= 1000 &&
                !(Number.isNaN(priceInputToFloat(formData.price)) && formData.price !== '') &&
                !isLoading
            ),
        [formData, isLoading],
    );

    return (
        <Modal isOpen={show} closeModal={handleClose}>
            <ModalWrapper>
                <ModalContent>
                    <ModalTitle>
                        {type === 'add'
                            ? t('add_food_menu_item_title')
                            : t('edit_food_menu_item_title')}
                    </ModalTitle>
                    <ModalSubtitle>
                        {type === 'add'
                            ? t('add_food_menu_item_subtitle')
                            : t('edit_food_menu_item_subtitle')}
                    </ModalSubtitle>
                    <AddItemInputWrapper>
                        <AddItemInputLineWrapper>
                            <AddItemName>
                                <TextInput
                                    dataTrackId="food-menu-add-item-name"
                                    label={t('food_menu_item_name')}
                                    characterCount={140}
                                    required
                                    value={formData.name}
                                    onChange={value => {
                                        setFormData({
                                            ...formData,
                                            name: value || '',
                                        });
                                    }}
                                />
                            </AddItemName>
                            <AddItemPrice>
                                <TextInput
                                    dataTrackId="food-menu-add-item-name"
                                    label={`${t('food_menu_item_price')} (${currency})`}
                                    value={formData.price}
                                    onChange={value => setOnChange(value)}
                                />
                            </AddItemPrice>
                        </AddItemInputLineWrapper>
                        <AddItemInputLineWrapper>
                            <AddItemDescription>
                                <TextArea
                                    characterCount={1000}
                                    rows={6}
                                    maxRows={10}
                                    value={formData.description}
                                    onChange={value => {
                                        setFormData({
                                            ...formData,
                                            description: value,
                                        });
                                    }}
                                    error={errorMaxCharacters(formData.description, 1000)}
                                    label={t('food_menu_item_description')}
                                    dataTrackId={'add-menu-item-description'}
                                />
                            </AddItemDescription>
                            <FoodMenuDropzone
                                files={files}
                                handleDropSuccess={handleDropSuccess}
                                deletePhoto={deletePhoto}
                            />
                        </AddItemInputLineWrapper>
                    </AddItemInputWrapper>
                </ModalContent>

                <CancelButton>
                    <Button
                        dataTrackId="food-menus-cancel-add-element"
                        variant="secondary"
                        onClick={handleClose}
                        size="large"
                        appearance="outlined"
                        full
                    >
                        {t('cancel')}
                    </Button>
                </CancelButton>
                <Button
                    dataTrackId="add_menu_item"
                    size="large"
                    full
                    variant="primary"
                    shape="cube"
                    disabled={!isFormValid}
                    isLoading={isLoading}
                    onClick={onConfirm}
                >
                    {t('validate')}
                </Button>
            </ModalWrapper>
        </Modal>
    );
};
