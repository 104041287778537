import { StatusType } from 'app/api/types';
import {
    ConnectionLogoutData,
    ConnectionReceivedData,
    LoginData,
    LoginHeaders,
    LoginSsoOidcData,
    LoginWithTokenData,
    LostPasswordData,
    PasswordData,
    V2ConnectionToken,
} from 'app/api/types/connection';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'connection';

class Connection extends ApiResourceBaseClass {
    static generateConnectionToken(userId: string, ttl: number): Promise<V2ConnectionToken> {
        return Connection.post(`${RESOURCE_URI}/generate_token`, {
            ttl,
            user_id: userId,
        }).then(({ data }) => data);
    }

    static completeInvitation(invitData: PasswordData): Promise<ConnectionReceivedData> {
        return Connection.post(`${RESOURCE_URI}/invitation`, invitData).then(({ data }) => data);
    }

    static login(
        loginData: LoginData | LoginWithTokenData,
        headers: LoginHeaders = {},
    ): Promise<ConnectionReceivedData> {
        return Connection.post(`${RESOURCE_URI}/login`, loginData, headers).then(
            ({ data }) => data,
        );
    }

    static googleLogin(loginData: LoginData | LoginWithTokenData): Promise<ConnectionReceivedData> {
        return Connection.post(`${RESOURCE_URI}/google_login`, loginData).then(({ data }) => data);
    }

    static logout(): Promise<ConnectionLogoutData> {
        return Connection.post(`${RESOURCE_URI}/logout`).then(({ data }) => data);
    }

    static resetPassword(resetData: PasswordData): Promise<StatusType> {
        return Connection.post(`${RESOURCE_URI}/reset_password`, resetData).then(
            ({ data }) => data,
        );
    }

    static lostPassword(lostData: LostPasswordData): Promise<ConnectionReceivedData> {
        return Connection.post(`${RESOURCE_URI}/lost_password`, lostData).then(({ data }) => data);
    }

    static oidcAuth(
        ssoAlias: string,
        loginData: LoginSsoOidcData,
    ): Promise<ConnectionReceivedData> {
        return Connection.post(`oidc/${ssoAlias}/auth`, loginData).then(({ data }) => data);
    }

    static generateSsoLoginUrl(type: string, ssoAlias: string): string {
        return `/api/v2/${type}/${ssoAlias}/login`;
    }
}

export default Connection;
