import styled from '@emotion/styled';

export const DiffusionLogsFiltersWrapper = styled.div`
    display: flex;
    margin: 8px 0px 8px 0px;
    gap: 8px;
    flex-wrap: wrap;
    width: 25%;
`;

export const DiffusionLogsSmallFilter = styled.div`
    flex-basis: 135px;
    flex-grow: 2;
`;
