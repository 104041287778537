import { CityObject } from 'app/api/types/cities';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'cities';

class Cities extends ApiResourceBaseClass {
    static search(query = ''): Promise<Array<CityObject>> {
        return Cities.get(`${RESOURCE_URI}`, {
            query,
        }).then(({ data }) => data);
    }

    static getTopCities(): Promise<Array<CityObject>> {
        return Cities.get(`${RESOURCE_URI}/top`).then(({ data }) => data);
    }
}

export default Cities;
