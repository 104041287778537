import { ComponentType, Ref } from 'react';

/**
 * HOC that serves as forward ref but for class components.
 * A wrapped class component can receive the ref prop, and it will
 * be available inside the wrapped component as the upperRef prop.
 */
export const withForwardedRef = <R extends HTMLElement | null, P extends { upperRef?: Ref<R> }>(
    WrappedComponent: ComponentType<P>,
) => {
    return (props: P & { externalRef?: Ref<R> }) => {
        return <WrappedComponent {...props} upperRef={props.externalRef} />;
    };
};
