import AdminApiResourceBaseClass from 'admin/common/api/calls/baseAdminApiCalls';

const RESOURCE_URI = 'integrations';

class IntegrationsApiCall extends AdminApiResourceBaseClass {
    static getIntegrations(): Promise<any> {
        return IntegrationsApiCall.get(`${RESOURCE_URI}`).then(({ data }) => data);
    }
}

export default IntegrationsApiCall;
