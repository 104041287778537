import { ReactNode } from 'react';

import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';
import { Pagination, Text } from '@partoohub/ui';

import { ChevronDown } from 'admin/common/components/icons/ChevronDown';

export const Row = styled.div`
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(20, 37, 66, 0.08);
    &:hover {
        box-shadow: 0px 5px 10px rgba(20, 37, 66, 0.16);
    }
`;

export const RowWithLayout = styled(Row)`
    display: 'flex';
    cursor: pointer;
`;

export const QuickConnectAction = styled.summary`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 5px;
`;
export const DisclosureTrigger = styled.summary`
    list-style: none;
    &::-webkit-details-marker {
        display: none;
    }

    ${({ expanded }: { expanded: boolean }) =>
        expanded &&
        'border-bottom-style: solid;' +
            'border-bottom-width: 1px;' +
            `border-bottom-color: ${greyColorObject.initial};`}

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    gap: 16px;
`;

export const StyledChevron = styled(ChevronDown)`
    color: ${HEX_COLORS.secondary};
    ${({ upward }: { upward: boolean }) => upward && 'transform: rotate(0.5turn);'}
`;

export const BusinessName = styled(
    ({ children, className }: { children?: ReactNode; className?: string }) => (
        <Text className={className} variant="bodyMBold">
            {children}
        </Text>
    ),
)`
    display: flex;
`;

export const BulkButtonWrapper = styled.div`
    display: flex;
    gap: 5px;
`;

export const PaginationLayout = styled(Pagination)`
    justify-content: center;
`;
