import { useTranslation } from 'react-i18next';

import { ServiceItem, ServiceItemProps } from './ServiceItem';

type SectionBodyProps = {
    servicesList: ServiceItemProps[];
};

export const SectionBody = ({ servicesList }: SectionBodyProps) => {
    const { t } = useTranslation();
    return (
        <>
            {servicesList.map(elem => (
                <ServiceItem
                    key={elem.mainLabel}
                    mainLabel={elem.mainLabel}
                    description={elem.description}
                    optionalLabel={elem.optionalLabel}
                    menuElem={elem.menuElem}
                    disabled={!!elem.disabled}
                />
            ))}
            {servicesList.length === 0 && (
                <ServiceItem
                    key={t('no_gmb_services_for_the_category_yet')}
                    mainLabel={t('no_gmb_services_for_the_category_yet')}
                    disabled
                />
            )}
        </>
    );
};
