import { MessageReplyTemplate } from 'app/states/messageReplyTemplate/dataTypes';

type MessageTemplateContent = {
    _version: number;
    text: string;
};

const formatPlaceholder = (text: string): string => text.replace(/@\[.*?\]\((\w+)\)/g, '{{$1}}');

const getTemplateContent = (template: MessageReplyTemplate): MessageTemplateContent => {
    const content = template.content;
    return JSON.parse(content);
};

const generateNewDuplicateTitle = (title: string) => {
    // this method increments or add an index to the given string. Eg.:
    // - "my title" -becomes-> "my title (2)"
    // - "my title (2)" -becomes-> "my title (3)" (and so on)
    const prevIndex = /\((\d{1,3})\)$/.exec(title);
    const nextIndex = prevIndex ? parseInt(prevIndex[1]) + 1 : 2;
    const fragment = `(${nextIndex})`;
    if (prevIndex) {
        // replace the old index with new value.
        return title.replace(/\((\d+)\)$/, fragment);
    }
    {
        // add an index for the first time.
        return `${title} ${fragment}`;
    }
};

export { getTemplateContent, generateNewDuplicateTitle, formatPlaceholder };
