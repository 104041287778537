import { ApiResourceBaseClass } from '@partoohub/api-client';

import {
    AdminBulkCreateFoodMenu,
    AdminBulkCreatePhotoMenu,
    BusinessModalFiltersType,
    FoodMenuGetDataType,
} from 'admin/api/types';

const RESOURCE_URI = 'food_menus';

export class BulkFoodMenuApiCall extends ApiResourceBaseClass {
    async deleteFoodMenu(queryParams: BusinessModalFiltersType): Promise<FoodMenuGetDataType> {
        const { data } = await this.post<FoodMenuGetDataType>(
            `${RESOURCE_URI}/bulk_delete`,
            queryParams,
        );
        return data;
    }

    async updateFoodMenu(queryParams: AdminBulkCreateFoodMenu): Promise<FoodMenuGetDataType> {
        const { data } = await this.post<FoodMenuGetDataType>(
            `${RESOURCE_URI}/bulk_create_food_menus`,
            queryParams,
        );
        return data;
    }

    async updatePhotoMenu(queryParams: AdminBulkCreatePhotoMenu): Promise<FoodMenuGetDataType> {
        const { data } = await this.post<FoodMenuGetDataType>(
            `${RESOURCE_URI}/bulk_create_menu_medias`,
            queryParams,
        );
        return data;
    }
}
