import { Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import {
    ConfirmationModalButtons,
    ConfirmationModalContainer,
    ConfirmationModalDescription,
    ConfirmationModalTitle,
    StyledButton,
} from './MergeAccountsConfirmationModal.styled';

type DeleteModalProps = {
    onClose: () => void;
    opened: boolean;
    onConfirm: () => void;
};

export const MergeAccountsConfirmationModal = ({
    opened,
    onClose,
    onConfirm,
}: DeleteModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={opened} closeModal={onClose}>
            <ConfirmationModalContainer>
                <ConfirmationModalTitle>
                    {t('admin:org_switcher_merge_accounts_CTA')}
                </ConfirmationModalTitle>
                <ConfirmationModalDescription>
                    {t('delete_modal_subtitle')}
                </ConfirmationModalDescription>
                <ConfirmationModalButtons>
                    <StyledButton
                        appearance="outlined"
                        variant="secondary"
                        dataTrackId="delete_group_cancel"
                        onClick={onClose}
                        size="large"
                        shape="cube"
                    >
                        {t('cancel')}
                    </StyledButton>
                    <StyledButton
                        dataTrackId="delete_group_delete"
                        variant="primary"
                        onClick={onConfirm}
                        size="large"
                        shape="cube"
                    >
                        {t('confirm')}
                    </StyledButton>
                </ConfirmationModalButtons>
            </ConfirmationModalContainer>
        </Modal>
    );
};
