import { useConstant } from 'app/common/hooks/useConstant';

let id = 0;

/**
 * Generates a unique id when the component using this hook mounts.
 * The unique id persists across renders and is unique across all
 * components using useMountId(). When the component unmounts and
 * remounts, a new one is generated. Also, if this is used multiple
 * times in one component, it generates multiple ids.
 */
export const useMountId = (): string => useConstant(() => (id++).toString(10));
