import { useState } from 'react';

import { useGetKeywordsFromOrg } from '../../hooks/useGetKeywordsFromOrg';
import { useOrgIdParsed } from '../../hooks/useOrgIdParsed';
import { KeywordsSection } from '../KeywordsSection/KeywordsSection';
import { KeywordsSectionPlaceholder } from '../KeywordsSectionPlaceholder/KeywordsSectionPlaceholder';
import { ModalCreateKeyword } from '../ModalCreateKeyword/ModalCreateKeyword';

export const KeywordsSectionDisplayHandlerSection = () => {
    const orgId = useOrgIdParsed();
    const [openedKeywordModal, setOpenedKeywordModal] = useState(false);
    const { keywords, isLoading } = useGetKeywordsFromOrg(orgId);

    return (
        <>
            {keywords.length === 0 && !isLoading && (
                <KeywordsSectionPlaceholder action={setOpenedKeywordModal} />
            )}
            {keywords.length > 0 && (
                <KeywordsSection createKeyword={() => setOpenedKeywordModal(true)} />
            )}
            {openedKeywordModal && (
                <ModalCreateKeyword onClose={() => setOpenedKeywordModal(false)} />
            )}
        </>
    );
};
