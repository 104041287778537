import { useRef } from 'react';

interface ResultBox<T> {
    v: T;
}

/**
 * Taken from https://github.com/Andarist/use-constant/blob/master/src/index.ts
 *
 * Almost the same as useMemo(() => fn(), []), but useMemo doesn't
 * guarantee that the callback is never called again across re-renders.
 * This hook guarantees that the callback is called once (when the
 * component mounts).
 */
export const useConstant = <T>(fn: () => T): T => {
    const ref = useRef<ResultBox<T>>();

    if (!ref.current) {
        ref.current = { v: fn() };
    }

    return ref.current.v;
};
