import { useMutation, useQueryClient } from 'react-query';

import api from 'admin/common/api/calls';
import {
    CompetitorWithId,
    CreateNewCompetitorPayload,
} from 'admin/common/api/types/competitiveBenchmark';
import { COMPETITIVE_BENCHMARK_KEYWORDS } from 'admin/common/data/queryKeysConstants';

import { useOrgIdParsed } from './useOrgIdParsed';

export const useCreateNewCompetitor = () => {
    const queryClient = useQueryClient();
    const orgId = useOrgIdParsed();

    const {
        mutate: createNewCompetitor,
        isSuccess,
        isError,
        reset,
    } = useMutation(
        (payload: CreateNewCompetitorPayload): Promise<CompetitorWithId> => {
            return api.competitiveBenchmark.createCompetitor({ ...payload, orgId });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([COMPETITIVE_BENCHMARK_KEYWORDS, orgId]);
            },
        },
    );

    return {
        mutate: createNewCompetitor as (
            payload: CreateNewCompetitorPayload,
        ) => Promise<CompetitorWithId>,
        isSuccess,
        isError,
        reset,
    };
};
