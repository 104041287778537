import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const IconWrapper = styled.div`
    width: 40px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    > img {
        width: 100%;
        height: 100%;
    }
`;

export const Icon = styled.div`
    height: auto;
    width: 40px;
`;

export const ErrorInfo = styled.div`
    flex-basis: 400px;
    flex-shrink: 3;
    flex-grow: 1;
    margin-right: 5px;
    overflow: hidden;
`;

export const Count = styled(Text)`
    flex-basis: 10px;
    flex-grow: 2;
    margin-right: 5px;
    margin-left: 20px;
    overflow: hidden;
    flex-shrink: 0;
`;

export const Actions = styled.div`
    flex-basis: 300px;
    justify-content: right;
    display: flex;
    flex-grow: 2;
    flex-wrap: wrap;
`;

export const ChevronWrapper = styled.div`
    width: 12px;
`;
