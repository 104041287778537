import { FC, useMemo, useState } from 'react';

import { CustomFile, DropzoneTypeEnum, Switch, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import BulkImportApiCall from 'app/api/v2/api_calls/bulkImportApiCalls';

import { BulkTypeSelect, bulkTypeEnum } from 'admin/common/components/inputs/BulkTypeSelect';
import { DropZoneDescription } from 'admin/common/components/inputs/DropZone/DropZoneDescription';
import { DropZoneWithoutUpload } from 'admin/common/components/inputs/DropZone/DropzoneWithoutUpload';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

const MAX_SIZE = 5_000_000;
const ACCEPTED_FILES = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

const Importer: FC = () => {
    const { t } = useTranslation();

    const [files, setFiles] = useState<CustomFile[]>([]);
    const [fileHasError, setFileHasError] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [makeModification, setMakeModification] = useState(true);
    const [sendInvitation, setSendInvitation] = useState(false);
    const [bulkType, setBulkType] = useState<string | null>(null);
    const isFormValid = useMemo(
        () => files.length && !fileHasError && !!bulkType,
        [files, bulkType, fileHasError],
    );

    const submitForm = async () => {
        if (!isFormValid) return;

        setIsFormLoading(true);

        try {
            if (bulkType === bulkTypeEnum.LOCATION) {
                await BulkImportApiCall.bulkBusinessImport(files[0] as Blob, makeModification);
                toast.loading(
                    t('admin:page_importer__success_toast__content'),
                    t('admin:page_importer__success_toast__title'),
                );
            } else if (bulkType === bulkTypeEnum.USER) {
                await BulkImportApiCall.bulkUserImport(
                    files[0] as Blob,
                    makeModification,
                    sendInvitation,
                );
                toast.loading(
                    t('admin:page_importer__success_toast__content'),
                    t('admin:page_importer__success_toast__title'),
                );
            } else {
                toast.error('Not implemented', t('admin:toast_error__title'));
            }
        } catch (e: any) {
            toast.error(e.message, t('admin:toast_error__title'));
        } finally {
            resetForm();
        }
    };

    const resetForm = () => {
        setFiles([]);
        setFileHasError(false);
        setIsFormLoading(false);
        setMakeModification(true);
    };

    return (
        <AdminPage
            dataTrackId="page_importer"
            title={t('admin:page_importer__title')}
            description={t('admin:page_importer__description')}
            infoLink={'https://partoo.elium.com/tile/view/270'}
        >
            <BorderedContainer>
                <PageFormRow>
                    <BulkTypeSelect
                        placeholder={t('admin:page_exporter__select_bulk_type')}
                        selectedValue={bulkType}
                        onChange={setBulkType}
                    />
                </PageFormRow>
                {bulkType && (
                    <>
                        <PageFormRow marginBottom={24}>
                            <DropZoneWithoutUpload
                                title={t('drop_zone_label')}
                                acceptedDescription={
                                    <DropZoneDescription
                                        maxSize={MAX_SIZE}
                                        acceptedExtensions={ACCEPTED_FILES}
                                    />
                                }
                                type={DropzoneTypeEnum.Document}
                                files={files}
                                multiple={false}
                                maxSize={MAX_SIZE}
                                full
                                setFiles={setFiles}
                                accept={ACCEPTED_FILES}
                            />
                        </PageFormRow>
                        <PageFormRow marginBottom={8}>
                            <Switch
                                dataTrackId="importer-make-modification__switch"
                                name="make_modification"
                                checked={makeModification}
                                onChange={() => setMakeModification(!makeModification)}
                                label={
                                    <Text variant="bodyMBold" as="span" color="secondary">
                                        {t('admin:write_modifications')}
                                    </Text>
                                }
                            />
                        </PageFormRow>
                    </>
                )}
                {bulkType === bulkTypeEnum.USER && (
                    <PageFormRow marginBottom={8}>
                        <Switch
                            dataTrackId="importer_send_invitation__switch"
                            name="send_invitation"
                            checked={sendInvitation}
                            onChange={() => setSendInvitation(!sendInvitation)}
                            label={
                                <Text variant="bodyMBold" as="span" color="secondary">
                                    {t('admin:send_invitation')}
                                </Text>
                            }
                        />
                    </PageFormRow>
                )}
            </BorderedContainer>
            <SubmitButton
                dataTrackId={bulkType ? `admin_importer__import_${bulkType}_data` : ''}
                text={t('admin:page_importer__button_submit')}
                onClick={submitForm}
                disabled={!isFormValid}
                loading={isFormLoading}
            />
        </AdminPage>
    );
};

export default Importer;
