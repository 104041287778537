import { FC, useEffect, useState } from 'react';

import { UploadAudioResponse } from '@partoohub/api-client';
import { CustomFile, DropzoneTypeEnum, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { EllipsisLink } from 'admin/common/components/buttons/EllipsisLink';
import { DropZoneDescription } from 'admin/common/components/inputs/DropZone/DropZoneDescription';
import { DropZoneWithoutUpload } from 'admin/common/components/inputs/DropZone/DropzoneWithoutUpload';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

import { UrlWrapper } from 'admin/content/connectAs/pages/ConnectAs/ConnectAs.styled';

import { AUDIO_TYPES } from './AllowedAudioTypes';
import { CopyChip } from './CopyChip';
import { useUploadAudio } from './hooks/useUploadAudio';

const CallDeflectionAudioUpload: FC = () => {
    const { t } = useTranslation();
    const [audioFiles, setAudioFiles] = useState<CustomFile[]>([]);
    const [audioFileUrl, setAudioFileUrl] = useState<string>('');

    const onSuccess = (data: UploadAudioResponse) => {
        toast.success(
            t('admin:page__call_deflection_audio_upload__success_toast__content'),
            t('admin:page__call_deflection_audio_upload__success_toast__title'),
        );
        setAudioFileUrl(data?.transcoded_infos[0]?.url ?? '');
    };
    const onError = () => {
        toast.error(
            t('admin:page__call_deflection_audio_upload__error_toast__content'),
            t('admin:page__call_deflection_audio_upload__error_toast__title'),
        );
    };
    const uploadAudio = useUploadAudio(onSuccess, onError);

    const handleAudioUpload = () => {
        if (audioFiles?.length && audioFiles[0]) {
            uploadAudio.mutate(audioFiles[0]);
        }
        setAudioFiles([]);
    };

    useEffect(() => {
        if (audioFiles.length) {
            setAudioFileUrl('');
        }
    }, [audioFiles]);

    return (
        <AdminPage
            dataTrackId="admin__call_deflection"
            title={t('admin:page__call_deflection_audio_upload__title')}
            description={t('admin:page__call_deflection_audio_upload__description')}
        >
            <BorderedContainer>
                <PageFormRow marginBottom={1}>
                    <DropZoneWithoutUpload
                        title={t('drop_zone_label')}
                        acceptedDescription={
                            <DropZoneDescription acceptedExtensions={AUDIO_TYPES} />
                        }
                        type={DropzoneTypeEnum.Document}
                        files={audioFiles}
                        setFiles={setAudioFiles}
                        multiple={false}
                        disabled={audioFiles.length === 1}
                        full
                        accept={AUDIO_TYPES}
                    />
                </PageFormRow>
                <PageFormRow>
                    <SubmitButton
                        dataTrackId="admin__call_deflection__audio_upload"
                        onClick={handleAudioUpload}
                        disabled={!audioFiles.length}
                        text={t('admin:page__call_deflection__submit_button')}
                    />
                </PageFormRow>
                <PageFormRow>
                    <UrlWrapper>
                        <EllipsisLink
                            value={audioFileUrl}
                            icon={<CopyChip audioFileUrl={audioFileUrl} />}
                        />
                    </UrlWrapper>
                </PageFormRow>
            </BorderedContainer>
        </AdminPage>
    );
};

export default CallDeflectionAudioUpload;
