import { useMemo } from 'react';

import { GROUP_MANAGER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export default function useEnabledGroups(
    value: Array<number>,
    isMatchingAccesses: boolean | undefined,
): Array<string> | undefined {
    const { data: me } = useMe();

    return useMemo(() => {
        if (me?.role !== GROUP_MANAGER || isMatchingAccesses) {
            return undefined;
        }

        const accesses = me?.accesses ?? [];

        if (
            !accesses.some(access =>
                access.some(groupId => value.includes(groupId)),
            )
        ) {
            return accesses.flat().map(groupId => groupId.toString());
        }

        const enableGroups: Array<number> = [];
        accesses?.forEach(access => {
            if (access.some(groupId => value.includes(groupId))) {
                enableGroups?.push(...access);
            }
        });

        return enableGroups.map(groupId => groupId.toString());
    }, [me, value, isMatchingAccesses]);
}
