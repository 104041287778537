import { useEffect, useState } from 'react';

import { BusinessCount, BusinessModalFilters, ResetModeEnum } from '@partoohub/modular-components';
import { Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FoodMenuSectionType } from 'admin/api/types/bulkFoodMenu';
import { BusinessModalComponent } from 'admin/common/components/businessModal/BusinessModal';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import {
    PageContent,
    PageDescription,
    PageFormRow,
} from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import {
    ActionSelect,
    AddDetailsAction,
    AddPhotoAction,
    BulkMenuActionEnum,
    DeleteAction,
    UpdateFoodMenuModal,
} from 'admin/content/data/pages/FoodMenusCreation/components';

import { convertFilters } from './components/UpdateFoodMenuModal';
import { BusinessSelectionContainer } from './FoodMenusCreation.styled';

const FOOD_MENUS_CREATION_SEARCH_PARAMS = {
    can_have_food_menus: true,
    connected_to_gmb_location: true,
};

const FoodMenusCreation = () => {
    const { t } = useTranslation();
    const [bulkMenuAction, setBulkMenuAction] = useState<string | null>(null);
    const [openedSaveModal, setOpenedSaveModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [businessCountSelected, setBusinessCountSelected] = useState<BusinessCount>({
        total: undefined,
        selected: undefined,
    });
    const [photosMenu, setPhotosMenu] = useState<Array<string>>([]);
    const [sections, setSections] = useState<Array<FoodMenuSectionType>>([]);
    const [org, setOrg] = useState<Option | undefined>(undefined);
    const initialFilters: BusinessModalFilters = {
        ...FOOD_MENUS_CREATION_SEARCH_PARAMS,
        org_id: undefined,
    };
    const [filters, setFilters] = useState<BusinessModalFilters>(initialFilters);

    const handleOrg = (org?: Option) => {
        setOrg(org);
        const newOrgId = org?.value ? Number.parseInt(org.value) : undefined;
        setFilters({
            ...initialFilters,
            org_id: newOrgId,
        });
    };

    useEffect(() => {
        setBusinessCountSelected({
            total: undefined,
            selected: undefined,
        });
    }, [org]);

    const buttonInfoByAction: {
        [action: string]: {
            translationKey: string;
            active: boolean;
        };
    } = {
        [BulkMenuActionEnum.PHOTO]: {
            translationKey: 'admin:bulk_menu__add_photo',
            active: photosMenu?.length > 0,
        },
        [BulkMenuActionEnum.DETAILS]: {
            translationKey: 'admin:bulk_menu__add_details',
            active: sections?.length > 0,
        },
        [BulkMenuActionEnum.BOTH]: {
            translationKey: 'admin:bulk_menu__add_both',
            active: photosMenu?.length > 0 || sections?.length > 0,
        },
    };

    const resetForm = () => {
        setBulkMenuAction(null);
        setPhotosMenu([]);
        setSections([]);
        setFilters({ ...initialFilters });
        setOrg(undefined);
    };

    const transformedFilters = convertFilters(filters);

    return (
        <AdminPage
            dataTrackId="page_bulk_food_menus"
            title={t('admin:bulk_food_menus__page_title')}
            description={t('admin:bulk_food_menus__page_description')}
            infoLink={'https://partoo.elium.com/tile/view/281'}
        >
            <BorderedContainer>
                <PageFormRow>
                    <PageContent>
                        <PageDescription>
                            {t('admin:bulk_food_menus__business_select_description')}
                        </PageDescription>
                        <BusinessSelectionContainer>
                            <NewOrganizationSelect
                                dataTrackId="admin_duplicate_businesses__filter_organization"
                                orgs={org}
                                setOrgs={handleOrg}
                                placeholder={t('admin:filter_select_organization')}
                                required
                            />
                            <BusinessModalComponent
                                key={filters.org_id} // Don't remove key. It's needed to reset BusinessModal when updating the org, especially for the selected business count.
                                buttonVariant="select"
                                variant="all"
                                onCountChange={setBusinessCountSelected}
                                disabled={!org}
                                label={t('admin:select_businesses')}
                                filters={filters}
                                filterVisibility={{
                                    searchBusiness: true,
                                    searchCity: true,
                                    searchOrg: false,
                                }}
                                resetMode={ResetModeEnum.Lifecycle}
                                setFilters={setFilters}
                            />
                        </BusinessSelectionContainer>
                    </PageContent>
                </PageFormRow>
                <PageFormRow>
                    <PageContent>
                        <PageDescription>
                            {t('admin:bulk_food_menus__action_description')}
                        </PageDescription>
                        <ActionSelect
                            selectedAction={bulkMenuAction}
                            onChange={setBulkMenuAction}
                        />
                    </PageContent>
                </PageFormRow>
                {bulkMenuAction && (
                    <PageFormRow>
                        {(bulkMenuAction === BulkMenuActionEnum.DETAILS ||
                            bulkMenuAction === BulkMenuActionEnum.BOTH) && (
                            <AddDetailsAction setSections={setSections} sections={sections} />
                        )}
                        {(bulkMenuAction === BulkMenuActionEnum.PHOTO ||
                            bulkMenuAction === BulkMenuActionEnum.BOTH) && (
                            <AddPhotoAction
                                photosMenu={photosMenu}
                                setPhotosMenu={setPhotosMenu}
                                photosPerRow={bulkMenuAction === BulkMenuActionEnum.PHOTO ? 4 : 2}
                            />
                        )}
                    </PageFormRow>
                )}
            </BorderedContainer>
            <PageFormRow>
                {!!bulkMenuAction && bulkMenuAction !== BulkMenuActionEnum.DELETE && (
                    <SubmitButton
                        dataTrackId={`admin_bulk_menu_creation__add_${bulkMenuAction}`}
                        disabled={!org || !buttonInfoByAction[bulkMenuAction]?.active || loading}
                        loading={loading}
                        text={t(buttonInfoByAction[bulkMenuAction]?.translationKey)}
                        onClick={() => setOpenedSaveModal(true)}
                    />
                )}
            </PageFormRow>

            {bulkMenuAction && bulkMenuAction === BulkMenuActionEnum.DELETE && (
                <DeleteAction
                    filters={transformedFilters}
                    count={businessCountSelected}
                    onSuccess={resetForm}
                />
            )}
            <UpdateFoodMenuModal
                filters={filters}
                businessCountSelected={businessCountSelected}
                bulkMenuAction={bulkMenuAction}
                sections={sections}
                onSuccess={resetForm}
                photosMenu={photosMenu}
                openedSaveModal={openedSaveModal}
                setOpenedSaveModal={setOpenedSaveModal}
                setLoading={setLoading}
            />
        </AdminPage>
    );
};

export default FoodMenusCreation;
