import React, { useEffect, useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Button, Modal, NumberInput, Stack, Text, TextArea, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { servicesTypeEnum } from 'app/api/types/services';

import {
    ButtonsWrapper,
    EditModalTitle,
    ModalWrapper,
    StructuredServiceName,
} from './modal.styled';
import { ServiceModalFormType } from './utils';

export type EditServiceModalDataType = {
    serviceType: string;
    serviceName: string;
    serviceDescription: string;
    servicePrice: string;
    serviceId?: number;
};

type Props = {
    show: boolean;
    setServices: React.Dispatch<React.SetStateAction<Array<ServiceModalFormType>>>;
    services: Array<ServiceModalFormType>;
    handleClose: () => void;
    data: EditServiceModalDataType;
    currency: string;
};

export const EditServiceModal = ({
    show,
    handleClose,
    setServices,
    services,
    data,
    currency,
}: Props) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<ServiceModalFormType>({
        name: '',
        description: '',
        price: '',
        type: '',
        serviceId: undefined,
    });
    const [serviceWithSameNameAlreadyExist, setServiceWithSameNameAlreadyExist] =
        useState<boolean>(false);

    const editService = () => {
        const nameAlreadyExists = services.some(
            service => service.name === formData.name && data.serviceName !== formData.name,
        );
        if (nameAlreadyExists) {
            setServiceWithSameNameAlreadyExist(true);
            return;
        }
        const queryParams = {
            name: formData.name!,
            description: formData.description,
            price: formData.price.replace(',', '.'),
            type: data.serviceType,
            serviceId: data.serviceId,
        };
        setServices(services.map(obj => (obj.name === data.serviceName ? queryParams : obj)));
        close();
    };

    const close = () => {
        setServiceWithSameNameAlreadyExist(false);
        handleClose();
    };

    const fillForm = () => {
        setFormData({
            type: data.serviceType,
            name: data.serviceName,
            description: data.serviceDescription || '',
            price: String(data.servicePrice || ''),
            serviceId: data.serviceId,
        });
    };

    const isFormInvalid = (): boolean =>
        (data.serviceType === servicesTypeEnum.FREE_FORM_SERVICE_ITEM && !formData.name) ||
        formData.description.length > 300 ||
        (Number.isNaN(parseFloat(formData.price)) && formData.price !== '') ||
        formData.name?.replace(/\s/g, '').length === 0;

    function removeDuplicateCharacters(string: string) {
        return string
            .split('')
            .filter(
                (item, pos, self) => self.indexOf(item) === pos || ![',', 'e', '+'].includes(item),
            )
            .join('');
    }

    const setOnChange = (value?: string) => {
        setFormData({
            ...formData,
            price: value ? removeDuplicateCharacters(value.replace(/[^\d,e+]/g, '')) : '',
        });
    };

    useEffect(() => {
        fillForm();
    }, [data]);

    return (
        <Modal isOpen={show} closeModal={close}>
            <ModalWrapper>
                <EditModalTitle>{t('edit_service_modal_title')}</EditModalTitle>
                <div>
                    {data.serviceType === servicesTypeEnum.STRUCTURED_SERVICE_ITEM && (
                        <StructuredServiceName>
                            <Text variant="bodySBold" color={HEX_COLORS.secondary}>
                                {t('service_name')}
                            </Text>
                            <Text variant="heading6">{data.serviceName}</Text>
                        </StructuredServiceName>
                    )}
                    <Stack>
                        {data.serviceType === servicesTypeEnum.FREE_FORM_SERVICE_ITEM && (
                            <TextInput
                                dataTrackId="bulk_service_create__edit_modal__service_name"
                                placeholder={t('service_name')}
                                required
                                value={formData.name}
                                onChange={value => {
                                    setFormData({
                                        ...formData,
                                        name: value || '',
                                    });
                                }}
                                error={serviceWithSameNameAlreadyExist}
                                notice={
                                    serviceWithSameNameAlreadyExist
                                        ? t(
                                              'error_updating_service_name_already_exists_toast_description',
                                          )
                                        : undefined
                                }
                            />
                        )}
                        <NumberInput
                            dataTrackId="bulk_service_create__edit_modal__service_price"
                            unit={`${currency}`}
                            label={`${t('service_price')} (${currency})`}
                            min={0}
                            value={formData.price.replace('.', ',')}
                            onChange={value => setOnChange(value)}
                        />
                        <TextArea
                            dataTrackId="bulk_service_create__edit_modal__service_description"
                            label={t('service_description')}
                            placeholder={t('service_description')}
                            rows={5}
                            maxRows={10}
                            characterCount={300}
                            value={formData.description}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    description: value,
                                });
                            }}
                        />
                    </Stack>
                </div>

                <ButtonsWrapper direction="row" gap="0.5rem">
                    <Button
                        dataTrackId="bulk_service_create__edit_modal__cancel"
                        appearance="outlined"
                        variant="secondary"
                        onClick={close}
                        size="large"
                        full
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        dataTrackId="bulk_service_create__edit_modal__validate"
                        variant="primary"
                        shape="cube"
                        size="large"
                        disabled={isFormInvalid()}
                        onClick={editService}
                        full
                    >
                        {t('validate')}
                    </Button>
                </ButtonsWrapper>
            </ModalWrapper>
        </Modal>
    );
};
