import { useEffect } from 'react';

import { Button, ButtonAppearance, ButtonVariants, IconElement, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

type NetworkError = { message: 'Network Error' };

function isNetworkError(obj: unknown): obj is NetworkError {
    return obj instanceof Object && 'message' in obj && obj.message === 'Network Error';
}

export const DownloadButton = ({
    id,
    text,
    appearance,
    variant,
    icon,
    apiCall,
    dataTrackId,
}: {
    id: string;
    text: string;
    appearance?: ButtonAppearance;
    variant: ButtonVariants;
    icon: IconElement;
    apiCall: (id: string) => Promise<any>;
    dataTrackId: string;
}) => {
    const { t } = useTranslation();

    const mutation = useMutation(apiCall);

    useEffect(() => {
        if (mutation.isSuccess) {
            window.location = mutation.data;
            return;
        }
        if (mutation.isError) {
            if (isNetworkError(mutation.error)) {
                toast.error(
                    t('admin:page_daily_report__network_error_toast_content'),
                    t('admin:page_daily_report__network_error_toast_title'),
                );
            } else {
                toast.error(
                    t('admin:page_daily_report__error_toast_content'),
                    t('admin:page_daily_report__error_toast_title'),
                );
            }
        }
    }, [mutation.status]);

    return (
        <Button
            dataTrackId={dataTrackId}
            icon={icon}
            onClick={() => mutation.mutate(id)}
            variant={variant}
            isLoading={mutation.isLoading}
            appearance={appearance}
        >
            {text}
        </Button>
    );
};
