import { businessModalFiltersInitialState } from 'app/common/components/businessModal/context/businessModalFiltersReducer';
import useReduxStateToLocalState from 'app/common/components/businessModal/hooks/privateHooks/useReduxStateToLocalState';

export default function useIsReduxStateEmpty(): boolean {
    const { selection } = useReduxStateToLocalState();
    return (
        JSON.stringify(selection) ===
        JSON.stringify(businessModalFiltersInitialState.selection)
    );
}
