import { FC, useEffect, useState } from 'react';

import { Banner, Option, SingleSelect, TextInput, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { administrativeRegionsApiClient, prospectsApiClient } from 'admin/api/apiResources';
import { ProspectsFormData } from 'admin/api/types';
import EmailInput from 'admin/common/components/inputs/EmailInput';
import { PlaceTypeSelect } from 'admin/common/components/inputs/PlaceTypeSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import {
    COUNTRIES,
    DUPLICATE_PROSPECT_SEARCH,
    REGIONS,
} from 'admin/common/data/queryKeysConstants';
import { useMe } from 'admin/common/hooks/queries/useMe';
import {
    countryMapper,
    retrieveDefaultCountry,
} from 'admin/content/tools/pages/ProspectListing/data/google_prospects_data';

export const ProspectBusinessSearch: FC = () => {
    const { t } = useTranslation();

    const { data: user } = useMe();

    const { data: countryList } = useQuery(COUNTRIES, () =>
        administrativeRegionsApiClient.getCountries(),
    );
    const countries = countryMapper(countryList);
    const countrySection = [{ options: countries }];
    const defaultCountry = retrieveDefaultCountry(countries);
    const [selectedCountry, setSelectedCountry] = useState<Option | undefined>(defaultCountry);

    useEffect(() => {
        if (defaultCountry && !selectedCountry) {
            setSelectedCountry(defaultCountry);
        }
    }, [countryList]);

    const { data: dataRegions } = useQuery([REGIONS, selectedCountry], () =>
        administrativeRegionsApiClient.getRegions(selectedCountry?.value || ''),
    );
    const regions = countryMapper(dataRegions);
    const regionSection = [{ options: regions }];

    const [brand, setBrand] = useState<string | undefined>('');
    const [selectedRegion, setSelectedRegion] = useState<Option | undefined>(undefined);
    const [placeType, setPlaceType] = useState<Option | undefined>(undefined);
    const [email, setEmail] = useState<string>(user?.email || '');

    const { mutate, isLoading } = useMutation(
        [DUPLICATE_PROSPECT_SEARCH],
        (data: ProspectsFormData) => prospectsApiClient.search(data),
        {
            onSuccess: () => {
                setBrand('');
                setSelectedRegion(undefined);
                setPlaceType(undefined);
                toast.success(
                    t('admin:page_prospect_search__toast_success__description'),
                    t('admin:page_prospect_search__toast_success__title'),
                );
            },
            onError: (e: any) => {
                const errorMessage = e.response?.data?.errors?.json?.email || e.message;
                toast.error(errorMessage, t('admin:toast_error__title'));
            },
        },
    );

    const canSubmit = !!email && !!countries && !!brand;
    const submitForm = async () => {
        if (isLoading || !canSubmit) {
            return;
        }
        mutate({
            email,
            query: brand || '',
            code: selectedRegion?.value || selectedCountry?.value || defaultCountry.value,
            type: placeType?.value,
        });
    };

    return (
        <AdminPage
            dataTrackId="prospect_business__page"
            title={t('admin:page_tools_prospects_listings__title')}
            description={t('admin:page_tools_prospects_listings__description')}
            infoLink={
                'https://partoo.elium.com/tile/view/276/#b01cbae4-a32b-4fce-8e4d-a1837058b738'
            }
        >
            <div style={{ marginBottom: '16px' }}>
                <Banner
                    dataTrackId="prospects_listing_banner"
                    variant="warning"
                    withCloseIcon={false}
                >
                    {t('prospects_listing_cost_warning')}
                </Banner>
            </div>
            <BorderedContainer>
                <PageFormRow>
                    <TextInput
                        dataTrackId="admin_prospect_listing__search_brand"
                        value={brand}
                        onChange={setBrand}
                        label={t('brand_name')}
                        required
                    />
                </PageFormRow>
                <PageFormRow>
                    <SingleSelect
                        dataTrackId="admin_prospect_listing__search_country"
                        label={t('country')}
                        sections={countrySection}
                        selectedValue={selectedCountry}
                        onChange={value => {
                            setSelectedCountry(value);
                            setSelectedRegion(undefined);
                        }}
                        maxHeight={300}
                        disableReset
                    />
                    <SingleSelect
                        dataTrackId="admin_prospect_listing__search_region"
                        label={t('region')}
                        sections={regionSection}
                        selectedValue={selectedRegion}
                        onChange={setSelectedRegion}
                        maxHeight={300}
                    />
                </PageFormRow>
                <PageFormRow>
                    <PlaceTypeSelect selectedValue={placeType} onChange={setPlaceType} />
                </PageFormRow>
                <PageFormRow>
                    <EmailInput
                        placeholder={t('email')}
                        email={email}
                        onChange={setEmail}
                        isMandatory
                    />
                </PageFormRow>
            </BorderedContainer>

            <SubmitButton
                dataTrackId="admin_prospect_listing__search_listing"
                text={t('search_for_listings')}
                onClick={submitForm}
                disabled={!canSubmit}
            />
        </AdminPage>
    );
};
