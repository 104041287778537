import axios, { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash-es';

const BASE_URL = `https://${window.location.host}/api/v2`;

export class AppApiResourceBaseClass {
    static post(
        route: string,
        data: Array<Record<string, any>> | Record<string, any> = {},
        headers: Record<string, any> = {},
    ): Promise<AxiosResponse<any>> {
        if (Object.keys(headers).length === 0) {
            return axios.post(`${BASE_URL}/${route}`, data);
        }

        return axios.post(`${BASE_URL}/${route}`, data, {
            headers,
        });
    }

    static put(route: string, data: Record<string, any> = {}): Promise<AxiosResponse<any>> {
        return axios.put(`${BASE_URL}/${route}`, data);
    }

    static get(
        route: string,
        params: Record<string, any> = {},
        headers: Record<string, any> = {},
    ): Promise<AxiosResponse<any>> {
        const config: Record<string, any> = {
            params,
        };

        if (!isEmpty(headers)) {
            config.headers = headers;
        }

        return axios.get(`${BASE_URL}/${route}`, config);
    }

    static delete(route: string, data: Record<string, any> = {}): Promise<AxiosResponse<any>> {
        return axios.delete(`${BASE_URL}/${route}`, data);
    }

    static patch(route: string, data: Record<string, any> = {}): Promise<AxiosResponse<any>> {
        return axios.patch(`${BASE_URL}/${route}`, data);
    }
}
