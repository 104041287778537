import { KeyboardEventHandler, useEffect, useState } from 'react';

import { Button, Modal, Stack, Text, TextInput, TextInputType } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ContentContainer } from './ModalCreateKeyword.styled';

interface ConfirmModalWithTextProps {
    /* Set how the confirm text should be validated:
     ** 'disable': Disable the confirm button until the input matches the expected text
     ** 'error': Always enable the confirm button but display an error if the input doesn't match the expected text
     */
    validationStyle?: 'disable' | 'error';

    title: string;
    subTitle: string;
    inputTextLabel: string;
    opened: boolean;
    onConfirm: (text: string) => void;
    onClose: () => void;
    resetNotice?: () => void;
    inputTextType?: TextInputType;
    errorNotice?: string;
}

/**
 * Confirm modal with an input field
 * The user is required to input the specified confirmText to be able to confirm
 */
export const CustomConfirmModalWithText = ({
    validationStyle = 'disable',
    opened,
    title,
    subTitle,
    inputTextLabel,
    inputTextType,
    errorNotice,
    resetNotice,
    onConfirm,
    onClose,
}: ConfirmModalWithTextProps) => {
    const { t } = useTranslation();

    const [text, setText] = useState('');

    const closeModal = () => {
        setText('');
        onClose();
    };

    const confirmAndCloseModal = () => {
        const trimmedText = text.trim();
        onConfirm(trimmedText);
    };

    const onEnterKeyDown: KeyboardEventHandler = e => {
        if (e.key === 'Enter' && validationStyle === 'disable') {
            confirmAndCloseModal();
        }
    };

    useEffect(() => {
        if (opened) {
            setText('');
        }
    }, [opened]);

    useEffect(() => {
        if (resetNotice && text.length > 0 && validationStyle === 'error') {
            resetNotice();
        }
    }, [text]);

    return (
        <Modal isOpen={opened} closeModal={closeModal}>
            <ContentContainer>
                <Stack direction="column" gap="24px" alignItems="left">
                    <Stack direction="column" gap="16px">
                        <Text variant="heading3" as={'span'}>
                            🔑 {title}
                        </Text>
                        <Text variant="bodyMRegular" as={'span'} color="secondary">
                            {subTitle}
                        </Text>
                    </Stack>
                    <TextInput
                        dataTrackId=""
                        autoFocus
                        type={inputTextType}
                        value={text}
                        onChange={setText}
                        label={inputTextLabel}
                        onKeyDown={onEnterKeyDown}
                        error={validationStyle === 'error'}
                        notice={validationStyle === 'error' ? errorNotice : undefined}
                        required
                    />
                    <Stack direction="row" justifyContent="end">
                        <Button
                            dataTrackId=""
                            variant="secondary"
                            shape="cube"
                            size="large"
                            appearance="outlined"
                            onClick={closeModal}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            dataTrackId=""
                            disabled={!text}
                            variant="primary"
                            shape="cube"
                            size="large"
                            onClick={confirmAndCloseModal}
                        >
                            {t('confirm')}
                        </Button>
                    </Stack>
                </Stack>
            </ContentContainer>
        </Modal>
    );
};
