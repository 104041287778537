import { useEffect, useState } from 'react';

import { Option } from '@partoohub/ui';
import { useQuery } from 'react-query';

import { useSearchParams } from 'react-router';

import { userApiClient } from 'admin/api/apiResources';
import { convertUserToOption } from 'admin/common/components/inputs/UserSelect/convertUserToOption';
import { USERS } from 'admin/common/data/queryKeysConstants';

export const useUser: () => [Option | undefined, (selectedUser?: Option) => void] = () => {
    const [user, setUser] = useState<Option | undefined>(undefined);
    const [params, setParams] = useSearchParams();
    const userId = params.get('user_id');

    const { data } = useQuery(
        [USERS, userId],
        () => {
            return userApiClient.getUser(userId!);
        },
        { enabled: userId !== null },
    );

    useEffect(() => {
        if (data && (!user || user.value !== userId)) {
            setUser(convertUserToOption(data));
        }
    }, [data]);

    const setUserAndUpdateURL = (selectedUser: Option | undefined) => {
        setUser(selectedUser ?? undefined);

        const newParams = new URLSearchParams(params);
        if (selectedUser === null || selectedUser === undefined) {
            newParams.delete('user_id');
        } else {
            newParams.set('user_id', selectedUser.value);
        }
        setParams(newParams);
    };

    return [user, setUserAndUpdateURL];
};
