import styled from '@emotion/styled';

export const DiffusionCleanerFiltersWrapper = styled.div`
    display: flex;
    margin-bottom: 16px;
    gap: 8px;
    flex-wrap: wrap;
`;

export const DiffusionCleanerSmallFilter = styled.div`
    flex-basis: 135px;
    flex-grow: 2;
`;
