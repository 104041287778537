import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorVariants } from '@partoohub/branding';
import { Icon, Stack } from '@partoohub/ui';

import BorderedContainer from 'admin/common/components/layout/BorderedContainer';

export const StyledBorderedContainer = styled(BorderedContainer)<{ opened: boolean }>`
    padding: 24px;
    ${({ opened, theme }) =>
        !opened
            ? css`
                  cursor: pointer;
              `
            : css`
                  border: 1px solid ${theme.colors.primary.initial};
                  box-shadow: ${theme.colors.primary.initial} 0 0 0 1px;
              `}
`;

export const StyledEditor = styled(Stack)`
    margin-top: 16px;
`;

const BaseIcon = styled(Icon)`
    height: 32px;
    width: 32px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const StyledCheckedIcon = styled(BaseIcon)<{ color: ColorVariants }>`
    background-color: ${({ theme, color }) => theme.colors[color].alpha};
`;

export const StyledUnCheckedIcon = styled(BaseIcon)`
    border: solid 1px #1425421f;
`;
