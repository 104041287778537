import { useMutation, useQueryClient } from 'react-query';

import { competitiveBenchmarkApiClient } from 'admin/api/apiResources';
import { CompetitorWithId, CreateNewCompetitorPayload } from 'admin/api/types/competitiveBenchmark';
import { COMPETITIVE_BENCHMARK_KEYWORDS } from 'admin/common/data/queryKeysConstants';

import { useOrgIdParsed } from './useOrgIdParsed';

export const useCreateNewCompetitor = () => {
    const queryClient = useQueryClient();
    const orgId = useOrgIdParsed();

    const {
        mutate: createNewCompetitor,
        isSuccess,
        isError,
        reset,
    } = useMutation(
        (payload: CreateNewCompetitorPayload): Promise<CompetitorWithId> => {
            return competitiveBenchmarkApiClient.createCompetitor({ ...payload, orgId });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([COMPETITIVE_BENCHMARK_KEYWORDS, orgId]);
            },
        },
    );

    return {
        mutate: createNewCompetitor as (
            payload: CreateNewCompetitorPayload,
        ) => Promise<CompetitorWithId>,
        isSuccess,
        isError,
        reset,
    };
};
