import { FC } from 'react';

import { HeadManager } from '@partoohub/modular-components';
import { useTranslation } from 'react-i18next';

import env from 'admin/common/utils/getEnvironment';

import Connection from './pages/Connection/Connection';

const Root: FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <HeadManager title={t('admin:page_connection__meta_title')} env={env.getEnv()} />
            <Connection />
        </>
    );
};

export default Root;
