import styled from '@emotion/styled';

export const ScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 8px 24px 16px 24px;
    width: 100%;
    overflow: auto;

    .infinite-scroll-component {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: inherit !important;
    }
`;

export const BusinessCount = styled.div`
    padding-inline: 24px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.default.initial};
    margin-bottom: 8px;
    font-size: 14px;
`;

export const NotFoundMessage = styled.div`
    margin: 32px auto auto;
    font-weight: bold;
    font-size: 22px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;
