import { HeadManager } from '@partoohub/modular-components';
import { ErrorBoundary } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';

import PageErrorPlaceholder from 'admin/common/components/errors/PageErrorPlaceholder';
import env from 'admin/common/utils/getEnvironment';

import Diffusion from './components/Diffusion';
import configureStore from './configureStore';

const store = configureStore() as any;

const Root = () => {
    const { t } = useTranslation();
    return (
        <Provider store={store}>
            <HeadManager title={t('admin:page_logs_diffusion__meta_title')} env={env.getEnv()} />
            <ErrorBoundary
                dataTrackId="page_logs_diffusion_error"
                FallbackComponent={PageErrorPlaceholder}
            >
                <Diffusion />
            </ErrorBoundary>
        </Provider>
    );
};

export default Root;
