import { PropsWithChildren, ReactNode } from 'react';

import {
    MergedAccountsMenuListContent,
    MergedAccountsMenuListContentHeader,
    MergedAccountsMenuListContentTemplateContainer,
} from './MergedAccountsMenuList.styled';

interface MenuListContentTemplateProps {
    headerContent?: ReactNode;
}

export const MergedAccountsMenuList = ({
    headerContent,
    children,
}: PropsWithChildren<MenuListContentTemplateProps>) => {
    return (
        <MergedAccountsMenuListContentTemplateContainer>
            {headerContent && (
                <MergedAccountsMenuListContentHeader>
                    {headerContent}
                </MergedAccountsMenuListContentHeader>
            )}
            <MergedAccountsMenuListContent>{children}</MergedAccountsMenuListContent>
        </MergedAccountsMenuListContentTemplateContainer>
    );
};
