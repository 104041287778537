import { snakeCase } from 'lodash-es';

import { ObjectOrArray } from 'app/common/types';

const formatObjectKeysToSnakeCase = (obj: ObjectOrArray) => {
    if (Array.isArray(obj)) {
        return obj.map(formatObjectKeysToSnakeCase);
    }

    if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce(
            (formattedObj, key) => ({
                ...formattedObj,
                [snakeCase(key)]: formatObjectKeysToSnakeCase(obj[key]),
            }),
            {},
        );
    }

    return obj;
};

export default formatObjectKeysToSnakeCase;
