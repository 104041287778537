import qs from 'query-string';

import {
    DiffusionLogResponseData,
    DiffusionLogsParams,
    FormattedDiffusionLogsParams,
    FormattedGetNewLogCountParams,
    SearchDiffusionLogsReceivedData,
    V2DiffusionLog,
    V2FormattedDiffusionLog,
    V2FormattedPusherStepLog,
    V2PusherStepLog,
} from 'app/api/types/diffusion_log';

export type PaginatedResponse<T> = T & { page: number; max_page: number; count: number };

const formatParams = (paramsObj: Record<string, any>): Record<string, any> =>
    Object.keys(paramsObj).reduce((obj, key) => {
        if (paramsObj[key] === undefined || paramsObj[key] === null) {
            return obj;
        }

        if (Array.isArray(paramsObj[key])) {
            return { ...obj, [key]: paramsObj[key].toString() };
        }

        return { ...obj, [key]: paramsObj[key] };
    }, {});

export default formatParams;

export const dropUndefinedParams = (paramsObj: Record<string, any>): Record<string, any> =>
    Object.fromEntries(Object.entries(paramsObj).filter(([_, v]) => v != null));

export const generateQueryParamsString = (queryParams: Record<string, any>): string =>
    qs.stringify(dropUndefinedParams(queryParams));

export const pusherStepLogFormatter = (step: V2PusherStepLog): V2FormattedPusherStepLog => ({
    callLogs: step.call_logs,
    creationDate: step.creation_date,
    flagAfter: step.flag_after,
    flagBefore: step.flag_before,
    stepName: step.step_name,
    state: step.state,
    diffusionErrors: step.diffusion_errors,
});

export const diffusionLogFormatter = (rawLog: V2DiffusionLog): V2FormattedDiffusionLog => ({
    id: rawLog.id,
    businessId: rawLog.business_id,
    creationDate: rawLog.creation_date,
    startDate: rawLog.start_date,
    completionDate: rawLog.completion_date,
    partnerName: rawLog.partner_name,
    pushType: rawLog.push_type,
    state: rawLog.state,
    orgId: rawLog.org_id,
    provider: rawLog.provider,
    isOutdated: rawLog.is_outdated,
    pusherStepLogs: rawLog.pusher_step_logs
        ? rawLog.pusher_step_logs.map(pusherStepLogFormatter)
        : [],
    diffusionErrors: rawLog.diffusion_errors,
});

export const diffusionLogResponseFormatter = (
    data: DiffusionLogResponseData,
): SearchDiffusionLogsReceivedData => ({
    records: data.diffusion_logs.map(diffusionLogFormatter),
    page: data.page,
    maxPage: data.max_page,
    count: data.count,
});

export const formatDiffusionLogQuery = (
    params: DiffusionLogsParams,
): FormattedDiffusionLogsParams => ({
    page: params.page,
    partner_name: params.partnerName,
    business_id__in: JSON.stringify(params.businessIdIn),
    business_id__notin: JSON.stringify(params.businessIdNotin),
    business_id_query: params.businessIdQuery,
    org_id__in: JSON.stringify(params.orgIdIn),
    org_id__notin: JSON.stringify(params.orgIdNotin),
    org_id_query: params.orgIdQuery,
    creation_date__gt: params.creationDateGt,
    id__in: JSON.stringify(params.idIn),
    id__notin: JSON.stringify(params.idNotin),
    state__in: JSON.stringify(params.stateIn),
    // @ts-ignore
    push_type__in: JSON.stringify(params.pushTypeIn),
});

export const formatGetNewLogCountQuery = (
    params: DiffusionLogsParams,
): FormattedGetNewLogCountParams => ({
    partner_name: params.partnerName,
    business_id__in: JSON.stringify(params.businessIdIn),
    business_id__notin: JSON.stringify(params.businessIdNotin),
    business_id_query: params.businessIdQuery,
    creation_date__gt: params.creationDateGt,
    state__in: JSON.stringify(params.stateIn),
    // @ts-ignore
    push_type__in: JSON.stringify(params.pushTypeIn),
});
