import styled from '@emotion/styled';

export const BusinessAndTooltip = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 80px;
    height: 80px;
    transition: all 0.2s;
    cursor: pointer;
`;
