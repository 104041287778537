import { FeedbackFormData } from '@partoohub/api-client';
import { List } from '@partoohub/ui';

import { SurveyListRow } from './SurveyListRow/SurveyListRow';

type Props = {
    formList: FeedbackFormData[];
    onEdit: () => void;
};

export const SurveySectionContentList = ({ formList, onEdit }: Props) => {
    const renderRow = (form: FeedbackFormData) => (
        <SurveyListRow key={form.id} survey={form} onEdit={onEdit} />
    );

    return (
        <List showHeader={false} columns={[{ width: '55%' }, { width: '30%' }, { width: '15%' }]}>
            {formList.map(renderRow)}
        </List>
    );
};
