import React, { useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Modal, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';

import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';

import { bulkFoodMenuApiClient } from 'admin/api/apiResources';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

type Props = {
    onSuccess: () => void;
};
export const DeleteAction: React.FC<Props> = ({ onSuccess }) => {
    const { t } = useTranslation();
    const selectedBusinessesCount = useSelectedBusinessesCount();

    const businessModalFilters = useBusinessModalFilters();
    const [openedWarningModal, setOpenedWarningModal] = useState<boolean>(false);

    const deleteMenuMutation = useMutation(
        () => {
            return bulkFoodMenuApiClient.deleteFoodMenu(businessModalFilters);
        },
        {
            onError: () => {
                toast.error(
                    t('admin:bulk_menu__delete_error_toast_description'),
                    t('admin:bulk_menu__delete_error_toast_title'),
                );
            },
            onSuccess: () => {
                onSuccess();
                toast.success(
                    t('admin:bulk_menu__delete_success_toast_description'),
                    t('admin:bulk_menu__delete_success_toast_title'),
                );
            },
        },
    );
    const onClickDeleteFoodMenu = () => {
        deleteMenuMutation.mutate();
        setOpenedWarningModal(false);
    };
    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Delete,
    };

    return (
        <>
            <PageFormRow>
                <SubmitButton
                    dataTrackId="admin_bulk_menu_creation__delete_all"
                    disabled={selectedBusinessesCount === 0}
                    text={t('admin:bulk_menu__delete')}
                    onClick={() => setOpenedWarningModal(true)}
                />
            </PageFormRow>
            <Modal isOpen={openedWarningModal} closeModal={() => setOpenedWarningModal(false)}>
                <div style={{ width: '500px' }}>
                    <Dialog
                        dataTrackId=""
                        headerContent={illustrationElement}
                        title={t('admin:bulk_menu__delete_modal_title')}
                        subTitle={t('admin:bulk_menu__business')}
                        actions={[
                            <Button
                                dataTrackId="cancel_menu"
                                variant="secondary"
                                size="large"
                                onClick={() => setOpenedWarningModal(false)}
                                appearance="outlined"
                                full
                            >
                                {t('cancel')}
                            </Button>,
                            <Button
                                dataTrackId="delete_menu"
                                variant="danger"
                                size="large"
                                onClick={onClickDeleteFoodMenu}
                                full
                            >
                                {t('delete')}
                            </Button>,
                        ]}
                    />
                </div>
            </Modal>
        </>
    );
};
