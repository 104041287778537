import React from 'react';

import { useTranslation } from 'react-i18next';

import { RightPart } from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/BusinessModalContent.styled';
import Header from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/Header';

import AccessGroups from './AccessGroups';
import AccessGroupsFooter from './AccessGroupsFooter';

type Props = {
    closeModal: () => void;
    value: Array<number>;
    onChange: (value: Array<number>) => void;
    saveModal: () => void;
    orgId?: number;
};

const AccessGroupContent = ({
    closeModal,
    value,
    onChange,
    saveModal,
    orgId,
}: Props) => {
    const { t } = useTranslation();

    return (
        <RightPart>
            <Header
                title={t('business_drawer_groups')}
                closeModal={closeModal}
            />
            <AccessGroups value={value} onChange={onChange} orgId={orgId} />
            <AccessGroupsFooter
                value={value}
                onChange={onChange}
                saveModal={saveModal}
                orgId={orgId}
            />
        </RightPart>
    );
};

export default AccessGroupContent;
