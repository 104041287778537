import { useEventListener } from 'app/common/hooks/useEventListener';

export const useClickOutside = <T extends Array<HTMLElement | null>>(
    refs: T,
    handler: (event: MouseEvent) => void,
) =>
    useEventListener(window.document, 'click', event => {
        const clickedOnOneRef = refs.some(
            ref => ref !== null && ref.contains(event.target as Node | null),
        );
        if (!clickedOnOneRef) {
            handler?.(event);
        }
    });
