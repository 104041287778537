import { useMemo } from 'react';

import { Loader } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';

import { useOrg } from 'admin/content/tools/pages/DiffusionCleaner/hooks/useOrg';

import { useGetFeedbackForms } from './hooks/useGetFeedbackForm';

import { LookerStudioLinkEditor } from './LookerStudioLinkEditor';
import { ReplySuggestionPromptEditor } from './ReplySuggestionPromptEditor';
import { SurveySection } from './SurveySection';

export const FeedbackManagement = () => {
    const { t } = useTranslation();

    const [org, setOrg] = useOrg();

    const selectedOrgId = useMemo(
        () =>
            org?.value && typeof parseInt(org?.value, 10) === 'number'
                ? parseInt(org.value, 10)
                : 0,
        [org],
    );
    const [forms, { isLoading: isLoadingFeedback }] = useGetFeedbackForms(selectedOrgId, {
        enabled: !!selectedOrgId,
    });

    return (
        <AdminPage
            dataTrackId="page_tools_feedback_management__organization_settings"
            title={t('admin:side_menu_tools__section__feedback_management__organization_settings')}
        >
            <PageFormRow>
                <NewOrganizationSelect
                    dataTrackId="admin_feedback_management__select_org"
                    orgs={org}
                    setOrgs={setOrg}
                    placeholder={t('admin:feedback_management__select_org')}
                />
            </PageFormRow>
            {isLoadingFeedback ? (
                <Loader />
            ) : (
                !!selectedOrgId && (
                    <>
                        <PageFormRow>
                            <ReplySuggestionPromptEditor orgId={selectedOrgId} />
                        </PageFormRow>
                        <PageFormRow>
                            <LookerStudioLinkEditor orgId={selectedOrgId} />
                        </PageFormRow>
                        <SurveySection formList={forms} orgId={selectedOrgId} />
                    </>
                )
            )}
        </AdminPage>
    );
};
