import { FC, PropsWithChildren, createContext, useMemo } from 'react';

import { UserData } from '@partoohub/api-client';

// TODO Tricia: replace with admin loading view if one exists
import LoadingView from 'app/common/components/LoadingView';

import { useMe } from 'admin/common/hooks/queries/useMe';

export type AdminDataContextType = {
    me: UserData;
};

export const AdminDataContext = createContext<AdminDataContextType | undefined>(
    {} as AdminDataContextType,
);

/**
 * Admin data context provider
 * The app child won't be rendered until all the mandatory data is fetched
 */
export const AdminDataProvider: FC<PropsWithChildren> = ({ children }) => {
    const { data: me, isLoading: isLoadingMe } = useMe();

    const isDataAvailable = !isLoadingMe && !!me;

    const value: AdminDataContextType | undefined = useMemo(
        () => (isDataAvailable ? { me } : undefined),
        [me, isDataAvailable],
    );

    if (!value) {
        return <LoadingView />;
    }

    return <AdminDataContext.Provider value={value}>{children}</AdminDataContext.Provider>;
};
