import { mapKeys } from 'lodash-es';

import { deepMapper } from 'app/common/utils/deepMapper';

const fieldRenaming = {
    // infos
    coordinates: 'manualLat',
    zipcode: 'zipCode',
    address2: 'addressComplement',
    // description
    descriptionLong: 'longDescription',
    descriptionShort: 'shortDescription',
    // attributes
    attributesUrl: 'attributesUrls',
    // advanced settings
    nationalIdentificationNumber: 'identificationNumber',
    // photos
    logo: 'LOGO',
    cover: 'TO BE DETERMINED',
};

const sectionRenaming = {
    hours: 'openHours',
};

const publisherRenaming = {
    appleBusinnesConnect: 'apple_business_connect',
    googleMyBusiness: 'google_my_business',
    bingGlobal: 'bing_global',
};

const renaming = { ...fieldRenaming, ...sectionRenaming, ...publisherRenaming };

const businessPublisherErrorsFormatter = (responseData: Record<string, any>): Record<string, any> =>
    deepMapper(responseData, section => mapKeys(section, (v, k) => renaming[k] || k));

export default businessPublisherErrorsFormatter;
