import { RefObject } from 'react';

type Props = {
    active: boolean;
    ref?: RefObject<SVGSVGElement>;
};

export const LogsIcon = ({ active, ref }: Props) => (
    <svg
        ref={ref}
        width="100%"
        height="100%"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
    >
        {active && (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8 10.4H28C28.8816 10.4 29.6 11.1184 29.6 12V31.2C29.6 32.0816 28.8816 32.8 28 32.8H12C11.1184 32.8 10.4 32.0816 10.4 31.2V12C10.4 11.1184 11.1184 10.4 12 10.4H15.2V9.60001C15.2 9.15841 15.5584 8.80001 16 8.80001H17.736C18.0672 7.86881 18.9568 7.20001 20 7.20001C21.0432 7.20001 21.9328 7.86881 22.264 8.80001H24C24.4416 8.80001 24.8 9.15841 24.8 9.60001V10.4ZM18.4 10.4H16.8V12H23.2V10.4H21.6C21.1584 10.4 20.8 10.0416 20.8 9.60001C20.8 9.15841 20.4416 8.80001 20 8.80001C19.5584 8.80001 19.2 9.15841 19.2 9.60001C19.2 10.0416 18.8416 10.4 18.4 10.4ZM19.7664 17.0353C19.4544 16.7233 18.9472 16.7217 18.6336 17.0337L16 19.6689L14.9664 18.6337C14.6544 18.3217 14.1472 18.3217 13.8352 18.6337C13.5232 18.9457 13.5232 19.4529 13.8352 19.7649L15.4352 21.3649C15.5904 21.5217 15.7952 21.6001 16 21.6001C16.2048 21.6001 16.4096 21.5217 16.5664 21.3665L19.7664 18.1665C20.0784 17.8545 20.0784 17.3473 19.7664 17.0353ZM18.6336 23.4337C18.9472 23.1217 19.4544 23.1233 19.7664 23.4353C20.0784 23.7473 20.0784 24.2545 19.7664 24.5665L16.5664 27.7665C16.4096 27.9217 16.2048 28.0001 16 28.0001C15.7952 28.0001 15.5904 27.9217 15.4352 27.7665L13.8352 26.1665C13.5232 25.8545 13.5232 25.3473 13.8352 25.0353C14.1472 24.7233 14.6544 24.7233 14.9664 25.0353L16 26.0689L18.6336 23.4337ZM25.6 18.4H22.4C21.9584 18.4 21.6 18.7584 21.6 19.2C21.6 19.6416 21.9584 20 22.4 20H25.6C26.0416 20 26.4 19.6416 26.4 19.2C26.4 18.7584 26.0416 18.4 25.6 18.4ZM22.4 24.8H25.6C26.0416 24.8 26.4 25.1584 26.4 25.6C26.4 26.0416 26.0416 26.4 25.6 26.4H22.4C21.9584 26.4 21.6 26.0416 21.6 25.6C21.6 25.1584 21.9584 24.8 22.4 24.8Z"
            />
        )}

        {!active && (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8 10.4H28C28.8816 10.4 29.6 11.1184 29.6 12V31.2C29.6 32.0816 28.8816 32.8 28 32.8H12C11.1184 32.8 10.4 32.0816 10.4 31.2V12C10.4 11.1184 11.1184 10.4 12 10.4H15.2V9.60001C15.2 9.15841 15.5584 8.80001 16 8.80001H17.736C18.0672 7.86881 18.9568 7.20001 20 7.20001C21.0432 7.20001 21.9328 7.86881 22.264 8.80001H24C24.4416 8.80001 24.8 9.15841 24.8 9.60001V10.4ZM18.4 10.4H16.8V12H23.2V10.4H21.6C21.1584 10.4 20.8 10.0416 20.8 9.60001C20.8 9.15841 20.4416 8.80001 20 8.80001C19.5584 8.80001 19.2 9.15841 19.2 9.60001C19.2 10.0416 18.8416 10.4 18.4 10.4ZM28 31.2H12V12H15.2V12.8C15.2 13.2416 15.5584 13.6 16 13.6H24C24.4416 13.6 24.8 13.2416 24.8 12.8V12H28V31.2ZM19.7664 17.0353C19.4544 16.7233 18.9472 16.7217 18.6336 17.0337L16 19.6689L14.9664 18.6337C14.6544 18.3217 14.1472 18.3217 13.8352 18.6337C13.5232 18.9457 13.5232 19.4529 13.8352 19.7649L15.4352 21.3649C15.5904 21.5217 15.7952 21.6001 16 21.6001C16.2048 21.6001 16.4096 21.5217 16.5664 21.3665L19.7664 18.1665C20.0784 17.8545 20.0784 17.3473 19.7664 17.0353ZM19.7664 23.4353C19.4544 23.1233 18.9472 23.1217 18.6336 23.4337L16 26.0689L14.9664 25.0353C14.6544 24.7233 14.1472 24.7233 13.8352 25.0353C13.5232 25.3473 13.5232 25.8545 13.8352 26.1665L15.4352 27.7665C15.5904 27.9217 15.7952 28.0001 16 28.0001C16.2048 28.0001 16.4096 27.9217 16.5664 27.7665L19.7664 24.5665C20.0784 24.2545 20.0784 23.7473 19.7664 23.4353ZM21.6 19.2C21.6 18.7584 21.9584 18.4 22.4 18.4H25.6C26.0416 18.4 26.4 18.7584 26.4 19.2C26.4 19.6416 26.0416 20 25.6 20H22.4C21.9584 20 21.6 19.6416 21.6 19.2ZM26.4 25.6C26.4 25.1584 26.0416 24.8 25.6 24.8H22.4C21.9584 24.8 21.6 25.1584 21.6 25.6C21.6 26.0416 21.9584 26.4 22.4 26.4H25.6C26.0416 26.4 26.4 26.0416 26.4 25.6Z"
            />
        )}
    </svg>
);
