import React, { useState } from 'react';

import { DailyReportLog } from 'admin/api/types';
import { DailyReportRowDetails } from 'admin/content/logs/pages/DailyReport/components/DailyReportRowDetails';

import { DailyReportRowWrapper } from './DailyReport.styled';
import { DailyReportRowTop } from './DailyReportRowTop';

export type Props = {
    log: DailyReportLog;
};

const DailyReportRow: React.FC<Props> = ({ log }) => {
    const [closed, setClosed] = useState(true);

    return (
        <DailyReportRowWrapper>
            <DailyReportRowTop log={log} toggleClosed={() => setClosed(!closed)} closed={closed} />

            {!closed && <DailyReportRowDetails logId={log.id} />}
        </DailyReportRowWrapper>
    );
};

export default DailyReportRow;
