import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import useCalculateBusinessCount from 'app/common/components/businessModal/hooks/privateHooks/useCalculateBusinessCount';

/**
 * Temporary count of the selection, to be used in the Business Modal only
 */
export default function useBusinessModalCurrentSelectedCount():
    | number
    | undefined {
    const { selection } = useBusinessModalContext();
    return useCalculateBusinessCount(selection);
}
