import { MouseEvent, useMemo } from 'react';

import { css } from '@emotion/react';
import {
    Chip,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    List,
    Stack,
    Text,
    toast,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FeedbackFormData } from 'app/api/types/feedbackForm';

import { FEEDBACK_MANAGEMENT_BASE_URL } from 'admin/common/data/config';
import { stringifyDate } from 'admin/common/utils/dates';

import { ProgressBar, RowCssContainer } from './SurveyListRow.styled';

type SurveyListRowProps = {
    survey: FeedbackFormData;
    onEdit: (survey: FeedbackFormData) => void;
};

export const SurveyListRow = ({ survey, onEdit }: SurveyListRowProps) => {
    const { t } = useTranslation();
    const { id, display_name, email_reply_source, email_reply_subject, external_id } = survey;

    const surveyLink = useMemo(
        () =>
            `${FEEDBACK_MANAGEMENT_BASE_URL}/${id}?email=email&store_code=store_code&firstname=firstname&lastname=lastname&date=${stringifyDate(new Date())}`,
        [id],
    );

    const fields = [display_name, email_reply_source, email_reply_subject, external_id];

    const nonEmptyFields = fields.filter(field => field && field.trim() !== '').length;
    const ratio = nonEmptyFields / fields.length;
    const fraction = `${nonEmptyFields}/${fields.length}`;

    const handleChipClick = async (event: MouseEvent) => {
        event.stopPropagation();
        try {
            await navigator.clipboard.writeText(surveyLink);
            toast.success(
                t('admin:feedback_management__toast__survey_generated_content'),
                t('admin:feedback_management__toast__survey_generated_title'),
            );
        } catch {
            toast.error(
                t('admin:feedback_management__toast__copy_failed_content'),
                t('admin:feedback_management__toast__copy_failed_title'),
            );
        }
    };

    const copyChip = (
        <Stack
            css={css`
                margin-right: 24px;
            `}
            alignItems="end"
        >
            <Chip
                dataTrackId="feedback_management__copy_survey_link"
                aria-label={t('admin:feedback_management__copy_link_snippet')}
                onClick={handleChipClick}
                chipShape="cube"
            >
                <Stack gap="4px" direction="row" alignItems="center">
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCopy, IconPrefix.SOLID]}
                        iconSize="12px"
                        color="primary"
                    />
                    {t('admin:feedback_management__copy_link_snippet')}
                </Stack>
            </Chip>
        </Stack>
    );

    return (
        <div
            role="button"
            tabIndex={0}
            onClick={() => onEdit(survey)}
            onKeyDown={() => undefined}
            aria-label={t('admin:feedback_management__survey_row_aria', {
                surveyName: display_name,
            })}
        >
            <List.Row
                dataTrackId={`feedback_management__survey_${survey.display_name}`}
                id={survey.display_name}
                rowCssContainer={RowCssContainer}
            >
                <List.Cell>
                    <Stack direction="row" alignItems="center" gap="8px">
                        <Icon
                            icon={['fa-square-poll-horizontal', IconPrefix.REGULAR]}
                            fontSize="24px"
                        />
                        <Text as="span" variant="bodyMSemibold">
                            {survey.display_name}
                        </Text>
                    </Stack>
                </List.Cell>
                <List.Cell>
                    <Stack
                        gap="16px"
                        direction="row"
                        alignItems="center"
                        css={css`
                            flex: 1;
                        `}
                    >
                        {ratio === 1 ? (
                            <Icon icon={['fa-check-circle', IconPrefix.SOLID]} color="success" />
                        ) : (
                            <ProgressBar
                                value={ratio * 100}
                                label={`Progression ${ratio * 100}%`}
                            />
                        )}
                        <Text as="span" variant="bodyMSemibold" color="secondary">
                            {t('feedback_management__field_completed', { ratio: fraction })}
                        </Text>
                    </Stack>
                </List.Cell>
                {survey.external_id && <List.Cell>{copyChip}</List.Cell>}
            </List.Row>
        </div>
    );
};
