import { UserData } from '@partoohub/api-client';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { userApiClient } from 'app/api/v2/api_calls/apiResources';
import { ME } from 'app/common/data/queryKeysConstants';

interface Options {
    enabled?: boolean;
    onSuccess?: (data: UserData) => void;
    onError?: (err: AxiosError) => void;
    retry?: boolean;
    refetchOnReconnect?: boolean;
    refetchOnWindowFocus?: boolean;
}

/**
 * Please use useMe from "front/javascript/app/pages/app/AppDataProvider/hooks/useMe.ts" instead
 */
export default function useMe(options?: Options) {
    return useQuery([ME], () => userApiClient.getMe(), options);
}
