import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router';

import { connectionApiClient, userApiClient } from 'admin/api/apiResources';
import { STATIC_BASE_URL } from 'admin/common/data/config';
import { CONNECT_AS_PATH } from 'admin/common/data/routeIds';
import { useSearchParam } from 'admin/common/hooks/useSearchParam';
import env from 'admin/common/utils/getEnvironment';

import {
    GoogleSigninButton,
    GoogleSigninButtonContainer,
    GoogleSigninButtonFake,
    GoogleSigninError,
    GoogleSigninTextFake,
} from './GoogleSignin.styled';

const CLIENT_ID = '583584673263-n1gcbk83m86i3mg25lafb5pa4cdp6bii.apps.googleusercontent.com';
const GOOGLE_LOGIN_PROXY_URI = 'https://sso-proxy.slates.partoo.co/oauth2/callback';
const GOOGLE_ID_TOKEN = 'google_id_token';

const hasGoogleError = (errors: Record<string, any>): boolean =>
    [
        'Invalid google id_token',
        'Something went wrong with google sign in',
        'Invalid user',
        'Invalid credentials',
    ].includes(errors.global);

export const GoogleSignIn = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [googleIdToken] = useSearchParam(GOOGLE_ID_TOKEN);

    const [errors, setErrors] = useState<Record<string, any>>({});
    const displayGoogleLoginError = hasGoogleError(errors);
    const googleSigninRef = useRef<HTMLDivElement>(null);

    const [searchParams] = useSearchParams();

    const { mutate: onGoogleSubmit } = useMutation(
        (idToken: string) => connectionApiClient.login({ id_token: idToken }),
        {
            onSuccess: () => {
                userApiClient.getMe();
                const nextPage = searchParams.get('next_page');
                navigate(nextPage ?? CONNECT_AS_PATH);
                window.location.reload();
            },
            onError: (error: any) => {
                if (error.response && error.response.status === 400) {
                    const errorMessage =
                        error?.response?.data?.errors?.json ??
                        error?.response?.data?.errors?.authentication ??
                        {};
                    setErrors(
                        typeof errorMessage === 'string' ? { global: errorMessage } : errorMessage,
                    );
                } else {
                    setErrors({ global: 'unknown error' });
                }
            },
        },
    );

    // Handle Google autologin if Google token is in url, only used in slate envs
    useEffect(() => {
        if (googleIdToken) {
            onGoogleSubmit(googleIdToken);
        }
    }, []);

    // Handle Google callback, called everywhere but slate envs
    const handleCallbackResponse = (response: any) => {
        if (response?.credential) {
            onGoogleSubmit(response.credential);
        }
    };

    useEffect(() => {
        // If in slate env, we need to redirect to the proxy to handle the Google login
        const envSpecificGoogleConfig = env.isSlate()
            ? {
                  login_uri: GOOGLE_LOGIN_PROXY_URI,
                  state: JSON.stringify({
                      next_url: window.location.href,
                  }),
                  ux_mode: 'redirect',
              }
            : {
                  callback: handleCallbackResponse,
                  prompt: true,
              };

        if (window.google?.accounts?.id) {
            window.google.accounts.id.initialize({
                client_id: CLIENT_ID,
                ...envSpecificGoogleConfig,
            });
        }
    }, [window.google?.accounts?.id]);

    useEffect(() => {
        if (window.google?.accounts?.id && googleSigninRef.current) {
            window.google.accounts.id.renderButton(googleSigninRef.current, {
                theme: 'outline',
                size: 'large',
                width: '400px', // Max width allowed by Google
            });
        }
    }, [window.google?.accounts?.id, googleSigninRef]);

    if (!window.google?.accounts?.id) {
        return null;
    }

    return (
        <>
            <GoogleSigninButtonContainer alignItems="center" justifyContent="center">
                <GoogleSigninButtonFake>
                    <img
                        src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/google_white_background.svg`}
                        alt=""
                    />
                    <GoogleSigninTextFake as="span" variant="heading6">
                        {t('google_sign_in')}
                    </GoogleSigninTextFake>
                </GoogleSigninButtonFake>
                <GoogleSigninButton ref={googleSigninRef} />
            </GoogleSigninButtonContainer>
            {displayGoogleLoginError && (
                <GoogleSigninError>
                    <img src={`${STATIC_BASE_URL}/images/common/custom-svg/error.svg`} alt="" />
                    <span>{t('google_sign_in_error')}</span>
                </GoogleSigninError>
            )}
        </>
    );
};
