import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { logsApiClient } from 'admin/api/apiResources';
import { DailyReportData } from 'admin/api/types';
import { DAILY_REPORT_DATA } from 'admin/common/data/queryKeysConstants';

import {
    DailyReportDataLi,
    DailyReportDataUl,
    DailyReportDetailIconWrapper,
    DailyReportDetailsWrapper,
} from './DailyReport.styled';

interface Props {
    logId: string;
}

export const DailyReportRowDetails: React.FC<Props> = ({ logId }) => {
    const { t } = useTranslation();
    const [content, setContent] = useState<DailyReportData[]>([]);
    const { data, isFetching } = useQuery(
        [DAILY_REPORT_DATA, { logId }],
        () => logsApiClient.dailyReportData(logId),
        {
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (data) {
            setContent(data);
        }
    }, [data]);

    return (
        <DailyReportDetailsWrapper>
            {!isFetching && !!content.length && (
                <DailyReportDataUl>
                    {content.map(c => (
                        <DailyReportDataLi key={`${logId}-${c.name}`}>
                            <b>{c.count}</b> {t(c.name)}
                        </DailyReportDataLi>
                    ))}
                </DailyReportDataUl>
            )}
            {!isFetching && !content.length && (
                <>
                    <DailyReportDetailIconWrapper>
                        <i className="fa-solid fa-ban" />
                    </DailyReportDetailIconWrapper>
                    {t('admin:page_daily_report__row_details__no_data_found')}
                </>
            )}
            {isFetching && (
                <>
                    <DailyReportDetailIconWrapper>
                        <i className="fa-solid fa-spinner fa-spin" />
                    </DailyReportDetailIconWrapper>
                    {t('admin:page_daily_report__row_details__fetching_data')}
                </>
            )}
        </DailyReportDetailsWrapper>
    );
};
