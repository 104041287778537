import { useEffect, useState } from 'react';

import { UserData } from '@partoohub/api-client';
import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { V2ConnectionToken } from 'app/api/types/connection';
import { FuzzySearchUsersReceivedData } from 'app/api/types/user';
import Connection from 'app/api/v2/api_calls/connectionApiCalls';
import User from 'app/api/v2/api_calls/userApiCalls';

import { Publisher } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { CONNECTION_PATH, VISIBILITY_LOCATION_PATH } from 'admin/common/data/routeIds';
import getUserQuery from 'admin/content/tools/pages/DiffusionCleaner/utils/getUserQuery';

interface useConnectionProps {
    orgId: string | undefined;
    provider: string | undefined;
    publisher?: Publisher;
    businessId?: string;
    businessEditSection?: string;
    nextPageUri?: string;
}

export default function useConnection({
    orgId,
    provider,
    businessId,
    businessEditSection,
    nextPageUri,
}: useConnectionProps) {
    const { t } = useTranslation();
    const hasExtension = window.partoo_extension;
    const [userSearch, setUserSearch] = useState<UserData | null>(null);
    const [connectionToken, setConnectionToken] = useState<string | null>(null);

    const getUser = useMutation(() => User.searchUsers(getUserQuery(orgId, provider)), {
        onSuccess: (data: FuzzySearchUsersReceivedData) => {
            if (data.users[0]) {
                setUserSearch(data.users[0]);
            } else {
                if (orgId === undefined) {
                    const key = 'admin:page_diffusion_cleaner__no_user_found_for_provider__error';
                    toast.error(t(key), 'Error !');
                }
                if (provider === undefined) {
                    const key = 'admin:page_diffusion_cleaner__no_user_found_for_org__error';
                    toast.error(t(key), 'Error !');
                }
            }
        },
        onError: () => {
            const key = 'admin:page_diffusion_cleaner__user_search__error';
            toast.error(t(key), 'Error !');
        },
    });

    const generateConnectionTokenMutation = useMutation(
        (user: UserData) => Connection.generateConnectionToken(user.id, 60),
        {
            onError: () => {
                toast.error(t('admin:page_diffusion_cleaner__generate_token__error'), 'Error !');
            },
            onSuccess: (data: V2ConnectionToken) => setConnectionToken(data.token),
        },
    );

    useEffect(() => {
        if (userSearch !== null) {
            generateConnectionTokenMutation.mutate(userSearch);
        }
    }, [userSearch]);

    useEffect(() => {
        if (connectionToken !== null) {
            const cleanHost = window.location.host.replace('admin.', 'app.');
            const nextPage = encodeURIComponent(
                nextPageUri ||
                    `${VISIBILITY_LOCATION_PATH}/${businessId}?section=${businessEditSection}`,
            );
            const url = decodeURIComponent(
                `https://${cleanHost}${CONNECTION_PATH}?connection_token=${connectionToken}&next_page=${nextPage}`,
            );

            if (hasExtension) {
                window.postMessage({
                    type: 'partooExtensionMessage',
                    action: 'chrome.windows.create',
                    payload: { incognito: true, url },
                });
            } else {
                window.open(url, '_blank');
            }
        }
    }, [connectionToken]);

    const onClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (userSearch === null) {
            getUser.mutate();
        } else {
            generateConnectionTokenMutation.mutate(userSearch);
        }
    };

    return onClick;
}
