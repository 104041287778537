import { SuccessResponse } from 'app/api/types';
import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import { generateQueryParamsString } from 'app/api/v2/api_calls/utils';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

const RESOURCE_URI = 'bulk';

type BulkExportBusinessAdminFilter = {
    provider?: string;
    org_id?: string;
    bulk_format?: string;
    status?: string;
    sections__in?: string;
    is_presence_management_subscriber?: boolean;
    is_review_management_subscriber?: boolean;
    is_review_booster_subscriber?: boolean;
    is_business_message_subscriber?: boolean;
    language?: string;
};

type BulkExportUserAdminFilter = {
    provider?: string;
    org_id?: string;
    sections__in?: string;
};

export type BulkExportBusinessClientFilter = BusinessModalFiltersType & {
    email?: string;
    sections__in?: string;
};

export class BulkExportAdminApiCall extends AdminApiResourceBaseClass {
    static bulkBusinessExport(
        filter: BulkExportBusinessAdminFilter,
        email?: string,
    ): Promise<SuccessResponse> {
        return BulkExportAdminApiCall.get(`${RESOURCE_URI}/business/exporter`, {
            email,
            ...filter,
        }).then(({ data }) => data);
    }

    static bulkUserExport(filter: BulkExportUserAdminFilter): Promise<SuccessResponse> {
        return BulkExportAdminApiCall.get(`${RESOURCE_URI}/user/exporter`, {
            ...filter,
        }).then(({ data }) => data);
    }
}

export class BulkExportClientApiCall extends ApiResourceBaseClass {
    static bulkBusinessExport(
        queryParams: BulkExportBusinessClientFilter,
    ): Promise<SuccessResponse> {
        return BulkExportClientApiCall.get(
            `${RESOURCE_URI}/business/exporter?${generateQueryParamsString(queryParams)}`,
        ).then(({ data }) => data);
    }
}
