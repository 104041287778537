import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';

interface useUploadTripadvisorProps {
    onError: (error: any) => void;
    onSuccess: (error: any) => void;
}

export const useUploadTripadvisor = ({ onSuccess, onError }: useUploadTripadvisorProps) => {
    const { mutate: uploadTripAdvisor, isLoading } = useMutation(
        (formData: FormData) =>
            api.businessModule.publisherConnection.tripAdvisorConnectionCall(formData),
        {
            onSuccess,
            onError,
        },
    );
    return {
        uploadTripAdvisor,
        isLoading,
    };
};
