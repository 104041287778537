import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import DateFormat from 'admin/content/logs/common/components/DateFormat/DateFormat';
import {
    FontAwesomeIconContainer,
    FontAwesomeIconContent,
    Row,
} from 'admin/content/logs/common/components/List.styled';
import Status from 'admin/content/logs/common/components/Status/Status';
import {
    importLogStatuses,
    translateImportLogData,
} from 'admin/content/logs/pages/ImportLog/utils/utils';

import {
    ImportLogRowEmailWrapper,
    ImportLogRowNameWrapper,
    LeftCell,
    MiddleCell,
    RightCell,
} from './ImportLog.styled';

type Props = {
    creatorName: string;
    creatorEmail: string;
    bulkType: string;
    bulkFormat: string;
    creationDate: number;
    status: string;
    closed: boolean;
    toggleClosed: () => void;
};

export const ImportLogRowTop = ({
    creatorName,
    creatorEmail,
    creationDate,
    status,
    bulkType,
    bulkFormat,
    toggleClosed,
    closed,
}: Props) => {
    const { t } = useTranslation();

    const importLogStatus = useMemo(() => importLogStatuses(t), [t]);

    const translatedImportLogData = useMemo(
        () =>
            translateImportLogData(t).find(
                data => data.bulkType == bulkType && data.bulkFormat == bulkFormat,
            ),
        [t, bulkType, bulkFormat],
    );

    const { name: importLogDataName, icon: importLogDataIcon } = translatedImportLogData!;

    return (
        <Row onClick={toggleClosed}>
            <LeftCell>
                <FontAwesomeIconContainer>
                    <FontAwesomeIconContent icon={importLogDataIcon} />
                </FontAwesomeIconContainer>
                {importLogDataName}
            </LeftCell>
            <MiddleCell>
                <ImportLogRowNameWrapper>{creatorName}</ImportLogRowNameWrapper>
                <ImportLogRowEmailWrapper>{creatorEmail}</ImportLogRowEmailWrapper>
            </MiddleCell>
            <MiddleCell>
                <DateFormat date={creationDate} />
            </MiddleCell>
            <MiddleCell>
                <Status status={status.toUpperCase()} label={importLogStatus[status]} />
            </MiddleCell>
            <RightCell>
                <i className={`fa fa-angle-${closed ? 'down' : 'up'}`} />
            </RightCell>
        </Row>
    );
};
