import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Outlet, useLocation } from 'react-router-dom';

import api from 'app/api/v2/api_calls';

import { useIsSideMenuOpen } from 'admin/common/contexts/isSideMenuOpenContext';
import { CATEGORIES_STATS } from 'admin/common/data/queryKeysConstants';

import {
    CATEGORIES_EDIT_PATH,
    CATEGORIES_PATH,
    CATEGORIES_REMAP_PATH,
} from 'admin/common/data/routeIds';

import { SideMenuContent, SideMenuTitle, SideMenuWrapper } from './Menus.styled';
import MenuLink from '../MenuLink';
import Sublink from '../Sublink';

const CategoriesMenu: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { isSideMenuOpen } = useIsSideMenuOpen();

    const { data, refetch } = useQuery(CATEGORIES_STATS, api.categories.getCategoriesStats);

    const urlContainsParam = (paramName: string) => {
        const params = new URLSearchParams(location.search);
        if (params.get(paramName) !== null) {
            return true;
        }
        const fromPage = new URLSearchParams(location.state?.from);
        return fromPage.get(paramName) !== null;
    };

    useEffect(() => {
        refetch();
    }, [location.pathname]);

    return (
        <>
            <SideMenuWrapper open={isSideMenuOpen}>
                <SideMenuTitle>{t('admin:side_menu_categories__title')}</SideMenuTitle>

                <SideMenuContent>
                    <MenuLink
                        to={CATEGORIES_PATH}
                        name={t('admin:side_menu_categories__section_list')}
                        relatedPaths={[
                            CATEGORIES_PATH,
                            `${CATEGORIES_EDIT_PATH}/*`,
                            `${CATEGORIES_REMAP_PATH}/*`,
                        ]}
                    >
                        <Sublink
                            to={CATEGORIES_PATH}
                            name={t('admin:side_menu_categories__section_list__all')}
                            chipContent={
                                data?.all !== undefined ? (
                                    data?.all
                                ) : (
                                    <i className="fa fa-spinner fa-spin" />
                                )
                            }
                            overrideIsActive={() =>
                                !urlContainsParam('missing_mappings') && !urlContainsParam('state')
                            }
                        />
                        <Sublink
                            to={`${CATEGORIES_PATH}?missing_mappings=true&impacted_businesses__gt=0&state=OK`}
                            name={t('admin:side_menu_categories__section_list__missing_mapping')}
                            chipContent={
                                data?.missing_mappings !== undefined ? (
                                    data?.missing_mappings
                                ) : (
                                    <i className="fa fa-spinner fa-spin" />
                                )
                            }
                            overrideIsActive={() => urlContainsParam('missing_mappings')}
                        />
                        <Sublink
                            to={`${CATEGORIES_PATH}?state=DEPRECATED&impacted_businesses__gt=0`}
                            name={t('admin:side_menu_categories__section_list__obsolete')}
                            chipContent={
                                data?.deprecated !== undefined ? (
                                    data?.deprecated
                                ) : (
                                    <i className="fa fa-spinner fa-spin" />
                                )
                            }
                            overrideIsActive={() =>
                                !urlContainsParam('missing_mappings') && urlContainsParam('state')
                            }
                        />
                    </MenuLink>
                </SideMenuContent>
            </SideMenuWrapper>
            <Outlet />
        </>
    );
};

export default CategoriesMenu;
