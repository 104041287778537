import { FontAwesomeIconsPartooUsed, Option } from '@partoohub/ui';

import { UserInfo } from 'admin/api/types/userInfos';

export const userToOption = (userInfo: UserInfo): Option => {
    let label = userInfo.email || '<no email>';
    if (userInfo.user_count > 1) {
        label += ` (${userInfo.user_count} orgs)`;
    }

    return {
        value: userInfo.id.toString(),
        label,
        name: userInfo.email ?? '',
        icon: [FontAwesomeIconsPartooUsed.faUser],
    };
};
