import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { feedbackReplySuggestionApiClient } from 'admin/api/apiResources';
import { FEEDBACK_REPLY_SUGGESTION_PROMPT } from 'admin/common/data/queryKeysConstants';

export const useUpdateFeedbackReplySuggestionPrompt = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { mutate } = useMutation(
        ({ orgId, content }: { orgId: number; content: string }) =>
            feedbackReplySuggestionApiClient.updatePrompt(orgId, {
                content,
            }),
        {
            onSuccess: (_, { orgId }) => {
                queryClient.invalidateQueries([FEEDBACK_REPLY_SUGGESTION_PROMPT, orgId]);
                toast.success(
                    t('admin:feedback_management__update_prompt_success_toast_content'),
                    t('admin:feedback_management__update_prompt_success_toast_title'),
                );
            },
            onError: () => {
                toast.error(
                    t('admin:feedback_management__update_prompt_error_toast_content'),
                    t('admin:feedback_management__update_prompt_error_toast_title'),
                );
            },
        },
    );

    return mutate;
};
