import { useMemo } from 'react';

import { uniqBy } from 'lodash-es';
import { UseInfiniteQueryOptions, UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';

import {
    SearchBusinessParams,
    SearchBusinessesReceivedData,
    V2BusinessData,
} from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { BUSINESSES } from 'app/common/data/queryKeysConstants';

export const useBusinesses = (
    filters: SearchBusinessParams = {},
    options: UseInfiniteQueryOptions<SearchBusinessesReceivedData> = {},
): {
    query: UseInfiniteQueryResult<SearchBusinessesReceivedData>;
    businesses: Array<V2BusinessData>;
    count: number | undefined;
} => {
    const businessSearchFilters = {
        partoo_ui: true,
        order_by: '-created',
        ...filters,
    } as SearchBusinessParams;

    // Remove empty values from businessSearchFilters
    Object.keys(businessSearchFilters).forEach(
        key =>
            [undefined, null, ''].includes(businessSearchFilters[key]) &&
            delete businessSearchFilters[key],
    );

    const query = useInfiniteQuery(
        [BUSINESSES, businessSearchFilters],
        ({ pageParam: page = 1 }) =>
            api.business.searchBusinesses({
                ...businessSearchFilters,
                page,
            }),
        {
            getNextPageParam: lastPage =>
                lastPage.page < lastPage.max_page ? lastPage.page + 1 : undefined,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            ...options,
        },
    );

    const businesses = useMemo(
        () =>
            uniqBy(
                query?.data?.pages?.flatMap(page => page.businesses) ?? [],
                business => business.id,
            ),
        [query],
    );

    return {
        query,
        businesses,
        count: query.data?.pages?.[0]?.count,
    };
};
