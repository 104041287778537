import { ApiResourceBaseClass } from '@partoohub/api-client';

import {
    CompetitorWithId,
    CreateNewCompetitorPayloadWithOrgId,
    CreateNewKeywordPayload,
    DeleteCompetitorPayload,
    DeleteKeywordPayload,
    KeywordAdminData,
    KeywordAdminDataRaw,
    ToggleKeywordPayload,
    UpdateCompetitorPayload,
} from '../types/competitiveBenchmark';

const RESOURCE_URI = 'competitive-benchmark';

export class CompetitiveBenchmark extends ApiResourceBaseClass {
    async getKeywordsFromOrg(orgId: number): Promise<KeywordAdminDataRaw> {
        const { data } = await this.get<KeywordAdminDataRaw>(`${RESOURCE_URI}/keywords/${orgId}`);
        return data;
    }

    async createNewKeyword(payload: CreateNewKeywordPayload): Promise<KeywordAdminData> {
        const { data } = await this.post<KeywordAdminData>(
            `${RESOURCE_URI}/keywords/${payload.orgId}`,
            {
                keyword: payload.newKeyword,
            },
        );
        return data;
    }

    async toggleKeyword(payload: ToggleKeywordPayload): Promise<KeywordAdminData> {
        const { data } = await this.patch<KeywordAdminData>(
            `${RESOURCE_URI}/keywords/${payload.orgId}`,
            {
                id: payload.id,
                active: payload.active,
            },
        );
        return data;
    }

    async deleteKeyword(payload: DeleteKeywordPayload): Promise<null> {
        await this.delete(`${RESOURCE_URI}/keywords/${payload.orgId}`, {
            data: { id: payload.id },
        });
        return null;
    }

    async createCompetitor(
        payload: CreateNewCompetitorPayloadWithOrgId,
    ): Promise<CompetitorWithId> {
        const { orgId, keywordId, name, included, excluded } = payload;
        const { data } = await this.post<CompetitorWithId>(`${RESOURCE_URI}/competitors`, {
            name,
            keyword_id: keywordId,
            org_id: orgId,
            included,
            excluded,
        });
        return data;
    }

    async deleteCompetitor(payload: DeleteCompetitorPayload): Promise<CompetitorWithId> {
        const { competitor } = payload;
        const { data } = await this.delete<CompetitorWithId>(
            `${RESOURCE_URI}/competitors/${competitor.id}`,
        );
        return data;
    }

    async updateCompetitor(payload: UpdateCompetitorPayload): Promise<CompetitorWithId> {
        const { competitor } = payload;
        const { data } = await this.put<CompetitorWithId>(
            `${RESOURCE_URI}/competitors/${competitor.id}`,
            competitor,
        );
        return data;
    }
}
