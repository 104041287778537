import IS_IFRAME from 'app/common/data/iframe';
import {
    USER_ID_CREATE,
    UserListQueryKeys,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

/* Path tokens */
export const LOCATIONS_PATH_TOKEN = 'locations';
export const DIFFUSION_PATH_TOKEN = 'diffusion';
export const BULK_UPDATE_PATH_TOKEN = 'bulk-update';
export const BULK_UPDATE_EXPORT_PATH_TOKEN = 'export';
export const BULK_UPDATE_IMPORT_PATH_TOKEN = 'import';
export const MESSAGING_PATH_TOKEN = 'messaging';
export const CONVERSATION_STARTERS_PATH_TOKEN = 'conversation-starters';
export const REVIEWS_PATH_TOKEN = 'reviews';
export const FEEDBACK_FORMS_PATH_TOKEN = 'surveys';
export const AUTO_REPLY_PATH_TOKEN = 'auto-reply';
export const TAGS_PATH_TOKEN = 'tags';
export const AUTO_TAGS_PATH_TOKEN = 'automatic-tags';
export const RETROACTIVE_TAGS_PATH_TOKEN = 'retroactive-tags';
export const VISIBILITY_PATH_TOKEN = 'visibility';
export const COMPETITIVE_BENCHMARK_PATH_TOKEN = 'competitive-benchmark';
export const SETTINGS_PATH_TOKEN = 'settings';
export const SEND_INVITE_PATH_TOKEN = 'send-invite';
export const ACCOUNT_PATH_TOKEN = 'account';
export const PROFILE_PATH_TOKEN = 'profile';
export const NOTIFICATIONS_PATH_TOKEN = 'notifications';
export const REPLY_TEMPLATES_PATH_TOKEN = 'reply-templates';
export const TEAM_PATH_TOKEN = 'team';
export const PERMISSIONS_PATH_TOKEN = 'permissions';
export const USERS_PATH_TOKEN = 'users';
export const CONFIGURATION_PATH_TOKEN = 'configuration';
export const CONNECTION_PATH_TOKEN = 'connection';
export const CONNECTIONS_PATH_TOKEN = 'connections';
export const CUSTOM_FIELDS_PATH_TOKEN = 'custom-fields';
export const GROUPS_SETTINGS_PATH_TOKEN = 'groups';
export const API_KEYS_PATH_TOKEN = 'api-keys';
export const ANALYTICS_PATH_TOKEN = 'analytics';
export const BUSINESSES_PATH_TOKEN = 'businesses';
export const FIELD_PERMISSIONS_PATH_TOKEN = 'field-permissions';
export const API_KEY_MANAGER_PATH_TOKEN = 'api-key-manager';
export const ADD_PATH_TOKEN = 'add';
export const INVITE_PATH_TOKEN = 'invite';
export const RESET_PASSWORD_PATH_TOKEN = 'reset_password';
export const LOST_PASSWORD_PATH_TOKEN = 'lost_password';
export const SSO_PATH_TOKEN = 'sso';
export const BUSINESS_ID = 'businessId';
export const ID_PARAM_TOKEN = 'id';
export const PATH_PARAM_TOKEN = 'token';
export const INVOICE_PATH_TOKEN = 'invoice';
export const INVOICES_PATH_TOKEN = 'invoices';
export const WHATSAPP_SETTINGS_PATH_TOKEN = 'whatsapp';
export const WHATSAPP_PHONE_NUMBER_ID = 'whatsappPhoneNumberId';
export const V2 = 'v2';
export const MAIN_PATH_TOKEN = 'main';
export const INTEGRATIONS_PATH_TOKEN = 'integrations';
export const MESSAGES_PATH_TOKEN = 'messages';
export const PLATFORMS_PATH_TOKEN = 'platforms';

export const GOOGLE_PATH_TOKEN = 'google';
export const APPLE_PATH_TOKEN = 'apple';
export const GOOGLE_FACEBOOK_PATH_TOKEN = 'google-facebook';
export const CHAT_PREFERENCES_PATH_TOKEN = 'chat-preferences';
export const WITHOUT_COMMENT_PATH_TOKEN = 'without-comment';
export const WITH_COMMENT_PATH_TOKEN = 'with-comment';
export const CHATBOTS_PATH_TOKEN = 'chatbots';
export const SETTINGS_MESSAGES_PATH_TOKEN = 'messages';
export const MESSAGE_REPLY_TEMPLATES_PATH_TOKEN = 'reply-templates';
export const CUSTOMER_EXPERIENCE_PATH_TOKEN = 'customer-experience';
export const CONVERSATION_ROUTER_SETTINGS_PATH = 'conversation-router';
export const OIDC_PATH_TOKEN = 'oidc';
export const SAML_PATH_TOKEN = 'saml';
export const WIDGET_SETUP_PATH_TOKEN = 'widget';
export const WIDGET_SETUP_ID = 'widgetSetupId';
export const WEBHOOKS = 'webhooks';
export const CREATE_PATH_TOKEN = 'create';
export const VERIFICATION_PATH_TOKEN = 'verification';

/* Paths
 */

// HOMEPAGE
export const HOMEPAGE_PATH = '/homepage';

// VISIBILITY
export const VISIBILITY_PATH = '/visibility';
export const VISIBILITY_LOCATION_PATH = `${VISIBILITY_PATH}/locations`; // locations
export const VISIBILITY_LOCATION_ADD_PATH = `${VISIBILITY_LOCATION_PATH}/add`;
export const VISIBILITY_LOCATION_EDIT_PATH = `${VISIBILITY_LOCATION_PATH}/:${BUSINESS_ID}`;
export const VISIBILITY_BULK_UPDATE_PATH = `${VISIBILITY_PATH}/bulk-update`; // bulk-update
export const VISIBILITY_BULK_UPDATE_EXPORT_PATH = `${VISIBILITY_BULK_UPDATE_PATH}/${BULK_UPDATE_EXPORT_PATH_TOKEN}`; // bulk export
export const VISIBILITY_BULK_UPDATE_IMPORT_PATH = `${VISIBILITY_BULK_UPDATE_PATH}/${BULK_UPDATE_IMPORT_PATH_TOKEN}`; // bulk import
export const VISIBILITY_DIFFUSION_PATH = `${VISIBILITY_PATH}/diffusion`; // diffusion

// CONVERSATIONS
export const CONVERSATIONS_PATH = '/conversations';
export const CONVERSATIONS_MESSAGING_PATH = `${CONVERSATIONS_PATH}/messaging`; // messaging

// CUSTOMER EXPERIENCE
export const CUSTOMER_EXPERIENCE_PATH = '/customer-experience';
export const CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH = `${CUSTOMER_EXPERIENCE_PATH}/reviews`; // reviews & feedback management
export const CUSTOMER_EXPERIENCE_SEND_INVITE_PATH = `${CUSTOMER_EXPERIENCE_PATH}/send-invite`; // review booster

// ANALYTICS
export const ANALYTICS_PATH = '/analytics';
export const ANALYTICS_VISIBILITY_PATH = `${ANALYTICS_PATH}/visibility`; // visibility
export const ANALYTICS_REVIEW_PATH = `${ANALYTICS_PATH}/reviews`; // reviews
export const COMPETITIVE_BENCHMARK_PATH = `${ANALYTICS_PATH}/competitive-benchmark`;

// Posts
export const POSTS_PATH = '/posts';
export const POSTS_CREATE_PATH = `${POSTS_PATH}/create`;

// SETTINGS
export const SETTINGS_PATH = '/settings';

// messaging
export const SETTINGS_MESSAGES_PATH = `${SETTINGS_PATH}/messages`;
export const SETTINGS_CHATBOT_PATH = `${SETTINGS_MESSAGES_PATH}/${CHATBOTS_PATH_TOKEN}`;

// OTHERS
export const ROOT_PATH = '/';
export const LOG_OUT_PATH = '/logout';
export const NOT_FOUND_PATH = '/404';
export const CONNECTION_PATH = '/connection';
export const CONNECTION_RESET_PASSWORD_TEMPLATE_PATH = `/connection/reset_password/:${PATH_PARAM_TOKEN}`;
export const CONNECTION_INVITE_PATH = `/connection/invite/:${PATH_PARAM_TOKEN}`;
export const CONNECTION_LOST_PASSWORD_PATH = '/connection/lost_password';
export const CONNECTION_LOGIN_SSO_PATH = '/connection/sso';

// OAUTH2
export const GOOGLE_OAUTH2_START_PATH = '/oauth2/google/start';
export const FACEBOOK_OAUTH2_START_PATH = '/oauth2/facebook/start';

// PRIVACY POLICY
export const PRIVACY_PATH = '/privacy';
export const PRIVACY_MESSAGING_PATH = `${PRIVACY_PATH}/messaging`;
export const MESSAGING_PRIVACY_POLICY_PATH = `${PRIVACY_MESSAGING_PATH}/:id`;

// Messages section
export const SETTINGS_MESSAGES_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}`;
export const SETTINGS_MESSAGES_CHAT_PREFERENCES_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}/${CHAT_PREFERENCES_PATH_TOKEN}`;
export const SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}/${REPLY_TEMPLATES_PATH_TOKEN}`;
export const SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}/${CONVERSATION_STARTERS_PATH_TOKEN}`;
export const SETTINGS_MESSAGES_CONVERSATION_STARTERS_V2_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}/${CONVERSATION_STARTERS_PATH_TOKEN}/${V2}`;
export const SETTINGS_MESSAGES_CHATBOTS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}/${CHATBOTS_PATH_TOKEN}`;
export const SETTINGS_MESSAGES_WHATSAPP_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}/${WHATSAPP_SETTINGS_PATH_TOKEN}`;
export const SETTINGS_MESSAGES_CONVERSATION_ROUTER_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}/${CONVERSATION_ROUTER_SETTINGS_PATH}`;
export const SETTINGS_MESSAGES_WIDGET_SETUP_ADD_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}/${WIDGET_SETUP_PATH_TOKEN}/add`;
export const SETTINGS_MESSAGES_WIDGET_SETUP_WIDGET_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MESSAGES_PATH_TOKEN}/${WIDGET_SETUP_PATH_TOKEN}/`;

// Main section
export const SETTINGS_MAIN_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MAIN_PATH_TOKEN}`;
export const SETTINGS_MAIN_PROFILE_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MAIN_PATH_TOKEN}/${PROFILE_PATH_TOKEN}`;
export const SETTINGS_MAIN_NOTIFICATIONS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MAIN_PATH_TOKEN}/${NOTIFICATIONS_PATH_TOKEN}`;
export const SETTINGS_MAIN_INVOICES_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${MAIN_PATH_TOKEN}/${INVOICES_PATH_TOKEN}`;

// Team section
export const SETTINGS_TEAM_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${TEAM_PATH_TOKEN}`;
export const SETTINGS_TEAM_USERS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${TEAM_PATH_TOKEN}/${USERS_PATH_TOKEN}`;
export const SETTINGS_TEAM_USER_ADD_PATHNAME = `${SETTINGS_TEAM_USERS_PATHNAME}?${UserListQueryKeys.USER_EDIT}=${USER_ID_CREATE}`;
export const SETTINGS_TEAM_PERMISSIONS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${TEAM_PATH_TOKEN}/${PERMISSIONS_PATH_TOKEN}`;
export const SETTINGS_TEAM_GROUPS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${TEAM_PATH_TOKEN}/${GROUPS_SETTINGS_PATH_TOKEN}`;
export const SETTINGS_TEAM_SSO_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${TEAM_PATH_TOKEN}/${SSO_PATH_TOKEN}`;
export const SETTINGS_TEAM_CONNECTION_SSO_OIDC_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${TEAM_PATH_TOKEN}/${SSO_PATH_TOKEN}/${OIDC_PATH_TOKEN}`;
export const SETTINGS_TEAM_CONNECTION_SSO_SAML_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${TEAM_PATH_TOKEN}/${SSO_PATH_TOKEN}/${SAML_PATH_TOKEN}`;

// Reviews section
export const SETTINGS_REVIEWS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}`;
export const SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${REPLY_TEMPLATES_PATH_TOKEN}`;
export const SETTINGS_REVIEWS_AUTO_REPLY_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${AUTO_REPLY_PATH_TOKEN}`;
export const SETTINGS_REVIEWS_AUTO_REPLY_WITHOUT_COMMENT_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${AUTO_REPLY_PATH_TOKEN}/${WITHOUT_COMMENT_PATH_TOKEN}`;
export const SETTINGS_REVIEWS_AUTO_REPLY_WITHOUT_COMMENT_CREATE_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${AUTO_REPLY_PATH_TOKEN}/${WITHOUT_COMMENT_PATH_TOKEN}/create`;
export const SETTINGS_REVIEWS_AUTO_REPLY_WITHOUT_COMMENT_EDIT_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${AUTO_REPLY_PATH_TOKEN}/${WITHOUT_COMMENT_PATH_TOKEN}/:${ID_PARAM_TOKEN}`;
export const SETTINGS_REVIEWS_AUTO_REPLY_WITH_COMMENT_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${AUTO_REPLY_PATH_TOKEN}/${WITH_COMMENT_PATH_TOKEN}`;
export const SETTINGS_REVIEWS_TAGS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${TAGS_PATH_TOKEN}`;
export const SETTINGS_REVIEWS_TAGS_AUTO_TAGS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${TAGS_PATH_TOKEN}/${AUTO_TAGS_PATH_TOKEN}`;
export const SETTINGS_REVIEWS_TAGS_RETROACTIVE_TAGS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${TAGS_PATH_TOKEN}/${RETROACTIVE_TAGS_PATH_TOKEN}`;
export const SETTINGS_REVIEWS_SURVEYS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${REVIEWS_PATH_TOKEN}/${FEEDBACK_FORMS_PATH_TOKEN}`;

// Platforms section
export const SETTINGS_PLATFORMS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${PLATFORMS_PATH_TOKEN}`;
const SETTINGS_PLATFORMS_CONNECTION_PATHNAME = `${SETTINGS_PLATFORMS_PATHNAME}/${CONNECTION_PATH_TOKEN}`;
export const SETTINGS_PLATFORMS_CONNECTION_GOOGLE_FACEBOOK_PATHNAME = `${SETTINGS_PLATFORMS_CONNECTION_PATHNAME}/${GOOGLE_FACEBOOK_PATH_TOKEN}`;
export const SETTINGS_PLATFORMS_CONNECTION_APPLE_PATHNAME = `${SETTINGS_PLATFORMS_CONNECTION_PATHNAME}/${APPLE_PATH_TOKEN}`;
export const SETTINGS_PLATFORMS_VERIFICATION_PATHNAME = `${SETTINGS_PLATFORMS_PATHNAME}/${VERIFICATION_PATH_TOKEN}`;
export const SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME = `${SETTINGS_PLATFORMS_VERIFICATION_PATHNAME}/${GOOGLE_PATH_TOKEN}`;

// Integrations section
export const SETTINGS_INTEGRATIONS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${INTEGRATIONS_PATH_TOKEN}`;
export const SETTINGS_INTEGRATIONS_CUSTOM_FIELDS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${INTEGRATIONS_PATH_TOKEN}/${CUSTOM_FIELDS_PATH_TOKEN}`;
export const SETTINGS_INTEGRATIONS_API_KEYS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${INTEGRATIONS_PATH_TOKEN}/${API_KEYS_PATH_TOKEN}`;
export const SETTINGS_INTEGRATIONS_WEBHOOKS_PATHNAME = `/${SETTINGS_PATH_TOKEN}/${INTEGRATIONS_PATH_TOKEN}/${WEBHOOKS}`;

// DEFAULT PAGE FOR THE APP
export const DEFAULT_PAGE_PATH = !IS_IFRAME ? HOMEPAGE_PATH : VISIBILITY_PATH;
