import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import { formatPlaceholder } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/services/utils';
import {
    CreateMessageReplyTemplateResponse,
    MessageReplyTemplate,
    MessageReplyTemplateData,
    SortingOptions,
    USAGE,
} from 'app/states/messageReplyTemplate/dataTypes';

const MESSAGING_REPLY_TEMPATES_URI = 'messaging/templates';

class MessagingReplyTemplateApiCalls extends ApiResourceBaseClass {
    static getReplyTemplates(
        orderBy: SortingOptions = USAGE,
        onlyUserCreated = false,
    ): Promise<Array<MessageReplyTemplate>> {
        return MessagingReplyTemplateApiCalls.get(`${MESSAGING_REPLY_TEMPATES_URI}`, {
            order_by: orderBy,
            only_user_created: onlyUserCreated,
        }).then(({ data }) => data);
    }

    static createMessageReplyTemplate(
        messageReplyTemplateData: MessageReplyTemplateData,
    ): Promise<CreateMessageReplyTemplateResponse> {
        const jsonBody: Partial<MessageReplyTemplateData> = {
            title: messageReplyTemplateData.title,
            content: JSON.stringify({
                _version: '0.1',
                text: formatPlaceholder(messageReplyTemplateData.content),
            }),
        };
        if (messageReplyTemplateData.icon) {
            jsonBody.icon = messageReplyTemplateData.icon;
        }

        return MessagingReplyTemplateApiCalls.post(MESSAGING_REPLY_TEMPATES_URI, jsonBody).then(
            ({ data }) => {
                return data;
            },
        );
    }

    static updateMessageReplyTemplate(
        templateId: string,
        payload: MessageReplyTemplateData,
    ): Promise<MessageReplyTemplate> {
        const jsonBody: Partial<MessageReplyTemplateData> = {
            title: payload.title,
            content: JSON.stringify({
                _version: '0.1',
                text: formatPlaceholder(payload.content),
            }),
        };
        if (payload.icon) {
            jsonBody.icon = payload.icon;
        }

        return MessagingReplyTemplateApiCalls.put(
            `${MESSAGING_REPLY_TEMPATES_URI}/${templateId}`,
            jsonBody,
        ).then(({ data }) => data);
    }

    static deleteMessageReplyTemplate(templateId: string): Promise<any> {
        return MessagingReplyTemplateApiCalls.delete(
            `${MESSAGING_REPLY_TEMPATES_URI}/${templateId}`,
        ).then(({ data }) => data);
    }
}

export default MessagingReplyTemplateApiCalls;
