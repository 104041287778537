import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import api from 'admin/common/api/calls';
import {
    KeywordAdminData,
    KeywordAdminDataRaw,
    ToggleKeywordPayload,
} from 'admin/common/api/types/competitiveBenchmark';

import { COMPETITIVE_BENCHMARK_KEYWORDS } from 'admin/common/data/queryKeysConstants';

export const useToggleKeyword = (): {
    isLoading: boolean;
    mutate: (keyword: ToggleKeywordPayload) => void;
} => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(
        (payload: ToggleKeywordPayload): Promise<KeywordAdminData> =>
            api.competitiveBenchmark.toggleKeyword(payload),
        {
            onSuccess: keyword => {
                queryClient.setQueryData(
                    [COMPETITIVE_BENCHMARK_KEYWORDS, keyword.partoo_org_id],
                    (oldData?: KeywordAdminDataRaw) => {
                        if (oldData === undefined) return { keywords: [] };

                        const keywords: KeywordAdminData[] = [...oldData.keywords];
                        const index = keywords.findIndex(k => k.id === keyword.id);
                        if (index === -1) return oldData;

                        keywords[index] = keyword;
                        return { keywords };
                    },
                );
            },
            onError: (e: any) => {
                if (
                    e.status === 400 &&
                    e.response?.data?.errors?.json === 'Maximum active keywords allowed reached'
                ) {
                    toast.error(
                        t('admin:competitive_benchmark__max_active_keywords_reached'),
                        t('admin:competitive_benchmark__max_active_keywords_reached_description'),
                    );
                } else {
                    toast.error(
                        t('admin:page_connect_as__error_toast__description'),
                        t('admin:page_connect_as__error_toast__title'),
                    );
                }
            },
        },
    );

    return { mutate, isLoading };
};
