import { useTranslation } from 'react-i18next';

import { ConfirmModalWithText } from 'admin/common/components/modals/ConfirmModalWithText';

interface Props {
    opened: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

export const ConfirmUpdatePromptModal = ({ opened, onConfirm, onClose }: Props) => {
    const { t } = useTranslation();

    const confirmText = 'UPDATE';

    return (
        <ConfirmModalWithText
            opened={opened}
            onClose={onClose}
            onConfirm={onConfirm}
            subTitle={t('admin:feedback_management__confirm_update_prompt_modal_subtitle')}
            title={t('admin:feedback_management__confirm_update_prompt_modal_title')}
            inputTextLabel={t('admin:confirm_modal__type_confirm_text', {
                confirm_text: confirmText,
            })}
            confirmText={confirmText}
        />
    );
};

export const ConfirmDeletePromptModal = ({ opened, onConfirm, onClose }: Props) => {
    const { t } = useTranslation();

    const confirmText = 'DELETE';

    return (
        <ConfirmModalWithText
            opened={opened}
            onClose={onClose}
            onConfirm={onConfirm}
            subTitle={t('admin:feedback_management__confirm_delete_prompt_modal_subtitle')}
            title={t('admin:feedback_management__confirm_delete_prompt_modal_title')}
            inputTextLabel={t('admin:confirm_modal__type_confirm_text', {
                confirm_text: confirmText,
            })}
            confirmText={confirmText}
        />
    );
};
