import { SuccessResponse } from '@partoohub/api-client';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'subscription';

class Subscription extends ApiResourceBaseClass {
    static subscriptionUpsellPost(): Promise<SuccessResponse> {
        return Subscription.post(`${RESOURCE_URI}/upsell`).then(({ data }) => data);
    }

    static subscriptionDownsellPost(): Promise<SuccessResponse> {
        return Subscription.post(`${RESOURCE_URI}/downsell`).then(({ data }) => data);
    }
}

export default Subscription;
