import React from 'react';

import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SETTINGS_PLATFORMS_VERIFICATIONS_GOOGLE_PATHNAME } from 'admin/common/data/routeIds';
import onClickFunction from 'admin/content/tools/pages/DiffusionCleaner/hooks/connectAs';

type Props = {
    businessName: string;
    provider?: string;
    orgId?: string;
};
export const VerificationLink: React.FC<Props> = ({ businessName, provider, orgId }) => {
    const { t } = useTranslation();
    const hasExtension = window.partoo_extension;
    const nextPageUri = `${SETTINGS_PLATFORMS_VERIFICATIONS_GOOGLE_PATHNAME}?query=${businessName}`;

    return (
        <Button
            appearance="outlined"
            shape="rounded"
            size="medium"
            variant="secondary"
            dataTrackId="admin_diffusion_cleaner__verification_link_button"
            onClick={onClickFunction({ orgId, provider, businessId: businessName, nextPageUri })}
            disabled={!hasExtension}
            tooltip={
                hasExtension
                    ? undefined
                    : t(
                          'admin:page_diffusion_cleaner__business_quick_connect_button__no_extension_tooltip',
                      )
            }
        >
            {t('admin:page_diffusion_cleaner__business_button__verification_link')}
        </Button>
    );
};
