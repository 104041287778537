import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import QuickAction from 'admin/addon/QuickAction';
import { Sidebar } from 'admin/common/components/navigation/Sidebar';

import { TemplateStyled } from './Template.styled';

export const Template: FC = () => (
    <>
        <TemplateStyled>
            <Sidebar />
            <Outlet />
        </TemplateStyled>
        <QuickAction />
    </>
);
