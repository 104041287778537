import ApiResourceBaseClass from './baseApiCalls';
import { generateQueryParamsString } from './utils';

const RESOURCE_URI = 'presence_analytics';
const METRICS_KEY = 'metrics';
const KEYWORDS_KEY = 'keywords';

class PresenceAnalytics extends ApiResourceBaseClass {
    // TODO BIRD: type the queryParams and the return value

    static exportMetrics(queryParams: Record<string, any>): Promise<{
        status: boolean;
    }> {
        return PresenceAnalytics.get(
            `${RESOURCE_URI}/${METRICS_KEY}/export?${generateQueryParamsString(queryParams)}`,
        ).then(({ data }) => data);
    }

    static exportKeywords(queryParams: Record<string, any>): Promise<{
        status: boolean;
    }> {
        return PresenceAnalytics.get(
            `${RESOURCE_URI}/${KEYWORDS_KEY}/export?${generateQueryParamsString(queryParams)}`,
        ).then(({ data }) => data);
    }

    static getMetricsAnalytics(queryParams: Record<string, any>): Promise<Record<string, any>> {
        return PresenceAnalytics.get(
            `${RESOURCE_URI}/${METRICS_KEY}?${generateQueryParamsString(queryParams)}`,
        ).then(({ data }) => data);
    }

    static getKeywordsAnalytics(queryParams: Record<string, any>): Promise<Record<string, any>> {
        return PresenceAnalytics.get(
            `${RESOURCE_URI}/${KEYWORDS_KEY}?${generateQueryParamsString(queryParams)}`,
        ).then(({ data }) => data);
    }
}

export default PresenceAnalytics;
