import { AuthTokenReceivedData } from 'app/api/types/auth_token';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'auth_token';

class AuthTokenResource extends ApiResourceBaseClass {
    static getAuthToken(
        headers: Record<string, any> = {},
    ): Promise<AuthTokenReceivedData> {
        return AuthTokenResource.get(RESOURCE_URI, {}, headers).then(
            ({ data }) => data,
        );
    }
}

export default AuthTokenResource;
