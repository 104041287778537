import { useTranslation } from 'react-i18next';

import { AccountPartnerName } from 'app/api/types/account';
import { getPartnerAlt } from 'app/common/utils/partner';
import { STATIC_BASE_URL } from 'app/config';

const PartnerIcon = (partner: AccountPartnerName) => {
    const { t } = useTranslation();
    const imgName = {
        google: 'google',
        facebook: 'facebook',
        apple: 'apple',
    };
    const imgSrc = `${STATIC_BASE_URL}/images/common/partners_sprite/circle/${imgName[partner]}.svg`;

    return <img src={imgSrc} alt={t(getPartnerAlt(partner))} className="noselect" />;
};

export default PartnerIcon;
