import React, { useCallback, useEffect, useState } from 'react';

import QuickActionModal from './components/QuickActionModal';

const QuickAction: React.FC = () => {
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    const [specialKeyPressed, setSpecialKeyPressed] = useState<boolean>(false);

    const handleKeyDown = useCallback(
        event => {
            if (event.ctrlKey || event.metaKey) {
                setSpecialKeyPressed(true);

                if (event.key === 'k') {
                    event.preventDefault();
                    setDisplayModal(!displayModal);
                }
            }
            if (event.key === 'Escape') setDisplayModal(false);
        },
        [displayModal],
    );

    const handleKeyUp = useCallback(event => {
        if (!event.ctrlKey && !event.metaKey) setSpecialKeyPressed(false);
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyDown, handleKeyUp]);

    const hideModal = () => setDisplayModal(false);

    return (
        <QuickActionModal
            opened={displayModal}
            closeModal={hideModal}
            specialKeyPressed={specialKeyPressed}
        />
    );
};

export default QuickAction;
