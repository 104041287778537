import { DiffusionLoadingDetails } from './LoadingDetails.styled';
import { LoadingRectangle } from './LoadingRectangle';
import { DiffusionStepLog } from '../DiffusionDetails/DiffusionDetails.styled';

const LoadingDetails = () => (
    <DiffusionLoadingDetails>
        <DiffusionStepLog>
            <LoadingRectangle
                recWidth="45px"
                recHeight="45px"
                recRadius="50%"
                recMargin="0px 100px 0px 27px"
            />
            <span>
                <LoadingRectangle
                    recWidth="200px"
                    recHeight="8px"
                    recRadius="5%"
                    recMargin="0 0 12px 0"
                />
                <LoadingRectangle
                    recWidth="200px"
                    recHeight="8px"
                    recRadius="5%"
                    recMargin="0 0 12px 0"
                />
                <LoadingRectangle recWidth="100px" recHeight="8px" recRadius="5%" />
            </span>
        </DiffusionStepLog>
    </DiffusionLoadingDetails>
);

export default LoadingDetails;
