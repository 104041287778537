import {
    AccountFetchJobData,
    AccountFetchJobsData,
    AccountPartner,
    AccountPartnerResourceName,
    FacebookOauthAccountToReauthenticateData,
    FuzzySearchLocationsReceivedData,
    GoogleVerificationCompleteData,
    GoogleVerificationCompleteReceivedData,
    GoogleVerificationStartData,
    GoogleVerificationStartReceivedData,
    GoogleVerificationsOptionsData,
    SearchAccountsReceivedData,
    SearchGmbAccountsReceivedData,
    SearchGoogleVerificationParams,
    V2AccountData,
    V2AccountDetails,
    V2LocationData,
    V2NumberAccountsImportedData,
} from 'app/api/types/account';

import ApiResourceBaseClass from './baseApiCalls';
import formatParams from './utils';

const RESOURCE_URI = 'account';
const RESOURCE_GOOGLE_URI = 'google-oauth-accounts';
const RESOURCE_FACEBOOK_URI = 'facebook-oauth-accounts';
const RESOURCE_FACEBOOK_SINGLE_URI = 'facebook-oauth-account';

export const partnerToResourceUri = {
    GOOGLE_MY_BUSINESS: RESOURCE_GOOGLE_URI,
    gmb: RESOURCE_GOOGLE_URI,
    FACEBOOK: RESOURCE_FACEBOOK_URI,
    facebook: RESOURCE_FACEBOOK_URI,
};

class Account extends ApiResourceBaseClass {
    static searchAccount(
        partner?: AccountPartner | string,
        fuzzy_query?: string,
        all_accounts = false,
        pageParam = 1,
    ): Promise<SearchAccountsReceivedData> {
        return Account.get(
            `${RESOURCE_URI}/search`,
            formatParams({
                partner,
                fuzzy_query,
                all_accounts,
                page: pageParam,
            }),
        ).then(({ data }) => data);
    }

    static getAccount(accountId: string): Promise<V2AccountData> {
        return Account.get(`${RESOURCE_URI}/${accountId}`).then(({ data }) => data);
    }

    static searchGmbAccounts(
        accountId: string,
        fuzzy_query?: string,
        is_active?: boolean,
        all_accounts = false,
        pageParam = 1,
    ): Promise<SearchGmbAccountsReceivedData> {
        return Account.get(
            `${RESOURCE_GOOGLE_URI}/${accountId}/accounts`,
            formatParams({
                fuzzy_query,
                is_active,
                all_accounts,
                page: pageParam,
            }),
        ).then(({ data }) => data);
    }

    static searchAllGoogleAccounts(
        fuzzy_query?: string,
        is_active?: boolean,
        pageParam = 1,
    ): Promise<SearchGmbAccountsReceivedData> {
        return Account.get(
            'google-accounts',
            formatParams({
                fuzzy_query,
                is_active,
                page: pageParam,
            }),
        ).then(({ data }) => data);
    }

    static getAccountDetails(
        accountId: string,
        partner: AccountPartnerResourceName,
    ): Promise<V2AccountDetails> {
        return Account.get(`${partnerToResourceUri[partner]}/${accountId}/information`).then(
            ({ data }) => data,
        );
    }

    static getNumberImportedOAuthAccounts(): Promise<V2NumberAccountsImportedData> {
        return Account.get(`${RESOURCE_URI}/number`).then(({ data }) => data);
    }

    static getLocationDetail(locationId: string, partner: AccountPartner): Promise<V2LocationData> {
        return Account.get(`${partnerToResourceUri[partner]}/locations/${locationId}`, {
            partoo_ui: 'True',
        }).then(({ data }) => data);
    }

    static fuzzySearchLocations(
        partner: AccountPartnerResourceName,
        query: string,
        page: number,
        oauth_account_id?: string,
        google_account_id?: string,
        google_location_id__in?: Array<string | null>,
        not_connected_only?: boolean,
        verification_status?: Array<string>,
    ): Promise<FuzzySearchLocationsReceivedData> {
        const params = formatParams({
            query,
            page,
            oauth_account_id,
            google_account_id,
            not_connected_only,
            verification_status,
            google_location_id__in,
        });
        return Account.get(`${partnerToResourceUri[partner]}/fuzzy-locations`, params).then(
            ({ data }) => data,
        );
    }

    static verificationsFuzzySearchLocations(
        queryParams: SearchGoogleVerificationParams,
    ): Promise<FuzzySearchLocationsReceivedData> {
        return Account.get('verifications/fuzzy-google-locations', formatParams(queryParams)).then(
            ({ data }) => data,
        );
    }

    static fetchGoogleVerificationsOptions(
        googleLocationId: string,
    ): Promise<GoogleVerificationsOptionsData> {
        return Account.get(`verifications/google-locations/${googleLocationId}/options`).then(
            ({ data }) => data,
        );
    }

    static startGoogleVerification(
        googleLocationId: string,
        googleStartVerificationData: GoogleVerificationStartData,
    ): Promise<GoogleVerificationStartReceivedData> {
        const {
            method,
            phone_number: phoneNumber,
            email,
            full_name: fullName,
        } = googleStartVerificationData;

        const payload: GoogleVerificationStartData = {
            method,
        };

        if (['SMS', 'PHONE_CALL'].includes(method) && phoneNumber) {
            payload.phone_number = phoneNumber;
        } else if (method === 'EMAIL' && email) {
            payload.email = email;
        } else if (method === 'ADDRESS' && fullName) {
            payload.full_name = fullName;
        }

        return Account.post(
            `verifications/google-locations/${googleLocationId}/start`,
            payload,
        ).then(({ data }) => data);
    }

    static completeGoogleVerification(
        googleLocationId: string,
        googleCompleteVerificationData: GoogleVerificationCompleteData,
    ): Promise<GoogleVerificationCompleteReceivedData> {
        return Account.post(
            `verifications/google-locations/${googleLocationId}/complete`,
            googleCompleteVerificationData,
        ).then(({ data }) => data);
    }

    static refreshFacebookAccounts(
        accountId: string,
        fetch_origin: string | undefined = undefined,
    ): Promise<AccountFetchJobsData> {
        return Account.post(
            `${RESOURCE_FACEBOOK_URI}/${accountId}/refresh${fetch_origin ? `?fetch_origin=${fetch_origin}` : ''}`,
        ).then(({ data }) => data);
    }

    static getFacebookOauthAccountToReauthenticate(): Promise<FacebookOauthAccountToReauthenticateData> {
        return Account.get(`${RESOURCE_FACEBOOK_SINGLE_URI}/to-reauthenticate`).then(
            ({ data }) => data,
        );
    }

    static refreshGMBAccounts(accountId: string): Promise<AccountFetchJobsData> {
        return Account.post(`${RESOURCE_GOOGLE_URI}/${accountId}/refresh`).then(({ data }) => data);
    }

    static refreshGoogleAccount(
        account_id: string,
        title: string,
        store_code: string,
        locality: string,
        postal_code: string,
    ): Promise<AccountFetchJobData> {
        return Account.post(
            `google-accounts/${account_id}/refresh`,
            formatParams({
                title,
                store_code,
                locality,
                postal_code,
            }),
        ).then(({ data }) => data);
    }

    static disconnect(accountId: string): Promise<string> {
        return Account.delete(`${RESOURCE_URI}/${accountId}`).then(({ data }) => data);
    }
}

export default Account;
