import styled from '@emotion/styled';

export const MergedAccountsMenuListContentTemplateContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: 8px;
`;

export const MergedAccountsMenuListContentHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.default.alpha};
    min-height: 88px;
`;

export const MergedAccountsMenuListContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;
    border-radius: 8px;
`;
