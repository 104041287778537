import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

export const SelectNewUserContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.primary.background};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primary.alpha};
`;

export const SelectNewUserButtons = styled.div`
    display: flex;
    margin-left: auto;
    gap: 16px;
`;

export const AddNewUserButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 72px;
    border: 1px dashed ${({ theme }) => theme.colors.default.alpha};
    border-radius: 8px;
    padding: 16px 16px 16px 24px;
`;
