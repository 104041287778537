import { useMutation, useQueryClient } from 'react-query';

import { competitiveBenchmarkApiClient } from 'admin/api/apiResources';
import { DeleteKeywordPayload, KeywordWithId } from 'admin/api/types/competitiveBenchmark';
import { COMPETITIVE_BENCHMARK_KEYWORDS } from 'admin/common/data/queryKeysConstants';

import { useOrgIdParsed } from './useOrgIdParsed';

export const useDeleteKeyword = () => {
    const queryClient = useQueryClient();
    const orgId = useOrgIdParsed();

    const {
        mutate: deleteKeyword,
        isSuccess,
        reset,
    } = useMutation(
        (payload: DeleteKeywordPayload): Promise<KeywordWithId> => {
            return competitiveBenchmarkApiClient.deleteKeyword(payload);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([COMPETITIVE_BENCHMARK_KEYWORDS, orgId]);
            },
        },
    );

    return {
        mutate: deleteKeyword as (payload: DeleteKeywordPayload) => Promise<KeywordWithId>,
        isSuccess,
        reset,
    };
};
