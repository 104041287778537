import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { FullScreenModal } from 'admin/common/components/modals/FullScreenModal/FullScreenModal';
import {
    USER_INFO_CREATE,
    UserInfosQueryKeys,
} from 'admin/content/tools/pages/MultiOrganizations/enums';

import { MergedAccountsCreateForm } from './MergedAccountsCreateForm';

export const MergedAccountsCreateModal = () => {
    const { t } = useTranslation();
    const [userInfo, setUserInfo] = useStateQueryParams(UserInfosQueryKeys.USER_INFO);
    const show = userInfo === USER_INFO_CREATE;

    const childrenRight = (
        <EditFormDescription
            title={t('admin:org_switcher_merge_page_advice_title')}
            description={t('admin:org_switcher_merge_page_advice_description')}
            advice={t('admin:org_switcher_merge_page_advice_description_2')}
        />
    );

    return (
        <FullScreenModal
            childrenLeft={<MergedAccountsCreateForm />}
            childrenRight={childrenRight}
            show={show}
            onHideClick={() => setUserInfo('')}
            forceDisplayRightPart // ????
        />
    );
};
