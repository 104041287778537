import styled from '@emotion/styled';
import { Icon, Text } from '@partoohub/ui';

export const CategoryRowWrapper = styled.div`
    cursor: pointer;
`;

export const CategoryColumnText = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledCategoryCardIconToolip = styled(Icon)`
    margin-left: 4px;
    opacity: 0.3;
`;
