import { Publisher } from 'admin/api/types';
import { SETTINGS_PLATFORMS_CONNECTION_GOOGLE_FACEBOOK_PATHNAME } from 'admin/common/data/routeIds';

export enum OpenedModalEnum {
    NONE = 'none',
    QUICK_CONNECT = 'quick_connect',
    BUSINESS_CONNECT = 'business_connect',
}

export function getQuickConnectModalUri(publisher: Publisher, businessId: string) {
    const formattedPubliher = publisher === 'google_my_business' ? 'google' : publisher;
    const modal = businessId ? OpenedModalEnum.BUSINESS_CONNECT : OpenedModalEnum.QUICK_CONNECT;
    const openModal =
        '?modal=' +
        modal +
        '&publisher=' +
        formattedPubliher +
        (businessId ? `&businessId=${businessId}` : '');
    const uri = encodeURIComponent(
        `${SETTINGS_PLATFORMS_CONNECTION_GOOGLE_FACEBOOK_PATHNAME}${openModal}`,
    );
    return uri;
}
