import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const MergedAccountsCreateFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
`;

export const MergedAccountsCreateFormTop = styled.div`
    padding: 24px;
`;

export const Title = styled(Text)`
    margin-bottom: 32px;
`;

export const MainUserInfoTitle = styled(Text)`
    margin-top: 24px;
    margin-bottom: 16px;
`;

export const MergeAccountsButton = styled.div`
    padding: 16px;
    margin-top: auto;
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
    position: sticky;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.theme.initial};
`;
