import { useSelector } from 'react-redux';

import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import { getOrgIds } from 'app/common/components/businessModal/context/businessModalFiltersReducer';
import { filterByBusinessSelector } from 'app/common/components/businessModalDeprecated/reducers';

/**
 * Return the selected org id from the search filters (provided to the modal) or the modal filters (selected in the modal)
 */
export default function useSelectedOrgBusinessModal() {
    const { filters } = useBusinessModalContext();
    const filterByBusinessState = useSelector(filterByBusinessSelector);

    const searchOrgId = filterByBusinessState.searchFilters.org_id?.toString();
    const modalSelectedOrgId = getOrgIds(filters)[0]?.toString();

    return {
        searchOrgId,
        modalSelectedOrgId,
    };
}
