import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationVariousElement,
    IllustrationVariousEnum,
} from '@partoohub/iconography';

import { EmptyState } from '@partoohub/modular-components';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import {
    USER_INFO_CREATE,
    UserInfosQueryKeys,
} from 'admin/content/tools/pages/MultiOrganizations/enums';

import {
    NoMergedAccountPlaceholderContainer,
    StyledButton,
} from './NoMergedAccountPlaceholder.styled';

export const NoMergedAccountPlaceholder = () => {
    const { t } = useTranslation();
    const illustrationElement: IllustrationVariousElement = {
        type: IllustrationCategoryEnum.Various,
        name: IllustrationVariousEnum.StatusEmpty,
    };
    const [, setUserInfos] = useStateQueryParams(UserInfosQueryKeys.USER_INFO);

    return (
        <NoMergedAccountPlaceholderContainer>
            <EmptyState
                title={t('admin:org_switcher_empty_title')}
                description={t('admin:org_switcher_empty_description')}
                image={
                    <Illustration
                        dataTrackId="homepage_messages__illustration"
                        illustration={illustrationElement}
                        height="140"
                        width="140"
                    />
                }
                size="small"
            >
                <StyledButton
                    dataTrackId="no_merged_accounts__button"
                    onClick={() => setUserInfos(USER_INFO_CREATE)}
                    variant="primary"
                    shape="cube"
                >
                    {t('admin:org_switcher_merge_accounts_CTA')}
                </StyledButton>
            </EmptyState>
        </NoMergedAccountPlaceholderContainer>
    );
};
