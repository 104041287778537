import { ComponentProps } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { blackColorObject } from '@partoohub/branding';

import { STATIC_BASE_URL } from 'app/config';

interface BusinessCardProps {
    selected: boolean;
}

const BusinessCardContainer = styled.div`
    height: 66px;
    width: 100%;
    position: relative;
    border-radius: 8px;

    cursor: pointer;

    transition: all 0.2s ease;
    box-shadow: ${({ theme }) => theme.shadows.initial};

    &:hover {
        box-shadow: ${({ theme }) => theme.shadows.hover};
    }
`;

const BusinessCardBorders = styled.div<BusinessCardProps>`
    width: 100%;
    height: 100%;

    border-radius: 8px;

    position: absolute;
    top: 0;
    left: 0;

    pointer-events: none;

    ${({ selected, theme }) =>
        selected &&
        css`
            border: 2px solid ${theme.colors.primary.initial};
        `}
`;

const BusinessCardContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
`;

const Corner = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;

    box-sizing: border-box;
    border: solid 20px ${({ theme }) => theme.colors.primary.initial};
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-right-radius: 4px;

    pointer-events: none;
`;

const Tick = styled.img`
    position: absolute;
    top: 7.5px;
    right: 5px;
    pointer-events: none;
`;

export const BusinessCardComponent = (props: BusinessCardProps & ComponentProps<'div'>) => (
    /* Prevent content from moving around when border becomes thicker, borders and
     *  content are in position absolute, container is the reference in position
     *  relative. I didn't find any other solution. */
    <BusinessCardContainer>
        <BusinessCardContent {...props}>{props.children}</BusinessCardContent>
        {props.selected && <Corner />}
        {props.selected && <Tick src={`${STATIC_BASE_URL}/images/app/icons/tick.svg`} alt="" />}
        <BusinessCardBorders selected={props.selected} />
    </BusinessCardContainer>
);

type BusinessCardPhotoProps = {
    url: string;
};

export const BusinessCardPhoto = styled.div<BusinessCardPhotoProps>`
    margin-right: 8px;

    position: relative;
    width: 50px;
    min-width: 50px; // somehow width is not sufficient sometimes
    height: 50px;
    border-radius: 8px;
    border: ${props => (props.url ? `1px solid ${blackColorObject['10']};` : 'none')};
    box-sizing: border-box;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('${props => props.url}');

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    background-color: ${props => (props.url ? 'none' : props.theme.colors.secondary.alpha)};
    color: ${props => props.theme.colors.secondary.initial};
    font-size: 24px;
`;

export const BusinessCardInfos = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
`;

export const BusinessCardTitle = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.colors.default.initial};

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const BusinessCardContentBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    font-size: 12px;
    line-height: 15px;
`;

export const BusinessReach = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2px;
    height: 24px;
    padding: 4px 8px;
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
    border-radius: 4px;
    width: fit-content;

    > * {
        text-transform: uppercase !important;
    }
`;

export const BusinessCardAddress = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};

    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
