import styled from '@emotion/styled';
import { Expandable, Stack } from '@partoohub/ui';

export const KeywordContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    gap: 16px;
`;

export const StackFullWidth = styled(Stack)`
    width: 100%;
`;

export const ExpandableFullWidth = styled(Expandable)`
    width: 100%;
`;
