import {
    ComponentProps,
    MouseEvent,
    PropsWithChildren,
    ReactNode,
    RefObject,
    useContext,
} from 'react';

import FlipAnimated from 'app/common/designSystem/components/atoms/FlipAnimated';
import { PositionedPortal } from 'app/common/utils/PositionedPortal';

import {
    Container,
    EmptyCard,
    Icon,
    ItemCard,
    MenuCard,
    MotionSectionCardBody,
    RelativeContainer,
    SectionCard,
    SectionCardHeader,
} from './Menu.styled';
import FlipSizeAnimated from '../../../atoms/FlipSizeAnimated/FlipSizeAnimated';
import { ToggleMenuContext } from '../contexts/ToggleMenuContext';

const ANIM_DURATION = 300;
const MAX_MENU_HEIGHT = 374; // pixels

export interface MenuProps {
    referenceElement: HTMLElement | null;
    id?: string;
    ref?: RefObject<HTMLDivElement>;
}

const Menu = ({
    children,
    referenceElement,
    id,
    ref,
    ...props
}: PropsWithChildren<MenuProps & ComponentProps<'div'>>) => {
    // we need to re-render when opening / closing sections so that the height get animated
    useContext(ToggleMenuContext);

    if (referenceElement === null) {
        return null;
    }

    return (
        <FlipSizeAnimated duration={ANIM_DURATION} maxHeight={MAX_MENU_HEIGHT}>
            <PositionedPortal
                referenceElement={referenceElement}
                position="bottom"
                yOffset={4}
                sameWidthAsReference
            >
                <MenuCard maxHeight={MAX_MENU_HEIGHT} id={id} ref={ref} {...props}>
                    {children}
                </MenuCard>
            </PositionedPortal>
        </FlipSizeAnimated>
    );
};

interface SectionProps {
    children: ReactNode;
    title: ReactNode;
    opened?: boolean;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Section = ({ children, title, opened = true, onClick }: SectionProps) => (
    <SectionCard>
        <FlipAnimated duration={ANIM_DURATION} easing="linear">
            <SectionCardHeader onClick={onClick}>
                {title}
                <Icon className="fa-solid fa-angle-right" direction={opened ? 'down' : 'right'} />
            </SectionCardHeader>
        </FlipAnimated>
        <RelativeContainer>
            <Container position={opened ? 'static' : 'absolute'}>
                {opened && (
                    <MotionSectionCardBody
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {children}
                    </MotionSectionCardBody>
                )}
            </Container>
        </RelativeContainer>
    </SectionCard>
);

interface ItemProps {
    children: ReactNode;
}

const Item = ({ children, ...props }: ItemProps & ComponentProps<'div'>) => (
    <ItemCard {...props}>{children}</ItemCard>
);

interface EmptyProps {
    children: ReactNode;
}

const Empty = ({ children, ...props }: EmptyProps & ComponentProps<'div'>) => (
    <EmptyCard {...props}>{children}</EmptyCard>
);

export default Object.assign(Menu, {
    Section,
    Item,
    Empty,
});
