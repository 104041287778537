import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import {
    BusinessValidationResponse,
    ConversationStarter,
    ConversationStarterItem,
    ConversationStarterItemPayload,
    ConversationStarterItemsGetResponse,
    ConversationStarterPatchPayload,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

const CONVERSATION_STARTERS = 'conversation_starters';

const getConversationStarterItemUri = (conversationStarterId: string) =>
    `conversation_starters/${conversationStarterId}/conversation_starter_items`;

const getConversationStarterItemIdUri = (
    conversationStarterId: string,
    conversationStarterItemId: string,
) =>
    `conversation_starters/${conversationStarterId}/conversation_starter_items/${conversationStarterItemId}`;

class ConversationStarters extends ApiResourceBaseClass {
    static getConversationStarters(): Promise<Array<ConversationStarter>> {
        return ConversationStarters.get(CONVERSATION_STARTERS).then(({ data }) => data);
    }

    static getConversationStarter(conversationStarterId: string): Promise<ConversationStarter> {
        return ConversationStarters.get(CONVERSATION_STARTERS + `/${conversationStarterId}`).then(
            ({ data }) => data,
        );
    }

    static getConversationStartersItems(
        conversationStarterId: string,
    ): Promise<ConversationStarterItemsGetResponse> {
        return ConversationStarters.get(getConversationStarterItemUri(conversationStarterId)).then(
            ({ data }) => data,
        );
    }

    static createConversationStarter(): Promise<ConversationStarter> {
        return ConversationStarters.post(CONVERSATION_STARTERS).then(({ data }) => data);
    }

    static duplicateConversationStarter(
        conversationStarterId: string,
    ): Promise<ConversationStarter> {
        return ConversationStarters.post(CONVERSATION_STARTERS, {
            duplicate_conversation_starter_id: conversationStarterId,
        }).then(({ data }) => data);
    }

    static deleteConversationStarter(conversationStarterId: string): Promise<ConversationStarter> {
        return ConversationStarters.delete(
            `${CONVERSATION_STARTERS}/${conversationStarterId}`,
        ).then(({ data }) => data);
    }

    static updateConversationStarter(
        conversationStarterId: string,
        payload: ConversationStarterPatchPayload,
    ): Promise<ConversationStarter> {
        return ConversationStarters.patch(
            CONVERSATION_STARTERS + `/${conversationStarterId}`,
            payload,
        ).then(({ data }) => data);
    }

    static updateConversationStarterBusinesses(
        conversationStarterId: string,
        businessSearchFilter: BusinessModalFiltersType,
    ): Promise<ConversationStarter> {
        const payload = {
            business_search_params: businessSearchFilter,
        };
        return ConversationStarters.patch(
            CONVERSATION_STARTERS + `/${conversationStarterId}/business-selection`,
            payload,
        ).then(({ data }) => data);
    }

    static activateConversationStarter(
        conversationStarterId: string,
        enable: boolean,
    ): Promise<ConversationStarter> {
        const payload = {
            enable,
        };
        return ConversationStarters.patch(
            CONVERSATION_STARTERS + `/${conversationStarterId}/activation`,
            payload,
        ).then(({ data }) => data);
    }

    static createConversationStarterItem(
        conversationStarterId: string,
        payload: ConversationStarterItemPayload,
    ): Promise<ConversationStarterItem> {
        return ConversationStarters.post(
            getConversationStarterItemUri(conversationStarterId),
            payload,
        ).then(({ data }) => data);
    }

    static updateConversationStarterItem(
        conversationStarterId: string,
        conversationStarterItemId: string,
        payload: ConversationStarterItemPayload,
    ): Promise<ConversationStarterItem> {
        return ConversationStarters.patch(
            getConversationStarterItemIdUri(conversationStarterId, conversationStarterItemId),
            payload,
        ).then(({ data }) => data);
    }

    static updateConversationStarterItemOrder(
        conversationStarterId: string,
        reorderedConversationStarterIds: Array<string>,
    ): Promise<string> {
        return ConversationStarters.patch(getConversationStarterItemUri(conversationStarterId), {
            cs_items_ids: reorderedConversationStarterIds,
        }).then(({ data }) => data);
    }

    static deleteConversationStarterItem(
        conversationStarterId: string,
        conversationStarterItemId: string,
    ) {
        return ConversationStarters.delete(
            getConversationStarterItemIdUri(conversationStarterId, conversationStarterItemId),
        );
    }

    static validateBusinesses(
        conversationStarterId: string,
        businessSearchFilter: BusinessModalFiltersType,
    ): Promise<BusinessValidationResponse> {
        const payload = {
            business_search_params: businessSearchFilter,
        };
        return ConversationStarters.post(
            `${CONVERSATION_STARTERS}/${conversationStarterId}/validate-business-selection`,
            payload,
        ).then(({ data }) => data);
    }
}

export default ConversationStarters;
