import { QueryKey, useQuery } from 'react-query';

import { competitiveBenchmarkApiClient } from 'admin/api/apiResources';
import { KeywordAdminData } from 'admin/api/types/competitiveBenchmark';
import { COMPETITIVE_BENCHMARK_KEYWORDS } from 'admin/common/data/queryKeysConstants';

export const useGetKeywordsFromOrg = (
    orgId: number,
): { keywords: KeywordAdminData[]; isLoading: boolean } => {
    const { data, isLoading } = useQuery([COMPETITIVE_BENCHMARK_KEYWORDS, orgId] as QueryKey, () =>
        competitiveBenchmarkApiClient.getKeywordsFromOrg(orgId),
    );
    return { keywords: data?.keywords ?? [], isLoading };
};
