import { format, parse, parseISO } from 'date-fns';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const ISO_DATE_FORMAT = 'd MMM yyyy';
export const ISO_DATETIME_FORMAT = 'd MMM yyyy HH:mm:ss';
export const ISO_DATETIME_FORMAT_WITH_TIMEZONE = 'yyyy-MM-dd\'T\'HH:mm:ss';
export const MONTH_YEAR_FORMAT = 'MMM yyyy';

export const HOUR_FORMAT = 'HH:mm';
export const TIME_FORMAT = 'HH:mm:ss';

export const formatISODate = (datetime: string) => {
    const parsedDate = parseISO(datetime);
    return format(parsedDate, ISO_DATE_FORMAT);
};

export const formatISODatetime = (datetime: string) => {
    const parsedDate = parseISO(datetime);
    return format(parsedDate, ISO_DATETIME_FORMAT);
};

const MILLISECONDS_PER_MINUTE = 60000;
export const getUTCDate = (date: Date | string) => {
    const convertedDate = typeof date === 'string' ? new Date(date) : date;
    return new Date(
        convertedDate.getTime() + convertedDate.getTimezoneOffset() * MILLISECONDS_PER_MINUTE,
    );
};

export const formatTZDate = (scheduledDate: Date, hour: string) => {
    const date = new Date(scheduledDate);
    if (hour.length > 0) {
        const [hourValue, minuteValue] = hour.split(':');
        date.setHours(parseInt(hourValue, 10));
        date.setMinutes(minuteValue ? parseInt(minuteValue, 10) : 0);
    } else {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
    }

    return format(date, 'yyyy-MM-dd\'T\'HH:mm:ssxxx');
};

export const stringifyDate = (value: Date | null) =>
    value !== null ? format(value, DATE_FORMAT) : '';

export const parseDate = (value: string) => {
    const parsedDate = parse(value, DATE_FORMAT, new Date());
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
};
