export const getPartnerAlt = (partner: string): string => {
    /*
        Post pages use:
        - google_my_business__posts
        - instagram__posts
        - facebook__posts
    */

    switch (partner) {
        case 'facebook':
        case 'facebook__posts':
            return 'facebook_logo';
        case 'google':
        case 'google_my_business':
        case 'google_my_business__posts':
            return 'google_logo';
        case 'instagram__posts':
            return 'instagram_logo';
        // app/onboarding/createGoogleLocationFromBusiness/screens/BusinessNameScreen/BusinessNameScreen.tsx
        case 'business':
            return 'partoo_logo';
        default:
            return partner;
    }
};
