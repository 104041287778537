import { useEffect } from 'react';

export const useEventListener = <
    T extends EventTarget,
    E extends keyof GlobalEventHandlersEventMap,
>(
    ref: T | null,
    event: E,
    handler: (event: GlobalEventHandlersEventMap[E]) => void,
) => {
    useEffect(() => {
        if (ref !== null) {
            ref.addEventListener(event, handler);
            return () => ref.removeEventListener(event, handler);
        }
    }, [ref, handler]);
};
