import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    V2FormattedDiffusionErrors,
    V2FormattedDiffusionPostCheckErrors,
} from 'admin/api/types/diffusion';

type Props = {
    diffusionError: V2FormattedDiffusionErrors | V2FormattedDiffusionPostCheckErrors;
};

const DiffusionErrorComponent = ({ diffusionError }: Props) => {
    const { t } = useTranslation();
    let toDisplay = '';

    if (diffusionError) {
        if (diffusionError.field) {
            toDisplay += `${t(diffusionError.field)}: `;
        }

        if (diffusionError.key) {
            toDisplay += `${t(diffusionError.key)}`;
        }
    }

    return <Text variant="bodyMBold">{`${toDisplay}`}</Text>;
};

export default DiffusionErrorComponent;
