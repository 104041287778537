import { useEffect, useId } from 'react';

import { useHideIntercomContext } from './useHideIntercomContext';

export const useHideIntercom = (shouldHideIntercom: boolean) => {
    const id = useId();
    const contextData = useHideIntercomContext();

    useEffect(() => {
        if (!contextData) {
            console.warn('HideIntercomProvider not found');
            return;
        }
        const { showIntercom, hideIntercom } = contextData;

        if (shouldHideIntercom) {
            hideIntercom(id);
        } else {
            showIntercom(id);
        }

        return () => showIntercom(id);
    }, [shouldHideIntercom, contextData]);
};
