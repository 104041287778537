import ApiResourceBaseClass from './baseApiCalls';
import {
    diffusionLogFormatter,
    diffusionLogResponseFormatter,
    dropUndefinedParams,
    formatDiffusionLogQuery,
    formatGetNewLogCountQuery,
    generateQueryParamsString,
} from './utils';
import {
    DiffusionLogsParams,
    NewLogsCountResponse,
    SearchDiffusionLogsReceivedData,
    V2FormattedDiffusionLog,
} from '../../types/diffusion_log';

const RESOURCE_URI = 'diffusion_log';

class DiffusionLog extends ApiResourceBaseClass {
    static searchDiffusionLogs(
        queryParams: DiffusionLogsParams,
    ): Promise<SearchDiffusionLogsReceivedData> {
        return DiffusionLog.get(
            `${RESOURCE_URI}/search`,
            dropUndefinedParams(formatDiffusionLogQuery(queryParams)),
        ).then(({ data }) => diffusionLogResponseFormatter(data));
    }

    static getNewLogsCount(
        queryParams: DiffusionLogsParams,
    ): Promise<NewLogsCountResponse> {
        return DiffusionLog.get(
            `${RESOURCE_URI}/count?${generateQueryParamsString(
                formatGetNewLogCountQuery(queryParams),
            )}`,
        ).then(({ data }) => data);
    }

    static getDiffusionLog(
        diffusionLogId: number,
    ): Promise<V2FormattedDiffusionLog> {
        return DiffusionLog.get(`${RESOURCE_URI}/${diffusionLogId}`).then(
            ({ data }) => diffusionLogFormatter(data),
        );
    }
}

export default DiffusionLog;
