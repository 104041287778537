import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const CategoryTranslationsContainer = styled.div`
    border: 1px solid ${greyColorObject.initial};
    border-radius: 4px;
`;
export const StyledTitle = styled(Text)`
    padding: 26px 24px;
    border-bottom: solid 1px ${greyColorObject.initial};
`;
export const CategoryTranslationsList = styled.div`
    padding: 26px 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
`;

export const CategoryTranslationItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const FlagContainer = styled.div`
    width: 26px;
    height: 26px;
    border: 1px lightgray solid;
    border-radius: 50%;
    overflow: hidden;
    & > img {
        height: 24px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    grid-area: flag;
`;
