import { useState } from 'react';

import { MultiSelect, Option, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { UserTypeEnum } from 'admin/api/types/userInfos';
import { EmptyMessageWrapper } from 'admin/common/components/inputs/EmptyMessage';
import useDebounce from 'admin/common/hooks/useDebounce';
import { useUserInfos } from 'admin/content/tools/pages/MultiOrganizations/hooks/useUserInfos';
import { userToOption } from 'admin/content/tools/pages/MultiOrganizations/utils/utils';

type Props = {
    selectedValue: Option[];
    setSelectedValue: (value: Option[]) => void;
    errorMessage?: string;
};

export const UserInfosMultiSelect = ({ selectedValue, setSelectedValue, errorMessage }: Props) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');

    const debouncedSearchValue = useDebounce(searchValue);

    const { query, userInfos } = useUserInfos({
        query: debouncedSearchValue,
        has_users: true,
        user_type: UserTypeEnum.HUMAN,
    });

    const { fetchNextPage, hasNextPage } = query;
    const options = userInfos.map(userToOption);

    return (
        <MultiSelect
            dataTrackId="user_infos_multi_select"
            label={t('admin:org_switcher_merge_page_dropdown')}
            notice={
                !!errorMessage
                    ? errorMessage
                    : t('admin:org_switcher_merge_page_dropdown_description')
            }
            sections={[
                {
                    options,
                },
            ]}
            selectedValue={selectedValue}
            onChange={setSelectedValue}
            onSearch={setSearchValue}
            emptyMessage={
                <EmptyMessageWrapper>
                    <Text variant="bodyMMedium">
                        {t('admin:select_organization__empty_message')} “{debouncedSearchValue}“
                    </Text>
                </EmptyMessageWrapper>
            }
            required
            loadMore={fetchNextPage}
            hasMore={hasNextPage}
            error={!!errorMessage}
        />
    );
};
