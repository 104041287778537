import { DependencyList, useEffect, useRef } from 'react';

import { castArray } from 'lodash-es';
import { useStore } from 'react-redux';
import { AnyAction } from 'redux';

import { AppState } from 'app/states/reducers';

/**
 * Subscribes to redux store events
 * Listen to actions and call a given callback after the redux store changes
 * Can emulate Sagas like that
 *
 * @param callback provide the triggered action and the whole state after redux changes
 * @param type the type/list of types that trigger the callback
 * @param deps dependency array for the callback
 */
export function useReduxEffect(
    callback: (action: AnyAction, state: AppState) => void,
    type: string | string[],
    deps: DependencyList = [],
): void {
    const currentValue = useRef(null);
    const store = useStore();

    const handleChange = (): void => {
        const state = store.getState();
        const action = state.actionReduxEffectReducer;

        if (action === undefined) {
            console.error('actionReduxEffectReducer is not defined in the store');
            return;
        }

        const previousValue = currentValue.current;
        currentValue.current = action.count;

        if (previousValue !== action.count && castArray(type).includes(action.action.type)) {
            // Do NOT try to touch it
            // setTimeout is used to force the callback after the current render cycle
            setTimeout(() => {
                callback(action.action, state);
            }, 0);
        }
    };

    useEffect(() => {
        const unsubscribe = store.subscribe(handleChange);
        return (): void => unsubscribe();
    }, deps);
}
