/**
 * @deprecated Use `TransparencyEnum.DISABLED`
 */
export const DISABLED_COLOR = 'rgba(127, 142, 164, 0.25)';

/**
 * @deprecated Use colors from @partoohub/branding
 */
export type Color =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'sidebar'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'dark'
    | 'light'
    | 'black'
    | 'white'
    | 'background'
    | 'shadow'
    | 'overlay'
    | 'transparent'
    | 'border'
    | 'disabled'
    | 'sidebar-color'
    | 'social-google'
    | 'social-facebook'
    | 'social-twitter'
    | 'social-foursquare'
    | 'social-tripadvisor'
    | 'color-light-grey';

/**
 * @deprecated Use colors from @partoohub/branding
 */
export const [
    PRIMARY,
    SECONDARY,
    TERTIARY,
    QUATERNARY,
    SIDEBAR,
    SUCCESS,
    WARNING,
    DANGER,
    INFO,
    DARK,
    LIGHT,
    BLACK,
    WHITE,
    BACKGROUND,
    SHADOW,
    OVERLAY,
    TRANSPARENT,
    BORDER,
    DISABLED,
    SIDEBAR_COLOR,
    SOCIAL_GOOGLE,
    SOCIAL_FACEBOOK,
    SOCIAL_TWITTER,
    SOCIAL_FOURSQUARE,
    SOCIAL_TRIPADVISOR,
    LIGHT_GREY,
] = [
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'sidebar',
    'success',
    'warning',
    'danger',
    'info',
    'dark',
    'light',
    'black',
    'white',
    'background',
    'shadow',
    'overlay',
    'transparent',
    'border',
    'disabled',
    'sidebar-color',
    'social-google',
    'social-facebook',
    'social-twitter',
    'social-foursquare',
    'social-tripadvisor',
    'color-light-grey',
];

export const COLORS = [
    PRIMARY,
    SECONDARY,
    TERTIARY,
    QUATERNARY,
    SIDEBAR,
    SUCCESS,
    WARNING,
    DANGER,
    INFO,
    DARK,
    LIGHT,
    BLACK,
    WHITE,
    BACKGROUND,
    SHADOW,
    OVERLAY,
    TRANSPARENT,
    BORDER,
    DISABLED,
    SIDEBAR_COLOR,
    SOCIAL_GOOGLE,
    SOCIAL_FACEBOOK,
    SOCIAL_TWITTER,
    SOCIAL_FOURSQUARE,
    SOCIAL_TRIPADVISOR,
    LIGHT_GREY,
];
