import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

import ApiResourceBaseClass from './baseApiCalls';
import {
    CompetitiveKeywords,
    CompetitiveOverview,
    CompetitorCentralRank,
    CompetitorLocalRank,
    CompetitorLocalRankRawData,
    CompetitorRankRawData,
    ExportPayload,
    KeywordType,
} from '../../types/competitiveBenchmark';

const RESOURCE_URI = 'competitive-benchmark';

export const OVERVIEW_DEFAULT: CompetitiveOverview = { dates: [], businesses: [] };

export type GetLocalRankingsParams = {
    keyword: string;
    date: string;
    business: string;
};

export type GetGlobalRankingsParams = {
    keyword: string;
    date: string;
} & BusinessModalFiltersType;

export type GetOverviewParams = {
    keyword: string;
    start_date: string;
    end_date: string;
} & BusinessModalFiltersType;

class CompetitiveBenchmark extends ApiResourceBaseClass {
    static async getKeywordsCompetitive(): Promise<CompetitiveKeywords> {
        const { data } = await CompetitiveBenchmark.get(`${RESOURCE_URI}/keywords`);
        const parsedAvailableKeywords: KeywordType[] = data
            ? data.available_keywords.map((availableKeyword: any) => {
                  return {
                      keyword: availableKeyword.keyword,
                      dates: availableKeyword.dates
                          .map((date: string) => new Date(date))
                          .sort((a: Date, b: Date) => a.getTime() - b.getTime()),
                  };
              })
            : [];

        return { available_keywords: parsedAvailableKeywords };
    }

    static async getOverview(params: GetOverviewParams): Promise<CompetitiveOverview> {
        const { data } = await CompetitiveBenchmark.get(`${RESOURCE_URI}/overview`, params);
        if (!data) return OVERVIEW_DEFAULT;
        const dates: Date[] = data.overview.dates
            .map((date: string) => new Date(date))
            .sort((a: Date, b: Date) => a.getTime() - b.getTime());
        return { dates, businesses: data.overview.businesses };
    }

    static async getCompetitorRankings(
        params: GetGlobalRankingsParams,
    ): Promise<CompetitorCentralRank[]> {
        const { data } = await CompetitiveBenchmark.get(`${RESOURCE_URI}/central`, params);
        const ranks: CompetitorRankRawData[] = data?.ranks ?? [];
        return ranks.map(rank => ({
            name: rank.name,
            highlight: rank.highlight,
            apparitionPercentage: rank.apparition_percentage,
            rank: rank.rank,
            rating: rank.average_rating,
            reviewCount: rank.average_reviews,
            businessCount: rank.business_count,
        }));
    }

    static async getCompetitorLocalRankings(
        params: GetLocalRankingsParams,
    ): Promise<CompetitorLocalRank[]> {
        const { data } = await CompetitiveBenchmark.get(`${RESOURCE_URI}/local`, params);
        const ranks: CompetitorLocalRankRawData[] = data?.ranks ?? [];
        return ranks.map(rank => ({
            name: rank.name,
            highlight: rank.highlight,
            rank: rank.rank,
            rating: rank.rating,
            reviewCount: rank.review_count,
            address: rank.address,
        }));
    }

    static async postExportTask(payload: ExportPayload): Promise<void> {
        return CompetitiveBenchmark.post(`${RESOURCE_URI}/export`, payload).then(({ data }) => {
            return data;
        });
    }
}

export default CompetitiveBenchmark;
