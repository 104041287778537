import { FC } from 'react';

import { HeadManager } from '@partoohub/modular-components';
import { ErrorBoundary } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import PageErrorPlaceholder from 'admin/common/components/errors/PageErrorPlaceholder';
import env from 'admin/common/utils/getEnvironment';

import { CompetitiveBenchmark } from './CompetitiveBenchmark';

const Root: FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <HeadManager title={t('admin:page_competitive_benchmark__title')} env={env.getEnv()} />
            <ErrorBoundary
                dataTrackId="page_tools_reviews_error"
                FallbackComponent={PageErrorPlaceholder}
            >
                <CompetitiveBenchmark />
            </ErrorBoundary>
        </>
    );
};

export default Root;
