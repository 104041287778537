import { useCallback, useState } from 'react';

import { useQuery } from 'react-query';

import { diffusionCleanerApiClient } from 'admin/api/apiResources';
import { DiffusionCleanerCountFilters } from 'admin/api/types';
import { DIFFUSION_CLEANER_COUNT } from 'admin/common/data/queryKeysConstants';
import { getSelectedOptions } from 'admin/common/utils/selectOptions';

import { useOrg } from './../hooks/useOrg';

export const useDiffusionCount = (filters: DiffusionCleanerCountFilters) => {
    const [org] = useOrg();
    const orgId = filters.org?.value || org?.value;
    const [pageHasError, setPageHasError] = useState(false);

    const queryFn = useCallback(() => {
        return diffusionCleanerApiClient.diffusionCleanerCount({
            orgId,
            provider: filters.provider?.value,
            publishers: getSelectedOptions(filters.publishers),
            errorKeys: getSelectedOptions(filters.errorTypes),
            orderBy: '-count',
            groups: getSelectedOptions(filters.groups),
        });
    }, [orgId, filters.provider, filters.groups, filters.publishers, filters.errorTypes]);

    const { data, isLoading, error } = useQuery(
        [
            DIFFUSION_CLEANER_COUNT,
            orgId,
            filters.provider,
            filters.groups,
            filters.publishers,
            filters.errorTypes,
        ],
        queryFn,
        {
            onSuccess: () => {
                setPageHasError(false);
            },
            onError: () => {
                setPageHasError(true);
            },
            enabled: !!orgId || !!filters.provider,
        },
    );
    return { data, isLoading, error, pageHasError };
};
