import { Container, TextStyled } from './EllipsisLink.styled';

type Props = {
    value: string;
    icon: JSX.Element;
};

export const EllipsisLink = ({ value, icon }: Props) => {
    return (
        <Container>
            <TextStyled as="p">{value}</TextStyled>
            {icon}
        </Container>
    );
};
