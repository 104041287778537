import { useQuery } from 'react-query';

import { categoriesApiClient } from 'admin/api/apiResources';
import { CategoryDetailFilter } from 'admin/api/types/categories';
import { CATEGORIES } from 'admin/common/data/queryKeysConstants';

export const useCategoryDetails = (categoryId: number, params: CategoryDetailFilter) => {
    return useQuery([CATEGORIES, { categoryId }, { params }], () => {
        return categoriesApiClient.getCategoryDetails(categoryId, params);
    });
};
