import { DependencyList, useEffect } from 'react';

type useIntersectionObserverProps<T> = {
    target: React.RefObject<T>;
    callback: (entry: IntersectionObserverEntry) => void;
    options?: IntersectionObserverInit;
    dependencies?: DependencyList;
};

export const useIntersectionObserver = <T extends HTMLElement>({
    target,
    callback,
    options,
    dependencies,
}: useIntersectionObserverProps<T>) => {
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0]) {
                callback(entries[0]);
            }
        }, options);

        if (target.current) {
            observer.observe(target.current);
        }
        return () => observer.disconnect();
    }, [dependencies]);
};
