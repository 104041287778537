import { FC, useEffect, useState } from 'react';

import { CategoryData } from '@partoohub/api-client';
import { Modal, toast } from '@partoohub/ui';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';

import { categoriesApiClient } from 'admin/api/apiResources';
import { RemapCategoryPayload } from 'admin/api/types/categories';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { CancelButton } from 'admin/content/categories/pages/common/CancelButton/CancelButton';
import { CategorySaveButton } from 'admin/content/categories/pages/common/CategorySaveButton/CategorySaveButton';
import { CategoryTranslation } from 'admin/content/categories/pages/common/CategoryTranslation/CategoryTranslation';
import { GoBackButton } from 'admin/content/categories/pages/common/GoBackButton/GoBackButton';
import { useCategoryDetails } from 'admin/content/categories/pages/common/hooks/useCategoryDetails';
import { useFromPage } from 'admin/content/categories/pages/common/hooks/useFromPage';

import { RemapConfirmationModal } from './components/RemapConfirmationModal/RemapConfirmationModal';
import { RemapInformation } from './components/RemapInformation/RemapInformation';

export const CategoryRemap: FC = () => {
    const { t } = useTranslation();
    const { goBack } = useFromPage(true);

    const { categoryId } = useParams();
    const [selectedCategory, setSelectedCategory] = useState<CategoryData | null>(null);
    const [showModal, setShowModal] = useState(false);

    const { data, isLoading } = useCategoryDetails(parseInt(categoryId ?? ''), {
        with_names: true,
    });

    const category = data ?? null;

    useEffect(() => {
        const default_cat =
            category && isEmpty(category?.live_category) ? category : category?.live_category;
        setSelectedCategory(default_cat ?? null);
    }, [category]);

    const { mutate: postCategories } = useMutation(
        async (categoriesToRemap: RemapCategoryPayload) => {
            await categoriesApiClient.postCategoriesRemap(categoriesToRemap);
            setShowModal(false);
            goBack();
        },
        {
            onError: () => {
                toast.error(
                    t('admin:page_category__remap_error_toast__title'),
                    t('admin:page_category__remap_error_toast__description'),
                );
            },
            onSuccess: () => {
                toast.success(
                    t('admin:page_category__remap_toast__title'),
                    t('admin:page_category__remap_toast__content'),
                );
            },
        },
    );

    const displayCancelButton =
        selectedCategory &&
        selectedCategory.gmb_id !== category?.gmb_id &&
        selectedCategory.gmb_id !== category?.live_category?.gmb_id;

    const saveRemap = () => {
        const categoriesToRemap = {
            category_id_live: selectedCategory?.id,
            category_id_to_map: category?.id,
        };
        postCategories(categoriesToRemap);
    };

    return (
        <>
            {!isLoading && (
                <AdminPage
                    dataTrackId="page_category_edit"
                    title={t('admin_categories_obsolete_title')}
                    infoLink={
                        'https://partoo.elium.com/tile/view/278/#2287d4b8-ae9b-413b-b0e4-16368df93344'
                    }
                    buttons={
                        displayCancelButton ? (
                            <CancelButton onClick={() => setSelectedCategory(null)} />
                        ) : (
                            <></>
                        )
                    }
                >
                    <GoBackButton />
                    <RemapInformation
                        category={category}
                        changeCategoryValue={setSelectedCategory}
                        selectedCategory={selectedCategory}
                    />
                    <CategoryTranslation selectedCategory={selectedCategory} />
                    {selectedCategory && category && (
                        <>
                            <CategorySaveButton
                                disabled={
                                    !category.live_category
                                        ? selectedCategory.gmb_id === category.gmb_id
                                        : selectedCategory.gmb_id === category.live_category.gmb_id
                                }
                                onClick={() => setShowModal(true)}
                            />
                            <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
                                <RemapConfirmationModal
                                    warningTextParams={{
                                        impactedBusinesses:
                                            category?.impacted_businesses?.toString(),
                                        categoryGmbId: category?.gmb_id,
                                        selectedCategoryGmbId: selectedCategory.gmb_id,
                                    }}
                                    onSaveClick={saveRemap}
                                    onCancelClick={() => setShowModal(false)}
                                />
                            </Modal>
                        </>
                    )}
                </AdminPage>
            )}
        </>
    );
};
