import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { EASINGS } from 'admin/common/data/constants/easings';

type DropdownProps = {
    open: boolean;
};

export const LinkWrapper = styled.div`
    margin-bottom: 24px;

    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
`;

export const LinkStyled = styled.div`
    font-weight: 700;

    transition: color 0.2s;

    &:hover {
        color: ${({ theme }) => theme.colors.primary.initial};
    }

    .menu_link {
        transition: color 0.2s;

        &.active {
            color: ${({ theme }) => theme.colors.primary.initial};
        }
    }

    a {
        display: inline-flex;
        gap: 8px;
    }
`;

export const DropdownIcon = styled.span<DropdownProps>`
    margin-left: 4px;
    padding: 0px 4px;

    font-size: 12px;

    cursor: pointer;

    i {
        transition: transform 0.2s ${EASINGS.easeOutAndBack};
    }

    ${props =>
        props.open &&
        css`
            i {
                transform: rotate(90deg);
            }
        `}
`;

export const SublinkGroup = styled.div<DropdownProps>`
    margin-left: 8px;

    max-height: 0px;
    overflow: hidden;

    transition: max-height 0.2s;

    ${props =>
        props.open &&
        css`
            max-height: 200px;
        `}
`;
