export const JUSTACOTE = 'justacote';
export const JUSTACOTE_PHOTOS = 'justacote__photos';
export const GOOGLE_MY_BUSINESS = 'google_my_business';
export const GOOGLE_MY_BUSINESS_PHOTOS = 'google_my_business__photos';
export const GOOGLE_MY_BUSINESS_PHONE = 'google_my_business__phone';
export const GOOGLE_MY_BUSINESS_ATTRIBUTES = 'google_my_business__attributes';
export const GOOGLE_MY_BUSINESS_ADDRESS = 'google_my_business__address';
export const GOOGLE_MY_BUSINESS_SERVICES = 'google_my_business__services';
export const GOOGLE_MY_BUSINESS_FOOD_MENUS = 'google_my_business__food_menus';
export const GOOGLE_MY_BUSINESS_MORE_HOURS = 'google_my_business__more_hours';
export const GOOGLE_MY_BUSINESS_POSTS = 'google_my_business__posts';
export const LE_118K = 'le118000';
export const FACEBOOK = 'facebook';
export const HOODSPOT = 'hoodspot';
export const BING_GLOBAL = 'bing_global';
export const APPLE_BUSINESS_CONNECT = 'apple_business_connect__main';
export const APPLE_BUSINESS_CONNECT_PHOTOS = 'apple_business_connect__photos';

export const DIFFUSERS_TEXT: { [key: string]: string } = {
    justacote: 'main_diffuser',
    justacote__photos: 'photos',
    google_my_business: 'main_diffuser',
    google_my_business__photos: 'photos',
    google_my_business__phone: 'phone',
    google_my_business__attributes: 'attributes',
    google_my_business__address: 'address',
    google_my_business__services: 'services',
    google_my_business__food_menus: 'food_menus',
    [GOOGLE_MY_BUSINESS_POSTS]: 'Posts',
    [GOOGLE_MY_BUSINESS_MORE_HOURS]: 'more_hours',
    bing_global: 'bing_global',
    apple_business_connect__main: 'main_diffuser',
    apple_business_connect__photos: 'photos',
};

export const DIFFUSERS_BY_PUBLISHER = {
    justacote: [JUSTACOTE, JUSTACOTE_PHOTOS],
    google_my_business: [
        GOOGLE_MY_BUSINESS,
        GOOGLE_MY_BUSINESS_PHOTOS,
        GOOGLE_MY_BUSINESS_PHONE,
        GOOGLE_MY_BUSINESS_ATTRIBUTES,
        GOOGLE_MY_BUSINESS_ADDRESS,
        GOOGLE_MY_BUSINESS_SERVICES,
        GOOGLE_MY_BUSINESS_FOOD_MENUS,
        GOOGLE_MY_BUSINESS_MORE_HOURS,
        GOOGLE_MY_BUSINESS_POSTS,
    ],
    apple_business_connect: [APPLE_BUSINESS_CONNECT, APPLE_BUSINESS_CONNECT_PHOTOS],
};
