import { useState } from 'react';

import { Button, FontAwesomeIconsPartooUsed, Option, SingleSelect } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { UserInfosMultiSelect } from 'admin/content/tools/pages/MultiOrganizations/components/UserInfosMultiSelect';
import { UserInfosQueryKeys } from 'admin/content/tools/pages/MultiOrganizations/enums';
import { useMergeUserInfos } from 'admin/content/tools/pages/MultiOrganizations/hooks/useMergeUserInfos';

import { MergeAccountsConfirmationModal } from 'admin/content/tools/pages/MultiOrganizations/sections/MergedAccountsModals/modals/MergeAccountsConfirmationModal';

import {
    MainUserInfoTitle,
    MergeAccountsButton,
    MergedAccountsCreateFormContainer,
    MergedAccountsCreateFormTop,
    Title,
} from './MergedAccountsCreateForm.styled';

export const MergedAccountsCreateForm = () => {
    const { t } = useTranslation();
    const [selectedValues, setSelectedValues] = useState<Option[]>([]);
    const [mainUserInfo, setMainUserInfo] = useState<Option | undefined>(undefined);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [, setUserInfo] = useStateQueryParams(UserInfosQueryKeys.USER_INFO);

    const mergeUserInfos = useMergeUserInfos(
        () => {
            setUserInfo('');
            setShowConfirmationModal(false);
        },
        () => {
            setShowConfirmationModal(false);
        },
    );

    const setUserInfosSelectedValue = (value: Option[]) => {
        if (value.length < 2) {
            setMainUserInfo(undefined);
        } else if (!value.find(userInfo => userInfo.value === mainUserInfo?.value)) {
            setMainUserInfo(undefined);
        }
        setSelectedValues(value);
    };

    const onMergeAccounts = () => {
        mergeUserInfos.mutate({
            mainUserInfoId: Number(mainUserInfo?.value),
            body: {
                user_info_ids: selectedValues
                    .filter(userInfo => userInfo.value !== mainUserInfo?.value)
                    .map(userInfo => Number(userInfo.value)),
            },
        });
    };

    // @ts-ignore
    const errorMessage = mergeUserInfos.error?.response?.data?.errors?.json;

    return (
        <MergedAccountsCreateFormContainer>
            <MergedAccountsCreateFormTop>
                <Title variant="heading2">{t('admin:org_switcher_merge_page_title')}</Title>
                <UserInfosMultiSelect
                    selectedValue={selectedValues}
                    setSelectedValue={setUserInfosSelectedValue}
                    errorMessage={errorMessage}
                />
                {selectedValues.length >= 2 && (
                    <>
                        <MainUserInfoTitle variant="heading6">
                            {t('admin:org_switcher_new_user_info')}
                        </MainUserInfoTitle>

                        <SingleSelect
                            dataTrackId="main_user_info_select"
                            selectedValue={mainUserInfo}
                            onChange={setMainUserInfo}
                            sections={[
                                {
                                    options: selectedValues,
                                },
                            ]}
                            label={t('admin:org_switcher_new_user_info_dropdown')}
                            notice={t('admin:org_switcher_new_user_info_dropdown_description')}
                            required
                            icon={[FontAwesomeIconsPartooUsed.faUser]}
                        />
                    </>
                )}
            </MergedAccountsCreateFormTop>
            <MergeAccountsButton>
                <Button
                    dataTrackId="merge_accounts_button"
                    variant={mergeUserInfos.error ? 'danger' : 'primary'}
                    size="large"
                    full
                    disabled={!mainUserInfo}
                    onClick={() => setShowConfirmationModal(true)}
                    isLoading={mergeUserInfos.isLoading}
                >
                    {t('admin:org_switcher_merge_accounts_CTA')}
                </Button>
            </MergeAccountsButton>

            <MergeAccountsConfirmationModal
                onClose={() => setShowConfirmationModal(false)}
                opened={showConfirmationModal}
                onConfirm={onMergeAccounts}
            />
        </MergedAccountsCreateFormContainer>
    );
};
