import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';

import useLocalSelectionToReduxState from 'app/common/components/businessModal/hooks/privateHooks/useLocalSelectionToReduxState';
import {
    applyFilterByBusinessFilters,
    setBusinessFilters,
} from 'app/common/components/businessModalDeprecated/reducers';

import { FloatingFooter } from './Footer.styled';

type Props = {
    closeModal: () => void;
};

const Footer = ({ closeModal }: Props) => {
    const { t } = useTranslation();

    const { toggleSelectAll, selection, variation } = useBusinessModalContext();

    const dispatch = useDispatch();
    const reduxState = useLocalSelectionToReduxState();

    const handleSubmit = () => {
        dispatch(setBusinessFilters(reduxState));
        dispatch(applyFilterByBusinessFilters());
        closeModal();
    };

    const commonButtonProps = {
        shape: 'cube',
        size: 'large',
        full: true,
    } as const;

    return (
        <FloatingFooter>
            {(variation !== 'group-only' || !!selection.filters.length) && (
                <Button
                    {...commonButtonProps}
                    appearance="outlined"
                    onClick={toggleSelectAll}
                    dataTrackId={
                        selection.selectAll
                            ? 'business_modal__erase_button'
                            : 'business_modal__select_all_button'
                    }
                    variant="secondary"
                >
                    {t(
                        selection.selectAll
                            ? 'business_drawer_erase'
                            : 'business_drawer_select_all',
                    )}
                </Button>
            )}
            <Button
                {...commonButtonProps}
                appearance="contained"
                onClick={handleSubmit}
                variant="primary"
                dataTrackId="business_modal__confirm_button"
            >
                {t('business_drawer_confirm')}
            </Button>
        </FloatingFooter>
    );
};

export default Footer;
