import { RefObject } from 'react';

type Props = {
    active: boolean;
    ref?: RefObject<SVGSVGElement>;
};

export const PlaygroundIcon = ({ active, ref }: Props) => (
    <svg
        ref={ref}
        width="100%"
        height="100%"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
    >
        {active && (
            <>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0077 7.50016C14.0077 7.9604 14.3573 8.3335 14.7886 8.3335H24.7837C25.2149 8.3335 25.5645 7.9604 25.5645 7.50016C25.5645 7.03993 25.2149 6.66683 24.7837 6.66683H14.7886C14.3573 6.66683 14.0077 7.03993 14.0077 7.50016Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.844 7.28341C15.844 6.94175 16.1123 6.66675 16.4476 6.66675H16.456H22.6423C22.9692 6.66675 23.2458 6.94175 23.2458 7.28341V15.3751L26.056 21.1902H22.0676C21.7128 20.2767 20.8251 19.6291 19.7861 19.6291C18.7472 19.6291 17.8595 20.2767 17.5047 21.1902H13.0928L15.8021 15.5584L15.844 7.28341ZM22.1056 22.8568C21.7797 23.8252 20.8643 24.5226 19.7861 24.5226C18.7079 24.5226 17.7926 23.8252 17.4667 22.8568H12.6C12.4994 22.8568 12.4027 22.841 12.3126 22.8117L8.51768 30.7001C8.24106 31.2751 8.27459 31.9417 8.61827 32.4834C8.95357 33.0167 9.53197 33.3334 10.1607 33.3334H29.0046C29.6333 33.3334 30.2117 33.0167 30.547 32.4834C30.8907 31.9417 30.9242 31.2751 30.6476 30.6917L26.8614 22.8568H22.1056ZM16.4545 29.9367C15.1033 29.9367 14.0078 28.8413 14.0078 27.49C14.0078 26.1387 15.1033 25.0433 16.4545 25.0433C17.8058 25.0433 18.9013 26.1387 18.9013 27.49C18.9013 28.8413 17.8058 29.9367 16.4545 29.9367ZM15.5696 27.49C15.5696 27.9787 15.9658 28.375 16.4545 28.375C16.9433 28.375 17.3395 27.9787 17.3395 27.49C17.3395 27.0012 16.9433 26.605 16.4545 26.605C15.9658 26.605 15.5696 27.0012 15.5696 27.49ZM23.3334 25.8334C23.3334 26.2936 22.9603 26.6667 22.5 26.6667C22.0398 26.6667 21.6667 26.2936 21.6667 25.8334C21.6667 25.3732 22.0398 25.0001 22.5 25.0001C22.9603 25.0001 23.3334 25.3732 23.3334 25.8334ZM25 28.3334C25.4603 28.3334 25.8334 27.9603 25.8334 27.5001C25.8334 27.0398 25.4603 26.6667 25 26.6667C24.5398 26.6667 24.1667 27.0398 24.1667 27.5001C24.1667 27.9603 24.5398 28.3334 25 28.3334ZM22.5 29.1667C22.5 29.627 22.1269 30.0001 21.6667 30.0001C21.2065 30.0001 20.8334 29.627 20.8334 29.1667C20.8334 28.7065 21.2065 28.3334 21.6667 28.3334C22.1269 28.3334 22.5 28.7065 22.5 29.1667ZM18.9012 22.0759C18.9012 22.5646 19.2974 22.9608 19.7861 22.9608C20.2749 22.9608 20.6711 22.5646 20.6711 22.0759C20.6711 21.5871 20.2749 21.1909 19.7861 21.1909C19.2974 21.1909 18.9012 21.5871 18.9012 22.0759Z"
                />
            </>
        )}

        {!active && (
            <>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5636 7.67797C15.5636 7.11947 16.0122 6.66672 16.5654 6.66672H22.5244C23.0777 6.66672 23.5262 7.11947 23.5262 7.67797V15.5161L30.6104 30.1755C31.3151 31.6338 30.2631 33.3334 28.6557 33.3334H10.5108C8.90739 33.3334 7.85524 31.6415 8.55214 30.1838L15.5636 15.5178V7.67797ZM17.098 8.21556V15.5866C17.098 15.7745 17.056 15.9599 16.9751 16.1291L9.934 30.857C9.72877 31.2863 10.0386 31.7845 10.5108 31.7845H28.6557C29.1291 31.7845 29.4389 31.284 29.2313 30.8545L22.117 16.1328C22.0347 15.9623 21.9918 15.7752 21.9918 15.5856V8.21556H17.098Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0076 7.49999C14.0076 7.96023 14.3572 8.33333 14.7885 8.33333H24.7836C25.2149 8.33333 25.5645 7.96023 25.5645 7.49999C25.5645 7.03976 25.2149 6.66666 24.7836 6.66666H14.7885C14.3572 6.66666 14.0076 7.03976 14.0076 7.49999Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0077 27.4899C14.0077 28.8412 15.1031 29.9366 16.4544 29.9366C17.8057 29.9366 18.9011 28.8412 18.9011 27.4899C18.9011 26.1386 17.8057 25.0431 16.4544 25.0431C15.1031 25.0431 14.0077 26.1386 14.0077 27.4899ZM16.4544 28.3749C15.9657 28.3749 15.5694 27.9786 15.5694 27.4899C15.5694 27.0011 15.9657 26.6049 16.4544 26.6049C16.9432 26.6049 17.3394 27.0011 17.3394 27.4899C17.3394 27.9786 16.9432 28.3749 16.4544 28.3749Z"
                />
                <path d="M23.3333 25.8334C23.3333 26.2936 22.9602 26.6667 22.5 26.6667C22.0397 26.6667 21.6666 26.2936 21.6666 25.8334C21.6666 25.3731 22.0397 25 22.5 25C22.9602 25 23.3333 25.3731 23.3333 25.8334Z" />
                <path d="M25.8333 27.5C25.8333 27.9603 25.4602 28.3334 25 28.3334C24.5397 28.3334 24.1666 27.9603 24.1666 27.5C24.1666 27.0398 24.5397 26.6667 25 26.6667C25.4602 26.6667 25.8333 27.0398 25.8333 27.5Z" />
                <path d="M22.4999 29.1667C22.4999 29.6269 22.1269 30 21.6666 30C21.2064 30 20.8333 29.6269 20.8333 29.1667C20.8333 28.7065 21.2064 28.3334 21.6666 28.3334C22.1269 28.3334 22.4999 28.7065 22.4999 29.1667Z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.3393 22.0758C17.3393 23.4271 18.4348 24.5225 19.786 24.5225C21.1373 24.5225 22.2328 23.4271 22.2328 22.0758C22.2328 20.7245 21.1373 19.6291 19.786 19.6291C18.4348 19.6291 17.3393 20.7245 17.3393 22.0758ZM19.786 22.9608C19.2973 22.9608 18.9011 22.5646 18.9011 22.0758C18.9011 21.5871 19.2973 21.1908 19.786 21.1908C20.2748 21.1908 20.671 21.5871 20.671 22.0758C20.671 22.5646 20.2748 22.9608 19.786 22.9608Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.55 22.0234C12.55 22.4836 12.8996 22.8567 13.3309 22.8567H18.1202C18.5514 22.8567 18.9011 22.4836 18.9011 22.0234C18.9011 21.5631 18.5514 21.19 18.1202 21.19H13.3309C12.8996 21.19 12.55 21.5631 12.55 22.0234Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.6711 22.0234C20.6711 22.4836 20.9748 22.8567 21.3494 22.8567H25.5095C25.8841 22.8567 26.1877 22.4836 26.1877 22.0234C26.1877 21.5631 25.8841 21.19 25.5095 21.19H21.3494C20.9748 21.19 20.6711 21.5631 20.6711 22.0234Z"
                />
            </>
        )}
    </svg>
);
