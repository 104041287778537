import { FC, ReactElement, ReactNode } from 'react';

import { Text } from '@partoohub/ui';

import { IconWrapper, LinkWrapper, StyledTooltip } from './SidebarLink.styled';

type Props = {
    to: string;
    tooltip: string;
    icon: ReactNode;
    dataTrack: string;
};

const ExternalSidebarLink: FC<Props> = ({ to, tooltip, icon, dataTrack }) => (
    <LinkWrapper data-track={dataTrack} data-intercom-target={dataTrack}>
        <StyledTooltip
            text={<Text variant="bodySBold">{tooltip}</Text>}
            placement="right"
            variant="default"
        >
            <a href={to} target="_blank" className="link" rel="noreferrer">
                <IconWrapper className="icon_wrapper">{icon as ReactElement}</IconWrapper>
            </a>
        </StyledTooltip>
    </LinkWrapper>
);

export default ExternalSidebarLink;
