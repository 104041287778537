import { MESSAGING_PRIVACY_POLICY_PATH } from 'app/routing/routeIds';

type IntercomOptions = {
    hide_default_launcher: boolean;
};

class IntercomWrapper {
    static disabled = false;

    /**
     * Intercom Options for update
     * Type is not exhaustive, add default values here
     * if you need to modify Intercom behavior on
     * specific conditions.
     */
    static defaultIntercomOptions: IntercomOptions = {
        hide_default_launcher: false,
    };

    /**
     * Intercom will not be displayed for these pages
     */
    static hideIntercomOnPages = [MESSAGING_PRIVACY_POLICY_PATH];

    static shutdown(disabled? = true): void {
        // @ts-ignore
        if (window.Intercom) {
            this.disabled = disabled;
            // @ts-ignore
            window.Intercom('shutdown');
        }
    }

    static boot(): void {
        // @ts-ignore
        if (window.Intercom && !this.disabled) {
            // @ts-ignore
            window.Intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: INTERCOM_APP_ID,
                language_override: null,
            });
        }
    }

    static intercomShouldBeHiddenOnPage(pathname: string) {
        /**
         * Verifies that Intercom should be hidden on a page
         * matching regex groups. This allows to match REST routes
         * like /resource/:id/resource2/:id2/.../action
         *
         * We iterate through each subPath in pathname and the path to test.
         * Then, we check that pathname subpath match the page's subpath we test.
         * If we find a rest param in the page'subpath, we pass the subPath associated in pathname.
         * We then substract the params we pass until the string is empty.
         */
        const rePathParam = /(?<pathParam>\/[\w-]+)/;
        const reRestParam = /(?<restParam>\/:\w+)/;
        for (const page of this.hideIntercomOnPages) {
            let pathnameCpy = pathname;
            let continueCheck = true;
            let currentPage = page;
            while (continueCheck) {
                const matchPathParamOnPage = rePathParam.exec(currentPage);
                const matchPathParamOnPathname = rePathParam.exec(pathnameCpy);
                const matchRestParamOnPage = reRestParam.exec(currentPage);
                // We get first path params and check if they match
                if (
                    matchPathParamOnPage?.groups?.pathParam &&
                    matchPathParamOnPathname?.groups?.pathParam &&
                    matchPathParamOnPage.groups.pathParam ===
                        matchPathParamOnPathname.groups.pathParam
                ) {
                    // If path params match, we subtract them and continue iteration
                    currentPage = currentPage.substring(
                        matchPathParamOnPage.groups.pathParam.length,
                    );
                    pathnameCpy = pathnameCpy.substring(
                        matchPathParamOnPathname.groups.pathParam.length,
                    );
                } else if (
                    matchRestParamOnPage?.groups?.restParam &&
                    matchPathParamOnPathname?.groups?.pathParam
                ) {
                    // if next param on pathname is a rest param (variable), we skip it
                    currentPage = currentPage.substring(
                        matchRestParamOnPage.groups.restParam.length,
                    );
                    pathnameCpy = pathnameCpy.substring(
                        matchPathParamOnPathname.groups.pathParam.length,
                    );
                } else {
                    // If no conditions match, pathname does not match
                    continueCheck = false;
                }
            }
            if (!pathnameCpy.length && !currentPage.length) {
                // If we have subtracted all path params and rest params, route matches
                return true;
            }
        }

        return false;
    }

    static update(options?: { pathname?: string }): void {
        // @ts-ignore
        if (window.Intercom && !this.disabled) {
            let intercomOptions = this.defaultIntercomOptions;
            if (
                (options?.pathname && this.intercomShouldBeHiddenOnPage(options.pathname)) ||
                navigator.userAgent.includes('PartooMobileApp')
            ) {
                intercomOptions = {
                    ...intercomOptions,
                    hide_default_launcher: true,
                };
            }

            // @ts-ignore
            window.Intercom('update', intercomOptions);
        }
    }

    static show() {
        // @ts-ignore
        if (window.Intercom && !this.disabled) {
            // @ts-ignore
            window.Intercom('show');
        }
    }

    static updateUserHash(userHash: string, userId: string, pathname?: string) {
        const hide = !pathname || this.intercomShouldBeHiddenOnPage(pathname);
        // @ts-ignore
        if (window.Intercom && !this.disabled) {
            // @ts-ignore
            window.Intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: INTERCOM_APP_ID,
                user_hash: userHash,
                user_id: userId,
                hide_default_launcher: hide,
                language_override: null,
            });
        }
    }
}

export default IntercomWrapper;
