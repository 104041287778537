import {
    CALLBACK_CALLED,
    CallbackCalledMessage,
    GivePageHeightMessage,
    IFRAME_JS_LOADED,
    IFrameJSHasLoadedMessage,
    PAGE_CHANGED,
    PAGE_SIZE_GIVEN,
    PageHasChangedMessage,
    STYLE_OVERRIDDEN,
    StyleIsOverriddenMessage,
    USER_IS_LOGGED_IN,
    USER_IS_LOGGED_OUT,
    UserIsLoggedInMessage,
    UserIsLoggedOutMessage,
} from './types';

export const iFrameJSHasLoaded = (): IFrameJSHasLoadedMessage => ({
    type: IFRAME_JS_LOADED,
});

export const styleIsOverridden = (): StyleIsOverriddenMessage => ({
    type: STYLE_OVERRIDDEN,
});

export const givePageHeight = (height: number): GivePageHeightMessage => ({
    type: PAGE_SIZE_GIVEN,
    height,
});

export const userIsLoggedIn = (): UserIsLoggedInMessage => ({
    type: USER_IS_LOGGED_IN,
});

export const userIsLoggedOut = (): UserIsLoggedOutMessage => ({
    type: USER_IS_LOGGED_OUT,
});

export const appPageHasChanged = (url: string): PageHasChangedMessage => ({
    type: PAGE_CHANGED,
    url,
});

export const callbackCalled = (
    action: string,
    inputs?: any,
): CallbackCalledMessage => ({
    type: CALLBACK_CALLED,
    action,
    inputs,
});
