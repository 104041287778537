import { Button, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { IS_BELOW_MOBILE, useDeviceType } from 'app/hooks';

import {
    USER_INFO_CREATE,
    UserInfosQueryKeys,
} from 'admin/content/tools/pages/MultiOrganizations/enums';

export const MergedAccountsListHeader = () => {
    const { t } = useTranslation();
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_MOBILE);
    const [, setUserInfos] = useStateQueryParams(UserInfosQueryKeys.USER_INFO);

    return (
        <Stack
            gap="8px"
            direction={isBelowProvidedDevices ? 'column' : 'row'}
            alignItems={isBelowProvidedDevices ? 'flex-start' : 'center'}
            justifyContent="space-between"
            style={{ flex: 1 }}
        >
            <Stack gap="0">
                <Stack direction="row" gap="4px" alignItems="center">
                    <Text variant="heading6" as="span">
                        {t('admin:org_switcher_list_merge_title')}
                    </Text>
                </Stack>

                <Text as="span" color="secondary">
                    {t('admin:org_switcher_list_merge_description')}
                </Text>
            </Stack>
            <Button
                dataTrackId="not_found__button"
                onClick={() => setUserInfos(USER_INFO_CREATE)}
                variant="primary"
                shape="cube"
            >
                {t('admin:org_switcher_merge_accounts_CTA')}
            </Button>
        </Stack>
    );
};
