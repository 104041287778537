import { ApiResourceBaseClass } from '@partoohub/api-client';

import { BulkExportV1Filter } from 'admin/api/types';

const RESOURCE_URI = 'old_extractor';

export class BulkExportV1ApiCall extends ApiResourceBaseClass {
    async bulkExportV1(filter: BulkExportV1Filter): Promise<any> {
        const { data } = await this.post(`${RESOURCE_URI}`, {
            ...filter,
        });
        return data;
    }
}
