import React from 'react';

type Props = {
    active: boolean;
};

const CategoriesIcon = React.forwardRef<SVGSVGElement, Props>(
    ({ active }, ref) => (
        <svg
            ref={ref}
            width="100%"
            height="100%"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
        >
            {active && (
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.6175 10.0758C24.1433 8.43083 25.7458 7.36333 27.4817 7.51583C29.2775 7.67333 30.7083 9.10416 30.8658 10.9C30.8892 11.1633 30.8758 11.4208 30.845 11.675C32.6292 13.8192 31.1917 21.225 29.8083 22.6058V30.1967C29.8083 31.4575 28.7692 32.4983 27.5083 32.4983H18.1575C17.39 32.4983 16.8233 32.245 16.3675 31.7417L8.92917 23.5483C8.07917 22.6117 8.1525 21.1317 9.09 20.2817L18.9317 11.36C20.0276 10.3378 22.6069 10.1496 23.5162 10.0833C23.5527 10.0806 23.5866 10.0781 23.6175 10.0758ZM29.1142 10.6117C28.8708 9.8275 28.1842 9.25167 27.335 9.1775C26.4583 9.10167 25.7892 9.5675 25.4492 10.0775C27.4067 10.1125 28.8533 10.5308 29.1142 10.6117ZM28.1408 30.1967V24.1842L20.8067 30.8317H27.5075C27.8742 30.8317 28.1408 30.5625 28.1408 30.1967ZM13.2725 20.6C13.0608 20.5966 12.8558 20.6741 12.6992 20.8166C12.3592 21.1241 12.3317 21.6483 12.6375 21.99L15.0317 24.6416C15.34 24.9858 15.8692 25.0133 16.2117 24.7033C16.5508 24.3941 16.5758 23.8683 16.2667 23.5283L13.8742 20.8766C13.7217 20.705 13.5033 20.605 13.2725 20.6Z"
                />
            )}

            {!active && (
                <path d="M27.4816 7.51583C25.7457 7.36333 24.1432 8.43083 23.6174 10.0758C22.8166 10.1358 20.0716 10.2967 18.9316 11.36L9.08991 20.2817C8.15241 21.1317 8.07907 22.6117 8.92907 23.5483L16.3674 31.7417C16.8232 32.245 17.3899 32.4983 18.1574 32.4983H27.5082C28.7691 32.4983 29.8082 31.4575 29.8082 30.1967V22.6058C31.1916 21.225 32.6291 13.8192 30.8449 11.675C30.8757 11.4208 30.8891 11.1633 30.8657 10.9C30.7082 9.10416 29.2774 7.67333 27.4816 7.51583ZM27.3349 9.1775C28.1841 9.25167 28.8707 9.8275 29.1141 10.6117C28.8532 10.5308 27.4066 10.1125 25.4491 10.0775C25.7891 9.5675 26.4582 9.10167 27.3349 9.1775ZM28.9007 12.3142C28.6516 12.705 28.2491 13.0467 27.7924 13.1733C27.6407 13.1108 27.4791 13.0792 27.3157 13.0792C26.6257 13.0792 26.0657 13.6392 26.0657 14.3292C26.0657 15.0192 26.6257 15.5792 27.3157 15.5792C27.8707 15.5792 28.3524 15.2267 28.5091 14.6933C29.0441 14.4825 29.5232 14.155 29.8974 13.7458C30.3666 17.06 29.0999 21.1042 28.3499 21.7442L18.5074 30.67C18.2332 30.9192 17.8466 30.8958 17.5974 30.6208L10.1657 22.4275C9.91657 22.1533 9.93407 21.765 10.2082 21.5158L20.0524 12.59C21.4207 11.4575 27.1949 11.5192 28.9007 12.3142ZM13.2724 20.6C13.0607 20.5967 12.8557 20.6742 12.6991 20.8167C12.3591 21.1242 12.3316 21.6483 12.6374 21.99L15.0316 24.6417C15.3399 24.9858 15.8691 25.0133 16.2116 24.7033C16.5507 24.3942 16.5757 23.8683 16.2666 23.5283L13.8741 20.8767C13.7216 20.705 13.5032 20.605 13.2724 20.6ZM28.1407 24.1842V30.1967C28.1407 30.5625 27.8741 30.8317 27.5074 30.8317H20.8066L28.1407 24.1842Z" />
            )}
        </svg>
    ),
);

export default CategoriesIcon;
