import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const EditCategoryContainer = styled.div`
    margin-left: -16px;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    background: white;

    width: 100%;
    overflow-y: scroll;
    box-shadow:
        -8px 0px 10px rgb(0 0 0 / 5%),
        -1px 0px 0px rgb(0 0 0 / 4%);
`;

export const EditCategoryTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 32px;
`;

export const GmbImageBox = styled.div`
    width: 56px;
    height: 56px;
`;

export const EditCategoryNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: 16px;
`;
