import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import Loader from 'app/common/designSystem/components/atoms/svg/Loader';
import { useDeviceType } from 'app/hooks';

import { LoadingViewContainer } from './LoadingView.container';

const LoadingView = () => {
    const { t } = useTranslation();
    const { device } = useDeviceType();
    if (device === 'mobileApp') return <></>;

    return (
        <LoadingViewContainer>
            <div className="loading">
                <div className="loader-container">
                    <div className="opacity-setter" />
                    <div className="loader-elements">
                        <Text variant="heading2">{t('loading')}</Text>
                        <Loader />
                    </div>
                </div>
            </div>
        </LoadingViewContainer>
    );
};
export default LoadingView;
