import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleSelect } from 'admin/common/components/inputs/SingleSelect';

export enum BulkMenuActionEnum {
    PHOTO = 'photo',
    DETAILS = 'details',
    BOTH = 'both',
    DELETE = 'delete',
}
type Props = {
    selectedAction: string | null;
    onChange: (value: string | null) => void;
};
export const ActionSelect: React.FC<Props> = ({ selectedAction, onChange }) => {
    const { t } = useTranslation();

    const options = useMemo(
        () => [
            {
                value: BulkMenuActionEnum.PHOTO,
                name: BulkMenuActionEnum.PHOTO,
                label: t('admin:bulk_menu__add_photo'),
            },
            {
                value: BulkMenuActionEnum.DETAILS,
                name: BulkMenuActionEnum.DETAILS,
                label: t('admin:bulk_menu_add_menu'),
            },
            {
                value: BulkMenuActionEnum.BOTH,
                name: BulkMenuActionEnum.BOTH,
                label: t('admin:bulk_menu__add_both'),
            },
            {
                value: BulkMenuActionEnum.DELETE,
                name: BulkMenuActionEnum.DELETE,
                label: t('admin:bulk_menu__delete_all'),
            },
        ],
        [],
    );

    return (
        <SingleSelect
            dataTrackId="page_bulk_food_menus__select_action"
            sections={[{ options }]}
            selectedValue={selectedAction}
            onChange={onChange}
            placeholder={t('admin:bulk_menu__select_action')}
            disableReset
            required
        />
    );
};
