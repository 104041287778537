import React from 'react';

import InfiniteScroll from 'react-infinite-scroller';

import { DailyReportLog } from 'app/api/v2/api_calls/logsApiCalls';

import DailyReportRow from './DailyReportRow';

export type Props = {
    logs: DailyReportLog[];
    isFetching: boolean;
    fetchNextLogs: () => void;
    allLogsFetched: boolean;
};

export const DailyReportTable: React.FC<Props> = ({
    logs,
    isFetching,
    fetchNextLogs,
    allLogsFetched,
}) => {
    return (
        <InfiniteScroll
            loadMore={() => {
                if (!isFetching) fetchNextLogs();
            }}
            hasMore={!allLogsFetched}
            useWindow={false}
            threshold={50}
            getScrollParent={() => document.getElementById('page_wrapper')}
        >
            <div>
                {logs.map((log, index) => {
                    return <DailyReportRow key={index} log={log} />;
                })}
            </div>
        </InfiniteScroll>
    );
};
