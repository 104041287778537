import React, { useState } from 'react';

import { Button, Drawer, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { DiffusionCleanerStatusData } from 'admin/common/api/calls/diffusionCleanerApiCalls';

import { DrawerHeading, DrawerWrapper, ResponseContent } from './UnknownErrorButton.styled';

type Props = {
    business: DiffusionCleanerStatusData;
};

export const UnknownErrorButton = ({ business }: Props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <>
            <Drawer
                isOpen={open}
                onHide={() => setOpen(false)}
                dataTrackId="admin_diffusion_cleaner__unknown_error__drawer"
            >
                <DrawerWrapper>
                    <DrawerHeading>
                        <Text variant="heading5">
                            {t('admin:page_diffusion_cleaner__unknown_error__heading')}
                        </Text>
                    </DrawerHeading>
                    {
                        <Text variant="bodyLRegular">
                            {business.call_log_ids.map(call_log_id => (
                                <ResponseContent call_log_id={call_log_id} />
                            ))}
                        </Text>
                    }
                </DrawerWrapper>
            </Drawer>
            <Button
                shape="rounded"
                size="medium"
                variant="theme"
                dataTrackId={'admin_diffusion_cleaner__business_button__unknown_error'}
                onClick={() => setOpen(!open)}
            >
                <Text variant="bodyMBold" color="white">
                    {t('admin:page_diffusion_cleaner__business_button__unknown_error')}
                </Text>
            </Button>
        </>
    );
};
