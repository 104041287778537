// General
export const ANALYTICS = 'analytics';
export const BUSINESSES = 'businesses';
export const GROUPS = 'groups';
export const PUBLISHERS = 'publishers';
export const PROVIDERS = 'providers';
export const AVAILABLE_ROLES = 'availableRoles';
export const USERS = 'users';
export const ME = 'me';
export const AVAILABLE_LANGUAGES = 'availableLanguages';
export const COUNTRIES = 'countries';
export const REGIONS = 'regions';

// Bulk
export const INTEGRATIONS = 'integrations';
export const ORG_CATEGORIES = 'orgCategories';
export const ORG_COUNTRIES = 'orgCountries';
export const SERVICES_SUGGESTIONS = 'servicesSuggestions';

// Categories
export const CATEGORIES = 'categories';
export const CATEGORIES_STATS = 'categoriesStats';

// Logs
export const DAILY_REPORTS = 'dailyReports';
export const DAILY_REPORT_DATA = 'dailyReportData';
export const BULK_IMPORT_LOG = 'bulkImportLog';

// Tools
export const DIFFUSION_CLEANER_COUNT = 'diffusionCleanerCount';
export const DIFFUSION_CLEANER_STATUS = 'diffusionCleanerStatus';
export const DIFFUSION_CLEANER_MISSING_CATEGORIES = 'diffusionCleanerMissingCategories';
export const DIFFUSION_CLEANER_READABLE_ERROR = 'diffusionCleanerReadableError';
export const DIFFUSION_CLEANER_UNKNOWN_ERROR = 'diffusionCleanerUnknownError';

export const DUPLICATE_PROSPECT_SEARCH = 'duplicate_prospect_search';
export const DUPLICATE_BUSINESS_SEARCH = 'duplicate_business_search';
export const DUPLICATE_BUSINESS_SEARCH_PLACE_TYPES = 'duplicate_business_search_place_types';

export const FEEDBACK_FORM = 'feedbackForm';
export const FEEDBACK_LOOKER_STUDIO = 'feedbackLookerStudio';
export const FEEDBACK_REPLY_SUGGESTION_PROMPT = 'feedbackReplySuggestionPrompt';
export const FEEDBACK_REPLY_SUGGESTION_GENERIC_PROMPT = 'feedbackReplySuggestionGenericPrompt';

export const COMPETITIVE_BENCHMARK_ORGS = 'competitiveBenchmarkOrgs';
export const COMPETITIVE_BENCHMARK_KEYWORDS = 'competitiveBenchmarkKeywords';

export const MULTI_ORGANIZATIONS = 'multiOrganizations';

// Playground
export const GOOGLE_ACCOUNTS = 'google-accounts';
export const GOOGLE_ADMIN_MANAGEMENT = 'google-admin-management';
export const BUSINESS_LOCATIONS = 'businessLocation';

// Orgs
export const ORGANIZATIONS = 'organizations';
