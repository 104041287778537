import { push } from '@lagunovsky/redux-react-router';
import { map } from 'lodash-es';
import { Store } from 'redux';

import IntercomWrapper from 'app/common/services/intercomWrapper';
import { hideableComponentsNames, setHideableComponentDisplay } from 'app/SDKBridge/reducers';

import {
    GET_PAGE_SIZE,
    JsApiMessage,
    OVERRIDE_ACTION,
    OVERRIDE_STYLE,
    REJECT_ACTION,
    SET_OPTIONS,
    SET_UPDATE_PAGE,
} from 'sdk/messages/types';

import {
    appPageHasChanged,
    callbackCalled,
    givePageHeight,
    iFrameJSHasLoaded,
    styleIsOverridden,
    userIsLoggedIn,
    userIsLoggedOut,
} from './messages/creators';
import { BridgeMessage } from './messages/types';

const OPEN_BUSINESS_EVENT = 'open_business';
const SUBSCRIBE_EVENT = 'subscribe';
const BUSINESS_CREATED_EVENT = 'business_created';
const BUSINESS_INFO_UPDATED_EVENT = 'business_infos_updated';
const BUSINESS_LINKS_UPDATED_EVENT = 'business_links_updated';
const BUSINESS_DESCRIPTION_UPDATED_EVENT = 'business_description_updated';
const BUSINESS_ATTRIBUTES_UPDATED_EVENT = 'business_attributes_updated';
const BUSINESS_CONTACT_UPDATED_EVENT = 'business_contact_updated';
const BUSINESS_OPEN_HOURS_UPDATED_EVENT = 'business_open_hours_updated';
const BUSINESS_ADVANCED_SETTINGS_UPDATED_EVENT = 'business_advanced_settings_updated';
const NO_BUSINESS_CLICK_EVENT = 'no_business_click';
const NO_ELIGIBLE_BUSINESS_CLICK_EVENT = 'no_eligible_business_click';
const PM_GO_TO_EDIT_CLICK_EVENT = 'pm_view_go_to_edit_click';
const PM_GO_TO_PARTNER_CONNECTION_CLICK_EVENT = 'pm_view_go_to_partner_connection_click';
const ERROR_EVENT = 'error';

const BUSINESS_MORE_HOURS_UPDATED_EVENT = 'business_more_hours_updated';
const BUSINESS_SPECIFIC_HOURS_UPDATED_EVENT = 'business_specific_hours_updated';
const BUSINESS_ADDRESS_UPDATED_EVENT = 'business_address_updated';
const BUSINESS_LOGO_UPDATED_EVENT = 'business_logo_updated';
const BUSINESS_COVER_UPDATED_EVENT = 'business_cover_updated';
const BUSINESS_PHOTOS_UPDATED_EVENT = 'business_photos_updated';
const BUSINESS_CATEGORIES_UPDATED_EVENT = 'business_categories_updated';
const BUSINESS_MENU_UPDATED_EVENT = 'business_menu_updated';
const BUSINESS_CUSTOM_FIELDS_UPDATED_EVENT = 'business_custom_fields_updated';
// Services

export const PARTOO_APP_EVENT_IDS = {
    OPEN_BUSINESS_EVENT,
    SUBSCRIBE_EVENT,
    BUSINESS_CREATED_EVENT,
    NO_BUSINESS_CLICK_EVENT,
    NO_ELIGIBLE_BUSINESS_CLICK_EVENT,
    PM_GO_TO_EDIT_CLICK_EVENT,
    PM_GO_TO_PARTNER_CONNECTION_CLICK_EVENT,
    ERROR_EVENT,
    // Business edit
    BUSINESS_INFO_UPDATED_EVENT, // Deprecated
    BUSINESS_DESCRIPTION_UPDATED_EVENT,
    BUSINESS_ATTRIBUTES_UPDATED_EVENT,
    BUSINESS_CONTACT_UPDATED_EVENT,
    BUSINESS_OPEN_HOURS_UPDATED_EVENT,
    BUSINESS_ADVANCED_SETTINGS_UPDATED_EVENT,
    BUSINESS_LINKS_UPDATED_EVENT,
    BUSINESS_MORE_HOURS_UPDATED_EVENT,
    BUSINESS_SPECIFIC_HOURS_UPDATED_EVENT,
    BUSINESS_ADDRESS_UPDATED_EVENT,
    BUSINESS_LOGO_UPDATED_EVENT,
    BUSINESS_COVER_UPDATED_EVENT,
    BUSINESS_PHOTOS_UPDATED_EVENT,
    BUSINESS_CATEGORIES_UPDATED_EVENT,
    BUSINESS_MENU_UPDATED_EVENT,
    BUSINESS_CUSTOM_FIELDS_UPDATED_EVENT,
};

export type CallbacksObject = {
    open_business: boolean;
    subscribe: boolean;
    business_created: boolean;
    no_business_click: boolean;
    no_eligible_business_click: boolean;
    pm_view_go_to_edit_click: boolean;
    pm_view_go_to_partner_connection_click: boolean;
    business_infos_updated: boolean;
    business_description_updated: boolean;
    business_attributes_updated: boolean;
    business_contact_updated: boolean;
    business_open_hours_updated: boolean;
    business_advanced_settings_updated: boolean;
    business_links_updated: boolean;
    business_more_hours_updated;
    business_specific_hours_updated;
    business_address_updated;
    business_logo_updated;
    business_cover_updated;
    business_photos_updated;
    business_categories_updated;
    business_menu_updated;
    business_custom_fields_updated;

    error: boolean;
};

const CALLBACKS: CallbacksObject = {
    [OPEN_BUSINESS_EVENT]: false,
    [SUBSCRIBE_EVENT]: false,
    [BUSINESS_CREATED_EVENT]: false,
    [NO_BUSINESS_CLICK_EVENT]: false,
    [NO_ELIGIBLE_BUSINESS_CLICK_EVENT]: false,
    [PM_GO_TO_EDIT_CLICK_EVENT]: false,
    [PM_GO_TO_PARTNER_CONNECTION_CLICK_EVENT]: false,
    [BUSINESS_INFO_UPDATED_EVENT]: false,
    [BUSINESS_DESCRIPTION_UPDATED_EVENT]: false,
    [BUSINESS_ATTRIBUTES_UPDATED_EVENT]: false,
    [BUSINESS_CONTACT_UPDATED_EVENT]: false,
    [BUSINESS_OPEN_HOURS_UPDATED_EVENT]: false,
    [BUSINESS_ADVANCED_SETTINGS_UPDATED_EVENT]: false,
    [BUSINESS_LINKS_UPDATED_EVENT]: false,
    [BUSINESS_MORE_HOURS_UPDATED_EVENT]: false,
    [BUSINESS_SPECIFIC_HOURS_UPDATED_EVENT]: false,
    [BUSINESS_ADDRESS_UPDATED_EVENT]: false,
    [BUSINESS_LOGO_UPDATED_EVENT]: false,
    [BUSINESS_COVER_UPDATED_EVENT]: false,
    [BUSINESS_PHOTOS_UPDATED_EVENT]: false,
    [BUSINESS_CATEGORIES_UPDATED_EVENT]: false,
    [BUSINESS_MENU_UPDATED_EVENT]: false,
    [BUSINESS_CUSTOM_FIELDS_UPDATED_EVENT]: false,

    [ERROR_EVENT]: false,
};

type AppCanReceiveSeedDataMessage = {
    type: 'REACT_CAN_RECEIVE_SEED_DATA';
};

type ReceivedMessage = {
    data: JsApiMessage | AppCanReceiveSeedDataMessage;
};

const getDocumentHeight = (): number => (document.body && document.body.scrollHeight) || 0;

class SDKBridge {
    // Callbacks state
    _callbacks: CallbacksObject = { ...CALLBACKS };

    // Visual state
    businessFoundBannerText = '';

    // Message State
    messageTarget = '*';

    // App store
    store: Store<any> | null = null;

    constructor(messageTarget?: string) {
        this.messageTarget = messageTarget || this.messageTarget;
        // Register the listener that will react to JS message from the SDK
        window.addEventListener('message', message => this._handleMessage(message));
    }

    // PUBLIC methods -------------------------------------------------------------

    /**
     * Perform 2 actions:
     * 1. Register Redux store to witch the action are going to be dispatched.
     * 2. Once the store is registered, send a message to the SDK indicating
     *    it is ready to receive JS messages.
     *
     * @param store {Store}
     */
    registerStore = (store: Store<any>): void => {
        this.store = store;

        this._postMessageToParent(iFrameJSHasLoaded());
    };

    /**
     * If a callback has been registered for given `eventId`,
     * post a JS message to the SDK that includes the `eventId`
     * & the `inputs` for the callback.
     *
     * @param eventId {string} Event ID
     * @param inputs {any} Inputs for the callback
     * @returns {boolean} Return true if the callback has been called, false otherwise
     */
    onEventOccurred = (eventId: string, inputs?: any): boolean => {
        const shouldCallCb = this._checkCallbackShouldBeCalled(eventId);

        if (shouldCallCb) {
            this._postMessageToParent(callbackCalled(eventId, inputs));
        }

        return shouldCallCb;
    };

    sendUserIsLoggedOut = () => this._postMessageToParent(userIsLoggedOut());

    sendUserIsLoggedIn = () => this._postMessageToParent(userIsLoggedIn());

    /**
     * Send a JS message to the SDK to indicates that the App url has changed
     *
     * @param url {string} New App url
     */
    sendPageChanged = (url: string) => this._postMessageToParent(appPageHasChanged(url));

    /**
     * Take a function `f` and return a wrapped function that is linked to an `eventId`.
     * The wrapped function has the same signature as the initial function `f`.
     *
     * If a callback has been defined for this `eventId` and the wrapped function
     * is called, here what happens:
     *   1. A message is posted to SDK, indicating the callback should be triggered
     *   2. If `blocking` is set to `false`, the initial function `f` is called and its
     *      result is returned.
     *
     * @param f {Function}              The function we want to link to the `eventId`
     * @param eventId {string}          The event ID
     * @param blocking {boolean}        By default set to `false`. Indicates if the function `f`
     *                                  should be called after the callback has been triggered.
     * @param inputFormatter {Function} Rather than directly sending the raw inputs to the callback,
     *                                  we might want to format the inputs before sending them to
     *                                  the callback. `inputFormatter` is function that takes the
     *                                  inputs and format them into the format expected by the
     *                                  callback.
     *
     * @returns {function(...[*]): void}
     */
    linkFunctionToEvent =
        (
            f: (...args: Array<any>) => any,
            eventId: string,
            blocking = false,
            inputFormatter?: (...args: Array<any>) => any,
        ): ((...args: Array<any>) => any) =>
        (...inputs) => {
            const formattedInputs = inputFormatter ? inputFormatter(...inputs) : inputs;

            const callBackHasBeenCalled = this.onEventOccurred(eventId, formattedInputs);
            const shouldCallFunction = !callBackHasBeenCalled || !blocking;

            if (shouldCallFunction) {
                return f(...inputs);
            }

            return null;
        };

    /**
     * Check if a callback has been registered for the `eventId`
     *
     * @param eventId {string} Event Id
     * @returns {boolean}
     * @private
     */
    _checkCallbackShouldBeCalled = (eventId: string): boolean => !!this._callbacks[eventId];

    /**
     * Handle JS message coming from the JS SDK.
     *
     * @param data {JsApiMessage|AppCanReceiveSeedDataMessage} Data of JS Message
     * @returns {null|void}
     * @private
     */
    _handleMessage = ({ data }: ReceivedMessage): null | void => {
        switch (data.type) {
            case OVERRIDE_STYLE:
                return this._overrideStyle();

            case OVERRIDE_ACTION:
                // register a callback
                this._callbacks[data.action] = true;
                return null;

            case REJECT_ACTION:
                // unregister a callback
                this._callbacks[data.action] = false;
                return null;

            case GET_PAGE_SIZE:
                // send page size to JS SDK
                return this._postMessageToParent(givePageHeight(getDocumentHeight()));

            case SET_UPDATE_PAGE:
                return this._dispatchActionToStore(push(data.nextUrl));

            case SET_OPTIONS:
                // APP options
                this._dispatchActionToStore(
                    setHideableComponentDisplay(
                        hideableComponentsNames.PRESENCE_DOWNLOAD_BUTTON,
                        data.options.displayPresenceManagementDownload,
                    ),
                );

                this._dispatchActionToStore(
                    setHideableComponentDisplay(
                        hideableComponentsNames.KNOWLEDGE_ADD_BUTTON,
                        data.options.displayAddButton,
                    ),
                );

                this._dispatchActionToStore(
                    setHideableComponentDisplay(
                        hideableComponentsNames.SHOW_BUSINESS_MODAL_FILTERS_BUTTON,
                        data.options.displayBusinessModalFilters,
                    ),
                );

                this._dispatchActionToStore(
                    setHideableComponentDisplay(
                        hideableComponentsNames.KNOWLEDGE_EDIT_SELECT_BUSINESS_BUTTON,
                        data.options.displayEditBusinessSelector,
                    ),
                );

                this._dispatchActionToStore(
                    setHideableComponentDisplay(
                        hideableComponentsNames.VERIFICATION_REQUIRED_BUTTON,
                        data.options.displayVerificationRequiredButton,
                    ),
                );

                // Intercom options
                if (!data.options.displayIntercom) {
                    this._disableIntercom();
                }

                if (data.options.businessFoundBannerText) {
                    this.businessFoundBannerText = data.options.businessFoundBannerText;
                }

                return null;

            default:
                return null;
        }
    };

    _overrideStyle() {
        map(
            [
                // @ts-ignore
                ...document.getElementsByClassName('alert'),
                // @ts-ignore
                ...document.getElementsByClassName('notif'),
            ],
            (div: HTMLDivElement) => {
                div.style.marginLeft = '0';
            },
        );
        const mainContent = document.getElementById('main-content');

        if (mainContent) {
            mainContent.style.marginLeft = '0';
        }

        this._postMessageToParent(styleIsOverridden());
    }

    /**
     * Dispatch a Redux action to the registered store
     *
     * @param action {Object} Redux action
     * @private
     */
    _dispatchActionToStore = (action: Record<string, any>): void => {
        if (this.store) {
            // @ts-ignore
            this.store.dispatch(action);
        }
    };

    /**
     * Disable intercom in App
     *
     * @private
     */
    _disableIntercom = (): void => {
        IntercomWrapper.shutdown();
        this._dispatchActionToStore(
            setHideableComponentDisplay(hideableComponentsNames.SHOW_INTERCOM_BUTTON, false),
        );
    };

    /**
     * Post a JS message to SDK
     *
     * @param message {BridgeMessage}
     * @private
     */
    _postMessageToParent = (message: BridgeMessage): void => {
        window.parent.postMessage(message, this.messageTarget);
    };
}

export default SDKBridge;
