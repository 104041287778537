import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FooterContainer } from './RemapConfirmationModal.styled';

type RemapConfirmationModalProps = {
    warningTextParams: {
        impactedBusinesses: string;
        categoryGmbId: string;
        selectedCategoryGmbId: string;
    };
    onSaveClick: () => void;
    onCancelClick: () => void;
};

export const RemapConfirmationModal = ({
    warningTextParams,
    onSaveClick,
    onCancelClick,
}: RemapConfirmationModalProps) => {
    const { t } = useTranslation();
    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Warning,
    };
    return (
        <Dialog
            dataTrackId=""
            header={
                <div style={{ paddingTop: '24px' }}>
                    <Illustration
                        dataTrackId="remap_confirmation_modal__warning"
                        illustration={illustrationElement}
                        width="320"
                        height="140"
                    />
                </div>
            }
            subTitle={t('admin_categories_obsolete_confirmationpopin_warning_plural', {
                count: parseInt(warningTextParams.impactedBusinesses),
                ...warningTextParams,
            })}
            title={t('admin_categories_obsolete_confirmationpopin_title')}
        >
            <FooterContainer>
                <Button
                    full
                    dataTrackId=""
                    variant="secondary"
                    size="large"
                    shape="cube"
                    appearance="outlined"
                    onClick={() => onCancelClick()}
                >
                    <Text variant="bodyLBold">
                        {t('admin_categories_obsolete_confirmationpopin_cancel')}
                    </Text>
                </Button>
                <Button
                    dataTrackId="page_remap_category__confirmation_modal__save"
                    onClick={onSaveClick}
                    variant="primary"
                    shape="cube"
                    size="large"
                    full
                >
                    {t('save')}
                </Button>
            </FooterContainer>
        </Dialog>
    );
};
