import { FC } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';

import { useIsSideMenuOpen } from 'admin/common/contexts/isSideMenuOpenContext';

import { PageWrapper } from '../templates/AdminPage/AdminPage.styled';

const PageErrorPlaceholder: FC = () => {
    const { isSideMenuOpen } = useIsSideMenuOpen();

    const illustrationElement: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.Sad,
        type: IllustrationCategoryEnum.Laptop,
    };
    return (
        <PageWrapper isSideMenuOpen={isSideMenuOpen} id="page_wrapper">
            <NotFoundTemplate
                show
                imgSrc={illustrationElement}
                title="admin:error_page_placeholder_title"
                subtitle="admin:error_page_placeholder_subtitle"
                buttonText="refresh"
                handleClick={() => window.location.reload()}
                icon={[FontAwesomeIconsPartooUsed.faRedo, IconPrefix.SOLID]}
            />
        </PageWrapper>
    );
};

export default PageErrorPlaceholder;
