import React, { useState } from 'react';

import { CustomFile, Dropzone, DropzoneTypeEnum, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DropZoneDescription } from 'admin/common/components/inputs/DropZone/DropZoneDescription';

import { usePhotosUpload } from 'admin/content/data/pages/FoodMenusCreation/hooks/usePhotosUpload';

import { AddPhotoContainer, AddPhotoHeader, DropzoneWrapper } from './AddPhotoAction.styled';

const ACCEPTED_FILES = {
    ['image/png']: ['.png'],
    ['image/jpeg']: ['.jpg', '.jpeg'],
};

type Props = {
    photosMenu: string[];
    setPhotosMenu: React.Dispatch<React.SetStateAction<string[]>>;
    photosPerRow: number;
};
export const AddPhotoAction: React.FC<Props> = ({
    photosMenu,
    setPhotosMenu,
    photosPerRow = 2,
}) => {
    const { t } = useTranslation();

    const [files, setFiles] = useState<CustomFile[]>([]);

    const { mutate: uploadPhotos, isLoading } = usePhotosUpload(data => {
        setPhotosMenu(photos => [...photos, ...data.result.uploads]);
    });

    const onDropSuccess = (newFiles: CustomFile[]) => {
        uploadPhotos(newFiles, {
            onSuccess: () => {
                setFiles(files => [...files, ...newFiles]);
            },
        });
    };

    const onDelete = (name: string) => {
        const indexToDelete = files.map(file => file.name).indexOf(name);
        setFiles(files => {
            const newFiles = [...files];
            newFiles.splice(indexToDelete, 1);
            return newFiles;
        });
        setPhotosMenu(photosMenu => {
            const newPhotos = [...photosMenu];
            newPhotos.splice(indexToDelete, 1);
            return newPhotos;
        });
    };

    return (
        <AddPhotoContainer>
            <AddPhotoHeader direction="row" justifyContent="space-between" alignItems="center">
                <div>
                    <Text variant="heading6" color={'dark'}>
                        {t('admin:bulk_menu__photos_section_title')}
                    </Text>
                    <Text variant={'bodyMBold'} color={'secondary'}>
                        {t('admin:bulk_menu__photos_section_description')}
                    </Text>
                </div>
                {!!photosMenu?.length && (
                    <Text variant="bodyMBold" as="span">
                        {t('admin:bulk_menu__photos_count', {
                            count: photosMenu.length,
                        })}
                    </Text>
                )}
            </AddPhotoHeader>
            <DropzoneWrapper>
                <Dropzone
                    title={t('menu_photo_dropzone_label')}
                    acceptedDescription={
                        <DropZoneDescription acceptedExtensions={ACCEPTED_FILES} />
                    }
                    accept={ACCEPTED_FILES}
                    type={DropzoneTypeEnum.Photo}
                    files={files}
                    onDropSuccess={onDropSuccess}
                    onDelete={onDelete}
                    full={false}
                    numberPerLine={photosPerRow}
                    multiple
                    loading={isLoading}
                />
            </DropzoneWrapper>
        </AddPhotoContainer>
    );
};
