import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const SectionGlobal = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    margin-bottom: 24px;
`;

export const CategoryCell = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: solid 1px ${greyColorObject.initial};
    :last-child {
        border-bottom: 0px;
    }
`;
