import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const RoundedGreyBorder = styled.div`
    border: 1px solid ${greyColorObject.initial};
    border-radius: 8px;
`;

export const RoundedGreyBorderWithLayout = styled(RoundedGreyBorder)`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 16px 24px;
    flex-wrap: wrap;
`;

export const BusinessInfo = styled.div`
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: space-between;
`;

export const IconWithRightMargin = styled.i`
    margin-right: 6px;
`;
