import { FontAwesomeIconsPartooUsed, IconElement, IconPrefix, Option } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { SingleSelect } from 'admin/common/components/inputs/SingleSelect';
import {
    USE_ATTRIBUTES_IN_NEW_EXPORTER,
    USE_CUSTOM_FIELDS_IN_NEW_EXPORTER,
} from 'admin/common/data/constants/featureFlags';

export const bulkFormatEnum = {
    DEFAULT: 'default',
    LOCATION__MORE_HOURS: 'more_hours',
    LOCATION__MESSAGE_SETTINGS: 'message_settings',
    LOCATION_ATTRIBUTES: 'attributes',
    LOCATION_CUSTOM_FIELDS: 'custom_fields',
};

export const bulkFormatOptions: Option[] = [
    {
        value: bulkFormatEnum.DEFAULT,
        name: bulkFormatEnum.DEFAULT,
        label: 'Partoo exporter',
        icon: [FontAwesomeIconsPartooUsed.faFileArrowDown, IconPrefix.REGULAR],
    },
    {
        value: bulkFormatEnum.LOCATION__MORE_HOURS,
        name: bulkFormatEnum.LOCATION__MORE_HOURS,
        label: 'More hours',
        icon: [FontAwesomeIconsPartooUsed.faClock, IconPrefix.REGULAR],
    },
    {
        value: bulkFormatEnum.LOCATION__MESSAGE_SETTINGS,
        name: bulkFormatEnum.LOCATION__MESSAGE_SETTINGS,
        label: 'Message settings',
        icon: [FontAwesomeIconsPartooUsed.faMessage, IconPrefix.REGULAR],
    },
    ...(USE_ATTRIBUTES_IN_NEW_EXPORTER
        ? [
              {
                  value: bulkFormatEnum.LOCATION_ATTRIBUTES,
                  name: bulkFormatEnum.LOCATION_ATTRIBUTES,
                  label: 'Attributes',
                  icon: [FontAwesomeIconsPartooUsed.faTags, IconPrefix.REGULAR] as IconElement,
              },
          ]
        : []),
    ...(USE_CUSTOM_FIELDS_IN_NEW_EXPORTER
        ? [
              {
                  value: bulkFormatEnum.LOCATION_CUSTOM_FIELDS,
                  name: bulkFormatEnum.LOCATION_CUSTOM_FIELDS,
                  label: 'Custom Fields',
                  icon: [FontAwesomeIconsPartooUsed.faPenField, IconPrefix.REGULAR] as IconElement,
              },
          ]
        : []),
];

type Props = {
    bulkFormat: string | null;
    setBulkFormat: (value: string | null) => void;
};

export const BulkFormatSelect = ({ bulkFormat, setBulkFormat }: Props) => {
    const { t } = useTranslation();

    return (
        <SingleSelect
            dataTrackId="bulk_select_format"
            sections={[{ options: bulkFormatOptions }]}
            selectedValue={bulkFormat}
            onChange={setBulkFormat}
            placeholder={t('bulk_format')}
            disableReset
            required
        />
    );
};
