import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { Stack } from '@partoohub/ui';

export const AddPhotoContainer = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    margin-bottom: 24px;
`;

export const AddPhotoHeader = styled(Stack)`
    height: 70px;
    padding: 0 24px;
    border-bottom: solid 1px ${greyColorObject.initial};
`;

export const DropzoneWrapper = styled.div`
    padding: 32px 24px;
`;
