import {
    BusinessAttributesGetDataType,
    BusinessAttributesGetParamsType,
    BusinessAttributesPostParamsType,
} from 'app/api/types/attributes';
import {
    BusinessConnectedChannels,
    BusinessCreationRequestData,
    BusinessDisabledDiffusersGetDatatype,
    BusinessFieldsType,
    BusinessLocation,
    BusinessUpdateRequestData,
    CompletionRateResponse,
    ConnectionsStatsReceivedData,
    CreateGoogleLocationFromBusinessType,
    CreationFailureResponse,
    CreationSuccessResponses,
    DuplicateBusinessSearchFailureResponse,
    DuplicateBusinessSearchSuccessResponse,
    FetchGmbImagesReceivedData,
    FormattedBusinessImportFieldsType,
    GoogleLocationFromBusinessStatus,
    OnboardingCreationSuccessResponses,
    SearchBusinessParams,
    SearchBusinessesReceivedData,
    SearchBusinessesStatsData,
    SubscriptionsStatsParams,
    V2BusinessData,
} from 'app/api/types/business';
import { SuccessResponse, V2Subscriptions } from 'app/api/types/index';
import {
    BusinessMoreHoursGetDataType,
    BusinessMoreHoursGetParamsType,
    BusinessMoreHoursPostType,
} from 'app/api/types/more_hours';

import {
    GetPlaceActionLinksForBusinessReceivedData,
    PlaceActionLinksPostRequest,
} from 'app/api/types/placeActionLinks';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

import ApiResourceBaseClass from './baseApiCalls';

export const RESOURCE_URI = 'business';

class Business extends ApiResourceBaseClass {
    static getBusinessEditFields(businessId: string): Promise<BusinessFieldsType> {
        return Business.get(`${RESOURCE_URI}/${businessId}/business_fields`).then(
            ({ data }) => data,
        );
    }

    static getBusinessFields(): Promise<BusinessFieldsType> {
        return Business.get(`${RESOURCE_URI}/fields/permissions`).then(({ data }) => data);
    }

    static updateBusinessFields(businessFields: BusinessFieldsType): Promise<Record<string, any>> {
        return Business.post(`${RESOURCE_URI}/fields/permissions`, businessFields).then(
            ({ data }) => data,
        );
    }

    static searchBusinessesStats(
        queryParams: SearchBusinessParams,
    ): Promise<SearchBusinessesStatsData> {
        return Business.get(`${RESOURCE_URI}/search/stats`, queryParams).then(({ data }) => data);
    }

    static searchBusinessesCount(queryParams: SearchBusinessParams): Promise<{
        count: number;
    }> {
        return Business.get(`${RESOURCE_URI}/search/count`, queryParams).then(({ data }) => data);
    }

    static getBusinessesLanguages(
        queryParams: BusinessModalFiltersType,
    ): Promise<{ languages: Array<string> }> {
        return Business.get(`${RESOURCE_URI}/languages`, queryParams).then(({ data }) => data);
    }

    static getBusiness(businessId: string): Promise<V2BusinessData> {
        return Business.get(`${RESOURCE_URI}/${businessId}`, {
            partoo_ui: 'True',
        }).then(({ data }) => data);
    }

    static getSubscriptions(queryParams: SubscriptionsStatsParams): Promise<V2Subscriptions> {
        return Business.get(`${RESOURCE_URI}/subscriptions`, queryParams).then(({ data }) => data);
    }

    static getUserBusinessConnectionsStats(params = {}): Promise<ConnectionsStatsReceivedData> {
        return Business.get(`${RESOURCE_URI}/connections_stats`, params).then(({ data }) => data);
    }

    static createBusiness(
        businessData: BusinessCreationRequestData,
    ): Promise<CreationSuccessResponses | CreationFailureResponse> {
        return Business.post(`${RESOURCE_URI}?partoo_ui=true`, businessData).then(
            ({ data }) => data,
        );
    }

    static importGMBInfos(
        businessId: string,
        requestData: FormattedBusinessImportFieldsType,
    ): Promise<SuccessResponse> {
        return Business.post(`${RESOURCE_URI}/${businessId}/google-import`, requestData).then(
            ({ data }) => data,
        );
    }

    static updateBusiness(
        businessId: string,
        businessData: BusinessUpdateRequestData,
    ): Promise<SuccessResponse> {
        return Business.post(`${RESOURCE_URI}/${businessId}?partoo_ui=true`, businessData).then(
            ({ data }) => data,
        );
    }

    static updateBusinessCompletionRate(businessId: string): Promise<CompletionRateResponse> {
        return Business.post(`${RESOURCE_URI}/${businessId}/completion_rate`).then(
            ({ data }) => data,
        );
    }

    static updateBusinessMoreHours(
        businessId: string,
        moreHoursData: BusinessMoreHoursPostType,
    ): Promise<SuccessResponse> {
        return Business.post(`${RESOURCE_URI}/${businessId}/more_hours`, moreHoursData).then(
            ({ data }) => data,
        );
    }

    static fetchGmbImages(businessId: string): Promise<FetchGmbImagesReceivedData> {
        return Business.post(`${RESOURCE_URI}/import-photo/${businessId}`).then(({ data }) => data);
    }

    static duplicateBusinessSearch(
        payload: any,
    ): Promise<DuplicateBusinessSearchSuccessResponse | DuplicateBusinessSearchFailureResponse> {
        return Business.post(`${RESOURCE_URI}/search-duplicates`, payload).then(({ data }) => data);
    }

    static getPlaceTypes(): Promise<string[]> {
        return Business.get(`${RESOURCE_URI}/search-duplicates/place-types`).then(
            ({ data }) => data,
        );
    }

    static businessAttributesGet(
        businessId: string,
        queryParams: BusinessAttributesGetParamsType,
    ): Promise<BusinessAttributesGetDataType> {
        return Business.get(`${RESOURCE_URI}/${businessId}/attributes`, queryParams).then(
            ({ data }) => data,
        );
    }

    static businessAttributesPost(
        businessId: string,
        queryParams: BusinessAttributesPostParamsType,
    ): Promise<SuccessResponse> {
        return Business.post(`${RESOURCE_URI}/${businessId}/attributes`, queryParams).then(
            ({ data }) => data,
        );
    }

    static businessPlaceActionLinksGet(
        businessId: string,
    ): Promise<GetPlaceActionLinksForBusinessReceivedData> {
        return Business.get(`${RESOURCE_URI}/${businessId}/place_action_links`).then(
            ({ data }) => data,
        );
    }

    static businessPlaceActionLinksPost(
        businessId: string,
        PlaceActionLinkData: PlaceActionLinksPostRequest,
    ): Promise<SuccessResponse> {
        return Business.post(
            `${RESOURCE_URI}/${businessId}/place_action_links`,
            PlaceActionLinkData,
        ).then(({ data }) => data);
    }

    static businessMoreHoursGet(
        businessId: string,
        queryParams: BusinessMoreHoursGetParamsType,
    ): Promise<BusinessMoreHoursGetDataType> {
        return Business.get(`${RESOURCE_URI}/${businessId}/more_hours`, queryParams).then(
            ({ data }) => data,
        );
    }

    static onboardingBusinessesCreation(
        locations: Array<BusinessLocation>,
        groups?: Array<number>,
    ): Promise<OnboardingCreationSuccessResponses | CreationFailureResponse> {
        return Business.post('onboarding-businesses', {
            locations,
            groups,
        }).then(({ data }) => data);
    }

    static businessDisabledDiffusersGet(
        businessId: string,
    ): Promise<BusinessDisabledDiffusersGetDatatype> {
        return Business.get(`${RESOURCE_URI}/${businessId}/disabled_diffusers`).then(
            ({ data }) => data,
        );
    }

    static applyBulkInApp(filters: BusinessModalFiltersType, data: BusinessUpdateRequestData) {
        return Business.post(`${RESOURCE_URI}/bulk-modification`, {
            ...filters,
            filters,
            data,
        }).then(({ data }) => data);
    }

    static getBusinessConnectedChannels(businessId: string): Promise<BusinessConnectedChannels> {
        return Business.get(`${RESOURCE_URI}/${businessId}/connected_channels`).then(
            ({ data }) => data,
        );
    }

    static createGoogleLocationFromBusiness(
        businessId: string,
        queryParams: CreateGoogleLocationFromBusinessType,
    ): Promise<GoogleLocationFromBusinessStatus> {
        return Business.post(`${RESOURCE_URI}/${businessId}/google-location`, queryParams).then(
            ({ data }) => data,
        );
    }
}

export default Business;
