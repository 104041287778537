import { Icon, IconElement, Stack, Text } from '@partoohub/ui';

interface MenuLabelItemProps {
    text: string;
    icon?: IconElement;
    color?: string;
}

export const MenuLabelItem = ({ text, icon, color = 'default' }: MenuLabelItemProps) => {
    return (
        <Stack direction="row" alignItems="center">
            {icon && <Icon icon={icon} color={color} />}
            <Stack gap="0">
                <Text variant="bodyMMedium" as="span" color={color}>
                    {text}
                </Text>
            </Stack>
        </Stack>
    );
};
