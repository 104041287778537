import React from 'react';

import { connect } from 'react-redux';

import {
    HideableComponentsNames,
    componentDisplaySelector,
} from 'app/SDKBridge/reducers';
import { AppState } from 'app/states/reducers';

type Props = {
    displayComponent: boolean;
};

/** Curryfied version of withHideableComponent for easier use with compose().
 */
export const hideableComponent =
    (componentName: HideableComponentsNames) =>
    (component: React.ComponentType) =>
        withHideableComponent(component, componentName);

/**
 * This HOC checks if the component it is applied to should be rendered.
 * It looks inside hideableComponents state object. If the value stored in the key `componentName`
 * is `false` the component is not render.
 *
 * @param Component
 * @param componentName
 * @returns {*}
 */
const withHideableComponent: any = (
    Component: any,
    componentName: HideableComponentsNames,
) => {
    const HideableComponent = (props: Props) => {
        const { displayComponent } = props;
        return displayComponent && <Component {...props} />;
    };

    const mapStateToProps = ({ hideableComponents }: AppState) => ({
        displayComponent: componentDisplaySelector(
            hideableComponents,
            componentName,
        ),
    });

    // @ts-ignore
    return connect(mapStateToProps)(HideableComponent);
};

export default withHideableComponent;
