import {
    CreateFeedbackLookerStudioPayload,
    FeedbackLookerStudioData,
    UpdateFeedbackLookerStudioPayload,
} from 'app/api/types/feedbackLookerStudio';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'feedback/feedback_looker_studio';

export class FeedbackLookerStudio extends ApiResourceBaseClass {
    static async getFeedbackLookerStudio(orgId: number): Promise<FeedbackLookerStudioData> {
        const { data } = await FeedbackLookerStudio.get(`${RESOURCE_URI}/${orgId}`);

        return data;
    }

    static async updateFeedbackLookerStudio(
        orgId: number,
        payload: UpdateFeedbackLookerStudioPayload,
    ): Promise<FeedbackLookerStudioData> {
        const { data } = await FeedbackLookerStudio.put(`${RESOURCE_URI}/${orgId}`, payload);

        return data;
    }
    static async createFeedbackLookerStudio(
        payload: CreateFeedbackLookerStudioPayload,
    ): Promise<FeedbackLookerStudioData> {
        const { data } = await FeedbackLookerStudio.post(RESOURCE_URI, payload);

        return data;
    }
}
