import { extractPartooSubdomain } from '@partoohub/utils';

export const PROD_STATIC_BASE_URL = 'https://static.partoo.co';
type Config = {
    ENV: string;
    STATIC_BASE_URL: string;
    WEBSOCKET_LIVE_UPDATES_URL: string;
    FEEDBACK_MANAGEMENT_BASE_URL: string;
};

let ENV: string;
let STATIC_BASE_URL: string;
let WEBSOCKET_LIVE_UPDATES_URL: string;
let FEEDBACK_MANAGEMENT_BASE_URL: string;

const { reference, subdomain } = extractPartooSubdomain();

const env = !subdomain || subdomain === '' ? 'prod' : subdomain;

const buildConfig = (): Config => {
    const isCustomEnv = subdomain && env !== 'prod';

    // Handle 'dev' environment separately due to unique WebSocket URL
    if (env === 'dev') {
        return {
            ENV: 'dev',
            STATIC_BASE_URL: 'https://static.dev.partoo.co',
            WEBSOCKET_LIVE_UPDATES_URL: 'wss://app.dev.partoo.co/websockets',
            FEEDBACK_MANAGEMENT_BASE_URL: 'https://survey.dev.partoo.co',
        };
    }

    // Handle environments with reference and subdomain
    if (reference && isCustomEnv) {
        return {
            ENV: subdomain,
            STATIC_BASE_URL: `https://${reference}.static.${subdomain}.partoo.co`,
            WEBSOCKET_LIVE_UPDATES_URL: `wss://${reference}.websockets.${subdomain}.partoo.co`,
            FEEDBACK_MANAGEMENT_BASE_URL: `https://${reference}.survey.${subdomain}.partoo.co`,
        };
    }

    // Handle other non-production subdomains
    if (isCustomEnv) {
        return {
            ENV: subdomain,
            STATIC_BASE_URL: `https://static.${subdomain}.partoo.co`,
            WEBSOCKET_LIVE_UPDATES_URL: `wss://websockets.${subdomain}.partoo.co`,
            FEEDBACK_MANAGEMENT_BASE_URL: `https://survey.${subdomain}.partoo.co`,
        };
    }

    // Default to production configuration
    return {
        ENV: 'prod',
        STATIC_BASE_URL: PROD_STATIC_BASE_URL,
        WEBSOCKET_LIVE_UPDATES_URL: 'wss://websockets.prod.partoo.co',
        FEEDBACK_MANAGEMENT_BASE_URL: 'https://survey.partoo.co',
    };
};

const {
    ENV: envResult,
    STATIC_BASE_URL: staticBase,
    WEBSOCKET_LIVE_UPDATES_URL: wsUpdates,
    FEEDBACK_MANAGEMENT_BASE_URL: feedbackBase,
} = buildConfig();

// Assign the results to variables
ENV = envResult;
STATIC_BASE_URL = staticBase;
WEBSOCKET_LIVE_UPDATES_URL = wsUpdates;
FEEDBACK_MANAGEMENT_BASE_URL = feedbackBase;
const STATIC_ASSETS_BASE_URL = 'https://partoo-media.s3.eu-west-1.amazonaws.com/assets';
export const STATIC_ADMIN_ASSETS_DIR = `${STATIC_ASSETS_BASE_URL}/admin`;

export { ENV, STATIC_BASE_URL, WEBSOCKET_LIVE_UPDATES_URL, FEEDBACK_MANAGEMENT_BASE_URL };
