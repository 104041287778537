import React from 'react';

import { Button, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
};
export const CopyButton: React.FC<Props> = ({ name, address, zipcode, city, country }) => {
    const { t } = useTranslation();

    const onClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const textToCopy =
            '<p><b>' +
            name +
            '</b> - ' +
            address +
            ' - ' +
            zipcode +
            ' - ' +
            city +
            ' - ' +
            country;
        const blobHtml = new Blob([textToCopy.toString()], {
            type: 'text/html',
        });
        const formattedText = [
            new ClipboardItem({
                ['text/html']: blobHtml,
            }),
        ];

        navigator.clipboard.write(formattedText);
        toast.success(t('admin:page_diffusion_cleaner__business_copy_button__success'), 'Success!');
    };

    return (
        <Button
            appearance="contained"
            shape="rounded"
            size="medium"
            variant="secondary"
            dataTrackId="admin_diffusion_cleaner__copy_business_button"
            onClick={onClick}
        >
            <Text variant="bodyMBold" color="white">
                {t('admin:page_diffusion_cleaner__business_button__copy')}
            </Text>
        </Button>
    );
};
