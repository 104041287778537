import {
    Account,
    Address,
    ApiKeys,
    Attributes,
    AuthToken,
    Business,
    Categories,
    Chatbot,
    Cities,
    Comments,
    CompetitiveBenchmark,
    CompetitiveBenchmarkSettings,
    Connection,
    ConversationRouter,
    ConversationStarters,
    CustomFields,
    CustomFieldsSections,
    FeedbackForm,
    FeedbackLookerStudio,
    FeedbackResult,
    FoodMenu,
    Geocoding,
    Group,
    Holidays,
    Image as ImageApiClient,
    InteractionTag,
    Invoice,
    Lang,
    Leads,
    Messaging,
    MessagingCallDeflection,
    MessagingPrivacyPolicy,
    MessagingReplyTemplate,
    MessagingSettings,
    MessagingStats,
    MoreHours,
    Organization,
    PresenceAnalytics,
    ProductUpsale,
    Provider,
    Publisher,
    PublisherErrors,
    PublisherState,
    ReviewAutoReply,
    ReviewBooster,
    ReviewTag,
    Scopes,
    Services,
    SsoOidc,
    SsoSaml,
    Subscription,
    User,
    Webhooks,
    WhatsappBusinessAccount,
    WhatsappProfile,
    WidgetSetup,
} from '@partoohub/api-client';

const BASE_URL = `https://${window.location.host}/api/v2`;

export const accountApiClient = new Account(BASE_URL);
export const addressApiClient = new Address(BASE_URL);
export const apiKeysApiClient = new ApiKeys(BASE_URL);
export const authTokenApiClient = new AuthToken(BASE_URL);
export const businessApiClient = new Business(BASE_URL);
export const holidaysApiClient = new Holidays(BASE_URL);
export const citiesApiClient = new Cities(BASE_URL);
export const chatbotApiClient = new Chatbot(BASE_URL);
export const commentsApiClient = new Comments(BASE_URL);
export const connectionApiClient = new Connection(BASE_URL);
export const conversationRouterApiClient = new ConversationRouter(BASE_URL);
export const feedbackFormApiClient = new FeedbackForm(BASE_URL);
export const feedbackLookerStudioApiClient = new FeedbackLookerStudio(BASE_URL);
export const feedbackResultApiClient = new FeedbackResult(BASE_URL);
export const geocodingApiClient = new Geocoding(BASE_URL);
export const groupApiClient = new Group(BASE_URL);
export const imageApiClient = new ImageApiClient(BASE_URL);
export const interactionTagApiClient = new InteractionTag(BASE_URL);
export const invoiceApiClient = new Invoice(BASE_URL);
export const messagingApiClient = new Messaging(BASE_URL);
export const messagingCallDeflectionApiClient = new MessagingCallDeflection(BASE_URL);
export const messagingPrivacyPolicyApiClient = new MessagingPrivacyPolicy(BASE_URL);
export const messagingReplyTemplateApiClient = new MessagingReplyTemplate(BASE_URL);
export const messagingSettingsApiClient = new MessagingSettings(BASE_URL);
export const messagingStatsApiClient = new MessagingStats(BASE_URL);
export const moreHoursApiClient = new MoreHours(BASE_URL);
export const productUpsaleApiClient = new ProductUpsale(BASE_URL);
export const providerApiClient = new Provider(BASE_URL);
export const publisherApiClient = new Publisher(BASE_URL);
export const langApiClient = new Lang(BASE_URL);
export const organizationApiClient = new Organization(BASE_URL);
export const reviewTagApiClient = new ReviewTag(BASE_URL);
export const userApiClient = new User(BASE_URL);
export const reviewAutoReplyApiClient = new ReviewAutoReply(BASE_URL);
export const reviewBoosterApiClient = new ReviewBooster(BASE_URL);
export const scopesApiClient = new Scopes(BASE_URL);
export const ssoOidcApiClient = new SsoOidc(BASE_URL);
export const ssoSamlApiClient = new SsoSaml(BASE_URL);
export const webhooksApiClient = new Webhooks(BASE_URL);
export const whatsappBusinessAccountApiClient = new WhatsappBusinessAccount(BASE_URL);
export const whatsappProfileApiClient = new WhatsappProfile(BASE_URL);
export const widgetSetupApiClient = new WidgetSetup(BASE_URL);
export const leadsApiClient = new Leads(BASE_URL);
export const conversationStartersApiClient = new ConversationStarters(BASE_URL);
export const publisherStateApiClient = new PublisherState(BASE_URL);
export const publisherErrorsApiClient = new PublisherErrors(BASE_URL);
export const presenceAnalyticsApiClient = new PresenceAnalytics(BASE_URL);
export const foodMenuApiClient = new FoodMenu(BASE_URL);
export const servicesApiClient = new Services(BASE_URL);
export const competitiveBenchmarkApiClient = new CompetitiveBenchmark(BASE_URL);
export const competitiveBenchmarkSettingsApiClient = new CompetitiveBenchmarkSettings(BASE_URL);
export const subscriptionApiClient = new Subscription(BASE_URL);
export const customFieldsApiClient = new CustomFields(BASE_URL);
export const customFieldsSectionsApiClient = new CustomFieldsSections(BASE_URL);
export const categoriesApiClient = new Categories(BASE_URL);
export const attributesApiClient = new Attributes(BASE_URL);
