import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';
import { getQueryStringFromObject } from '@partoohub/utils';

import { GoogleAdminManagementData } from 'admin/api/types';

const RESOURCE_URI = 'google-administrations';

export class GoogleAdminManagementApiCall extends ApiResourceBaseClass {
    async fetchGoogleAdminRequests(
        page = 1,
        queryFilter: Record<string, any> = {},
    ): Promise<GoogleAdminManagementData> {
        const queryParams = getQueryStringFromObject({ ...queryFilter, page });

        const { data } = await this.get<GoogleAdminManagementData>(
            `${RESOURCE_URI}?${queryParams}`,
        );

        return data;
    }

    async requestAdmin(googleLocationId: string): Promise<SuccessResponse> {
        const { data } = await this.post<SuccessResponse>(
            `${RESOURCE_URI}/locations/${googleLocationId}/request`,
        );

        return data;
    }

    async acceptInvitation(
        googleLocationId: string,
        invitationName: string,
    ): Promise<SuccessResponse> {
        const { data } = await this.post<SuccessResponse>(
            `${RESOURCE_URI}/locations/${googleLocationId}/accept`,
            {
                invitation_name: invitationName,
            },
        );

        return data;
    }
}
