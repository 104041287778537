import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { IconButton } from '@partoohub/ui';

type PartProps = {
    backgroundUrl?: string;
};

export const FullScreen = styled.div`
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.theme.background};
`;

export const LeftPart = styled.div<PartProps>`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
`;

export const RightPart = styled.div<PartProps>`
    display: flex;
    width: 40%;
    max-width: 500px;
    background: ${props => `url(${props.backgroundUrl})`};
    background-size: cover;
    border-left: solid 1px ${greyColorObject.initial};
    overflow: hidden;
`;

export const StyledIconButton = styled(IconButton)`
    position: absolute !important;
    top: 20px;
    right: 24px;
`;
