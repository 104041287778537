import ApiResourceBaseClass from './baseApiCalls';
import { dropUndefinedParams } from './utils';
import { SuccessResponse } from '../../types';
import { CommentData, CommentParams, UpdateCommentParams } from '../../types/comments';

const RESOURCE_URI = 'comments';

class Comments extends ApiResourceBaseClass {
    static async createComment(comment: CommentParams): Promise<CommentData> {
        const { data } = await Comments.post(`${RESOURCE_URI}`, dropUndefinedParams(comment));
        return data;
    }

    static async updateComment(
        comment: UpdateCommentParams,
        commentId: number,
    ): Promise<CommentData> {
        const { data } = await Comments.put(`${RESOURCE_URI}/${commentId}`, comment);
        return data;
    }

    static async deleteComment(commentId: string): Promise<SuccessResponse> {
        const { data } = await Comments.delete(`${RESOURCE_URI}/${commentId}`);
        return data;
    }
}

export default Comments;
