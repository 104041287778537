import {
    BUSINESSES_CITIES,
    BUSINESSES_GROUPS,
    BUSINESSES_ORGS,
    BUSINESSES_QUERIES,
    BUSINESSES_SELECT_ALL,
    EXCLUDED_BUSINESSES,
    INCLUDED_BUSINESSES,
} from 'app/common/data/routeIds';
import { getParsedSearchParam } from 'app/common/data/routeIds.parsers';

export const parseSearchParams = (location: Location) => ({
    mode: getParsedSearchParam(location, '*', BUSINESSES_SELECT_ALL),
    includedIds: getParsedSearchParam(location, '*', INCLUDED_BUSINESSES),
    excludedIds: getParsedSearchParam(location, '*', EXCLUDED_BUSINESSES),
    queries: getParsedSearchParam(location, '*', BUSINESSES_QUERIES),
    cities: getParsedSearchParam(location, '*', BUSINESSES_CITIES),
    groupIdIn: getParsedSearchParam(location, '*', BUSINESSES_GROUPS),
    orgs: getParsedSearchParam(location, '*', BUSINESSES_ORGS),
});
