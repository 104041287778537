import styled from '@emotion/styled';
import { TOOLTIP_WRAPPER_Z_INDEX } from '@partoohub/ui';

export const BlackTooltip = styled.div`
    text-align: left;
    font-size: 12px;
    padding: 4px 8px;
    background: ${({ theme }) => theme.colors?.default?.initial};
    color: white;
    max-width: 330px;
    border-radius: 4px;
    z-index: ${TOOLTIP_WRAPPER_Z_INDEX};
    pointer-events: none;

    opacity: 0;
    transition: opacity 0.1s ease-in;
    &::first-letter {
        text-transform: capitalize;
    }

    &.fade-enter-active {
        opacity: 1;
    }

    &.fade-enter-done {
        opacity: 1;
    }

    &.fade-exit-active {
        opacity: 0;
    }
`;
