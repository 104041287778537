import { BusinessModalErrorType, BusinessModalTranslations } from '@partoohub/modular-components';

export const translations: BusinessModalTranslations = {
    selectBusiness: 'business_drawer_select_locations',
    count: 'business_drawer_business_count',
    reachGlobal: 'business_drawer_reach_global',
    filters: 'business_drawer_filters',
    confirm: 'business_drawer_confirm',
    erase: 'business_drawer_erase',
    cancel: 'cancel',
    selectAll: 'business_drawer_select_all',
    groups: 'business_drawer_groups',
    search: 'business_drawer_search',
    cities: 'business_drawer_cities',
    organizations: 'business_drawer_organizations',
    error: {
        maxLimitReached: 'business_drawer_error_max_limit_reached',
    } as Record<BusinessModalErrorType, string>,
    emptyState: {
        title: 'placeholder_no_result_title',
        description: 'placeholder_no_result_description',
        reset: 'reset_filters',
    },
};
