import { FilterByBusinessState } from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import { AppState } from 'app/states/reducers';

export * from './filterByBusiness';

/**
 * @deprecated
 * Should not be used anymore except by the Business Modal
 */
export const filterByBusinessSelector = (
    state: AppState,
): FilterByBusinessState => state.filterByBusiness;
