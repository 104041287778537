import { SuccessResponse } from 'app/api/types';
import {
    BusinessQueryParams,
    CreatePostBody,
    PostFilterParams,
    PostResponse,
    PostState,
    UpdatePostBody,
} from 'app/api/types/post';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import {
    dropUndefinedParams,
    generateQueryParamsString,
} from 'app/api/v2/api_calls/utils';

const RESOURCE_URI = 'posts';

export type TPost = Record<string, any>;
export type PostRequestBody = {
    business_search: Record<string, any>;
    data: CreatePostBody;
    publishers?: Array<'facebook' | 'google_my_business'>;
};

class Post extends ApiResourceBaseClass {
    static async createPost(
        businessFilters: BusinessQueryParams,
        payload: CreatePostBody,
        platform: Array<'facebook' | 'google_my_business'>,
    ): Promise<PostResponse> {
        const businessSearch = dropUndefinedParams(businessFilters);
        businessSearch.partoo_ui = true;
        const body: PostRequestBody = {
            business_search: businessSearch,
            data: payload,
            publishers: platform,
        };
        const { data } = await Post.post(`${RESOURCE_URI}`, body);
        return data;
    }

    static async getPostList(
        filterParams: PostFilterParams,
    ): Promise<PostResponse> {
        const { data } = await Post.get(
            `${RESOURCE_URI}?${generateQueryParamsString(filterParams)}`,
        );
        return data;
    }

    static async updatePost(
        postId: string,
        payload: UpdatePostBody,
    ): Promise<TPost> {
        const { data } = await Post.put(`${RESOURCE_URI}/${postId}`, payload);
        return data;
    }

    static async updatePostBulk(
        bulkId: number,
        payload: UpdatePostBody,
    ): Promise<Record<string, string | number>> {
        const { data } = await Post.post(`${RESOURCE_URI}/edit_bulk`, {
            bulk_id: bulkId,
            post_data: payload,
        });
        return data;
    }

    static async deletePost(postId: string): Promise<SuccessResponse> {
        const { data } = await Post.delete(`${RESOURCE_URI}/${postId}`);
        return data;
    }

    static async deletePostBulk(bulkId: number): Promise<SuccessResponse> {
        const { data } = await Post.post(`${RESOURCE_URI}/delete_bulk`, {
            bulk_id: bulkId,
        });
        return data;
    }

    static async countPostBulk(bulkId: number): Promise<number> {
        const { data } = await Post.get(`${RESOURCE_URI}/count_bulk/${bulkId}`);
        return data.count;
    }

    static async getPostState(postId: string): Promise<PostState> {
        const { data } = await Post.get(`${RESOURCE_URI}/${postId}/state`);
        return data;
    }
}

export default Post;
