import { useMutation, useQueryClient } from 'react-query';

import api from 'admin/common/api/calls';
import {
    CompetitorWithId,
    DeleteCompetitorPayload,
} from 'admin/common/api/types/competitiveBenchmark';
import { COMPETITIVE_BENCHMARK_KEYWORDS } from 'admin/common/data/queryKeysConstants';

import { useOrgIdParsed } from './useOrgIdParsed';

export const useDeleteCompetitor = () => {
    const queryClient = useQueryClient();
    const orgId = useOrgIdParsed();

    const {
        mutate: deleteCompetitor,
        isSuccess,
        reset,
    } = useMutation(
        (payload: DeleteCompetitorPayload): Promise<CompetitorWithId> => {
            return api.competitiveBenchmark.deleteCompetitor(payload);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([COMPETITIVE_BENCHMARK_KEYWORDS, orgId]);
            },
        },
    );

    return {
        mutate: deleteCompetitor as (payload: DeleteCompetitorPayload) => Promise<CompetitorWithId>,
        isSuccess,
        reset,
    };
};
