import React from 'react';

import { Filters, Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DiffusionCleanerCountFilters } from 'admin/common/api/types/diffusion_cleaner';

import { useDiffusionCount } from '../hooks/useDiffusionCount';

type Props = {
    setErrorTypes: (selectedItems: Record<string, boolean>) => void;
    disabled?: boolean;
    filters: DiffusionCleanerCountFilters;
};

export const DiffusionErrorTypeSelect: React.FC<Props> = ({
    setErrorTypes,
    disabled,
    filters,
}: Props) => {
    const { t } = useTranslation();

    const { data, isLoading, pageHasError } = useDiffusionCount({
        provider: filters.provider,
        org: filters.org,
        groups: filters.groups,
        publishers: filters.publishers,
    });

    const diffusionCleanerErrorTypes: Option[] =
        data && !pageHasError && !isLoading
            ? data.map(error => ({
                  label: t(`admin:${error.error_name}`),
                  name: error.error_key,
                  value: error.error_key,
              }))
            : [];

    const uniqueDiffusionCleanerErrorTypes = [
        ...new Map(diffusionCleanerErrorTypes.map(item => [item.name, item])).values(),
    ];
    const section = [{ options: uniqueDiffusionCleanerErrorTypes }];

    const hasErrorToDisplay: boolean = uniqueDiffusionCleanerErrorTypes.length !== 0;
    return (
        <Filters
            dataTrackId="admin_diffusion_cleaner__filter_error_type"
            menuListArray={section}
            selectedItems={filters.errorTypes}
            onChange={setErrorTypes}
            placeholder={
                hasErrorToDisplay
                    ? t('admin:page_diffusion_cleaner__error_type_filter')
                    : t('admin:page_diffusion_cleaner__no_error_type_filter')
            }
            position="bottom"
            maxHeight={300}
            multiple={true}
            disabled={disabled || !hasErrorToDisplay}
        />
    );
};
