import { useCallback, useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { DiffusionCleanerAdminApiCall } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DiffusionCleanerStatusFilters } from 'admin/common/api/types/diffusion_cleaner';
import { DIFFUSION_CLEANER_STATUS } from 'admin/common/data/queryKeysConstants';

import { useOrg } from './useOrg';

export const useDiffusionStatus = (filters: DiffusionCleanerStatusFilters) => {
    const [org] = useOrg();
    const orgId = org?.value;
    const [pageHasError, setPageHasError] = useState(false);

    const queryKey = useMemo(
        () => [DIFFUSION_CLEANER_STATUS, { ...filters }],
        [
            filters.provider,
            filters.provider,
            filters.publisher,
            filters.errorKey,
            filters.page,
            filters.groups,
        ],
    );

    const queryFn = useCallback(() => {
        return DiffusionCleanerAdminApiCall.diffusionCleanerStatus({
            orgId,
            provider: filters.provider,
            publisher: filters.publisher,
            errorKey: filters.errorKey,
            page: filters.page,
            groups: filters.groups,
        });
    }, [
        orgId,
        filters.provider,
        filters.publisher,
        filters.errorKey,
        filters.page,
        filters.groups,
    ]);

    const { data, isLoading, error, refetch } = useQuery(queryKey, queryFn, {
        staleTime: Infinity,
        onSuccess: () => {
            setPageHasError(false);
        },
        onError: () => {
            setPageHasError(true);
        },
        enabled: !!orgId || !!filters.provider,
    });
    return { data, isLoading, error, pageHasError, refetch };
};
