import React from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { logsApiClient } from 'admin/api/apiResources';
import { DownloadButton } from 'admin/common/components/buttons/DownloadLinkButton/DownloadLinkButton';
import {
    ImportLogRowDetailsButtons,
    ImportLogRowDetailsWrapper,
} from 'admin/content/logs/pages/ImportLog/components/ImportLog.styled';

type Props = {
    importLogId: string;
    hasReport?: boolean;
};

const ImportLogRowDetails: React.FC<Props> = ({ hasReport, importLogId }) => {
    const { t } = useTranslation();
    return (
        <ImportLogRowDetailsWrapper>
            {hasReport && (
                <ImportLogRowDetailsButtons>
                    <DownloadButton
                        id={importLogId}
                        text={t('admin:page_import_log__report_file')}
                        variant="primary"
                        icon={[FontAwesomeIconsPartooUsed.faFileArrowDown, IconPrefix.SOLID]}
                        apiCall={(id: string) => logsApiClient.bulkImportReportLink(id)}
                        dataTrackId="admin_import_log__download_report_file"
                    />
                </ImportLogRowDetailsButtons>
            )}
            <ImportLogRowDetailsButtons>
                <DownloadButton
                    id={importLogId}
                    text={t('admin:page_import_log__input_file')}
                    appearance="outlined"
                    variant="theme"
                    icon={[FontAwesomeIconsPartooUsed.faDownload, IconPrefix.SOLID]}
                    apiCall={(id: string) => logsApiClient.bulkImportInputLink(id)}
                    dataTrackId="admin_import_log__download_input_file"
                />
            </ImportLogRowDetailsButtons>
        </ImportLogRowDetailsWrapper>
    );
};

export default ImportLogRowDetails;
