import {
    FeedbackReplySuggestionPromptData,
    UpdateFeedbackReplySuggestionPromptPayload,
} from 'app/api/types/feedbackReplySuggestion';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'feedback/feedback_reply_suggestion';

export class FeedbackReplySuggestion extends ApiResourceBaseClass {
    static async getGenericPrompt(): Promise<FeedbackReplySuggestionPromptData> {
        const { data } = await FeedbackReplySuggestion.get(`${RESOURCE_URI}/prompt`);
        return data;
    }

    static async updateGenericPrompt(
        payload: UpdateFeedbackReplySuggestionPromptPayload,
    ): Promise<FeedbackReplySuggestionPromptData> {
        const { data } = await FeedbackReplySuggestion.put(`${RESOURCE_URI}/prompt`, payload);
        return data;
    }

    static async getPrompt(orgId: number): Promise<FeedbackReplySuggestionPromptData> {
        const { data } = await FeedbackReplySuggestion.get(`${RESOURCE_URI}/prompt/${orgId}`);
        return data;
    }

    static async updatePrompt(
        orgId: number,
        payload: UpdateFeedbackReplySuggestionPromptPayload,
    ): Promise<FeedbackReplySuggestionPromptData> {
        const { data } = await FeedbackReplySuggestion.put(
            `${RESOURCE_URI}/prompt/${orgId}`,
            payload,
        );
        return data;
    }

    static async deletePrompt(orgId: number): Promise<void> {
        const { data } = await FeedbackReplySuggestion.delete(`${RESOURCE_URI}/prompt/${orgId}`);
        return data;
    }
}
