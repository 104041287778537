import {
    AttributesGetDataType,
    AttributesGetParamsType,
} from 'app/api/types/attributes';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'attributes';

class Attributes extends ApiResourceBaseClass {
    static getAttributes(
        params: AttributesGetParamsType,
    ): Promise<AttributesGetDataType> {
        return Attributes.get(`${RESOURCE_URI}/list`, params).then(
            ({ data }) => data,
        );
    }
}

export default Attributes;
