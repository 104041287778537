import React, { useEffect, useMemo, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { DatePickerType, Filters, OverrideDatePicker, RangeDate } from '@partoohub/ui';
import { format, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router';

import { logsApiClient } from 'admin/api/apiResources';
import { BulkImportLog } from 'admin/api/types';
import AdminPage from 'admin/common/components/templates/AdminPage';
import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';
import { useWebsocket } from 'admin/common/contexts/websocketContext';
import { DEFAULT_LOCALE_DATE_PICKER } from 'admin/common/data/locale';
import { BULK_IMPORT_LOG } from 'admin/common/data/queryKeysConstants';
import { IMPORTER_PATH } from 'admin/common/data/routeIds';
import { IMPORT_LOG } from 'admin/common/data/websocket';
import { useMe } from 'admin/common/hooks/queries/useMe';
import { DATE_FORMAT } from 'admin/common/utils/dates';
import { getSelectedOptions } from 'admin/common/utils/selectOptions';
import {
    DateFilterWrapper,
    FiltersWrapper,
} from 'admin/content/logs/common/components/Filters.styled';
import { EmptyPlaceholder, HeaderRow } from 'admin/content/logs/common/components/List.styled';

import { ImportLogTable } from 'admin/content/logs/pages/ImportLog/components/ImportLogTable';
import { MyLogFilter } from 'admin/content/logs/pages/ImportLog/components/MyLogFilter';
import {
    importLogStatuses,
    translateImportLogData,
} from 'admin/content/logs/pages/ImportLog/utils/utils';

import {
    ImportLogFilterWrapper,
    LeftCell,
    MiddleCell,
    NotificationButtonWrapper,
    RelativeDiv,
    RightCell,
} from './components/ImportLog.styled';

import NotificationButton from '../DiffusionLogs/old/components/NotificationButton';

const ImportLog: React.FC = () => {
    const websocket = useWebsocket();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const importLogStatus = useMemo(() => importLogStatuses(t), [t]);

    const [logs, setLogs] = useState<BulkImportLog[]>([]);
    const [dates, setDates] = useState<RangeDate>([null, null]);

    const [statusFilters, setStatusFilters] = useState<Record<string, boolean>>({});
    const selectedStatuses = getSelectedOptions(statusFilters);

    const translatedImportLogData = useMemo(() => translateImportLogData(t), [t]);
    const defaultData = Object.fromEntries(translatedImportLogData.map(data => [data.id, false]));
    const [dataFilters, setDataFilters] = useState(defaultData);
    const selectedDataFilters = getSelectedOptions(dataFilters);

    const [onlyMyReportFilter, setOnlyMyReportFilter] = useState<boolean>(true);

    const [currentSearch, setCurrentSearch] = useState(searchParams.get('search_query') || '');
    const [displaySearchBar, setDisplaySearchBar] = useState(!!currentSearch);

    const [websocketLogs, setWebsocketLogs] = useState<Array<BulkImportLog>>([]);
    const [websocketLogsToDisplay, setWebsocketLogsToDisplay] = useState<Array<BulkImportLog>>([]);

    const navigate = useNavigate();

    const hasFiltersSet =
        !!dates[0] || !!selectedStatuses.length || !!selectedDataFilters.length || !!currentSearch;

    const { data: user } = useMe();

    const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
        [
            BULK_IMPORT_LOG,
            dates[0],
            selectedStatuses,
            selectedDataFilters,
            onlyMyReportFilter,
            currentSearch,
            user,
        ],
        async ({ pageParam = 1 }) => {
            return logsApiClient.getBulkImportLog({
                created_at: dates[0] ? format(dates[0], DATE_FORMAT) : undefined,
                statuses: selectedStatuses.length
                    ? Object.entries(statusFilters)
                          .map(([status, _]) => status)
                          .join(',')
                    : undefined,
                bulk_types_formats: selectedDataFilters.length
                    ? selectedDataFilters.join(',')
                    : undefined,
                user_id: onlyMyReportFilter ? user?.id : undefined,
                search_query: currentSearch,
                page: pageParam,
            });
        },
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: Infinity,
        },
    );
    useEffect(() => {
        if (!isFetching) {
            setLogs(data?.pages?.map(({ logs }) => logs)?.flat() || []);
        }
    }, [data]);

    const websocketMessages = (event: any) => {
        const data = JSON.parse(event.data);
        if (data.type === IMPORT_LOG) {
            setWebsocketLogs(state => [data.data, ...state]);
            if (matchCurrentFilters(data.data)) {
                setWebsocketLogsToDisplay(state => [data.data, ...state]);
            }
        }
    };

    useEffect(() => {
        websocket?.then(ws => {
            ws.onopen = () => undefined;
            ws.onmessage = websocketMessages;

            return () => ws.close();
        });
    }, [dates, selectedStatuses, selectedDataFilters, onlyMyReportFilter, currentSearch]);

    /** Anytime we change the filters, if some new logs have been created since the last filter change:
     * - empty websocketLogsToDisplay because the new logs might not correspond to the new filters
     * - invalidate the queries because it we don't, the logs list might be not up-to-date anymore (because of cache)
     */
    useEffect(() => {
        if (websocketLogs.length) {
            queryClient.invalidateQueries({ queryKey: [BULK_IMPORT_LOG] });
            setWebsocketLogsToDisplay([]);
            setWebsocketLogs([]);
        }
    }, [dates, selectedStatuses, selectedDataFilters, onlyMyReportFilter, currentSearch]);

    const addNewLogs = () => {
        setLogs([...websocketLogsToDisplay, ...logs]);
        setWebsocketLogsToDisplay([]);
    };

    const toggleSearchBar = () => {
        if (!displaySearchBar || (displaySearchBar && (!currentSearch || !currentSearch.length))) {
            setDisplaySearchBar(!displaySearchBar);
        }
    };

    const resetFilters = () => {
        setDates([null, null]);
        setStatusFilters({});
        setDataFilters(defaultData);
        setOnlyMyReportFilter(false);
        setCurrentSearch('');
    };

    const matchCurrentFilters = (log: BulkImportLog) => {
        if (!log) {
            return false;
        }
        if (!!dates[0] && !isSameDay(dates[0], log.created_at)) {
            return false;
        }
        if (!!selectedDataFilters.length) {
            const logData = translatedImportLogData.find(
                data => data.bulkFormat === log.bulk_format && data.bulkType === log.bulk_type,
            );
            if (!selectedDataFilters.some(df => df === logData?.id)) {
                return false;
            }
        }
        if (!!selectedStatuses.length && !selectedStatuses.some(s => s === log.status)) {
            return false;
        }
        if (onlyMyReportFilter && log.originator !== user?.id) {
            return false;
        }
        if (!!currentSearch && !log.originator_email.includes(currentSearch)) {
            return false;
        }
        return true;
    };

    const redirectToImporter = () => {
        navigate(IMPORTER_PATH);
    };

    const updateCurrentSearch = (value?: string) => {
        setCurrentSearch(value || '');
        const newParams = {
            ...searchParams,
            search_query: value,
        };
        if (!value) {
            delete newParams.search_query;
        }
        setSearchParams(newParams);
    };

    const illustrationElement: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.Happy,
        type: IllustrationCategoryEnum.Laptop,
    };

    return (
        <AdminPage
            dataTrackId="page_import_log"
            title={t('admin:page_import_log__title')}
            description={t('admin:page_import_log__description')}
            infoLink="https://partoo.elium.com/tile/view/1985/"
            displaySearchBar={displaySearchBar}
            toggleSearchBar={toggleSearchBar}
            currentSearch={currentSearch}
            updateCurrentSearch={updateCurrentSearch}
            searchPlaceholder={t('admin:page_import_log__search_placeholder')}
        >
            <FiltersWrapper>
                <DateFilterWrapper>
                    <OverrideDatePicker
                        dataTrackId="page_import_log__select_date"
                        type={DatePickerType.DateSelector}
                        labels={{ startDate: t('date') }}
                        dates={dates}
                        onChange={setDates}
                        placeholders={{ startDate: t('date_placeholder') }}
                        localeDatePicker={DEFAULT_LOCALE_DATE_PICKER}
                    />
                </DateFilterWrapper>
                <ImportLogFilterWrapper>
                    <Filters
                        menuListArray={[
                            {
                                options: Object.entries(importLogStatus).map(([value, label]) => ({
                                    label,
                                    value,
                                    name: value,
                                })),
                            },
                        ]}
                        selectedItems={statusFilters}
                        onChange={setStatusFilters}
                        placeholder={t('admin:page_import_log__status_filter')}
                        multiple
                        dataTrackId="page_import_log__select_status"
                        maxHeight={500}
                        position="bottom"
                    />
                </ImportLogFilterWrapper>
                <ImportLogFilterWrapper>
                    <Filters
                        selectedItems={dataFilters}
                        onChange={setDataFilters}
                        menuListArray={[
                            {
                                options: translatedImportLogData.map(data => ({
                                    label: data.name,
                                    value: data.id,
                                    icon: data.icon,
                                    name: data.id,
                                })),
                            },
                        ]}
                        placeholder={t('admin:page_import_log__data_filter')}
                        multiple
                        dataTrackId="page_import_log__select_data"
                        maxHeight={500}
                        position="bottom"
                    />
                </ImportLogFilterWrapper>
                <MyLogFilter
                    checked={onlyMyReportFilter}
                    onClick={() => {
                        setOnlyMyReportFilter(!onlyMyReportFilter);
                    }}
                />
            </FiltersWrapper>
            <HeaderRow>
                <LeftCell>{t('admin:page_import_log__data')}</LeftCell>
                <MiddleCell>{t('admin:page_import_log__creator')}</MiddleCell>
                <MiddleCell>{t('admin:page_import_log__date')}</MiddleCell>
                <MiddleCell>{t('admin:page_import_log__status')}</MiddleCell>
                <RightCell></RightCell>
            </HeaderRow>
            {!logs?.length && !isFetching ? (
                <EmptyPlaceholder>
                    <NotFoundTemplate
                        show
                        title={t('no-result-found')}
                        imgSrc={illustrationElement}
                        subtitle={
                            hasFiltersSet
                                ? t('page_logs__empty_placeholder__subtitle')
                                : t('admin:page_import_log__empty_placeholder__subtitle_no_filter')
                        }
                        buttonText={
                            hasFiltersSet
                                ? t('page_logs__empty_placeholder__button')
                                : t('admin:page_import_log__empty_placeholder__button_no_filter')
                        }
                        handleClick={hasFiltersSet ? resetFilters : redirectToImporter}
                    />
                </EmptyPlaceholder>
            ) : (
                <RelativeDiv>
                    {!!websocketLogsToDisplay.length && (
                        <NotificationButtonWrapper>
                            <NotificationButton
                                count={websocketLogsToDisplay.length}
                                onClick={addNewLogs}
                            />
                        </NotificationButtonWrapper>
                    )}
                    <ImportLogTable
                        logs={logs}
                        isFetching={isFetching}
                        fetchNextLogs={fetchNextPage}
                        allLogsFetched={!hasNextPage}
                    />
                </RelativeDiv>
            )}
        </AdminPage>
    );
};

export default ImportLog;
