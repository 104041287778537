import { ApiResourceBaseClass } from '@partoohub/api-client';

import {
    DuplicateBusinessSearchFailureResponse,
    DuplicateBusinessSearchSuccessResponse,
} from 'admin/api/types';

const RESOURCE_URI = 'search-duplicates';

export class SearchDuplicatesApiCall extends ApiResourceBaseClass {
    async duplicateBusinessSearch(
        payload: any,
    ): Promise<DuplicateBusinessSearchSuccessResponse | DuplicateBusinessSearchFailureResponse> {
        const { data } = await this.post<
            DuplicateBusinessSearchSuccessResponse | DuplicateBusinessSearchFailureResponse
        >(RESOURCE_URI, payload);
        return data;
    }

    async getPlaceTypes(): Promise<string[]> {
        const { data } = await this.get<string[]>(`${RESOURCE_URI}/place-types`);
        return data;
    }
}
