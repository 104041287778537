import { FC, useEffect, useMemo, useState } from 'react';

import { CustomFile, DropzoneTypeEnum, Option, Switch, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { bulkImportV1ApiClient } from 'admin/api/apiResources';
import { DropZoneDescription } from 'admin/common/components/inputs/DropZone/DropZoneDescription';
import { DropZoneWithoutUpload } from 'admin/common/components/inputs/DropZone/DropzoneWithoutUpload';
import EmailInput from 'admin/common/components/inputs/EmailInput';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import { SwitchInputWrapper } from 'admin/common/components/layout/SwitchWrapper/SwitchWrapper.styled';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { useMe } from 'admin/common/hooks/queries/useMe';

import IntegrationInput from './components/IntegrationInput';

const DEFAULT_INTEGRATION = 'cs_uploader';
const MAX_SIZE = 500_000_000;
const ACCEPTED_FILES = {
    'application/vnd.ms-excel': ['.xls'],
    'text/csv': ['.csv'],
};

const DEFAULT_OPTION = {
    name: DEFAULT_INTEGRATION,
    value: DEFAULT_INTEGRATION,
    label: DEFAULT_INTEGRATION,
};
const ImporterV1: FC = () => {
    const { t } = useTranslation();

    const [files, setFiles] = useState<CustomFile[]>([]);
    const [integration, setIntegration] = useState<Option | undefined>(DEFAULT_OPTION);
    const [writeModification, setWriteModification] = useState<boolean>(true);
    const [email, setEmail] = useState<string>('');

    const { data: me } = useMe();

    useEffect(() => {
        setEmail(me?.email ?? '');
    }, [me]);

    const { mutateAsync, isLoading } = useMutation(
        () =>
            bulkImportV1ApiClient.bulkImportV1({
                file: files[0] as Blob,
                email,
                integration: integration?.value || '',
                do_save: writeModification,
            }),
        {
            onSuccess: () => {
                toast.success(
                    t('admin:page_importer__success_toast__content'),
                    t('admin:page_importer__success_toast__title'),
                );
            },
            onError: (error: any) => {
                toast.error(error.message, t('admin:toast_error__title'));
            },
            onSettled: () => resetForm(),
        },
    );

    const isFormValid = useMemo(() => !!email && !!integration, [email, integration]);

    const submitForm = async () => {
        if (!isFormValid || isLoading) return;
        await mutateAsync();
    };

    const resetForm = () => {
        setFiles([]);
        setIntegration(DEFAULT_OPTION);
        setWriteModification(true);
    };

    const toggleWriteModification = () => setWriteModification(!writeModification);

    return (
        <AdminPage
            dataTrackId="page_old_importer"
            title={t('admin:page_old_importer__title')}
            description={t('admin:page_old_importer__description')}
            infoLink={'https://partoo.elium.com/tile/view/849'}
        >
            <BorderedContainer>
                <PageFormRow>
                    <DropZoneWithoutUpload
                        title={t('drop_zone_label')}
                        acceptedDescription={
                            <DropZoneDescription
                                maxSize={MAX_SIZE}
                                acceptedExtensions={ACCEPTED_FILES}
                            />
                        }
                        type={DropzoneTypeEnum.Document}
                        files={files}
                        setFiles={setFiles}
                        multiple={false}
                        maxSize={MAX_SIZE}
                        full
                        accept={ACCEPTED_FILES}
                    />
                </PageFormRow>
                <PageFormRow>
                    <IntegrationInput
                        selectedValue={integration}
                        onChange={setIntegration}
                        placeholder={t('admin:name_field_integration')}
                    />
                    <EmailInput
                        placeholder={t('admin:receiving_email')}
                        email={email}
                        onChange={setEmail}
                        isMandatory
                        hideExplanation
                    />
                </PageFormRow>
                <PageFormRow>
                    <SwitchInputWrapper>
                        <Switch
                            dataTrackId="toggle_write_modification__switch"
                            name="toggle_write_modification"
                            onChange={toggleWriteModification}
                            checked={writeModification}
                            label={
                                <Text variant="bodyMBold" as="span" color="secondary">
                                    {t('admin:write_modifications')}
                                </Text>
                            }
                        />
                    </SwitchInputWrapper>
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin_old_importer__import_data"
                disabled={!isFormValid || isLoading}
                text={t('admin:page_importer__button_submit')}
                onClick={submitForm}
            />
        </AdminPage>
    );
};

export default ImporterV1;
