import styled from '@emotion/styled';

import { BusinessList } from './BusinessList';

export const BusinessListStyled = styled(BusinessList)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
`;
