import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'admin/common/data/config';

import {
    ConnectionPageContainer,
    ConnectionPageLeftPart,
    ConnectionPageLeftPartContent,
    ConnectionPageRightPart,
    PartooLogoContainer,
} from './Connection.styled';
import { GoogleSignIn } from '../GoogleSignIn/GoogleSignIn';

const Connection = () => {
    const { t } = useTranslation();
    return (
        <ConnectionPageContainer>
            <ConnectionPageLeftPart>
                <ConnectionPageLeftPartContent>
                    <PartooLogoContainer>
                        <img
                            src={`${STATIC_BASE_URL}/images/providers_asset/partoo/partoo_logo.png`}
                            className="logo"
                            alt={t('admin:partoo_logo')}
                        />
                    </PartooLogoContainer>
                    <GoogleSignIn />
                </ConnectionPageLeftPartContent>
            </ConnectionPageLeftPart>
            <ConnectionPageRightPart>
                <img
                    src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/partoo.svg`}
                    alt=""
                />
            </ConnectionPageRightPart>
        </ConnectionPageContainer>
    );
};

export default Connection;
