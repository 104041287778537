import { useState } from 'react';

import { Button, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { UserInfosQueryKeys } from 'admin/content/tools/pages/MultiOrganizations/enums';

import { useMergeUserInfos } from 'admin/content/tools/pages/MultiOrganizations/hooks/useMergeUserInfos';

import { useUsersByUserInfoIds } from 'admin/content/tools/pages/MultiOrganizations/hooks/useUsersByUserInfoIds';

import { MergeAccountsConfirmationModal } from 'admin/content/tools/pages/MultiOrganizations/sections/MergedAccountsModals/modals/MergeAccountsConfirmationModal';

import { AddUserToMerge } from './AddUserToMerge';
import {
    EditMergedAccountsSubtitleContainer,
    MergeAccountsButton,
    MergedAccountsCreateFormContainer,
    MergedAccountsCreateFormTop,
    Title,
    UserList,
    UserListItem,
} from './MergedAccountsEditForm.styled';

export const MergedAccountsEditForm = () => {
    const { t } = useTranslation();

    const [mainUserInfoId, setMainUserInfoId] = useStateQueryParams(
        UserInfosQueryKeys.USER_INFO_ID,
    );
    const [userInfoIds, setUserInfoIds] = useState<number[]>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const allUserInfoIds: Array<number> = [parseInt(mainUserInfoId, 10), ...userInfoIds];

    const usersByUserInfoIdResults = useUsersByUserInfoIds(allUserInfoIds);

    const users = usersByUserInfoIdResults
        .map(query => query.data)
        .filter(Boolean)
        .flat();

    const addNewUserData = (newUserInfoId: number) => {
        if (!userInfoIds.includes(newUserInfoId)) {
            setUserInfoIds([...userInfoIds, newUserInfoId]);
        }
    };

    const mergeUserInfos = useMergeUserInfos(
        () => {
            setMainUserInfoId('');
            setUserInfoIds([]);
            setShowConfirmationModal(false);
        },
        () => {
            setShowConfirmationModal(false);
        },
    );

    const onMergeAccounts = () => {
        mergeUserInfos.mutate({
            mainUserInfoId: parseInt(mainUserInfoId, 10),
            body: {
                user_info_ids: userInfoIds.map(userInfoId => userInfoId),
            },
        });
    };

    // @ts-ignore
    const errorMessage = mergeUserInfos.error?.response?.data?.errors?.json;

    return (
        <MergedAccountsCreateFormContainer>
            <MergedAccountsCreateFormTop>
                <Title variant="heading2">{t('admin:org_switcher_merge_page_title')}</Title>
                <EditMergedAccountsSubtitleContainer>
                    <Text variant="bodyLMedium" color="secondary">
                        {t('admin:org_switcher_merge_page_subtitle_orgs')}
                    </Text>
                    <span>&nbsp;</span>
                    <Text variant="bodyLSemibold" color="secondary">
                        {users?.[0]?.email}
                    </Text>
                </EditMergedAccountsSubtitleContainer>

                <UserList>
                    {users?.map(user => (
                        <UserListItem key={user?.user_id}>
                            <Text variant="bodyMSemibold">
                                {user?.org_name} ({user?.org_id})
                            </Text>
                        </UserListItem>
                    ))}
                </UserList>

                <AddUserToMerge onAddUserInfo={addNewUserData} allUserInfoIds={allUserInfoIds} />
            </MergedAccountsCreateFormTop>
            <MergeAccountsButton>
                <Button
                    dataTrackId="merge_accounts_button"
                    variant={mergeUserInfos.error ? 'danger' : 'primary'}
                    size="large"
                    full
                    disabled={!userInfoIds.length}
                    onClick={() => setShowConfirmationModal(true)}
                    isLoading={mergeUserInfos.isLoading}
                >
                    {!!errorMessage ? errorMessage : t('admin:org_switcher_merge_accounts_CTA')}
                </Button>
            </MergeAccountsButton>
            <MergeAccountsConfirmationModal
                onClose={() => setShowConfirmationModal(false)}
                opened={showConfirmationModal}
                onConfirm={onMergeAccounts}
            />
        </MergedAccountsCreateFormContainer>
    );
};
