import { UploadAudioResponse } from '@partoohub/api-client';
import { useMutation } from 'react-query';

import { messagingCallDetailsApiClient } from 'admin/api/apiResources';

export const useUploadAudio = (
    onSuccess: (response: UploadAudioResponse) => void = () => undefined,
    onError: (error: Error) => void = () => undefined,
) => {
    return useMutation((payload: File) => messagingCallDetailsApiClient.uploadAudio(payload), {
        onSuccess,
        onError,
    });
};
