import { Button, FontAwesomeIconsPartooUsed, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { KeywordTitleContainer } from './KeywordTitle.styled';

type Props = {
    action?: () => void;
};
export const KeywordTitle = ({ action }: Props) => {
    const { t } = useTranslation();

    return (
        <KeywordTitleContainer>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="column" gap="unset">
                    <Text variant="heading6" as="span">
                        {t('admin:page_competitive_benchmark__keyword_title')}
                    </Text>
                    <Text variant="bodyMRegular" color="secondary" as="span">
                        {t('admin:page_competitive_benchmark__keyword_description')}
                    </Text>
                </Stack>
                {action && (
                    <Button
                        dataTrackId="keyword-section-add-keyword"
                        appearance="contained"
                        shape="cube"
                        size="medium"
                        variant="primary"
                        onClick={() => action()}
                        icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.REGULAR]}
                    >
                        {t('admin:page_competitive_benchmark__add_keyword_button')}
                    </Button>
                )}
            </Stack>
        </KeywordTitleContainer>
    );
};
