import { SuccessResponse } from '@partoohub/api-client';

import {
    AdminBulkCreateServiceParamsType,
    FreeFormServiceCreateParamsType,
    FreeFormServiceUpdateParamsType,
    ServiceSuggestionGetParamsType,
    ServicesGetDataType,
    ServicesSuggestionsGetDataType,
    StructuredServiceCreateParamsType,
    StructuredServiceUpdateParamsType,
} from 'app/api/types/services';
import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';

import ApiResourceBaseClass from './baseApiCalls';
import { RESOURCE_URI as BUSINESS_RESOURCE_URI } from './businessApiCalls';

const RESOURCE_URI = 'services';
const FREE_FORM_SERVICE_RESOURCE_URI = `free_form_${RESOURCE_URI}`;
const STRUCTURED_SERVICE_RESOURCE_URI = `structured_${RESOURCE_URI}`;

class Services extends ApiResourceBaseClass {
    static isServicesAvailable(businessId: string): Promise<boolean> {
        return Services.get(`${BUSINESS_RESOURCE_URI}/${businessId}/is_services_available`).then(
            ({ data }) => data,
        );
    }

    static getServices(businessId: string): Promise<ServicesGetDataType> {
        return Services.get(`${BUSINESS_RESOURCE_URI}/${businessId}/${RESOURCE_URI}`).then(
            ({ data }) => data,
        );
    }

    static getServicesSuggestions(
        queryParams: ServiceSuggestionGetParamsType,
    ): Promise<ServicesSuggestionsGetDataType> {
        return Services.get(`${RESOURCE_URI}/suggestions`, queryParams).then(({ data }) => data);
    }

    static getServicesSuggestionsCategory(
        category: string,
        country: string,
        lang: string,
    ): Promise<ServicesSuggestionsGetDataType> {
        const queryParams = { category, country, lang };
        return Services.get(`${RESOURCE_URI}/suggestions_category`, queryParams).then(
            ({ data }) => data,
        );
    }

    static deleteService(businessId: string, serviceId: number): Promise<SuccessResponse> {
        return Services.delete(
            `${BUSINESS_RESOURCE_URI}/${businessId}/${RESOURCE_URI}/${serviceId}`,
        ).then(({ data }) => data);
    }

    static createStructuredService(
        businessId: string,
        queryParams: StructuredServiceCreateParamsType,
    ): Promise<ServicesGetDataType> {
        return Services.post(
            `${BUSINESS_RESOURCE_URI}/${businessId}/${STRUCTURED_SERVICE_RESOURCE_URI}`,
            queryParams,
        ).then(({ data }) => data);
    }

    static createFreeFormService(
        businessId: string,
        queryParams: FreeFormServiceCreateParamsType,
    ): Promise<ServicesGetDataType> {
        return Services.post(
            `${BUSINESS_RESOURCE_URI}/${businessId}/${FREE_FORM_SERVICE_RESOURCE_URI}`,
            queryParams,
        ).then(({ data }) => data);
    }

    static updateStructuredService(
        businessId: string,
        queryParams: StructuredServiceUpdateParamsType,
    ): Promise<ServicesGetDataType> {
        return Services.post(
            `${BUSINESS_RESOURCE_URI}/${businessId}/${STRUCTURED_SERVICE_RESOURCE_URI}`,
            queryParams,
        ).then(({ data }) => data);
    }

    static updateFreeFormService(
        businessId: string,
        queryParams: FreeFormServiceUpdateParamsType,
    ): Promise<ServicesGetDataType> {
        return Services.post(
            `${BUSINESS_RESOURCE_URI}/${businessId}/${FREE_FORM_SERVICE_RESOURCE_URI}`,
            queryParams,
        ).then(({ data }) => data);
    }
}

export class ServicesAdmin extends AdminApiResourceBaseClass {
    static adminBulkCreateService(
        queryParams: AdminBulkCreateServiceParamsType,
    ): Promise<SuccessResponse> {
        return ServicesAdmin.post(`${RESOURCE_URI}/bulk_create_services`, queryParams).then(
            ({ data }) => data,
        );
    }
}

export default Services;
