import { Button, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'admin/common/data/config';

import {
    DeleteModalButtons,
    DeleteModalContainer,
    DeleteModalDescription,
    DeleteModalImage,
    DeleteModalTitle,
} from './DeleteModal.styled';

type DeleteModalProps = {
    title: string;
    onClose: () => void;
    opened: boolean;
    onConfirm: () => void;
};

export const DeleteModal = ({ opened, title, onClose, onConfirm }: DeleteModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={opened} closeModal={onClose}>
            <DeleteModalContainer>
                <DeleteModalImage>
                    <img src={`${STATIC_BASE_URL}/images/app/delete_modal_image.svg`} alt="" />
                </DeleteModalImage>
                <DeleteModalTitle>{title}</DeleteModalTitle>
                <DeleteModalDescription>{t('delete_modal_subtitle')}</DeleteModalDescription>
                <DeleteModalButtons>
                    <Button
                        appearance="outlined"
                        variant="secondary"
                        dataTrackId="delete_group_cancel"
                        onClick={onClose}
                        size="large"
                        shape="cube"
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        dataTrackId="delete_group_delete"
                        variant="danger"
                        onClick={onConfirm}
                        size="large"
                        shape="cube"
                    >
                        {t('delete')}
                    </Button>
                </DeleteModalButtons>
            </DeleteModalContainer>
        </Modal>
    );
};
