import { SuccessResponse } from 'app/api/types';
import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';

const RESOURCE_URI = 'actions';

export class AdminActionsApiCall extends AdminApiResourceBaseClass {
    static userActions({
        user_ids,
        action_type,
        products,
        migration_org,
    }: {
        user_ids: string;
        action_type: string;
        products: string | undefined;
        migration_org: string;
    }): Promise<SuccessResponse> {
        return AdminActionsApiCall.post(`${RESOURCE_URI}/user`, {
            action_type: action_type,
            user_ids: user_ids,
            products: products,
            migration_org: migration_org,
        }).then(({ data }) => data);
    }
}
