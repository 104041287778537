import styled from '@emotion/styled';

export const DeleteModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 24px;
    text-align: center;
`;

export const DeleteModalImage = styled.div`
    margin-bottom: 16px;

    > img {
        width: 320px;
    }
`;

export const DeleteModalTitle = styled.div`
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 8px;
`;

export const DeleteModalDescription = styled.div`
    margin-bottom: 32px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const DeleteModalButtons = styled.div`
    display: flex;
    gap: 16px;

    > * {
        display: flex;
        flex-basis: 100%;
    }
`;
