import { ServiceSuggestionType, ServiceTypeEnum } from '@partoohub/api-client';
import {
    Button,
    Chip,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    Option,
    Stack,
    Text,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { CategoryCell } from './ServicesList.styled';

type SectionFooterProps = {
    suggestionsToDisplay: ServiceSuggestionType[];
    category: Option;
    openAddServiceModal: (type: string, name: string, serviceId?: number) => void;
};

export const SectionFooter = ({
    suggestionsToDisplay,
    category,
    openAddServiceModal,
}: SectionFooterProps) => {
    const { t } = useTranslation();
    return (
        <CategoryCell>
            <Stack gap="8px" direction="row" alignItems="baseline" wrap>
                {suggestionsToDisplay.map(suggestion => (
                    <div key={`chip_suggestion_${suggestion.service_id}_for_${category.value}`}>
                        <Chip
                            dataTrackId={`service-list__${suggestion.name}__chip_suggestion`}
                            onClick={() => {
                                openAddServiceModal(
                                    ServiceTypeEnum.STRUCTURED_SERVICE_ITEM,
                                    suggestion.name,
                                    suggestion.service_id,
                                );
                            }}
                        >
                            <Stack gap="4px" direction="row" alignItems="center">
                                <Icon
                                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                                    iconSize="12px"
                                    color="primary"
                                />
                                <Text as="span" variant="bodySBold" color="primary">
                                    {suggestion.name}
                                </Text>
                            </Stack>
                        </Chip>
                    </div>
                ))}
                <Button
                    dataTrackId="service-list__add_personalized_service"
                    appearance="text"
                    variant="primary"
                    icon={[FontAwesomeIconsPartooUsed.faPlusCircle]}
                    onClick={() => openAddServiceModal(ServiceTypeEnum.FREE_FORM_SERVICE_ITEM, '')}
                >
                    {t('add_personalized_service_button')}
                </Button>
            </Stack>
        </CategoryCell>
    );
};
