import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { CategoryData } from 'app/api/types/categories';

import FLAG from 'admin/common/data/flags.json';

import { LANGUAGES_MAP, SUPPORTED_LANGUAGES } from 'admin/common/data/languages';

import {
    CategoryTranslationItem,
    CategoryTranslationsContainer,
    CategoryTranslationsList,
    FlagContainer,
    StyledTitle,
} from './CategoryTranslation.styled';

interface Props {
    selectedCategory: CategoryData | null;
}

const getTranslatedName = (category: CategoryData, lang = 'EN'): string => {
    let i;
    for (i = 0; i < category.names.length; i += 1) {
        if (category.names[i].lang === lang) {
            return category.names[i].name;
        }
    }
    return 'admin_categories_missinglabel';
};

export const CategoryTranslation = ({ selectedCategory }: Props) => {
    const { t } = useTranslation();
    return (
        <CategoryTranslationsContainer>
            <StyledTitle variant="heading6">
                {t('admin_categories_obsolete_title_translationsblock')}
            </StyledTitle>
            {selectedCategory && selectedCategory.names && (
                <CategoryTranslationsList>
                    {SUPPORTED_LANGUAGES.map(lang => {
                        const flags = FLAG as Record<string, string>;
                        return (
                            <CategoryTranslationItem key={lang}>
                                <FlagContainer>
                                    <img
                                        src={flags[LANGUAGES_MAP[lang]?.country]}
                                        alt={t(`original-${lang}`)}
                                    />
                                </FlagContainer>
                                <Text
                                    variant="bodyMSemibold"
                                    as="span"
                                    disabled={
                                        getTranslatedName(selectedCategory, lang) ===
                                        'admin_categories_missinglabel'
                                    }
                                    color={
                                        getTranslatedName(selectedCategory, lang) ===
                                        'admin_categories_missinglabel'
                                            ? 'secondary'
                                            : 'default'
                                    }
                                >
                                    {t(getTranslatedName(selectedCategory, lang))}
                                </Text>
                            </CategoryTranslationItem>
                        );
                    })}
                </CategoryTranslationsList>
            )}
        </CategoryTranslationsContainer>
    );
};
