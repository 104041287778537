export const GoToServiceDeskIcon = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_36452_12330)">
            <mask
                id="mask0_36452_12330"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="4"
                y="4"
                width="32"
                height="32"
            >
                <path d="M4 4H36V36H4V4Z" fill="white" />
            </mask>
            <g mask="url(#mask0_36452_12330)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.9917 33.1208C17.4001 33.1208 16.9209 32.6415 16.9209 32.05V30.9792H23.0792V32.05C23.0792 32.6415 22.6 33.1208 22.0084 33.1208H17.9917ZM15.0459 32.05C15.0459 33.6768 16.3642 34.9958 17.9917 34.9958H22.0084C23.6359 34.9958 24.9542 33.6768 24.9542 32.05V30.0417C24.9542 29.5239 24.5345 29.1042 24.0167 29.1042H15.9834C15.4656 29.1042 15.0459 29.5239 15.0459 30.0417V32.05Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.9999 10.8958C15.5318 10.8958 11.8999 14.5257 11.8999 18.9958C11.8999 21.4242 12.9693 23.599 14.6793 25.0603L14.6945 25.0736C15.8731 26.1255 16.6339 27.5563 16.8543 29.1033L23.1758 29.104C23.3957 27.5656 24.1516 26.1421 25.3255 25.0952L25.332 25.0894C27.0304 23.6026 28.0998 21.4234 28.0998 18.9958C28.0998 14.5254 24.4726 10.8958 19.9999 10.8958ZM10.0249 18.9958C10.0249 13.4899 14.4965 9.02075 19.9999 9.02075C25.5085 9.02075 29.9748 13.4902 29.9748 18.9958C29.9748 21.9854 28.6559 24.67 26.5705 26.4972C25.5617 27.3984 24.9842 28.6879 24.9842 30.0416C24.9842 30.2903 24.8854 30.5288 24.7096 30.7046C24.5337 30.8804 24.2953 30.9792 24.0466 30.9791L15.9831 30.9782C15.4654 30.9781 15.0457 30.5584 15.0457 30.0407C15.0457 28.6812 14.465 27.3854 13.453 26.4787C11.3419 24.6706 10.0249 21.9829 10.0249 18.9958Z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8168 4.07126C14.2951 3.87293 14.8435 4.09988 15.0419 4.57816L15.8703 6.57604C16.0686 7.05432 15.8417 7.60281 15.3634 7.80113C14.8851 7.99945 14.3366 7.77251 14.1383 7.29423L13.3099 5.29635C13.1115 4.81807 13.3385 4.26958 13.8168 4.07126ZM26.1833 4.07126C26.6615 4.26958 26.8885 4.81807 26.6902 5.29635L25.8617 7.29423C25.6634 7.77251 25.1149 7.99945 24.6366 7.80113C24.1584 7.60281 23.9314 7.05432 24.1297 6.57604L24.9582 4.57816C25.1565 4.09988 25.705 3.87293 26.1833 4.07126ZM5.07548 12.8138C5.27357 12.3354 5.82196 12.1082 6.30033 12.3063L8.29865 13.1338C8.77702 13.3319 9.00423 13.8802 8.80614 14.3586C8.60804 14.837 8.05966 15.0642 7.58128 14.8661L5.58297 14.0386C5.1046 13.8405 4.87739 13.2921 5.07548 12.8138ZM34.9245 12.8138C35.1226 13.2921 34.8954 13.8405 34.417 14.0386L32.4187 14.8661C31.9403 15.0642 31.3919 14.837 31.1938 14.3586C30.9957 13.8802 31.223 13.3319 31.7013 13.1338L33.6996 12.3063C34.178 12.1082 34.7264 12.3354 34.9245 12.8138ZM8.80614 23.6326C9.00423 24.1109 8.77702 24.6593 8.29865 24.8574L6.30033 25.6849C5.82196 25.883 5.27357 25.6558 5.07548 25.1774C4.87739 24.699 5.1046 24.1507 5.58297 23.9526L7.58128 23.1251C8.05966 22.927 8.60804 23.1542 8.80614 23.6326ZM31.1938 23.6326C31.3919 23.1542 31.9403 22.927 32.4187 23.1251L34.417 23.9526C34.8954 24.1507 35.1226 24.699 34.9245 25.1774C34.7264 25.6558 34.178 25.883 33.6996 25.6849L31.7013 24.8574C31.223 24.6593 30.9957 24.1109 31.1938 23.6326Z"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_36452_12330">
                <rect width="32" height="32" fill="white" transform="translate(4 4)" />
            </clipPath>
        </defs>
    </svg>
);
