import React, {
    ComponentProps,
    MouseEvent,
    ReactNode,
    useContext,
} from 'react';

import { CSSTransition } from 'react-transition-group';

import FlipAnimated from 'app/common/designSystem/components/atoms/FlipAnimated';
import { PositionedPortal } from 'app/common/utils/PositionedPortal';

import {
    Container,
    EmptyCard,
    Icon,
    ItemCard,
    MenuCard,
    RelativeContainer,
    SectionCard,
    SectionCardBody,
    SectionCardHeader,
} from './Menu.styled';
import FlipSizeAnimated from '../../../atoms/FlipSizeAnimated/FlipSizeAnimated';
import { ToggleMenuContext } from '../contexts/ToggleMenuContext';

const ANIM_DURATION = 300;
const MAX_MENU_HEIGHT = 374; // pixels

export interface MenuProps {
    children: ReactNode;
    referenceElement: HTMLElement | null;
    id?: string;
}

const Menu = React.forwardRef<
    HTMLDivElement,
    MenuProps & ComponentProps<'div'>
>(({ children, referenceElement, id, ...props }, ref) => {
    // we need to re-render when opening / closing sections so that the height get animated
    useContext(ToggleMenuContext);

    if (referenceElement === null) {
        return null;
    }

    return (
        <FlipSizeAnimated duration={ANIM_DURATION} maxHeight={MAX_MENU_HEIGHT}>
            <PositionedPortal
                referenceElement={referenceElement}
                position="bottom"
                yOffset={4}
                sameWidthAsReference
            >
                <MenuCard
                    maxHeight={MAX_MENU_HEIGHT}
                    id={id}
                    ref={ref}
                    {...props}
                >
                    {children}
                </MenuCard>
            </PositionedPortal>
        </FlipSizeAnimated>
    );
});

interface SectionProps {
    children: ReactNode;
    title: ReactNode;
    opened?: boolean;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Section = ({ children, title, opened = true, onClick }: SectionProps) => (
    <SectionCard>
        <FlipAnimated duration={ANIM_DURATION} easing="linear">
            <SectionCardHeader onClick={onClick}>
                {title}
                <Icon
                    className="fa-solid fa-angle-right"
                    direction={opened ? 'down' : 'right'}
                />
            </SectionCardHeader>
        </FlipAnimated>
        <RelativeContainer>
            <Container position={opened ? 'static' : 'absolute'}>
                <CSSTransition
                    in={opened}
                    timeout={ANIM_DURATION}
                    classNames="fade"
                    unmountOnExit
                >
                    <SectionCardBody opened={opened}>
                        {children}
                    </SectionCardBody>
                </CSSTransition>
            </Container>
        </RelativeContainer>
    </SectionCard>
);

interface ItemProps {
    children: ReactNode;
}

const Item = ({ children, ...props }: ItemProps & ComponentProps<'div'>) => (
    <ItemCard {...props}>{children}</ItemCard>
);

interface EmptyProps {
    children: ReactNode;
}

const Empty = ({ children, ...props }: EmptyProps & ComponentProps<'div'>) => (
    <EmptyCard {...props}>{children}</EmptyCard>
);

export default Object.assign(Menu, {
    Section,
    Item,
    Empty,
});
