import { CategoryData } from 'app/api/types/categories';

export const WARNING = 'warning';
export const DANGER = 'danger';
export const SUCCESS = 'success';
export type CategoryStatusType = 'warning' | 'danger' | 'success';

export const cellCategoryListing = {
    business_count: '110px',
    status: '60px',
    action: '40px',
};

export const formatGmbId = (gmbId: string): string => gmbId.split(':')[1];

export const getCategoryStatus = (
    categoryState: string,
    missingMapping: boolean,
): CategoryStatusType => {
    if (categoryState === 'DEPRECATED') return DANGER;
    if (missingMapping) return WARNING;
    return SUCCESS;
};

export const formatCategoryData = (category: CategoryData) => {
    return {
        name: category.name ?? null,
        formattedGmbId: formatGmbId(category.gmb_id),
        status: getCategoryStatus(category.state, category.missing_mappings),
        categoryId: category.id,
        impactedBusinesses: category.impacted_businesses,
    };
};

export const buildQueryParams = (searchParams: URLSearchParams, currentSearch?: string) => {
    return {
        state: searchParams.get('state'),
        missing_mappings: searchParams.get('missing_mappings'),
        order_by: searchParams.get('order_by') || '-impacted_businesses',
        impacted_businesses__gt: searchParams.get('impacted_businesses__gt'),
        search_query: currentSearch,
        with_names: true,
    };
};
