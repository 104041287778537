import React, {
    ComponentProps,
    MouseEvent,
    useContext,
    useEffect,
} from 'react';

import {
    ToggleMenuContext,
    ToggleMenuContextProvider,
} from 'app/common/designSystem/components/molecules/AsyncSubMenuMultipleSelect/contexts/ToggleMenuContext';
import { useMountId } from 'app/common/hooks/useMountId';

import Menu from './Menu';

/*
 * This file contains thin wrappers over the base stateless components. These wrappers
 * implement some internal state management (setting up the ToggleMenuContext, forward
 * computed height, etc.)
 */

const ToggleGroupMenu = React.forwardRef<
    HTMLDivElement,
    ComponentProps<typeof Menu>
>((props, ref) => (
    <ToggleMenuContextProvider>
        <Menu ref={ref} {...props} />
    </ToggleMenuContextProvider>
));

const Section = (
    props: Exclude<ComponentProps<typeof Menu.Section>, 'opened'>,
) => {
    const { toggleSection, sections, registerSection, unregisterSection } =
        useContext(ToggleMenuContext);
    const mountId = useMountId();
    const opened = !!sections[mountId]?.opened;

    const { onClick, ...rest } = props;

    useEffect(() => {
        registerSection(mountId);
        return () => unregisterSection(mountId);
    }, []);

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        toggleSection(mountId);
        onClick?.(event);
    };

    return <Menu.Section {...rest} onClick={handleClick} opened={opened} />;
};

export default Object.assign(ToggleGroupMenu, {
    Item: Menu.Item,
    Empty: Menu.Empty,
    Section,
});
