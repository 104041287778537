import AdminApiResourceBaseClass from 'admin/common/api/calls/baseAdminApiCalls';

const RESOURCE_URI = 'reviews';

class ReviewApiCalls extends AdminApiResourceBaseClass {
    static getWrongGmbLocationCount(business_id: string): Promise<Record<'count', number>> {
        return ReviewApiCalls.get(`${RESOURCE_URI}/get_wrong_gmb_location_count`, {
            business_id,
        }).then(({ data }) => data);
    }

    static cleanGmbReviews(business_id: string): Promise<Record<'deleted', number>> {
        return ReviewApiCalls.post(`${RESOURCE_URI}/clean_gmb_reviews`, {
            business_id,
        }).then(({ data }) => data);
    }
}

export default ReviewApiCalls;
