import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

type ProspectsFormData = {
    email: string;
    query: string;
    type?: string;
    code: string;
};

const RESOURCE_URI = 'business/search-prospects';
export class Prospects extends ApiResourceBaseClass {
    static search(body: ProspectsFormData): Promise<Record<string, any>> {
        return Prospects.post(`${RESOURCE_URI}`, body).then(({ data }) => data);
    }
}
