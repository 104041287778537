import { css } from '@emotion/react';

import { HEX_COLORS } from '@partoohub/branding';
import { reset } from '@partoohub/ui';

const resetStyle = css`
    ${reset}
    * {
        margin: 0;
    }
    body {
        font-size: 14px;
        line-height: 1.42857143;
        color: ${HEX_COLORS.blackberry};
        -webkit-font-smoothing: antialiased;
    }
`;

const globalAnimation = css`
    @-webkit-keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
        }

        100% {
            -webkit-transform: scale(1);
            opacity: 0;
        }
    }

    @keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 0;
        }
    }

    // Use infinite scroll
    @keyframes react-placeholder-pulse {
        0% {
            opacity: 0.6;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.6;
        }
    }
`;

const globalApp = css`
    :root {
        --pto-font-family: 'Outfit';
        --pto-primary: #0085f2;
        --pto-primary-light: #42b7f5;
        --pto-primary-dark: #027fc1;
        --pto-primary-transparent: rgba(3, 159, 241, 0.12);
        --pto-primary-medium: rgba(3, 159, 241, 0.8);
        --pto-primary-hover: rgba(3, 159, 241, 0.08);
        --pto-blackberry-transparent: rgba(20, 33, 58, 0.12);
    }

    #react_app {
        width: 100%;
        height: 100%;
    }

    #react_app > div {
        height: 100%;
    }

    a:focus,
    a:active {
        outline: 0 !important;
        border-radius: 0;
        background-color: transparent;
    }

    input[type='checkbox']:focus,
    input[type='checkbox']:active {
        outline: 0 !important;
    }

    input[type='time']::-webkit-calendar-picker-indicator {
        background: none;
    }

    .checkbox label::before,
    .radio label::before {
        outline: 0 !important;
    }

    .navbar {
        border-radius: 0;
        border: none;
    }

    .noselect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Chrome/Safari/Opera */
        -khtml-user-select: none;
        /* Konqueror */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently not supported by any browser */
    }

    span.mandatory-field {
        margin-left: 2px;
        color: #ff426e;
    }

    // Use inside jssdk
    .hide {
        display: none !important;
    }

    input[type='number'] {
        -moz-appearance: textfield; /* This removes the spinner from Firefox */

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; /* This removes the spinner from WebKit browsers like Chrome and Safari */
        }
    }

    ${globalAnimation};
`;

export const global = css`
    ${resetStyle};
    ${globalApp};

    html {
        height: 100%;
    }

    body {
        height: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: var(--pto-font-family), Helvetica, sans-serif;
        font-variant: no-common-ligatures;
    }

    select {
        letter-spacing: inherit;
    }

    // reset : useful for correctly positioning the ball of the switch component
    input[type='checkbox'],
    input[type='radio'] {
        margin: 0;
    }

    a,
    a:hover,
    a:focus {
        text-decoration: none;
    }

    label {
        margin-bottom: 0;
    }
`;
