import { V2BusinessData } from '@partoohub/api-client';

import {
    BusinessModalFilters,
    GroupFilter,
} from 'app/common/components/businessModal/context/businessModalFiltersReducer';
import { businessTsQuerySimple } from 'app/common/utils/tsQuerySimple';

// Returns if the business matches the selected groups filters
const matchGroupV2 = (
    businessGroups: Array<string>,
    filters: Array<string>,
    sectionMapping: Record<string, string | undefined>,
): boolean => {
    const sectionsInFilter = new Set();
    const matchedSections = new Set();

    for (const group of filters) {
        const section = sectionMapping[group];
        if (section === undefined) {
            return false;
        }
        sectionsInFilter.add(section);
        if (businessGroups.includes(group)) {
            matchedSections.add(section);
        }
    }

    return sectionsInFilter.size === matchedSections.size;
};

export const matchAccesses = (
    businessGroups: Array<string>,
    accesses: Array<Array<string>>,
    sectionMapping: Record<string, string | undefined>,
): boolean => {
    return accesses.some(access => matchGroupV2(businessGroups, access, sectionMapping));
};

const businessIsSelected = <T extends BusinessModalFilters>(
    selection: T['selection'],
    business: V2BusinessData,
    foundSelectedBusinessIds: Array<string>,
    groupV2ToSection: Record<string, string> = {},
): boolean => {
    const { selectAll, selectedIds, unselectedIds, filters } = selection;

    // Manually selected / unselected businesses
    if (selectedIds.includes(business.id)) {
        return true;
    } else if (unselectedIds.includes(business.id)) {
        return false;
    }

    // Handle select all fetched businesses
    if (foundSelectedBusinessIds.includes(business.id)) {
        return true;
    } else if (!selectAll) {
        return false;
    }

    // Check all filters in case of select all but not fetched business

    // Query
    const { query } = selection;
    const hasQuery = query !== '';

    if (hasQuery) {
        const matchesQuery =
            // Not 100% reliable, that's why we check foundSelectedBusinessIds first
            businessTsQuerySimple(business, query);

        if (!matchesQuery) {
            return false;
        }
    }

    // City
    const cityFilters = filters.filter(([ns]) => ns === 'city');
    const hasCityFilters = !!cityFilters.length;

    if (hasCityFilters) {
        const matchesCityFilters = cityFilters.some(([, { id: city }]) => business.city === city);

        if (!matchesCityFilters) {
            return false;
        }
    }

    // Groups
    const groupFilters = filters.filter(([type]) => type === 'group') as Array<GroupFilter>;
    const hasGroupFilters = !!groupFilters.length;

    if (hasGroupFilters) {
        const groupFilterIds: Array<string> = groupFilters
            .map(([, { id: groupIds }]) => groupIds)
            .flat(1);

        const matchesGroupFilters = matchGroupV2(
            (business.groups ?? []).map(groupId => groupId.toString()),
            groupFilterIds,
            groupV2ToSection,
        );

        if (!matchesGroupFilters) {
            return false;
        }
    }

    // Orgs
    const orgFilters = filters.filter(([ns]) => ns === 'org');
    const hasOrgFilters = !!orgFilters.length;

    if (hasOrgFilters) {
        const matchesOrgFilters = orgFilters.some(
            ([, { id: orgId }]) => orgId === business.org_id.toString(),
        );

        if (!matchesOrgFilters) {
            return false;
        }
    }

    // It matches all filters
    return true;
};

export default businessIsSelected;
