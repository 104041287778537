import { PublisherRawData } from 'app/api/types/publisher';

import ApiResourceBaseClass from './baseApiCalls';
import { ReceivedPartnerData } from '../../types';

const RESOURCE_URI = 'publisher';

class Publisher extends ApiResourceBaseClass {
    static getPublishersForUser(): Promise<Array<PublisherRawData>> {
        return Publisher.get(`${RESOURCE_URI}`).then(
            ({ data: { publishers } }) => publishers,
        );
    }

    static fetchPartnersRef(): Promise<ReceivedPartnerData> {
        return Publisher.get(`${RESOURCE_URI}`).then(({ data }) => data);
    }
}

export default Publisher;
