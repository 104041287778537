import useCalculateBusinessCount from 'app/common/components/businessModal/hooks/privateHooks/useCalculateBusinessCount';
import useReduxStateToLocalState from 'app/common/components/businessModal/hooks/privateHooks/useReduxStateToLocalState';

/**
 * Number of selected businesses in the business modal. Uses the redux store information
 * Returns undefined if the query is not ready and 0 if no business is selected
 */
export default function useSelectedBusinessesCount(): number | undefined {
    const { selection } = useReduxStateToLocalState();
    return useCalculateBusinessCount(selection);
}
