import { LoadingView } from 'admin/common/components/loader/LoadingView';

import { useMergedUsers } from 'admin/content/tools/pages/MultiOrganizations/hooks/useMergedUsers';

import { MergedAccountsList } from './MergedAccountsList';
import { NoMergedAccountPlaceholder } from './NoMergedAccountPlaceholder';

export const MergedAccountsPage = () => {
    const { query, userInfos } = useMergedUsers();
    const { isLoading } = query;

    if (isLoading) {
        return <LoadingView />;
    }

    if (!userInfos.length) {
        return <NoMergedAccountPlaceholder />;
    }

    return <MergedAccountsList />;
};
