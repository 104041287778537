import { ComponentType, ReactNode } from 'react';

import { ExplanationWithCount } from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithCount/ExplanationWithCount';
import ExplanationWithIcons from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithIcons';
import ExplanationWithPublisherList, {
    PublisherErrors,
    PublishersInfo,
} from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';
import getDisplayName from 'app/common/utils/getDisplayName';

export type ExplanationHOCProps = {
    children?: ReactNode;
    explanation?: ReactNode;
    // Optional props
    hasError?: boolean;
    hasWarning?: boolean;
    disabled?: boolean;
    hideExplanation?: boolean;
    selectedOptionsLimit?: number;
    value?: any;
    characterCount?: number;
    content?: Array<ReactNode>; // Not sure if really used
    selectedOptions?: any;
    publisherErrors?: PublisherErrors;
    publishersInfo?: PublishersInfo;
    errorMessage?: string;
};

const statusType = (hasError, hasWarning) => {
    if (hasError) {
        return 'color--danger';
    }

    if (hasWarning) {
        return 'color--warning';
    }

    return 'color--secondary';
};

const ExplanationWrapper = ({
    children,
    explanation,
    disabled,
    hasError,
    hasWarning,
}: ExplanationHOCProps) => (
    <div className="flex-1">
        {children}
        <div
            className={`mdc-text-field-helper-line font-small
                ${statusType(hasError, hasWarning)}
                ${disabled ? 'text-field__disabled' : ''}`}
        >
            {explanation}
        </div>
    </div>
);

function explanationHOC<T extends ExplanationHOCProps = ExplanationHOCProps>(
    Input: ComponentType<T>,
) {
    function WithExplanation(props: T) {
        const {
            hideExplanation,
            selectedOptionsLimit,
            value,
            characterCount,
            content,
            selectedOptions,
            hasError = false,
            hasWarning = false,
            explanation,
            disabled = false,
            publisherErrors = {},
            publishersInfo = {},
            errorMessage,
        } = props;
        // We should find a more elegant way to handle this
        // Priority : ERROR_MESSAGE > PUBLISHER > EXPLANATION_COUNT > EXPLANATION

        if (hideExplanation) {
            return <Input {...props} />;
        }

        let explanationComponent;

        if (content) {
            explanationComponent = (
                <ExplanationWithIcons explanation={explanation} content={content} />
            );
        }

        if (errorMessage) {
            explanationComponent = errorMessage;
        } else if (
            hasWarning &&
            publisherErrors &&
            publishersInfo &&
            Object.keys(publisherErrors).length > 0
        ) {
            explanationComponent = (
                <ExplanationWithPublisherList
                    publisherErrors={publisherErrors}
                    publishersInfo={publishersInfo}
                    disabled={disabled}
                />
            );
        } else if (characterCount || selectedOptionsLimit) {
            explanationComponent = (
                <ExplanationWithCount
                    explanation={explanation}
                    characterCount={characterCount || selectedOptionsLimit}
                    value={value || selectedOptions}
                    hasError={hasError}
                    disabled={disabled}
                />
            );
        } else if (explanation) {
            explanationComponent = explanation;
        }

        return (
            <ExplanationWrapper
                hasError={hasError}
                hasWarning={hasWarning}
                explanation={explanationComponent}
                disabled={disabled}
            >
                <Input hasError={hasError} hasWarning={hasWarning} disabled={disabled} {...props} />
            </ExplanationWrapper>
        );
    }

    WithExplanation.displayName = `WithExplanation(${getDisplayName(Input)})`;
    return WithExplanation;
}

export default explanationHOC;
