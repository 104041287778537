import styled from '@emotion/styled';

export const BusinessPartnerLogo = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-left: 16px;
        margin-right: 16px;
    }
`;
