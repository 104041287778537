import { FC, useEffect, useState } from 'react';

import { Option, Section, SingleSelect } from '@partoohub/ui';
import { useQuery } from 'react-query';

import { DemoCreatorAdminApiCall } from 'app/api/v2/api_calls/demoCreatorApiCalls';

import { convertOrgToSelectOption } from 'admin/common/components/inputs/OrganizationSelect/convertOrgToOption';
import { ORGANIZATIONS } from 'admin/common/data/queryKeysConstants';

type Props = {
    org?: Option;
    setOrg: (selectedItem?: Option) => void;
    dataTrackId: string;
    placeholder?: string;
};

export const OrganizationSelect: FC<Props> = ({
    org,
    setOrg,
    dataTrackId,
    placeholder = 'Organization',
}: Props) => {
    const [searchValue, setSearchValue] = useState('');
    const [section, setSection] = useState<Section[]>([]);
    const { data, isLoading } = useQuery([ORGANIZATIONS, { searchValue }], () =>
        DemoCreatorAdminApiCall.getOrgsForDemoCreator({
            query: searchValue,
        }),
    );

    useEffect(() => {
        if (!isLoading) {
            const options =
                data?.orgs?.flat()?.map(organization => convertOrgToSelectOption(organization)) ||
                [];
            const section = [{ options: options }];
            setSection(section);
        }
    }, [data]);

    const handleChange = (selectedOptions?: Option): void => {
        setOrg(selectedOptions);
    };

    return (
        <SingleSelect
            dataTrackId={dataTrackId}
            label={placeholder}
            sections={section}
            selectedValue={org}
            onChange={handleChange}
            onSearch={setSearchValue}
            maxHeight={300}
            required
        />
    );
};
