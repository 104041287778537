import { useCallback, useMemo } from 'react';

import { FilterCategory, FilterName, Filters, PartialFilters } from '@partoohub/modular-components';
import { Option, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { Publisher } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DiffusionCleanerCountFilters } from 'admin/common/api/types/diffusion_cleaner';
import { concatTrueValues, createFilterMap } from 'admin/common/utils/filter';
import appleLogo from 'admin/content/tools/pages/DiffusionCleaner/components/Filters/assets/apple_business_connect-secondary.svg';
import bingLogo from 'admin/content/tools/pages/DiffusionCleaner/components/Filters/assets/bing_global-secondary.svg';
import facebookLogo from 'admin/content/tools/pages/DiffusionCleaner/components/Filters/assets/facebook-secondary.svg';
import googleLogo from 'admin/content/tools/pages/DiffusionCleaner/components/Filters/assets/google_my_business-secondary.svg';
import hoodspotLogo from 'admin/content/tools/pages/DiffusionCleaner/components/Filters/assets/hoodspot-secondary.svg';
import justacoteLogo from 'admin/content/tools/pages/DiffusionCleaner/components/Filters/assets/justacote-secondary.svg';
import le118000Logo from 'admin/content/tools/pages/DiffusionCleaner/components/Filters/assets/le118000-secondary.svg';
import { useDiffusionCount } from 'admin/content/tools/pages/DiffusionCleaner/hooks/useDiffusionCount';

const publishersOptions = [
    {
        value: Publisher.GOOGLE_MY_BUSINESS,
        img: googleLogo,
    },
    {
        value: Publisher.FACEBOOK,
        img: facebookLogo,
    },
    {
        value: Publisher.LE118000,
        img: le118000Logo,
    },
    {
        value: Publisher.JUSTACOTE,
        img: justacoteLogo,
    },
    {
        value: Publisher.HOODSPOT,
        img: hoodspotLogo,
    },
    {
        value: Publisher.BING_GLOBAL,
        img: bingLogo,
    },
    {
        value: Publisher.APPLE_BUSINESS_CONNECT,
        img: appleLogo,
    },
];

interface useFilterSchemaProps {
    diffusionCleanerfilters: DiffusionCleanerCountFilters;
    setDiffusionCleanerFilters: React.Dispatch<React.SetStateAction<DiffusionCleanerCountFilters>>;
}
export const useFilterSchema = ({
    diffusionCleanerfilters,
    setDiffusionCleanerFilters,
}: useFilterSchemaProps) => {
    const { t } = useTranslation();

    const { data, isLoading, pageHasError } = useDiffusionCount({
        provider: diffusionCleanerfilters.provider,
        org: diffusionCleanerfilters.org,
        groups: diffusionCleanerfilters.groups,
        publishers: diffusionCleanerfilters.publishers,
        errorTypes: diffusionCleanerfilters.errorTypes,
    });

    const diffusionCleanerErrorTypes: Option[] =
        data && !pageHasError && !isLoading
            ? data
                  .map(error => ({
                      label: t(`admin:${error.error_name}`),
                      name: error.error_key,
                      value: error.error_key,
                  }))
                  .sort((e1, e2) => e1.label.localeCompare(e2.label))
            : [];

    const uniqueDiffusionCleanerErrorTypes = [
        ...new Map(diffusionCleanerErrorTypes.map(item => [item.name, item])).values(),
    ];

    const optionsPublisher = publishersOptions.map(option => ({
        name: option.value,
        label: (
            <Stack gap="4px" direction="column" justifyContent="center" alignItems="center">
                <img src={option.img} alt="" />
                <Text variant="bodySBold" as="span">
                    {t(option.value)}
                </Text>
            </Stack>
        ),
        value: option.value,
    }));

    const handlePublishersChange = useCallback((publishers: Record<string, boolean>) => {
        setDiffusionCleanerFilters((prev: DiffusionCleanerCountFilters) => ({
            ...prev,
            publishers,
        }));
    }, []);
    const handleErrorTypesChange = useCallback((errorTypes: Record<string, boolean>) => {
        setDiffusionCleanerFilters((prev: DiffusionCleanerCountFilters) => ({
            ...prev,
            errorTypes,
        }));
    }, []);

    const filters = useMemo(
        () => ({
            [FilterName.SOCIAL_MEDIA]: createFilterMap(diffusionCleanerfilters.publishers),
            [FilterName.TYPE]: createFilterMap(diffusionCleanerfilters.errorTypes),
        }),
        [diffusionCleanerfilters.publishers],
    );

    const setFilters = (filters: PartialFilters) => {
        handlePublishersChange(concatTrueValues(filters, FilterName.SOCIAL_MEDIA));
        handleErrorTypesChange(concatTrueValues(filters, FilterName.TYPE));
    };

    const schema: Filters = useMemo(
        () => [
            {
                id: 'publisher',
                property: FilterName.SOCIAL_MEDIA,
                sectionName: t('connections_filter_publisher'),
                filterCategory: FilterCategory.SELECTABLE,
                options: optionsPublisher,
            },
            {
                id: 'errorType',
                property: FilterName.TYPE,
                sectionName: t('admin:page_diffusion_cleaner__error_type_filter'),
                filterCategory: FilterCategory.CHECKBOX,
                options: uniqueDiffusionCleanerErrorTypes,
            },
        ],
        [data],
    );

    return { schema, filters, setFilters };
};
