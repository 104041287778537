import { Chip, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ButtonWrapper } from './GoBackButton.styled';
import { useFromPage } from '../hooks/useFromPage';

export const GoBackButton = () => {
    const { t } = useTranslation();
    const { goBack } = useFromPage();

    return (
        <ButtonWrapper>
            <Chip
                dataTrackId="admin_categories_obsolete_backbutton"
                variant="secondary"
                iconPosition="left"
                onClick={goBack}
            >
                <Stack gap="8px" direction="row" alignItems="center">
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCircleArrowLeft, IconPrefix.SOLID]}
                        color="secondary"
                        iconSize="14px"
                    />
                    <Text variant="bodyMBold" as="span" color="secondary">
                        {t('admin_categories_obsolete_backbutton')}
                    </Text>
                </Stack>
            </Chip>
        </ButtonWrapper>
    );
};
