import { Loader, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { LoadingViewContainer } from './LoadingView.styled';

export const LoadingView = () => {
    const { t } = useTranslation();

    return (
        <LoadingViewContainer>
            <Text variant="heading2">{t('loading')}</Text>
            <Loader size="medium" strokeWidth={8} />
        </LoadingViewContainer>
    );
};
