import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';

const RESOURCE_URI = 'bulk';

export class BulkImportAdminApiCall extends ApiResourceBaseClass {
    async bulkBusinessImport(
        file: Blob,
        makeModification: boolean,
        email?: string,
    ): Promise<SuccessResponse> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('makeModification', String(makeModification));
        if (email) formData.append('email', email);

        const { data } = await this.post<SuccessResponse>(
            `${RESOURCE_URI}/business/importer`,
            formData,
        );
        return data;
    }

    async bulkUserImport(
        file: Blob,
        makeModification: boolean,
        sendInvitation: boolean,
        email?: string,
    ): Promise<SuccessResponse> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('makeModification', String(makeModification));
        formData.append('sendInvitation', String(sendInvitation));
        if (email) formData.append('email', email);

        const { data } = await this.post<SuccessResponse>(
            `${RESOURCE_URI}/user/importer`,
            formData,
        );
        return data;
    }
}
