import { FeedbackReplySuggestionPromptData } from '@partoohub/api-client';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { feedbackReplySuggestionApiClient } from 'admin/api/apiResources';
import { FEEDBACK_REPLY_SUGGESTION_PROMPT } from 'admin/common/data/queryKeysConstants';

export const useGetFeedbackReplySuggestionPrompt = (
    orgId: number,
    options: UseQueryOptions<FeedbackReplySuggestionPromptData> = {},
) => {
    const query = useQuery(
        [FEEDBACK_REPLY_SUGGESTION_PROMPT, orgId] as QueryKey,
        () => feedbackReplySuggestionApiClient.getPrompt(orgId),
        {
            ...options,
            retry: false,
        },
    );

    return [query.data, query] as const;
};
