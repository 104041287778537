import { UploadImageResponse } from '@partoohub/api-client';
import { useMutation } from 'react-query';

import { imageApiClient } from 'app/api/v2/api_calls/apiResources';
import formatImagesForApi from 'app/businessEditV2/oldFromEditV1/services/formatImagesForAPI';

export default function usePhotosUpload(
    onSuccess: (data: UploadImageResponse) => void = () => undefined,
    onError: (error: any) => void = () => undefined,
) {
    return useMutation(
        (photos: Array<File>) => imageApiClient.uploadImage(formatImagesForApi(photos)),
        {
            onSuccess,
            onError,
        },
    );
}
