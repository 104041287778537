import { V2DiffusionErrors } from 'app/api/types/diffusion_errors';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'affected_resource';

class DiffusionErrors extends ApiResourceBaseClass {
    static getDiffusionErrors(id: number): Promise<V2DiffusionErrors> {
        return DiffusionErrors.get(`${RESOURCE_URI}/${id}`).then(
            ({ data }) => data,
        );
    }
}

export default DiffusionErrors;
