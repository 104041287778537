import arEmoji from '@emoji-mart/data/i18n/ar.json';
import csEmoji from '@emoji-mart/data/i18n/cs.json';
import deEmoji from '@emoji-mart/data/i18n/de.json';
import enEmoji from '@emoji-mart/data/i18n/en.json';
import esEmoji from '@emoji-mart/data/i18n/es.json';
import frEmoji from '@emoji-mart/data/i18n/fr.json';
import hiEmoji from '@emoji-mart/data/i18n/hi.json';
import itEmoji from '@emoji-mart/data/i18n/it.json';
import nlEmoji from '@emoji-mart/data/i18n/nl.json';
import plEmoji from '@emoji-mart/data/i18n/pl.json';
import ptEmoji from '@emoji-mart/data/i18n/pt.json';

import env from 'app/common/services/getEnvironment';

export type LanguageType = {
    code: string;
    name: string;
    country: string;
    supported: boolean;
    rtl: boolean;
    flag: string;
    emojiPopupLanguage?: typeof enEmoji;
    translationKey?: string;
    tcLink?: string;
};

export const LANGUAGES_DATA: readonly LanguageType[] = [
    {
        code: 'en',
        name: 'english',
        country: 'GB', // 'EN' for categories
        supported: true,
        rtl: false,
        flag: '🇬🇧',
        emojiPopupLanguage: enEmoji,
        tcLink: 'https://www.partoo.co/en/legal/',
    },
    {
        code: 'fr',
        name: 'french',
        country: 'FR',
        supported: true,
        rtl: false,
        flag: '🇫🇷',
        emojiPopupLanguage: frEmoji,
        tcLink: 'https://www.partoo.co/fr/legal/',
    },
    {
        code: 'es',
        name: 'spanish',
        country: 'ES',
        supported: true,
        rtl: false,
        flag: '🇪🇸',
        emojiPopupLanguage: esEmoji,
        tcLink: 'https://www.partoo.co/es/legal/',
    },
    {
        code: 'it',
        name: 'italian',
        country: 'IT',
        supported: true,
        rtl: false,
        flag: '🇮🇹',
        emojiPopupLanguage: itEmoji,
        tcLink: 'https://www.partoo.co/it/legal/',
    },
    {
        code: 'pt-BR',
        name: 'portuguese-brazilian',
        country: 'BR',
        supported: true,
        rtl: false,
        flag: '🇧🇷',
        emojiPopupLanguage: ptEmoji,
    },
    {
        code: 'pt',
        name: 'portuguese',
        country: 'PT',
        supported: true,
        rtl: false,
        flag: '🇵🇹',
        emojiPopupLanguage: ptEmoji,
    },
    {
        code: 'de',
        name: 'german',
        country: 'DE',
        supported: true,
        rtl: false,
        flag: '🇩🇪',
        emojiPopupLanguage: deEmoji,
    },
    {
        code: 'lb',
        name: 'luxembourgish',
        country: 'LU',
        supported: false,
        rtl: false,
        flag: '🇱🇺',
    },
    {
        code: 'hi',
        name: 'hindi',
        country: 'IN',
        supported: false,
        rtl: false,
        flag: '🇮🇳',
        emojiPopupLanguage: hiEmoji,
    },
    {
        code: 'nl',
        name: 'dutch',
        country: 'NL',
        supported: true,
        rtl: false,
        flag: '🇳🇱',
        emojiPopupLanguage: nlEmoji,
    },
    {
        code: 'pl',
        name: 'polish',
        country: 'PL',
        supported: true,
        rtl: false,
        flag: '🇵🇱',
        emojiPopupLanguage: plEmoji,
    },
    {
        code: 'sl',
        name: 'slovenian',
        country: 'SI',
        supported: false,
        rtl: false,
        flag: '🇸🇮',
    },
    {
        code: 'ar',
        name: 'arabic',
        country: 'SA',
        supported: true,
        rtl: env.isStaging() || env.isDev(),
        flag: '🇸🇦',
        emojiPopupLanguage: arEmoji,
    },
    {
        code: 'da',
        name: 'danish',
        country: 'DK',
        supported: false,
        rtl: false,
        flag: '🇩🇰',
    },
    {
        code: 'ga',
        name: 'irish',
        country: 'IE',
        supported: false,
        rtl: false,
        flag: '🇮🇪',
    },
    {
        code: 'ro',
        name: 'romanian',
        country: 'RO',
        supported: true,
        rtl: false,
        flag: '🇷🇴',
    },
    {
        code: 'cs',
        name: 'czech',
        country: 'CZ',
        supported: true,
        rtl: false,
        flag: '🇨🇿',
        emojiPopupLanguage: csEmoji,
    },
    {
        code: 'sk',
        name: 'slovak',
        country: 'SK',
        supported: true,
        rtl: false,
        flag: '🇸🇰',
    },
    {
        code: 'ca',
        name: 'catalan',
        country: 'CA',
        supported: true,
        rtl: false,
        flag: '🇪🇸',
    },
    {
        code: 'ja',
        name: 'japanese',
        country: 'JP',
        supported: false,
        rtl: false,
        flag: '🇯🇵',
    },
    {
        code: 'sv',
        name: 'swedish',
        country: 'SE',
        supported: false,
        rtl: false,
        flag: '🇸🇪',
    },
    {
        code: 'ru',
        name: 'russian',
        country: 'RU',
        supported: false,
        rtl: false,
        flag: '🇷🇺',
    },
    {
        code: 'bg',
        name: 'bulgarian',
        country: 'BG',
        supported: true,
        rtl: false,
        flag: '🇧🇬',
    },
    {
        code: 'hr',
        name: 'croatian',
        country: 'HR',
        supported: false,
        rtl: false,
        flag: '🇭🇷',
    },
    {
        code: 'sr',
        name: 'serbian',
        country: 'RS',
        supported: false,
        rtl: false,
        flag: '🇷🇸',
    },
    {
        code: 'lt',
        name: 'lithuanian',
        country: 'LT',
        supported: false,
        rtl: false,
        flag: '🇱🇹',
    },
    {
        code: 'no',
        name: 'norwegian',
        country: 'NO',
        supported: false,
        rtl: false,
        flag: '🇳🇴',
        translationKey: 'language_NO',
    },
    {
        code: 'et',
        name: 'estonian',
        country: 'EE',
        supported: false,
        rtl: false,
        flag: '🇪🇪',
    },
    {
        code: 'fi',
        name: 'finnish',
        country: 'FI',
        supported: false,
        rtl: false,
        flag: '🇫🇮',
    },
    {
        code: 'he',
        name: 'hebrew',
        country: 'IL',
        supported: false,
        rtl: true,
        flag: '🇮🇱',
    },
    {
        code: 'zh',
        name: 'chinese',
        country: 'CN',
        supported: false,
        rtl: false,
        flag: '🇨🇳',
    },
    {
        code: 'el',
        name: 'greek',
        country: 'GR',
        supported: false,
        rtl: false,
        flag: '🇬🇷',
    },
    {
        code: 'lv',
        name: 'latvian',
        country: 'LV',
        supported: true,
        rtl: false,
        flag: '🇱🇻',
    },
    {
        code: 'hu',
        name: 'hungarian',
        country: 'HU',
        supported: true,
        rtl: false,
        flag: '🇭🇺',
    },
    {
        code: 'tr',
        name: 'turkish',
        country: 'TR',
        supported: false,
        rtl: false,
        flag: '🇹🇷',
    },
] as const;

export const languagesData = LANGUAGES_DATA;

export const LANGUAGES: readonly string[] = LANGUAGES_DATA.map(language => language.code);

export type LanguageCodeType = (typeof LANGUAGES)[number];

export const LANGUAGES_MAP: Record<LanguageCodeType, LanguageType> = LANGUAGES_DATA.reduce(
    (languageMap, language) => {
        languageMap[language.code] = language;
        return languageMap;
    },
    {} as Record<LanguageCodeType, LanguageType>,
);

export const getTranslationKey = (language: LanguageType) => {
    return language.translationKey ?? language.code;
};

/**
 * The languages that
 * 1. can be selected as application language;
 * 2. can be set as user language;
 * 3. have complete translations.
 */
export const SUPPORTED_LANGUAGES: readonly string[] = LANGUAGES_DATA.filter(
    (language: LanguageType) => language.supported,
).map((language: LanguageType) => language.code);

const ENGLISH = LANGUAGES_DATA.find(language => language.name === 'english');

export const ENGLISH_LANGUAGE_CODE = ENGLISH ? ENGLISH.code : '';

export const EMOJI_LANGUAGE = Object.assign(
    {},
    ...LANGUAGES_DATA.map((data: LanguageType) => ({
        [data.code]: 'emojiPopupLanguage' in data ? data.emojiPopupLanguage : enEmoji,
    })),
);

export const getTCLink = (lang: string): string => {
    const language = LANGUAGES_MAP[lang];
    return language?.tcLink ?? LANGUAGES_MAP.en.tcLink ?? '';
};

/**
 * Look for a language code matching the locale formatted as `language-REGION`
 * Try to match the whole locale to find the region-specific language code (eg. pt-BR) if available
 * Else, only match the language part, otherwise return the provided default language
 */
export const localeToLanguage = (
    locale: string | null,
    defaultLanguage?: string,
): string | undefined => {
    if (!locale) return defaultLanguage;

    const languageType =
        LANGUAGES_DATA.find(ldata => ldata.code.toLowerCase() === locale.toLowerCase()) ??
        LANGUAGES_DATA.find(
            ldata => ldata.code.toLowerCase() === locale.split('-')[0].toLowerCase(),
        );

    return languageType?.code ?? defaultLanguage;
};
