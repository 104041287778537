import { LegacyRef, useEffect, useRef, useState } from 'react';

import { Checkbox } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useClampText } from 'use-clamp-text';

import {
    Group,
    GroupSection as GroupSectionType,
} from 'app/api/types/groupsV2';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import {
    GroupLine,
    GroupName,
    GroupSectionContainer,
    SectionContent,
    SectionHeader,
    SectionName,
} from './GroupSection.styled';

type Props = {
    section: GroupSectionType;
    onGroupClick: (id: string, label: string, checked: boolean) => void;
    selectedGroups: Array<string>;
    enabledGroups?: Array<string>;
    // Hide groups with no business count, only for filters, but not for access groups modal (selection).
    hideGroupWithoutCount?: boolean;
};

const GroupSection = ({
    section,
    onGroupClick,
    selectedGroups,
    enabledGroups,
    hideGroupWithoutCount = true,
}: Props) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [contentHeight, setContentHeight] = useState<number>(20000);

    const contentRef = useRef<HTMLDivElement>(null);

    const [nameRef, { clampedText: sectionName }] = useClampText({
        text: section.name,
        ellipsis: '...',
        lines: 2,
        debounceTime: 0,
    });

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [contentRef.current, section]);

    const enabledSection = section.groups.some(
        group => enabledGroups?.includes(group.id.toString()) ?? true,
    );

    return (
        <GroupSectionContainer>
            <SectionHeader onClick={() => setIsOpen(!isOpen)}>
                <SectionName
                    disabled={!enabledSection}
                    ref={nameRef as LegacyRef<HTMLDivElement>}
                >
                    {sectionName}
                </SectionName>
                {isOpen ? (
                    <i className="fas fa-angle-down" />
                ) : (
                    <i className="fas fa-angle-right" />
                )}
            </SectionHeader>

            <SectionContent
                ref={contentRef}
                collapsed={!isOpen}
                maxHeight={contentHeight}
            >
                {section.groups
                    .filter(
                        group =>
                            !hideGroupWithoutCount || group.business_count > 0,
                    )
                    .map((group: Group) => {
                        const checked: boolean = selectedGroups.includes(
                            group.id.toString(),
                        );
                        const enabled: boolean =
                            enabledGroups?.includes(group.id.toString()) ??
                            true;

                        return (
                            <GroupLine
                                disabled={!enabled}
                                key={`group-${group.id}`}
                            >
                                <GroupName>{group.name}</GroupName>
                                <TooltipWrapper
                                    text={
                                        !enabled
                                            ? t('groups_must_match_accesses')
                                            : ''
                                    }
                                >
                                    <Checkbox
                                        dataTrackId="group_section__checkbox"
                                        aria-label=""
                                        onChange={() =>
                                            onGroupClick(
                                                group.id.toString(),
                                                group.name,
                                                checked,
                                            )
                                        }
                                        checked={checked}
                                        disabled={!enabled}
                                    />
                                </TooltipWrapper>
                            </GroupLine>
                        );
                    })}
            </SectionContent>
        </GroupSectionContainer>
    );
};

export default GroupSection;
