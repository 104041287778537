import React, { useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Button, Drawer, FontAwesomeIconsPartooUsed, Icon, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router';

import { diffusionCleanerApiClient } from 'admin/api/apiResources';
import { DiffusionCleanerStatusData, Publisher } from 'admin/api/types';
import { DIFFUSION_CLEANER_MISSING_CATEGORIES } from 'admin/common/data/queryKeysConstants';

import { CATEGORIES_EDIT_PATH } from 'admin/common/data/routeIds';
import { useMe } from 'admin/common/hooks/queries/useMe';

import {
    Category,
    CategoryList,
    CategoryNameWrapper,
    DrawerHeading,
    DrawerWrapper,
} from './MissingCategoriesButton.styled';

type Props = {
    business: DiffusionCleanerStatusData;
    publisher: Publisher;
};

export const MissingCategoriesButton = ({ business, publisher }: Props) => {
    const { t } = useTranslation();
    const me = useMe();
    const lang = me.data !== undefined ? me.data.lang : 'en';
    const { isSuccess, data } = useQuery({
        queryKey: [DIFFUSION_CLEANER_MISSING_CATEGORIES, business.id, lang],
        queryFn: () =>
            diffusionCleanerApiClient.getMissingCategories({
                business_id: business.id,
                lang,
                publisher,
            }),
        enabled: me.isSuccess,
    });
    const [open, setOpen] = useState(false);

    return (
        <>
            <Drawer
                isOpen={open}
                onHide={() => setOpen(false)}
                dataTrackId="admin_diffusion_cleaner__missing_categories__drawer"
            >
                <DrawerWrapper>
                    <DrawerHeading>
                        <Text variant="heading5">
                            {t('admin:page_diffusion_cleaner__missing_categories__heading')}
                        </Text>
                        <Text variant="bodyLRegular" color={HEX_COLORS.secondary}>
                            {t('admin:page_diffusion_cleaner__missing_categories__subheading')}
                        </Text>
                    </DrawerHeading>
                    <CategoryList>
                        {isSuccess &&
                            data.map(category => (
                                <Category key={category.id}>
                                    <CategoryNameWrapper>
                                        <Icon
                                            icon={[FontAwesomeIconsPartooUsed.faTag]}
                                            color="secondary"
                                        />
                                        <Text variant="bodyMSemibold">{category.label}</Text>
                                    </CategoryNameWrapper>
                                    <Link to={`${CATEGORIES_EDIT_PATH}/${category.id}`}>
                                        <Button
                                            size="small"
                                            appearance="outlined"
                                            shape="rounded"
                                            dataTrackId="admin_diffusion_cleaner__missing_categories__go_to_category_matching_page"
                                            variant="theme"
                                        >
                                            {t(
                                                'admin:page_diffusion_cleaner__missing_categories__go_to_category_matching_button',
                                            )}
                                        </Button>
                                    </Link>
                                </Category>
                            ))}
                    </CategoryList>
                </DrawerWrapper>
            </Drawer>
            <Button
                shape="rounded"
                size="medium"
                variant="theme"
                dataTrackId={'admin_diffusion_cleaner__business_button__missing_categories'}
                onClick={() => setOpen(!open)}
            >
                <Text variant="bodyMBold" color="white">
                    {t('admin:page_diffusion_cleaner__business_button__missing_categories')}
                </Text>
            </Button>
        </>
    );
};
