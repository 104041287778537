import { useEffect, useState } from 'react';

import { Button, Loader, Stack, TextArea } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import AdminPage from 'admin/common/components/templates/AdminPage';

import { ConfirmEditGenericPromptModal } from './ConfirmEditGenericPromptModal';
import { StyledBorderedContainer } from './GenericPrompt.styled';
import { useGetFeedbackReplySuggestionGenericPrompt } from '../hooks/useGetFeedbackReplySuggestionGenericPrompt';
import { useUpdateFeedbackReplySuggestionGenericPrompt } from '../hooks/useUpdateFeedbackReplySuggestionGenericPrompt';

export const GenericPrompt = () => {
    const { t } = useTranslation();

    const [openedModal, setOpenedModal] = useState(false);
    const [prompt, setPrompt] = useState('');

    const [savedPrompt, { isLoading }] = useGetFeedbackReplySuggestionGenericPrompt();
    const updatePrompt = useUpdateFeedbackReplySuggestionGenericPrompt();

    useEffect(() => {
        setPrompt(savedPrompt?.content ?? '');
    }, [savedPrompt]);

    const openModal = () => {
        setOpenedModal(true);
    };

    return (
        <AdminPage
            dataTrackId="page_tools_feedback_management__generic_prompt"
            title={t('admin:side_menu_tools__section__feedback_management__generic_prompt')}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <Stack gap="24px">
                    <StyledBorderedContainer>
                        <ConfirmEditGenericPromptModal
                            opened={openedModal}
                            onConfirm={() => updatePrompt({ content: prompt })}
                            onClose={() => setOpenedModal(false)}
                        />
                        <TextArea
                            label={t(
                                'admin:side_menu_tools__section__feedback_management__generic_prompt',
                            )}
                            dataTrackId=""
                            value={prompt}
                            onChange={setPrompt}
                            maxRows={50}
                            rows={20}
                        />
                    </StyledBorderedContainer>
                    <Button
                        dataTrackId="feedback_management__generic_prompt_edit_save"
                        size="large"
                        variant="primary"
                        shape="cube"
                        onClick={() => openModal()}
                        disabled={!prompt || prompt === savedPrompt?.content}
                        full
                    >
                        {t('save')}
                    </Button>
                </Stack>
            )}
        </AdminPage>
    );
};
