import { FC } from 'react';

import { CustomFile, Dropzone, DropzoneProps } from '@partoohub/ui';

interface DropzoneWithoutUploadProps extends Omit<DropzoneProps, 'onDropSuccess' | 'onDelete'> {
    setFiles: (files: CustomFile[]) => void;
}
export const DropZoneWithoutUpload: FC<DropzoneWithoutUploadProps> = props => {
    const { files, setFiles } = props;

    const onDropSuccess = (newFiles: CustomFile[]) => {
        const updatedFiles = [...files, ...newFiles];
        setFiles(updatedFiles);
    };

    const onDelete = (name: string) => {
        setFiles(files.filter(file => file.name !== name));
    };

    return <Dropzone onDropSuccess={onDropSuccess} onDelete={onDelete} {...props} />;
};
