import { RefObject } from 'react';
interface MobileIconProps {
    ref?: RefObject<SVGSVGElement>;
}

export const MobileIcon = ({ ref }: MobileIconProps) => (
    <svg
        ref={ref}
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M16 4H8C7.15625 4 6.5 4.6875 6.5 5.5V18.5C6.5 19.3438 7.15625 20 8 20H16C16.8125 20 17.5 19.3438 17.5 18.5V5.5C17.5 4.6875 16.8125 4 16 4ZM13.5 18C13.5 18.2812 13.25 18.5 13 18.5H10.9688C10.7188 18.5 10.5 18.2812 10.5 18C10.5 17.75 10.7188 17.5 11 17.5H13C13.25 17.5 13.5 17.75 13.5 18Z" />
    </svg>
);
