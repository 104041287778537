import React from 'react';

import { useTranslation } from 'react-i18next';

import OrgSelectBusinessModal from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/GroupsContent/OrgSelectBusinessModal';
import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import useSelectedOrgBusinessModal from 'app/common/components/businessModal/hooks/privateHooks/useSelectedOrgBusinessModal';
import { ADMIN, PROVIDER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

import Groups from './Groups';
import Header from '../Header';

const GroupsContent = () => {
    const { t } = useTranslation();
    const { variation } = useBusinessModalContext();
    const { data: me } = useMe();

    const { searchOrgId } = useSelectedOrgBusinessModal();

    return (
        <>
            <Header title={t('business_drawer_groups')} />
            {variation === 'group-only' &&
                !searchOrgId &&
                [ADMIN, PROVIDER].includes(me?.role ?? '') && <OrgSelectBusinessModal />}
            <Groups />
        </>
    );
};

export default GroupsContent;
