import { useQuery } from 'react-query';

import { UseQueryOptions } from 'react-query/types/react/types';

import { userInfosApiClient } from 'admin/api/apiResources';
import { UserInfo } from 'admin/api/types/userInfos';
import { MULTI_ORGANIZATIONS } from 'admin/common/data/queryKeysConstants';

export const useUserInfo = (userInfoId: number, options: UseQueryOptions<UserInfo> = {}) => {
    return useQuery(
        [MULTI_ORGANIZATIONS, { userInfoId }],
        () => userInfosApiClient.getUsersByUserInfoId(userInfoId),
        options,
    );
};
