import styled from '@emotion/styled';

import { MENU_BUTTON_Z_INDEX } from '@partoohub/ui';

export const ButtonWrapper = styled.div`
    display: flex;
    gap: 4px;
    position: relative;
    cursor: pointer;
    align-items: center;
`;

export const MenuListWrapper = styled.div`
    position: absolute;
    min-width: 100%;
    z-index: ${MENU_BUTTON_Z_INDEX};
    top: 50px;
    right: 0px;
    left: unset;
`;
