import styled from '@emotion/styled';

export const GroupsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 16px;
    width: 100%;
    overflow: auto;

    > div {
        // Infinite scroll div
        > div:last-child {
            &::after {
                border: none;
            }
        }
    }
`;
