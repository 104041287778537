import { greyColorObject } from '@partoohub/branding';
import { Separator } from '@partoohub/ui';

import { KeywordContentContainerList, KeywordsSectionContainer } from './KeywordsSection.styled';
import { useGetKeywordsFromOrg } from '../../hooks/useGetKeywordsFromOrg';
import { useOrgIdParsed } from '../../hooks/useOrgIdParsed';
import { KeywordContent } from '../KeywordContent/KeywordContent';

import { KeywordTitle } from '../KeywordTitle/KeywordTitle';

type Props = {
    createKeyword: () => void;
};

export const KeywordsSection = ({ createKeyword }: Props) => {
    const orgId = useOrgIdParsed();
    const { keywords } = useGetKeywordsFromOrg(orgId);

    return (
        <KeywordsSectionContainer>
            <KeywordTitle action={createKeyword} />
            <Separator color={greyColorObject.initial} />
            {keywords.length > 0 && (
                <KeywordContentContainerList>
                    {keywords.map(keyword => (
                        <KeywordContent key={`${keyword.id}`} keyword={keyword} />
                    ))}
                </KeywordContentContainerList>
            )}
        </KeywordsSectionContainer>
    );
};
