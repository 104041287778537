import { SuccessResponse } from 'app/api/types';
import { AccountPartnerResourceName } from 'app/api/types/account';
import {
    BusinessGoogleConnectionsErrorsCountData,
    BusinessSuccessResponse,
    FacebookAutoMatchSuggestionsResponse,
    FormattedBusinessConnectToLocationType,
    GmbAutoMatchSuggestionsResponse,
} from 'app/api/types/business';

import ApiResourceBaseClass from '../baseApiCalls';

const RESOURCE_URI = 'business';

const partnerToResourceUri = {
    gmb: 'google-locations',
    facebook: 'facebook-pages',
};

class PublisherConnection extends ApiResourceBaseClass {
    static getFacebookAutoMatchSuggestions(): Promise<FacebookAutoMatchSuggestionsResponse> {
        return PublisherConnection.get(`${RESOURCE_URI}/facebook-suggested`).then(
            ({ data }) => data,
        );
    }

    static getGmbAutoMatchSuggestions(): Promise<GmbAutoMatchSuggestionsResponse> {
        return PublisherConnection.get(`${RESOURCE_URI}/google-suggested`).then(({ data }) => data);
    }

    static connectBusinessToLocation(
        businessId: string,
        locationId: string,
        partnerResourceName: AccountPartnerResourceName,
        requestData: FormattedBusinessConnectToLocationType,
    ): Promise<BusinessSuccessResponse> {
        return PublisherConnection.post(
            `${RESOURCE_URI}/${businessId}/connect/${partnerToResourceUri[partnerResourceName]}/${locationId}`,
            requestData,
        ).then(({ data }) => data);
    }

    static disconnectBusinessFromLocation(
        businessId: string,
        partnerResourceName: AccountPartnerResourceName,
    ): Promise<BusinessSuccessResponse> {
        return PublisherConnection.post(
            `${RESOURCE_URI}/${businessId}/disconnect/${partnerToResourceUri[partnerResourceName]}`,
        ).then(({ data }) => data);
    }

    static tripAdvisorConnectionCall(formData: FormData): Promise<SuccessResponse> {
        return PublisherConnection.post(`${RESOURCE_URI}/connect/tripadvisor`, formData, {
            'Content-Type': 'multipart/form-data',
        }).then(({ data }) => data);
    }

    static googleConnectionsErrorsCount(): Promise<BusinessGoogleConnectionsErrorsCountData> {
        return PublisherConnection.get(
            `${RESOURCE_URI}/partner-connections-errors/google/count`,
        ).then(({ data }) => data);
    }
}

export default PublisherConnection;
