import ApiResourceBaseClass from './baseApiCalls';
import { Choice } from '../../types/user';

const RESOURCE_URI = 'lang';

class Lang extends ApiResourceBaseClass {
    static getAvailableLanguages(): Promise<Array<Choice>> {
        return Lang.get(`${RESOURCE_URI}/available_languages`).then(
            ({ data }) => data,
        );
    }
}

export default Lang;
