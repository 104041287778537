import { FC, useState } from 'react';

import { Option, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { overridingStateParametersApiClient } from 'admin/api/apiResources';
import { OverridingStateParameters } from 'admin/api/types';
import { NewPublishersSelect } from 'admin/common/components/inputs/NewPublishersSelect';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { NewProviderSelect } from 'admin/common/components/inputs/ProviderSelect/NewProviderSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

import { StateSelect } from './components/StateSelect';

const OverridingState: FC = () => {
    const { t } = useTranslation();
    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [provider, setProvider] = useState<Option | undefined>(undefined);
    const [states, setStates] = useState<Option>();
    const [publishers, setPublishers] = useState<Record<string, boolean>>({});

    const selectedPublishers = Object.entries(publishers)
        .filter(([_, selected]) => !!selected)
        .map(([value, _]) => value);

    const { mutate, isLoading } = useMutation(
        (data: OverridingStateParameters) => overridingStateParametersApiClient.set(data),
        {
            onSuccess: () => {
                toast.success(
                    t('admin:page_tools_overriding_state__success_toast__content'),
                    t('admin:page_tools_overriding_state__success_toast__title'),
                );
                resetForm();
            },
            onError: (error: any) => {
                toast.error(error.message, t('admin:toast_error__title'));
            },
        },
    );

    const isFormValid = (!!org || !!provider) && selectedPublishers.length !== 0;

    const resetForm = () => {
        setOrg(undefined);
        setProvider(undefined);
        setStates(undefined);
        setPublishers({});
    };

    const handleProviderChange = (newProvider?: Option) => {
        setProvider(newProvider);
        if (newProvider && newProvider.value !== provider?.value) {
            setOrg(undefined);
        }
    };

    const submitForm = async () =>
        mutate({
            publishers: selectedPublishers,
            state: states!.value,
            org_id: org?.value,
            provider: provider?.value,
        });

    return (
        <AdminPage
            dataTrackId="overriding_state__page"
            title={t('admin:page_tools_overriding_state__title')}
            description={t('admin:page_tools_overriding_state__description')}
            infoLink={
                'https://partoo.elium.com/tile/view/279/#55bd0246-7b6f-477b-9195-59e60e271750'
            }
        >
            <BorderedContainer>
                <PageFormRow>
                    <NewProviderSelect
                        dataTrackId="admin_overriding_states__filter_provider"
                        provider={provider}
                        setProvider={handleProviderChange}
                    />
                    <NewOrganizationSelect
                        dataTrackId="admin_overriding_states__filter_organization"
                        orgs={org}
                        setOrgs={setOrg}
                        queryParams={{
                            provider: provider?.value,
                        }}
                        placeholder={t('admin:organization')}
                    />
                </PageFormRow>
                <PageFormRow>
                    <NewPublishersSelect
                        dataTrackId="diffusion-cleaner-publishers"
                        publishers={publishers}
                        setPublishers={setPublishers}
                    />
                </PageFormRow>
                <PageFormRow>
                    <StateSelect state={states} setState={setStates} />
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin_overriding_states__set_state"
                text={t('admin:page_tools_overriding_state__button_submit')}
                onClick={submitForm}
                disabled={!isFormValid || isLoading}
            />
        </AdminPage>
    );
};

export default OverridingState;
