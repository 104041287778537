export type Environment = 'dev' | 'prod' | 'preprod' | 'staging' | 'sandbox' | 'slate';

const env = {
    getEnv: (): Environment => {
        const host = window.location.hostname;

        if (
            host.includes('localhost') ||
            host.includes('0.0.0.0') ||
            host.includes('127.0.0.1') ||
            host.includes('dev.')
        ) {
            return 'dev';
        }

        if (host.includes('preprod')) {
            return 'preprod';
        }

        if (host.includes('staging')) {
            return 'staging';
        }

        if (host.includes('sandbox')) {
            return 'sandbox';
        }

        if (host.includes('slates')) {
            return 'slate';
        }

        return 'prod';
    },
    isProdEnv: (): boolean => {
        const environment = env.getEnv();
        return ['prod', 'sandbox'].includes(environment);
    },
    isTestEnv: (): boolean => {
        const environment = env.getEnv();
        return ['dev', 'staging', 'slate'].includes(environment);
    },
    isProd: (): boolean => env.getEnv() === 'prod',
    isDev: (): boolean => env.getEnv() === 'dev',
    isPreprod: (): boolean => env.getEnv() === 'preprod',
    isSandbox: (): boolean => env.getEnv() === 'sandbox',
    // We want slate to behave like staging
    isStaging: (): boolean => ['staging', 'slate'].includes(env.getEnv()),
    isSlate: (): boolean => env.getEnv() === 'slate',
    isAdmin: (): boolean => window.location.hostname.includes('admin'),
};

export default env;
