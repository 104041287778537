import { SearchUserParams } from 'app/api/types/user';

import { ORG_ADMIN, PROVIDER } from 'admin/common/data/constants/roles';
import { UserStatus } from 'admin/common/data/enums';

export default function getUserQuery(
    orgId: string | undefined,
    provider: string | undefined,
): SearchUserParams {
    if (orgId === undefined) {
        return {
            provider: provider,
            role: PROVIDER,
            status: UserStatus.ACTIVE,
        };
    } else if (provider === undefined) {
        return {
            org_id: parseInt(orgId),
            role: ORG_ADMIN,
            status: UserStatus.ACTIVE,
        };
    } else {
        return {};
    }
}
