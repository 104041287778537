import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, IconPrefix, Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ActionButtonWithMenu } from './ActionButtonWithMenu';

interface Props {
    onMenuClick: (value: string) => void;
    disabled: boolean;
    options: Array<Option>;
}

export const FoodMenuMenu = ({ onMenuClick, disabled, options }: Props) => {
    const { t } = useTranslation();
    return (
        <ActionButtonWithMenu
            dataTrackId="manage_button__action_button"
            icon={[FontAwesomeIconsPartooUsed.faEllipsisVertical, IconPrefix.SOLID]}
            disabled={disabled}
            appearance="outlined"
            options={options}
            onMenuClick={(value: string) => onMenuClick(value)}
            cssMenu={css`
                top: 50px;
                right: 0px;
                left: unset;
            `}
            label={t('food_menu_actions')}
        />
    );
};
