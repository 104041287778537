import { useTranslation } from 'react-i18next';

import { DiffusionTableHeaderContainer } from './DiffusionTableHeader.styled';
import {
    DiffusionRowStatus,
    DiffusionTableRowContainer,
} from '../contents/DiffusionRow/DiffusionRow.styled';
import { DiffusionCell } from '../contents/RowTop/RowTop.styled';

const DiffusionTableHeader = () => {
    const { t } = useTranslation();
    return (
        <DiffusionTableHeaderContainer>
            <DiffusionRowStatus />
            <DiffusionTableRowContainer appearance="content">
                <DiffusionCell type="header" width="small" isFirst />
                <DiffusionCell type="header" width="large">
                    <span>{t('pl_business')}</span>
                </DiffusionCell>
                <DiffusionCell type="header" width="medium">
                    <span>{t('pl_category')}</span>
                </DiffusionCell>
                <DiffusionCell type="header" width="regular">
                    <span>{t('pl_creation')}</span>
                </DiffusionCell>
                <DiffusionCell type="header" width="regular">
                    <span>{t('diffusion_updated')}</span>
                </DiffusionCell>
                <DiffusionCell type="header" width="regular">
                    <span>{t('pl_status')}</span>
                </DiffusionCell>
                <DiffusionCell type="header" width="small" />
            </DiffusionTableRowContainer>
        </DiffusionTableHeaderContainer>
    );
};

export default DiffusionTableHeader;
