import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

import AdminApiResourceBaseClass from 'admin/common/api/calls/baseAdminApiCalls';

const RESOURCE_URI = 'food_menus';

export type AdminBulkCreateFoodMenu = BusinessModalFiltersType & {
    is_override_checked: boolean;
    menus: Array<FoodMenuSectionType>;
};

export type AdminBulkCreatePhotoMenu = BusinessModalFiltersType & {
    is_override_checked: boolean;
    medias: Array<string>;
};

export type FoodMenuItemMediaType = {
    id?: string;
    public_url?: string;
    source_url: string;
};

export type FoodMenuItemType = {
    name: string;
    order: number;
    description: string;
    price?: number;
    media?: FoodMenuItemMediaType;
    photo_url?: string;
};

export type FoodMenuSectionType = {
    name: string;
    order: number;
    items: FoodMenuItemType[];
};

export type FoodMenuGetDataType = {
    menus: Array<FoodMenuSectionType>;
};

export type FoodMenuItemFormType = {
    name: string;
    order: number;
    description: string;
    price: string;
    media?: FoodMenuItemMediaType;
};

class BulkFoodMenu extends AdminApiResourceBaseClass {
    static deleteFoodMenu(queryParams: BusinessModalFiltersType): Promise<FoodMenuGetDataType> {
        return BulkFoodMenu.post(`${RESOURCE_URI}/bulk_delete`, queryParams).then(
            ({ data }) => data,
        );
    }

    static updateFoodMenu(queryParams: AdminBulkCreateFoodMenu): Promise<FoodMenuGetDataType> {
        return BulkFoodMenu.post(`${RESOURCE_URI}/bulk_create_food_menus`, queryParams).then(
            ({ data }) => data,
        );
    }

    static updatePhotoMenu(queryParams: AdminBulkCreatePhotoMenu): Promise<FoodMenuGetDataType> {
        return BulkFoodMenu.post(`${RESOURCE_URI}/bulk_create_menu_medias`, queryParams).then(
            ({ data }) => data,
        );
    }
}

export default BulkFoodMenu;
