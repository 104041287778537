import { GeocodingParams, GeocodingType } from 'app/api/types/geocoding';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

class Geocoding extends ApiResourceBaseClass {
    static getGeocoding(queryParams: GeocodingParams): Promise<GeocodingType> {
        return Geocoding.get('geocoding', queryParams).then(({ data }) => data);
    }
}

export default Geocoding;
