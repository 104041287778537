import api from 'app/api/v2/api_calls';

import { WEBSOCKET_LIVE_UPDATES_URL } from 'admin/common/data/config';

const createWebsocketConnection = () => {
    const getAuthToken = async () => {
        try {
            const { token } = await api.authToken.getAuthToken();
            return token;
        } catch (error) {
            console.error('ERROR : ', error);
            return '';
        }
    };

    return getAuthToken().then(
        token => new WebSocket(`${WEBSOCKET_LIVE_UPDATES_URL}?token=${token}`),
    );
};

export default createWebsocketConnection;
