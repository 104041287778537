import { FC, useState } from 'react';

import { Tooltip, toast } from '@partoohub/ui';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'admin/common/data/config';

import { BusinessAndTooltip } from './PartnerLogo.styled';

type Props = {
    sourceImg: string;
    businessId: string;
};

export const PartnerLogo: FC<Props> = ({ businessId, sourceImg }) => {
    const { t } = useTranslation();
    const [imgSrc, setImgSrc] = useState(sourceImg);

    const handleMouseEnter = () => {
        setImgSrc(`${STATIC_BASE_URL}/images/app/app_review/copy.png`);
    };
    const handleMouseLeave = () => {
        setImgSrc(sourceImg);
    };

    const onCopySuccess = () => {
        toast.success('The business id was successfully copied.', 'Success!');
    };

    return (
        <CopyToClipboard text={businessId} onCopy={onCopySuccess}>
            <BusinessAndTooltip onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Tooltip text={t('copy_business_id')} caret={false} placement="bottom">
                    <img
                        data-track="partner_logo"
                        alt="partner-logo"
                        src={imgSrc}
                        width={40}
                        height={40}
                    />
                </Tooltip>
            </BusinessAndTooltip>
        </CopyToClipboard>
    );
};
