import { AppApiResourceBaseClass } from 'admin/common/api/calls/baseAppApiCalls';
import { UserData } from 'admin/common/api/types/user';

const RESOURCE_URI = 'user';

export class User extends AppApiResourceBaseClass {
    static getMe(displayBusinessIds: 'True' | 'False' = 'False'): Promise<UserData> {
        const route = `${RESOURCE_URI}/me?platform=web`;
        const params = { display_business_ids: displayBusinessIds };
        return User.get(route, params).then(({ data }) => data);
    }
}
