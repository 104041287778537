import React, { ReactElement, useState } from 'react';

import { ModifierPhases, Placement } from '@popperjs/core';
import useResizeObserver from '@react-hook/resize-observer';
import ReactDOM from 'react-dom';
import { mergeRefs } from 'react-merge-refs';
import { usePopper } from 'react-popper';

// https://github.com/floating-ui/floating-ui/issues/794#issuecomment-824220211
const sameWidthModifier = {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite' as ModifierPhases,
    requires: ['computeStyles'],
    fn: ({ state }) => {
        state.styles.popper.minWidth = `${state.rects.reference.width}px`;
    },
    effect: ({ state }) => {
        state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
    },
};

interface PositionedPortalProps {
    children: ReactElement;
    position?: Placement;
    referenceElement: HTMLElement | null;
    xOffset?: number;
    yOffset?: number;
    watchReferenceElementSize?: boolean;
    sameWidthAsReference?: boolean;
}

/**
 * A wrapper component that positions its child element next to a reference element
 * using popper.js. There are three refs involved on the child here : an internal ref
 * for popper.js, a potential ref set on the child, and this wrapper itself must be
 * able to take a ref that's forwarded to the child.
 *
 * TODO: this is basically an extract of the code that is in TooltipWrapper, but that
 *       can render anything, not just tooltips. Refactor TooltipWrapper using this
 *       component.
 *
 * @example
 * <OtherWrapperSettingRefOnItsChild>
 *   <PositionedPortal {...props}>
 *     <div ref={userRef}/>
 *   </PositionedPortal>
 * </OtherWrapperSettingRefOnItsChild>
 *
 */
export const PositionedPortal = React.forwardRef<HTMLElement, PositionedPortalProps>(
    (
        {
            xOffset = 0,
            yOffset = 0,
            position = 'bottom',
            referenceElement,
            children,
            watchReferenceElementSize = false,
            sameWidthAsReference = false,
        },
        upperRef,
    ) => {
        const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);

        // https://popper.js.org/docs/v2/modifiers/offset/
        const offsetModifier = {
            name: 'offset',
            options: { offset: [xOffset || 0, yOffset || 0] },
        };

        const modifiers = [
            (xOffset !== undefined || yOffset !== undefined) && offsetModifier,
            sameWidthAsReference && sameWidthModifier,
        ].filter(Boolean) as Array<typeof offsetModifier>;

        const { styles, attributes, update } = usePopper(referenceElement, targetElement, {
            placement: position,
            modifiers,
        });

        // watch target component resize and update tooltip to recalculate its position
        // (mostly useful for TextArea component)
        // https://popper.js.org/react-popper/v2/hook/#update-forceupdate-and-state
        useResizeObserver(watchReferenceElementSize ? referenceElement : null, () => {
            update?.();
        });

        // preserve the ref on children while we add another one when cloning children
        // https://github.com/facebook/react/issues/8873#issuecomment-275423780:w
        const { ref: childRef } = children as ReactElement & {
            ref;
        };

        return (
            <>
                {ReactDOM.createPortal(
                    React.cloneElement(children, {
                        ref: mergeRefs([setTargetElement, childRef, upperRef]),
                        style: styles.popper,
                        ...attributes.popper,
                    }),
                    document.body,
                )}
            </>
        );
    },
);
