import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';

import { ProspectsFormData } from 'admin/api/types';

const RESOURCE_URI = 'business/search-prospects';

export class ProspectsApiCall extends ApiResourceBaseClass {
    async search(body: ProspectsFormData): Promise<SuccessResponse> {
        const { data } = await this.post<SuccessResponse>(`${RESOURCE_URI}`, body);

        return data;
    }
}
