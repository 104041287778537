import { BusinessReachEnum, V2BusinessStatus } from 'app/api/types/business';
import {
    FilterByBusinessState,
    citySelector,
    excludedIdsSelector,
    groupIdInSelector,
    includedIdsSelector,
    modeSelector,
    querySelector,
} from 'app/common/components/businessModalDeprecated/reducers';

export type BusinessSearchParams = {
    org_id?: number | null;
    country?: string | null;
    status?: V2BusinessStatus | null;
    can_modify_service_list?: boolean | null;
    can_have_food_menus?: boolean | null;
    connected_to_gmb_location?: boolean | null;
    connected_to_facebook_location?: boolean | null;
    // TODO: make it an array and format for the api call
    reach?: BusinessReachEnum | string | null; // comma separated values of BusinessReachEnum
    features__in?: string | null; // comma separated values of FEATURE_ENUM
    features__notin?: string | null; // comma separated values of FEATURE_ENUM
};

export type BusinessModalFiltersType = {
    business_org_id?: number | null;
    country?: string | null;
    status?: V2BusinessStatus | null;
    can_modify_service_list?: boolean | null;
    can_have_food_menus?: boolean | null;
    connected_to_gmb_location?: boolean | null;
    connected_to_facebook_location?: boolean | null;

    business__in?: string | null;
    business__notin?: string | null;
    business_query?: string | null;
    business_city?: string | null;
    groups?: string | null;
    partoo_ui?: boolean;

    features__in?: string | null; // comma separated values of FEATURE_ENUM
    features__notin?: string | null; // comma separated values of FEATURE_ENUM
};

/**
 * @deprecated
 * Generate filters from a filterByBusiness state for the BusinessSearchCompanion
 * Should not be used anymore except by the Business Modal
 * /!\ Please use useBusinessModalFilters instead (outside of Sagas)
 */
const generateFiltersFromBusinessModal = (
    filterByBusiness: FilterByBusinessState,
    isSelectionMode: boolean = false,
): BusinessModalFiltersType => {
    const groupIdIn = groupIdInSelector(filterByBusiness) || [];
    const { org_id: orgId, ...searchFilters } = filterByBusiness.searchFilters;

    // Business modal filters
    const businessModalFilters = {
        business__in: includedIdsSelector(filterByBusiness),
        business__notin: excludedIdsSelector(filterByBusiness),
        business_query: querySelector(filterByBusiness),
        business_city: citySelector(filterByBusiness),
        groups: groupIdIn.length ? groupIdIn.toString() : null,
    };

    // Remove empty values
    Object.keys(businessModalFilters).forEach(
        key =>
            [undefined, null, ''].includes(businessModalFilters[key]) &&
            delete businessModalFilters[key],
    );

    const filters = {
        // Search params from page
        ...searchFilters,
        ...businessModalFilters,

        business_org_id: orgId ?? filterByBusiness.filteredBusinesses.orgs?.[0],
        partoo_ui: true,
    };

    // Remove empty values from filters
    Object.keys(filters).forEach(
        key => [undefined, null, ''].includes(filters[key]) && delete filters[key],
    );

    // If selection mode and only one partoo_ui filter, select no business
    if (
        isSelectionMode &&
        modeSelector(filterByBusiness) === 'select' &&
        Object.keys(businessModalFilters).length === 0
    ) {
        filters.business__in = '';
    }

    return filters as BusinessModalFiltersType;
};

export default generateFiltersFromBusinessModal;
