import React from 'react';

import { FoodMenuSectionType } from 'admin/common/api/calls/bulkFoodMenuApiCalls';
import { AddMenuSection } from 'admin/content/data/pages/FoodMenusCreation/FoodMenusCreation.styled';

import { MenuSections } from './MenuSections';

type Props = {
    sections: FoodMenuSectionType[];
    setSections: React.Dispatch<React.SetStateAction<FoodMenuSectionType[]>>;
};
export const AddDetailsAction: React.FC<Props> = ({ sections, setSections }) => {
    return (
        <AddMenuSection>
            <MenuSections value={sections} onChange={setSections} disabled={false} />
        </AddMenuSection>
    );
};
