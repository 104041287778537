import { useTranslation } from 'react-i18next';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import useTotalBusinessesCount from 'app/common/components/businessModal/hooks/useTotalBusinessesCount';
import useBusiness from 'app/common/hooks/queries/useBusiness';

const MAX_TITLE_LENGTH = 100;

/**
 * Returns the title and subtitle for components that display the Business Modal selected businesses
 */
export default function useSelectedBusinessesTitle(isSelectionMode = false) {
    const { t } = useTranslation();

    const totalCount = useTotalBusinessesCount();
    const selectedCount = useSelectedBusinessesCount() ?? 0;
    const allSelected = selectedCount === 0 || selectedCount === totalCount;
    const businessFilters = useBusinessModalFilters();
    const oneBusinessSelected =
        selectedCount === 1 &&
        !!businessFilters?.business__in &&
        !businessFilters.business__in.includes(',');
    let businessId = '';

    if (oneBusinessSelected) {
        businessId = businessFilters?.business__in ?? '';
    }

    const { data: business } = useBusiness(businessId);

    let title: string;

    if (oneBusinessSelected && business) {
        if (business.name.includes(business.city)) {
            title = business.name;
        } else {
            title = `${business.name}, ${business.city}`;
        }

        // Add ellipsis if title is too long
        title =
            title.length > MAX_TITLE_LENGTH
                ? title.substring(0, MAX_TITLE_LENGTH) + '...'
                : title;
    } else if (isSelectionMode || !allSelected) {
        title = t('selected_businesses_with_count', { count: selectedCount });
    } else {
        title = t('all_my_businesses');
    }

    return {
        title,
        subtitle: oneBusinessSelected && business ? business?.address_full : '',
    };
}
