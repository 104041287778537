import { useEffect } from 'react';

import { Button, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';

import { CompetitorWithId } from 'admin/common/api/types/competitiveBenchmark';

import {
    DeleteModalButtons,
    DeleteModalContainer,
    DeleteModalDescription,
    DeleteModalImage,
    DeleteModalTitle,
} from './DeleteCompetitorModal.styled';
import { useDeleteCompetitor } from '../hooks/useDeleteCompetitor';

type DeleteCompetitorModalProps = {
    onClose: () => void;
    opened: boolean;
    competitor: CompetitorWithId;
};

export const DeleteCompetitorModal = ({
    opened,
    onClose,
    competitor,
}: DeleteCompetitorModalProps) => {
    const { t } = useTranslation();
    const { mutate: deleteCompetitor, isSuccess, reset } = useDeleteCompetitor();

    useEffect(() => {
        if (isSuccess) {
            onClose();
            reset();
        }
    }, [isSuccess]);

    return (
        <Modal isOpen={opened} closeModal={onClose}>
            <DeleteModalContainer>
                <DeleteModalImage>
                    <img
                        src={`${STATIC_BASE_URL}/images/app/delete_modal_image.svg`}
                        alt="delete_section"
                    />
                </DeleteModalImage>
                <DeleteModalTitle>{t('admin:delete_competitor_modal_title')}</DeleteModalTitle>
                <DeleteModalDescription>{t('delete_modal_subtitle')}</DeleteModalDescription>
                <DeleteModalButtons>
                    <Button
                        appearance="outlined"
                        variant="secondary"
                        dataTrackId="delete_group_cancel"
                        onClick={onClose}
                        size="large"
                        shape="cube"
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        dataTrackId="delete_group_delete"
                        variant="primary"
                        onClick={() => competitor && deleteCompetitor({ competitor })}
                        size="large"
                        shape="cube"
                    >
                        {t('delete')}
                    </Button>
                </DeleteModalButtons>
            </DeleteModalContainer>
        </Modal>
    );
};
