import { Link, Text } from '@partoohub/ui';

import {
    DiffusionCleanerStatusData,
    Publisher,
} from 'admin/common/api/calls/diffusionCleanerApiCalls';

import { makeDbAdminUrl } from 'admin/common/data/dbAdminHelpers';

import {
    BusinessInfo,
    IconWithRightMargin,
    RoundedGreyBorderWithLayout,
} from './BusinessCard.styled';
import {
    BusinessEditLink,
    CopyButton,
    GBPLinkButton,
    MissingCategoriesButton,
    PushButton,
    QuickConnect,
    ReadableErrorButton,
    ReinstatementFormButton,
    UnknownErrorButton,
} from './buttons';
import { VerificationLink } from './buttons/VerificationLink';

type Props = {
    buttons: string[];
    business: DiffusionCleanerStatusData;
    provider?: string;
    orgId?: string;
    publisher: Publisher;
    businessEditSection: string;
};

const BusinessButtons = ({
    buttons,
    business,
    provider,
    orgId,
    publisher,
    businessEditSection,
}: Props) => {
    return (
        <>
            {buttons.map(button => {
                switch (button) {
                    case 'missing_categories':
                        return (
                            <MissingCategoriesButton
                                key={`diffusion-cleaner-business-button-${button}-${business.id}`}
                                business={business}
                                publisher={publisher}
                            />
                        );
                    case 'google_form':
                        return (
                            <ReinstatementFormButton
                                key={`diffusion-cleaner-business-button-${button}-${business.id}`}
                            />
                        );
                    case 'back_office_gbp_search':
                        return (
                            <GBPLinkButton
                                key={`diffusion-cleaner-business-button-${button}-${business.id}`}
                                name={business.name}
                                address={business.address_full}
                                zipcode={business.zipcode}
                                city={business.city}
                                country={business.country}
                            />
                        );
                    case 'copy_business_info':
                        return (
                            <CopyButton
                                key={`diffusion-cleaner-business-copy-button-${button}-${business.id}`}
                                name={business.name}
                                address={business.address_full}
                                zipcode={business.zipcode}
                                city={business.city}
                                country={business.country}
                            />
                        );
                    case 'quick_connect':
                        if (publisher === 'google_my_business' || publisher === 'facebook') {
                            return (
                                <QuickConnect
                                    key={`diffusion-cleaner-business-quick-connect-${button}-${business.id}`}
                                    provider={provider}
                                    orgId={orgId}
                                    publisher={publisher}
                                />
                            );
                        } else return;
                    case 'push':
                        return (
                            <PushButton
                                key={`diffusion-cleaner-business-push-${button}-${business.id}`}
                                businessId={business.id}
                                diffuser={business.diffuser_name}
                            />
                        );
                    case 'business_edit_link':
                        return (
                            <BusinessEditLink
                                key={`diffusion-cleaner-business-business_edit_link-${button}-${business.id}-${businessEditSection}`}
                                businessId={business.id}
                                provider={provider}
                                orgId={orgId}
                                businessEditSection={businessEditSection}
                            />
                        );
                    case 'verification':
                        if (publisher === 'google_my_business') {
                            return (
                                <VerificationLink
                                    key={`diffusion-cleaner-business-verification-link-${button}-${business.id}`}
                                    provider={provider}
                                    orgId={orgId}
                                    businessName={business.name}
                                />
                            );
                        } else return;
                    case 'readable_error':
                        return (
                            <ReadableErrorButton
                                key={`diffusion-cleaner-business-see_call_log-${button}-${business.id}`}
                                business={business}
                            />
                        );
                    case 'unknown_error':
                        return (
                            <UnknownErrorButton
                                key={`diffusion-cleaner-business-response_content-${button}-${business.id}`}
                                business={business}
                            />
                        );
                }
            })}
        </>
    );
};

export const BusinessCard = ({
    business,
    buttons,
    provider,
    orgId,
    publisher,
    businessEditSection,
}: {
    business: DiffusionCleanerStatusData;
    buttons: string[];
    provider?: string;
    orgId?: string;
    publisher: Publisher;
    businessEditSection: string;
}) => {
    return (
        <RoundedGreyBorderWithLayout>
            <BusinessInfo>
                <Text variant="bodyMBold">
                    <Link
                        href={makeDbAdminUrl(`business_sql/edit/?id=${business.id}`)}
                        target="_blank"
                        variant="default"
                    >
                        {business.name}
                    </Link>
                </Text>
                <Text variant="bodyMRegular" color={'secondary'}>
                    <IconWithRightMargin className="fa-solid fa-location-dot" />
                    {business.address_full} {business.zipcode} {business.city}
                </Text>
            </BusinessInfo>
            <BusinessButtons
                buttons={buttons}
                business={business}
                provider={provider}
                orgId={orgId}
                publisher={publisher}
                businessEditSection={businessEditSection}
            />
        </RoundedGreyBorderWithLayout>
    );
};
