import { useMutation, useQueryClient } from 'react-query';

import api from 'admin/common/api/calls';
import {
    CompetitorWithId,
    UpdateCompetitorPayload,
} from 'admin/common/api/types/competitiveBenchmark';

import { COMPETITIVE_BENCHMARK_KEYWORDS } from 'admin/common/data/queryKeysConstants';

import { useOrgIdParsed } from './useOrgIdParsed';

export const useUpdateCompetitor = () => {
    const queryClient = useQueryClient();
    const orgId = useOrgIdParsed();

    const {
        mutate: updateCompetitor,
        isSuccess,
        isError,
        reset,
    } = useMutation(
        (payload: UpdateCompetitorPayload): Promise<CompetitorWithId> => {
            return api.competitiveBenchmark.updateCompetitor(payload);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([COMPETITIVE_BENCHMARK_KEYWORDS, orgId]);
            },
        },
    );

    return {
        mutate: updateCompetitor as (payload: UpdateCompetitorPayload) => Promise<CompetitorWithId>,
        isSuccess,
        isError,
        reset,
    };
};
