import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

export const EditCategorySettingsContainer = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    margin-top: 24px;
`;

export const StyledTitle = styled(Text)`
    border-bottom: solid 1px ${greyColorObject.initial};
    padding: 26px 24px;
`;

export const CheckboxContainer = styled.div`
    padding: 24px;
`;

export const StyledSubtitle = styled.div`
    text-align: left;
    border-bottom: solid ${greyColorObject.initial};
    line-height: 1.6px;
    font-weight: bold;
    margin: 6px 0;
    padding-left: 41px;

    span {
        background: white;
        padding: 0 10px;
    }
`;

export const CategorySettingsLegacyBox = styled.div`
    padding: 12px 18px 16px 26px;
    max-height: 76px;
    overflow-y: scroll;
`;
