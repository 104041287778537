import { SuccessResponse } from '@partoohub/api-client';

import {
    CustomFieldsSectionCreationPayload,
    CustomFieldsSectionsCreateData,
    CustomFieldsSectionsGetData,
} from 'app/api/types/business';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'custom_fields/section';

export class CustomFieldsSections extends ApiResourceBaseClass {
    static getCustomFieldsSections(org_id?: number): Promise<CustomFieldsSectionsGetData> {
        const url = RESOURCE_URI + (org_id ? `?org_id=${org_id}` : '');

        return CustomFieldsSections.get(url).then(({ data }) => data);
    }

    static createCustomFieldsSection(
        sectionData: CustomFieldsSectionCreationPayload,
    ): Promise<CustomFieldsSectionsCreateData> {
        return CustomFieldsSections.post(`${RESOURCE_URI}`, sectionData).then(({ data }) => data);
    }

    static editCustomFieldSection(
        sectionId: number,
        sectionData: CustomFieldsSectionCreationPayload,
    ): Promise<SuccessResponse> {
        return CustomFieldsSections.put(`${RESOURCE_URI}/${sectionId}`, sectionData).then(
            ({ data }) => data,
        );
    }

    static deleteCustomFieldSection(sectionId: number): Promise<SuccessResponse> {
        return CustomFieldsSections.delete(`${RESOURCE_URI}/${sectionId}`).then(({ data }) => data);
    }
}
