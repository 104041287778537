import { PureComponent, RefObject, isValidElement } from 'react';

// TODO: Replace by InfiniteScroll from 'react-infinite-scroll-component'
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { Choice, ChoiceWithSubtitle } from 'app/api/types/user';
import {
    ChoiceText,
    IconWrapper,
    SuggestedOptionItem,
    SuggestedOptionItemIcon,
    SuggestedOptionsHeader,
    SuggestedOptionsItems,
} from 'app/common/designSystem/components/atoms/CommonSelectMenu/CommonSelectMenu.styled';

const SCROLL_STEP = 2;

type Props = {
    options: Array<ChoiceWithSubtitle>;
    selectedOptions: Array<ChoiceWithSubtitle>;
    onClick: (item: ChoiceWithSubtitle, e: Event, suggestion?: boolean) => void;
    closeMenu: () => void;
    t: (arg0: string) => string;
    loadMore?: () => void;
    hasMore?: boolean;
    isLoading?: boolean;
    titleOnOptions?: boolean;
    onChangePreselectedValue?: (value?: string | null) => void;
    suggestedOptions?: Array<Choice>;
    suggestedOptionsHeader?: string;
    currentSearch?: string;
};

type State = {
    wrapperRef: HTMLElement | null;
    idxHoverOption: number;
    preSelectedValue?: string;
};

const colorCalculator = (
    index: number,
    idxHoverOption: number,
    option: ChoiceWithSubtitle,
    selectedOptions: Array<ChoiceWithSubtitle>,
) => {
    const selected = selectedOptions.filter(opt => opt.value === option.value).length > 0;
    const keyOver = index === idxHoverOption;

    if (keyOver) {
        if (selected) {
            return 'multiple_select__menu__item_selected_key_over';
        }

        return 'multiple_select__menu__item_key_over';
    }

    if (selected) {
        return 'multiple_select__menu__item_selected';
    }

    return '';
};

class Menu extends PureComponent<Props, State> {
    static defaultProps = {
        hasMore: false,
        isLoading: false,
        titleOnOptions: false,
        loadMore: () => undefined,
    };

    constructor() {
        // @ts-ignore
        super();
        this.state = {
            wrapperRef: null,
            idxHoverOption: -1,
        };
    }

    buttonsRef: Array<any> = [];

    handleKeys = (event: KeyboardEvent) => {
        const { options, onClick, closeMenu } = this.props;
        const { idxHoverOption, wrapperRef } = this.state;
        const { buttonsRef } = this;
        let hoverItem: RefObject<any> | null = null;
        let newIdx = 0;

        switch (event.key) {
            case 'ArrowUp':
                event.preventDefault();
                event.stopPropagation();

                if (idxHoverOption > 0) {
                    newIdx = idxHoverOption - 1;

                    if (newIdx - SCROLL_STEP > 0) {
                        hoverItem = buttonsRef[newIdx - SCROLL_STEP];
                    } else {
                        hoverItem = buttonsRef[newIdx];
                    }
                } else {
                    newIdx = options.length - 1;
                    hoverItem = buttonsRef[newIdx];
                    if (wrapperRef) wrapperRef.scrollTop = wrapperRef.scrollHeight;
                }

                this.setState({
                    idxHoverOption: newIdx,
                });
                break;

            case 'ArrowDown':
                event.preventDefault();
                event.stopPropagation();

                if (idxHoverOption >= options.length - 1) {
                    newIdx = 0;
                    hoverItem = buttonsRef[newIdx];
                    if (wrapperRef) wrapperRef.scrollTop = 0;
                } else {
                    newIdx = idxHoverOption + 1;

                    if (newIdx + SCROLL_STEP < options.length - 1) {
                        hoverItem = buttonsRef[newIdx + SCROLL_STEP];
                    } else {
                        hoverItem = buttonsRef[newIdx];
                    }
                }

                this.setState({
                    idxHoverOption: Math.max(newIdx, 0),
                });
                break;

            case 'Enter':
                event.preventDefault();
                event.stopPropagation();
                if (options.length > 0 && idxHoverOption >= 0)
                    onClick(options[idxHoverOption], event);
                break;

            case 'Escape':
                event.preventDefault();
                event.stopPropagation();
                closeMenu();
                break;

            default:
                break;
        }

        if (hoverItem) {
            // @ts-ignore
            hoverItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeys, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeys, false);
    }

    setWrapperRef = (ref: HTMLElement | null) => {
        this.setState({
            wrapperRef: ref,
        });
    };

    setPreSelectedValue = (value?: string) => {
        const { onChangePreselectedValue } = this.props;
        this.setState({
            preSelectedValue: value,
        });

        if (onChangePreselectedValue) {
            onChangePreselectedValue(value);
        }
    };

    unsetPreSelectedValue = (value?: string) => {
        const { preSelectedValue } = this.state;

        if (value === preSelectedValue) {
            this.setPreSelectedValue();
        }
    };

    displayIcon = (icon?: string | JSX.Element) => {
        if (icon) {
            if (isValidElement(icon)) {
                return icon;
            }
            return (
                <IconWrapper className="margin_right--simple">
                    <i className={`${icon}`} />
                </IconWrapper>
            );
        }
        return <></>;
    };

    render() {
        const {
            options,
            onClick,
            selectedOptions,
            loadMore,
            hasMore,
            isLoading,
            closeMenu,
            titleOnOptions,
            suggestedOptions,
            suggestedOptionsHeader,
            currentSearch,
            t,
        } = this.props;
        const { idxHoverOption, wrapperRef } = this.state;

        const suggestions =
            suggestedOptions?.filter(
                suggestion =>
                    !selectedOptions.find(
                        selectedOption => selectedOption.value === suggestion.value,
                    ),
            ) ?? [];

        const displaySuggestions = currentSearch === undefined || !currentSearch.length;

        let filteredOptions = options;
        if (!!suggestions.length && displaySuggestions) {
            const suggestedValues = suggestions.map(suggestion => suggestion.value);
            filteredOptions = options.filter(option => !suggestedValues.includes(option.value));
        }

        return (
            <div
                className="multiple_select__menu multiple_select__menu--async"
                ref={this.setWrapperRef}
            >
                {!!suggestions.length && displaySuggestions && (
                    <>
                        {suggestedOptionsHeader && (
                            <SuggestedOptionsHeader>
                                {suggestedOptionsHeader}
                            </SuggestedOptionsHeader>
                        )}
                        <SuggestedOptionsItems>
                            {suggestions.map(option => (
                                <SuggestedOptionItem
                                    onClick={e => {
                                        onClick(option, e, true);
                                    }}
                                    key={`suggestion_${option.value}`}
                                >
                                    {option.icon && (
                                        <i className={`${option.icon}  margin_right--simple`} />
                                    )}
                                    {option.label}
                                    <SuggestedOptionItemIcon>
                                        <i className="fa-solid fa-circle-plus" />
                                    </SuggestedOptionItemIcon>
                                </SuggestedOptionItem>
                            ))}
                        </SuggestedOptionsItems>
                        <SuggestedOptionsHeader>
                            {t('async_select_other_results')}
                        </SuggestedOptionsHeader>
                    </>
                )}
                <InfiniteScroll
                    loadMore={loadMore || (() => undefined)}
                    hasMore={hasMore}
                    loader={
                        <div className="flex--justify_center padding--simple" key={0}>
                            <i className="fa-solid fa-circle-notch fa-spin color--secondary" />
                        </div>
                    }
                    initialLoad={false}
                    getScrollParent={() => wrapperRef}
                    useWindow={false}
                >
                    {filteredOptions.map((option, index) => (
                        <button
                            ref={ref => {
                                this.buttonsRef[index] = ref;
                            }}
                            type="button"
                            // prettier-ignore
                            className={`
                                multiple_select__menu__item ${colorCalculator(index, idxHoverOption, option, selectedOptions)}
                            `}
                            onClick={e => {
                                // @ts-ignore
                                onClick(option, e);
                                this.unsetPreSelectedValue(option.value); // sorry...
                            }}
                            key={option.value}
                            onMouseOver={() => this.setPreSelectedValue(option.value)}
                            onFocus={() => this.setPreSelectedValue(option.value)}
                            onMouseOut={() => this.unsetPreSelectedValue(option.value)}
                            onBlur={() => this.unsetPreSelectedValue(option.value)}
                            title={titleOnOptions ? option.label : ''}
                        >
                            <div className="multiple_select__menu__item_content">
                                {this.displayIcon(option?.icon)}
                                <ChoiceText>
                                    <span>{option.label}</span>
                                    <span>{option.subtitle}</span>
                                </ChoiceText>
                            </div>
                            {selectedOptions.filter(opt => opt.value === option.value).length >
                                0 && <i className="fa-solid fa-check" />}
                        </button>
                    ))}
                    {!filteredOptions.length && !isLoading && (
                        <button
                            type="button"
                            className="multiple_select__menu__item__empty_placeholder"
                            onClick={closeMenu}
                        >
                            {t('no-result-found')}
                        </button>
                    )}
                    {isLoading && (
                        <div className="flex--justify_center padding--simple">
                            <i className="fa-solid fa-circle-notch fa-spin color--secondary" />
                        </div>
                    )}
                </InfiniteScroll>
            </div>
        );
    }
}

export default withTranslation()(Menu);
