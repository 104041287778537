import { Button, FontAwesomeIconsPartooUsed, IconPrefix, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { STATIC_ADMIN_ASSETS_DIR } from 'app/config';

import { StyledImage } from './SurveySectionNoContent.styled';

type Props = {
    addSurvey: () => void;
};

export const SurveySectionNoContent = ({ addSurvey }: Props) => {
    const { t } = useTranslation();

    return (
        <Stack alignItems="center" gap="0px">
            <StyledImage
                src={`${STATIC_ADMIN_ASSETS_DIR}/feedback-management-no_survey.png`}
                alt=""
            />
            <Stack alignItems="center" gap="16px">
                <Text variant="heading3">{t('admin:feedback_management__no_surveys')}</Text>
                <Button
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.REGULAR]}
                    onClick={() => addSurvey()}
                    dataTrackId="feedback_management__add_survey_button"
                >
                    {t('admin:feedback_management__add_survey_button')}
                </Button>
            </Stack>
        </Stack>
    );
};
