import React, { FC } from 'react';

import { HeadManager } from '@partoohub/modular-components';
import { ErrorBoundary } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import PageErrorPlaceholder from 'admin/common/components/errors/PageErrorPlaceholder';
import env from 'admin/common/utils/getEnvironment';

import GoogleAccountRefresh from './GoogleAccountRefresh';

const Root: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <HeadManager
                title={t('admin:page_google_account_refresh__meta_title')}
                env={env.getEnv()}
            />
            <ErrorBoundary
                dataTrackId="page_google_account_refresh_error"
                FallbackComponent={PageErrorPlaceholder}
            >
                <GoogleAccountRefresh />
            </ErrorBoundary>
        </>
    );
};

export default Root;
