import React from 'react';

import {
    FooterElement,
    FooterIcon,
    FooterText,
    ModalFooter,
} from './QuickActionModal.styled';

const QuickActionModalFooter: React.FC = () => (
    <ModalFooter>
        <FooterElement>
            <FooterIcon className="fa-solid fa-arrow-turn-down-left" />
            <FooterText>Open</FooterText>
        </FooterElement>
        <FooterElement>
            <FooterIcon className="fa-solid fa-exchange-alt fa-rotate-90" />
            <FooterText>Select</FooterText>
        </FooterElement>
        <FooterElement>
            <FooterIcon className="fa-solid fa-command" />
            <FooterIcon className="fa-solid fa-arrow-turn-down-left" />
            <FooterText>Open in a new tab</FooterText>
        </FooterElement>
    </ModalFooter>
);

export default QuickActionModalFooter;
