import React from 'react';

import { Text } from '@partoohub/ui';

import { useQuery } from 'react-query';

import { DiffusionCleanerAdminApiCall } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DIFFUSION_CLEANER_READABLE_ERROR } from 'admin/common/data/queryKeysConstants';

import { useMe } from 'admin/common/hooks/queries/useMe';

type Props = {
    call_log_id: number;
};

export const HumanErrorMessageComponent = ({ call_log_id }: Props) => {
    const me = useMe();
    const { isSuccess, data } = useQuery({
        queryKey: [DIFFUSION_CLEANER_READABLE_ERROR, call_log_id],
        queryFn: () =>
            DiffusionCleanerAdminApiCall.getCallLog({
                call_log_id: parseInt(call_log_id),
            }),
        enabled: me.isSuccess,
    });

    return (
        <>
            {isSuccess && (
                <Text variant="bodyLRegular">{JSON.parse(data.human_error_message)}</Text>
            )}
        </>
    );
};
