import React from 'react';

import { Filters } from '@partoohub/ui';

import { ERROR, FIELD_ERROR, PENDING, SUCCESS } from 'admin/common/data/constants/publisherStates';

type Props = {
    states: Record<string, boolean>;
    setStates: (selectedItems: Record<string, boolean>) => void;
    disabled?: boolean;
};

export const DiffusionLogsStateSelect: React.FC<Props> = ({
    states,
    setStates,
    disabled,
}: Props) => {
    const stateOptions = [SUCCESS, ERROR, FIELD_ERROR, PENDING].map(action => ({
        value: action,
        label: action,
        name: action,
    }));
    const section = [{ options: stateOptions }];
    return (
        <Filters
            dataTrackId="admin_diffusion_logs__filter_state"
            menuListArray={section}
            selectedItems={states}
            onChange={setStates}
            placeholder={'States'}
            position="bottom"
            maxHeight={300}
            multiple={true}
            disabled={disabled}
        />
    );
};
