import React, { FC } from 'react';

import { TextInput } from '@partoohub/ui';

type Props = {
    placeholder: string;
    email: string;
    isMandatory?: boolean;
    onChange: React.Dispatch<React.SetStateAction<string>>;
};

const EmailInput: FC<Props> = ({ placeholder, email, isMandatory, onChange }) => (
    <TextInput
        dataTrackId="admin-email-input"
        placeholder={placeholder}
        label={placeholder}
        type="email"
        required={isMandatory}
        onChange={value => onChange(value || '')}
        value={email}
    />
);

export default EmailInput;
