import { BusinessCount, BusinessModalButtonVariant } from '@partoohub/modular-components';
import { useTranslation } from 'react-i18next';

export const useBusinessModalButtonText = (
    count: BusinessCount,
    variant: BusinessModalButtonVariant = 'page',
): string => {
    const { t } = useTranslation();

    const businessCount = count?.selected;
    const total = count?.total;

    const totalText = total
        ? `${t('filter_by_business')} ( ${Number(total).toLocaleString()} )`
        : t('filter_by_business');

    return businessCount && businessCount >= 1
        ? t('business_modal_button_business_count_label', { count: businessCount ?? 0 })
        : totalText;
};
