import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { V2UserData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { ME } from 'app/common/data/queryKeysConstants';

interface Options {
    enabled?: boolean;
    onSuccess?: (data: V2UserData) => void;
    onError?: (err: AxiosError) => void;
    retry?: boolean;
    refetchOnReconnect?: boolean;
    refetchOnWindowFocus?: boolean;
}

/**
 * Please use useMe from "front/javascript/app/pages/app/AppDataProvider/hooks/useMe.ts" instead
 */
export default function useMe(options?: Options) {
    return useQuery([ME], () => api.user.getMe(), options);
}
