import { FeedbackFormListData } from '@partoohub/api-client';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { feedbackFormApiClient } from 'admin/api/apiResources';
import { FEEDBACK_FORM } from 'admin/common/data/queryKeysConstants';

export const useGetFeedbackForms = (
    orgId: number,
    options: UseQueryOptions<FeedbackFormListData> = {},
) => {
    const query = useQuery(
        [FEEDBACK_FORM, orgId] as QueryKey,
        () => feedbackFormApiClient.getFeedbackForms(orgId),
        {
            ...options,
            retry: false,
        },
    );

    const forms = query?.data?.items ?? [];
    return [forms, query] as const;
};
