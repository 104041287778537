import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';
import { getQueryStringFromObject } from '@partoohub/utils';

import { BulkExportBusinessAdminFilter, BulkExportUserAdminFilter } from 'admin/api/types';

const RESOURCE_URI = 'bulk';

export class BulkExportAdminApiCall extends ApiResourceBaseClass {
    async bulkBusinessExport(
        filter: BulkExportBusinessAdminFilter,
        email?: string,
    ): Promise<SuccessResponse> {
        const { data } = await this.get<SuccessResponse>(
            `${RESOURCE_URI}/business/exporter?${getQueryStringFromObject({
                email,
                ...filter,
            })}`,
        );
        return data;
    }

    async bulkUserExport(filter: BulkExportUserAdminFilter): Promise<SuccessResponse> {
        const { data } = await this.get<SuccessResponse>(`${RESOURCE_URI}/user/exporter`, {
            ...filter,
        });
        return data;
    }
}
