import { camelCase, mapKeys } from 'lodash-es';

import { deepMapper } from 'app/common/utils/deepMapper';

const camelCasify = (obj: Record<string, any>): Record<string, any> =>
    mapKeys(obj, (v, key) => camelCase(key));

export const nestedCamelCasify = (obj: any): Record<string, any> => deepMapper(obj, camelCasify);

export const nestedCamelCasifyTyped = <T>(obj: unknown): T => deepMapper(obj, camelCasify);

export default camelCasify;
