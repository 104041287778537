import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

const AUDIO_UPLOAD_URI = 'audio';

export type TranscodedInfos = {
    audio_codec: string;
    url: string;
};

export type UploadAudioResponse = {
    status: string;
    transcoded_infos: Array<TranscodedInfos>;
};

class MessagingCallDeflectionApiCalls extends ApiResourceBaseClass {
    static uploadAudio(audio_file: File): Promise<UploadAudioResponse> {
        const formData = new FormData();
        formData.append('audio_file', audio_file);
        formData.append('transcode_formats', 'mp3');
        return MessagingCallDeflectionApiCalls.post(AUDIO_UPLOAD_URI, formData).then(
            ({ data }) => data,
        );
    }
}

export default MessagingCallDeflectionApiCalls;
