import { FC } from 'react';

import { Filters } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { BM, PM, RB, RM } from 'admin/common/data/constants/products';

type Props = {
    products: Record<string, boolean>;
    placeholder: string;
    setProducts: (selectedItems: Record<string, boolean>) => void;
    dataTrackId: string;
};

export const ProductSelect: FC<Props> = ({
    products,
    placeholder,
    setProducts,
    dataTrackId,
}: Props) => {
    const { t } = useTranslation();

    const options = [
        {
            value: PM,
            label: t(PM),
            name: PM,
        },
        {
            value: RM,
            label: t(RM),
            name: RM,
        },
        {
            value: RB,
            label: t(RB),
            name: RB,
        },
        {
            value: BM,
            label: t(BM),
            name: BM,
        },
    ];
    const section = [{ options: options }];

    return (
        <Filters
            dataTrackId={dataTrackId}
            menuListArray={section}
            selectedItems={products}
            onChange={setProducts}
            placeholder={placeholder}
            position="bottom"
            maxHeight={300}
            multiple
        />
    );
};
