import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Choice } from 'app/api/types/user';
import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect';
import { useOrgs } from 'app/common/hooks/queries/useOrgs';
import useDebounce from 'app/common/hooks/useDebounce';

type Props = {
    onChange: (org: Choice | null) => void;
    value: Choice | null;
};

const OrganizationSelect = ({ onChange, value }: Props) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const queryDebounced = useDebounce(query);
    const [orgsQuery, orgs] = useOrgs({ query: queryDebounced });

    const options: Array<Choice> =
        orgs?.map(org => ({
            label: org.name,
            value: org.org_id.toString(),
        })) ?? [];

    const loadMore = (searchInput: string) => {
        if (searchInput != query) {
            setQuery(searchInput);
        } else {
            orgsQuery.fetchNextPage();
        }
    };

    return (
        <AsyncSingleSelect
            options={options}
            selectedValue={value}
            onChange={onChange}
            hasMore={orgsQuery.hasNextPage}
            loadMore={loadMore}
            placeholder={t('organization')}
            mustFilterOptions
        />
    );
};

export default OrganizationSelect;
