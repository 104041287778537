import { CompetitorWithId, KeywordAdminData } from 'admin/common/api/types/competitiveBenchmark';

import { useGetKeywordsFromOrg } from '../hooks/useGetKeywordsFromOrg';
import { useOrgIdParsed } from '../hooks/useOrgIdParsed';

export const useGetCompetitorListByKeywordId = (keywordId: string): CompetitorWithId[] => {
    const orgId = useOrgIdParsed();
    const { keywords, isLoading } = useGetKeywordsFromOrg(orgId);

    if (!keywords || isLoading) {
        return [];
    }

    return (
        keywords.find((keyword: KeywordAdminData) => keyword.id === keywordId)?.competitors || []
    );
};
