import { Option } from '@partoohub/ui';

import { V2PartnerAccountData } from 'app/api/types/account';

export const convertGmbAccountToOption = (gmb_account: V2PartnerAccountData): Option =>
    ({
        label: `${gmb_account.account_name} (${gmb_account.id})`,
        name: gmb_account.id,
        value: gmb_account.id,
    }) as Option;
