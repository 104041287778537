import { useMemo } from 'react';

import { GroupSectionList } from 'app/api/types/groupsV2';
import { matchAccesses } from 'app/common/components/businessModal/utils/businessIsSelected';
import { GROUP_MANAGER } from 'app/common/data/roles';
import { useSectionMapping } from 'app/common/hooks/queries/useGroupsV2';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export default function useIsMatchingAccesses(
    value: Array<number>,
    sections: GroupSectionList | undefined,
): boolean {
    const { data: me } = useMe();
    const accesses =
        me?.accesses?.map(access =>
            access.map(groupId => groupId.toString()),
        ) ?? [];

    const groupV2ToSection = useSectionMapping(sections);

    return useMemo(() => {
        if (me?.role !== GROUP_MANAGER) {
            return true;
        }

        return matchAccesses(
            value.map(groupId => groupId.toString()),
            accesses,
            groupV2ToSection,
        );
    }, [me, value, accesses, groupV2ToSection]);
}
