import { List } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { useMergedUsers } from 'admin/content/tools/pages/MultiOrganizations/hooks/useMergedUsers';

import { MergedAccountRow } from './MergedAccountRow';
import { MergedAccountsListContainer } from './MergedAccountsList.styled';
import { MergedAccountsListHeader } from './MergedAccountsListHeader';
import { MergedAccountsMenuList } from './MergedAccountsMenuList';

export const MergedAccountsList = () => {
    const { t } = useTranslation();

    const { query, userInfos } = useMergedUsers();
    const { fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = query;

    const columns = [
        { label: t('admin:org_switcher_list_column_account') },
        { label: t('admin:org_switcher_list_column_accessible_orgs') },
    ];

    return (
        <MergedAccountsMenuList headerContent={<MergedAccountsListHeader />}>
            <MergedAccountsListContainer>
                <InfiniteScroll
                    hasMore={hasNextPage && !isFetchingNextPage}
                    loadMore={() => fetchNextPage()}
                    useWindow={false}
                    getScrollParent={() => document.getElementById('react-content')}
                >
                    <List columns={columns} loading={isLoading}>
                        {userInfos.map(userInfo => (
                            <MergedAccountRow key={userInfo.id} userInfo={userInfo} />
                        ))}
                    </List>
                </InfiniteScroll>
            </MergedAccountsListContainer>
        </MergedAccountsMenuList>
    );
};
