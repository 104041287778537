import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import LocationCard from 'app/businessConnection/components/common/LocationCard/LocationCard';
import { STATIC_BASE_URL } from 'app/config';

export const FullscreenConnectionModal = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
`;

export const FullscreenConnectionModalScrollable = styled.div`
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 42px 42px 0 42px;
    // To avoid eating the box-shadow
    margin-bottom: -8px;
`;

export const FullscreenConnectionModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 40px;
`;

export const FullscreenConnectionModalHeaderTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    h3 {
        margin: 0;
    }
    img {
        height: 24px;
    }
`;

export const FullscreenConnectionModalInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
`;

export const FullscreenConnectionModalHeaderDescription = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const FullscreenConnectionModalFooter = styled.div`
    padding: 24px 42px 42px 42px;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 90%,
        rgba(255, 255, 255, 0) 100%
    );
`;

export const LocationCardStyled = styled(LocationCard)`
    position: relative;
    border: 1px solid ${greyColorObject.initial};
    border-radius: 4px;
    align-items: center;
    background-color: white;
    height: 100%;
    width: 100%;

    border: 1px solid ${({ theme }) => theme.colors.primary.initial};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary.initial};

    &:before {
        position: absolute;

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 40px 40px 0;

        top: 0;
        right: 0;
        opacity: 1;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
        border-color: transparent ${({ theme }) => theme.colors.primary.initial} transparent
            transparent;
        content: '';
    }

    &:after {
        position: absolute;
        top: 2px;
        right: 4px;
        content: url(${`${STATIC_BASE_URL}/images/app/funnel/icon-checked.svg`});
    }
`;

export const LoadingError = styled.h3`
    padding: 16px;
    text-align: center;
`;
