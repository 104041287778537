import AdminApiResourceBaseClass from 'admin/common/api/calls/baseAdminApiCalls';

const RESOURCE_URI = 'old_extractor';

type BulkExportV1Filter = {
    email: string;
    partner: string;
    statuses?: string[];
    org_ids?: string[];
    providers?: string[];
    is_enterprise?: string;
    has_active_presence_management?: string;
    has_active_review_management?: string;
    has_active_review_booster?: string;
};

class BulkExportV1ApiCall extends AdminApiResourceBaseClass {
    static bulkExportV1(filter: BulkExportV1Filter): Promise<any> {
        return BulkExportV1ApiCall.post(`${RESOURCE_URI}`, {
            ...filter,
        }).then(({ data }) => data);
    }
}

export default BulkExportV1ApiCall;
