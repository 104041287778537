import { format, getTime, parseISO } from 'date-fns';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const ISO_DATE_FORMAT = 'd MMM yyyy';

export const HOUR_FORMAT = 'HH:mm';

export const stringifyDate = (date: Date, formatter: string = DATE_FORMAT) => {
    return format(date, formatter);
};

export const formatISODate = (datetime: string) => {
    const parsedDate = parseISO(datetime);
    return format(parsedDate, ISO_DATE_FORMAT);
};

export const dateToUnix = (date: string) => {
    return getTime(date) / 1000;
};
