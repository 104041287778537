import React from 'react';

import { useTranslation } from 'react-i18next';

import IS_IFRAME from 'app/common/data/iframe';

import { STATIC_BASE_URL } from 'app/config';

import {
    EditFormDescriptionAdviceContainer,
    EditFormDescriptionAdviceText,
    EditFormDescriptionAdviceTexts,
    EditFormDescriptionAdviceTitle,
    EditFormDescriptionContainer,
    EditFormDescriptionContent,
    EditFormDescriptionPartooIcon,
    EditFormDescriptionSubtitle,
    EditFormDescriptionTitle,
} from './EditFormDescription.styled';
import Illustration from './Illustration';

type Props = {
    title: string;
    description?: string;
    advice?: string;
    children?: React.ReactNode;
};

const EditFormDescription = ({ title, description, advice, children }: Props) => {
    const { t } = useTranslation();

    return (
        <EditFormDescriptionContainer>
            <EditFormDescriptionContent>
                <EditFormDescriptionTitle>{title}</EditFormDescriptionTitle>
                {description && (
                    <EditFormDescriptionSubtitle>{description}</EditFormDescriptionSubtitle>
                )}
                {children}
                {advice && (
                    <EditFormDescriptionAdviceContainer>
                        <Illustration />
                        <EditFormDescriptionAdviceTexts>
                            <EditFormDescriptionAdviceTitle>
                                {t('our_advice')}
                            </EditFormDescriptionAdviceTitle>
                            <EditFormDescriptionAdviceText>{advice}</EditFormDescriptionAdviceText>
                        </EditFormDescriptionAdviceTexts>
                    </EditFormDescriptionAdviceContainer>
                )}
            </EditFormDescriptionContent>
            {!IS_IFRAME && (
                <EditFormDescriptionPartooIcon>
                    <img
                        src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/partoo_dev.svg`}
                        className="logo"
                        alt=""
                    />
                </EditFormDescriptionPartooIcon>
            )}
        </EditFormDescriptionContainer>
    );
};

export default EditFormDescription;
