import { useLayoutEffect, useState } from 'react';

type WindowSize = {
    width: number | undefined;
    height: number | undefined;
};

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useLayoutEffect(() => {
        const handleResize = () =>
            setWindowSize({
                width: window?.innerWidth || undefined,
                height: window?.innerHeight || undefined,
            });

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
};
