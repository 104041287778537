import { FC, useMemo, useState } from 'react';

import { FakeReviewsGenerationData } from '@partoohub/api-client';
import { HEX_COLORS } from '@partoohub/branding';
import {
    Checkbox,
    DatePickerType,
    Option,
    OverrideDatePicker,
    RangeDate,
    Switch,
    Text,
    TextInput,
} from '@partoohub/ui';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import { FEATURE_ENUM } from 'app/api/types/plans_features';

import BusinessSelect from 'admin/common/components/inputs/BusinessSelect';
import { SingleSelect } from 'admin/common/components/inputs/SingleSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';

import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { reviewApiClient } from 'admin/common/data/apiResources';
import { DEFAULT_LOCALE_DATE_PICKER } from 'admin/common/data/locale';
import { DATE_FORMAT } from 'admin/common/utils/dates';

export const GenerateFakeData: FC = () => {
    const { t } = useTranslation();
    const [business, setBusiness] = useState<Option | undefined>(undefined);
    const [dates, setDates] = useState<RangeDate>([null, null]);
    const [rating, setRating] = useState<string | null>(null);
    const [time, setTime] = useState<string | undefined>(undefined);
    const [action, setAction] = useState<'create' | 'update'>('create');
    const [realtimeUpdates, setRealtimeUpdates] = useState(false);
    const [count, setCount] = useState(0);

    const { mutateAsync, isSuccess, isError, isLoading } = useMutation(
        (fakeReviews: FakeReviewsGenerationData) => {
            return reviewApiClient.generateFakeReviews(fakeReviews);
        },
    );

    const color = () => {
        if (isSuccess) return 'success';
        if (isError) return 'danger';
        return 'primary';
    };

    const isFormValid = useMemo(() => {
        if (business === undefined) return false;
        if (rating === null) return false;
        if (dates[0] === null || dates[1] === null) return false;
        if (action === 'update' && !realtimeUpdates) return false;
        return true;
    }, [business, rating, dates, action, realtimeUpdates]);

    const submitForm = () => {
        if (!isFormValid) return;
        const payload: {
            business_id: string;
            rating?: number;
            start_date: string;
            end_date: string;
            count: number;
            action: 'create' | 'update';
            realtime_updates: boolean;
            datetime?: string;
        } = {
            business_id: (business as Option).value,
            rating: rating ? parseInt(rating) : undefined,
            start_date: dates?.[0] ? format(dates?.[0], DATE_FORMAT) : '',
            end_date: dates?.[1] ? format(dates?.[1], DATE_FORMAT) : '',
            count,
            action: action,
            realtime_updates: realtimeUpdates,
        };
        if (time !== undefined) {
            payload.datetime = dates?.[0] ? format(dates[0], DATE_FORMAT) + 'T' + time + ':00' : '';
        }
        mutateAsync(payload);
    };

    const handleCountChange = (input: string | undefined): void => {
        if (input === undefined) {
            setCount(0);
            return;
        }
        const parsed = parseInt(input);
        if (isNaN(parsed)) {
            setCount(0);
            return;
        }
        setCount(parsed);
    };

    const handleRealtimeUpdatesChange = () => {
        if (realtimeUpdates) {
            setRealtimeUpdates(false);
            return;
        }
        setRealtimeUpdates(true);
    };

    const handleActionChange = () => {
        if (action === 'update') {
            setAction('create');
            return;
        }
        setAction('update');
    };

    const handleBusinessChange = (businesses?: Option): void => {
        setBusiness(businesses);
    };

    return (
        <AdminPage
            dataTrackId="page_tools_generate_fake_data"
            title={t('admin:page_tools_generate_fake_data__title')}
            description={t('admin:page_tools_generate_fake_data__description')}
        >
            <BorderedContainer>
                <PageFormRow>
                    <BusinessSelect
                        dataTrackId="admin_generate_fake_data__select_business"
                        businesses={business}
                        setBusinesses={handleBusinessChange}
                        placeholder={t('admin:filter_select_businesses')}
                        searchBusinessParams={{ features__in: FEATURE_ENUM.REVIEW_MANAGEMENT }}
                        isMulti={false}
                        required
                    />
                </PageFormRow>
                <PageFormRow>
                    <OverrideDatePicker
                        dataTrackId="page_tools_reviews__select_date"
                        type={DatePickerType.DateRangePicker}
                        labels={{ startDate: t('date_begin'), endDate: t('date_end') }}
                        dates={dates}
                        onChange={setDates}
                        placeholders={{
                            startDate: t('date_placeholder'),
                            endDate: t('date_placeholder'),
                        }}
                        localeDatePicker={DEFAULT_LOCALE_DATE_PICKER}
                        required
                    />
                </PageFormRow>
                <PageFormRow>
                    <SingleSelect
                        dataTrackId="generate_fake_data__select_rating"
                        placeholder={t('choose_an_option')}
                        onChange={setRating}
                        sections={[
                            {
                                options: [1, 2, 3, 4, 5].map(value => ({
                                    value: `${value}`,
                                    name: `${value}`,
                                    label: t('star_rating', { count: value }),
                                })),
                            },
                        ]}
                        selectedValue={rating}
                        required
                    />
                    <TextInput
                        dataTrackId="generate_fake_data__time"
                        value={time}
                        placeholder="HH:mm"
                        notice={t('admin:page_generate_fake_data__specific_hour')}
                        onChange={setTime}
                    />
                </PageFormRow>
                <PageFormRow>
                    <Switch
                        dataTrackId="generate_fake_data__switch"
                        name="generate_fake_data"
                        checked={action === 'update'}
                        onChange={handleActionChange}
                        label={
                            <Text variant="bodyMBold" color={HEX_COLORS.secondary}>
                                {t('generate_fake_review_updates')}
                            </Text>
                        }
                    />
                </PageFormRow>
                <PageFormRow>
                    <Checkbox
                        dataTrackId="generate_fake_data__checkbox"
                        checked={realtimeUpdates}
                        onChange={handleRealtimeUpdatesChange}
                    >
                        <Text variant="bodyMBold" as="span" color="secondary">
                            {t('generate_fake_reviews_enable_realtime_updates')}
                        </Text>
                    </Checkbox>
                </PageFormRow>
                <PageFormRow>
                    <TextInput
                        dataTrackId="admin_generate_fake_data__reviews_count"
                        notice={`${t('generate_fake_data_select_review_count')}. ${t('it_will_send_email_only_on_first_negative_review')}`}
                        placeholder={t('generate_fake_data_select_review_count')}
                        onChange={handleCountChange}
                        value={count.toString()}
                    />
                </PageFormRow>
                <PageFormRow>
                    <SubmitButton
                        dataTrackId="admin_generate_fake_data__generate"
                        disabled={!isFormValid}
                        text={t('generate_fake_data')}
                        onClick={submitForm}
                        variant={color()}
                        loading={isLoading}
                    />
                </PageFormRow>
            </BorderedContainer>
        </AdminPage>
    );
};
