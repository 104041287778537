import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Progress } from '@partoohub/ui';

export const RowCssContainer = css`
    overflow-y: unset;
`;

export const ProgressBar = styled(Progress)`
    height: 8px;
    width: 50px;
`;
