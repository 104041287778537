import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { CategorySaveButtonContainer } from './CategorySaveButton.styled';

interface CategorySaveButtonProps {
    disabled: boolean;
    onClick: () => void;
}

export const CategorySaveButton = ({ disabled, onClick }: CategorySaveButtonProps) => {
    const { t } = useTranslation();
    return (
        <CategorySaveButtonContainer>
            <Button
                dataTrackId="page_category_remap__save_button"
                icon={disabled ? undefined : [FontAwesomeIconsPartooUsed.faSave, IconPrefix.SOLID]}
                onClick={onClick}
                disabled={disabled}
                variant="primary"
                shape="cube"
                size="large"
                full
            >
                {t('save')}
            </Button>
        </CategorySaveButtonContainer>
    );
};
