import GoogleAdminManagement from 'app/api/v2/api_calls/googleAdminManagementApiCalls';

import Account from './accountApiCalls';
import { BulkExportClientApiCall as BulkExportClient } from './bulkExportApiCalls';
import { BulkImportClientApiCall as BulkImportClient } from './bulkImportApiCalls';
import BusinessModule from './business';
import Business from './businessApiCalls';
import Connection from './connectionApiCalls';
import { FeedbackForm } from './feedbackForm';
import { FeedbackLookerStudio } from './feedbackLookerStudio';
import { FeedbackReplySuggestion } from './feedbackReplySuggestionApiCalls';
import GroupV2 from './groupsV2ApiCalls';
import InteractionTag from './interactionTagApiCalls';
import Organization from './orgApiCalls';
import PartnerLink from './partnerLinksApiCalls';
import PlaceActionLinks from './placeActionLinksApiCalls';
import Post from './postApiCalls';
import PresenceAnalytics from './presenceAnalyticsApiCalls';
import { ReviewAnalytics } from './reviewAnalyticsApiCalls';
import Review from './reviewApiCalls';
import { ReviewAutoTagSettings } from './reviewAutoTagSettingsApiCalls';

const api = {
    connection: Connection,
    review: Review,
    reviewAnalytics: ReviewAnalytics,
    reviewAutoTagSettings: ReviewAutoTagSettings,
    feedbackForm: FeedbackForm,
    feedbackLookerStudio: FeedbackLookerStudio,
    feedbackReplySuggestion: FeedbackReplySuggestion,
    account: Account,
    business: Business,
    businessModule: BusinessModule,
    org: Organization,
    presenceAnalytics: PresenceAnalytics,
    groupsV2: GroupV2,
    image: Image,
    post: Post,
    bulkExportClient: BulkExportClient,
    bulkImportClient: BulkImportClient,
    interactionTag: InteractionTag,
    googleAdminManagement: GoogleAdminManagement,
    placeActionLinks: PlaceActionLinks,
    partnerLinks: PartnerLink,
};

export default api;
