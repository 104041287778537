import useSelectedOrgBusinessModal from 'app/common/components/businessModal/hooks/privateHooks/useSelectedOrgBusinessModal';
import { ADMIN, PROVIDER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

/**
 * Returns if the groups should be hidden for admins/providers if no org is selected
 */
export default function useHideGroupsForAdmins(): boolean {
    const { data: me } = useMe();
    const { searchOrgId, modalSelectedOrgId } = useSelectedOrgBusinessModal();
    const hasAnyOrgSelected = !!(modalSelectedOrgId || searchOrgId);

    return [ADMIN, PROVIDER].includes(me?.role ?? '') && !hasAnyOrgSelected;
}
