import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';

import {
    GetUserInfosParams,
    MergeUserInfosBody,
    UserInfo,
    UserInfosReceivedData,
} from '../types/userInfos';

const RESOURCE_URI = 'user-infos';

export class UserInfosApiCall extends ApiResourceBaseClass {
    async getUserInfos(params: GetUserInfosParams = {}): Promise<UserInfosReceivedData> {
        const { data } = await this.get<UserInfosReceivedData>(`${RESOURCE_URI}`, params);
        return data;
    }

    async getUsersByUserInfoId(userInfoId: number): Promise<UserInfo> {
        const { data } = await this.get<UserInfo>(`${RESOURCE_URI}/${userInfoId}`);
        return data;
    }

    async mergeUserInfos(mainUserInfo: number, body: MergeUserInfosBody): Promise<SuccessResponse> {
        const { data } = await this.post<SuccessResponse>(
            `${RESOURCE_URI}/${mainUserInfo}/merge`,
            body,
        );
        return data;
    }
}
