import { FC } from 'react';

import { Filters } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { UserStatus } from 'admin/common/data/enums';

type Props = {
    status: Record<string, boolean>;
    placeholder: string;
    setStatus: (selectedItems: Record<string, boolean>) => void;
    dataTrackId: string;
};

const UserStatusSelect: FC<Props> = ({ status, placeholder, setStatus, dataTrackId }: Props) => {
    const { t } = useTranslation();
    const options = [
        {
            value: UserStatus.INVITED,
            label: t(UserStatus.INVITED),
            name: UserStatus.INVITED,
        },
        {
            value: UserStatus.ACTIVE,
            label: t(UserStatus.ACTIVE),
            name: UserStatus.ACTIVE,
        },
    ];
    const section = [{ options: options }];

    return (
        <Filters
            dataTrackId={dataTrackId}
            menuListArray={section}
            selectedItems={status}
            onChange={setStatus}
            placeholder={placeholder}
            position="bottom"
            maxHeight={300}
        />
    );
};

export default UserStatusSelect;
