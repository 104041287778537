import styled from '@emotion/styled';

export const FiltersWrapper = styled.div`
    display: flex;
    margin: 8px 0;
`;

export const DateFilterWrapper = styled.div`
    display: inline-block;
    padding-right: 10px;
`;
