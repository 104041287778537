import { useCallback, useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { DiffusionCleanerAdminApiCall } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DiffusionCleanerCountFilters } from 'admin/common/api/types/diffusion_cleaner';
import { DIFFUSION_CLEANER_COUNT } from 'admin/common/data/queryKeysConstants';
import { getSelectedOptions } from 'admin/common/utils/selectOptions';

import { useOrg } from './../hooks/useOrg';

export const useDiffusionCount = (filters: DiffusionCleanerCountFilters) => {
    const [org] = useOrg();
    const orgId = filters.org?.value || org?.value;
    const [pageHasError, setPageHasError] = useState(false);

    const queryKey = useMemo(
        () => [DIFFUSION_CLEANER_COUNT, filters],
        [orgId, filters.provider, filters.groups, filters.publishers, filters.errorTypes],
    );

    const queryFn = useCallback(() => {
        return DiffusionCleanerAdminApiCall.diffusionCleanerCount({
            orgId,
            provider: filters.provider?.value,
            publishers: getSelectedOptions(filters.publishers),
            errorKeys: getSelectedOptions(filters.errorTypes),
            orderBy: '-count',
            groups: getSelectedOptions(filters.groups),
        });
    }, [filters, orgId]);

    const { data, isLoading, error } = useQuery(queryKey, queryFn, {
        staleTime: Infinity,
        onSuccess: () => {
            setPageHasError(false);
        },
        onError: () => {
            setPageHasError(true);
        },
        enabled: !!orgId || !!filters.provider,
    });
    return { data, isLoading, error, pageHasError };
};
