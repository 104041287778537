// ACTION types
export const SET_HIDEABLE_COMPONENT_DISPLAY = 'SET_HIDEABLE_COMPONENT_DISPLAY';

// HIDEABLE COMPONENTS NAME
// App display options
const PRESENCE_DOWNLOAD_BUTTON = 'PRESENCE_DOWNLOAD_BUTTON';
const KNOWLEDGE_ADD_BUTTON = 'KNOWLEDGE_ADD_BUTTON';
const KNOWLEDGE_EDIT_SELECT_BUSINESS_BUTTON = 'KNOWLEDGE_EDIT_SELECT_BUSINESS_BUTTON';
const SHOW_INTERCOM_BUTTON = 'SHOW_INTERCOM_BUTTON';
const SHOW_BUSINESS_MODAL_FILTERS_BUTTON = 'SHOW_BUSINESS_MODAL_FILTERS_BUTTON';
const VERIFICATION_REQUIRED_BUTTON = 'VERIFICATION_REQUIRED_BUTTON';

export type HideableComponentsNames =
    | 'PRESENCE_DOWNLOAD_BUTTON'
    | 'KNOWLEDGE_ADD_BUTTON'
    | 'KNOWLEDGE_EDIT_SELECT_BUSINESS_BUTTON'
    | 'SHOW_INTERCOM_BUTTON'
    | 'SHOW_BUSINESS_MODAL_FILTERS_BUTTON'
    | 'VERIFICATION_REQUIRED_BUTTON';

export const hideableComponentsNames = {
    PRESENCE_DOWNLOAD_BUTTON,
    KNOWLEDGE_ADD_BUTTON,
    KNOWLEDGE_EDIT_SELECT_BUSINESS_BUTTON,
    SHOW_INTERCOM_BUTTON,
    SHOW_BUSINESS_MODAL_FILTERS_BUTTON,
    VERIFICATION_REQUIRED_BUTTON,
} as Record<HideableComponentsNames, HideableComponentsNames>;

type SetHideableComponentDisplayAction = {
    type: 'SET_HIDEABLE_COMPONENT_DISPLAY';
    componentName: HideableComponentsNames;
    display: boolean;
};

export const setHideableComponentDisplay = (
    componentName: HideableComponentsNames,
    display: boolean,
): SetHideableComponentDisplayAction => ({
    type: 'SET_HIDEABLE_COMPONENT_DISPLAY',
    componentName,
    display,
});

export type HideableComponentsState = {
    PRESENCE_DOWNLOAD_BUTTON: boolean;
    KNOWLEDGE_ADD_BUTTON: boolean;
    KNOWLEDGE_EDIT_SELECT_BUSINESS_BUTTON: boolean;
    SHOW_INTERCOM_BUTTON: boolean;
    SHOW_BUSINESS_MODAL_FILTERS_BUTTON: boolean;
    VERIFICATION_REQUIRED_BUTTON: boolean;
};

const hideableComponentsInitialState = {
    [PRESENCE_DOWNLOAD_BUTTON]: true,
    [KNOWLEDGE_ADD_BUTTON]: true,
    [KNOWLEDGE_EDIT_SELECT_BUSINESS_BUTTON]: true,
    [SHOW_INTERCOM_BUTTON]: true,
    [SHOW_BUSINESS_MODAL_FILTERS_BUTTON]: true,
    [VERIFICATION_REQUIRED_BUTTON]: true,
};

const hideableComponentsReducer = (
    state: HideableComponentsState = hideableComponentsInitialState,
    action: SetHideableComponentDisplayAction,
): HideableComponentsState => {
    if (action.type === SET_HIDEABLE_COMPONENT_DISPLAY)
        return { ...state, [action.componentName]: action.display };
    return state;
};

export const componentDisplaySelector = (
    state: HideableComponentsState,
    componentName: HideableComponentsNames,
): boolean => {
    if (!state) return true;

    if (componentName in state) {
        return state[componentName];
    }

    return true;
};

export default hideableComponentsReducer;
