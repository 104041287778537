import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const Backdrop = styled.div`
    opacity: 1;

    position: absolute;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.3);

    transition: 0.1s opacity;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
`;

export const Modal = styled.div`
    opacity: 1;

    position: absolute;
    top: 64px;
    left: 50%;

    border-radius: 8px;

    width: 60%;
    min-width: 720px;
    max-height: calc(100vh - 128px);

    background: white;

    transform: translateX(-50%);
    transition: 0.1s opacity;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
`;

export const ModalSearch = styled.div`
    display: flex;
    align-items: center;

    border-bottom: 1px solid ${greyColorObject.dark};
    padding: 16px;

    height: 56px;

    input {
        border: 0;
        outline: none;

        line-height: 18px;
        width: 100%;

        color: rgba(0, 0, 0, 0.75);
        font-size: 16px;
        font-weight: 700;
    }
`;

export const SearchIcon = styled.i`
    margin-right: 8px;

    color: rgba(0, 0, 0, 0.75);
    font-size: 18px;
`;

export const ModalContent = styled.div`
    overflow-y: scroll;

    max-height: calc(100vh - 212px);
`;

export const SelectedIcon = styled.div`
    display: none;

    margin-left: 8px;

    fill: rgba(0, 0, 0, 0.5);
`;

export const Action = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 16px;

    height: 40px;

    color: #333333;
    font-size: 16px;
    line-height: 19px;

    &:hover {
        background-color: #f4f3f3;
    }

    &.selected {
        background-color: #f4f3f3;

        ${SelectedIcon} {
            display: block;
        }
    }
`;

export const ActionContent = styled.div`
    display: flex;
    align-items: center;
`;

export const ActionIcon = styled.div`
    margin-right: 8px;

    height: 24px;
    width: 24px;

    fill: rgba(0, 0, 0, 0.5);
`;

export const NoResult = styled.div`
    padding: 8px 16px;

    color: #333333;
    font-size: 16px;
    line-height: 24px;
`;

export const ModalFooter = styled.div`
    display: flex;
    gap: 24px;

    border-top: 1px solid ${greyColorObject.dark};
    padding: 4px 16px;

    height: 28px;
`;

export const FooterElement = styled.div`
    display: flex;
`;

export const FooterIcon = styled.i`
    display: flex;
    align-items: center;
    gap: 8px;

    margin-right: 12px;

    color: #979693;
    font-size: 12px;
`;

export const FooterText = styled.div`
    color: #979693;
    font-size: 12px;
`;
