import { FC } from 'react';

import { HeadManager } from '@partoohub/modular-components';
import { ErrorBoundary } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import PageErrorPlaceholder from 'admin/common/components/errors/PageErrorPlaceholder';
import env from 'admin/common/utils/getEnvironment';


import DemoCreator from './DemoCreator';

const Root: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <HeadManager
                title={t('admin:side_menu_tools__section__demo_creator')}
                env={env.getEnv()}
            />
            <ErrorBoundary
                dataTrackId="demo_creator__page_error"
                FallbackComponent={PageErrorPlaceholder}
            >
                <DemoCreator />
            </ErrorBoundary>
        </>
    );
};

export default Root;
