import React from 'react';

import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { Publisher } from 'admin/api/types';

import onClickFunction from 'admin/content/tools/pages/DiffusionCleaner/hooks/connectAs';
import {
    OpenedModalEnum,
    getQuickConnectModalUri,
} from 'admin/content/tools/pages/DiffusionCleaner/utils/helper';

type Props = {
    provider?: string;
    orgId?: string;
    publisher: Publisher;
};
export const QuickConnect: React.FC<Props> = ({ provider, orgId, publisher }) => {
    const { t } = useTranslation();
    const hasExtension = window.partoo_extension;
    const nextPageUri = getQuickConnectModalUri(publisher, OpenedModalEnum.BUSINESS_CONNECT);

    return (
        <Button
            appearance="outlined"
            shape="rounded"
            size="medium"
            variant="secondary"
            dataTrackId="admin_diffusion_cleaner__quick_connect_business_button"
            onClick={onClickFunction({ orgId, provider, publisher, nextPageUri })}
            disabled={!hasExtension}
            tooltip={
                hasExtension
                    ? undefined
                    : t(
                          'admin:page_diffusion_cleaner__business_quick_connect_button__no_extension_tooltip',
                      )
            }
        >
            {t('admin:page_diffusion_cleaner__business_button__quick_connect')}
        </Button>
    );
};
