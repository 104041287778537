import { FC } from 'react';

import { Switch, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { MyLogSliderWrapper } from 'admin/content/logs/pages/ImportLog/components/ImportLog.styled';

type Props = {
    checked: boolean;
    onClick: () => void;
};

export const MyLogFilter: FC<Props> = ({ checked, onClick }: Props) => {
    const { t } = useTranslation();
    return (
        <MyLogSliderWrapper>
            <Switch
                dataTrackId="import_log__switch"
                name="import_log"
                checked={checked}
                onChange={onClick}
                label={
                    <Text variant="bodySBold" as="span">
                        {t('admin:page_import_log__my_log_filter')}
                    </Text>
                }
                labelPosition="left"
            />
        </MyLogSliderWrapper>
    );
};
