import { BusinessModalErrorType, BusinessModalTranslations } from '@partoohub/modular-components';
import { useTranslation } from 'react-i18next';

import { translations } from '../helpers/translations';

export const useGetTranslations = (): BusinessModalTranslations => {
    const { t } = useTranslation();

    const translateObject = (obj: BusinessModalTranslations): BusinessModalTranslations => ({
        ...obj,
        error: Object.fromEntries(
            Object.entries(obj.error).map(([key, value]) => [key, t(value)]),
        ) as Record<BusinessModalErrorType, string>,
        emptyState: {
            title: t(obj.emptyState.title),
            description: t(obj.emptyState.description),
            reset: t(obj.emptyState.reset),
        },
        ...Object.fromEntries(
            Object.entries(obj)
                .filter(([key]) => key !== 'error' && key !== 'emptyState')
                .map(([key, value]) => [key, t(value as string)]),
        ),
    });

    return translateObject(translations);
};
