import { UseInfiniteQueryOptions, UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';

import { userInfosApiClient } from 'admin/api/apiResources';
import { GetUserInfosParams, UserInfo, UserInfosReceivedData } from 'admin/api/types/userInfos';
import { MULTI_ORGANIZATIONS } from 'admin/common/data/queryKeysConstants';

export const useUserInfos = (
    params: GetUserInfosParams = {},
    options: UseInfiniteQueryOptions<UserInfosReceivedData> = {},
): {
    query: UseInfiniteQueryResult<UserInfosReceivedData>;
    userInfos: Array<UserInfo>;
    count: number | undefined;
} => {
    const query = useInfiniteQuery(
        [MULTI_ORGANIZATIONS, { params }],
        ({ pageParam: page = 1 }) =>
            userInfosApiClient.getUserInfos({
                ...params,
                page,
            }),
        {
            getNextPageParam: prevPage =>
                prevPage.page < prevPage.max_page ? prevPage.page + 1 : undefined,
            ...options,
        },
    );

    const userInfos = query.data?.pages.map(({ user_infos }) => user_infos)?.flat() ?? [];

    return {
        query,
        userInfos,
        count: query.data?.pages?.[0]?.count,
    };
};
