import { useLayoutEffect } from 'react';

import { getQueryStringFromObject } from '@partoohub/utils';

import { produce } from 'immer';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { useParseFunction, useStringifyFunction } from 'app/common/data/routeIds.parsers';
import { useSearchParam } from 'app/common/hooks/useSearchParam';
import { AppState } from 'app/states/reducers';

import usePrevious from './usePrevious';

type Selector<P> = [(state: AppState) => P, string];

/**
 * @name useGetParamReduxSync
 * Utility hook that performs a bidirectional synchronization between a Redux state and a url
 * get parameter. This is mainly a big hack, and used only to have the filters in urls feature
 * without having to refactor everything out of Redux.
 *
 * Regarding selectors, we use an alternative form [parentSelector, name]. This allows to
 * perform immutable updates on the store since we can grab a reference on the parent.
 *
 * Consider the classic selector form :
 * ```
 * state => state.reviews.filters.rating,
 * ```
 * this would translate in this alternative form to:
 * ```
 * [state => state.reviews.filters, 'rating']
 * ```
 *
 * @param getParamName
 * The name of the get parameter, as shown in the url.
 * @param selector
 * A selector in the form of [parentSelector, name] to identify the Redux state to synchronize.
 * @param urlToStore
 * Additional number of selectors of the form [parentSelector, name], that identify any additional
 * Redux states that must be set equal to the matching url get parameter when the url parameter
 * changes, but that don't perform any change in the url when the Redux state is changed.
 * This is mainly useful for "pending" filters that need to be set equal to the get param value
 * on initial page load, without impacting the get param when changed during the edition of a
 * filter.
 */
export const useGetParamReduxSync = <T, P = any>(
    getParamName: string,
    selector: Selector<P> | undefined,
    ...urlToStore: Array<Selector<P>>
) => {
    const parse = useParseFunction(getParamName);
    const stringify = useStringifyFunction(getParamName);

    // url related hooks
    const navigate = useNavigate();
    const paramValue = useSearchParam(getParamName);
    const prevParamValue = usePrevious(paramValue);

    // store related hooks
    const dispatch = useDispatch();
    const parentSelector = selector?.[0] || (() => undefined);
    const name = selector?.[1] || undefined;
    const storeValue = useSelector(parentSelector)?.[name!];
    const prevStoreValue = usePrevious(storeValue);

    // We combine the sync of store to url, and url to store in a single useLayoutEffect
    // to make sure there is only one of the two happening in a single render phase.
    useLayoutEffect(() => {
        const storeValueStr = storeValue !== undefined ? stringify?.(storeValue) : undefined;

        const actionsToDispatch: Array<SetAnyStateAction<T, P>> = [];

        // if the param value has changed, update relevant parts of the store
        if (paramValue !== prevParamValue) {
            // syncing extra urlToStore states
            for (const [parentSelector, name] of urlToStore) {
                actionsToDispatch.push({
                    type: SET_ANY_STATE,
                    parentSelector,
                    name,
                    value: parse(paramValue),
                });
            }

            // syncing main store state, only if it's different from the param value
            if (selector !== undefined && paramValue !== stringify?.(storeValue)) {
                const [parentSelector, name] = selector;
                actionsToDispatch.push({
                    type: SET_ANY_STATE,
                    parentSelector,
                    name,
                    value: parse(paramValue),
                });
            }

            if (actionsToDispatch.length) {
                batch(() => {
                    actionsToDispatch.forEach(dispatch);
                });
            }
        }
        // if the store value has changed and is different from url, update the url
        else if (
            selector !== undefined &&
            storeValue !== prevStoreValue &&
            storeValueStr !== paramValue
        ) {
            navigate(
                {
                    search: getQueryStringFromObject({ [getParamName]: storeValueStr }),
                },
                { replace: true },
            );
        }
    }, [storeValue, paramValue]);
};

export const SET_ANY_STATE = 'SET_ANY_STATE';

interface SetAnyStateAction<T, P> {
    type: typeof SET_ANY_STATE;
    parentSelector: (state: AppState) => P;
    name: string;
    value: T;
}

export const setAnyStateReducer = produce<AppState, [SetAnyStateAction<any, any>]>(
    (state, action) => {
        action.parentSelector(state)[action.name] = action.value;
    },
);
