import { KeyboardEvent, useState } from 'react';

import { Filters, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import AccessGroupModal from 'app/common/components/AccessGroupModal/AccessGroupModal';

import { KeyCode } from 'admin/common/types/keyboard';

import { GroupSelectButton } from './GroupSelect.styled';

type Props = {
    value: Record<string, boolean>;
    onChange: (value: Array<number>) => void;
    orgId?: number;
};

const GroupSelect = ({ value, onChange, orgId }: Props) => {
    // value is just an object of group IDs mapped to "true" (basically a set)
    const [opened, setOpened] = useState<boolean>(false);
    const groups = [];
    for (const group in value) {
        if (value[group]) {
            groups.push(parseInt(group, 10));
        }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (orgId && e.key === KeyCode.Enter) {
            setOpened(true);
        }
        if (orgId && e.key === KeyCode.Escape) {
            setOpened(false);
        }
    };

    return (
        <>
            {/* This <Filters> is a "fake" component acting as a button to open a modal
                It is a <Filters> instead of a <Button> just to match the rest of the page
                design while keeping the logic custom. */}
            <GroupSelectButton
                onClick={() => {
                    if (orgId) setOpened(true);
                }}
                onKeyDown={handleKeyDown}
                tabIndex={0}
            >
                <Filters
                    dataTrackId="admin_diffusion_cleaner__filter_group"
                    selectedItems={value}
                    placeholder={'Groups'}
                    position="bottom"
                    maxHeight={300}
                    menuListArray={[]}
                    onChange={() => onChange([])}
                    icon={[FontAwesomeIconsPartooUsed.faFolder, IconPrefix.SOLID]}
                    disabled={orgId === undefined}
                    multiple
                />
            </GroupSelectButton>
            <AccessGroupModal
                opened={opened}
                closeModal={() => setOpened(false)}
                value={groups}
                onChange={onChange}
                orgId={orgId}
            />
        </>
    );
};

export default GroupSelect;
