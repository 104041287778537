import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
    BusinessModalFilters,
    Filter,
    businessModalFiltersInitialState,
} from 'app/common/components/businessModal/context/businessModalFiltersReducer';
import { filterByBusinessSelector } from 'app/common/components/businessModalDeprecated/reducers';

export default function useReduxStateToLocalState(): BusinessModalFilters {
    const { filteredBusinesses } = useSelector(filterByBusinessSelector);

    const filters = [
        ...filteredBusinesses.cities.map(
            city => ['city', { id: city, label: city }] as Filter,
        ),

        // We don't need the group name below as we're not showing pills in
        // the search box when reloading the page. Also, when we come from
        // url, we loose track of which id is a subgroup, which is a group,
        // but anyway we're using this data only to know which business is
        // selected.
        ...filteredBusinesses.groupIdIn.map(
            groupId =>
                [
                    'group',
                    {
                        id: [groupId.toString()],
                        label: `Group ${groupId.toString()}`,
                    },
                ] as Filter,
        ),

        ...filteredBusinesses.orgs.map(
            orgId =>
                [
                    'org',
                    { id: orgId, label: `Org ${orgId.toString()}` },
                ] as Filter,
        ),
    ];

    return useMemo(
        () => ({
            ...businessModalFiltersInitialState,
            selection: {
                // Redux state.queries is a list but contains at most one single query
                query: filteredBusinesses.queries[0] || '',

                selectAll: filteredBusinesses.mode === 'unselect',
                selectedIds: filteredBusinesses.includedIds,
                unselectedIds: filteredBusinesses.excludedIds,
                filters,
            },
            filters,
        }),
        [filteredBusinesses],
    );
}
