import { generateQueryParamsString } from 'app/api/v2/api_calls/utils';

import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

import ApiResourceBaseClass from './baseApiCalls';
import {
    PublishersStateStatsResponse,
    PublishersStatesBusinessInfoQueryParams,
    PublishersStatesBusinessesInfoData,
    PublishersStatesBusinessesInfoQueryParams,
} from '../../types/publisher_state';

const RESOURCE_URI = 'publisher_states';

class PublisherState extends ApiResourceBaseClass {
    static getPublishersStates(
        queryParams: BusinessModalFiltersType,
    ): Promise<PublishersStateStatsResponse> {
        return PublisherState.get(
            `${RESOURCE_URI}/search?${generateQueryParamsString(queryParams)}`,
        ).then(({ data }) => data);
    }

    static getPublishersStatesBusinessesInfo(
        queryParams: PublishersStatesBusinessesInfoQueryParams,
    ): Promise<PublishersStatesBusinessesInfoData> {
        return PublisherState.get(
            `${RESOURCE_URI}/businesses_info?${generateQueryParamsString(
                queryParams,
            )}`,
        ).then(({ data }) => data);
    }

    static getPublishersStatesBusinessInfo(
        queryParams: PublishersStatesBusinessInfoQueryParams,
    ): Promise<PublishersStatesBusinessesInfoData> {
        return PublisherState.get(
            `${RESOURCE_URI}/business_info?${generateQueryParamsString(
                queryParams,
            )}`,
        ).then(({ data }) => data);
    }

    static postPublisherStatesExport(
        email: string,
    ): Promise<Record<string, any>> {
        return PublisherState.post(`${RESOURCE_URI}/export`, {
            email,
        }).then(({ data }) => data);
    }
}

export default PublisherState;
