import { FC, useEffect, useState } from 'react';

import { List, Option, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { FuzzySearchUsersReceivedData, RoleValue, V2UserData } from 'app/api/types/user';
import { AdminActionsApiCall } from 'app/api/v2/api_calls/adminActionsApiCalls';
import User from 'app/api/v2/api_calls/userApiCalls';

import { LargeFilter } from 'admin/common/components/inputs/NewSelect.styled';

import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { ProductSelect } from 'admin/common/components/inputs/ProductSelect';
import { NewProviderSelect } from 'admin/common/components/inputs/ProviderSelect/NewProviderSelect';
import { NewRoleSelect } from 'admin/common/components/inputs/RoleSelect/NewRoleSelect';
import UserStatusSelect from 'admin/common/components/inputs/UserStatusSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';

import { getSelectedOptions } from 'admin/common/utils/selectOptions';

import ActionSelect, { PossibleActionsEnum } from './ActionSelect';
import { SelectBar } from './SelectBar';
import { PaginationLayout } from './UserActions.styled';
import UserRow from './UserRow';

export const UserActions: FC = () => {
    const { t } = useTranslation();
    const [provider, setProvider] = useState<Option | undefined>(undefined);
    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [role, setRole] = useState<Record<string, boolean>>({});
    const [status, setStatus] = useState<Record<string, boolean>>({});
    const [action, setAction] = useState<Record<string, boolean>>({});
    const [products, setProducts] = useState<Record<string, boolean>>({});
    const [migrationOrg, setMigrationOrg] = useState<Option | undefined>(undefined);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [users, setUsers] = useState<Array<V2UserData> | null>(null);
    const orgId = org?.value;

    const resetFilters = (new_provider?: Option) => {
        setProvider(new_provider);
        setOrg(undefined);
        setRole({});
        setStatus({});
        setAction({});
        setProducts({});
        setMigrationOrg(undefined);
        setPage(0);
        setPageCount(0);
    };

    const handleOrgChange = (newOrg?: Option) => {
        setOrg(newOrg);
    };

    const selectedRole = getSelectedOptions(role)[0];
    const selectedStatus = getSelectedOptions(status)[0];
    const selectedAction = getSelectedOptions(action)[0];
    const selectedProducts = getSelectedOptions(products).toString();

    const needSelectedProduct =
        selectedAction == PossibleActionsEnum.DISPLAY_PRODUCTS ||
        selectedAction == PossibleActionsEnum.HIDE_PRODUCTS;
    const needMigrationOrg = selectedAction == PossibleActionsEnum.MIGRATE_ORG;
    const enableButton =
        selectedAction !== undefined &&
        (!needSelectedProduct || selectedProducts !== '') &&
        (!needMigrationOrg || migrationOrg?.value);

    const { mutateAsync } = useMutation(AdminActionsApiCall.userActions, {
        onSuccess: () => {
            toast.success(
                t('admin:page_user_actions__associtate-to-org-businesses__success_toast__content'),
                t('admin:page_user_actions__associate-to-org-businesses__success_toast__title'),
            );
        },
        onError: () => {
            toast.error('', t('admin:toast_error__title'));
        },
    });

    const submitForm = async (selectedItems: string) => {
        await mutateAsync({
            user_ids: selectedItems,
            action_type: selectedAction,
            products: selectedProducts,
            migration_org: migrationOrg?.value ?? '',
        });
    };

    const searchParams = {
        org_id: orgId ? parseInt(orgId, 10) : undefined,
        provider: provider || undefined,
        status: selectedStatus ? (selectedStatus as 'invited' | 'active' | null) : undefined,
        role: selectedRole ? (selectedRole as RoleValue) : undefined,
    };

    const getUser = useMutation(
        () =>
            User.searchUsers({
                ...(searchParams || {}),
                page: page,
            }),
        {
            onSuccess: (data: FuzzySearchUsersReceivedData) => {
                if (data) {
                    setUsers(data.users);
                    setPageCount(data.max_page);
                }
            },
        },
    );

    useEffect(() => {
        getUser.mutate();
    }, [org, provider, role, status, page]);

    return (
        <AdminPage
            dataTrackId="user_actions__page"
            title={t('admin:page_user_action__title')}
            description={t('admin:page_user_action__description')}
            infoLink="tbd"
        >
            <BorderedContainer>
                <PageFormRow>
                    <LargeFilter>
                        <NewProviderSelect
                            dataTrackId="admin_user_actions__filter_provider"
                            provider={provider}
                            setProvider={resetFilters}
                        />
                    </LargeFilter>
                    <LargeFilter>
                        <NewOrganizationSelect
                            dataTrackId="admin_user_actions__filter_organization"
                            orgs={org}
                            setOrgs={handleOrgChange}
                            queryParams={{
                                provider: provider?.value,
                            }}
                            placeholder={t('admin:organization')}
                        />
                    </LargeFilter>
                    <NewRoleSelect
                        role={role}
                        setRole={setRole}
                        placeholder={t('admin:role')}
                        dataTrackId="admin_user_actions__filter_role"
                    />
                    <UserStatusSelect
                        status={status}
                        setStatus={setStatus}
                        placeholder={t('admin:status')}
                        dataTrackId="admin_user_actions__filter_status"
                    />
                </PageFormRow>
                <PageFormRow>
                    <ActionSelect
                        action={action}
                        setAction={setAction}
                        placeholder={t('admin:action')}
                        dataTrackId="admin_user_actions__select_action"
                    />
                </PageFormRow>
                {needSelectedProduct && (
                    <PageFormRow>
                        <ProductSelect
                            products={products}
                            setProducts={setProducts}
                            placeholder={t('admin:product-choice')}
                            dataTrackId="user-action-product-choice"
                        />
                    </PageFormRow>
                )}
                {needMigrationOrg && (
                    <LargeFilter>
                        <NewOrganizationSelect
                            dataTrackId="user-action-organization-migration"
                            orgs={migrationOrg}
                            setOrgs={setMigrationOrg}
                            queryParams={{
                                provider: provider?.value,
                            }}
                            placeholder={t('admin:organization-for-migration')}
                        />
                    </LargeFilter>
                )}

                <List
                    selectable
                    columns={[
                        {
                            label: 'First Name',
                            width: '150px',
                        },
                        {
                            label: 'Last Name',
                            width: '150px',
                        },
                        {
                            label: 'Role',
                            width: '150px',
                        },
                        {
                            label: 'Provider',
                            width: '120px',
                        },
                        {
                            label: 'Org Id',
                            width: '100px',
                        },
                        {
                            label: 'Status',
                            width: '100px',
                        },
                        {
                            label: 'Bulk modification',
                            width: '50px',
                        },
                        {},
                    ]}
                >
                    {users && users.map(user => <UserRow user={user} key={user.id}></UserRow>)}
                    {users && (
                        <SelectBar
                            users={users}
                            selectedAction={selectedAction}
                            submitForm={submitForm}
                            enableButton={!!enableButton}
                        />
                    )}
                </List>
                {pageCount > 1 && (
                    <PaginationLayout
                        dataTrackId={'user_actions'}
                        onChange={setPage}
                        page={page}
                        pageCount={pageCount}
                    />
                )}
            </BorderedContainer>
        </AdminPage>
    );
};
