import { UserData } from '@partoohub/api-client';
import { Option } from '@partoohub/ui';

export const convertUserToOption = (user: UserData) => {
    const isDisabled = user.disabled;

    return {
        label: `${user.first_name} ${user.last_name} - ${user.email} - ORG (${user.org_id}) - ${user.role}${isDisabled ? ` (${user.status})` : ''}`,
        value: user.id,
        name: `${user.first_name} ${user.last_name}`,
        disabled: isDisabled,
    } as Option;
};
