import { useState } from 'react';

import {
    Button,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    Option,
    SingleSelect,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { UserTypeEnum } from 'admin/api/types/userInfos';
import useDebounce from 'admin/common/hooks/useDebounce';
import { useUserInfos } from 'admin/content/tools/pages/MultiOrganizations/hooks/useUserInfos';
import { userToOption } from 'admin/content/tools/pages/MultiOrganizations/utils/utils';

import {
    AddNewUserButton,
    SelectNewUserButtons,
    SelectNewUserContainer,
} from './AddUserToMerge.styled';

type Props = {
    onAddUserInfo: (userId: number) => void;
    allUserInfoIds: Array<number>;
};

export const AddUserToMerge = ({ onAddUserInfo, allUserInfoIds }: Props) => {
    const { t } = useTranslation();
    const [newUserInfo, setNewUserInfo] = useState<Option>();
    const [searchValue, setSearchValue] = useState('');
    const [showAddUserSelect, setShowAddUserSelect] = useState(false);
    const debouncedSearchValue = useDebounce(searchValue);

    const { query, userInfos } = useUserInfos({
        query: debouncedSearchValue,
        has_users: true,
        id__nin: allUserInfoIds.join(','),
        user_type: UserTypeEnum.HUMAN,
    });

    const { fetchNextPage, hasNextPage } = query;
    const options = userInfos.map(userToOption);

    const handleCancel = () => {
        setShowAddUserSelect(false);
        setNewUserInfo(undefined);
    };

    const handleAdd = () => {
        onAddUserInfo(parseInt(newUserInfo?.value ?? '', 10));
        setShowAddUserSelect(false);
        setNewUserInfo(undefined);
    };

    return (
        <>
            {!showAddUserSelect && (
                <AddNewUserButton
                    onClick={() => setShowAddUserSelect(true)}
                    icon={<Icon icon={[FontAwesomeIconsPartooUsed.faAdd, IconPrefix.SOLID]} />}
                    appearance="outlined"
                    variant="secondary"
                    full
                    dataTrackId="add_user_to_merge"
                >
                    {t('admin:org_switcher_merge_page_add_account')}
                </AddNewUserButton>
            )}

            {showAddUserSelect && (
                <SelectNewUserContainer>
                    <SingleSelect
                        dataTrackId="main_user_info_select"
                        selectedValue={newUserInfo}
                        onChange={setNewUserInfo}
                        sections={[
                            {
                                options: options,
                            },
                        ]}
                        label={t('admin:org_switcher_new_user_info_dropdown')}
                        required
                        loadMore={fetchNextPage}
                        hasMore={hasNextPage}
                        onSearch={setSearchValue}
                    />
                    <SelectNewUserButtons>
                        <Button
                            dataTrackId="add_user_to_merge__cancel"
                            variant="secondary"
                            size="medium"
                            onClick={handleCancel}
                            appearance="outlined"
                            key="cancel"
                        >
                            {t('admin:org_switcher_merge_page_add_account_cancel')}
                        </Button>
                        <Button
                            dataTrackId="add_user_to_merge__confirm"
                            variant="primary"
                            size="medium"
                            onClick={handleAdd}
                            key="delete"
                            disabled={!newUserInfo}
                        >
                            {t('admin:org_switcher_merge_page_add_account_add')}
                        </Button>
                    </SelectNewUserButtons>
                </SelectNewUserContainer>
            )}
        </>
    );
};
