import { Illustration, IllustrationElement } from '@partoohub/iconography';
import { ButtonAppearance, IconElement, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { PARTOOROBOT_404_NOT_FOUND } from 'app/constants/robot';

import { NotFoundTemplateContainer, StyledButton } from './NotFoundTemplate.styled';

type Props = {
    handleClick?: () => void;
    imgSrc: IllustrationElement;
    show: boolean;
    // Optional props
    buttonStatus?: ButtonAppearance;
    title?: string | null;
    subtitle?: string | null;
    subsubtitle?: string;
    buttonText?: string | null;
    withButton?: boolean;
    icon?: IconElement;
};

const NotFoundTemplate = ({
    show,
    imgSrc,
    buttonStatus = 'contained',
    handleClick,
    title,
    subtitle,
    subsubtitle,
    buttonText,
    withButton = true,
    icon,
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const img = (
        <Illustration
            dataTrackId={`not_found__img__${title}`}
            illustration={imgSrc}
            width={'430'}
            height={'188'}
        />
    );
    return (
        <NotFoundTemplateContainer
            direction="row"
            alignItems="center"
            justifyContent="center"
            data-partoorobot={PARTOOROBOT_404_NOT_FOUND}
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{ textAlign: 'center', maxWidth: '480px' }}
            >
                {img}
                {title && (
                    <Text variant="heading5" as="span">
                        {t(title)}
                    </Text>
                )}
                {show && subtitle && (
                    <Text variant="bodyMRegular" as="span" color="secondary">
                        {t(subtitle)}
                    </Text>
                )}
                {show && subsubtitle && (
                    <Text variant="bodyMRegular" as="span" color="secondary">
                        {t(subsubtitle)}
                    </Text>
                )}
                {show && withButton && (
                    <StyledButton
                        dataTrackId="not_found__button"
                        onClick={handleClick}
                        variant="primary"
                        shape="cube"
                        appearance={buttonStatus}
                        icon={icon}
                    >
                        {t(buttonText ?? '')}
                    </StyledButton>
                )}
            </Stack>
        </NotFoundTemplateContainer>
    );
};

export default NotFoundTemplate;
