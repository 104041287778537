import { useMemo, useState } from 'react';

import { FontAwesomeIconsPartooUsed, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import OrgsSection from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/BusinessesContent/SearchFilters/Sections/OrgsSection';
import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import {
    BUSINESSES_MANUAL_SELECTION_LIMIT,
    Filter,
} from 'app/common/components/businessModal/context/businessModalFiltersReducer';
import useSelectedOrgBusinessModal from 'app/common/components/businessModal/hooks/privateHooks/useSelectedOrgBusinessModal';
import { ADMIN } from 'app/common/data/roles';
import {
    Menu,
    SearchBox,
} from 'app/common/designSystem/components/molecules/AsyncSubMenuMultipleSelect';

import { useFilteredCities } from 'app/common/hooks/queries/useCities';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useOrgs } from 'app/common/hooks/queries/useOrgs';
import { useClickOutside } from 'app/common/hooks/useClickOutside';
import useDebounce from 'app/common/hooks/useDebounce';
import { useEventListener } from 'app/common/hooks/useEventListener';

import { ErrorMessage, SearchFiltersContainer } from './SearchFilters.styled';
import CitiesSection from './Sections/CitiesSection';

const SearchFilters = () => {
    const { t } = useTranslation();

    const { query, setQuery, filters, addFilter, removeFilter, selection, noBusinessLimit } =
        useBusinessModalContext();

    const [filterSearch, setFilterSearch] = useState('');
    const [opened, setOpened] = useState(false);

    const debouncedFilterSearch = useDebounce(filterSearch);

    const [searchBoxRef, setSearchBoxRef] = useState<HTMLDivElement | null>(null);
    const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

    const addFilterAndCloseMenu = (filter: Filter) => {
        addFilter(filter);
        setFilterSearch('');
        setOpened(false);
    };

    const placeholderAside =
        !!filters.filter(([filterType]) => filterType !== 'group').length ||
        opened ||
        filterSearch !== '';

    useEventListener(inputRef, 'focus', () => setOpened(true));

    useClickOutside([menuRef, inputRef], () => {
        setFilterSearch('');
        setOpened(false);
    });

    const meRole = useMe().data?.role;

    const { searchOrgId } = useSelectedOrgBusinessModal();

    const [orgsQuery, orgs] = useOrgs(
        { query: debouncedFilterSearch },
        { enabled: meRole === ADMIN && !searchOrgId },
    );
    const [, cities] = useFilteredCities({ search: debouncedFilterSearch });

    // debounce so that we don't show/unshow sections too fast (flickery otherwise)
    const showSections = useDebounce(
        useMemo(
            () => ({
                orgs: orgs?.length !== 0 && meRole === ADMIN && !searchOrgId,
                cities: cities?.length !== 0,
            }),
            [cities, meRole],
        ),
        100,
    );

    const handleChange = (value: string) => {
        setQuery(value ?? '');
    };

    return (
        <SearchFiltersContainer>
            <TextInput
                dataTrackId="business_modal__query_search__textfield"
                hasOldTextFieldHeight
                leftElementType={LeftElementType.Icon}
                leftElement={[FontAwesomeIconsPartooUsed.faSearch]}
                placeholder={t('business_drawer_search')}
                value={query}
                onChange={handleChange}
            />

            <SearchBox focused={opened} ref={setSearchBoxRef}>
                <SearchBox.Input
                    value={filterSearch}
                    ref={setInputRef}
                    onChange={event => setFilterSearch(event.target.value)}
                    cursor={opened ? 'text' : 'pointer'}
                />
                <SearchBox.Placeholder aside={placeholderAside} focused={opened}>
                    {t('business_drawer_filters')}
                </SearchBox.Placeholder>
                <SearchBox.Icon />
                {!opened &&
                    filters
                        .filter(([filterType]) => filterType !== 'group')
                        .map(([filterType, value]) => (
                            <SearchBox.Pill
                                key={`${filterType}-${value.id}`}
                                onClickCross={() => removeFilter([filterType, value] as Filter)}
                                onClick={() => inputRef?.focus()}
                            >
                                {value.label}
                            </SearchBox.Pill>
                        ))}
            </SearchBox>

            {opened && (
                <Menu
                    ref={setMenuRef}
                    referenceElement={searchBoxRef}
                    id="scrollable-business-menu"
                    onClick={() => inputRef?.focus()}
                >
                    {showSections.cities && (
                        <CitiesSection
                            items={cities ?? []}
                            onClickItem={(event, city) => {
                                event.stopPropagation(); // don't focus input
                                addFilterAndCloseMenu(['city', { id: city, label: city }]);
                            }}
                        />
                    )}
                    {showSections.orgs && (
                        <OrgsSection
                            query={orgsQuery}
                            items={orgs}
                            onClickItem={(event, org) => {
                                event.stopPropagation(); // don't focus input
                                addFilterAndCloseMenu([
                                    'org',
                                    {
                                        id: org.org_id.toString(),
                                        label: org.name,
                                    },
                                ]);
                            }}
                        />
                    )}
                    {!showSections.cities && !showSections.orgs && (
                        <Menu.Empty>{t('business_drawer_no_result_found')}</Menu.Empty>
                    )}
                </Menu>
            )}

            {!noBusinessLimit &&
                (selection.selectedIds.length >= BUSINESSES_MANUAL_SELECTION_LIMIT ||
                    selection.unselectedIds.length >= BUSINESSES_MANUAL_SELECTION_LIMIT) && (
                    <ErrorMessage>
                        <i className="fa-sharp fa-solid fa-circle-xmark" />
                        {t('business_modal_error_select_business')}
                    </ErrorMessage>
                )}
        </SearchFiltersContainer>
    );
};

export default SearchFilters;
