import { isNull, omitBy } from 'lodash-es';
import queryString from 'query-string';

import { SuccessResponse } from 'app/api/types';
import { GoogleAdminManagementData } from 'app/api/types/googleAdminManagement';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

const RESOURCE_URI = 'google-administrations';

class GoogleAdminManagement extends ApiResourceBaseClass {
    static fetchGoogleAdminRequests(
        page = 1,
        queryFilter: Record<string, any> = {},
    ): Promise<GoogleAdminManagementData> {
        const queryParams = queryString.stringify(omitBy({ ...queryFilter, page }, isNull));

        return GoogleAdminManagement.get(`${RESOURCE_URI}?${queryParams}`).then(({ data }) => data);
    }

    static requestAdmin(googleLocationId: string): Promise<SuccessResponse> {
        return GoogleAdminManagement.post(
            `${RESOURCE_URI}/locations/${googleLocationId}/request`,
        ).then(({ data }) => data);
    }

    static acceptInvitation(
        googleLocationId: string,
        invitationName: string,
    ): Promise<SuccessResponse> {
        return GoogleAdminManagement.post(`${RESOURCE_URI}/locations/${googleLocationId}/accept`, {
            invitation_name: invitationName,
        }).then(({ data }) => data);
    }
}

export default GoogleAdminManagement;
