import { useEffect, useState } from 'react';

import { TextArea } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ConfirmDeletePromptModal, ConfirmUpdatePromptModal } from './ConfirmEditPromptModal';
import { ExpandableEditor } from '../ExpandableEditor/ExpandableEditor';
import { useDeleteFeedbackReplySuggestionPrompt } from '../hooks/useDeleteFeedbackReplySuggestion';
import { useGetFeedbackReplySuggestionPrompt } from '../hooks/useGetFeedbackReplySuggestionPrompt';
import { useUpdateFeedbackReplySuggestionPrompt } from '../hooks/useUpdateFeedbackReplySuggestionPrompt';

type PromptEditorModalType = 'edit' | 'delete';

interface Props {
    orgId: number;
}

export const ReplySuggestionPromptEditor = ({ orgId }: Props) => {
    const { t } = useTranslation();
    const [openedModal, setOpenModal] = useState<PromptEditorModalType | undefined>(undefined);
    const [prompt, setPrompt] = useState('');

    const [savedPrompt] = useGetFeedbackReplySuggestionPrompt(orgId, { enabled: !!orgId });
    const updatePrompt = useUpdateFeedbackReplySuggestionPrompt();
    const deletePrompt = useDeleteFeedbackReplySuggestionPrompt();

    useEffect(() => {
        setPrompt(savedPrompt?.content ?? '');
    }, [savedPrompt]);

    const openModal = (type: PromptEditorModalType) => setOpenModal(type);
    const handleReset = () => setPrompt(savedPrompt?.content ?? '');
    const handleConfirmUpdate = () => updatePrompt({ orgId, content: prompt });
    const handleConfirmDelete = () => deletePrompt({ orgId });

    return (
        <>
            <ConfirmUpdatePromptModal
                opened={openedModal === 'edit'}
                onConfirm={handleConfirmUpdate}
                onClose={() => setOpenModal(undefined)}
            />
            <ConfirmDeletePromptModal
                opened={openedModal === 'delete'}
                onConfirm={handleConfirmDelete}
                onClose={() => setOpenModal(undefined)}
            />
            <ExpandableEditor
                title={t('admin:feedback_management__reply_suggestion_prompt')}
                value={prompt}
                isValid={!!prompt}
                isCustom={savedPrompt?.is_custom}
                onSave={() => openModal('edit')}
                onReset={handleReset}
                onDelete={() => openModal('delete')}
                disabled={!prompt || prompt === savedPrompt?.content}
                fromReplySuggestion
            >
                <TextArea
                    dataTrackId="feedback_management__reply_suggestion_prompt"
                    value={prompt}
                    onChange={setPrompt}
                    maxRows={50}
                />
            </ExpandableEditor>
        </>
    );
};
