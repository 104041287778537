import React from 'react';

import InfiniteScroll from 'react-infinite-scroller';

import useEnabledGroups from 'app/common/components/AccessGroupModal/hooks/useEnabledGroups';
import useIsMatchingAccesses from 'app/common/components/AccessGroupModal/hooks/useIsMatchingAccesses';
import { GroupsContainer } from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/GroupsContent/Groups/Groups.styled';
import GroupSection from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/GroupsContent/Groups/GroupSection';
import useGroupsV2 from 'app/common/hooks/queries/useGroupsV2';

type Props = {
    value: Array<number>;
    onChange: (value: Array<number>) => void;
    orgId?: number;
};

const AccessGroups = ({ value, onChange, orgId }: Props) => {
    const { query, sections } = useGroupsV2({ org_id: orgId });
    const { fetchNextPage, hasNextPage, isFetchingNextPage } = query;

    const isMatchingAccesses = useIsMatchingAccesses(value, sections);
    const enableGroups = useEnabledGroups(value, isMatchingAccesses);

    const selectedGroups = value.map(groupId => groupId.toString());

    const onGroupClick = (id: string, _: string, checked: boolean) => {
        let newValue = [...selectedGroups];
        if (checked) {
            newValue = newValue.filter(groupId => groupId !== id);
        } else {
            newValue.push(id);
        }

        onChange(newValue.map(groupId => Number(groupId)));
    };

    return (
        <GroupsContainer>
            <InfiniteScroll
                hasMore={hasNextPage && !isFetchingNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
            >
                {(sections ?? [])
                    .filter(section => section.groups.length)
                    .map(section => (
                        <GroupSection
                            key={`section-${section.id}`}
                            section={section}
                            onGroupClick={onGroupClick}
                            selectedGroups={selectedGroups}
                            enabledGroups={enableGroups}
                            hideGroupWithoutCount={false}
                        />
                    ))}
            </InfiniteScroll>
        </GroupsContainer>
    );
};

export default AccessGroups;
