import { HeadManager } from '@partoohub/modular-components';
import { ErrorBoundary } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';

import PageErrorPlaceholder from 'admin/common/components/errors/PageErrorPlaceholder';
import env from 'admin/common/utils/getEnvironment';


import BulkServicesCreateForm from './components';
import configureStore from './configureStore';

const store = configureStore() as any;

const Root = () => {
    const { t } = useTranslation();
    return (
        <Provider store={store}>
            <HeadManager title={t('admin:page_bulk_services__title')} env={env.getEnv()} />
            <ErrorBoundary
                dataTrackId="page_bulk_services_error"
                FallbackComponent={PageErrorPlaceholder}
            >
                <BulkServicesCreateForm />
            </ErrorBoundary>
        </Provider>
    );
};

export default Root;
