import { useState } from 'react';

import { BusinessReachEnum } from '@partoohub/api-client';
import {
    Banner,
    ButtonVariants,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    LeftElementType,
    NumberInput,
    Option,
    Switch,
    Text,
    TextInput,
    toast,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useDispatch } from 'react-redux';

import BusinessModalButton from 'app/common/components/businessModal';

import BusinessModal from 'app/common/components/businessModal/components/BusinessModal';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesTitle from 'app/common/components/businessModal/hooks/useSelectedBusinessesTitle';
import { resetBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers';

import { searchDuplicatesApiClient } from 'admin/api/apiResources';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { PlaceTypeSelect } from 'admin/common/components/inputs/PlaceTypeSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { DUPLICATE_BUSINESS_SEARCH } from 'admin/common/data/queryKeysConstants';

const MAXIMAL_RADIUS_SEARCH = 1000;

export const DuplicateBusinessSearch = () => {
    const { t } = useTranslation();

    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [buttonColor, setButtonColor] = useState<ButtonVariants>('primary');
    const [buttonText, setButtonText] = useState<string>(t('Search for duplicates'));
    const [radiusMeters, setRadiusMeters] = useState(150);
    const [placeType, setPlaceType] = useState<Option | undefined>(undefined);
    const [noPartnerCoOnly, setNoPartnerCoOnly] = useState(true);

    const { mutate, isLoading } = useMutation(
        [DUPLICATE_BUSINESS_SEARCH],
        () =>
            searchDuplicatesApiClient.duplicateBusinessSearch({
                business_filters: {
                    business_org_id: org?.value,
                    ...(noPartnerCoOnly ? { connected_to_gmb_location: false } : {}),
                    ...businessModalFilters,
                },
                radius_meters: radiusMeters,
                place_type: placeType ? placeType.value : undefined,
            }),
        {
            onSuccess: () => {
                resetButton();
                toast.success('Your search was started.', t('success'));
            },
            onError: (e: any) => {
                const errorMessage =
                    e.response?.data?.errors?.json?.email ||
                    e.response?.data?.errors?.json?.business ||
                    e.message;
                setButtonColor('danger');
                setButtonText(t('error'));
                toast.error(t(errorMessage), 'Error');
            },
        },
    );

    const resetButton = () => {
        setButtonColor('primary');
        setButtonText(t('Search for duplicates'));
    };

    const handleOrg = (orgChoice?: Option) => {
        setOrg(orgChoice);
        resetButton();
        dispatch(resetBusinessFilter());
    };

    const businessModalFilters = useBusinessModalFilters();

    const onSubmit = () => {
        if (isLoading) {
            return;
        }
        mutate();
        setOrg(undefined);
        setRadiusMeters(150);
        setPlaceType(undefined);
        dispatch(resetBusinessFilter());
    };

    const { title } = useSelectedBusinessesTitle();
    const [businessModalOpen, setBusinessModalOpen] = useState<boolean>(false);

    const dispatch = useDispatch();

    return (
        <AdminPage
            dataTrackId="duplicate_business_search__page"
            title={t('admin:page_tools_clients_duplicates__title')}
            description={t('admin:page_tools_clients_duplicates__description')}
            infoLink="https://partoo.elium.com/tile/view/3161/"
        >
            <div style={{ marginBottom: '16px' }}>
                <Banner
                    dataTrackId="admin_duplicate_businesses__cost_banner"
                    variant="warning"
                    withCloseIcon={false}
                >
                    {t('admin:duplicate_search_cost_warning')}
                </Banner>
            </div>
            <BorderedContainer>
                <PageFormRow>
                    <NewOrganizationSelect
                        dataTrackId="admin_duplicate_businesses__filter_organization"
                        orgs={org}
                        setOrgs={handleOrg}
                        placeholder={t('admin:filter_select_organization')}
                        required
                    />
                    {!noPartnerCoOnly && (
                        <BusinessModalButton
                            withUrlSync={false}
                            noBusinessLimit
                            disabled={!org}
                            // searchFilters={FOOD_MENUS_CREATION_SEARCH_PARAMS}
                            button={
                                // the business modal is not opened without this div
                                <div>
                                    <TextInput
                                        dataTrackId="admin_duplicate_businesses__open_business_selection"
                                        label={t('admin:select_businesses')}
                                        value={title}
                                        disabled={!org}
                                    />
                                </div>
                            }
                        />
                    )}
                    <BusinessModal
                        opened={businessModalOpen}
                        closeModal={() => setBusinessModalOpen(false)}
                        searchFilters={{
                            reach: BusinessReachEnum.LOCAL,
                            org_id:
                                org?.value && typeof parseInt(org?.value, 10) === 'number'
                                    ? parseInt(org.value, 10)
                                    : 0,
                        }}
                    />
                </PageFormRow>
                <PageFormRow>
                    <Switch
                        dataTrackId="admin_duplicate_businesses__partner_co_toggle"
                        label={
                            <Text as="span" variant="bodyMBold" color="secondary">
                                {t('admin:page_tools_clients_duplicates__partner_co_toggle')}
                            </Text>
                        }
                        name="partner_co_toggle"
                        checked={noPartnerCoOnly}
                        onChange={() => setNoPartnerCoOnly(!noPartnerCoOnly)}
                    />
                </PageFormRow>
                <PageFormRow>
                    <NumberInput
                        dataTrackId="admin_duplicate_businesses__input_radius"
                        label={t('admin:page_tools_clients_duplicates__radius_input')}
                        value={radiusMeters}
                        unit="m"
                        onChange={r => setRadiusMeters(typeof r === 'number' ? r : parseInt(r))}
                        leftElementType={LeftElementType.Icon}
                        leftElement={[FontAwesomeIconsPartooUsed.faStreetView, IconPrefix.REGULAR]}
                        max={MAXIMAL_RADIUS_SEARCH}
                        notice={t('admin:page_tools_clients_duplicates__radius_maximum', {
                            max: MAXIMAL_RADIUS_SEARCH,
                        })}
                    />
                    <PlaceTypeSelect selectedValue={placeType} onChange={setPlaceType} />
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin_duplicate_businesses__search_duplicate"
                text={buttonText}
                variant={buttonColor}
                onClick={onSubmit}
                disabled={!org}
            />
        </AdminPage>
    );
};
