import { UseQueryResult, useQuery } from 'react-query';

import { businessApiClient } from 'app/api/v2/api_calls/apiResources';
import { CITIES } from 'app/common/data/queryKeysConstants';

const cityMatchesSearch = (city: string, search: string) =>
    // when search is empty string, all cities match
    search === '' ||
    search
        .split(' ')
        .filter(Boolean)
        .some(token => city.toLowerCase().includes(token.toLowerCase()));

interface Filters {
    search?: string;
}

/*
 * With client side filtering
 */
export const useFilteredCities = (
    filters: Filters = {},
): [UseQueryResult<{ cities: Array<string> }>, Array<string> | undefined] => {
    const [query, cities] = useCities();
    const filteredCities = cities?.filter(
        city =>
            city !== null && // somehow the API can return null cities sometimes
            cityMatchesSearch(city, filters.search || ''),
    );
    return [query, filteredCities];
};

/*
 * Base version
 */
export const useCities = (): [
    UseQueryResult<{ cities: Array<string> }>,
    Array<string> | undefined,
] => {
    const query = useQuery([CITIES, {}], businessApiClient.getAllCities);
    const cities = query.data?.cities;
    return [query, cities];
};
