import React, { useMemo } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DailyReportLog, LogsAdminApiCall } from 'app/api/v2/api_calls/logsApiCalls';

import { DownloadButton } from 'admin/common/components/buttons/DownloadLinkButton/DownloadLinkButton';
import { formatISODate } from 'admin/common/utils/dates';
import { IconContainer, Row } from 'admin/content/logs/common/components/List.styled';
import {
    AppleIcon,
    BuildingIcon,
    BuildingsIcon,
    DatabaseIcon,
    RightToBracketIcon,
    ShopIcon,
} from 'admin/content/logs/pages/DailyReport/assets/assets';
import {
    ArrowWrapper,
    LeftCell,
    MiddleCell,
    RightCell,
} from 'admin/content/logs/pages/DailyReport/components/DailyReport.styled';

export const pullerCategoryMapping = {
    unique_puller_report: {
        translationKey: 'admin:page_daily_report__unique_puller_report',
        icon: RightToBracketIcon,
        fontAwesomeIcon: 'fa-regular fa-right-to-bracket',
    },
    stale_data_report: {
        translationKey: 'admin:page_daily_report__stale_data_report',
        icon: DatabaseIcon,
        fontAwesomeIcon: 'fa-regular fa-database',
    },
    apple_report: {
        translationKey: 'admin:page_daily_report__apple_report',
        icon: AppleIcon,
        fontAwesomeIcon: 'fa-brands fa-apple',
    },
    smb_feed_report: {
        translationKey: 'admin:page_daily_report__smb_feed_report',
        icon: ShopIcon,
        fontAwesomeIcon: 'fa-regular fa-shop',
    },
    gc_feed_report: {
        translationKey: 'admin:page_daily_report__gc_feed_report',
        icon: BuildingIcon,
        fontAwesomeIcon: 'fa-regular fa-building',
    },
    expert_feed_report: {
        translationKey: 'admin:page_daily_report__expert_feed_report',
        icon: BuildingsIcon,
        fontAwesomeIcon: 'fa-regular fa-buildings',
    },
    smb_partner_co_status_issue_report: {
        translationKey: 'admin:page_daily_report__smb_partner_co_status_issue_report',
        icon: ShopIcon,
        fontAwesomeIcon: 'fa-regular fa-shop',
    },
    gc_partner_co_status_issue_report: {
        translationKey: 'admin:page_daily_report__gc_partner_co_status_issue_report',
        icon: BuildingIcon,
        fontAwesomeIcon: 'fa-regular fa-building',
    },
    channel_and_expert_partner_co_status_issue_report: {
        translationKey:
            'admin:page_daily_report__channel_and_expert_partner_co_status_issue_report',
        icon: BuildingsIcon,
        fontAwesomeIcon: 'fa-regular fa-buildings',
    },
};

type Props = {
    log: DailyReportLog;
    closed: boolean;
    toggleClosed: () => void;
};

const translateMapping = (t: (key: string) => string) => {
    return Object.fromEntries(
        Object.entries(pullerCategoryMapping).map(([reportType, { translationKey, icon }]) => {
            const label = t(translationKey);
            return [reportType, { icon, label }];
        }),
    );
};

export const DailyReportRowTop: React.FC<Props> = ({ log, toggleClosed, closed }) => {
    const { t } = useTranslation();
    const translatedMapping = useMemo(() => translateMapping(t), [t]);

    return (
        <Row onClick={toggleClosed}>
            <LeftCell>
                <IconContainer>{translatedMapping[log.category].icon}</IconContainer>
                {translatedMapping[log.category].label}
            </LeftCell>
            <MiddleCell>{formatISODate(log.created_at)}</MiddleCell>
            <RightCell>
                <DownloadButton
                    id={log.id}
                    text={t('admin:page_daily_report__report_file')}
                    variant="primary"
                    icon={[FontAwesomeIconsPartooUsed.faFileArrowDown, IconPrefix.SOLID]}
                    apiCall={LogsAdminApiCall.dailyReportLink}
                    dataTrackId={`admin_daily_report__download_${log.category}`}
                />
                <ArrowWrapper>
                    <i className={`fa fa-angle-${closed ? 'down' : 'up'}`} />
                </ArrowWrapper>
            </RightCell>
        </Row>
    );
};
