import React from 'react';

import { useTranslation } from 'react-i18next';

import Businesses from './Businesses';
import SearchFilters from './SearchFilters';
import Header from '../Header';

const BusinessesContent = () => {
    const { t } = useTranslation();

    return (
        <>
            <Header title={t('business_drawer_select_locations')} />
            <SearchFilters />
            <Businesses />
        </>
    );
};

export default BusinessesContent;
