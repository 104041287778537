import { ConversationMessage } from 'app/states/messaging';

export const generateDelightMessages = (): Array<ConversationMessage> => {
    return [
        {
            id: 3,
            status: 'Success',
            content: 'messaging_conversation_delight_message_04_welcome',
            content_type: 'Text',
            date: '2023-06-07 15:54:05.042699+00:00',
            is_deleted: false,
            is_automated: false,
            sender_type: 'Consumer',
            sender_label: 'Partoo',
            user_id: 'partoo',
        },
        {
            id: 2,
            status: 'Success',
            content: 'messaging_conversation_delight_message_03_channels',
            content_type: 'Text',
            date: '2023-06-07 15:53:05.042699+00:00',
            is_deleted: false,
            is_automated: false,
            sender_type: 'Consumer',
            sender_label: 'Partoo',
            user_id: 'partoo',
        },
        {
            id: 1,
            status: 'Success',
            content: 'https://media.tenor.com/1GFGoqTI4F0AAAAC/welcome-to-the-team-minions.gif',
            content_type: 'Image',
            date: '2023-06-07 15:52:05.042699+00:00',
            is_deleted: false,
            is_automated: false,
            sender_type: 'Consumer',
            sender_label: 'Partoo',
            user_id: 'partoo',
        },
        {
            id: 0,
            status: 'Success',
            content: 'messaging_conversation_delight_message_01_greeting',
            content_type: 'Text',
            date: '2023-06-07 15:51:05.042699+00:00',
            is_deleted: false,
            is_automated: false,
            sender_type: 'Consumer',
            sender_label: 'Partoo',
            user_id: 'partoo',
        },
    ];
};
