import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, List, Text } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { UserInfo } from 'admin/api/types/userInfos';

import { UserInfosQueryKeys } from 'admin/content/tools/pages/MultiOrganizations/enums';

import { MergedAccountRowWrapper, StyledIconListCell } from './MergedAccountRow.styled';

type Props = {
    userInfo: UserInfo;
};

export const MergedAccountRow = ({ userInfo }: Props) => {
    const [, setUserInfoId] = useStateQueryParams(UserInfosQueryKeys.USER_INFO_ID);

    const handleRowClick = () => {
        setUserInfoId(userInfo.id.toString());
    };

    return (
        <MergedAccountRowWrapper onClick={handleRowClick}>
            <List.Row id={userInfo.id.toString()} dataTrackId="merged_account_row">
                <List.Cell>
                    <Text variant="bodyMSemibold" color="secondary">
                        {userInfo.email || '-'}
                    </Text>
                </List.Cell>
                <List.Cell>
                    <Text variant="bodyMSemibold" color="secondary">
                        {userInfo.user_count}
                    </Text>
                </List.Cell>
                <StyledIconListCell>
                    <Icon
                        dataTrackId={`merged_account_row_details_${userInfo.id}`}
                        icon={[FontAwesomeIconsPartooUsed.faChevronRight, IconPrefix.SOLID]}
                        aria-label="View details"
                        color="secondary"
                    />
                </StyledIconListCell>
            </List.Row>
        </MergedAccountRowWrapper>
    );
};
