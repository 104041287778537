import { useCallback, useEffect, useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { validateHttpsUrl as isValidUrl } from 'admin/common/utils/validators';

import { ExpandableEditor } from '../ExpandableEditor/ExpandableEditor';
import { useCreateOrUpdateFeedbackLookerStudio } from '../hooks/useCreateOrUpdateFeedbackLookerStudio';
import { useGetFeedbackLookerStudio } from '../hooks/useGetFeedbackLookerStudio';

interface Props {
    orgId: number;
}

export const LookerStudioLinkEditor = ({ orgId }: Props) => {
    const { t } = useTranslation();
    const [dashboardLink, setDashboardLink] = useState('');
    const isValid = !dashboardLink || isValidUrl(dashboardLink);
    const lookerStudioLink = useGetFeedbackLookerStudio(orgId, { enabled: !!orgId });
    const { createOrUpdateFeedbackLookerStudio, isCreatingOrUpdating } =
        useCreateOrUpdateFeedbackLookerStudio();

    useEffect(() => {
        setDashboardLink(lookerStudioLink?.looker_studio_dashboard_link ?? '');
    }, [lookerStudioLink]);

    const handleChange = (value: string) => {
        setDashboardLink(value);
    };

    const handleResetLink = () => {
        setDashboardLink(lookerStudioLink?.looker_studio_dashboard_link ?? '');
    };

    const handleSubmitLookerStudio = useCallback(() => {
        if (lookerStudioLink?.org_id) {
            createOrUpdateFeedbackLookerStudio(lookerStudioLink.org_id, {
                looker_studio_dashboard_link: dashboardLink,
            });
        } else {
            createOrUpdateFeedbackLookerStudio(undefined, {
                org_id: orgId,
                looker_studio_dashboard_link: dashboardLink,
            });
        }
    }, [orgId, dashboardLink]);

    return (
        <ExpandableEditor
            title={t('admin:feedback_management__dashboard_link')}
            value={dashboardLink}
            isValid={isValid}
            isSaving={isCreatingOrUpdating}
            onSave={handleSubmitLookerStudio}
            disabled={
                isCreatingOrUpdating ||
                dashboardLink === lookerStudioLink?.looker_studio_dashboard_link ||
                !dashboardLink
            }
            onReset={handleResetLink}
        >
            <TextInput
                dataTrackId="feedback_management__dashboard_link"
                label={t('admin:feedback_management__dashboard_link')}
                leftElementType={LeftElementType.Icon}
                leftElement={[FontAwesomeIconsPartooUsed.faLink, IconPrefix.REGULAR]}
                value={dashboardLink}
                onChange={handleChange}
                name="looker_studio_dashboard_link"
                error={!isValid}
                notice={
                    isValid
                        ? t('admin:feedback_management__dashboard_link_notice')
                        : t('admin:feedback_management__dashboard_link_invalid')
                }
            />
        </ExpandableEditor>
    );
};
