import InfiniteScroll from 'react-infinite-scroller';

import GroupSection from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/GroupsContent/Groups/GroupSection';

import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import {
    GroupFilter,
    getGroupIds,
} from 'app/common/components/businessModal/context/businessModalFiltersReducer';

import useHideGroupsForAdmins from 'app/common/components/businessModal/hooks/privateHooks/useHideGroupsForAdmins';
import useOrgFilteredGroupsV2 from 'app/common/components/businessModal/hooks/privateHooks/useOrgFilteredGroupsV2';

import { GroupsContainer } from './Groups.styled';
import { NoGroupSection } from './GroupSection/NoGroupSection';

const Groups = () => {
    const hideGroups = useHideGroupsForAdmins();

    const { variation, removeFilter, addFilter, filters } = useBusinessModalContext();
    const { query, sections } = useOrgFilteredGroupsV2(
        {},
        { enabled: variation !== 'business-only' },
    );
    const { fetchNextPage, hasNextPage, isFetchingNextPage } = query;

    const toggleGroup = (id: string, label: string, checked: boolean) => {
        const filter: GroupFilter = [
            'group',
            {
                id: [id],
                label,
            },
        ];
        if (checked) {
            removeFilter(filter);
        } else {
            addFilter(filter);
        }
    };
    const selectedGroups = getGroupIds(filters);

    return (
        <GroupsContainer>
            {!hideGroups && (
                <InfiniteScroll
                    hasMore={hasNextPage && !isFetchingNextPage}
                    loadMore={() => fetchNextPage()}
                    useWindow={false}
                >
                    {(sections ?? [])
                        .filter(section => section.groups.some(group => group.business_count > 0))
                        .map(section => (
                            <GroupSection
                                key={`section-${section.id}`}
                                section={section}
                                onGroupClick={toggleGroup}
                                selectedGroups={selectedGroups}
                            />
                        ))}
                    <NoGroupSection onClick={toggleGroup} selectedGroups={selectedGroups} />
                </InfiniteScroll>
            )}
        </GroupsContainer>
    );
};

export default Groups;
