import { ApiResourceBaseClass } from '@partoohub/api-client';

import { AccountFetchJobData, SearchGmbAccountsReceivedData } from 'admin/api/types';

const GOOGLE_ACCOUNTS_URI = 'google-accounts';

export class AccountAdminApiCall extends ApiResourceBaseClass {
    async searchAllGoogleAccounts(
        fuzzy_query?: string,
        is_active?: boolean,
        pageParam = 1,
    ): Promise<SearchGmbAccountsReceivedData> {
        const { data } = await this.get<SearchGmbAccountsReceivedData>(GOOGLE_ACCOUNTS_URI, {
            fuzzy_query,
            is_active,
            page: pageParam,
        });
        return data;
    }

    async refreshGoogleAccount(
        account_id: string,
        title: string,
        store_code: string,
        locality: string,
        postal_code: string,
    ): Promise<AccountFetchJobData> {
        const { data } = await this.post<AccountFetchJobData>(
            `${GOOGLE_ACCOUNTS_URI}/${account_id}/refresh`,
            { title, store_code, locality, postal_code },
        );
        return data;
    }
}
