import 'regenerator-runtime/runtime';
import { createStore } from 'redux';

import reducers from 'admin/common/reducers';

const configureStore = () => {
    return createStore(reducers());
};

export default configureStore;
