import { Chip, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

interface Props {
    isCustomPrompt: boolean | undefined;
}

export const CustomReplySuggestionPromptChip = ({ isCustomPrompt }: Props) => {
    const { t } = useTranslation();

    if (isCustomPrompt === undefined) {
        return null;
    }

    return isCustomPrompt ? (
        <Chip dataTrackId="" chipShape="cube" variant="primary">
            {t('admin:feedback_management__custom_prompt')}
        </Chip>
    ) : (
        <Chip dataTrackId="" chipShape="cube" variant="secondary">
            <Text as="span" variant="bodySBold">
                {t('admin:feedback_management__generic_prompt')}
            </Text>
        </Chip>
    );
};
