import { FC } from 'react';

import { Global, ThemeProvider } from '@emotion/react';
import { Snackbar, theme } from '@partoohub/ui';

import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router';

import './../main.css';
import './../loader.css';

import { global } from 'admin/global.styled';

import App from './App';
import { IsSideMenuOpenProvider } from './common/contexts/isSideMenuOpenContext';
import { WebSocketContextProvider } from './common/contexts/websocketContext';

/** Entry point of Admin App.
 *
 * <App /> is memoized so all context providers (redux's StoreProvider,
 * react-query's QueryClientProvider, etc.) should be added here to avoid
 * rendering the whole app when a value changes.
 */

export const queryClient = new QueryClient({
    /**
     *  Global queries options.
     *  They can be overridden on a pre-query basis.
     */
    defaultOptions: {
        queries: {
            /** @name staleTime
             * We put the staleTime to Infinity because we don't want
             * any automatic refresh happening in the background to avoid
             * over-fetching.
             */
            staleTime: Infinity,

            refetchInterval: false,
            retry: false,

            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});

const Spa: FC = () => (
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <Global styles={global} />
            <ThemeProvider theme={theme}>
                <IsSideMenuOpenProvider>
                    <WebSocketContextProvider>
                        <App />
                        <Snackbar dataTrackId="snackbar__component" />
                    </WebSocketContextProvider>
                </IsSideMenuOpenProvider>
            </ThemeProvider>
        </BrowserRouter>
    </QueryClientProvider>
);

const app = document.getElementById('root');

const root = createRoot(app!);

root.render(<Spa />);
