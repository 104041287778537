import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';

export const ColumnHeadingsContainer = styled.div`
    display: flex;
    width: 100%;
    background: ${convertToRgba(HEX_COLORS.blackberry, 0.12)};
    border-radius: 4px;
    padding: 6px 20px;
    margin-bottom: 16px;
    gap: 16px;
`;

export const ColumnHeadingText = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    gap: 6px;
    color: ${({ theme }) => theme.colors.secondary.initial};

    .rotate_icon {
        transform: rotate(180deg);
    }
`;

export const ColumnHeadingTitle = styled.span`
    text-transform: uppercase;
    letter-spacing: 2px;
`;
