import React from 'react';

import { Filters } from '@partoohub/ui';

type Props = {
    publishers?: Record<string, boolean>;
    setPublishers: (selectedItems: Record<string, boolean>) => void;
    disabled?: boolean;
};

const publishersOptions = [
    {
        value: 'google_my_business',
        name: 'google_my_business',
        label: 'Google',
    },
    {
        value: 'facebook',
        name: 'facebook',
        label: 'Facebook',
    },
    {
        value: 'le118000',
        name: 'le118000',
        label: '118000',
    },
    {
        value: 'justacote',
        name: 'justacote',
        label: 'Justacoté',
    },
    {
        value: 'hoodspot',
        name: 'hoodspot',
        label: 'Hoodspot',
    },
    {
        value: 'bing_global',
        name: 'bing_global',
        label: 'Bing Worldwide',
    },
    {
        value: 'apple_business_connect',
        name: 'apple_business_connect',
        label: 'Apple',
    },
];

const section = [{ options: publishersOptions }];

export const DiffusionCleanerPublisherSelect: React.FC<Props> = ({
    publishers,
    setPublishers,
    disabled,
}: Props) => {
    return (
        <Filters
            dataTrackId="admin_diffusion_cleaner__filter_publishers"
            menuListArray={section}
            selectedItems={publishers}
            onChange={setPublishers}
            placeholder={'Publishers'}
            position="bottom"
            maxHeight={300}
            multiple={true}
            disabled={disabled}
        />
    );
};
