import {
    Chip,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    Stack,
    Text,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type Props = {
    count: number;
    onClick: () => void;
};

const NotificationButton = ({ count, onClick }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            {count > 0 && (
                <Chip dataTrackId="notification_button" onClick={onClick}>
                    <Stack
                        gap="8px"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Icon
                            icon={[
                                FontAwesomeIconsPartooUsed.faArrowUp,
                                IconPrefix.SOLID,
                            ]}
                            color="primary"
                        />
                        <Text
                            variant="bodySBold"
                            as="span"
                            color="primary"
                        >{`${count} ${t(
                            count === 1 ? 'new_log' : 'new_logs',
                        )}`}</Text>
                    </Stack>
                </Chip>
            )}
        </>
    );
};

export default NotificationButton;
