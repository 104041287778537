import { SsoInfoResponse, SuccessResponse } from '@partoohub/api-client';

import { BusinessFieldsType } from 'app/api/types/business';
import {
    OrgLanguagesRequest,
    OrgLanguagesResponse,
    OrganizationDomainsGetDataType,
    SidebarPages,
} from 'app/api/types/org';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'org';

class Organization extends ApiResourceBaseClass {
    static getBusinessEditFields(orgId: number): Promise<BusinessFieldsType> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/business_fields`).then(
            ({ data }) => data,
        );
    }

    static updateBusinessEditFields(
        orgId: number,
        businessFields: BusinessFieldsType,
    ): Promise<SuccessResponse> {
        return Organization.post(`${RESOURCE_URI}/${orgId}/business_fields`, businessFields).then(
            ({ data }) => data,
        );
    }

    static getSsoInfo(orgId: number): Promise<SsoInfoResponse> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/sso_info`).then(({ data }) => data);
    }

    static getBusinessesLanguages(
        orgId: number,
        params: OrgLanguagesRequest = {},
    ): Promise<OrgLanguagesResponse> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/languages`, params).then(
            ({ data }) => data,
        );
    }

    static getSidebarPages(orgId: number): Promise<SidebarPages> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/sidebar_pages`).then(({ data }) => data);
    }

    static checkBusinessStoreCode(
        orgId: number,
        storeCodes: string[],
    ): Promise<{ existing_store_codes: string[] }> {
        return Organization.post(`${RESOURCE_URI}/${orgId}/store-codes`, {
            store_codes: storeCodes,
        }).then(({ data }) => data);
    }

    static getBookingLinksConfig(orgId: number): Promise<OrganizationDomainsGetDataType> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/booking-links/configuration`).then(
            ({ data }) => data,
        );
    }
}

export default Organization;
