import { ReactNode } from 'react';

import { Stack, Text } from '@partoohub/ui';

import { CountText } from './ExplanationWithCount.styled';

interface Props {
    explanation?: ReactNode;
    characterCount?: number;
    // Optional props
    disabled?: boolean;
    value?: string;
    hasError?: boolean;
}

export const ExplanationWithCount = ({
    explanation,
    value = undefined,
    hasError = undefined,
    disabled = false,
    characterCount,
}: Props) => {
    const errorMaxCharacters = characterCount ? !!(value && value.length > characterCount) : false;

    const hasErrors = hasError || errorMaxCharacters;
    return (
        <Stack justifyContent="space-between" direction="row">
            <Text
                variant="bodySRegular"
                color={hasErrors ? 'danger' : 'secondary'}
                disabled={disabled}
            >
                {explanation}
            </Text>
            <CountText
                variant="bodySRegular"
                color={hasErrors ? 'danger' : 'secondary'}
                disabled={disabled}
            >
                {`${value ? value.length : 0} / ${characterCount}`}
            </CountText>
        </Stack>
    );
};
