import { FilteredBusinessData } from 'app/api/types/business';
import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import {
    getCities,
    getGroupIds,
    getOrgIds,
} from 'app/common/components/businessModal/context/businessModalFiltersReducer';

export default function useLocalSelectionToReduxState(): Partial<FilteredBusinessData> {
    const { selection } = useBusinessModalContext();
    const cities = getCities(selection.filters);
    const groupIds = getGroupIds(selection.filters);
    const orgIds = getOrgIds(selection.filters);

    return {
        mode: selection.selectAll ? 'unselect' : 'select',
        includedIds: selection.selectedIds,
        excludedIds: selection.unselectedIds,
        queries: [selection.query].filter(Boolean),
        cities: selection.selectAll ? cities : [],
        groupIdIn: selection.selectAll
            ? groupIds.map(g => parseInt(g, 10))
            : [],
        orgs: selection.selectAll ? orgIds : [],
    };
}
