import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Link, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    V2FormattedDiffusionErrors,
    V2FormattedDiffusionPostCheckErrors,
} from 'app/api/types/diffusion';

import { makeDbAdminUrl } from 'admin/common/data/dbAdminHelpers';

import { DiffusionStepLog, DiffusionStepLogFlags } from './DiffusionDetails.styled';
import DiffusionErrors from '../DiffusionErrors';

type Props = {
    state: '' | 'PENDING' | 'SUCCESS' | 'FAILURE';
    flagAfter?: string;
    flagBefore?: string;
    diffusionErrors: Array<V2FormattedDiffusionErrors>;
    callLog?: string;
    logs_human_error_message?: Array<string>;
    postCheckErrors: Array<V2FormattedDiffusionPostCheckErrors>;
};

const color = {
    '': '',
    PENDING: 'primary',
    SUCCESS: 'success',
    FAILURE: 'danger',
};

const stateIcon = {
    '': '',
    PENDING: 'fas fa-sync-alt fa-spin',
    SUCCESS: 'fa fa-check-circle',
    FAILURE: 'fas fa-times-circle',
};

const DiffusionDetails = ({
    state,
    diffusionErrors,
    flagAfter = '',
    flagBefore = '',
    callLog = '',
    logs_human_error_message,
    postCheckErrors,
}: Props) => {
    const { t } = useTranslation();

    const generateCallLogLink = (callLogIds: string) => {
        if (callLogIds.includes(',')) {
            return makeDbAdminUrl(`calllog?sort=0&flt2_id_in_list=${callLogIds}`);
        }

        return makeDbAdminUrl(`calllog/details/?id=${callLogIds}`);
    };

    return (
        <>
            {(!postCheckErrors || postCheckErrors.length === 0) &&
                logs_human_error_message &&
                logs_human_error_message.length > 0 && (
                    <DiffusionStepLog>
                        <div className="top-margin">
                            <ul>
                                {logs_human_error_message.map(call_log_human_error => (
                                    <li key={call_log_human_error}>{call_log_human_error}</li>
                                ))}
                            </ul>
                        </div>
                    </DiffusionStepLog>
                )}

            <DiffusionStepLog>
                <Stack
                    gap="8px"
                    direction="row"
                    alignItems="center"
                    style={{ paddingLeft: '16px' }}
                >
                    <Icon icon={[`${stateIcon[state]}`, IconPrefix.SOLID]} color={color[state]} />
                    <span>
                        {(flagBefore || flagAfter) && (
                            <DiffusionStepLogFlags state={state}>
                                <span>{flagBefore ?? '...'}</span>
                                <Icon
                                    icon={[
                                        FontAwesomeIconsPartooUsed.faArrowRight,
                                        IconPrefix.SOLID,
                                    ]}
                                />
                                <span>{flagAfter ?? '...'}</span>
                            </DiffusionStepLogFlags>
                        )}
                        {diffusionErrors?.map(resource => (
                            <DiffusionErrors key={`ar-${resource}`} diffusionError={resource} />
                        ))}
                        {postCheckErrors?.map(resource => (
                            <DiffusionErrors key={`ar-${resource}`} diffusionError={resource} />
                        ))}
                        {callLog && (
                            <div
                                data-track="admin_diffusion_log__see_call_log"
                                data-intercom-target="admin_diffusion_log__see_call_log"
                            >
                                <Link
                                    href={generateCallLogLink(callLog)}
                                    variant="primary"
                                    target="_blank"
                                    textVariant="bodyMBold"
                                >
                                    {t('see_call_logs')}
                                </Link>
                            </div>
                        )}
                        {!diffusionErrors?.length && !callLog && (
                            <Text variant="bodyMBold">{t('no_call_log')}</Text>
                        )}
                    </span>
                </Stack>
            </DiffusionStepLog>
        </>
    );
};

export default DiffusionDetails;
