import { FC, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleSelect } from 'admin/common/components/inputs/SingleSelect';

export type Subscribed = boolean | undefined;

type Props = {
    dataTrackId: string;
    placeholder: string;
    selectedValue: Subscribed;
    onChange: (value: Subscribed) => void;
};

const adaptSelectedValue = (value: Subscribed): 'both' | 'onlySubscribed' | 'onlyNotSubscribed' => {
    if (value === true) return 'onlySubscribed';
    if (value === false) return 'onlyNotSubscribed';
    return 'both';
};

export const SubscribedInput: FC<Props> = ({
    dataTrackId,
    placeholder,
    selectedValue,
    onChange,
}) => {
    const { t } = useTranslation();

    const options = useMemo(
        () => [
            { value: 'both', name: 'both', label: t('all') },
            { value: 'onlySubscribed', name: 'onlySubscribed', label: t('only_subscribed') },
            {
                value: 'onlyNotSubscribed',
                name: 'onlyNotSubscribed',
                label: t('only_not_subscribed'),
            },
        ],
        [],
    );

    const adaptedOnChange = useCallback((value: string | null): void => {
        if (value === 'onlySubscribed') onChange(true);
        else if (value === 'onlyNotSubscribed') onChange(false);
        else onChange(undefined);
    }, []);

    const adaptedSelectedValue = adaptSelectedValue(selectedValue);

    return (
        <SingleSelect
            dataTrackId={dataTrackId}
            sections={[{ options }]}
            selectedValue={adaptedSelectedValue}
            onChange={adaptedOnChange}
            placeholder={placeholder}
            disableReset
        />
    );
};
