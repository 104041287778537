import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { LOW_PRIORITY_COMPONENT_Z_INDEX } from '@partoohub/ui';

export const EditFormDescriptionContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const EditFormDescriptionContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 84px 40px;
`;

export const EditFormDescriptionTitle = styled.div`
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: ${({ theme }) => theme.colors.default.initial};
`;

export const EditFormDescriptionSubtitle = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-bottom: 22px;
    white-space: break-spaces;
`;

export const EditFormDescriptionPartooIcon = styled.div`
    img {
        width: 304px;
        height: 304px;
        bottom: -64px;
        right: -64px;
        opacity: 0.04;
        transform: rotate(40.35deg);
        position: absolute;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        pointer-events: none;
    }
`;

export const EditFormDescriptionAdviceContainer = styled.div`
    background-color: ${HEX_COLORS.white};
    border-radius: 16px;
    display: flex;
    align-items: center;
    z-index: ${LOW_PRIORITY_COMPONENT_Z_INDEX};
    padding: 20px 16px;

    > svg {
        min-width: 87px;
    }
`;

export const EditFormDescriptionAdviceTitle = styled.div`
    color: ${({ theme }) => theme.colors.primary.initial};
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
`;

export const EditFormDescriptionAdviceText = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
`;

export const EditFormDescriptionAdviceTexts = styled.div`
    margin-left: 12px;
`;
