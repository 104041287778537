import React from 'react';

import env from 'admin/common/utils/getEnvironment';

import { LogoChipStyled } from '../chips/Chips.styled';

const PartooIcon = React.forwardRef<SVGSVGElement>((_, ref) => (
    <>
        {env.getEnv() === 'dev' ? (
            <svg width="100%" height="100%" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.4385 29.3144C23.5334 29.3144 30.0957 22.7522 30.0957 14.6572C30.0957 6.56226 23.5334 0 15.4385 0C7.34351 0 0.78125 6.56226 0.78125 14.6572C0.78125 22.7522 7.34351 29.3144 15.4385 29.3144Z"
                    fill="#293CFF"
                />
                <path
                    d="M15.4385 39.9647C23.5334 39.9647 30.0957 33.4024 30.0957 25.3075C30.0957 17.2125 23.5334 10.6502 15.4385 10.6502C7.34351 10.6502 0.78125 17.2125 0.78125 25.3075C0.78125 33.4024 7.34351 39.9647 15.4385 39.9647Z"
                    fill="#5ACFFF"
                />
                <path
                    d="M24.6619 34.6398C32.7568 34.6398 39.3191 28.0775 39.3191 19.9826C39.3191 11.8876 32.7568 5.32538 24.6619 5.32538C16.5669 5.32538 10.0046 11.8876 10.0046 19.9826C10.0046 28.0775 16.5669 34.6398 24.6619 34.6398Z"
                    fill="#257BFF"
                />
                <path
                    d="M13.2245 29.1483C8.93129 23.7919 8.93129 16.1733 13.2245 10.8169C7.98273 11.6129 3.65239 15.1816 1.78174 19.9826C3.65288 24.7836 7.98124 28.3542 13.2245 29.1483Z"
                    fill="#4288FF"
                />
                <path
                    d="M13.2245 10.8169C13.9573 10.7061 14.6974 10.6505 15.4385 10.6508C21.6574 10.6508 26.9668 14.5209 29.0977 19.9826C29.7588 18.2857 30.0973 16.4803 30.0957 14.6592C30.1008 11.3253 28.9645 8.09006 26.8758 5.49155C21.7018 4.7058 16.5007 6.73397 13.2245 10.8149L13.2245 10.8169Z"
                    fill="#275CFA"
                />
                <path
                    d="M29.0977 19.9826C26.9668 25.4448 21.6549 29.3144 15.4385 29.3144C14.6974 29.3147 13.9573 29.2591 13.2245 29.1482C16.5007 33.2292 21.7018 35.2574 26.8758 34.4716C28.9637 31.8742 30.0999 28.6405 30.0957 25.3079C30.0976 23.4862 29.7591 21.6801 29.0977 19.9826Z"
                    fill="#40A6FF"
                />
                <path
                    d="M15.4385 10.6508C14.6974 10.6505 13.9573 10.7061 13.2246 10.8169C8.93133 16.1733 8.93133 23.7919 13.2246 29.1483C13.9573 29.2591 14.6974 29.3147 15.4385 29.3144C21.6574 29.3144 26.9669 25.4448 29.0977 19.9826C26.9669 14.5209 21.6549 10.6508 15.4385 10.6508Z"
                    fill="#257BFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0042 19.9829C10.004 15.282 13.8146 11.4709 18.5156 11.4706C20.7732 11.4705 22.9384 12.3672 24.5348 13.9636C26.1312 15.5599 27.028 17.725 27.028 19.9826C27.028 24.6835 23.2172 28.4944 18.5163 28.4945C13.8153 28.4946 10.0043 24.6839 10.0042 19.9829ZM14.0663 19.9871C14.0666 22.4442 16.0585 24.436 18.5156 24.4363L18.5181 24.4388C20.9773 24.4388 22.9708 22.4452 22.9708 19.9861C22.9708 17.5269 20.9773 15.5333 18.5181 15.5333C18.0304 15.5336 17.5462 15.6159 17.0857 15.7766C17.2976 16.1008 17.41 16.4799 17.4091 16.8672C17.4094 17.9776 16.5101 18.8784 15.3997 18.8801C15.0103 18.8802 14.6296 18.7654 14.3051 18.5502C14.148 19.013 14.0673 19.4983 14.0663 19.9871Z"
                    fill="white"
                />
            </svg>
        ) : (
            <svg
                ref={ref}
                width="100%"
                height="100%"
                viewBox="0 0 256 256"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M98.8062 187.612C150.614 187.612 192.612 145.614 192.612 93.8062C192.612 41.9985 150.614 0 98.8062 0C46.9985 0 5 41.9985 5 93.8062C5 145.614 46.9985 187.612 98.8062 187.612Z"
                    fill="#0085F2"
                />
                <path
                    d="M98.8062 255.774C150.614 255.774 192.612 213.776 192.612 161.968C192.612 110.16 150.614 68.1616 98.8062 68.1616C46.9985 68.1616 5 110.16 5 161.968C5 213.776 46.9985 255.774 98.8062 255.774Z"
                    fill="#00EBA9"
                />
                <path
                    d="M157.836 221.695C209.644 221.695 251.642 179.696 251.642 127.889C251.642 76.0808 209.644 34.0824 157.836 34.0824C106.028 34.0824 64.0296 76.0808 64.0296 127.889C64.0296 179.696 106.028 221.695 157.836 221.695Z"
                    fill="#FF9600"
                />
                <path
                    d="M84.637 186.549C57.1603 152.268 57.1603 103.509 84.637 69.2283C51.0896 74.3228 23.3753 97.1622 11.4032 127.889C23.3785 158.615 51.08 181.467 84.637 186.549Z"
                    fill="#15A2FF"
                />
                <path
                    d="M84.637 69.2283C89.3267 68.5188 94.0631 68.1633 98.8062 68.1648C138.607 68.1648 172.588 92.9338 186.225 127.889C190.457 117.029 192.623 105.474 192.612 93.819C192.645 72.4818 185.373 51.7764 172.005 35.1459C138.891 30.1171 105.605 43.0974 84.637 69.2156L84.637 69.2283Z"
                    fill="#AD46FF"
                />
                <path
                    d="M186.225 127.889C172.588 162.847 138.591 187.612 98.8062 187.612C94.0631 187.614 89.3267 187.259 84.637 186.549C105.605 212.667 138.891 225.647 172.005 220.619C185.367 203.995 192.639 183.299 192.612 161.971C192.624 150.312 190.458 138.753 186.225 127.889Z"
                    fill="#FFBA01"
                />
                <path
                    d="M98.8062 68.1648C94.0631 68.1632 89.3267 68.5187 84.637 69.2283C57.1603 103.509 57.1603 152.268 84.637 186.549C89.3267 187.258 94.0631 187.614 98.8062 187.612C138.607 187.612 172.588 162.847 186.225 127.889C172.588 92.9338 138.591 68.1648 98.8062 68.1648Z"
                    fill="#8A78FF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M64.0265 127.891C64.0253 97.8046 88.4136 73.4139 118.5 73.4121C132.948 73.4113 146.805 79.1504 157.022 89.3668C167.239 99.5832 172.979 113.44 172.979 127.889C172.979 157.975 148.59 182.364 118.504 182.365C88.4179 182.366 64.0276 157.977 64.0265 127.891ZM90.0245 127.917C90.0262 143.643 102.774 156.391 118.5 156.392L118.516 156.408C134.254 156.408 147.013 143.65 147.013 127.911C147.013 112.172 134.254 99.4133 118.516 99.4133C115.394 99.4153 112.296 99.9417 109.349 100.97C110.704 103.045 111.424 105.471 111.418 107.95C111.42 115.057 105.665 120.822 98.5578 120.833C96.066 120.833 93.6294 120.099 91.5528 118.722C90.5471 121.683 90.0309 124.789 90.0245 127.917Z"
                    fill="white"
                />
            </svg>
        )}
        <LogoChipStyled>admin</LogoChipStyled>
    </>
));

export default PartooIcon;
