import styled from '@emotion/styled';

import { VARS } from 'admin/common/data/constants/vars';

export const ServiceHelperContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 3 * ${VARS.paddingSimple};
    padding-right: 16px;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const ServiceHelperText = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ServiceHelperLabel = styled.div`
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
`;

export const OverrideHelperContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 3 * ${VARS.paddingSimple};
    padding-right: 16px;
    justify-content: space-between;
`;

export const OverrideHelperText = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const OverrideHelperLabel = styled.div`
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
`;

export const ConfirmButton = styled.div`
    margin-top: 16px;
`;
