import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { filterByBusinessSelector } from 'app/common/components/businessModalDeprecated/reducers';

import generateFiltersFromBusinessModal, {
    BusinessModalFiltersType,
} from '../utils/generateFiltersFromBusinessModal';

/**
 * Generates the filters from the business modal for the BusinessSearchCompanion
 * It's parsing redux data for now
 */
export default function useBusinessModalFilters(isSelectionMode = false): BusinessModalFiltersType {
    //NOSONAR
    const filterByBusiness = useSelector(filterByBusinessSelector);
    //NOSONAR
    return useMemo(
        () => generateFiltersFromBusinessModal(filterByBusiness, isSelectionMode),
        [filterByBusiness],
    );
}
