// This function returns if a business is opening soon, ie having an opening date in the future
import { V2BusinessData } from 'app/api/types/business';
import { OPEN } from 'app/common/data/businessStatuses';

export const isBusinessOpeningSoon = (business: V2BusinessData | undefined): boolean => {
    if (business) {
        return !!(
            business?.status === OPEN &&
            business?.opening_date &&
            new Date(business?.opening_date) > new Date()
        );
    }

    return false;
};
