import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import GroupSection from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/GroupsContent/Groups/GroupSection';

import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import {
    GroupFilter,
    getGroupIds,
} from 'app/common/components/businessModal/context/businessModalFiltersReducer';

import useHideGroupsForAdmins from 'app/common/components/businessModal/hooks/privateHooks/useHideGroupsForAdmins';
import useOrgFilteredGroupsV2 from 'app/common/components/businessModal/hooks/privateHooks/useOrgFilteredGroupsV2';

import { GroupsContainer } from './Groups.styled';
import { NoGroupSection } from './GroupSection/NoGroupSection';

const Groups = () => {
    const hideGroups = useHideGroupsForAdmins();

    const { variation, removeFilter, addFilter, filters, limitToGroups } =
        useBusinessModalContext();
    const { query, sections } = useOrgFilteredGroupsV2(
        {},
        { enabled: variation !== 'business-only' },
    );
    const { fetchNextPage, hasNextPage, isFetchingNextPage } = query;

    const toggleGroup = (id: string, label: string, checked: boolean) => {
        const filter: GroupFilter = [
            'group',
            {
                id: [id],
                label,
            },
        ];
        if (checked) {
            removeFilter(filter);
        } else {
            addFilter(filter);
        }
    };
    const selectedGroups = getGroupIds(filters);
    let enabledGroups: string[];
    if (limitToGroups) {
        enabledGroups = limitToGroups.map(group => group.id[0]!);

        sections?.forEach(section => {
            const sectionGroupIds = section.groups.map(group => group.id.toString());
            const selectedInSection = selectedGroups.filter(id => sectionGroupIds.includes(id));
            if (selectedInSection.length === 1 && selectedInSection[0]) {
                enabledGroups.splice(enabledGroups.indexOf(selectedInSection[0]), 1);
            }
        });
    }
    const { t } = useTranslation();

    return (
        <GroupsContainer>
            {!hideGroups && (
                <InfiniteScroll
                    hasMore={hasNextPage && !isFetchingNextPage}
                    loadMore={() => fetchNextPage()}
                    useWindow={false}
                >
                    {(sections ?? [])
                        .filter(section => section.groups.some(group => group.business_count > 0))
                        .map(section => (
                            <GroupSection
                                key={`section-${section.id}`}
                                section={section}
                                onGroupClick={toggleGroup}
                                selectedGroups={selectedGroups}
                                enabledGroups={enabledGroups}
                                disabledTooltip={t('business_modal_disabled_group_tooltip')}
                            />
                        ))}
                    {!limitToGroups?.length && (
                        <NoGroupSection onClick={toggleGroup} selectedGroups={selectedGroups} />
                    )}
                </InfiniteScroll>
            )}
        </GroupsContainer>
    );
};

export default Groups;
