import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import { MessagingPrivacyPolicyData } from 'app/pages/privacyPolicy/types';

const MESSAGING_PRIVACY_POLICY = 'privacy/messaging/';

class PrivacyPolicy extends ApiResourceBaseClass {
    static getMessagingPrivacyPolicy(policyId: string): Promise<MessagingPrivacyPolicyData> {
        return PrivacyPolicy.get(`${MESSAGING_PRIVACY_POLICY}${policyId}`).then(({ data }) => data);
    }
}

export default PrivacyPolicy;
