import styled from '@emotion/styled';

export const DiffusionStepLog = styled.div`
    display: flex;
    flex-direction: row;
    padding: 16px 0;
`;

export const DiffusionStepLogFlags = styled.div<{
    state: '' | 'PENDING' | 'SUCCESS' | 'FAILURE';
}>``;
