import { Option } from '@partoohub/ui';

import { V2OrgData } from 'app/api/types/org';

export const convertOrgToSelectOption = (org: V2OrgData) =>
    ({
        label: `${org.name} (${org.org_id})`,
        name: org.org_id.toString(),
        value: org.org_id.toString(),
    }) as Option;
