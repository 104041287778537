import { Stack, Text } from '@partoohub/ui';

type Props = {
    date: number;
};

const formatDate = (date: number) => {
    const dateToFormat = new Date(date * 1000)
        .toDateString()
        .slice(4)
        .split(' ');
    return `${dateToFormat[1]} ${dateToFormat[0]} ${dateToFormat[2]}`;
};

const DateFormat = ({ date }: Props) => {
    return date ? (
        <Stack gap="4px" direction="column">
            <Text variant="bodyMBold" as="span">
                {formatDate(date)}
            </Text>
            <Text variant="bodySRegular" as="span" color="secondary">
                {new Date(date * 1000).toLocaleTimeString()}
            </Text>
        </Stack>
    ) : (
        <></>
    );
};

export default DateFormat;
