import React from 'react';

import { useTranslation } from 'react-i18next';

import AdminPage from 'admin/common/components/templates/AdminPage';

import { MergedAccountsModals } from './sections/MergedAccountsModals';
import { MergedAccountsPage } from './sections/MergedAccountsPage';

export const MultiOrganizations: React.FC = () => {
    const { t } = useTranslation();

    return (
        <AdminPage dataTrackId="multi_organizations_page" title={t('admin:org_switcher_title')}>
            <MergedAccountsPage />

            <MergedAccountsModals />
        </AdminPage>
    );
};
