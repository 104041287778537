import ApiResourceBaseClass from './baseApiCalls';
import { UploadImageResponse } from '../../types/image';

const RESOURCE_URI = 'image';

class Image extends ApiResourceBaseClass {
    static uploadImage(formData: FormData): Promise<UploadImageResponse> {
        return Image.post(`${RESOURCE_URI}`, formData, {
            'Content-Type': 'multipart/form-data',
        }).then(({ data }) => data);
    }
}

export default Image;
