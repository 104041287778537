import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import api from 'admin/common/api/calls';
import { CreateNewKeywordPayload } from 'admin/common/api/types/competitiveBenchmark';
import { COMPETITIVE_BENCHMARK_KEYWORDS } from 'admin/common/data/queryKeysConstants';

export const useCreateNewKeyword = (): {
    isLoading: boolean;
    mutate: (payload: CreateNewKeywordPayload) => void;
} => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(
        (payload: CreateNewKeywordPayload) => api.competitiveBenchmark.createNewKeyword(payload),
        {
            onSuccess: keyword => {
                queryClient.setQueryData(
                    [COMPETITIVE_BENCHMARK_KEYWORDS, keyword.partoo_org_id],
                    (oldData: any) => ({ keywords: [...oldData.keywords, keyword] }),
                );
            },
            onError: () => {
                toast.error(
                    t('admin:page_connect_as__error_toast__description'),
                    t('admin:page_connect_as__error_toast__title'),
                );
            },
        },
    );

    return { mutate, isLoading };
};
