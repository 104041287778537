import { Chip, Text, Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { AccountPartnerName, V2LocationData } from 'app/api/types/account';
import {
    ChipContainer,
    InfosContainer,
    LocationAddressContainer,
    LocationCardContainer,
    LocationCodeContainer,
    LocationNameContainer,
    LocationStatusText,
    PartnerIconContainer,
    ServiceAreaComponent,
} from 'app/businessConnection/components/common/LocationCard/LocationCard.styled';
import PartnerIcon from 'app/businessConnection/components/common/PartnerIcon';
import { BRAND_TYPE } from 'app/common/data/locationTypes';
import { formatISODatetime } from 'app/utils/dates';

type Props = {
    location: V2LocationData;
    partnerName: AccountPartnerName;
    hasError?: boolean;
    isSelected?: boolean;
    noBorder?: boolean;
};

export const googleVerificationsDict = {
    verified: {
        color: 'success',
        label: 'google_verification_location_status_verified',
        icon: 'fa-check-circle',
    },
    unverified: {
        color: 'warning',
        label: 'google_verification_location_status_unverified',
        icon: 'fa-exclamation-circle',
    },
    pending: {
        color: 'warning',
        label: 'google_verification_location_status_pending',
        icon: 'fa-exclamation-circle',
    },
    in_review: {
        color: 'warning',
        label: 'google_verification_location_status_in_review',
        icon: 'fa-exclamation-circle',
    },
    has_duplicate: {
        color: 'warning',
        label: 'google_verification_location_status_has_duplicate',
        icon: 'fa-exclamation-circle',
    },
    disabled: {
        color: 'danger',
        label: 'google_verification_location_status_disabled',
        icon: 'fa-circle-xmark',
    },
    suspended: {
        color: 'danger',
        label: 'google_verification_location_status_suspended',
        icon: 'fa-circle-xmark',
    },
    null: {
        color: 'secondary',
        label: 'google_verification_location_status_missing',
        icon: 'fa-circle-question',
    },
};

const verificationStatusComponent = (
    currentStatus: { color: string; label: string; icon: string },
    statusCheckedAt: string | undefined,
) => {
    const { t } = useTranslation();

    return (
        <Tooltip text={statusCheckedAt ? formatISODatetime(statusCheckedAt) : ''} placement="auto">
            <LocationStatusText color={currentStatus.color} variant="bodySBold" oneLine>
                <i className={`fa-solid ${currentStatus.icon}`} />
                {t(currentStatus.label)}
            </LocationStatusText>
        </Tooltip>
    );
};

const pageChipComponent = () => {
    const { t } = useTranslation();

    return (
        <Chip
            dataTrackId="connection_modal__location_card_chip"
            aria-label={t('page')}
            chipShape="cube"
            variant="theme"
        >
            <Text variant="bodyXSBold">{t('page').toUpperCase()}</Text>
        </Chip>
    );
};

export const renderGoogleVerificationStatus = (location: V2LocationData) => {
    const currentStatusData =
        googleVerificationsDict[location.google_verifications?.status || 'null'];

    if (currentStatusData) {
        return verificationStatusComponent(
            currentStatusData,
            location.google_verifications?.checked_at,
        );
    }
};

export const commonLocationCardComponent = (location: V2LocationData) => {
    const { t } = useTranslation();

    const isBrandType = location.type === BRAND_TYPE;
    const hasAddressDetails = location.address_details || location.postal_code || location.locality;
    const areAllAddressFieldsMissing =
        !location.address_details && !location.postal_code && !location.locality;

    return (
        <>
            <LocationNameContainer variant="bodyMBold">
                {location.location_name}
                {isBrandType && hasAddressDetails && pageChipComponent()}
            </LocationNameContainer>
            {isBrandType && areAllAddressFieldsMissing ? (
                <ChipContainer>{pageChipComponent()}</ChipContainer>
            ) : (
                <LocationAddressContainer>
                    {!location.address_details && (
                        <ServiceAreaComponent>
                            <span className="fa-stack fa-small">
                                <i className="fa-solid fa-circle fa-stack-2x"></i>
                                <i className="fa-solid fa-location-dot fa-stack-1x fa-inverse"></i>
                            </span>
                            <div>{t('google_location_service_area')}</div>
                        </ServiceAreaComponent>
                    )}
                    {location.address_details && `${location.address_details}\n`}
                    {location.postal_code && `${location.postal_code} `}
                    {location.locality && `${location.locality}`}
                </LocationAddressContainer>
            )}
            {!isBrandType && location.store_code && (
                <LocationCodeContainer variant="bodySBold">
                    {`${t('store_code')}:`}
                    <span>{location.store_code}</span>
                </LocationCodeContainer>
            )}
        </>
    );
};

const LocationCard = ({ isSelected, hasError, noBorder, location, partnerName }: Props) => (
    <LocationCardContainer isSelected={isSelected} hasError={hasError} noBorder={noBorder}>
        <PartnerIconContainer>{PartnerIcon(partnerName)}</PartnerIconContainer>
        <InfosContainer>
            {commonLocationCardComponent(location)}
            {partnerName === 'google' && renderGoogleVerificationStatus(location)}
        </InfosContainer>
    </LocationCardContainer>
);

export default LocationCard;
