import { SuccessResponse } from '@partoohub/api-client';

import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

const RESOURCE_URI = 'bulk';

export class BulkImportClientApiCall extends ApiResourceBaseClass {
    static bulkBusinessImport(file: Blob, email?: string): Promise<SuccessResponse> {
        const formData = new FormData();
        formData.append('file', file);
        if (email) formData.append('email', email);

        return BulkImportClientApiCall.post(`${RESOURCE_URI}/business/importer`, formData).then(
            ({ data }) => data,
        );
    }
}
