import { FeedbackReplySuggestionPromptData } from '@partoohub/api-client';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { feedbackReplySuggestionApiClient } from 'admin/api/apiResources';
import { FEEDBACK_REPLY_SUGGESTION_GENERIC_PROMPT } from 'admin/common/data/queryKeysConstants';

export const useGetFeedbackReplySuggestionGenericPrompt = (
    options: UseQueryOptions<FeedbackReplySuggestionPromptData> = {},
) => {
    const query = useQuery(
        [FEEDBACK_REPLY_SUGGESTION_GENERIC_PROMPT] as QueryKey,
        () => feedbackReplySuggestionApiClient.getGenericPrompt(),
        options,
    );

    return [query.data, query] as const;
};
