import React from 'react';

import { MultiSelect, Option } from '@partoohub/ui';
import { useQuery } from 'react-query';

import { publisherApiClient } from 'admin/api/apiResources';
import { PUBLISHERS } from 'admin/common/data/queryKeysConstants';

type Props = {
    publishers: Record<string, boolean>;
    setPublishers: (selectedItems: Record<string, boolean>) => void;
    dataTrackId: string;
    disabled?: boolean;
};

export const NewPublishersSelect: React.FC<Props> = ({
    publishers,
    setPublishers,
    dataTrackId,
    disabled,
}: Props) => {
    const { data } = useQuery(
        [PUBLISHERS],
        async () => {
            const publishersList = await publisherApiClient.getPublishersForUser();
            const options = publishersList.map(publisher => ({
                value: publisher.name,
                name: publisher.name,
                label: publisher.formatted_name,
            }));
            const section = [{ options: options }];
            return section;
        },
        {
            staleTime: Infinity,
        },
    );
    const section = data || [];

    const a = (section[0]?.options || []).filter(({ value }) => publishers[value]);

    const setPublishersAdapted = (options: Option[]) =>
        setPublishers(
            Object.fromEntries(
                (section[0]?.options || []).map(({ value }) => [
                    value,
                    options.map(option => option.value).includes(value),
                ]),
            ),
        );

    return (
        <MultiSelect
            dataTrackId={dataTrackId}
            sections={section}
            selectedValue={a}
            onChange={setPublishersAdapted}
            placeholder={'Publishers'}
            maxHeight={300}
            disabled={disabled}
        />
    );
};
