import styled from '@emotion/styled';

export const LocationDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

export const LocationDetailsBrandBadge = styled.div`
    border: 1px solid;
    border-radius: 3px;
    padding: 1px 3px 0 3px;
    text-transform: uppercase;
    font-size: 10px;
`;
