import { ApiResourceBaseClass } from '@partoohub/api-client';

const RESOURCE_URI = 'reviews';

export class ReviewAdminApiCall extends ApiResourceBaseClass {
    async getWrongGmbLocationCount(business_id: string): Promise<Record<'count', number>> {
        const { data } = await this.get<Record<'count', number>>(
            `${RESOURCE_URI}/get_wrong_gmb_location_count`,
            {
                business_id,
            },
        );

        return data;
    }

    async cleanGmbReviews(business_id: string): Promise<Record<'deleted', number>> {
        const { data } = await this.post<Record<'deleted', number>>(
            `${RESOURCE_URI}/clean_gmb_reviews`,
            {
                business_id,
            },
        );
        return data;
    }
}
