import React, { useState } from 'react';

import { uniqueId } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';

import useBusinessModalCurrentSelectedCount from 'app/common/components/businessModal/hooks/privateHooks/useBusinessModalCurrentSelectedCount';
import useBusinessModalDisplayedBusinesses from 'app/common/components/businessModal/hooks/privateHooks/useBusinessModalDisplayedBusinesses';
import useTotalBusinessesCount from 'app/common/components/businessModal/hooks/useTotalBusinessesCount';

import BusinessCard from './BusinessCard';
import {
    BusinessCount,
    NotFoundMessage,
    ScrollContainer,
} from './Businesses.styled';
import BusinessLoadingCards from './BusinessLoadingCards';

const Businesses = () => {
    const { t } = useTranslation();
    const { query, businesses } = useBusinessModalDisplayedBusinesses();
    const [businessModalId] = useState(() => uniqueId('businesses-scroll-'));
    const selectedCount = useBusinessModalCurrentSelectedCount();
    const totalBusinessesCount = useTotalBusinessesCount();
    const { isSelectionMode } = useBusinessModalContext();

    const count = isSelectionMode
        ? selectedCount ?? 0
        : selectedCount || totalBusinessesCount;

    return (
        <>
            <BusinessCount>
                {t('business_modal_button_business_count_label', {
                    count,
                })}
            </BusinessCount>

            <ScrollContainer id={businessModalId}>
                <InfiniteScroll
                    hasMore={!!query.hasNextPage}
                    dataLength={businesses?.length ?? 0}
                    next={() => query.fetchNextPage()}
                    scrollableTarget={businessModalId}
                    loader={null}
                >
                    {query.isLoading && <BusinessLoadingCards count={12} />}

                    {businesses?.map((business, index) => (
                        <BusinessCard
                            key={`${business.id}-${index}`} // the backend can send duplicates :/
                            business={business}
                        />
                    ))}

                    {query.isFetchingNextPage && (
                        <BusinessLoadingCards count={6} />
                    )}
                    {!query.isLoading && businesses?.length === 0 && (
                        <NotFoundMessage>
                            {t('no_business_found')}
                        </NotFoundMessage>
                    )}
                </InfiniteScroll>
            </ScrollContainer>
        </>
    );
};

{
    /* Beware not to break the memoization below, there may be a lot of businesses,
     * check with the React profiler that this component below is not re-rendering
     * when typing in the filter search box.
     * TODO: consider using react-tracked
     */
}
const MemoizedBusinesses = React.memo(Businesses);

export default MemoizedBusinesses;
