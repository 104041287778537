import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

export type TypeFromEnumDict<E extends Record<string, string>> = E[keyof E];

/**
 * Message Type Definition
 */
export const MESSAGE_SENDER_TYPE = {
    USER: 'User',
    CONSUMER: 'Consumer',
    BOT: 'Bot',
    METADATA: 'Metadata',
} as const;

export const MESSAGE_CONTENT_TYPE = {
    IMAGE: 'Image',
    TEXT: 'Text',
    BUTTONS: 'Buttons',
    QUICK_REPLIES: 'QuickReplies',
    CONVERSATION_STARTER: 'ConversationStarter',
    ANSWER: 'Answer',
    IG_STORY_MENTION: 'IgStoryMention',
    IG_STORY_REPLY: 'IgStoryReply',
    SOLVING: 'Solving',
    REOPENING: 'Reopening',
    ASSIGNING: 'Assigning',
    UNASSIGNING: 'UnAssigning',
    SPAM: 'Spam',
    HANDOVER: 'Handover',
    UNSUPPORTED: 'Unsupported',
    ROUTING: 'Routing',
} as const;

export const MESSAGE_STATUS = {
    FAIL: 'Failed',
    SUCCESS: 'Success',
    SENDING: 'Sending',
} as const;

export const BUTTON_TYPE = {
    POSTBACK: 'postback',
    WEB_URL: 'web_url',
};

export enum StatusFilterOption {
    OPEN = 'open',
    CLOSED = 'closed',
}

export enum ConversationStatus {
    OPEN = 'Open',
    CLOSED = 'Closed',
}

export enum AssignFilterOption {
    ALL = 'all',
    ME = 'me',
    UNASSIGNED = 'unassigned',
    AUTOMATED = 'automated',
}

export enum URLParams {
    ASSIGN = 'assign',
    STATUS = 'status',
    CONVERSATION_ID = 'conversationId',
    SHOW_MODAL = 'showModal',
}

export const LOCAL_STORAGE_CONVERSATION_FILTERS = 'conversation_filters';

export type MessageStatus = TypeFromEnumDict<typeof MESSAGE_STATUS>;
export type MessageContentType = TypeFromEnumDict<typeof MESSAGE_CONTENT_TYPE>;
export type MessageSenderType = TypeFromEnumDict<typeof MESSAGE_SENDER_TYPE>;
export type ButtonType = TypeFromEnumDict<typeof BUTTON_TYPE>;

export type MessageStoryReplyType = {
    url: string;
    content: string;
    content_type: MessageContentType;
    id: string;
};

export interface Button {
    content_type: ButtonType;
    title: string;
    payload?: string | number;
    web_url?: string;
}

export interface ButtonMessageContent {
    text: string;
    buttons: Button[];
}

export interface QuickReply {
    content_type: 'text';
    title: string;
    payload: string | number;
    image_url?: string | null;
}

export interface QuickRepliesMessageContent {
    text: string;
    quick_replies: QuickReply[];
}

export interface ConversationStarterMessageContent {
    text?: string;
    buttons: Button[];
}

export interface AnswerMessage {
    text: string;
    payload: string;
}

export enum UnsupportedContentValues {
    AUDIO = 'audio',
    VIDEO = 'video',
    UNKNOWN = 'unknown',
}

export interface UnsupportedContent {
    type: UnsupportedContentValues;
}
export interface RoutedBusinessMetadataMessageContent {
    id: string;
    name: string;
    city: string;
    zipcode: string;
    country: string;
    formatted_address: string;
}

export type ConversationMessageContent =
    | string
    | MessageStoryReplyType
    | ButtonMessageContent
    | QuickRepliesMessageContent
    | ConversationStarterMessageContent
    | AnswerMessage
    | UnsupportedContent
    | RoutedBusinessMetadataMessageContent;

export type ConversationMessage = {
    id?: number;
    status: MessageStatus;
    user_id: string;
    content: ConversationMessageContent;
    content_type: MessageContentType;
    date: string;
    is_deleted: boolean;
    sender_type: MessageSenderType;
    sender_label: string;
    parent_conversation?: Conversation;
    delivered_at?: string;
    template_id?: string;
    is_automated: boolean;
};

// These define specific issues within a conversation.
export const CONVERSATION_ISSUE = {
    FACEBOOK_MISSING_WEBHOOK_SUBS: 'facebook_missing_webhook_subs',
    FACEBOOK_INVALID_STATUS: 'facebook_invalid_status',
    FACEBOOK_PARTNER_CO_UNDONE: 'facebook_partner_co_undone',
    INSTAGRAM_NO_ACCOUNT_LINKED: 'instagram_no_account_linked',
    SMS_NO_PHONE_NUMBER: 'sms_no_phone_number',
    GBM_END_OF_SERVICE: 'gbm_end_of_service',
} as const;

export type ConversationIssue = TypeFromEnumDict<typeof CONVERSATION_ISSUE>;

// These define the translation to display whithin the conversation
export const ISSUE_TRANSLATION_MAP = new Map<string, string>([
    [CONVERSATION_ISSUE.FACEBOOK_MISSING_WEBHOOK_SUBS, 'messaging_conversation_messenger_error'],
    [CONVERSATION_ISSUE.FACEBOOK_INVALID_STATUS, 'messaging_conversation_facebook_status_error'],
    [
        CONVERSATION_ISSUE.FACEBOOK_PARTNER_CO_UNDONE,
        'messaging_conversation_facebook_connection_undone',
    ],
    [
        CONVERSATION_ISSUE.INSTAGRAM_NO_ACCOUNT_LINKED,
        'messaging_conversation_missing_instagram_account_error',
    ],
    [CONVERSATION_ISSUE.SMS_NO_PHONE_NUMBER, 'Messages_SMS_no_phone_number_error_message'],
    [CONVERSATION_ISSUE.GBM_END_OF_SERVICE, 'gbm_end_inbox_message'],
]);

type ConversationBusiness = {
    id: string;
    name: string;
    formatted_address: string; // ex: 157 Boulevard Macdonald, Paris, 75019
    zipcode: string;
    city: string;
    country: string;
    reach: string;
};

export type MessagingPartner = 'Google' | 'Facebook' | 'Instagram' | 'Whatsapp' | 'SMS' | 'Delight';
export enum MessagingPartnerEnum {
    GOOGLE = 'Google',
    FACEBOOK = 'Facebook',
    INSTAGRAM = 'Instagram',
    WHATSAPP = 'Whatsapp',
    SMS = 'SMS',
    DELIGHT = 'Delight',
}

export type Conversation = {
    id: number;
    consumer_img: string;
    consumer_name: string;
    business: ConversationBusiness;
    routed_business: ConversationBusiness | null;
    is_read: boolean;
    status: ConversationStatus;
    last_message: Partial<ConversationMessage>;
    created_at: string;
    has_one_user_message: boolean;
    messaging_partner: MessagingPartner;
    issues: Array<ConversationIssue>;
    first_message_date: string;
    consumer_phone_number?: string;
    is_solved: boolean;
    is_expired: boolean;
    is_automated: boolean;
    assigned_user_id: string | null;
    display_name: string | null;
};

export type ConversationStats = {
    unread: number;
    unread_open: number;
    unread_close: number;
    median_sla: number | null;
    open: AssignConversationStats;
    close: AssignConversationStats;
};

export type AssignConversationStats = {
    all: number;
    assigned_to_me: number;
    not_assigned: number;
    automated: number;
};

export type MessagingInfos = {
    show_add_messenger_button: boolean;
    show_add_instagram_button: boolean;
    show_instagram_onboarded_button: boolean;
    show_missing_access_banner: boolean;
    show_whatsapp_modal: boolean;
};

export type ConversationsData = {
    count: number;
    next_cursor: string | null;
    conversations: Array<Conversation>;
};

export type PostMessageData = {
    message_id: number;
};

export type LiveConversationEventData = Conversation & { businessId: string };

export type MessagingFilters = {
    statusFilter: StatusFilterOption;
    assignFilter: AssignFilterOption;
    businessFilters: BusinessModalFiltersType;
};
