import { Option } from '@partoohub/ui';
import { sortBy } from 'lodash-es';

export const countryMapper = (data: Record<string, any> | undefined): Option[] => {
    const mappedCountry: any = [];
    data?.regions?.forEach(([code, name]: [string, string]) =>
        mappedCountry.push({
            value: code,
            name,
            label: name,
        }),
    );
    return sortBy(mappedCountry, choice => choice.label);
};

export const retrieveDefaultCountry = (countries: Option[]) =>
    countries.find(o => o.value === 'FR') as Option;

export const formatData = (formData: Record<string, any>) => {
    const formattedData: { [key: string]: any } = {};

    if (formData.regionCode) {
        formData.code = formData.regionCode;
        delete formData.regionCode;
    }

    for (const key of Object.keys(formData)) {
        formattedData[key] = formData[key]?.value || formData[key];
    }

    return formattedData;
};
