import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';

import { BulkImportV1Inputs } from 'admin/api/types';

const RESOURCE_URI = 'csinterface_uploader';

export class BulkImportV1 extends ApiResourceBaseClass {
    async bulkImportV1(inputs: BulkImportV1Inputs): Promise<SuccessResponse> {
        const formData = new FormData();

        if (inputs.file) formData.append('file', inputs.file);
        formData.append('integration', inputs.integration);
        formData.append('email', inputs.email);
        if (inputs.do_save) formData.append('do_save', 'on');

        const { data } = await this.post<SuccessResponse>(`${RESOURCE_URI}`, formData);
        return data;
    }
}
