import styled from '@emotion/styled';

export const SidebarWrapper = styled.div`
    width: 64px;
`;

export const SidebarHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 12px 0px 10px 0px;
    height: 80px;
`;

export const SidebarHeaderLogo = styled.div`
    height: 40px;
    /* width: 40px; */
`;

export const SidebarHeaderTitle = styled.div`
    margin-top: 12px;
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
`;

export const SidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100% - 80px);
`;

export const MainNav = styled.div`
    overflow-y: overlay;
    overflow-x: hidden;

    padding-top: 8px;

    height: 100%;
`;

export const FooterNav = styled.div`
    @media screen and (max-height: 700px) {
        box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.08);
    }
`;
