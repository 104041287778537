import { Choice } from 'app/api/types/user';
import { OrgSelectBusinessModalContainer } from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/GroupsContent/OrgSelectBusinessModal/OrgSelectBusinessModal.styled';
import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import { OrgFilter } from 'app/common/components/businessModal/context/businessModalFiltersReducer';
import OrganizationSelect from 'app/common/components/OrganizationSelect';

const OrgSelectBusinessModal = () => {
    const { addFilter, removeFilter, filters } = useBusinessModalContext();

    // @ts-ignore
    const selectedOrg: OrgFilter | undefined = filters.find(
        filter => filter[0] === 'org',
    );
    const value: Choice | null = selectedOrg
        ? {
              value: selectedOrg[1].id,
              label: selectedOrg[1].label,
          }
        : null;

    const onChange = (org: Choice | null) => {
        if (org === null) {
            if (selectedOrg) {
                removeFilter([
                    'org',
                    {
                        id: selectedOrg[1].id,
                        label: selectedOrg[1].label,
                    },
                ]);
            }
        } else {
            addFilter([
                'org',
                {
                    id: org.value,
                    label: org.label,
                },
            ]);
        }
    };

    return (
        <OrgSelectBusinessModalContainer>
            <OrganizationSelect onChange={onChange} value={value} />
        </OrgSelectBusinessModalContainer>
    );
};

export default OrgSelectBusinessModal;
