export const bgColor: { [status: string]: string } = {
    PENDING: 'background-color--primary',
    FIELD_ERROR: 'background-color--warning',
    FIELD_REJECTED: 'background-color--warning',
    DUPLICATED_BUSINESSES: 'background-color--warning',
    ERROR: 'background-color--danger',
    NOT_AUTHORIZED: 'background-color--danger',
    NOT_AUTHENTICATED: 'background-color--danger',
    SUCCESS: 'background-color--success',
    ORG_DISABLED: 'background-color--grey',
    INPUT_ERROR: 'background-color--danger',
    COLUMN_ERROR: 'background-color--danger',
    NO_MODIFICATION: 'background-color--warning',
};
