import { FC } from 'react';

import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

interface CategoryExportButtonProps {
    onClick?: () => void;
    disabled?: boolean;
}

export const CategoryExportButton: FC<CategoryExportButtonProps> = ({ disabled, onClick }) => {
    const { t } = useTranslation();

    return (
        <Button
            dataTrackId="category_header__export_button"
            icon={[FontAwesomeIconsPartooUsed.faFileDownload, IconPrefix.SOLID]}
            variant="success"
            appearance="contained"
            shape="cube"
            onClick={onClick}
            disabled={disabled}
        >
            {t('admin:categories_listing_export')}
        </Button>
    );
};
