import { useState } from 'react';

import { BusinessCount, BusinessModalFilters, ResetModeEnum } from '@partoohub/modular-components';
import {
    Button,
    CustomFile,
    DropzoneTypeEnum,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    Stack,
    toast,
} from '@partoohub/ui';
import { getQueryStringFromObject } from '@partoohub/utils';
import { useTranslation } from 'react-i18next';

import { BusinessModalComponent } from 'admin/common/components/businessModal/BusinessModal';
import { initialCountValue } from 'admin/common/components/businessModal/constants';
import { DropZoneDescription } from 'admin/common/components/inputs/DropZone/DropZoneDescription';
import { DropZoneWithoutUpload } from 'admin/common/components/inputs/DropZone/DropzoneWithoutUpload';
import AdminPage from 'admin/common/components/templates/AdminPage';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

import { useUploadTripadvisor } from './hooks/useUploadTripadvisor';

const MAX_SIZE = 5_000_000;
const ACCEPTED_FILES = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

const TripAdvisorUploader = () => {
    const { t } = useTranslation();
    const [count, setCount] = useState<BusinessCount>(initialCountValue);
    const [filters, setFilters] = useState<BusinessModalFilters>({});
    const { total = 0, selected = 0 } = count;

    const [files, setFiles] = useState<CustomFile[]>([]);
    // TODO MEESEEKS: replace this by a useMutation with queryParams
    const templateUrl = `/admin/download_tripadvisor_template?${getQueryStringFromObject({ business__in: filters?.business__in })}`;

    const disabled = selected === 0 || total === selected;

    const { uploadTripAdvisor, isLoading } = useUploadTripadvisor({
        onSuccess: () => {
            setFiles([]);
            toast.success(
                t('admin:tripadvisor_uploader__on_save_success__toast_desc'),
                t('admin:tripadvisor_uploader__on_save_success__toast_title'),
            );
        },
        onError: (error: any) => {
            toast.error(
                JSON.stringify(error.response?.data?.errors?.json || error.response?.data?.errors),
                t('admin:tripadvisor_uploader__on_save_error__toast_title'),
            );
        },
    });

    const onClick = () => {
        if (!files.length) return;
        const formData = new FormData();
        formData.append('file', files[0]);
        uploadTripAdvisor(formData);
    };

    return (
        <AdminPage
            dataTrackId="page_tripadvisor_importer"
            title={t('admin:page_tripadvisor_importer__title')}
            description={t('admin:page_tripadvisor_importer__description')}
            infoLink={'https://partoo.elium.com/tile/view/235/'}
            buttons={
                <Stack gap="8px" direction="row">
                    <BusinessModalComponent
                        variant="businessOnly"
                        buttonVariant="page"
                        filters={filters}
                        setFilters={setFilters}
                        onCountChange={setCount}
                        resetMode={ResetModeEnum.Lifecycle}
                    />
                    <Button
                        dataTrackId="admin_tripadvisor_importer__download_excel"
                        variant="success"
                        icon={[FontAwesomeIconsPartooUsed.faFileDownload, IconPrefix.SOLID]}
                        onClick={() => {
                            window.location.href = templateUrl;
                        }}
                        disabled={disabled}
                        tooltip={disabled ? t('tripadvisor_template_button_tooltip') : ''}
                    >
                        {t('download_excel_template')}
                    </Button>
                </Stack>
            }
        >
            <DropZoneWithoutUpload
                title={t('drop_zone_label')}
                acceptedDescription={
                    <DropZoneDescription maxSize={MAX_SIZE} acceptedExtensions={ACCEPTED_FILES} />
                }
                type={DropzoneTypeEnum.Document}
                files={files}
                setFiles={setFiles}
                multiple={false}
                maxSize={MAX_SIZE}
                full
                accept={ACCEPTED_FILES}
            />
            <SubmitButton
                dataTrackId="admin_tripadvisor_importer__import_data"
                text={t('admin:page_importer__button_submit')}
                disabled={isLoading || !files.length}
                onClick={onClick}
                loading={isLoading}
            />
        </AdminPage>
    );
};

export default TripAdvisorUploader;
