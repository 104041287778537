let ENV: string;
let STATIC_BASE_URL: string;
let WEBSOCKET_LIVE_UPDATES_URL: string;
let FEEDBACK_MANAGEMENT_BASE_URL: string;

switch (window.location.hostname) {
    case 'admin.partoo.co':
        ENV = 'prod';
        STATIC_BASE_URL = 'https://static.partoo.co';
        WEBSOCKET_LIVE_UPDATES_URL = 'wss://websockets.prod.partoo.co';
        FEEDBACK_MANAGEMENT_BASE_URL = 'https://survey.partoo.co';
        break;
    case 'admin.preprod.partoo.co':
        ENV = 'preprod';
        STATIC_BASE_URL = 'https://static.preprod.partoo.co';
        WEBSOCKET_LIVE_UPDATES_URL = 'wss://websockets.preprod.partoo.co';
        FEEDBACK_MANAGEMENT_BASE_URL = 'https://survey.preprod.partoo.co';
        break;
    case 'admin.sandbox.partoo.co':
        ENV = 'sandbox';
        STATIC_BASE_URL = 'https://static.sandbox.partoo.co';
        WEBSOCKET_LIVE_UPDATES_URL = 'wss://websockets.sandbox.partoo.co';
        FEEDBACK_MANAGEMENT_BASE_URL = 'https://survey.sandbox.partoo.co';
        break;
    case 'admin.staging.partoo.co':
        ENV = 'staging';
        STATIC_BASE_URL = 'https://static.staging.partoo.co';
        WEBSOCKET_LIVE_UPDATES_URL = 'wss://websockets.staging.partoo.co';
        FEEDBACK_MANAGEMENT_BASE_URL = 'https://survey.staging.partoo.co';
        break;
    case 'admin.dev.partoo.co':
        ENV = 'dev';
        STATIC_BASE_URL = 'https://static.dev.partoo.co';
        WEBSOCKET_LIVE_UPDATES_URL = 'wss://app.dev.partoo.co/websockets';
        FEEDBACK_MANAGEMENT_BASE_URL = 'https://survey.dev.partoo.co';
        break;
    default:
        console.warn(
            `No config defined for ${window.location.hostname} in front/javascript/admin/src/common/data/config.ts. If you're adding an environment, update this file accordingly`,
        );
}

export { ENV, STATIC_BASE_URL, WEBSOCKET_LIVE_UPDATES_URL, FEEDBACK_MANAGEMENT_BASE_URL };
