import React, { ForwardedRef } from 'react';

/**
 * HOC that serves as React.forwardRef but for class components.
 * A wrapped class component can receive the ref prop, and it will
 * be available inside the wrapped component as the upperRef prop.
 */
export const withForwardedRef = <
    R extends HTMLElement | null,
    P extends { upperRef?: ForwardedRef<R> },
>(
    WrappedComponent: React.ComponentType<P>,
) =>
    React.forwardRef<R, Exclude<P, 'upperRef'>>((props, ref) => (
        <WrappedComponent {...props} upperRef={ref} />
    ));
