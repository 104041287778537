import styled from '@emotion/styled';

export const AddedUserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;

    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: 8px;

    padding: 24px;
`;

export const AddedUserInfoTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const AddedUserInfoBottom = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;
