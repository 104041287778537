import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { setDefaultOptions } from 'date-fns';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { LanguageCodeType, SUPPORTED_LANGUAGES } from 'app/common/services/languages';

import { getDateFnsLocaleFromLocale } from '../utils/dateFns';

const DEFAULT_LANGUAGE = 'en';

/**
 * Get the corresponding supported language if it exists
 * @param lang - string of language to compare to the supported languages
 * @returns {LanguageCodeType|null} - the string corresponding to the supported language if found
 */
const getSupportedLang = (lang: string): LanguageCodeType | null =>
    SUPPORTED_LANGUAGES.includes(lang) ? lang : null;

/**
 * Get the browser language
 * @returns {string|null}
 */
const getBrowserLang = (): string | null => {
    const navigatorLang = (
        navigator.language ||
        // @ts-expect-error
        navigator.userLanguage ||
        ''
    ).toLowerCase();

    const [navigatorLangWithoutCountry, country] = navigatorLang.split('-');

    const navigatorLangWithCountry = country
        ? navigatorLangWithoutCountry.concat('-', country.toUpperCase())
        : '';

    return (
        getSupportedLang(navigatorLangWithoutCountry) ?? getSupportedLang(navigatorLangWithCountry)
    );
};

/**
 * Get the user language from the DOM if it is set
 * See getMeSaga.js where we set the 'lang' attribute
 * @returns {LanguageCodeType|null}
 */
const getUserLang = (): LanguageCodeType | null => {
    const elements = document.getElementsByTagName('html');

    if (elements && elements[0]) {
        const lang = elements[0].getAttribute('lang');
        return lang ? getSupportedLang(lang) : null;
    }

    return null;
};

const getLang = (): string => getUserLang() ?? getBrowserLang() ?? DEFAULT_LANGUAGE;

// **** Hooks versions to query language to display on the application ****

// This will likely read the browser language since the user data is not fetched
// on initial page load.
const initialLanguage = getLang();

/**
 * Returns the value of the "lang" attribute of the html element.
 *
 * This sets up a mutation observer on the html element.
 */
const useUserLang = () => {
    const lang = getUserLang() || initialLanguage;
    const [userLang, setUserLang] = useState(lang);

    if (lang != userLang) {
        setUserLang(lang);
    }

    return userLang;
};

// **** Language Context *****

const LanguageContext = createContext<LanguageContextType>(null as any);
type LanguageContextType = LanguageCodeType;

interface LanguageContextProviderProps {
    children: ReactNode;
}

export const LanguageContextProvider = ({ children }: LanguageContextProviderProps) => {
    const { data: me } = useMe();
    const meHookLang = me?.lang;

    const userLang = useUserLang();

    const language = useMemo(
        () => meHookLang || userLang || DEFAULT_LANGUAGE,
        [userLang, meHookLang],
    );

    useEffect(() => {
        const locale = getDateFnsLocaleFromLocale(language);
        setDefaultOptions({ locale: locale.locale });
    }, [language]);

    return <LanguageContext.Provider value={language}>{children}</LanguageContext.Provider>;
};

// Legacy getLang function
// If used in a component, the component might not be in synced with the language when
// it changes.
export default getLang;

// useLanguage hook
// This is to be used when a component needs to know the current language.
export const useLanguage = (): string => useContext(LanguageContext);
