import { MergedAccountsCreateModal } from './modals/MergedAccountsCreateModal';
import { MergedAccountsEditModal } from './modals/MergedAccountsEditModal';

export const MergedAccountsModals = () => {
    return (
        <>
            <MergedAccountsCreateModal />
            <MergedAccountsEditModal />
        </>
    );
};
