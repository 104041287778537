import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import createWebsocketConnection from 'admin/content/logs/common/createWebsocketConnection';

interface WebSocketContextProviderProps {
    children?: ReactNode;
}

const WebSocketContext = React.createContext<Promise<WebSocket> | null>(null);

export const WebSocketContextProvider: FC<WebSocketContextProviderProps> = ({ children }) => {
    const [websocket, setWebsocket] = useState<Promise<WebSocket> | null>(null);
    useEffect(() => setWebsocket(createWebsocketConnection()), []);
    const websocketContextValue = useMemo(() => websocket, [websocket]);
    return (
        <WebSocketContext.Provider value={websocketContextValue}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebsocket = () => useContext(WebSocketContext);
