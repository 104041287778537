import { Tooltip } from '@partoohub/ui';
import { flatten } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import ExplanationWithIcons from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithIcons';
import PublisherImg from 'app/presence/management/components/common/PublisherImage';

export type PublisherErrors = Record<string, Array<string>>;
export type PublishersInfo = Record<
    string,
    {
        formattedName: string;
        order: number;
    }
>;
type Props = {
    publisherErrors: PublisherErrors;
    publishersInfo: PublishersInfo;
    disabled?: boolean;
};

function isSameErrors(errors: Array<string>): boolean {
    return errors.every(error => error === errors[0]);
}

const ExplanationWithPublisherList = ({
    publisherErrors,
    publishersInfo,
    disabled = false,
}: Props) => {
    const { t } = useTranslation();
    const flattenErrors = flatten(Object.values(publisherErrors));
    const sameErrors = isSameErrors(flattenErrors);
    return (
        <ExplanationWithIcons
            content={Object.entries(publisherErrors)
                .sort(([p1], [p2]) => publishersInfo[p1].order - publishersInfo[p2].order)
                .map(([publisher, error]) => (
                    <Tooltip
                        text={error
                            .map(key =>
                                sameErrors
                                    ? `${publishersInfo[publisher].formattedName}`
                                    : `${publishersInfo[publisher].formattedName} :  ${t(key)}`,
                            )
                            .join('\n')}
                    >
                        <PublisherImg disabled={disabled} publisherName={publisher} />
                    </Tooltip>
                ))}
            explanation={sameErrors ? t(flattenErrors[0]) : t('field_in_error')}
        />
    );
};

export default ExplanationWithPublisherList;
