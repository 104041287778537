import { FC } from 'react';

import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

interface CancelButtonProps {
    onClick: () => void;
}
export const CancelButton: FC<CancelButtonProps> = ({ onClick }) => {
    const { t } = useTranslation();
    return (
        <Button
            dataTrackId="admin_categories_obsolete_cancelchanges"
            icon={[FontAwesomeIconsPartooUsed.faUndo, IconPrefix.SOLID]}
            onClick={onClick}
            shape="cube"
            appearance="outlined"
            variant="secondary"
        >
            {t('admin_categories_obsolete_cancelchanges')}
        </Button>
    );
};
