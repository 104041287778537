import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { feedbackFormApiClient } from 'admin/api/apiResources';
import { FEEDBACK_FORM } from 'admin/common/data/queryKeysConstants';

type FeedbackFormValues = {
    org_id: number;
    display_name: string;
    expiration_interval_seconds: number;
    external_id: string | null;
    email_reply_source: string | null;
    email_reply_subject: string | null;
    email_reply_to_business: string;
    email_reply_expeditor_name: string;
};

const formatPayload = (payload: FeedbackFormValues) => {
    return {
        external_id: payload.external_id,
        email_reply_source: payload.email_reply_source,
        email_reply_subject: payload.email_reply_subject,
        email_reply_to_business: Boolean(parseInt(payload.email_reply_to_business)),
        email_reply_expeditor_name: payload.email_reply_expeditor_name || null,
        expiration_interval_seconds: payload.expiration_interval_seconds,
        display_name: payload.display_name,
    };
};

export const useCreateOrUpdateFeedbackForm = (setError: UseFormSetError<any>) => {
    const queryClient = useQueryClient();
    const onError = (e: any) => {
        const error = e?.response?.data?.detail?.[0];
        if (!error) {
            return;
        }

        const message = error.msg;
        const field = error.loc?.[1];

        if (!message || !field) {
            return;
        }

        setError(field, { message });
    };

    const {
        mutate: createFeedbackForm,
        isLoading: createIsLoading,
        isSuccess: createIsSuccess,
    } = useMutation(
        (params: { payload: FeedbackFormValues }) => {
            return feedbackFormApiClient.createFeedbackForm({
                org_id: params.payload.org_id,
                ...formatPayload(params.payload),
            });
        },
        {
            onSuccess: ({ org_id }) => {
                queryClient.invalidateQueries([FEEDBACK_FORM, org_id]);
            },
            onError,
        },
    );

    const {
        mutate: updateFeedbackForm,
        isLoading: updateIsLoading,
        isSuccess: updateIsSuccess,
    } = useMutation(
        (params: { formId: string; payload: FeedbackFormValues }) => {
            return feedbackFormApiClient.updateFeedbackForm(
                params.formId,
                formatPayload(params.payload),
            );
        },
        {
            onSuccess: ({ org_id }) => {
                queryClient.invalidateQueries([FEEDBACK_FORM, org_id]);
            },
            onError,
        },
    );

    const createOrUpdate = (formId: string | undefined, payload: FeedbackFormValues) => {
        if (formId) {
            updateFeedbackForm({ formId, payload });
        } else {
            createFeedbackForm({ payload });
        }
    };

    return {
        createOrUpdateFeedbackForm: createOrUpdate,
        isCreatingOrUpdating: createIsLoading || updateIsLoading,
        isSuccess: createIsSuccess || updateIsSuccess,
    };
};
