export type LanguageType = {
    code: string;
    name: string;
    country: string;
    supportedForDemo: boolean;
    supportedForBulkExportBusiness: boolean;
    flag: string;
};

export const LANGUAGES_DATA: readonly LanguageType[] = [
    {
        code: 'en',
        name: 'english',
        country: 'GB', // 'EN' for categories
        supportedForDemo: true,
        supportedForBulkExportBusiness: true,
        flag: '🇬🇧',
    },
    {
        code: 'fr',
        name: 'french',
        country: 'FR',
        supportedForDemo: true,
        supportedForBulkExportBusiness: true,
        flag: '🇫🇷',
    },
    {
        code: 'es',
        name: 'spanish',
        country: 'ES',
        supportedForDemo: true,
        supportedForBulkExportBusiness: true,
        flag: '🇪🇸',
    },
    {
        code: 'it',
        name: 'italian',
        country: 'IT',
        supportedForDemo: true,
        supportedForBulkExportBusiness: true,
        flag: '🇮🇹',
    },
    {
        code: 'pt-BR',
        name: 'portuguese-brazilian',
        country: 'BR',
        supportedForDemo: false,
        supportedForBulkExportBusiness: true,
        flag: '🇧🇷',
    },
    {
        code: 'de',
        name: 'german',
        country: 'DE',
        supportedForDemo: false,
        supportedForBulkExportBusiness: true,
        flag: '🇩🇪',
    },
] as const;

export const languagesData = LANGUAGES_DATA;

export const LANGUAGES: readonly string[] = LANGUAGES_DATA.map(language => language.code);

export type LanguageCodeType = (typeof LANGUAGES)[number];

export const LANGUAGES_MAP: Record<LanguageCodeType, LanguageType> = LANGUAGES_DATA.reduce(
    (languageMap, language) => {
        languageMap[language.code] = language;
        return languageMap;
    },
    {} as Record<LanguageCodeType, LanguageType>,
);

export const SUPPORTED_LANGUAGES: readonly string[] = LANGUAGES_DATA.map(
    (language: LanguageType) => language.code,
);
