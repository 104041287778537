import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';

import {
    DiffusionCleanerBusinessPushFilter,
    DiffusionCleanerCountData,
    DiffusionCleanerCountFilter,
    DiffusionCleanerExport,
    DiffusionCleanerMissingCategoriesData,
    DiffusionCleanerMissingCategoriesParam,
    DiffusionCleanerReadableErrorData,
    DiffusionCleanerReadableErrorParam,
    DiffusionCleanerStatusData,
    DiffusionCleanerStatusFilter,
    DiffusionCleanerUnknownErrorData,
    DiffusionCleanerUnknownErrorParam,
} from 'admin/api/types';

const RESOURCE_URI = 'diffusion_cleaner';

export class DiffusionCleanerApiCall extends ApiResourceBaseClass {
    async diffusionCleanerCount(
        filter?: DiffusionCleanerCountFilter,
    ): Promise<DiffusionCleanerCountData[]> {
        const { data } = await this.get<DiffusionCleanerCountData[]>(`${RESOURCE_URI}/count`, {
            org_id: filter?.orgId,
            provider: filter?.provider,
            group__in: filter?.group__in?.length ? filter.group__in.join(',') : null,
            groups: filter?.groups?.length ? filter.groups.join(',') : null,
            publisher__in: filter?.publishers?.length ? filter.publishers.join(',') : null,
            error_key__in: filter?.errorKeys?.length ? filter.errorKeys.join(',') : null,
            ordering: filter?.orderBy || '-count',
        });
        return data;
    }

    async diffusionCleanerStatus(
        filter?: DiffusionCleanerStatusFilter,
    ): Promise<DiffusionCleanerStatusData[]> {
        const { data } = await this.get<DiffusionCleanerStatusData[]>(`${RESOURCE_URI}/status`, {
            org_id: filter?.orgId,
            provider: filter?.provider,
            publisher: filter?.publisher,
            group__in: filter?.group__in?.length ? filter.group__in.join(',') : null,
            groups: filter?.groups?.length ? filter.groups.join(',') : null,
            error_key: filter?.errorKey,
            page: filter?.page,
        });
        return data;
    }

    async businessPush(filter: DiffusionCleanerBusinessPushFilter): Promise<void> {
        await this.post(`${RESOURCE_URI}/business_push`, {
            business_id: filter.businessId,
            diffuser: filter.diffuser,
        });
    }

    async bulkPush(filter: DiffusionCleanerStatusFilter): Promise<void> {
        await this.post(`${RESOURCE_URI}/bulk_push`, {
            org_id: filter?.orgId,
            provider: filter?.provider,
            publisher: filter?.publisher,
            group__in: filter?.groups?.length ? filter.groups.join(',') : null,
            error_key: filter?.errorKey,
        });
    }

    async getMissingCategories(
        params: DiffusionCleanerMissingCategoriesParam,
    ): Promise<DiffusionCleanerMissingCategoriesData[]> {
        const { data } = await this.get<DiffusionCleanerMissingCategoriesData[]>(
            `${RESOURCE_URI}/missing_categories`,
            params,
        );
        return data;
    }

    async getCallLog(
        params: DiffusionCleanerReadableErrorParam,
    ): Promise<DiffusionCleanerReadableErrorData> {
        const { data } = await this.get<DiffusionCleanerReadableErrorData>(
            `${RESOURCE_URI}/readable_error`,
            params,
        );
        return data;
    }

    async getResponseContent(
        params: DiffusionCleanerUnknownErrorParam,
    ): Promise<DiffusionCleanerUnknownErrorData> {
        const { data } = await this.get<DiffusionCleanerUnknownErrorData>(
            `${RESOURCE_URI}/unknown_error`,
            params,
        );
        return data;
    }

    async diffusionCleanerExport(params: DiffusionCleanerExport): Promise<SuccessResponse> {
        const { data } = await this.get<SuccessResponse>(`${RESOURCE_URI}/exporter`, {
            org_id: params?.orgId,
            provider: params?.provider,
            group__in: params?.group__in?.length ? params.group__in : null,
            groups: params?.groups?.length ? params.groups.join(',') : null,
            publisher__in: params?.publishers?.length ? params.publishers.join(',') : null,
            error_key__in: params?.errorKeys?.length ? params.errorKeys.join(',') : null,
            lang: params?.lang,
        });
        return data;
    }
}
