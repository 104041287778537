import { Chip, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BusinessReachEnum } from 'app/api/types/business';
import { isBusinessOpeningSoon } from 'app/businessEditV2/utils/businessOpeningSoon';
import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import { BusinessWithSelected } from 'app/common/components/businessModal/hooks/privateHooks/useBusinessModalDisplayedBusinesses';
import { CLOSED, CLOSED_TEMPORARILY } from 'app/common/data/businessStatuses';

import { getMappedUrl, resizeImage } from 'app/utils/resizeImage';

import {
    BusinessCardAddress,
    BusinessCardComponent,
    BusinessCardContentBottom,
    BusinessCardInfos,
    BusinessCardPhoto,
    BusinessCardTitle,
    BusinessReach,
} from './BusinessCard.styled';

type Props = {
    business: BusinessWithSelected;
};

const BusinessCard = ({ business }: Props) => {
    const { t } = useTranslation();
    const { toggleBusiness } = useBusinessModalContext();

    const businessPhoto = resizeImage(
        getMappedUrl(business.logo_url ?? '', business?.media_mapping),
        100,
        100,
    );

    const isOpeningSoon = isBusinessOpeningSoon(business);

    return (
        <BusinessCardComponent
            onClick={() => toggleBusiness(business)}
            selected={business.selected}
        >
            <BusinessCardPhoto url={businessPhoto}>
                {!businessPhoto && <i className="fa-solid fa-image" />}
            </BusinessCardPhoto>
            <BusinessCardInfos>
                <BusinessCardTitle>{business.name}</BusinessCardTitle>
                <BusinessCardContentBottom>
                    {business.reach === BusinessReachEnum.GLOBAL ? (
                        <BusinessReach>
                            <Text variant="bodySBold">{t('business_reach__global')}</Text>
                        </BusinessReach>
                    ) : (
                        <BusinessCardAddress>
                            {business.address_full}
                            <br />
                            {business.zipcode} {business.city}
                        </BusinessCardAddress>
                    )}
                    {business.status === CLOSED && (
                        <Chip
                            chipShape="cube"
                            iconPosition="right"
                            variant="danger"
                            dataTrackId={'search-companion-closed-chip'}
                        >
                            {t('business_closed')}
                        </Chip>
                    )}
                    {business.status === CLOSED_TEMPORARILY && (
                        <Chip
                            chipShape="cube"
                            variant="danger"
                            dataTrackId={'search-companion-closed-chip'}
                        >
                            {t(CLOSED_TEMPORARILY)}
                        </Chip>
                    )}
                    {isOpeningSoon && (
                        <Chip
                            chipShape="cube"
                            variant="primary"
                            dataTrackId={'search-companion-opening-soon-chip'}
                        >
                            {t('business_opening_soon')}
                        </Chip>
                    )}
                </BusinessCardContentBottom>
            </BusinessCardInfos>
        </BusinessCardComponent>
    );
};

export default BusinessCard;
