import { FC } from 'react';

import { Filters } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

type Props = {
    action: Record<string, boolean>;
    placeholder: string;
    setAction: (selectedItems: Record<string, boolean>) => void;
    dataTrackId: string;
};

export const PossibleActionsEnum = {
    ASSOCIATE_BUSINESSES: 'associate-to-org-businesses',
    DISSOCIATE_BUSINESSES: 'dissociate-from-all-businesses',
    ALLOW_BULK: 'allow-bulk-modif',
    FORBID_BULK: 'forbid-bulk-modif',
    DISPLAY_PRODUCTS: 'display-multiple-products',
    HIDE_PRODUCTS: 'hide-multiple-products',
    MIGRATE_ORG: 'migrate-to-a-new-organization',
    DELETE: 'delete',
};

const ActionSelect: FC<Props> = ({ action, placeholder, setAction, dataTrackId }: Props) => {
    const { t } = useTranslation();
    const options = [
        PossibleActionsEnum.ASSOCIATE_BUSINESSES,
        PossibleActionsEnum.DISSOCIATE_BUSINESSES,
        PossibleActionsEnum.ALLOW_BULK,
        PossibleActionsEnum.FORBID_BULK,
        PossibleActionsEnum.DISPLAY_PRODUCTS,
        PossibleActionsEnum.HIDE_PRODUCTS,
        PossibleActionsEnum.MIGRATE_ORG,
        PossibleActionsEnum.DELETE,
    ].map(action => ({
        value: action,
        label: t('admin:user_actions__select_' + action),
        name: action,
    }));
    const section = [{ options: options }];

    return (
        <Filters
            dataTrackId={dataTrackId}
            menuListArray={section}
            selectedItems={action}
            onChange={setAction}
            placeholder={placeholder}
            position="bottom"
            maxHeight={300}
        />
    );
};

export default ActionSelect;
