import { RefObject } from 'react';

interface AppLinkIconProps {
    ref?: RefObject<SVGSVGElement>;
}

export const AppLinkIcon = ({ ref }: AppLinkIconProps) => (
    <svg
        ref={ref}
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M11.4 8.40898H12.6C12.8144 8.40898 13.0125 8.29461 13.1196 8.10898C13.2268 7.92336 13.2268 7.69461 13.1196 7.50898C13.0125 7.32336 12.8144 7.20898 12.6 7.20898H11.4C11.1856 7.20898 10.9875 7.32336 10.8803 7.50898C10.7732 7.69461 10.7732 7.92336 10.8803 8.10898C10.9875 8.29461 11.1856 8.40898 11.4 8.40898Z" />
        <path d="M22.2 15.5998H21.6V6.5998C21.5995 6.12262 21.4097 5.66497 21.0722 5.32763C20.7348 4.99013 20.2772 4.80035 19.8 4.7998H4.20001C3.72282 4.80034 3.26518 4.99012 2.92784 5.32763C2.59034 5.665 2.40056 6.12265 2.40001 6.5998V15.5998H1.80001C1.64091 15.5998 1.48822 15.663 1.37573 15.7755C1.26323 15.888 1.20001 16.0407 1.20001 16.1998V17.3998C1.20055 17.877 1.39033 18.3346 1.72784 18.672C2.06521 19.0095 2.52286 19.1993 3.00001 19.1998H21C21.4772 19.1993 21.9348 19.0095 22.2722 18.672C22.6097 18.3346 22.7995 17.877 22.8 17.3998V16.1998C22.8 16.0407 22.7368 15.888 22.6243 15.7755C22.5118 15.663 22.3591 15.5998 22.2 15.5998ZM4.20001 5.9998H19.8C19.9591 5.99994 20.1117 6.06329 20.2242 6.17565C20.3365 6.28815 20.3999 6.4407 20.4 6.5998V15.5998H3.60001V6.5998C3.60014 6.4407 3.66349 6.28815 3.77586 6.17565C3.88836 6.06329 4.04091 5.99994 4.20001 5.9998ZM21.6 17.3998C21.5999 17.5589 21.5365 17.7115 21.4242 17.824C21.3117 17.9363 21.1591 17.9997 21 17.9998H3.00002C2.84091 17.9997 2.68837 17.9363 2.57587 17.824C2.4635 17.7115 2.40015 17.5589 2.40002 17.3998V16.7998H21.6L21.6 17.3998Z" />
    </svg>
);
