import React from 'react';

import { Button, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import { DiffusionCleanerAdminApiCall } from 'admin/common/api/calls/diffusionCleanerApiCalls';

type Props = {
    provider?: string;
    orgId?: string;
    publisher?: string;
    groups: string[];
    errorKey: string;
};

export const BulkPushButton: React.FC<Props> = ({
    provider,
    orgId,
    publisher,
    groups,
    errorKey,
}) => {
    const { t } = useTranslation();

    const { mutate, isLoading } = useMutation(DiffusionCleanerAdminApiCall.bulkPush, {
        onSuccess: () => {
            toast.success('', t('admin:page_diffusion_cleaner__bulk_push_button__success'));
        },
        onError: (error: any) => {
            toast.error(error.message, t('admin:page_diffusion_cleaner__bulk_push_button__error'));
        },
    });

    const onPush = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        mutate({
            provider,
            orgId,
            publisher,
            groups,
            errorKey,
        });
    };
    return (
        <Button
            appearance="outlined"
            shape="rounded"
            size="medium"
            variant="primary"
            dataTrackId="admin_diffusion_cleaner__push_bulk_button"
            onClick={onPush}
            disabled={isLoading}
        >
            <Text variant="bodyMBold" color={'primary'}>
                {t('admin:page_diffusion_cleaner__bulk_button__push')}
            </Text>
        </Button>
    );
};
