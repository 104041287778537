import React, { FC, ReactNode, useCallback, useState } from 'react';

import {
    CollapsibleContent,
    CollapsibleContentContainer,
    CollapsibleTitle,
    DropdownIcon,
    GearContainer,
    TitleText,
} from './AdvancedSettings.styled';

type Props = {
    title: string;
    children?: ReactNode;
};

const AdvancedSettings: FC<Props> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    return (
        <>
            <CollapsibleTitle onClick={toggleOpen}>
                <GearContainer>
                    <i className="fa-solid fa-gear" />
                </GearContainer>
                <TitleText>{title}</TitleText>
                <DropdownIcon open={isOpen}>
                    <i className="fa-solid fa-chevron-right" />
                </DropdownIcon>
            </CollapsibleTitle>
            <CollapsibleContentContainer open={isOpen}>
                <CollapsibleContent>{children}</CollapsibleContent>
            </CollapsibleContentContainer>
        </>
    );
};

export default AdvancedSettings;
