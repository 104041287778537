import { ApiResourceBaseClass } from '@partoohub/api-client';

import {
    BulkImportLog,
    DailyReportData,
    DailyReportLog,
    DailyReportLogsAdminFilter,
    ImportLogsAdminFilter,
    LogsReportLink,
    SearchLogsReceivedData,
} from 'admin/api/types';

const RESOURCE_URI = 'logs';

export class LogsApiCall extends ApiResourceBaseClass {
    async dailyReports(
        filter?: DailyReportLogsAdminFilter,
    ): Promise<SearchLogsReceivedData<DailyReportLog>> {
        const { data } = await this.get<SearchLogsReceivedData<DailyReportLog>>(`${RESOURCE_URI}`, {
            type: 'daily_report',
            ...filter,
        });
        return data;
    }

    async dailyReportData(id: string): Promise<DailyReportData[]> {
        const { data } = await this.get<DailyReportData[]>(`daily_report_data/${id}`);
        return data;
    }

    async dailyReportLink(id: string): Promise<string> {
        const { data } = await this.get<LogsReportLink>(`daily_reports/${id}`);
        return data.url;
    }

    async bulkImportReportLink(id: string) {
        const { data } = await this.get<LogsReportLink>(`import_log/${id}/report`);
        return data.url;
    }

    async bulkImportInputLink(id: string) {
        const { data } = await this.get<LogsReportLink>(`import_log/${id}/file`);
        return data.url;
    }

    async getBulkImportLog(
        filter?: ImportLogsAdminFilter,
    ): Promise<SearchLogsReceivedData<BulkImportLog>> {
        const { data } = await this.get<SearchLogsReceivedData<BulkImportLog>>(`${RESOURCE_URI}`, {
            type: 'bulk_import',
            ...filter,
        });
        return data;
    }
}
