import { FontAwesomeIconsPartooUsed, IconPrefix, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { FeedbackFormData } from 'app/api/types/feedbackForm';
import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';

import { CreateOrUpdateSurveyContent } from './CreateOrUpdateSurveyContent/CreateOrUpdateSurveyContent';
import {
    RightPart,
    StyledFullScreen,
    StyledIconButton,
} from './CreateOrUpdateSurveyFullModal.styled';

type Props = {
    show: boolean;
    onHideClick: () => void;
    orgId: number;
    survey?: FeedbackFormData;
};

export const CreateOrUpdateSurveyFullModal = ({ show, onHideClick, orgId, survey }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={show} closeModal={onHideClick} placement="center" animation="fadeIn">
            <StyledFullScreen>
                <CreateOrUpdateSurveyContent
                    orgId={orgId}
                    survey={survey}
                    onHide={onHideClick}
                    {...(survey && { survey })}
                />
                <RightPart>
                    <EditFormDescription
                        title={t('admin:feedback_management__modal_setup_survey_title')}
                        description={t('admin:feedback_management__modal_setup_survey_description')}
                        advice={t('admin:feedback_management__modal_our_advice_content')}
                    />
                </RightPart>
                <StyledIconButton
                    onClick={onHideClick}
                    icon={[FontAwesomeIconsPartooUsed.faXMarkLarge, IconPrefix.SOLID]}
                    dataTrackId="close_fullscreen_modal_button"
                />
            </StyledFullScreen>
        </Modal>
    );
};
