import { Text } from '@partoohub/ui';

import { useQuery } from 'react-query';

import { diffusionCleanerApiClient } from 'admin/api/apiResources';
import { DIFFUSION_CLEANER_READABLE_ERROR } from 'admin/common/data/queryKeysConstants';

import { useMe } from 'admin/common/hooks/queries/useMe';

type Props = {
    call_log_id: number;
};

export const ResponseContentComponent = ({ call_log_id }: Props) => {
    const me = useMe();
    const { isSuccess, data } = useQuery({
        queryKey: [DIFFUSION_CLEANER_READABLE_ERROR, call_log_id],
        queryFn: () =>
            diffusionCleanerApiClient.getResponseContent({
                call_log_id,
            }),
        enabled: me.isSuccess,
    });

    return <>{isSuccess && <Text variant="bodyLRegular">{data.response_content}</Text>}</>;
};
