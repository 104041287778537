import qs from 'query-string';
import { useLocation } from 'react-router-dom';

/**
 * Get a search param from the current location
 */
export const useSearchParam = (name: string): string => getSearchParam(useLocation().search, name);

/**
 * Imperative version for usage in non-hooks environments (sagas, global scope)
 */
export const getSearchParam = (search: string, paramName: string): string => {
    // Using the query-string library to parse search params
    // as react-router v5 doesn't parse the search params.
    // query-string, over other alternatives is said to
    // be "the best", as it can for example handle storing
    // arrays of data in the url, parse numbers, booleans,
    // etc. (these features are not used here)
    // see https://github.com/remix-run/react-router/issues/4410#issuecomment-302577498
    const { [paramName]: value } = qs.parse(search);

    // if the same search param is present multiple times,
    // query-string returns an array, then take the first
    // one
    const nonArray = Array.isArray(value) ? value[0] : value;

    // if the search param is present but has no value,
    // query-string returns null, let's return an empty
    // string here for easier typing when using this
    // hook
    return nonArray || '';
};
