import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';

import { CreateDemoPayload } from 'admin/api/types';

const RESOURCE_URI = 'demos';

export class DemoCreatorAdminApiCall extends ApiResourceBaseClass {
    async createDemo({ org, lang }: CreateDemoPayload): Promise<SuccessResponse> {
        const { data } = await this.post<SuccessResponse>(RESOURCE_URI, {
            org_id: org,
            lang,
        });
        return data;
    }
}
