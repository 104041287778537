import { UploadImageResponse } from '@partoohub/api-client';
import { useMutation } from 'react-query';

import { imageApiClient } from 'admin/api/apiResources';

const formatImagesForAPI = (photos: Array<File>): FormData =>
    photos.reduce((form: FormData, photo) => {
        form.append(photo.name, photo);
        return form;
    }, new FormData());

export function usePhotosUpload(
    onSuccess: (data: UploadImageResponse) => void = () => undefined,
    onError: (error: any) => void = () => undefined,
) {
    return useMutation(
        (photos: Array<File>) => imageApiClient.uploadImage(formatImagesForAPI(photos)),
        {
            onSuccess,
            onError,
        },
    );
}
