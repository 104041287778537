import { SearchBusinessParams } from '@partoohub/api-client';
import { UseInfiniteQueryOptions } from 'react-query';
import { useSelector } from 'react-redux';

import { SearchBusinessesReceivedData } from 'app/api/types/business';
import { filterByBusinessSelector } from 'app/common/components/businessModalDeprecated/reducers';
import { useBusinesses } from 'app/common/hooks/queries/useBusinesses';

/**
 * Takes additional filters as params but sets at least the filters found in the Redux
 * store (they're still overridable though). It's necessary as some pages show only a
 * subset of businesses in the business drawer (for example Posts page shows only
 * businesses partner connected).
 */
export default function useReduxFilteredBusinesses(
    filters: SearchBusinessParams = {},
    options: UseInfiniteQueryOptions<SearchBusinessesReceivedData> = {},
) {
    const filterByBusinessState = useSelector(filterByBusinessSelector);

    const businessSearchFilters = {
        // Search filters in redux, provided by the page
        ...filterByBusinessState.searchFilters,

        // Custom filters to override other filters if needed, usually provided by the business modal
        ...filters,
    };

    return useBusinesses(businessSearchFilters as SearchBusinessParams, options);
}
