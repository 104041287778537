import { SuccessResponse } from 'app/api/types';
import {
    FeedbackResultQueryParams,
    ReceivedFeedbackResultsData,
    ReplyFeedbackResultsPayload,
    ReplyFeedbackResultsResponse,
} from 'app/api/types/feedbackResults';
import { CursorPaginationParams, ReplySuggestion } from 'app/api/types/review';

import ApiResourceBaseClass from './baseApiCalls';
import formatParams from './utils';

const RESOURCE_URI = 'feedback/feedback_result';

export class FeedbackResults extends ApiResourceBaseClass {
    static async searchFeedbackResults(
        queryParams: FeedbackResultQueryParams & CursorPaginationParams,
    ): Promise<ReceivedFeedbackResultsData> {
        const { data } = await FeedbackResults.get(`${RESOURCE_URI}`, formatParams(queryParams));
        return data;
    }

    static async downloadFeedbackResults(
        queryParams: FeedbackResultQueryParams & { email: string; feedback_form_ids: string[] },
    ): Promise<SuccessResponse> {
        const { data } = await FeedbackResults.get(
            `${RESOURCE_URI}/download`,
            formatParams(queryParams),
        );
        return data;
    }

    static async getReplySuggestion(
        feedbackResultId: number,
        index: number,
    ): Promise<ReplySuggestion> {
        const { data } = await FeedbackResults.get(
            `${RESOURCE_URI}/fetch-suggestion/${feedbackResultId}/${index}`,
        );
        return data;
    }

    static async reportSuggestion(suggestionId: number): Promise<{ [key: string]: string }> {
        const { data } = await FeedbackResults.post(`${RESOURCE_URI}/report-suggestion`, {
            suggestion_id: suggestionId,
        });
        return data;
    }

    static async replyFeedbackResult(
        feedbackResultId: number,
        payload: ReplyFeedbackResultsPayload,
    ): Promise<ReplyFeedbackResultsResponse> {
        const { data } = await FeedbackResults.post(
            `${RESOURCE_URI}/${feedbackResultId}/reply`,
            payload,
        );
        return data;
    }

    static async deleteFeedbackResult(
        feedbackResultId: number,
    ): Promise<ReplyFeedbackResultsResponse> {
        const { data } = await FeedbackResults.delete(`${RESOURCE_URI}/${feedbackResultId}`);
        return data;
    }
}
