import { BUSINESS, CUSTOM_FIELDS } from 'app/common/data/queryKeysConstants';

export const findFirstString = (error): string | null => {
    if (typeof error === 'string') {
        return error;
    }
    if (typeof error === 'object') {
        for (const [, val] of Object.entries(error)) {
            const value = findFirstString(val);
            if (typeof value === 'string') {
                return value;
            }
        }
        return null;
    }
    return null;
};

export const invalidatesAny = (...args) => ({
    predicate: query => args.some(arg => query.queryKey.includes(arg)),
});

export const getNumberOfAdditionalCategories = (categories: Array<any>) =>
    categories && categories.length ? categories.length - 1 : 0;

export enum SectionNames {
    COVER = 'cover',
    LOGO = 'logo',
    ADDRESS = 'address',
    OPEN_HOURS = 'open-hours',
    SPECIFIC_HOURS = 'specific-hours',
    MORE_HOURS = 'more-hours',
    PHOTOS = 'photos',
    DESCRIPTIONS = 'descriptions',
    CATEGORIES = 'categories',
    ATTRIBUTES = 'attributes',
    SERVICES = 'services',
    MENU = 'menu',
    CONTACT = 'contact',
    ADVANCED_SETTINGS = 'advanced-settings',
    LINKS = 'links',
    CUSTOM_FIELDS = 'custom-fields',
}

export const getBusinessRequestKey = (businessId?: string): Array<any> => [
    BUSINESS,
    CUSTOM_FIELDS,
    { businessId },
];

export function errorMaxCharacters(value: string, maxLen?: number | null) {
    return maxLen ? !!(value && value.length > maxLen) : false;
}

export const handlePhoneValue = (value?: string) => (value ?? '').replace(/([^-+()\d]*)/g, '');
