import { Filters } from '@partoohub/ui';

import { ERROR, FIELD_ERROR, PENDING, SUCCESS } from 'admin/common/data/constants/publisherStates';

type Props = {
    state: Record<string, boolean>;
    setState: (selectedItems: Record<string, boolean>) => void;
};

const options = [
    { value: SUCCESS, label: 'Success', name: SUCCESS },
    { value: PENDING, label: 'Pending', name: PENDING },
    { value: FIELD_ERROR, label: 'Field Error', name: FIELD_ERROR },
    { value: ERROR, label: 'External Error', name: ERROR },
];
const section = [{ options: options }];

export const StateSelect = ({ state, setState }: Props) => (
    <Filters
        dataTrackId="overriding-state-type"
        menuListArray={section}
        selectedItems={state}
        onChange={setState}
        placeholder="State (Without selected state, remove current overriding state)"
        position="bottom"
        maxHeight={300}
    />
);
