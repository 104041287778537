import { Icon, IconElement, Stack, Text } from '@partoohub/ui';

import TooltipWrapper from './TooltipWrapper';

interface MenuLabelItemProps {
    text: string;
    tooltipText?: string;
    icon?: IconElement;
    subtitle?: string;
    color?: string;
}

export const MenuLabelItem = ({
    text,
    tooltipText,
    icon,
    subtitle,
    color = 'default',
}: MenuLabelItemProps) => {
    const MenuLabelItemComponent = () => {
        return (
            <Stack direction="row" alignItems="center">
                {icon && <Icon icon={icon} color={color} />}
                <Stack gap="0">
                    <Text variant="bodyMMedium" as="span" color={color}>
                        {text}
                    </Text>
                    <Text
                        variant="bodySMedium"
                        as="span"
                        color={color !== 'default' ? color : 'secondary'}
                    >
                        {subtitle}
                    </Text>
                </Stack>
            </Stack>
        );
    };

    return tooltipText ? (
        <TooltipWrapper text={tooltipText} position="bottom-start">
            <MenuLabelItemComponent />
        </TooltipWrapper>
    ) : (
        <MenuLabelItemComponent />
    );
};
