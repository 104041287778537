import React from 'react';

import { Text } from '@partoohub/ui';

import { ColumnHeadingText, ColumnHeadingTitle } from './ColumnHeadingsContainer.styled';

type Props = {
    title: string;
};

export const ColumnHeading = ({ title }: Props) => {
    return (
        <ColumnHeadingText>
            <Text variant="bodyXSSemibold" as="span" color="#7F8EA4">
                <ColumnHeadingTitle>{title}</ColumnHeadingTitle>
            </Text>
        </ColumnHeadingText>
    );
};
