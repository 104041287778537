import styled from '@emotion/styled';

import { HumanErrorMessageComponent } from './HumanErrorMessage';

export const DrawerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const DrawerHeading = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 300px;
`;

export const HumanErrorMessage = styled(HumanErrorMessageComponent)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
`;

export const ReadableErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;
