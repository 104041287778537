import styled from '@emotion/styled';

export const Category = styled.div`
    display: flex;
    flex-direction: row;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-items: stretch;

    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
`;

export const DrawerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const DrawerHeading = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 300px;
`;

export const CategoryList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
`;

export const CategoryNameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;
