import React from 'react';

import { Button, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
};
export const GBPLinkButton: React.FC<Props> = ({ name, address, zipcode, city, country }) => {
    const { t } = useTranslation();

    const search = `${name} ${address} ${zipcode} ${city} ${country}`.replace(/\s/g, '+');
    const gbpSearchLink = `https://business.google.com/locations/search?q=${search}`;

    return (
        <Button
            appearance="outlined"
            shape="rounded"
            size="medium"
            variant="secondary"
            dataTrackId="admin_diffusion_cleaner__gbp_search_business_button"
            onClick={() => window.open(gbpSearchLink, '_blank', 'noreferrer')}
        >
            <Text variant="bodyMBold">
                {t('admin:page_diffusion_cleaner__business_button__gbp_search')}
            </Text>
        </Button>
    );
};
