import { SuccessResponse } from '@partoohub/api-client';

import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

const RESOURCE_URI = 'demos';

export class DemoCreatorAdminApiCall extends ApiResourceBaseClass {
    static createDemo({ org, lang }: { org: string; lang: string }): Promise<SuccessResponse> {
        return DemoCreatorAdminApiCall.post(`${RESOURCE_URI}`, {
            org_id: org,
            lang,
        }).then(({ data }) => data);
    }
}
