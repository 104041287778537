import React from 'react';

type Props = {
    onClick: (arg0: Event) => void;
    onBlur?: (arg0: Event) => void;
    children?: React.ReactNode;
    className?: string | null;
    disabled?: boolean;
    dataCy?: string;
    style?: Record<string, string>;
    dataTrack?: string;
};

const Clickable = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            onClick,
            onBlur = undefined,
            children = undefined,
            className = '',
            disabled = false,
            dataCy,
            style = {},
            dataTrack,
        }: Props,
        ref,
    ) => (
        <div
            ref={ref}
            // @ts-ignore
            onClick={onClick}
            // @ts-ignore
            onBlur={onBlur}
            // @ts-ignore
            onKeyPress={onClick}
            role="button"
            className={`clickable ${className}`}
            disabled={disabled}
            data-cy={dataCy}
            style={style}
            data-track={dataTrack}
            data-intercom-target={dataTrack}
        >
            {children}
        </div>
    ),
);

export default Clickable;
