import React, { ReactNode, useMemo } from 'react';

import { useImmer } from 'use-immer';

interface SectionT {
    opened: boolean;
}

type Sections = Record<string, SectionT>;

export const ToggleMenuContext = React.createContext<ToggleMenuContextType>(
    null as any,
);

interface ToggleMenuContextType {
    toggleSection: (sectionId: string) => void;
    registerSection: (sectionId: string) => void;
    unregisterSection: (sectionId: string) => void;
    sections: Sections;
}

interface ToggleMenuContextProviderProps {
    children: ReactNode;
}

/**
 * The whole point of this context is to make the Menu aware of its
 * child structure (sections and items). That way we can compute the
 * menu height based on the number of items per sections, and which
 * section is opened. Once we have the desired height, we can animate
 * it using a CSS transition.
 *
 * Also this holds only because every items inside the menu has a
 * fixed height. If one of these would have height: auto (the default)
 * it would be ~~more complicated~~ impossible to animate.
 *
 * It also preforms state management for the opened sections : when
 * we click on a section, we open it and close all others.
 *
 * This is basically a trade-off between component composition and
 * letting the user of the component provide a large data objet that
 * generates the sections and items. Here we basically avoid the user
 * of the component to provide such a large object and that makes the
 * state management simpler.
 */
export const ToggleMenuContextProvider = ({
    children,
}: ToggleMenuContextProviderProps) => {
    const [sections, updateSections] = useImmer<Sections>({});

    const registerSection = (sectionId: string) => {
        updateSections(sections => {
            if (sections[sectionId] !== undefined) {
                return;
            }
            sections[sectionId] = { opened: false };
        });
    };

    const unregisterSection = (sectionId: string) => {
        updateSections(sections => {
            delete sections[sectionId];
        });
    };

    const toggleSection = (sectionId: string) => {
        updateSections(sections => {
            if (sections[sectionId] === undefined) {
                return;
            }
            const prevValue = sections[sectionId].opened;
            // close all other sections
            for (const s of Object.keys(sections)) {
                sections[s].opened = false;
            }
            sections[sectionId].opened = !prevValue;
        });
    };

    const contextValue = useMemo(
        () => ({
            toggleSection,
            registerSection,
            unregisterSection,
            sections,
        }),
        [sections],
    );

    return (
        <ToggleMenuContext.Provider value={contextValue}>
            {children}
        </ToggleMenuContext.Provider>
    );
};
