import { Choice } from 'app/api/types/user';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'address';

class Address extends ApiResourceBaseClass {
    static getCountry(lang: string): Promise<Array<Choice>> {
        return Address.get(`${RESOURCE_URI}/country/${lang}`).then(
            ({ data }) => data,
        );
    }
}

export default Address;
