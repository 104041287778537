import { PropsWithChildren, RefObject } from 'react';

type Props = {
    onClick: (arg0: Event) => void;
    onBlur?: (arg0: Event) => void;
    className?: string | null;
    disabled?: boolean;
    dataCy?: string;
    style?: Record<string, string>;
    dataTrack?: string;
    ref?: RefObject<HTMLDivElement>;
};

/**
 * @deprecated Please stop using this component and use the Button component instead
 */
const Clickable = ({
    onClick,
    onBlur = undefined,
    children = undefined,
    className = '',
    disabled = false,
    dataCy,
    style = {},
    dataTrack,
    ref,
}: PropsWithChildren<Props>) => (
    <div
        ref={ref}
        // @ts-ignore
        onClick={onClick}
        // @ts-ignore
        onBlur={onBlur}
        // @ts-ignore
        onKeyPress={onClick}
        role="button"
        aria-label={`Clickable ${dataTrack}`}
        className={`clickable ${className}`}
        disabled={disabled}
        data-cy={dataCy}
        style={style}
        data-track={dataTrack}
        data-intercom-target={dataTrack}
    >
        {children}
    </div>
);

export default Clickable;
