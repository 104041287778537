import { BusinessFieldsType } from 'app/api/types/business';
import { FuzzySearchProviderReceivedData, ProviderType } from 'app/api/types/providerType';

import ApiResourceBaseClass from './baseApiCalls';
import { SuccessResponse } from '../../types';

const RESOURCE_URI = 'provider';

class Provider extends ApiResourceBaseClass {
    static getBusinessEditFields(label: string): Promise<BusinessFieldsType> {
        return Provider.get(`${RESOURCE_URI}/${label}/business_fields`).then(({ data }) => data);
    }

    static updateBusinessEditFields(
        label: string,
        businessFields: BusinessFieldsType,
    ): Promise<SuccessResponse> {
        return Provider.post(`${RESOURCE_URI}/${label}/business_fields`, businessFields).then(
            ({ data }) => data,
        );
    }

    static search(q: string, page?: number): Promise<Array<ProviderType>> {
        return Provider.get(`${RESOURCE_URI}/search`, { q, page }).then(
            ({ data }) => data.providers,
        );
    }
    static searchProviders(q: string, page?: number): Promise<FuzzySearchProviderReceivedData> {
        return Provider.get(`${RESOURCE_URI}/search`, { q, page }).then(({ data }) => data);
    }
}

export default Provider;
