import { useEffect, useMemo } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Modal, Stack, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import ReviewApiCalls from 'admin/common/api/calls/reviewApiCalls';

import { ReviewCleanerContent } from './ReviewCleanerConfirmModal.styled';

type Props = {
    show?: boolean;
    businessId: string;
    title: string;
    hide: () => void;
};

const REVIEW_CLEANER_QUERY_KEY = 'REVIEW_CLEANER';

export default function ({ businessId, title, hide, show }: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { data, isLoading } = useQuery(
        [REVIEW_CLEANER_QUERY_KEY, businessId],
        () => {
            return ReviewApiCalls.getWrongGmbLocationCount(businessId);
        },
        {
            staleTime: 60 * 10 * 1000, // 10 minutes
            enabled: show && !!businessId,
            retry: false,
        },
    );

    useEffect(() => {
        return () => {
            queryClient.invalidateQueries(REVIEW_CLEANER_QUERY_KEY);
        };
    }, []);

    const incorrectReviewsCount = data ? data.count : 0;

    const handleConfirm = () => {
        if (incorrectReviewsCount > 0) {
            ReviewApiCalls.cleanGmbReviews(businessId)
                .then(response => {
                    toast.success(
                        t('admin:page_review_cleaner__success_toast_description', {
                            count: response.deleted,
                        }),
                        t('success'),
                    );
                })
                .catch(() => {
                    toast.error('', t('admin:toast_error__title'));
                })
                .finally(() => {
                    hide();

                    queryClient.invalidateQueries({
                        queryKey: [REVIEW_CLEANER_QUERY_KEY, businessId],
                        refetchActive: false,
                    });
                });

            return;
        }

        hide();
    };

    const content = useMemo(() => {
        if (isLoading) {
            return <div>{t('loading')}</div>;
        }

        if (incorrectReviewsCount == 0) {
            return <div>{t('admin:page_review_cleaner__reviews_ok')}</div>;
        }

        return (
            <>
                <div>
                    {t('admin:page_review_cleaner__reviews_nok_count', {
                        count: incorrectReviewsCount,
                    })}
                </div>
                <div>{t('admin:page_review_cleaner__reviews_clean_confirm')}</div>
            </>
        );
    }, [isLoading, incorrectReviewsCount]);

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name:
            incorrectReviewsCount === 0 ? IllustrationOldEnum.Success : IllustrationOldEnum.Delete,
    };

    return (
        // <ConfirmModal
        //     show={show}
        //     imageType={'warning'}
        //     handleHide={hide}
        //     handleConfirm={handleConfirm}
        //     confirmType={'confirm'}
        //     ribbonState={
        //         isLoading
        //             ? 'disabled'
        //             : incorrectReviewsCount === 0
        //                 ? 'success'
        //                 : 'danger'
        //     }
        //     title={title}
        // >
        //     {content}
        // </ConfirmModal>
        <Modal isOpen={show} closeModal={hide}>
            <Dialog
                dataTrackId=""
                header={
                    <div style={{ paddingTop: '24px' }}>
                        <Illustration
                            dataTrackId=""
                            illustration={illustrationElement}
                            width="320"
                            height="140"
                        />
                    </div>
                }
                title={title}
            >
                <ReviewCleanerContent justifyContent="center" alignItems="center">
                    {content}
                </ReviewCleanerContent>
                <Stack direction="row">
                    <Button
                        dataTrackId="review_cleaner__cancel"
                        variant="secondary"
                        size="large"
                        onClick={hide}
                        appearance="outlined"
                        full
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        dataTrackId="review_cleaner__save"
                        variant={incorrectReviewsCount === 0 ? 'success' : 'danger'}
                        size="large"
                        onClick={handleConfirm}
                        full
                        disabled={isLoading}
                    >
                        {incorrectReviewsCount === 0 ? t('confirm') : t('delete')}
                    </Button>
                </Stack>
            </Dialog>
        </Modal>
    );
}
