import { FontAwesomeIconElement, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { TFunction } from 'i18next';

enum BulkTypeEnum {
    BUSINESS = 'business',
    USER = 'user',
    REPLY_TEMPLATE = 'reply_template',
}

enum BulkFormatEnum {
    DEFAULT = 'default',
    MORE_HOURS = 'more_hours',
    MESSAGE_SETTINGS = 'message_settings',
    OLD_IMPORT = 'old_import',
}
interface ImportLogData {
    id: string;
    bulkType: BulkTypeEnum;
    bulkFormat: BulkFormatEnum;
    name: string;
    icon: FontAwesomeIconElement;
}

export const translateImportLogData = (t: TFunction): ImportLogData[] => {
    return [
        {
            id: 'main_information',
            bulkType: BulkTypeEnum.BUSINESS,
            bulkFormat: BulkFormatEnum.DEFAULT,
            name: t('admin:page_import_log__main_information'),
            icon: [FontAwesomeIconsPartooUsed.faStore, IconPrefix.REGULAR],
        },
        {
            id: 'more_hours',
            bulkType: BulkTypeEnum.BUSINESS,
            bulkFormat: BulkFormatEnum.MORE_HOURS,
            name: t('admin:page_import_log__more_hours'),
            icon: [FontAwesomeIconsPartooUsed.faClock, IconPrefix.REGULAR],
        },
        {
            id: 'message_settings',
            bulkType: BulkTypeEnum.BUSINESS,
            bulkFormat: BulkFormatEnum.MESSAGE_SETTINGS,
            name: t('admin:page_import_log__message_settings'),
            icon: [FontAwesomeIconsPartooUsed.faMessage, IconPrefix.REGULAR],
        },
        {
            id: 'users',
            bulkType: BulkTypeEnum.USER,
            bulkFormat: BulkFormatEnum.DEFAULT,
            name: t('admin:page_import_log__users'),
            icon: [FontAwesomeIconsPartooUsed.faUsers, IconPrefix.REGULAR],
        },
        {
            id: 'old_importer',
            bulkType: BulkTypeEnum.BUSINESS,
            bulkFormat: BulkFormatEnum.OLD_IMPORT,
            name: t('admin:page_import_log__old_import'),
            icon: [FontAwesomeIconsPartooUsed.faUpload, IconPrefix.REGULAR],
        },
        {
            id: 'reply_templates',
            bulkType: BulkTypeEnum.REPLY_TEMPLATE,
            bulkFormat: BulkFormatEnum.DEFAULT,
            name: t('admin:page_import_log__reply_templates'),
            icon: [FontAwesomeIconsPartooUsed.faStar, IconPrefix.REGULAR],
        },
    ];
};

export const importLogStatuses = (t: TFunction): { [key: string]: string } => {
    return {
        success: t('admin:page_import_log__status__success'),
        input_error: t('admin:page_import_log__status__input_error'),
        column_error: t('admin:page_import_log__status__column_error'),
        no_modification: t('admin:page_import_log__status__no_modification'),
        partial_error: t('admin:page_import_log__status__partial_error'),
    };
};
