import { Dispatch, FC, SetStateAction, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Checkbox, Dialog, Modal, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';

import { bulkFoodMenuApiClient } from 'admin/api/apiResources';
import { AdminBulkCreateFoodMenu, FoodMenuSectionType } from 'admin/api/types/bulkFoodMenu';

import { BulkMenuActionEnum } from './ActionSelect';
import { OverrideCheckBox } from './UpdateFoodMenuModal.styled';

interface Props {
    bulkMenuAction: string | null;
    sections: FoodMenuSectionType[];
    onSuccess: () => void;
    photosMenu: string[];
    openedSaveModal: boolean;
    setOpenedSaveModal: Dispatch<SetStateAction<boolean>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
}
export const UpdateFoodMenuModal: FC<Props> = ({
    bulkMenuAction,
    sections,
    onSuccess,
    photosMenu,
    openedSaveModal,
    setOpenedSaveModal,
    setLoading,
}) => {
    const { t } = useTranslation();
    const businessModalFilters = useBusinessModalFilters();
    const [override, setOverride] = useState<boolean>(false);

    const updateFoodMenuMutation = useMutation(
        () => {
            const queryParams: AdminBulkCreateFoodMenu = {
                ...businessModalFilters,
                is_override_checked: override,
                menus: sections,
            };

            return bulkFoodMenuApiClient.updateFoodMenu(queryParams);
        },
        {
            onError: () => {
                toast.error(
                    t('admin:bulk_menu__food_menu_error_toast_description'),
                    t('admin:bulk_menu__food_menu_error_toast_title'),
                );
            },
            onSuccess: () => {
                onSuccess();
                toast.success(
                    t('admin:bulk_menu__food_menu_success_toast_description'),
                    t('admin:bulk_menu__food_menu_success_toast_title'),
                );
            },
        },
    );

    const updatePhotoMenuMutation = useMutation(
        () => {
            const queryParams = {
                ...businessModalFilters,
                is_override_checked: override,
                medias: photosMenu,
            };

            return bulkFoodMenuApiClient.updatePhotoMenu(queryParams);
        },
        {
            onError: () => {
                toast.error(
                    t('admin:bulk_menu__photo_menu_error_toast_description'),
                    t('admin:bulk_menu__photo_menu_error_toast_title'),
                );
                setLoading(false);
            },
            onSuccess: () => {
                onSuccess();
                setOverride(false);
                toast.success(
                    t('admin:bulk_menu__photo_menu_success_toast_description'),
                    t('admin:bulk_menu__photo_menu_success_toast_title'),
                );
                setLoading(false);
            },
        },
    );

    const onClickSaveFoodMenu = (value: string | null) => {
        if (value === BulkMenuActionEnum.DETAILS) {
            updateFoodMenuMutation.mutate();
        }
        if (value === BulkMenuActionEnum.PHOTO) {
            updatePhotoMenuMutation.mutate();
        }
        if (value === BulkMenuActionEnum.BOTH) {
            updateFoodMenuMutation.mutate();
            updatePhotoMenuMutation.mutate();
        }
        setOpenedSaveModal(false);
        setLoading(true);
    };

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Warning,
    };

    return (
        <Modal isOpen={openedSaveModal} closeModal={() => setOpenedSaveModal(false)}>
            <div style={{ width: '500px' }}>
                <Dialog
                    dataTrackId=""
                    headerContent={illustrationElement}
                    title={t('admin:bulk_menu__save_modal_title')}
                    subTitle={t('admin:bulk_menu__update_business')}
                    actions={[
                        <Button
                            dataTrackId="update_menu__cancel"
                            variant="secondary"
                            size="large"
                            onClick={() => setOpenedSaveModal(false)}
                            appearance="outlined"
                            full
                        >
                            {t('cancel')}
                        </Button>,
                        <Button
                            dataTrackId="update_menu__delete"
                            variant="primary"
                            size="large"
                            onClick={() => onClickSaveFoodMenu(bulkMenuAction)}
                            full
                        >
                            {t('confirm')}
                        </Button>,
                    ]}
                >
                    <OverrideCheckBox>
                        <Checkbox
                            dataTrackId="update_food_menu__checkbox"
                            checked={override}
                            onChange={() => setOverride(!override)}
                        >
                            <Text variant="bodyMBold" as="span" color="secondary">
                                {t('admin:bulk_menu__override')}
                            </Text>
                        </Checkbox>
                    </OverrideCheckBox>
                </Dialog>
            </div>
        </Modal>
    );
};
