import { FC, ReactNode } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix, TextInput } from '@partoohub/ui';
import { upperFirst } from 'lodash-es';

import { useTranslation } from 'react-i18next';

import { useIsSideMenuOpen } from 'admin/common/contexts/isSideMenuOpenContext';
import { STATIC_BASE_URL } from 'admin/common/data/config';

import {
    PageContent,
    PageDescription,
    PageHeader,
    PageHeaderLeft,
    PageHeaderTitle,
    PageHeaderTitleContent,
    PageWrapper,
    SearchBarWrapper,
    SearchIconWrapper,
} from './AdminPage.styled';

type Props = {
    title: string;
    children: ReactNode;
    description?: string;
    buttons?: ReactNode;
    infoLink?: string;
    displaySearchBar?: boolean;
    toggleSearchBar?: () => void;
    currentSearch?: string;
    updateCurrentSearch?: (userSearch?: string) => void;
    searchPlaceholder?: string;
    dataTrackId: string;
};

const AdminPage: FC<Props> = ({
    title,
    description,
    children,
    buttons,
    infoLink,
    displaySearchBar,
    toggleSearchBar,
    currentSearch,
    updateCurrentSearch,
    searchPlaceholder,
    dataTrackId,
}) => {
    const { isSideMenuOpen, setIsSideMenuOpen } = useIsSideMenuOpen();
    const { t } = useTranslation();

    const LinkIcon = (
        <IconButton
            dataTrackId={`${dataTrackId}__info_link`}
            icon={[FontAwesomeIconsPartooUsed.faCircleQuestion, IconPrefix.SOLID]}
            onClick={() => window.open(infoLink, '_blank')}
            appearance="outlined"
        />
    );
    return (
        <PageWrapper isSideMenuOpen={isSideMenuOpen} id="page_wrapper">
            <PageHeader haveDescription={!!description}>
                <PageHeaderLeft>
                    <IconButton
                        dataTrackId={`${dataTrackId}__toggle_menu`}
                        appearance="outlined"
                        icon={<img src={`${STATIC_BASE_URL}/images/app/icons/menu.svg`} alt="" />}
                        onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
                    />
                    <PageHeaderTitle>
                        {displaySearchBar && updateCurrentSearch ? (
                            <SearchBarWrapper>
                                <TextInput
                                    dataTrackId={`${dataTrackId}__search`}
                                    id="search-content"
                                    onChange={updateCurrentSearch}
                                    placeholder={upperFirst(t(searchPlaceholder || title))}
                                    value={currentSearch || ''}
                                    autoFocus
                                    onBlur={toggleSearchBar}
                                />
                            </SearchBarWrapper>
                        ) : (
                            <PageHeaderTitleContent>
                                {title}
                                {!description && infoLink && LinkIcon}
                            </PageHeaderTitleContent>
                        )}
                        {!displaySearchBar && toggleSearchBar && (
                            <SearchIconWrapper>
                                <IconButton
                                    dataTrackId={`${dataTrackId}__search_bar`}
                                    icon={[FontAwesomeIconsPartooUsed.faSearch, IconPrefix.REGULAR]}
                                    appearance="outlined"
                                    variant="primary"
                                    onClick={toggleSearchBar}
                                    tooltip={searchPlaceholder}
                                />
                            </SearchIconWrapper>
                        )}
                    </PageHeaderTitle>
                </PageHeaderLeft>
                {buttons && <div>{buttons}</div>}
            </PageHeader>
            {description && (
                <PageDescription>
                    {description}
                    {infoLink && description && LinkIcon}
                </PageDescription>
            )}
            <PageContent>{children}</PageContent>
        </PageWrapper>
    );
};

export default AdminPage;
