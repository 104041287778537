import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';

export type UploadImageResponse = {
    status: string;
    result: {
        uploads: Array<string>;
    };
};

const formatImagesForAPI = (photos: Array<File>): FormData =>
    photos.reduce((form: FormData, photo) => {
        form.append(photo.name, photo);
        return form;
    }, new FormData());

export function usePhotosUpload(
    onSuccess: (data: UploadImageResponse) => void = () => undefined,
    onError: (error: any) => void = () => undefined,
) {
    return useMutation((photos: Array<File>) => api.image.uploadImage(formatImagesForAPI(photos)), {
        onSuccess,
        onError,
    });
}
