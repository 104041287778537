import ApiResourceBaseClass from './baseApiCalls';
import { SuccessResponse } from '../../types';
import { FakeAnalyticsGenerationData } from '../../types/analytics';

const RESOURCE_URI = 'analytics';

class Analytics extends ApiResourceBaseClass {
    static generateFakeAnalytics(
        analyticsData: FakeAnalyticsGenerationData,
    ): Promise<SuccessResponse> {
        return Analytics.post(
            `${RESOURCE_URI}/generate-fake`,
            analyticsData,
        ).then(({ data }) => data);
    }
}

export default Analytics;
