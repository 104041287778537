import styled from '@emotion/styled';
import { List } from '@partoohub/ui';

export const Row = styled(List.Row)`
    overflow-y: unset;
    cursor: pointer;
    outline: none;
    gap: unset;
    grid-template-columns: 50% 20% 20% 10%;
`;

export const RowTitle = styled.span`
    display: flex;
    gap: 8px;
`;
