import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

const Animation = styled.div`
    opacity: 12%;

    animation: skeleton-loading 1s linear infinite alternate;

    @keyframes skeleton-loading {
        0% {
            background-color: ${HEX_COLORS.secondary};
        }
        100% {
            background-color: white;
        }
    }
`;

const SkeletonLine = styled(Animation)`
    height: 8px;
    border-radius: 4px;
`;

export const SkeletonLine1 = styled(SkeletonLine)`
    width: 160px;
`;

export const SkeletonLine2Wrapper = styled.div`
    padding-top: 6px;
`;

export const SkeletonLine2 = styled(SkeletonLine)`
    width: 264px;
`;

export const SkeletonButton = styled(Animation)`
    width: 100px;
    height: 40px;
    border-radius: 50px;
`;
