import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';

import useBusinessModalCurrentSelectedCount from 'app/common/components/businessModal/hooks/privateHooks/useBusinessModalCurrentSelectedCount';
import useLocalSelectionToReduxState from 'app/common/components/businessModal/hooks/privateHooks/useLocalSelectionToReduxState';
import {
    applyFilterByBusinessFilters,
    setBusinessFilters,
} from 'app/common/components/businessModalDeprecated/reducers';

import { FloatingFooter } from './Footer.styled';

type Props = {
    closeModal: () => void;
};

const Footer = ({ closeModal }: Props) => {
    const { t } = useTranslation();

    const { toggleSelectAll, selection, variation, limitToGroups } = useBusinessModalContext();
    const selectedCount = useBusinessModalCurrentSelectedCount();

    const dispatch = useDispatch();
    const reduxState = useLocalSelectionToReduxState();

    const handleSubmit = () => {
        dispatch(setBusinessFilters(reduxState));
        dispatch(applyFilterByBusinessFilters());

        closeModal();
    };

    const commonButtonProps = {
        shape: 'cube',
        size: 'large',
        full: true,
    } as const;

    const selectAllDataTracks = {
        business_drawer_erase: 'business_modal__erase_button',
        business_drawer_select_all: 'business_modal__select_all_button',
    };
    let selectAllText: keyof typeof selectAllDataTracks;
    if (selection.selectAll) {
        selectAllText = 'business_drawer_erase';
    } else {
        selectAllText = 'business_drawer_select_all';
    }

    return (
        <FloatingFooter>
            {(variation !== 'group-only' || !!selection.filters.length) && (
                <Button
                    {...commonButtonProps}
                    appearance="outlined"
                    onClick={toggleSelectAll}
                    dataTrackId={selectAllDataTracks[selectAllText]}
                    variant="secondary"
                >
                    {t(selectAllText)}
                </Button>
            )}
            <Button
                {...commonButtonProps}
                appearance="contained"
                onClick={handleSubmit}
                variant="primary"
                dataTrackId="business_modal__confirm_button"
                disabled={!!limitToGroups && (selectedCount ?? 0) < 1}
            >
                {t('business_drawer_confirm')}
            </Button>
        </FloatingFooter>
    );
};

export default Footer;
