import { FeedbackLookerStudioData } from '@partoohub/api-client';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { feedbackLookerStudioApiClient } from 'admin/api/apiResources';
import { FEEDBACK_LOOKER_STUDIO } from 'admin/common/data/queryKeysConstants';

export const useGetFeedbackLookerStudio = (
    orgId: number,
    options: UseQueryOptions<FeedbackLookerStudioData> = {},
) => {
    const query = useQuery(
        [FEEDBACK_LOOKER_STUDIO, orgId] as QueryKey,
        () => feedbackLookerStudioApiClient.getFeedbackLookerStudio(orgId),
        {
            ...options,
            retry: false,
        },
    );

    return query?.data;
};
