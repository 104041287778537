import React, { FC, MouseEventHandler, ReactNode } from 'react';

import { Button, ButtonVariants } from '@partoohub/ui';

import { SubmitButtonStyled } from './SubmitButton.styled';

type LinkParams = {
    link: string;
    target?: string;
};

type Props = {
    text: string;
    disabled?: boolean;
    variant?: ButtonVariants;
    icon?: ReactNode;
    loading?: boolean;
    link?: LinkParams;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    dataTrackId: string;
};

const SubmitButton: FC<Props> = ({
    text,
    icon,
    loading,
    link,
    onClick,
    disabled = false,
    variant = 'primary',
    dataTrackId,
}) => (
    <SubmitButtonStyled>
        {link && (
            <a href={link.link} target={link.target}>
                <Button
                    variant={variant}
                    shape="cube"
                    size="large"
                    disabled={disabled}
                    icon={icon as JSX.Element}
                    onClick={onClick}
                    isLoading={loading}
                    dataTrackId={dataTrackId}
                    full
                >
                    {text}
                </Button>
            </a>
        )}
        {!link && (
            <Button
                variant={variant}
                shape="cube"
                size="large"
                disabled={disabled}
                icon={icon as JSX.Element}
                onClick={onClick}
                isLoading={loading}
                dataTrackId={dataTrackId}
                full
            >
                {text}
            </Button>
        )}
    </SubmitButtonStyled>
);

export default SubmitButton;
