import { FC } from 'react';

import { Option, SingleSelect } from '@partoohub/ui';
import { useQuery } from 'react-query';

import IntegrationsApiCall from 'admin/common/api/calls/integrationsApiCalls';
import { INTEGRATIONS } from 'admin/common/data/queryKeysConstants';

type Props = {
    placeholder: string;
    selectedValue?: Option;
    onChange: (choice?: Option) => void;
};

const ExportFormatInput: FC<Props> = ({ placeholder, selectedValue, onChange }) => {
    const { data, isFetched } = useQuery(INTEGRATIONS, IntegrationsApiCall.getIntegrations, {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });

    const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

    const integrations: Option[] =
        data?.integrations.map((integration: any) => ({
            value: integration.name,
            name: integration.name,
            label: capitalize(integration.name.replace('_', ' ')),
        })) || [];

    return (
        <SingleSelect
            dataTrackId="admin_old_importer__integration_input"
            sections={[{ options: integrations }]}
            selectedValue={selectedValue ?? undefined}
            onChange={onChange}
            placeholder={placeholder}
            label={placeholder}
            disabled={!isFetched}
            maxHeight={300}
            required
        />
    );
};

export default ExportFormatInput;
