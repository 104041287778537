import { useTranslation } from 'react-i18next';

import { CLOSED, CLOSED_TEMPORARILY, OPEN } from 'admin/common/data/constants/businessStatuses';

import { SingleSelect } from './SingleSelect';

type Props = {
    status: string | null;
    setStatus: (value: string | null) => void;
};

export const BusinessStatusSelect = ({ status, setStatus }: Props) => {
    const { t } = useTranslation();

    const options = [
        { value: OPEN, name: OPEN, label: t(OPEN) },
        { value: CLOSED, name: CLOSED, label: t('business_closed') },
        { value: CLOSED_TEMPORARILY, name: CLOSED_TEMPORARILY, label: t(CLOSED_TEMPORARILY) },
    ];

    return (
        <SingleSelect
            dataTrackId="page_exporter__select_status"
            sections={[
                {
                    options: options,
                },
            ]}
            selectedValue={status}
            onChange={setStatus}
            placeholder={t('status')}
        />
    );
};
