import { AxiosError } from 'axios';

import { useQuery } from 'react-query';

import { User } from 'admin/common/api/calls/userApiCalls';
import { UserData } from 'admin/common/api/types/user';
import { ME } from 'admin/common/data/queryKeysConstants';

interface Options {
    enabled?: boolean;
    onSuccess?: (data: UserData) => void;
    onError?: (err: AxiosError) => void;
    retry?: boolean;
    refetchOnReconnect?: boolean;
    refetchOnWindowFocus?: boolean;
}

export const useMe = (options?: Options) => {
    return useQuery([ME, 'uncamel'], () => User.getMe(), options);
};
