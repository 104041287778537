import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const DiffusionTableHeaderContainer = styled.div`
    height: 32px;
    border-radius: 4px;
    background-color: ${greyColorObject.light};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
`;
