import { useMutation, useQueryClient } from 'react-query';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';

import { userInfosApiClient } from 'admin/api/apiResources';
import { MergeUserInfosBody } from 'admin/api/types/userInfos';

import { MULTI_ORGANIZATIONS } from 'admin/common/data/queryKeysConstants';

type Props = {
    mainUserInfoId: number;
    body: MergeUserInfosBody;
};

export const useMergeUserInfos = (
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ mainUserInfoId, body }: Props) =>
            userInfosApiClient.mergeUserInfos(mainUserInfoId, body),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(invalidatesAny(MULTI_ORGANIZATIONS)).then(onSuccess);
            },
            onError,
        },
    );
};
