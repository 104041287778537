import React, { useState } from 'react';

import { bgColor } from 'admin/content/logs/common/utils';

import {
    DiffusionRowContainer,
    DiffusionRowStatus,
    DiffusionTableRowContainer,
} from './DiffusionRow.styled';
import { DiffusionData } from '../../../Diffusion';
import Details from '../Details/Details';
import RowTop from '../RowTop/RowTop';

export type Props = {
    log: DiffusionData;
};

const DiffusionRow: React.FC<Props> = ({ log }) => {
    const [closed, setClosed] = useState(true);

    // QUICKFIX for blinking logs
    const pushType = (diffuserName: string) => {
        if (diffuserName.split('__').length > 1) {
            return diffuserName.split('__')[1];
        }

        return 'main';
    };

    const renderDetails = () => {
        if (closed) {
            return undefined;
        }
        return (
            <Details
                diffusionError={log.errors}
                diffusionState={log.state}
                callLog={log.logs}
                logs_human_error_message={log.logsHumanErrorMessage}
                postCheckErrors={log.postCheckErrors}
            />
        );
    };

    return (
        <DiffusionTableRowContainer appearance="full">
            <DiffusionRowStatus
                className={`${bgColor[log.state]}`}
                style={{ minWidth: '5px' }}
            />
            <DiffusionRowContainer style={{ maxWidth: 'calc(100% - 5px)' }}>
                <RowTop
                    partnerName={log.publisher}
                    businessName={log.businessName}
                    businessAddress={log.businessAddress}
                    businessCity={log.businessCity}
                    businessZipCode={log.businessZipcode}
                    businessId={log.businessId}
                    pushType={pushType(log.diffuser)}
                    creationDate={log.creationDate}
                    updateDate={log.updateDate}
                    state={log.state}
                    toggleClosed={() => setClosed(!closed)}
                    closed={closed}
                />
                {log.state !== 'PENDING' && renderDetails()}
            </DiffusionRowContainer>
        </DiffusionTableRowContainer>
    );
};

export default DiffusionRow;
