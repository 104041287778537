import { FC } from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

interface DropZoneDescriptionProps {
    minSize?: number;
    maxSize?: number;
    acceptedExtensions?: Record<string, string[]>;
}
const showAsKb = (bytes: number) => bytes / 1_000;
const showAsMb = (bytes: number) => bytes / 1_000_000;

export const DropZoneDescription: FC<DropZoneDescriptionProps> = ({
    minSize,
    maxSize,
    acceptedExtensions,
}) => {
    const { t } = useTranslation();

    const getSupportedSizesText = () => {
        if (minSize && maxSize) {
            return t('drop_zone_explanation_supported_sizes', {
                minSize: showAsKb(minSize),
                maxSize: showAsMb(maxSize),
            });
        }
        if (maxSize) {
            return t('drop_zone_explanation_supported_sizes_without_min', {
                maxSize: showAsMb(maxSize),
            });
        }
        if (minSize) {
            return t('drop_zone_explanation_supported_sizes_without_max', {
                maxSize: showAsMb(minSize),
            });
        }
        return '';
    };

    const displayExtensions = Object.values(acceptedExtensions || {}).flat();

    return (
        <>
            {t('drop_zone_explanation_howto')}&nbsp;
            <span
                dangerouslySetInnerHTML={{
                    __html: getSupportedSizesText(),
                }}
            />
            <br />
            {!!displayExtensions.length &&
                t('drop_zone_accepted_files', {
                    count: displayExtensions.length,
                })}
            &nbsp;
            <Text as="span" variant="bodyMBold" color="secondary">
                {displayExtensions.join(', ')}
            </Text>
        </>
    );
};
