import { FontAwesomeIconsPartooUsed, IconButton, Sticker, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useUserInfo } from 'admin/content/tools/pages/MultiOrganizations/hooks/useUserInfo';

import {
    AddedUserInfoBottom,
    AddedUserInfoContainer,
    AddedUserInfoTop,
} from './AddedUserInfo.styled';

type Props = {
    userInfoId: number;
    onDeleteUserInfo: () => void;
};

export const AddedUserInfo = ({ userInfoId, onDeleteUserInfo }: Props) => {
    const { t } = useTranslation();
    const { data: userInfo } = useUserInfo(userInfoId);

    return (
        <AddedUserInfoContainer>
            <AddedUserInfoTop>
                <div>
                    <Text variant="bodyMSemibold">{userInfo?.email}</Text>
                    <Text color="secondary">
                        {`${userInfo?.user_count} ${userInfo?.user_count && userInfo.user_count > 1 ? 'orgs' : 'org'}`}
                    </Text>
                </div>
                <IconButton
                    appearance="outlined"
                    dataTrackId="delete_user_info"
                    aria-label={t('delete')}
                    icon={[FontAwesomeIconsPartooUsed.faCircleMinus]}
                    variant="danger"
                    tooltip={t('delete')}
                    onClick={onDeleteUserInfo}
                />
            </AddedUserInfoTop>

            <AddedUserInfoBottom>
                {userInfo?.users.map(user => (
                    <Sticker
                        dataTrackId={`user_info_user_${user.id}`}
                        key={user.id}
                        variant="default"
                        appearance="outlined"
                    >
                        <Text variant="bodySMedium">{`${user.org_name} (${user.org_id})`}</Text>
                    </Sticker>
                ))}
            </AddedUserInfoBottom>
        </AddedUserInfoContainer>
    );
};
