import { Text } from '@partoohub/ui';

type Props = {
    name: string;
    address: string;
    zipcode: string;
    city: string;
};

const BusinessDetails = ({ name, address, zipcode, city }: Props) => (
    <div>
        <Text variant="bodyMBold">{name}</Text>
        <Text variant="bodySRegular" color="secondary">
            {address}
        </Text>
        <Text variant="bodySRegular" color="secondary">
            {zipcode}
            {` ${city}`}
        </Text>
    </div>
);

export default BusinessDetails;
