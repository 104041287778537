import { LoadingRectangleContainer } from './LoadingRectangle.styled';

type Props = {
    recWidth?: string;
    recHeight?: string;
    recMargin?: string;
    recRadius?: string;
};
export const LoadingRectangle = ({ recWidth, recHeight, recMargin = '0', recRadius }: Props) => (
    <LoadingRectangleContainer
        style={{
            width: recWidth,
            height: recHeight,
            margin: recMargin,
            borderRadius: recRadius,
        }}
    />
);
