import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack } from '@partoohub/ui';

import { CapitalizeText } from './Status.styled';

type Props = {
    status: string;
    label?: string;
};

const statusIcon = {
    PENDING: FontAwesomeIconsPartooUsed.faSync,
    FIELD_ERROR: FontAwesomeIconsPartooUsed.faExclamationCircle,
    FIELD_REJECTED: FontAwesomeIconsPartooUsed.faExclamationCircle,
    DUPLICATED_BUSINESSES: FontAwesomeIconsPartooUsed.faExclamationCircle,
    ERROR: FontAwesomeIconsPartooUsed.faTimesCircle,
    NOT_AUTHORIZED: FontAwesomeIconsPartooUsed.faTimesCircle,
    NOT_AUTHENTICATED: FontAwesomeIconsPartooUsed.faTimesCircle,
    SUCCESS: FontAwesomeIconsPartooUsed.faCheckCircle,
    ORG_DISABLED: FontAwesomeIconsPartooUsed.faBan,
    INPUT_ERROR: FontAwesomeIconsPartooUsed.faTimesCircle,
    COLUMN_ERROR: FontAwesomeIconsPartooUsed.faTimesCircle,
    NO_MODIFICATION: FontAwesomeIconsPartooUsed.faBan,
    PARTIAL_ERROR: FontAwesomeIconsPartooUsed.faExclamationCircle,
    CONNECTION_ERROR: FontAwesomeIconsPartooUsed.faExclamationCircle,
    INTERNAL_ERROR: FontAwesomeIconsPartooUsed.faTimesCircle,
    EXTERNAL_ERROR: FontAwesomeIconsPartooUsed.faTimesCircle,
    ADMIN_ERROR: FontAwesomeIconsPartooUsed.faTimesCircle,
};

const fontColor = {
    PENDING: 'primary',
    FIELD_ERROR: 'warning',
    FIELD_REJECTED: 'warning',
    DUPLICATED_BUSINESSES: 'warning',
    ERROR: 'danger',
    NOT_AUTHORIZED: 'danger',
    NOT_AUTHENTICATED: 'danger',
    SUCCESS: 'success',
    ORG_DISABLED: 'secondary',
    INPUT_ERROR: 'danger',
    COLUMN_ERROR: 'danger',
    NO_MODIFICATION: 'warning',
    PARTIAL_ERROR: 'danger',
    CONNECTION_ERROR: 'warning',
    INTERNAL_ERROR: 'danger',
    EXTERNAL_ERROR: 'danger',
    ADMIN_ERROR: 'danger',
};

const StatusImg = ({ status, label }: Props) => (
    <Stack direction="row" gap="4px" alignItems="center">
        <Icon
            icon={[statusIcon[status], IconPrefix.SOLID]}
            color={fontColor[status]}
            className={status === 'PENDING' ? FontAwesomeIconsPartooUsed.faSpin : ''}
        />
        <CapitalizeText variant="bodyMBold" as="span" color={fontColor[status]}>
            {label ?? status.toLowerCase()}
        </CapitalizeText>
    </Stack>
);

export default StatusImg;
