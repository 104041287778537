import React from 'react';

import { Filters } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DIFFUSERS_TEXT } from './diffusers';

type Props = {
    diffusers: Record<string, boolean>;
    setDiffusers: (selectedItems: Record<string, boolean>) => void;
    disabled?: boolean;
    diffuserOptions?: Array<string>;
};

export const DiffusionLogsPublisherSelect: React.FC<Props> = ({
    diffusers,
    setDiffusers,
    disabled,
    diffuserOptions = [''],
}: Props) => {
    const { t } = useTranslation();
    const section = [
        {
            options: diffuserOptions.map(option => ({
                value: option,
                label: t(DIFFUSERS_TEXT[option]),
                name: option,
            })),
        },
    ];
    return (
        <Filters
            dataTrackId="admin_diffusion_logs__filter_diffuser"
            menuListArray={section}
            selectedItems={diffusers}
            onChange={setDiffusers}
            placeholder={'Publishers'}
            position="bottom"
            maxHeight={300}
            multiple={true}
            disabled={disabled}
        />
    );
};
