import React, { useEffect, useRef, useState } from 'react';

import fuzzysort from 'fuzzysort';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router';

import AdminLinkIcon from './icons/AdminLinkIcon';
import AppLinkIcon from './icons/AppLinkIcon';
import DbAdminLinkIcon from './icons/DbAdminLinkIcon';
import {
    Action,
    ActionContent,
    ActionIcon,
    Backdrop,
    Modal,
    ModalContent,
    ModalSearch,
    NoResult,
    SearchIcon,
    SelectedIcon,
} from './QuickActionModal.styled';
import QuickActionModalFooter from './QuickActionModalFooter';
import { LinkTypeEnum, QUICK_ACTION_LINKS, QuickActionLink } from '../links';

type Props = {
    opened: boolean;
    specialKeyPressed: boolean;
    closeModal: () => undefined;
};

const QuickActionModal: React.FC<Props> = ({ opened, specialKeyPressed, closeModal }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState<string>('');
    const [actions, setActions] = useState<Array<QuickActionLink>>([]);
    const [selectionIndex, setSelectionIndex] = useState<number>(0);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (opened) searchInputRef.current?.focus();
        else {
            setTimeout(() => {
                searchInputRef.current?.blur();
                setSearch('');
                setSelectionIndex(0);
            }, 100);
        }
    }, [opened]);

    useEffect(() => {
        setActions(
            fuzzysort
                .go(search, QUICK_ACTION_LINKS, {
                    limit: 10,
                    all: true,
                    key: 'name',
                    threshold: 0.5,
                })
                .map(r => r.obj),
        );
        setSelectionIndex(0);
    }, [search]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (['Enter', 'ArrowUp', 'ArrowDown'].includes(event.key)) event.preventDefault();

        if (event.key === 'Enter') triggerAction(actions[selectionIndex]);
        else if (event.key === 'ArrowUp') selectPreviousLink();
        else if (event.key === 'ArrowDown') selectNextLink();
    };

    const selectNextLink = () => {
        if (!actions || actions.length <= 1) return;
        if (selectionIndex === actions.length - 1) setSelectionIndex(0);
        else setSelectionIndex(selectionIndex + 1);
    };

    const selectPreviousLink = () => {
        if (!actions || actions.length <= 1) return;
        if (selectionIndex === 0) setSelectionIndex(actions.length - 1);
        else setSelectionIndex(selectionIndex - 1);
    };

    const onActionClick = (event: any, action: QuickActionLink) => {
        event.preventDefault();
        triggerAction(action);
    };

    const triggerAction = (action: QuickActionLink) => {
        if (specialKeyPressed) window.open(action.link, '_blank')?.focus();
        else if (action.type === LinkTypeEnum.Admin) navigate(action.link);
        else window.location.href = action.link;
        closeModal();
    };

    const getActionIcon = (action: QuickActionLink) => {
        let icon;

        if (action.type === LinkTypeEnum.App) icon = <AppLinkIcon />;
        else if (action.type === LinkTypeEnum.DbAdmin) icon = <DbAdminLinkIcon />;
        else icon = <AdminLinkIcon />;

        return <ActionIcon>{icon}</ActionIcon>;
    };

    return (
        <>
            <Backdrop onClick={closeModal} className={opened ? '' : 'hidden'} />
            <Modal className={opened ? '' : 'hidden'}>
                <ModalSearch>
                    <SearchIcon className="fa-solid fa-magnifying-glass" />

                    <input
                        type="text"
                        onKeyDown={handleKeyDown}
                        name="quick_action_input"
                        value={search}
                        placeholder="Where do you want to go?"
                        onChange={e => setSearch(e.target.value)}
                        autoComplete="off"
                        ref={searchInputRef}
                        aria-label={t('quick_action__sidebar_button__tooltip')}
                    />
                </ModalSearch>
                <ModalContent>
                    {actions.length === 0 && <NoResult>No results matched your search</NoResult>}

                    {actions.map((action, index) => (
                        <Action
                            href={action.link}
                            onClick={event => onActionClick(event, action)}
                            key={`${action.name} - ${action.link}`}
                            className={selectionIndex === index ? 'selected' : ''}
                        >
                            <ActionContent>
                                {getActionIcon(action)}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            fuzzysort
                                                .single(search, action.name)
                                                ?.highlight('<b>', '</b>') || action.name,
                                    }}
                                />
                            </ActionContent>

                            <SelectedIcon className="fa-solid fa-arrow-turn-down-left" />
                        </Action>
                    ))}
                </ModalContent>
                <QuickActionModalFooter />
            </Modal>
        </>
    );
};

export default QuickActionModal;
