import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const IncognitoToggleWrapper = styled.div`
    display: flex;
    justify-content: left;
`;

export const StyledText = styled(Text)`
    margin-left: 8px;
`;
