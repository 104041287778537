import React from 'react';

import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { Publisher } from 'admin/common/api/calls/diffusionCleanerApiCalls';

import onClickFunction from 'admin/content/tools/pages/DiffusionCleaner/hooks/useConnection';

type Props = {
    provider?: string;
    orgId?: string;
    publisher: Publisher;
};
export const QuickConnect: React.FC<Props> = ({ provider, orgId, publisher }) => {
    const { t } = useTranslation();
    const hasExtension = window.partoo_extension;

    return (
        <Button
            appearance="contained"
            shape="rounded"
            size="medium"
            variant="primary"
            dataTrackId="admin_diffusion_cleaner__quick_connect_bulk_button"
            onClick={onClickFunction(orgId, provider, publisher, undefined)}
            disabled={!hasExtension}
        >
            {t('admin:page_diffusion_cleaner__quick_connect_button__business')}
        </Button>
    );
};
