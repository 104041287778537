import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';

import { AdminBulkCreateServiceParamsType } from 'admin/api/types';

const RESOURCE_URI = 'services';

export class ServicesAdminApiCall extends ApiResourceBaseClass {
    async adminBulkCreateService(
        queryParams: AdminBulkCreateServiceParamsType,
    ): Promise<SuccessResponse> {
        const { data } = await this.post<SuccessResponse>(
            `${RESOURCE_URI}/bulk_create_services`,
            queryParams,
        );

        return data;
    }
}
