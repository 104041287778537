import { css } from '@emotion/react';
import styled from '@emotion/styled';

const cellBaseStyles = css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 10px;
`;

const cellCategoryStyles = css`
    width: 125px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;

    img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
    }
`;

const cellHeaderStyles = css`
    font-size: 10px;
    font-weight: bold;

    span {
        text-transform: uppercase;
        margin-right: 5px;
    }

    &-first > span {
        margin-left: 32px;
    }
`;

const getCellTypeStyles = (type: string) => {
    if (type === 'category') {
        return css`
            ${cellCategoryStyles};
        `;
    }
    if (type === 'header') {
        return css`
            ${cellHeaderStyles}
        `;
    }
    return css``;
};
const getCellWidthStyles = (width: string) => {
    if (width === 'large') {
        return css`
            width: 265px;
        `;
    }
    if (width === 'medium') {
        return css`
            width: 145px;
        `;
    }
    if (width === 'small') {
        return css`
            width: 70px;
        `;
    }
    if (width === 'regular') {
        return css`
            width: 125px;
        `;
    }
    return css``;
};

export const DiffusionCell = styled.div<{
    type?: string;
    width?: string;
    isFirst?: boolean;
}>`
    ${({ type }) => type === 'header' && cellBaseStyles};
    ${({ type }) => getCellTypeStyles(type ?? '')}
    ${({ width }) => width && getCellWidthStyles(width ?? 'small')}
    ${({ isFirst, type }) =>
        type === 'header' &&
        isFirst &&
        css`
            > span {
                margin-left: 32px;
            }
        `}
`;
