import { GetHolidaysReceivedData } from 'app/api/types/holidays';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'holidays';

class Holidays extends ApiResourceBaseClass {
    static getHolidays(
        country: string,
        lang: string | null = null,
    ): Promise<GetHolidaysReceivedData> {
        return Holidays.get(`${RESOURCE_URI}/${country}`, { lang }).then(
            ({ data }) => data,
        );
    }
}

export default Holidays;
