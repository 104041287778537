import React, { useEffect, useState } from 'react';

import { Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FEATURE_ENUM } from 'app/api/types/plans_features';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';

import { KeywordsSectionDisplayHandlerSection } from './components/KeywordsSectionDisplayHandler/KeywordsSectionDisplayHandler';

export const CompetitiveBenchmark: React.FC = () => {
    const { t } = useTranslation();
    const [orgId, setOrgId] = useStateQueryParams('orgId');
    const [org, setOrg] = useState<Option | undefined>(
        orgId
            ? {
                  value: orgId,
                  name: orgId,
                  label: orgId,
              }
            : undefined,
    );

    useEffect(() => {
        if (org) {
            setOrgId(org.value);
        }
    }, [org]);

    return (
        <AdminPage
            dataTrackId="competitive_benchmark_page"
            title={t('admin:page_competitive_benchmark__title')}
        >
            <PageFormRow>
                <NewOrganizationSelect
                    dataTrackId="competitive-benchmark-organization"
                    orgs={org}
                    setOrgs={setOrg}
                    placeholder={t('admin:organization')}
                    queryParams={{
                        has_feature: FEATURE_ENUM.COMPETITIVE_BENCHMARK,
                    }}
                />
            </PageFormRow>
            {orgId && <KeywordsSectionDisplayHandlerSection />}
        </AdminPage>
    );
};
