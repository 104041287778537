import { BusinessModalFiltersType } from 'admin/api/types/bulkFoodMenu';
import { ServiceModalFormType } from 'admin/content/data/pages/ServicesCreation/old/components/ServicesSection/modals/utils';

export type AdminServiceCreateParamsType = {
    category_gmb_name: string;
    name: string;
    type: string;
    price?: number | null;
    description?: string | null;
    service_id?: number | null;
};

export type AdminBulkCreateServiceParamsType = BusinessModalFiltersType & {
    services: Array<AdminServiceCreateParamsType>;
    is_override_checked: boolean;
};

export const adminServiceCreateQueryParams = (
    categoryGmbName: string,
    name: string,
    type: string,
    description?: string,
    price?: string,
    serviceId?: number,
) =>
    ({
        category_gmb_name: categoryGmbName,
        name,
        description: description || null,
        price: !Number.isNaN(parseFloat(price!)) ? parseFloat(price!) : null,
        service_id: serviceId || null,
        type,
    }) as AdminServiceCreateParamsType;

export const adminBulkCreateServiceQueryParams = (
    categoryGmbName: string,
    services: Array<ServiceModalFormType>,
    isOverrideChecked: boolean,
) => ({
    services: services.map(service =>
        adminServiceCreateQueryParams(
            categoryGmbName,
            service.name,
            service.type,
            service.description,
            service.price,
            service.serviceId,
        ),
    ),
    is_override_checked: isOverrideChecked,
});
