import {
    Account,
    AuthToken,
    Business,
    Connection,
    FeedbackForm, FeedbackLookerStudio,
    FeedbackReplySuggestion,
    Image as ImageApiClient,
    Lang,
    MessagingCallDeflection,
    Organization,
    Provider,
    Publisher,
    Review,
    User,
} from '@partoohub/api-client';

import {
    AccountAdminApiCall,
    AdminActionsApiCall,
    AdministrativeRegions,
    BulkExportAdminApiCall,
    BulkExportV1ApiCall,
    BulkFoodMenuApiCall,
    BulkImportAdminApiCall,
    BulkImportV1,
    CategoriesApiCall,
    CompetitiveBenchmark,
    DemoCreatorAdminApiCall,
    DiffusionApiCall,
    DiffusionCleanerApiCall,
    GoogleAdminManagementApiCall,
    IntegrationsApiCall,
    LogsApiCall,
    OverridingStateParametersApiCall,
    ProspectsApiCall,
    ReviewAdminApiCall,
    ReviewTreatmentAdminApiCall,
    SearchDuplicatesApiCall,
    ServicesAdminApiCall,
    UserInfosApiCall,
} from './resources';

// CLIENT API ENDPOINTS
export const BASE_URL = `https://${window.location.host}/api/v2`;

export const accountApiClient = new Account(BASE_URL);
export const authTokenApiClient = new AuthToken(BASE_URL);
export const businessApiClient = new Business(BASE_URL);
export const connectionApiClient = new Connection(BASE_URL);
export const feedbackFormApiClient = new FeedbackForm(BASE_URL);
export const feedbackLookerStudioApiClient = new FeedbackLookerStudio(BASE_URL);
export const feedbackReplySuggestionApiClient = new FeedbackReplySuggestion(BASE_URL);
export const imageApiClient = new ImageApiClient(BASE_URL);
export const langApiClient = new Lang(BASE_URL);
export const organizationApiClient = new Organization(BASE_URL);
export const providerApiClient = new Provider(BASE_URL);
export const publisherApiClient = new Publisher(BASE_URL);
export const reviewApiClient = new Review(BASE_URL);
export const userApiClient = new User(BASE_URL);
export const messagingCallDetailsApiClient = new MessagingCallDeflection(BASE_URL);
export const categoriesApiClient = new CategoriesApiCall(BASE_URL);

// ADMIN API ENDPOINTS
export const ADMIN_BASE_URL = `https://${window.location.host}/admin`;

export const accountAdminApiClient = new AccountAdminApiCall(ADMIN_BASE_URL);
export const administrativeRegionsApiClient = new AdministrativeRegions(ADMIN_BASE_URL);
export const bulkExportV1ApiClient = new BulkExportV1ApiCall(ADMIN_BASE_URL);
export const bulkExportApiClient = new BulkExportAdminApiCall(ADMIN_BASE_URL);
export const bulkFoodMenuApiClient = new BulkFoodMenuApiCall(ADMIN_BASE_URL);
export const bulkImportApiClient = new BulkImportAdminApiCall(ADMIN_BASE_URL);
export const bulkImportV1ApiClient = new BulkImportV1(ADMIN_BASE_URL);
export const competitiveBenchmarkApiClient = new CompetitiveBenchmark(ADMIN_BASE_URL);
export const diffusionApiClient = new DiffusionApiCall(ADMIN_BASE_URL);
export const logsApiClient = new LogsApiCall(ADMIN_BASE_URL);
export const reviewAdminApiClient = new ReviewAdminApiCall(ADMIN_BASE_URL);
export const integrationsApiClient = new IntegrationsApiCall(ADMIN_BASE_URL);
export const adminActionsApiClient = new AdminActionsApiCall(ADMIN_BASE_URL);
export const reviewTreatmentAdminApiClient = new ReviewTreatmentAdminApiCall(ADMIN_BASE_URL);
export const overridingStateParametersApiClient = new OverridingStateParametersApiCall(
    ADMIN_BASE_URL,
);
export const searchDuplicatesApiClient = new SearchDuplicatesApiCall(ADMIN_BASE_URL);
export const servicesAdminApiClient = new ServicesAdminApiCall(ADMIN_BASE_URL);
export const prospectsApiClient = new ProspectsApiCall(ADMIN_BASE_URL);
export const userInfosApiClient = new UserInfosApiCall(ADMIN_BASE_URL);
export const demoCreatorAdminApiClient = new DemoCreatorAdminApiCall(ADMIN_BASE_URL);
export const diffusionCleanerApiClient = new DiffusionCleanerApiCall(ADMIN_BASE_URL);
export const googleAdminManagementApiClient = new GoogleAdminManagementApiCall(ADMIN_BASE_URL);
