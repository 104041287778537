import { ApiResourceBaseClass } from '@partoohub/api-client';

import { GetIntegrationsResponse } from 'admin/api/types';

const RESOURCE_URI = 'integrations';

export class IntegrationsApiCall extends ApiResourceBaseClass {
    async getIntegrations(): Promise<GetIntegrationsResponse> {
        const { data } = await this.get<GetIntegrationsResponse>(`${RESOURCE_URI}`);

        return data;
    }
}
