import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const DiffusionLoadingDetails = styled.div`
    flex-direction: column;
    align-items: start;
    margin-top: 4px;
    margin-bottom: 8px;
    border: 1px solid ${greyColorObject.initial};
    border-radius: 4px;
`;
