import { SuccessResponse, UserData } from '@partoohub/api-client';
import { getQueryObjectFromObject } from '@partoohub/utils';

import { SsoInfoResponse } from 'app/api/types/sso';
import {
    BusinessUser,
    BusinessUsersAPIResponse,
    BusinessUsersParams,
    FormattedUserData,
    FuzzySearchUsersReceivedData,
    ReportUserPreferencesResponse,
    RequestPartnerUpsaleResponse,
    SearchUserParams,
    UserBusinessesReceivedData,
    UserReceivedData,
    V2UsersAvailableRoles,
    V2UsersStats,
} from 'app/api/types/user';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'user';

class User extends ApiResourceBaseClass {
    static searchUsers(params: SearchUserParams): Promise<FuzzySearchUsersReceivedData> {
        const route = `${RESOURCE_URI}/search`;
        const data = getQueryObjectFromObject({ display_business_ids: 'False', ...params });
        return User.get(route, data).then(({ data }) => data);
    }

    static getUser(userId: string): Promise<UserData> {
        return User.get(`${RESOURCE_URI}/${userId}`).then(({ data }) => data);
    }

    static listUsers(usersList: string): Promise<Array<BusinessUser>> {
        return User.get(`users/list?user_id_in=${usersList}`).then(
            ({ data }: { data: FuzzySearchUsersReceivedData }) => {
                return data.users.map(user =>
                    Object.create({
                        id: user.id,
                        firstName: user.first_name,
                        lastName: user.last_name,
                        role: user.role,
                    }),
                );
            },
        );
    }

    static getUserBusiness(userId: string, page: number): Promise<UserBusinessesReceivedData> {
        const route = `${RESOURCE_URI}/${userId}/businesses`;
        return User.get(route, { page }).then(({ data }) => data);
    }

    static createUser(userData: FormattedUserData): Promise<UserReceivedData> {
        const route = `${RESOURCE_URI}?partoo_ui=true`;
        return User.post(route, userData).then(({ data }) => data);
    }

    static deleteUser(userId: string): Promise<UserReceivedData> {
        const route = `${RESOURCE_URI}/${userId}`;
        return User.delete(route).then(({ data }) => data);
    }

    static editUser(userId: string, userData: FormattedUserData): Promise<UserReceivedData> {
        const route = `${RESOURCE_URI}/${userId}`;
        return User.post(route, userData).then(({ data }) => data);
    }

    static getUsersStats(): Promise<V2UsersStats> {
        return User.get(`${RESOURCE_URI}/stats`).then(({ data }) => data);
    }

    static getAvailableRoles(): Promise<V2UsersAvailableRoles> {
        const route = `${RESOURCE_URI}/available-roles`;
        return User.get(route).then(({ data }) => data);
    }

    static reInvinteUser(userId: string): Promise<UserReceivedData> {
        const route = `${RESOURCE_URI}/${userId}/invite`;
        return User.post(route).then(({ data }) => data);
    }

    static reInvinteAllUsers(): Promise<SuccessResponse> {
        const route = `${RESOURCE_URI}/reinvite_all`;
        return User.post(route).then(({ data }) => data);
    }

    static requestPartnerUpsale(partnerName: string): Promise<RequestPartnerUpsaleResponse> {
        const route = `${RESOURCE_URI}/request_partner_upsale`;
        const params = { partner: partnerName };
        return User.post(route, params).then(({ data }) => data);
    }

    static getUserReportPreferences(userId: string): Promise<ReportUserPreferencesResponse> {
        const route = `${RESOURCE_URI}/${userId}/preferences`;
        return User.get(route).then(({ data }) => data);
    }

    static updateUserReportPreferences(
        payload: Record<string, any>,
        userId: string,
    ): Promise<SuccessResponse> {
        const route = `${RESOURCE_URI}/${userId}/preferences`;
        return User.post(route, payload).then(({ data }) => data);
    }

    static getSsoInfo(email: string): Promise<SsoInfoResponse> {
        const route = `${RESOURCE_URI}/sso_info`;
        return User.get(route, { email }).then(({ data }) => data);
    }

    static acceptTermsAndConditions(): Promise<SuccessResponse> {
        const route = `${RESOURCE_URI}/accept_terms_and_conditions`;
        return User.put(route).then(({ data }) => data);
    }

    static getBusinessUsers(
        businessId: string,
        page: number,
        queryParams: BusinessUsersParams,
    ): Promise<BusinessUsersAPIResponse> {
        const route = `users/business/${businessId}`;
        const params = { ...queryParams, page: page, per_page: 100 };
        return User.get(route, params).then(({ data }) => data);
    }
}

export default User;
