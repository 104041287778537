import React, { FC, ReactNode, useContext, useMemo, useState } from 'react';

interface IsSideMenuOpenProviderProps {
    children?: ReactNode;
}

interface IsSideMenuContextProps {
    isSideMenuOpen: boolean;
    setIsSideMenuOpen: (value: boolean) => void;
}

const IsSideMenuOpenContext = React.createContext<IsSideMenuContextProps>({
    isSideMenuOpen: true,
    setIsSideMenuOpen: _value => undefined,
});

export const IsSideMenuOpenProvider: FC<IsSideMenuOpenProviderProps> = ({ children }) => {
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);
    const contextValue = useMemo(
        () => ({
            isSideMenuOpen,
            setIsSideMenuOpen,
        }),
        [isSideMenuOpen],
    );

    return (
        <IsSideMenuOpenContext.Provider value={contextValue}>
            {children}
        </IsSideMenuOpenContext.Provider>
    );
};

export const useIsSideMenuOpen = () => useContext(IsSideMenuOpenContext);
