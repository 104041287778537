import { FontAwesomeIconsPartooUsed, IconPrefix, Modal } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import { FullScreen, LeftPart, RightPart, StyledIconButton } from './FullScreenModal.styled';
type Props = {
    childrenLeft: JSX.Element;
    // Display the right section only if the device is above tablet size, or if forceDisplayRightPart is set to true.
    childrenRight?: JSX.Element;
    show: boolean;
    onHideClick: () => void;
    forceDisplayExitButton?: boolean;
    forceDisplayRightPart?: boolean;
    hideCloseButton?: boolean;
};
const FullScreenModal = ({
    show,
    onHideClick,
    childrenLeft,
    childrenRight,
    forceDisplayExitButton = false,
    forceDisplayRightPart = false,
    hideCloseButton = false,
}: Props) => {
    const { isBelowProvidedDevices: isTablet } = useDeviceType(IS_BELOW_TABLET);
    const displayRightPart = childrenRight && (!isTablet || forceDisplayRightPart);
    const closeButton = (
        <StyledIconButton
            appearance="outlined"
            onClick={onHideClick}
            icon={[FontAwesomeIconsPartooUsed.faXMarkLarge, IconPrefix.SOLID]}
            dataTrackId="close_fullscreen_modal_button"
        />
    );
    return (
        <Modal isOpen={show} closeModal={onHideClick} placement="center" animation="fadeIn">
            <FullScreen>
                <LeftPart>{childrenLeft}</LeftPart>
                {displayRightPart && (
                    <RightPart
                        backgroundUrl={`${STATIC_BASE_URL}/images/app/funnel/background.png`}
                    >
                        {childrenRight}
                    </RightPart>
                )}
                {!hideCloseButton && closeButton}
                {!childrenRight && forceDisplayExitButton && closeButton}
            </FullScreen>
        </Modal>
    );
};
export default FullScreenModal;
