import { FC } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationNetworkElement,
    IllustrationNetworkEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router';

import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';

import { CONNECTION_PATH } from 'admin/common/data/routeIds';

const UnauthorizedPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const illustrationElement: IllustrationNetworkElement = {
        name: IllustrationNetworkEnum.Forbidden,
        type: IllustrationCategoryEnum.Network,
    };

    return (
        <NotFoundTemplate
            show
            title={t('unauthorized_title')}
            subtitle={t('unauthorized_description')}
            imgSrc={illustrationElement}
            buttonText={t('unauthorized_button')}
            handleClick={() => navigate(CONNECTION_PATH)}
        />
    );
};

export default UnauthorizedPage;
