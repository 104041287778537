import { ApiResourceBaseClass, SuccessResponse } from '@partoohub/api-client';

import { ActionFilter } from 'admin/api/types';

const RESOURCE_URI = 'actions';

export class AdminActionsApiCall extends ApiResourceBaseClass {
    async userActions(filter: ActionFilter): Promise<SuccessResponse> {
        const { data } = await this.post<SuccessResponse>(`${RESOURCE_URI}/user`, {
            ...filter,
        });

        return data;
    }
}
