import { Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { AccountPartnerName, V2LocationData } from 'app/api/types/account';
import {
    InfosContainer,
    LocationAddressContainer,
    LocationBrandBadge,
    LocationCardContainer,
    LocationCodeContainer,
    LocationNameContainer,
    LocationStatusText,
    PartnerIconContainer,
    ServiceAreaComponent,
} from 'app/businessConnection/components/common/LocationCard/LocationCard.styled';
import PartnerIcon from 'app/businessConnection/components/common/PartnerIcon';
import { BRAND_TYPE } from 'app/common/data/locationTypes';
import { formatISODatetime } from 'app/utils/dates';

type Props = {
    location: V2LocationData;
    partnerName: AccountPartnerName;
    hasError?: boolean;
    isSelected?: boolean;
    noBorder?: boolean;
};

export const googleVerificationsDict = {
    verified: {
        color: 'success',
        label: 'google_verification_location_status_verified',
        icon: 'fa-check-circle',
    },
    unverified: {
        color: 'warning',
        label: 'google_verification_location_status_unverified',
        icon: 'fa-exclamation-circle',
    },
    pending: {
        color: 'warning',
        label: 'google_verification_location_status_pending',
        icon: 'fa-exclamation-circle',
    },
    in_review: {
        color: 'warning',
        label: 'google_verification_location_status_in_review',
        icon: 'fa-exclamation-circle',
    },
    has_duplicate: {
        color: 'warning',
        label: 'google_verification_location_status_has_duplicate',
        icon: 'fa-exclamation-circle',
    },
    disabled: {
        color: 'danger',
        label: 'google_verification_location_status_disabled',
        icon: 'fa-circle-xmark',
    },
    suspended: {
        color: 'danger',
        label: 'google_verification_location_status_suspended',
        icon: 'fa-circle-xmark',
    },
    null: {
        color: 'secondary',
        label: 'google_verification_location_status_missing',
        icon: 'fa-circle-question',
    },
};

const verificationStatusComponent = (
    t: (text: string) => string,
    currentStatus: { color: string; label: string; icon: string },
    statusCheckedAt: string | undefined,
) => (
    <Tooltip text={statusCheckedAt ? formatISODatetime(statusCheckedAt) : ''} placement="auto">
        <LocationStatusText color={currentStatus.color} variant="bodySBold" oneLine>
            <i className={`fa-solid ${currentStatus.icon}`} />
            {t(currentStatus.label)}
        </LocationStatusText>
    </Tooltip>
);

export const renderGoogleVerificationStatus = (
    t: (text: string) => string,
    location: V2LocationData,
) => {
    const currentStatusData =
        googleVerificationsDict[location.google_verifications?.status || 'null'];

    if (currentStatusData) {
        return verificationStatusComponent(
            t,
            currentStatusData,
            location.google_verifications?.checked_at,
        );
    }
};

export const commonLocationCardComponent = (
    t: (text: string) => string,
    location: V2LocationData,
) => (
    <>
        <LocationNameContainer>
            {location.location_name}
            {location.type === BRAND_TYPE && <LocationBrandBadge>{t('page')}</LocationBrandBadge>}
        </LocationNameContainer>
        <LocationAddressContainer>
            {!location.address_details && (
                <ServiceAreaComponent>
                    <span className="fa-stack fa-small">
                        <i className="fa-solid fa-circle fa-stack-2x"></i>
                        <i className="fa-solid fa-location-dot fa-stack-1x fa-inverse"></i>
                    </span>
                    <div>{t('google_location_service_area')}</div>
                </ServiceAreaComponent>
            )}
            {location.address_details && `${location.address_details}\n`}
            {location.postal_code && `${location.postal_code} `}
            {location.locality && `${location.locality}`}
        </LocationAddressContainer>
        {location.type !== BRAND_TYPE && location.store_code && (
            <LocationCodeContainer>
                {`${t('store_code')}:`}
                <span>{location.store_code}</span>
            </LocationCodeContainer>
        )}
    </>
);

const LocationCard = ({ isSelected, hasError, noBorder, location, partnerName }: Props) => {
    const { t } = useTranslation();
    return (
        <LocationCardContainer isSelected={isSelected} hasError={hasError} noBorder={noBorder}>
            <PartnerIconContainer>{PartnerIcon(partnerName)}</PartnerIconContainer>
            <InfosContainer>
                {commonLocationCardComponent(t, location)}
                {partnerName === 'google' && renderGoogleVerificationStatus(t, location)}
            </InfosContainer>
        </LocationCardContainer>
    );
};

export default LocationCard;
