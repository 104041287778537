import { SuccessResponse } from 'app/api/types';
import { FuzzySearchOrgsReceivedData, SearchOrgParams } from 'app/api/types/org';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

import { generateQueryParamsString } from './utils';

const RESOURCE_URI = 'demos';

export class DemoCreatorAdminApiCall extends ApiResourceBaseClass {
    static createDemo({ org, lang }: { org: string; lang: string }): Promise<SuccessResponse> {
        return DemoCreatorAdminApiCall.post(`${RESOURCE_URI}`, {
            org_id: org,
            lang,
        }).then(({ data }) => data);
    }

    static getOrgsForDemoCreator(
        queryParams: SearchOrgParams,
    ): Promise<FuzzySearchOrgsReceivedData> {
        return DemoCreatorAdminApiCall.get(
            `${RESOURCE_URI}/get_orgs?${generateQueryParamsString(queryParams)}`,
        ).then(({ data }) => data);
    }
}
