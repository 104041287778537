const GOOGLE_MY_BUSINESS = 'google_my_business';
const JUSTACOTE = 'justacote';
const LE_118K = 'le118000';
const HOODSPOT = 'hoodspot';
const FACEBOOK = 'facebook';
const BING_GLOBAL = 'bing_global';
const APPLE_BUSINESS_CONNECT = 'apple_business_connect';

export const DIRECT_PUBLISHERS = [
    GOOGLE_MY_BUSINESS,
    FACEBOOK,
    JUSTACOTE,
    LE_118K,
    HOODSPOT,
    BING_GLOBAL,
    APPLE_BUSINESS_CONNECT,
];
