import { SuccessResponse } from '@partoohub/api-client';
import { getQueryStringFromObject } from '@partoohub/utils';

import { GoogleAdminManagementData } from 'app/api/types/googleAdminManagement';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

const RESOURCE_URI = 'google-administrations';

class GoogleAdminManagement extends ApiResourceBaseClass {
    static fetchGoogleAdminRequests(
        page = 1,
        queryFilter: Record<string, any> = {},
    ): Promise<GoogleAdminManagementData> {
        const queryParams = getQueryStringFromObject({ ...queryFilter, page });

        return GoogleAdminManagement.get(`${RESOURCE_URI}?${queryParams}`).then(({ data }) => data);
    }

    static requestAdmin(googleLocationId: string): Promise<SuccessResponse> {
        return GoogleAdminManagement.post(
            `${RESOURCE_URI}/locations/${googleLocationId}/request`,
        ).then(({ data }) => data);
    }

    static acceptInvitation(
        googleLocationId: string,
        invitationName: string,
    ): Promise<SuccessResponse> {
        return GoogleAdminManagement.post(`${RESOURCE_URI}/locations/${googleLocationId}/accept`, {
            invitation_name: invitationName,
        }).then(({ data }) => data);
    }
}

export default GoogleAdminManagement;
