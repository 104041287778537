import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { FeedbackReplySuggestionPromptData } from 'app/api/types/feedbackReplySuggestion';
import api from 'app/api/v2/api_calls';

import { FEEDBACK_REPLY_SUGGESTION_GENERIC_PROMPT } from 'admin/common/data/queryKeysConstants';

export const useGetFeedbackReplySuggestionGenericPrompt = (
    options: UseQueryOptions<FeedbackReplySuggestionPromptData> = {},
) => {
    const query = useQuery(
        [FEEDBACK_REPLY_SUGGESTION_GENERIC_PROMPT] as QueryKey,
        () => api.feedbackReplySuggestion.getGenericPrompt(),
        options,
    );

    return [query.data, query] as const;
};
