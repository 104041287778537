import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleSelect } from 'admin/common/components/inputs/SingleSelect';

type Props = {
    placeholder: string;
    selectedValue: string | null;
    onChange: (value: string | null) => void;
};

export const OrganisationTypeInput: FC<Props> = ({ placeholder, selectedValue, onChange }) => {
    const { t } = useTranslation();
    const translatedOrganizationTypeOptions = useMemo(
        () => [
            { value: 'both', name: 'both', label: t('all') },
            { value: 'only_true', name: 'only_true', label: t('only_enterprise') },
            { value: 'only_false', name: 'only_false', label: t('only_smb') },
        ],
        [],
    );

    return (
        <SingleSelect
            dataTrackId="page_old_exporter__select_organization_type"
            sections={[{ options: translatedOrganizationTypeOptions }]}
            selectedValue={selectedValue}
            onChange={onChange}
            placeholder={placeholder}
            disableReset
        />
    );
};
