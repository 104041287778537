import React, { FC, ReactElement, ReactNode } from 'react';

import { Text } from '@partoohub/ui';
import { NavLink } from 'react-router-dom';

import { ChipWrapper, IconWrapper, LinkWrapper, StyledTooltip } from './SidebarLink.styled';

type Props = {
    to: string;
    tooltip: string;
    icon: ReactNode;
    active: boolean;
    chip?: ReactNode;
};

const SidebarLink: FC<Props> = ({ to, tooltip, icon, active, chip }) => (
    <LinkWrapper>
        <StyledTooltip
            text={<Text variant="bodySBold">{tooltip}</Text>}
            placement="right"
            variant="default"
        >
            <NavLink to={to} className={active ? 'link active' : 'link'}>
                {chip && <ChipWrapper>{chip}</ChipWrapper>}
                <IconWrapper className="icon_wrapper">{icon as ReactElement}</IconWrapper>
            </NavLink>
        </StyledTooltip>
    </LinkWrapper>
);

export default SidebarLink;
