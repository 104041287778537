import { FC } from 'react';

import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

interface DiffusionCleanerExportButtonProps {
    onClick?: () => void;
    disabled?: boolean;
}

export const DiffusionCleanerExportButton: FC<DiffusionCleanerExportButtonProps> = ({
    disabled,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <Button
            dataTrackId="diffusion_cleaner__export_button"
            icon={[FontAwesomeIconsPartooUsed.faFileDownload, IconPrefix.SOLID]}
            variant="secondary"
            appearance="outlined"
            shape="cube"
            onClick={onClick}
            disabled={disabled}
        >
            {t('admin:diffusion_cleaner__export_data')}
        </Button>
    );
};
