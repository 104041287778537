import { SuccessResponse } from '@partoohub/api-client';
import { getQueryStringFromObject } from '@partoohub/utils';

import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

const RESOURCE_URI = 'bulk';

type BulkUserExportFilter = {
    query?: string;
    role?: string | null;
    status?: 'invited' | 'active' | null;
    has_assigned_businesses?: boolean;
    email?: string;
    language?: string;
};

export type BulkExportBusinessClientFilter = BusinessModalFiltersType & {
    email?: string;
    sections__in?: string;
};

export class BulkExportClientApiCall extends ApiResourceBaseClass {
    static bulkBusinessExport(
        queryParams: BulkExportBusinessClientFilter,
    ): Promise<SuccessResponse> {
        return BulkExportClientApiCall.get(
            `${RESOURCE_URI}/business/exporter?${getQueryStringFromObject(queryParams)}`,
        ).then(({ data }) => data);
    }

    static bulkUserExport(queryParams: BulkUserExportFilter): Promise<SuccessResponse> {
        return BulkExportClientApiCall.get(
            `${RESOURCE_URI}/user/exporter?${getQueryStringFromObject(queryParams)}`,
        ).then(({ data }) => data);
    }
}
