import { useMemo } from 'react';

import { useWindowSize } from './useWindowSize';

export const MIN_TABLET_WIDTH = 769;
export const MAX_TABLET_WIDTH = 1024;

export enum DeviceType {
    Desktop = 'desktop',
    Tablet = 'tablet',
    MobileApp = 'mobileApp',
    Mobile = 'mobile',
}

export const ALL_DEVICES_TYPES = [
    DeviceType.Mobile,
    DeviceType.MobileApp,
    DeviceType.Tablet,
    DeviceType.Desktop,
];
export const IS_BELOW_TABLET = [DeviceType.Mobile, DeviceType.MobileApp, DeviceType.Tablet];
export const IS_BELOW_MOBILE = [DeviceType.Mobile, DeviceType.MobileApp];

type DeviceTypeData = {
    device: DeviceType;
    isBelowProvidedDevices: boolean;
};

export const useDeviceType = (
    belowDevicesTypes: DeviceType[] = ALL_DEVICES_TYPES,
): DeviceTypeData => {
    const { width } = useWindowSize();

    const deviceType = useMemo<DeviceType>(() => {
        if (navigator.userAgent.includes('PartooMobileApp')) {
            return DeviceType.MobileApp;
        } else if (!!width && MIN_TABLET_WIDTH <= width && width <= MAX_TABLET_WIDTH) {
            return DeviceType.Tablet;
        } else if (!!width && MIN_TABLET_WIDTH > width) {
            return DeviceType.Mobile;
        }
        return DeviceType.Desktop;
    }, [width]);

    const isBelowProvidedDevices = belowDevicesTypes.includes(deviceType);

    return { device: deviceType, isBelowProvidedDevices };
};
