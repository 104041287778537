import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Stack } from '@partoohub/ui';

import { CategoryCell } from './ServicesList.styled';

export const ServiceCell = styled(CategoryCell)`
    padding: 0 24px;
    height: 70px;
`;

export const ServiceCellContent = styled(Stack)<{ disabled?: boolean }>`
    width: 100%;
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.3;
            pointer-events: none;
        `};
`;

export const ServiceCellLeft = styled.div`
    overflow: hidden;
`;

export const ServiceCellRight = styled(Stack)`
    min-width: fit-content;
`;
