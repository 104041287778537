import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const Container = styled.div`
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    box-sizing: border-box;
    border-color: rgb(230, 232, 237);
    padding: 10px 14px;
    background-color: #f5f7fb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const TextStyled = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
